import { Box, Typography, useTheme } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { HighlightCarouselItem } from "../../../types/HighlightCarouselItem"
import HighlightCarouselItemSkeleton from "./HighlightCarouselItemComponent.skeleton"
import LandingPageAccessGuard from "../LandingPageAccessGuard/LandingPageAccessGuard"
import useGoogleTagManager from "../../../helpers/analytics/use-google-tag-manager"

import styles from './HighlightCarouselItemComponent.module.scss'

interface HighlightCarouselItemProps {
    highlightCarouselItem?: HighlightCarouselItem,
    aspectRatio?: HighlightCarouselItemImageAspectRatio,
    onCarouselItemClick?: (item: HighlightCarouselItem) => void,
    showBackground: boolean,
    useBlackFontColor: boolean,
    fontColor: string
}

export enum HighlightCarouselItemImageAspectRatio {
    SQUARE = `1 / 1`,
    RECTANGLE = `5 / 3`,
}

const HighlightCarouselItemComponent = (props: HighlightCarouselItemProps) => {
    const { palette } = useTheme()
    const navigate = useNavigate()
    const { trackCarouselItemClick, trackCarouselItemRedirect } = useGoogleTagManager()

    const { highlightCarouselItem, aspectRatio = HighlightCarouselItemImageAspectRatio.RECTANGLE, onCarouselItemClick, showBackground, fontColor, useBlackFontColor } = props

    if (!highlightCarouselItem) {
        return <HighlightCarouselItemSkeleton />
    }

    const handleOnCarouselItemClick = () => {
        trackCarouselItemClick(highlightCarouselItem)

        onCarouselItemClick?.(highlightCarouselItem)

        if (highlightCarouselItem.url) {
            trackCarouselItemRedirect(highlightCarouselItem)
            window.open(highlightCarouselItem.url, `_blank`)
        } else if (highlightCarouselItem.membershipPlanUrl) {
            navigate(highlightCarouselItem.membershipPlanUrl)
        } else if (highlightCarouselItem.route) {
            navigate(highlightCarouselItem.route)
        }
    }

    const handleOnLandingPageGuardClickHandled = () => {
        trackCarouselItemClick(highlightCarouselItem)
    }

    const imageStyle = {
        aspectRatio,
    }

    const color = showBackground ? { color: fontColor } : { color: useBlackFontColor ? `black` : palette.primary.main }

    return (
        <LandingPageAccessGuard onClickHandled={handleOnLandingPageGuardClickHandled} membershipPlanRoute={highlightCarouselItem.membershipPlanUrl}>
            <Box className={styles.container} onClick={handleOnCarouselItemClick}>
                <Box component='img' className={styles.image} sx={imageStyle} src={highlightCarouselItem.image} alt={`${highlightCarouselItem.title} image`} />
                <Box className={styles.content}>
                    <Typography className={styles.title} variant="h3" sx={color}>{highlightCarouselItem.title}</Typography>
                    <Typography className={styles.description} variant="h4">{highlightCarouselItem.description}</Typography>
                </Box>
            </Box>
        </LandingPageAccessGuard>
    )
}

export default HighlightCarouselItemComponent