import { useContext } from 'react'

import { AppContext } from '../../stores/App/AppStore'
import { ActionTypes } from '../../stores/App/AppReducer'
import InstitutionRepository from './institution.repository'

const useInstitutionRepository = () => {
    const { dispatch } = useContext(AppContext)
    const institutionRepository = new InstitutionRepository()

    const retrieveInstitutions = () => {
        institutionRepository.getAll()
            .then((data) => {
                dispatch({ type: ActionTypes.INSTITUTION_RETRIEVE, data })
            })
            .catch((error) => {
                console.error(error.message)
            })
    }

    return {
        retrieveInstitutions,
    }
}

export { useInstitutionRepository }