import { AxiosError } from 'axios'
import StarterRequestError from '../../types/StarterRequestError'
import StarterDataError from '../../types/StarterDataError'

export const ERROR_500 = 500

export const getAxiosErrorMessage = (err: AxiosError): string => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const payload: any = err.response?.data
    return payload.error.message
}

export const starterDataError = (error: AxiosError<StarterRequestError>): StarterDataError => {
    return {
        errorMessage: errorMessage(error),
        errorCode: errorCode(error),
    }
}

export const errorMessage = (error: AxiosError<StarterRequestError>): string | undefined => {
    return error?.response?.data?.error?.message ?? error.message
}

export const errorCode = (error: AxiosError<StarterRequestError>): number | undefined => {
    return error?.response?.data?.error?.details?.errorCode ?? error.status ?? error.response?.status
}