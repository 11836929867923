import { Box, Button, Container, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { useContext, useEffect } from "react"

import MembershipRepository from "../../../../repositories/Membership/membership.repository"
import { useUserRepository } from "../../../../repositories/User/use-user-repository"
import SplashScreenGuard from "../../../../router/guards/SplashScreenGuard"
import { UserContext } from "../../../../stores/User/UserStore"
import welcomeImage from "../../../../assets/celebrate.png"
import { Route } from "../../../../router"

import styles from "./UpmeWelcomePage.module.scss"

const UpmeWelcomePage = () => {
    const { t } = useTranslation()

    const navigate = useNavigate()
    const { retrieveUser } = useUserRepository()

    const { state: userState } = useContext(UserContext)

    useEffect(() => {
        const membershipRepository = new MembershipRepository()

        retrieveUser()

        membershipRepository.clearUserJustBecameUnionMember()
        membershipRepository.setDisplayedWelcomeUnionMemberDialog()
    }, [])

    const handleButtonClick = () => {
        navigate(Route.MEMBERSHIP_USER_INTERESTS)
    }

    return (
        <SplashScreenGuard>
            <Container className={styles.container}>
                <img src={welcomeImage} alt="U PME Welcome Image" className={styles.banner}/>
                <Box className={styles.textContent}>
                    <Typography className={styles.title} variant="h1">{t(`upme_welcome_title`, { name: userState.user?.data?.name, interpolation: { escapeValue: false } })}</Typography>
                    <Typography className={styles.message} variant="body1" mb={1}>{t(`upme_welcome_message_line_1`)}</Typography>
                </Box>
                <Box className={styles.buttonContainer}>
                    <Button variant="contained" onClick={handleButtonClick}>{t(`upme_welcome_button`)}</Button>
                </Box>
            </Container>
        </SplashScreenGuard>
    )
}

export default UpmeWelcomePage