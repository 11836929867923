import { Skeleton } from "@mui/material"
import { ReactElement } from "react"
import { v4 as uuidv4 } from 'uuid'

import styles from "./SkeletonLines.module.css"

export interface SkeletonLinesProps {
    skeletonClassName?: string,
    skeletonLines?: number,
}

const defaultSkeletonLinesNumber = 5

const SkeletonLines = (props: SkeletonLinesProps) => {
    const {
        skeletonClassName = styles.skeleton,
        skeletonLines = defaultSkeletonLinesNumber,
    } = props

    const skeletonElements: ReactElement[] = new Array(skeletonLines)

    for (let i = 0; i < skeletonElements.length; i++) {
        skeletonElements[i] = <Skeleton variant="text" className={skeletonClassName} key={uuidv4()} />
    }

    return (
        <>
            {skeletonElements}
        </>
    )
}

export default SkeletonLines