import React from 'react'
import { Box, Container, Grid, Skeleton } from '@mui/material'
import SkeletonProps from '../../../types/SkeletonProps'
import SimpleFAQItemSkeleton from '../../molecules/SimpleFAQItem/SimpleFAQItem.skeleton'

const AboutSkeleton: React.FC<SkeletonProps> = (props: SkeletonProps) => {
    const { children, condition } = props

    if (condition) {
        return children
    }

    return (
        <Container>
            <Grid container display='flex' justifyContent="center" alignItems="center" direction='column'>
                <Grid item width='100%'>
                    <Skeleton variant='rectangular' width='100%' height='40vw' />
                </Grid>
                <Skeleton variant="text" sx={{ fontSize: `1rem` }} width='100%' />
                <Box marginTop={1}>
                    <SimpleFAQItemSkeleton key="faq-item-1" />
                    <SimpleFAQItemSkeleton key="faq-item-2" />
                </Box>
                <Grid item width='100%'>
                    <Skeleton variant='rectangular' width='100%' height='40vw' />
                </Grid>
            </Grid>
        </Container>
    )
}

export default AboutSkeleton