import { useContext, useEffect, useState } from 'react'
import { Container, Grid } from '@mui/material'
import { useParams } from 'react-router-dom'

import { AppContext } from '../../../stores/App/AppStore'
import { UserContext } from '../../../stores/User/UserStore'
import { useCuratedExperienceRepository } from '../../../repositories/CuratedExperience/use-curated-experience-repository'
import { useSessionRepository } from '../../../repositories/Session/use-session-repository'
import { CuratedExperienceDetail } from '../../../types'
import CommonBackButton from '../../atoms/CommonBackButton/CommonBackButton'
import CuratedExperienceDetailsContent from '../../organisms/CuratedExperienceDetailsContent/CuratedExperienceDetailsContent'
import SplashScreenGuard from '../../../router/guards/SplashScreenGuard'
import useTrackPageView from "../../../helpers/hooks/use-track-page-view"

enum UserStatus {
    LOGGED_OUT = `LOGGED_OUT`,
    LOGGED_IN = `LOGGED_IN`,
    MEMBER = `MEMBER`,
}

const CuratedExperienceDetails = () => {
    const { id: experienceId } = useParams()
    const { state: appState } = useContext(AppContext)
    const { state: userState } = useContext(UserContext)

    const { isAuthenticated, isMember } = useSessionRepository()
    const { retrieveCuratedExperience } = useCuratedExperienceRepository()

    const [curatedExperience, setCuratedExperience] = useState<CuratedExperienceDetail | undefined>()
    const [userStatus, setUserStatus] = useState(UserStatus.LOGGED_OUT)

    useTrackPageView(() => {
        if (curatedExperience) {
            return {
                title: `Curated Experience Details`,
                item: curatedExperience.title,
            }
        }
    }, [curatedExperience])

    useEffect(() => {
        if (experienceId && parseInt(experienceId)) {
            retrieveCuratedExperience(parseInt(experienceId))
        }
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        if (isAuthenticated()) {
            isMember().then((hasMembership => setUserStatus(hasMembership ? UserStatus.MEMBER : UserStatus.LOGGED_IN)))
        } else {
            setUserStatus(UserStatus.LOGGED_OUT)
        }
    }, [userState.session])

    useEffect(() => {
        if (experienceId && parseInt(experienceId)) {
            const currentExperience = appState.pillars.curatedExperience.experiences.find(experience => experience.id == parseInt(experienceId))
            if (currentExperience) {
                setCuratedExperience(currentExperience)
            }
        }
    }, [appState])

    return (
        <SplashScreenGuard>
            <Container>
                <CommonBackButton />

                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        <CuratedExperienceDetailsContent curatedExperience={curatedExperience} userStatus={userStatus} />
                    </Grid>
                </Grid>
            </Container>
        </SplashScreenGuard>
    )
}

export default CuratedExperienceDetails

export { UserStatus }