import react from "react"
import clsx from "clsx"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import { TransitionProps } from "@mui/material/transitions"
import { Button, Container, Typography, Zoom } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { Route } from "../../../router"
import remarkGfm from 'remark-gfm'
import ReactMarkdown from 'react-markdown'

import { ErrorMessageVariants } from "../../../types/ErrorMessageVariants"

import styles from './ErrorDialog.module.css'

const Transition = react.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<string, string>;
    },
    ref: React.Ref<unknown>,
) {
    return <Zoom ref={ref} {...props} />
})

interface ErrorDialogProps {
    acknowledgeOnly?: boolean
    dismissButtonText?: string
    defaultOpen: boolean
    variant?: ErrorMessageVariants
    onDismiss?: () => void
}

const ErrorDialog = ({
    acknowledgeOnly,
    dismissButtonText,
    defaultOpen,
    variant,
    onDismiss,
}: ErrorDialogProps) => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const handleCloseErrorDialog = () => {
        navigate(Route.ROOT)
    }

    const buttonText = t(`common_back_to_home`)
    const errorTitle = t(`sign_up_failure`)

    let errorText

    switch (variant) {
        case ErrorMessageVariants.PAYMENT:
            errorText = t(`payment_summary_order_failure`)
            break
        case ErrorMessageVariants.UTAP:
            errorText = t(`utap_claim_success_error_message`)
            break
        default:
            errorText = t(`common_error_message`)
            break
    }

    return (
        <Container>
            <Dialog
                open={defaultOpen}
                TransitionComponent={Transition}
                classes={{
                    paper: styles.dialog,
                }}
            >
                <DialogContent>
                    <Typography variant="h6" className={styles.title}>{errorTitle}</Typography>
                    <ReactMarkdown className={`reactMarkdown`} remarkPlugins={[remarkGfm]}>{errorText}</ReactMarkdown>
                </DialogContent>
                <DialogActions className={clsx(styles.actions, {
                    [styles.singleAction]: acknowledgeOnly,
                })}>
                    {!acknowledgeOnly ? (
                        <Button variant='outlined' onClick={onDismiss}>{dismissButtonText ?? t(`close`)}</Button>
                    ) : null}
                    <Button variant='contained' onClick={handleCloseErrorDialog} fullWidth={acknowledgeOnly}>{buttonText}</Button>
                </DialogActions>
            </Dialog>
        </Container>
    )
}

export default ErrorDialog