import { getPaymentUrl } from '../../clients/payment-order'
import { createPaymentSubscription, getStripePaymentOrder, getPaymentOrderHistory } from '../../clients/stripe-payment-order'
import { PaymentIntentResponse, StripePaymentOrderRequest, StripePaymentOrderResult, CreatePaymentOrderRequest, PaymentHistoryResult } from '../../types/StripePaymentOrder'
import { fromStrapiPaymentOrderHistory } from '../../helpers/mapping/payment-order-history'
import { LocalStorageKeys } from '../../types/LocalStorageKeys'
import { PaymentOrderUrl } from '../../types/PaymentOrder'

class PaymentOrderRepository {

    async getPaymentOrder(order: StripePaymentOrderRequest ): Promise<StripePaymentOrderResult> {
        return await getStripePaymentOrder(order)
    }

    async createPaymentOrder(paymentOrderRequest: CreatePaymentOrderRequest): Promise<PaymentIntentResponse> {
        return await createPaymentSubscription(paymentOrderRequest)
    }

    async getPaymentUrl(clientOrderId: string): Promise<PaymentOrderUrl> {
        const paymentOrderUrl = await getPaymentUrl(clientOrderId)
        return paymentOrderUrl
    }

    async getPaymentOrderHistory(exchangeId: string, pageSize: number): Promise<PaymentHistoryResult[]> {
        const data = await getPaymentOrderHistory(exchangeId, pageSize)
        return fromStrapiPaymentOrderHistory(data)
    }

    setClientOrderIdInLocalStorage(clientOrderId: string) {
        return localStorage.setItem(LocalStorageKeys.CLIENT_ORDER_ID_KEY, clientOrderId)
    }

    getClientOrderIdFromLocalStorage(): string {
        const clientOrderId = localStorage.getItem(LocalStorageKeys.CLIENT_ORDER_ID_KEY)
        return clientOrderId ?? ``
    }

}

export default PaymentOrderRepository