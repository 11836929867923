import { Pillar } from "../../types"
import { absoluteImageUrl } from "../utils/environment"
import { fromStrapiFaq } from "./faq"
import { fromStrapiProgramme } from "./programme"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const fromStrapiPillar = (strapiData: any): Pillar => {
    const { id, attributes } = strapiData
    const { title, description, route, url, programme, faqEntries, image, order, membershipScope } = attributes
    const imageURL = absoluteImageUrl(image.data?.attributes?.url)

    const item: Pillar = {
        id,
        title,
        description,
        route,
        url,
        programme: programme.data ? fromStrapiProgramme(programme.data) : undefined,
        faqEntries: faqEntries.data.map(fromStrapiFaq),
        imageURL,
        order,
        membershipScope,
    }

    return item
}

export {
    fromStrapiPillar,
}