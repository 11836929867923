import { useContext } from 'react'

import { AppContext } from '../../stores/App/AppStore'
import { ActionTypes } from '../../stores/App/AppReducer'
import TermsAndConditionsRepository from './terms-and-conditions.repository'

const useTnCRepository = () => {
    const { dispatch } = useContext(AppContext)
    const termsAndConditionsRepository = new TermsAndConditionsRepository()

    const retrieveTerms = () => {
        termsAndConditionsRepository.getAll()
            .then((data) => {
                dispatch({ type: ActionTypes.TERMS_RETRIEVE, data })
            })
            .catch((error) => {
                console.error(error.message)
            })
    }

    const retrieveTerm = (purpose: string) => {
        termsAndConditionsRepository.get(purpose)
            .then((data) => {
                dispatch({ type: ActionTypes.TERMS_RETRIEVE_ONE, data })
            })
            .catch((error) => {
                console.error(error.message)
            })
    }

    return {
        retrieveTerms,
        retrieveTerm,
    }
}

export { useTnCRepository }