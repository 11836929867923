import { DealCategory, StrapiDealCategory } from "../../types/DealCategory"

const fromStrapiDealCategory = (strapiDealCategory: StrapiDealCategory): DealCategory => {
    return {
        id: strapiDealCategory.id,
        name: strapiDealCategory.attributes.name,
        order: strapiDealCategory.attributes.order,
    }
}

export default fromStrapiDealCategory