import moment from 'moment'
import { UpdateFrequency } from '../../types/UpdateFrequency'

const validateUpdateFrequencyConfigurations = (envValue: string): boolean => {
    if (!envValue) {
        return false
    }

    const frequencyNumber = envValue.charAt(0)
    const frequencyType = envValue.charAt(1)

    if (!frequencyNumber || !frequencyType) {
        console.error(`Both number of days and frequency type must be provided.`)
        return false
    }

    if (isNaN(Number(frequencyNumber)) || !Object.values(UpdateFrequency).includes(frequencyType as UpdateFrequency)) {
        console.error(`Invalid frequency type or Invalid number of days`)
        return false
    }

    return true
}

export const shouldUpdateProfileFromMembershipSetting = (profileUpdateSettingDate: string | Date, userLastUpdatedProfileDate: string | Date): boolean => {
    if (!profileUpdateSettingDate) {
        return false
    }

    let shouldUpdateProfile = false

    if (profileUpdateSettingDate) {
        if(!userLastUpdatedProfileDate) {
            return true
        }

        const profileUpdateSettingMoment = moment(profileUpdateSettingDate)
        const userLastUpdatedProfileMoment = moment(userLastUpdatedProfileDate)
        shouldUpdateProfile = userLastUpdatedProfileMoment.isBefore(profileUpdateSettingMoment)

        return shouldUpdateProfile
    }

    return shouldUpdateProfile
}

export const shouldUpdateProfileDateFromConfiguration = (userLastUpdatedProfileDate?: string | Date) => {
    const frequencyConfiguration = `${process.env.REACT_APP_PROFILE_UPDATE_FREQUENCY}`
    const isConfigurationValidated = validateUpdateFrequencyConfigurations(frequencyConfiguration)

    if (!isConfigurationValidated && !userLastUpdatedProfileDate) {
        return false
    }

    if (isConfigurationValidated) {
        const frequencyNumber = frequencyConfiguration.charAt(0)
        const frequencyType = frequencyConfiguration.charAt(1)
        const formatFrequencyNumber = parseInt(frequencyNumber, 10)

        if(!userLastUpdatedProfileDate) {
            // config is set but user lastProfileUpdate can be undefined
            return moment().isSameOrAfter(userLastUpdatedProfileDate)

        } else {
            switch(frequencyType) {
                case UpdateFrequency.DAYS: {
                    const dateToShowPrompt = moment(userLastUpdatedProfileDate).add(formatFrequencyNumber, UpdateFrequency.DAYS)
                    return moment().isSameOrAfter(dateToShowPrompt)
                }
                case UpdateFrequency.WEEKS: {
                    const dateToShowPrompt = moment(userLastUpdatedProfileDate).add(formatFrequencyNumber, UpdateFrequency.WEEKS)
                    return moment().isSameOrAfter(dateToShowPrompt)
                }
                case UpdateFrequency.MONTHS: {
                    const dateToShowPrompt = moment(userLastUpdatedProfileDate).add(formatFrequencyNumber, UpdateFrequency.MONTHS)
                    return moment().isSameOrAfter(dateToShowPrompt)
                }
                case UpdateFrequency.YEARS: {
                    const dateToShowPrompt = moment(userLastUpdatedProfileDate).add(formatFrequencyNumber, UpdateFrequency.YEARS)
                    return moment().isSameOrAfter(dateToShowPrompt)
                }
                default:
                    throw new Error(`Invalid update frequency`)
            }
        }
    }
}