import { ButtonProps } from '@mui/material'
import { createTheme } from '@mui/material/styles'

const palette = {
    primary: {
        lightest: `#E7F7F9`,
        lighter: `#CFEFF4`,
        light: `#6FCEDD`,
        main: `#0FAEC6`,
        dark: `#0C8B9E`,
        contrastText: `#FFFFFF`,
    },
    error: {
        main: `#D10E1E`,
        dark: `#A70B18`,
        contrastText: `#FFFFFF`,
        var: {
            main: `#FAE7E8`,
            dark: `#F6CFD2`,
            contrastText: `#D10E1E`,
        },
    },
    success: {
        main: `#046E3B`,
        dark: `#03582F`,
        contrastText: `#FFFFFF`,
        var: {
            main: `#E6F0EB`,
            dark: `#CDE2D8`,
            contrastText: `#046E3B`,
        },
    },
    warning: {
        main: `#E65100`,
        dark: `#B84100`,
        contrastText: `#FFFFFF`,
        var: {
            main: `#FCEEE5`,
            dark: `#FADCCC`,
            contrastText: `#E65100`,
        },
    },
    info: {
        main: `#01579B`,
        dark: `#01467C`,
        contrastText: `#FFFFFF`,
        var: {
            main: `#E6EEF5`,
            dark: `#CCDDEB`,
            contrastText: `#01579B`,
        },
    },
    disabled: {
        main: `#C7C6CF`,
        contrastText: `#FFFFFF`,
    },
    surface: {
        primary: {
            main: `#FFFFFF`,
            dark: `#FAFAFF`,
            contrastText: `#0E0B35`,
        },
        secondary: {
            main: `#F2F2FF`,
            dark: `#E3E2F6`,
            contrastText: `#0E0B35`,
        },
        tertiary: {
            main: `#0E0B35`,
            contrastText: `#FFFFFF`,
        },
    },
    functional: {
        main: `#0E0B35`,
        text: {
            lighter: `#6A6982`,
            light: `#3C3A5B`,
            main: `#0E0B35`,
            mainVar: `#FFFFFF`,
        },
        border: {
            light: `#C7C6CF`,
            main: `#6A6982`,
        },
    },
}

const components = {
    MuiCard: {
        styleOverrides: {
            root: {
                backgroundColor: palette.surface.secondary.main,
            },
        },
    },
    MuiContainer: {
        styleOverrides: {
            root: {
                padding: 24,
            },
        },
    },
    MuiButton: {
        styleOverrides: {
            root: {
                padding: `0.6rem`,
                borderRadius: `0.6rem`,
            },
            contained: {
                background: `linear-gradient(90deg, ${palette.primary.main} 0%, ${palette.primary.main} 100%)`,
                color: palette.primary.contrastText,
                'text-transform': `uppercase`,
                fontWeight: 700,
                padding: `16px`,
            },
            outlined: {
                fontWeigth: 700,
                padding: `14px`,
            },
            text: {
                borderColor: `black`,
                color: `black`,
            },
        },
        variants: [
            {
                props: { variant: `prompt` } as ButtonProps,
                style: {
                    color: palette.info.var?.contrastText,
                },
            },
        ],
    },
    MuiLoadingButton: {
        styleOverrides: {
            loadingIndicator: {
                color: palette.primary.contrastText,
            },
        },
    },
    MuiMobileStepper: {
        styleOverrides: {
            root: {
                width: `auto`,
            },
            dot: {
                backgroundColor: `white`,
                border: `solid 1px ${palette.primary.light}`,
                margin: `8px`,
            },
            dotActive: {
                backgroundColor: palette.primary.light,
            },
        },
    },
    MuiTab: {
        styleOverrides: {
            root: {
                fontSize: `0.8rem`,
                'text-transform': `none`,
                '&.Mui-selected': {
                    color: palette.primary.dark,
                },
            },
        },
    },
    MuiTabs: {
        styleOverrides: {
            root: {
                backgroundColor: palette.surface.primary.main,
            },
            indicator: {
                backgroundColor: palette.primary.dark,
            },
        },
    },
    MuiAvatar: {
        styleOverrides: {
            root: {
                backgroundColor: palette.primary.main,
                color: `#FFFFFF`,
            },
        },
    },
    MuiPaper: {
        styleOverrides: {
            root: {
                width: `100%`,
            },
        },
    },
}

const theme = createTheme({
    palette,
    components,
})

const typography = {
    ...theme.typography,
    fontFamily: [
        `Roboto`,
        `-apple-system`,
        `BlinkMacSystemFont`,
        `Segoe UI`,
        `Oxygen`,
        `Ubuntu`,
        `Cantarell`,
        `Fira Sans`,
        `Droid Sans`,
        `Helvetica Neue`,
        `sans-serif`,
    ].join(`,`),
    h1: {
        fontFamily: `Poppins`,
        fontWeight: 800,
        fontSize: `2rem`,
        [theme.breakpoints.up(`md`)]: {
            fontSize: `2rem`,
        },
    },
    h2: {
        fontWeight: 300,
        fontSize: 60,
    },
    h3: {
        fontFamily: `Poppins`,
        fontWeight: 500,
        fontSize: `1.4rem`,
    },
    h4: {
        fontFamily: `Roboto`,
        fontWeight: 400,
        fontSize: `1.2  rem`,
    },
    h5: {
        fontFamily: `Inter`,
        fontWeight: 700,
        fontSize: `1.2rem`,
    },
    h6: {
        fontFamily: `Roboto`,
        fontWeight: 400,
        fontSize: `1.1rem`,
    },
    subtitle1: {
        fontWeight: 500,
        fontSize: 16,
    },
    subtitle2: {
        fontWeight: 500,
        fontSize: 14,
    },
    body1: {
        fontWeight: 300,
        fontSize: `1rem`,
    },
    body2: {
        fontWeight: 300,
        fontSize: `1rem`,
    },
    button: {
        fontWeight: 500,
        fontSize: `1rem`,
    },
    caption: {
        fontWeight: 300,
        fontSize: 12,
    },
    overline: {
        fontWeight: 300,
        fontSize: 10,
        color: palette.primary.contrastText,
    },
}

theme.typography = typography

export default theme