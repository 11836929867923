import { Tab, Tabs, TabsProps } from "@mui/material"
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react"
import clsx from "clsx"

import { ScrollingTabItem } from "../../../types/ScrollingTabItem"
import ScrollingTabsSkeleton from "./ScrollingTabs.skeleton"

import styles from "./ScrollingTabs.module.scss"

const MIN_DISTANCE_TO_RIGHT_BORDER = 50

interface ScrollingTabsProps extends TabsProps {
    items: ScrollingTabItem[]
    selectedIndex: number
    onTabChange: (event: React.SyntheticEvent, newIndex: number) => void
}

const ScrollingTabs = forwardRef(function ScrollingTabs(props: ScrollingTabsProps, ref) {
    const { items, selectedIndex, onTabChange, className, ...inheritedProps } = props

    const [selectedTabValue, setSelectedTabValue] = useState(selectedIndex)

    const categoriesTabsRef = useRef<HTMLButtonElement>(null)

    useImperativeHandle(ref, () => categoriesTabsRef.current)

    useEffect(() => {
        setSelectedTabValue(selectedIndex)
    }, [selectedIndex])

    const handleTabChange = (event: React.SyntheticEvent, newIndex: number) => {
        setSelectedTabValue(newIndex)

        const selectTabId = getTabId(items[newIndex])
        const selectedTab = document.getElementById(selectTabId)
        const selectedTabBoundingClientRect = selectedTab?.getBoundingClientRect()
        const tabsBoundingClientRect = categoriesTabsRef?.current?.getBoundingClientRect()

        scrollIfTooMuchOnTheRight(categoriesTabsRef?.current, tabsBoundingClientRect, selectedTabBoundingClientRect)

        onTabChange?.(event, newIndex)
    }

    const scrollIfTooMuchOnTheRight = (tabs: HTMLElement | null, tabsBoundingClientRect?: DOMRect, selectedTabBoundingClientRect?: DOMRect) => {
        if (tabsBoundingClientRect && selectedTabBoundingClientRect) {
            const scrollers = tabs?.getElementsByClassName(`MuiTabs-scroller`)

            if (scrollers?.length) {
                const scroller = scrollers[0]

                const distanceToRightBorder = tabsBoundingClientRect?.width - selectedTabBoundingClientRect?.right

                if (distanceToRightBorder < MIN_DISTANCE_TO_RIGHT_BORDER) {
                    scroller.scrollBy({
                        left: MIN_DISTANCE_TO_RIGHT_BORDER - distanceToRightBorder,
                        behavior: `smooth`,
                    })
                }
            }
        }
    }

    const getTabId = (item: ScrollingTabItem): string => {
        return `scrolling-tab-item-${item.id}`
    }

    const tabsStyle = clsx(
        className,
        styles.categoriesTabs,
    )

    if (!items) {
        return <ScrollingTabsSkeleton />
    }

    return (
        <Tabs ref={categoriesTabsRef} className={tabsStyle} value={selectedTabValue} onChange={handleTabChange} variant="scrollable" scrollButtons={false} aria-label="categories list" {...inheritedProps} >
            {
                items.map(item => <Tab id={getTabId(item)} label={item.name} key={getTabId(item)} />)
            }
        </Tabs>
    )
})

export default ScrollingTabs