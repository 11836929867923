import { LastClaimDataResponse, MembershipPlanResponse, MembershipSettingsResponse, UserInfoServiceResponse, UtapClaimsListServiceResponse } from "../../types"
import { UserState } from "./UserState"
import { CheckUnionMembershipStatusResponse } from '../../types/MembershipStatus'
import { CancelMembershipResponse, MembershipResponse, ResumeRecurringMembershipResponse } from "../../types/Membership"
import { UtapStatusResponse } from "../../types/UtapStatus"
import { PartnerResponse } from "../../types/Partner"
import { ImportedMembershipResponse } from "../../types/ImportedMembership"
import { MyInfoUserDataResponse } from "../../types/MyInfoUserData"
import { SessionResponse } from "../../types/Session"
import { ProfilePictureResponse } from "../../types/ProfilePicture"

export enum UserActionTypes {
    CANCEL_MEMBERSHIP = `CANCEL_MEMBERSHIP`,
    LOGIN = `LOGIN`,
    LOGOUT = `LOGOUT`,
    MEMBERSHIP_DATA_RETRIEVED = `MEMBERSHIP_DATA_RETRIEVED`,
    IMPORTED_MEMBERSHIP_DATA_RETRIEVED = `IMPORTED_MEMBERSHIP_DATA_RETRIEVED`,
    CLEAR_IMPORTED_MEMBERSHIP = `CLEAR_IMPORTED_MEMBERSHIP`,
    MYINFO_DATA_RETRIEVED = `MYINFO_DATA_RETRIEVED`,
    UNION_MEMBERSHIP_STATUS_RECEIVED = `UNION_MEMBERSHIP_STATUS_RECEIVED`,
    MEMBERSHIP_SETTINGS_RETRIEVED = `MEMBERSHIP_SETTINGS_RETRIEVED`,
    MEMBERSHIP_PLAN_RETRIEVED = `MEMBERSHIP_PLAN_RETRIEVED`,
    PARTNER_RETRIEVED = `PARTNER_RETRIEVED`,
    USER_INFO_RETRIEVED = `USER_INFO_RETRIEVED`,
    UTAP_CLAIMS_RETRIEVE = `UTAP_CLAIMS_RETRIEVE`,
    UTAP_STATUS_RETRIEVED = `UTAP_STATUS_RETRIEVED`,
    LAST_CLAIM_DATA_RETRIEVED = `LAST_CLAIM_DATA_RETRIEVED`,
    PROFILE_PICTURE_UPLOADED = `PROFILE_PICTURE_UPLOADED`,
    PROFILE_PICTURE_RETRIEVED = `PROFILE_PICTURE_RETRIEVED`,
    RESUME_MEMBERSHIP = `RESUME_MEMBERSHIP`
}

export type UserAction =
    | { type: UserActionTypes.CANCEL_MEMBERSHIP, data: CancelMembershipResponse }
    | { type: UserActionTypes.RESUME_MEMBERSHIP, data: ResumeRecurringMembershipResponse }
    | { type: UserActionTypes.LOGIN, session: SessionResponse }
    | { type: UserActionTypes.LOGOUT }
    | { type: UserActionTypes.MEMBERSHIP_DATA_RETRIEVED, data: MembershipResponse }
    | { type: UserActionTypes.IMPORTED_MEMBERSHIP_DATA_RETRIEVED, data: ImportedMembershipResponse }
    | { type: UserActionTypes.CLEAR_IMPORTED_MEMBERSHIP }
    | { type: UserActionTypes.MYINFO_DATA_RETRIEVED, data: MyInfoUserDataResponse }
    | { type: UserActionTypes.UNION_MEMBERSHIP_STATUS_RECEIVED, unionMembershipStatus: CheckUnionMembershipStatusResponse }
    | { type: UserActionTypes.MEMBERSHIP_SETTINGS_RETRIEVED, membershipSettings: MembershipSettingsResponse | undefined }
    | { type: UserActionTypes.MEMBERSHIP_PLAN_RETRIEVED, membershipPlan: MembershipPlanResponse | undefined }
    | { type: UserActionTypes.PARTNER_RETRIEVED, partner: PartnerResponse | undefined }
    | { type: UserActionTypes.USER_INFO_RETRIEVED, data: UserInfoServiceResponse }
    | { type: UserActionTypes.UTAP_CLAIMS_RETRIEVE, data: UtapClaimsListServiceResponse }
    | { type: UserActionTypes.UTAP_STATUS_RETRIEVED, data: UtapStatusResponse }
    | { type: UserActionTypes.LAST_CLAIM_DATA_RETRIEVED, data: LastClaimDataResponse }
    | { type: UserActionTypes.PROFILE_PICTURE_UPLOADED, profilePictureThumbnail: string }
    | { type: UserActionTypes.PROFILE_PICTURE_RETRIEVED, profilePicture: ProfilePictureResponse }

const sessionReducer = (state: UserState, action: UserAction): UserState => {
    switch (action.type) {
        case UserActionTypes.CANCEL_MEMBERSHIP: {
            return {
                ...state,
                membershipCancelAtPeriodEnd: action.data,
            }
        }
        case UserActionTypes.LOGIN: {
            return {
                ...state,
                session: action.session,
            }
        }
        case UserActionTypes.LOGOUT: {
            return {}
        }
        case UserActionTypes.MEMBERSHIP_DATA_RETRIEVED: {
            return {
                ...state,
                membership: action.data,
            }
        }
        case UserActionTypes.IMPORTED_MEMBERSHIP_DATA_RETRIEVED: {
            return {
                ...state,
                importedMembership: action.data,
            }
        }
        case UserActionTypes.CLEAR_IMPORTED_MEMBERSHIP: {
            return {
                ...state,
                importedMembership: undefined,
            }
        }
        case UserActionTypes.MYINFO_DATA_RETRIEVED: {
            return {
                ...state,
                myInfoUserData: action.data,
            }
        }
        case UserActionTypes.UNION_MEMBERSHIP_STATUS_RECEIVED: {
            return {
                ...state,
                unionMembershipStatus: action.unionMembershipStatus,
            }
        }
        case UserActionTypes.MEMBERSHIP_SETTINGS_RETRIEVED: {
            return {
                ...state,
                membershipSettings: action.membershipSettings,
            }
        }
        case UserActionTypes.MEMBERSHIP_PLAN_RETRIEVED: {
            return {
                ...state,
                membershipPlan: action.membershipPlan,
            }
        }
        case UserActionTypes.PARTNER_RETRIEVED: {
            return {
                ...state,
                partner: action.partner,
            }
        }
        case UserActionTypes.USER_INFO_RETRIEVED: {
            return {
                ...state,
                user: action.data,
            }
        }
        case UserActionTypes.UTAP_CLAIMS_RETRIEVE: {
            return {
                ...state,
                utapClaims: action.data,
            }
        }
        case UserActionTypes.UTAP_STATUS_RETRIEVED: {
            return {
                ...state,
                utapStatus: action.data,
            }
        }
        case UserActionTypes.LAST_CLAIM_DATA_RETRIEVED: {
            return {
                ...state,
                lastClaimData: action.data,
            }
        }
        case UserActionTypes.PROFILE_PICTURE_UPLOADED: {
            if (state.membership?.data) {
                return {
                    ...state,
                    membership: {
                        ...state.membership,
                        data: {
                            ...state.membership.data,
                            profilePictureThumbnail: action.profilePictureThumbnail,
                        },
                    },
                }
            }
            return state
        }
        case UserActionTypes.PROFILE_PICTURE_RETRIEVED: {
            return {
                ...state,
                profilePicture: action.profilePicture,
            }
        }
        case UserActionTypes.RESUME_MEMBERSHIP: {
            return {
                ...state,
                resumeRecurringMembership: action.data,
            }
        }
        default: {
            throw new Error(`Unhandled action type`)
        }
    }
}

export default sessionReducer