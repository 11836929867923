import { Box, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"

import SimpleFAQItemSkeleton from "../SimpleFAQItem/SimpleFAQItem.skeleton"

const SimpleFAQSectionSkeleton = () => {
    const { t } = useTranslation()

    return (
        <Box>
            <Typography variant="h5">{t(`simple_faq_title`)}</Typography>
            <Box marginTop={1}>
                <SimpleFAQItemSkeleton key="faq-item-1" />
                <SimpleFAQItemSkeleton key="faq-item-2" />
                <SimpleFAQItemSkeleton key="faq-item-3" />
            </Box>
        </Box>
    )
}

export default SimpleFAQSectionSkeleton