import { TrainingProvider } from '../../types'
import { fetchTrainingProviders } from '../../clients/training-provider'

class TrainingProviderRepository {

    async getAll(): Promise<TrainingProvider[]> {
        const data = await fetchTrainingProviders()
        return data
    }
}

export default TrainingProviderRepository