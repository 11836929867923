import { useContext } from 'react'

import { AppContext } from '../../stores/App/AppStore'
import { ActionTypes } from '../../stores/App/AppReducer'
import { MembershipType } from '../../types/MembershipType'
import FaqRepository from './faq-repository'

const useFAQRepository = () => {
    const { dispatch } = useContext(AppContext)
    const faqRepository = new FaqRepository()

    const retrieveFAQs = (membershipType: MembershipType | undefined) => {
        faqRepository.getAll(membershipType ?? MembershipType.STARTER)
            .then((faqs) => {
                dispatch({
                    type: ActionTypes.FAQ_RETRIEVED,
                    faqs: faqs,
                })
            })
            .catch((error) => {
                console.error(error.message)
            })
    }

    return {
        retrieveFAQs,
    }
}

export { useFAQRepository }