import { Box, Button, Container, Stack, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router"
import { Route } from "../../../router"
import { useRequestedRoute } from "../../../router/hooks/requested-route"
import { CuratedExperienceDetail } from "../../../types"
import CommonOverlayGradient from "../../atoms/CommonOverlayGradient/CommonOverlayGradient"
import { UserStatus } from "../../pages/CuratedExperienceDetails/CuratedExperienceDetails"

import styles from "./CuratedExperienceBottomOverlay.module.css"

interface CuratedExperienceOverlayProps {
    curatedExperience: CuratedExperienceDetail,
    userStatus: UserStatus,
    setShowToast: (value: boolean) => void
}

const CuratedExperienceBottomOverlay = (props: CuratedExperienceOverlayProps) => {
    const { curatedExperience, userStatus, setShowToast } = props

    return (
        <Stack className={styles.overlay}>
            <CommonOverlayGradient />
            <Container className={styles.overlayContent}>
                <BottomOverlayMessage curatedExperience={curatedExperience} userStatus={userStatus} setShowToast={setShowToast} />
                <BottomOverlayButton curatedExperience={curatedExperience} userStatus={userStatus} setShowToast={setShowToast} />
            </Container>
        </Stack>
    )
}

const BottomOverlayMessage = (props: CuratedExperienceOverlayProps) => {
    const { curatedExperience, userStatus, setShowToast } = props

    const { t } = useTranslation()

    const handleCopyClick = () => {
        navigator.clipboard.writeText(curatedExperience?.promoCode ?? ``)
        setShowToast(true)
    }

    if (userStatus === UserStatus.MEMBER) {
        if (curatedExperience.promoCode) {
            return <Box className={styles.overlayContentText}>
                <Typography variant={`body2`}>{t(`promo_code`)}: </Typography>
                <Typography variant={`subtitle1`}>{curatedExperience?.promoCode}</Typography>
                <Typography
                    variant={`subtitle2`}
                    className={styles.overlayContentLink}
                    onClick={handleCopyClick}>
                    {t(`copy`)}
                </Typography>
            </Box>
        }
    } else {
        return <Box className={styles.overlayLogoutContentText}>
            <Typography variant={`subtitle1`}>{t(`unlock_deal`)}</Typography>
        </Box>
    }
    return <></>

}

const BottomOverlayButton = (props: CuratedExperienceOverlayProps) => {
    const { curatedExperience, userStatus } = props

    const { setRequestedRoute } = useRequestedRoute()
    const navigate = useNavigate()
    const { t } = useTranslation()

    const handleExternalLinkClick = () => {
        window.open(curatedExperience?.externalLink, `_self`)
    }

    const handleLoginClick = () => {
        setRequestedRoute(Route.PILLARS_CURATED_EXPERIENCE, `${curatedExperience.id}`)
        navigate(Route.PLS_LOGIN)
    }

    const handleSignUpClick = () => {
        setRequestedRoute(Route.PILLARS_CURATED_EXPERIENCE, `${curatedExperience.id}`)
        navigate(Route.MEMBERSHIP_JOIN)
    }

    switch (userStatus) {
        case UserStatus.LOGGED_OUT:
            return <Button variant='contained' onClick={handleLoginClick} fullWidth>{t(`sign_up`)}</Button>
        case UserStatus.LOGGED_IN:
            return <Button variant='contained' onClick={handleSignUpClick} fullWidth>{t(`sign_up`)}</Button>
        case UserStatus.MEMBER:
            return <Button variant='contained' onClick={handleExternalLinkClick} fullWidth>{t(`book_now`)}</Button>
        default:
            return <></>
    }

}

export default CuratedExperienceBottomOverlay