const formRequiredStateTemplate = {
    gender: false,
    residentialStatus: false,
    phoneNumber: false,
    postalCode: false,
    block: false,
    street: false,
    institution: false,
    yearsLeft: false,
    fieldOfStudy: false,
    eCardName: false,
    age: false,
    email: false,
}

const yearOptions = [
    { label: `Less than 1 year`, value: 1 },
    { label: `1 year`, value: 2 },
    { label: `2 years`, value: 3 },
    { label: `3 years`, value: 4 },
    { label: `4 years`, value: 5 },
    { label: `5 years`, value: 6 },
    { label: `6 years`, value: 7 },
    { label: `7 years`, value: 8 },
    { label: `8 years`, value: 9 },
    { label: `9 years`, value: 10 },
    { label: `10 years`, value: 11 },
]

const genderOptions = [
    { label: `Male`, value: `MALE` },
    { label: `Female`, value: `FEMALE` },
    { label: `Unknown`, value: `UNKNOWN` },
]
const residentialOptions = [
    { label: `Citizen`, value: `CITIZEN` },
    { label: `Permanent Resident`, value: `PR` },
    { label: `Unknown`, value: `UNKNOWN` },
    { label: `Not Applicable`, value: `NOT APPLICABLE` },
]

export default {
    formRequiredStateTemplate,
    yearOptions,
    genderOptions,
    residentialOptions,
}