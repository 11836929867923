import { useContext, useEffect, useState } from 'react'
import { Container, Grid } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import CommonBackButton from '../../atoms/CommonBackButton/CommonBackButton'
import ConfirmationDialog from '../../molecules/ConfirmationDialog/ConfirmationDialog'
import { Route } from '../../../router'
import { UserContext } from '../../../stores/User/UserStore'
import { useMembershipCardPageRepository } from '../../../repositories/MembershipCardPage/use-membership-card-page-repository'
import { MembershipStatus } from '../../../types/MembershipStatus'
import unionLogo from '../../../assets/union-logo.png'
import { useMembershipSettingsRepository } from '../../../repositories/MembershipSettings/use-membership-settings-repository'
import SplashScreenGuard from '../../../router/guards/SplashScreenGuard'
import MembershipCardContent from '../../molecules/MembershipCardContent/MembershipCardContent'
import useTrackPageView from "../../../helpers/hooks/use-track-page-view"
import useGoogleTagManager from '../../../helpers/analytics/use-google-tag-manager'
import GtmEventName from '../../../helpers/analytics/GtmEventName'

import styles from './MembershipCard.module.css'

const MembershipCard = () => {
    useTrackPageView(`Membership Card page`)

    const { t } = useTranslation()
    const navigate = useNavigate()
    const { trackDialogClose, trackDialogOpen } = useGoogleTagManager()

    const { state: userState } = useContext(UserContext)
    const userMembership = userState.membership?.data
    const { retrieveMembershipCardPage } = useMembershipCardPageRepository()
    const { retrieveMembershipSettings } = useMembershipSettingsRepository()

    const [showInactiveMembershipDialog, setShowInactiveMembershipDialog] = useState<boolean>(false)

    useEffect(() => {
        retrieveMembershipCardPage()
        retrieveMembershipSettings()
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        if (userMembership != undefined && userMembership.status !== MembershipStatus.ACTIVE && !userMembership.hasPendingSignupPayment) {
            setShowInactiveMembershipDialog(true)
        }
    }, [userMembership])

    useEffect(() => {
        if (showInactiveMembershipDialog) {
            trackDialogOpen(GtmEventName.REACTIVATE_MEMBERSHIP_OPEN)
        }
    }, [showInactiveMembershipDialog])

    const handleDismissInactiveMembershipDialog = () => {
        trackDialogClose(GtmEventName.REACTIVATE_MEMBERSHIP_DISMISS)
        navigate(Route.HOME)
    }

    return (
        <SplashScreenGuard>
            <Container className={styles.body}>
                <CommonBackButton path={Route.HOME} />
                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        <Container component={`img`} src={unionLogo} className={styles.unionLogo} />
                        <MembershipCardContent membership={userMembership} />
                        <ConfirmationDialog
                            acknowledgeOnly
                            buttonText={t(`common_back_to_home`)}
                            confirmationTitle={t(`membership_suspended_title`)}
                            confirmationText={t(`membership_suspended_description`)}
                            defaultOpen={showInactiveMembershipDialog}
                            onConfirm={handleDismissInactiveMembershipDialog}
                        />
                    </Grid>
                </Grid>
            </Container>
        </SplashScreenGuard>
    )
}

export default MembershipCard