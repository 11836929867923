import { Box, BoxProps, Button, Typography, useTheme } from "@mui/material"
import { ReactElement, useEffect, useState } from "react"
import clsx from "clsx"

import styles from "./Prompt.module.scss"

export type PromptVariant = `info` | `warn` | `success`

interface PromptProps extends BoxProps {
    message?: string,
    buttonText: string,
    icon?: ReactElement | string,
    visible?: boolean,
    variant?: PromptVariant,
    onButtonClick?: () => void,
}

const Prompt = (props: PromptProps) => {
    const { message, buttonText, icon, visible, onButtonClick, className, variant, ...inheritedProps } = props

    const [isVisible, setIsVisible] = useState(visible ?? true)

    useEffect(() => {
        setIsVisible(visible ?? true)
    }, [visible])

    const theme = useTheme()

    const promptClassName = clsx(
        styles.prompt,
        className,
        {
            [styles.expanded]: isVisible,
            [styles.collapsed]: !isVisible,
        },
    )

    const getPromptStyle = (variant: PromptVariant | undefined) => {
        switch (variant) {
            case `info`:
                return {
                    backgroundColor: theme.palette.info?.var?.dark,
                    color: theme.palette.info?.var?.contrastText,
                }
            case `warn`:
                return {
                    backgroundColor: theme.palette.warning?.var?.dark,
                    color: theme.palette.warning?.var?.contrastText,
                }

            case `success`:
            default:
                return {
                    backgroundColor: theme.palette.success?.var?.main,
                    color: theme.palette.success?.var?.contrastText,
                }
        }
    }

    const selectedPromptStyle = getPromptStyle(variant)

    const promptButtonStyle = {
        color: selectedPromptStyle.color,
    }

    const handleButtonClick = () => {
        onButtonClick?.()
    }

    let iconComponent = undefined

    if (icon) {
        if (typeof icon === `string`) {
            iconComponent = <img src={icon} alt="Prompt icon" />
        } else {
            iconComponent = icon
        }
    }

    if (!message || !variant) {
        return null
    }

    return (
        <Box className={promptClassName} sx={selectedPromptStyle} {...inheritedProps}>
            {
                iconComponent &&
                <Box className={styles.icon}>
                    {iconComponent}
                </Box>
            }
            <Box className={styles.content}>
                <Typography>{message}</Typography>
                <Button variant="prompt" sx={promptButtonStyle} onClick={handleButtonClick}>{buttonText}</Button>
            </Box>
        </Box>
    )
}

export default Prompt