import { useContext } from 'react'

import { AppContext } from '../../stores/App/AppStore'
import { ActionTypes } from '../../stores/App/AppReducer'
import TraningCourseRepository from './training-course.repository'
import { UtapCoursesServiceResponse } from '../../types/UtapCoursesServiceResponse'

const useTrainingCourseRepository = () => {
    const { dispatch } = useContext(AppContext)
    const trainingCourseRepository = new TraningCourseRepository()

    const retrieveTrainingCourse = (trainerId: number | string) => {
        const utapCoursesData: UtapCoursesServiceResponse = {}

        trainingCourseRepository.getAll(trainerId)
            .then((result) => utapCoursesData.data = result)
            .catch((error) => {
                utapCoursesData.error = {
                    errorMessage: error.message,
                    errorCode: error.response?.status,
                }
            })
            .finally(() => {
                dispatch({ type: ActionTypes.TRAINING_COURSE_RETRIEVE, data: utapCoursesData})
            })
    }

    return {
        retrieveTrainingCourse,
    }
}

export { useTrainingCourseRepository }