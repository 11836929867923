import { Button, Container, Divider, Grid } from "@mui/material"
import { useContext, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import clsx from "clsx"

import { useMembershipSuccessPageRepository } from "../../../../repositories/MembershipSuccessPage/use-membership-success-page-repository"
import PwaInstallationInstructionsAlert from "../../../molecules/PwaInstallationInstructionsAlert/PwaInstallationInstructionsAlert"
import SplashScreenGuard from "../../../../router/guards/SplashScreenGuard"
import MarkdownContent from "../../../atoms/MarkdownContent/MarkdownContent"
import useMemberGuard from "../../../../router/guards/member-guard"
import BannerImage from "../../../atoms/BannerImage/BannerImage"
import TextContent from "../../../atoms/TextContent/TextContent"
import { AppContext } from "../../../../stores/App/AppStore"
import Spacer from "../../../atoms/Spacer/Spacer"
import { Route } from "../../../../router"
import useTrackPageView from "../../../../helpers/hooks/use-track-page-view"
import useGoogleTagManager from "../../../../helpers/analytics/use-google-tag-manager"
import GtmEventName from "../../../../helpers/analytics/GtmEventName"

import styles from "./Success.module.css"

const Success = () => {
    useMemberGuard()

    useTrackPageView(`Membership Success Page`)

    const { t } = useTranslation()
    const { trackButtonClick } = useGoogleTagManager()

    const navigate = useNavigate()

    const { state } = useContext(AppContext)
    const { retrieveMembershipSuccessPage } = useMembershipSuccessPageRepository()

    useEffect(() => {
        retrieveMembershipSuccessPage()
        window.scrollTo(0, 0)
    }, [])

    const handleClick = () => {
        trackButtonClick(GtmEventName.MEMBERSHIP_SUCCESS_CONTINUE_CLICK)
        navigate(Route.MEMBERSHIP_STARTER_ONBOARDING)
    }

    return (
        <SplashScreenGuard nextRoute={Route.HOME}>
            <Container>
                <Grid container className={styles.gridContainer}>
                    <Grid item className={styles.banner}>
                        <BannerImage imageUrl={state.pages.success.bannerImageURL} alt="Success banner image" />
                    </Grid>
                    <Grid item className={styles.centered}>
                        <TextContent
                            content={state.pages.success.title}
                            variant="h5"
                            skeletonClassName={clsx(styles.skeleton, styles.skeletonWelcomeText)}
                            skeletonLines={1}
                        />
                        <TextContent
                            content={state.pages.success.description}
                            variant="body1"
                            skeletonClassName={clsx(styles.skeleton, styles.skeletonDescription)}
                            skeletonLines={4}
                            paragraph
                        />
                    </Grid>
                    <Grid item>
                        <Divider />
                    </Grid>
                    <Grid item className={styles.explore}>
                        <TextContent
                            content={state.pages.success.subtitle}
                            variant="h5"
                            skeletonClassName={clsx(styles.skeleton, styles.skeletonWelcomeText)}
                            skeletonLines={1}
                        />
                        <MarkdownContent content={state.pages.success.subtext} />
                    </Grid>
                    <Spacer flexNumber={2} />
                    <Grid item className={styles.successButton}>
                        <Button variant='contained' onClick={handleClick} fullWidth>{t(`sign_up_confirmation_continue`)}</Button>
                    </Grid>
                </Grid>
                <PwaInstallationInstructionsAlert forceOpen={false} />
            </Container>
        </SplashScreenGuard>
    )
}

export default Success