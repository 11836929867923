import { useContext, useEffect } from 'react'
import { Container, Typography, Box, Link } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { AppContext } from '../../../stores/App/AppStore'
import { useAboutPageRepository } from '../../../repositories/AboutPage/use-about-page-repository'
import CommonBackButton from '../../atoms/CommonBackButton/CommonBackButton'
import HighlightCarousel from '../../molecules/HighlightCarousel/HighlightCarousel'
import { HighlightCarouselItemImageAspectRatio } from '../../molecules/HighlightCarouselItemComponent/HighlightCarouselItemComponent'
import UpgradeUnionCard from '../../molecules/UpgradeUnionCard/UpgradeUnionCard'
import { Route } from '../../../router'
import SimpleFAQSection from '../../molecules/SimpleFAQSection/SimpleFAQSection'
import AboutSkeleton from './About.skeleton'
import useTrackPageView from "../../../helpers/hooks/use-track-page-view"

import styles from './About.module.scss'

const About = () => {
    useTrackPageView(`About Page`)

    const { state } = useContext(AppContext)
    const { retrieveAboutCarouselItems, retrieveAboutFaqItems } = useAboutPageRepository()
    const { t } = useTranslation()

    const navigate = useNavigate()

    useEffect(() => {
        retrieveAboutCarouselItems()
        retrieveAboutFaqItems()
        window.scrollTo(0, 0)
    }, [])

    const handleUnionCardClick = () => {
        navigate(`${Route.UPME_CONVERSION_BENEFITS}`)
    }

    const handleViewAllClick = () => {
        navigate(`${Route.UPME_CONVERSION_BENEFITS}`)
    }

    const aboutCarouselItems = state.pages.aboutCarouselItems.data
    const aboutFaqItems = state.pages.aboutFaqItems.data
    const conditionForRendering = !!aboutCarouselItems && !!aboutFaqItems

    return (
        <AboutSkeleton condition={conditionForRendering}>
            <Container>
                <CommonBackButton />
                <Box className={styles.container}>
                    <Box className={styles.headerSection} display="flex" alignItems="center">
                        <Typography variant={`h3`} className={styles.title}>{t(`about_starter`)}</Typography>
                        <Link color="inherit" onClick={handleViewAllClick}>
                            <Typography variant='subtitle1'>{t(`about_view_all`)}</Typography>
                        </Link>
                    </Box>
                    <Box className={styles.carouselSection}>
                        <HighlightCarousel highlightCarouselItems={aboutCarouselItems} aspectRatio={HighlightCarouselItemImageAspectRatio.RECTANGLE} showBackground={false} hideMobileStepper={true} useBlackFontColor={true} />
                    </Box>
                    <Box className={styles.faqSection}>
                        <SimpleFAQSection faqEntries={aboutFaqItems} />
                    </Box>
                    <Box className={styles.unionCardSection}>
                        <UpgradeUnionCard handleUnionCardClick={handleUnionCardClick} />
                    </Box>
                </Box>
            </Container>
        </AboutSkeleton>
    )
}

export default About