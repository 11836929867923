import moment from 'moment'
import { Location } from 'react-router'
import fetchStrapiSession from '../../clients/session'
import { Jwt, Session } from '../../types'
import jwtDecode from 'jwt-decode'

const MILLISECONDS = 1000
const SESSION_KEY = `userSession`

class SessionRepository {
    async retrieve(location: Location): Promise<Session> {
        return fetchStrapiSession(location)
            .then(result => {
                const session = buildSession(result.jwt, result.user.name, result.user.email, result.userBecameUnionMember)
                this.set(session)
                return session
            })
    }

    setJwt(jwt: string): Session {
        const session = buildSession(jwt)
        this.set(session)
        return session
    }

    set(session: Session) {
        localStorage.setItem(SESSION_KEY, JSON.stringify(session))
    }

    remove() {
        localStorage.removeItem(SESSION_KEY)
    }

    get(): Session | undefined {
        const userSession = localStorage.getItem(SESSION_KEY)

        if (userSession) {
            try {
                const session = JSON.parse(userSession) as Session

                if (moment().isBefore(session.jwt.expiryDate)) {
                    return session
                }

                localStorage.removeItem(SESSION_KEY)
            }
            catch (error) {
                localStorage.removeItem(SESSION_KEY)
            }
        }
    }
}

const buildSession = (jwt: string, name?: string, email?: string, userBecameUnionMember?: boolean) => {
    const decodedToken: Jwt = jwtDecode(jwt)
    const expiryDate = new Date(decodedToken.exp * MILLISECONDS)

    return {
        jwt: {
            token: jwt,
            expiryDate: expiryDate,
        },
        name: name,
        email: email,
        userBecameUnionMember,
    } as Session
}

export default SessionRepository