import { absoluteImageUrl } from '../utils/environment'
import { StrapiUpmeIntroductionCarouselItem, UpmeIntroductionCarouselItem } from '../../types/UpmeIntroductionCarouselItem'

const fromStrapiUpmeIntroductionCarouselItem = (strapiUpmeIntroductionCarouselItem: StrapiUpmeIntroductionCarouselItem): UpmeIntroductionCarouselItem => (
    {
        id: strapiUpmeIntroductionCarouselItem.id,
        title: strapiUpmeIntroductionCarouselItem.title,
        description: strapiUpmeIntroductionCarouselItem.summary,
        image: absoluteImageUrl(strapiUpmeIntroductionCarouselItem.image.url),
        upmeBenefit: {
            id: strapiUpmeIntroductionCarouselItem.id,
            title: strapiUpmeIntroductionCarouselItem.title,
            summary: strapiUpmeIntroductionCarouselItem.summary,
            description: strapiUpmeIntroductionCarouselItem.description,
            isUpme: strapiUpmeIntroductionCarouselItem.isUPME,
            isUpmeDetails: strapiUpmeIntroductionCarouselItem.isUPMEDetails,
            isStarter: strapiUpmeIntroductionCarouselItem.isStarter,
            isStarterDetails: strapiUpmeIntroductionCarouselItem.isStarterDetails,
            category: strapiUpmeIntroductionCarouselItem.category,
            order: strapiUpmeIntroductionCarouselItem.order,
            image: absoluteImageUrl(strapiUpmeIntroductionCarouselItem.image.url),
        },
    }
)

export default fromStrapiUpmeIntroductionCarouselItem