import { Box, Typography, useTheme } from "@mui/material"
import { useTranslation } from "react-i18next"

import styles from './ComingSoonBadge.module.scss'

const ComingSoonBadge = () => {
    const { t } = useTranslation()
    const theme = useTheme()

    const comingSoonBadgeStyle = {
        backgroundColor: theme.palette.primary.main,
    }

    return (
        <Box className={styles.upcomingBadge} sx={comingSoonBadgeStyle}>
            <Typography variant="overline">{t(`upcoming`)}</Typography>
        </Box >
    )
}

export default ComingSoonBadge