import { HTTP_SUCCESS } from '../helpers/utils/http-status'
import { MyInfoUserData } from '../types/MyInfoUserData'
import { anonymousClient, authenticatedClient } from './util'

const postUtapClaim = async (claimForm: FormData) => {
    const multipartFormHeader = {
        headers: {
            'Content-Type': `multipart/form-data`,
        },
    }

    return authenticatedClient()
        .post(
            `/api/utap`,
            claimForm,
            multipartFormHeader,
        ).then(res => {
            if (res.status !== HTTP_SUCCESS) {
                throw new Error(`Could not save utap claim`)
            }
            return res
        }).then(res => res.data)
}

const retrieveLastClaimData = async () => {
    return authenticatedClient()
        .get(
            `/api/utap/lastClaimData`,
        ).then(res => {
            if (res.status !== HTTP_SUCCESS) {
                throw new Error(`Could not retrieve utap claims log`)
            }
            return res
        }).then(res => res.data)
}

const retrieveUtapClaims = async (myInfoUserData: MyInfoUserData, pageSize: number) => {
    return authenticatedClient()
        .post(
            `/api/utap/claims`,
            { myInfoUserData, pageSize },
        ).then(res => {
            if (res.status !== HTTP_SUCCESS) {
                throw new Error(`Could not retrieve utap claims`)
            }
            return res
        }).then(res => res.data)
}

const retrieveUtapStatus = async (myInfoUserData: MyInfoUserData) => {
    return authenticatedClient()
        .post(
            `/api/utap/status`,
            { myInfoUserData },
        ).then(res => {
            if (res.status !== HTTP_SUCCESS) {
                throw new Error(`Could not retrieve user's UTAP status`)
            }
            return res
        }).then(res => {
            return res.data
        })
}

const retrieveUtapLandingPage = async () => {
    return anonymousClient()
        .get(
            `/api/utap-landing-page`,
        ).then(res => {
            if (res.status !== HTTP_SUCCESS) {
                throw new Error(`Could not retrieve UTAP landing page`)
            }
            return res
        }).then(res => {
            return res.data
        })
}

export {
    postUtapClaim,
    retrieveLastClaimData,
    retrieveUtapClaims,
    retrieveUtapStatus,
    retrieveUtapLandingPage,
}