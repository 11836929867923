import FormFile from "../../../types/FormFile"
import CustomFileInput from "../../atoms/CustomFileInput/CustomFileInput"

import styles from './FormFileInput.module.scss'

// eslint-disable-next-line no-magic-numbers
const ATTACHMENT_ALLOWED_SIZE = 10 * 1024
const ATTACHMENT_ALLOWED_TYPE = { png: `image/png`, jpeg: `image/jpeg`, jpg: `image/jpg`, pdf: `application/pdf` }

interface FormFileInputProps {
    formFile: FormFile
    attemptedSubmission?: boolean
    disabled?: boolean
    onFileChange: (formFile: FormFile) => void
}

const FormFileInput = (props: FormFileInputProps) => {
    const { formFile, attemptedSubmission, disabled, onFileChange } = props

    const handleFileChange = (file?: File, hasError?: boolean) => {
        onFileChange({
            ...formFile,
            value: file,
            hasError,
        })
    }

    return (
        <CustomFileInput
            className={styles.fileInput}
            fullWidth
            label={formFile.name}
            description={formFile.description}
            onChange={handleFileChange}
            maxFileSize={ATTACHMENT_ALLOWED_SIZE}
            allowedFiletypes={ATTACHMENT_ALLOWED_TYPE}
            mandatory={formFile.mandatory}
            attemptedSubmission={attemptedSubmission}
            disabled={disabled} />
    )
}

export default FormFileInput