import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Route } from '..'
import { useSessionRepository } from '../../repositories/Session/use-session-repository'

export default function useMemberGuard() {
    const { isAuthenticated, isMember } = useSessionRepository()
    const navigate = useNavigate()

    useEffect(() => {
        if (!isAuthenticated()) {
            navigate(Route.ROOT)
        } else {
            isMember().then((isMember) => {
                if (!isMember) {
                    navigate(Route.MEMBERSHIP_JOIN)
                }
            })
        }
    })
}