import { useContext } from 'react'

import { AppContext } from '../../stores/App/AppStore'
import { ActionTypes } from '../../stores/App/AppReducer'
import BankRepository from './bank.repository'

const useBankRepository = () => {
    const { dispatch } = useContext(AppContext)
    const bankRepository = new BankRepository()

    const retrieveBank = () => {
        bankRepository.getAll()
            .then((data) => {
                dispatch({ type: ActionTypes.BANK_RETRIEVE, data })
            })
            .catch((error) => {
                console.error(error.message)
            })
    }

    return {
        retrieveBank,
    }
}

export { useBankRepository }