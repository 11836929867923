import { useContext } from 'react'
import { MembershipCardPage } from '../../types'
import { ActionTypes } from '../../stores/App/AppReducer'
import MembershipCardPageRepository from './membership-card-page.repository'
import { AppContext } from '../../stores/App/AppStore'

const useMembershipCardPageRepository = () => {
    const { dispatch } = useContext(AppContext)
    const { getMembershipCardPage } = new MembershipCardPageRepository()

    const retrieveMembershipCardPage = () => {
        return getMembershipCardPage()
            .then((pageData: MembershipCardPage) => {
                dispatch({
                    type: ActionTypes.PAGE_MEMBERSHIP_CARD_RETRIEVED,
                    membershipCardPageData: pageData,
                })
            })
            .catch(error => {
                const membershipErrorError: MembershipCardPage = {
                    hasError: true,
                    errorMessage: error.response?.data?.error?.message,
                    errorCode: error.status,
                }
                dispatch({
                    type: ActionTypes.PAGE_MEMBERSHIP_CARD_RETRIEVED,
                    membershipCardPageData: membershipErrorError,
                })
            })
    }

    return {
        retrieveMembershipCardPage,
    }
}

export { useMembershipCardPageRepository }