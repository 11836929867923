import { useContext, useEffect, useState } from 'react'
import { Box, Container, Divider, Grid, Typography } from '@mui/material'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'

import CommonBackButton from '../../../atoms/CommonBackButton/CommonBackButton'
import retrieveMyInfoButtonImage from '../../../../assets/retrieve-with-my-info-button.png'
import useNonMemberGuard from '../../../../router/guards/non-member-guard'
import { AppContext } from '../../../../stores/App/AppStore'
import { UserContext } from '../../../../stores/User/UserStore'
import { Route, STRAPI_URL } from '../../../../router'
import { useAuthenticatedGuard } from "../../../../router/guards/authenticated-guard"
import { useJoinPageRepository } from '../../../../repositories/JoinPage/use-join-page-repository'
import BannerImage from '../../../atoms/BannerImage/BannerImage'
import TextContent from '../../../atoms/TextContent/TextContent'
import { useNavigate, useParams } from 'react-router-dom'
import { useMembershipPlanRepository } from '../../../../repositories/MembershipPlan/use-membership-plan-repository'
import { MyInfoReason } from '../../../../types/MyInfoUserData'
import { LocalStorageKeys } from '../../../../types/LocalStorageKeys'
import Spacer from '../../../atoms/Spacer/Spacer'
import MembershipPlanTNCsDialogDialog from '../../../molecules/MembershipPlanTnCsDialog/MembershipPlanTNCsDialog'
import AlertDialog from '../../../molecules/AlertDialog/AlertDialog'
import { useMembershipRepository } from '../../../../repositories/Membership/use-membership-repository'
import { AppState } from '../../../../stores/App/AppState'
import SplashScreenGuard from '../../../../router/guards/SplashScreenGuard'
import MembershipJoinPageContent from '../../../molecules/MembershipJoinPageContent/MembershipJoinPageContent'

import styles from './Join.module.css'

export const skeletonJoinPageBanner = (state: AppState) => {
    return (
        <>
            <Grid item className={styles.banner}>
                <BannerImage
                    imageUrl={state.pages.join.bannerImageURL}
                    className={styles.appLogo}
                    alt="logo"
                />
            </Grid>
            <Grid item className={styles.centered}>
                <TextContent
                    content={state.pages.join.title}
                    variant="h5"
                    skeletonClassName={clsx(styles.skeleton, styles.skeletonWelcomeText)}
                    skeletonLines={1}
                />
                <TextContent
                    content={state.pages.join.description}
                    variant="body1"
                    skeletonClassName={clsx(styles.skeleton, styles.skeletonDescription)}
                    skeletonLines={4}
                    paragraph
                />
            </Grid>
        </>
    )
}

const Join = () => {
    const { t } = useTranslation()
    const { state: appState } = useContext(AppContext)
    const { state: userState } = useContext(UserContext)
    const { retrieveMembership } = useMembershipRepository()
    const { retrieveJoinPage } = useJoinPageRepository()
    const { saveMembershipPlanKey } = useMembershipPlanRepository()
    const { plan, referralCode } = useParams()

    useAuthenticatedGuard(!plan ? Route.MEMBERSHIP_JOIN : (Route.MEMBERSHIP_JOIN + `/` + plan) as Route)
    useNonMemberGuard(Route.MEMBERSHIP_CARD)
    const navigate = useNavigate()

    const [showMembershipTNCsDialog, setShowMembershipTNCsDialog] = useState<boolean>(false)

    useEffect(() => {
        retrieveJoinPage()
        retrieveMembership()
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        saveMembershipPlanKey(plan)
    }, [plan])

    useEffect(() => {
        if (referralCode) {
            localStorage.setItem(LocalStorageKeys.REFERRAL_CODE, referralCode)
        }
    }, [referralCode])

    const handleClick = () => {
        localStorage.setItem(LocalStorageKeys.MYINFO_REASON, MyInfoReason.SIGNUP)
        window.location.href = `${STRAPI_URL}${Route.MYINFO_AUTHORISE}`
    }

    const myInfoInfo = <>
        <Typography variant="h6" className={styles.joinMyInfoTitle}>
            {t(`join_myInfo_title`)}
        </Typography>
        <Spacer height={15} />
        <Typography variant="body2" className={styles.joinMyInfoField}>
            {t(`join_myInfo_field1`)}
        </Typography>
        <Typography variant="body2" className={styles.joinMyInfoField}>
            {t(`join_myInfo_field2`)}
        </Typography>
        <Typography variant="body2" className={styles.joinMyInfoField}>
            {t(`join_myInfo_field3`)}
        </Typography>
        <Typography variant="body2" className={styles.joinMyInfoField}>
            {t(`join_myInfo_field4`)}
        </Typography>
        <Typography variant="body2" className={styles.joinMyInfoField}>
            {t(`join_myInfo_field5`)}
        </Typography>
        <Typography variant="body2" className={styles.joinMyInfoField}>
            {t(`join_myInfo_field6`)}
        </Typography>
        <Typography variant="body2" className={styles.joinMyInfoField}>
            {t(`join_myInfo_field7`)}
        </Typography>
    </>

    const joinPageBanner = (userState.membershipPlan?.data == null) ?
        skeletonJoinPageBanner(appState)
        :
        <Container className={styles.membershipPlanContainer}>
            <MembershipJoinPageContent mainImage={userState.membershipPlan.data.bannerTop} description={userState.membershipPlan.data.description} bottomImage={userState.membershipPlan.data.bannerBottom} />
            <MembershipPlanTNCsDialogDialog
                membershipPlan={userState.membershipPlan?.data}
                buttonText={t(`close`)}
                shouldShow={showMembershipTNCsDialog}
                onClose={() => setShowMembershipTNCsDialog(false)}
                onLinkTextClick={() => setShowMembershipTNCsDialog(true)}
            />
        </Container>

    return (
        <SplashScreenGuard>
            <Container>
                <CommonBackButton path={Route.ROOT} />
                <Grid container className={styles.gridContainer}>
                    {joinPageBanner}
                    <Divider />
                    <Grid item className={styles.retrieveButtonGridItem}>
                        <Box
                            component="img"
                            onClick={handleClick}
                            className={styles.retrieveButtonImage}
                            src={retrieveMyInfoButtonImage}
                        />
                    </Grid>
                    <Grid item>
                        {myInfoInfo}
                    </Grid>
                </Grid>
                <AlertDialog
                    alertText={t(`membershipPlanUsageLimitHit`)}
                    buttonText={t(`membershipPlanUsageLimitHitButton`)}
                    shouldShow={userState.membershipPlan?.data?.usageLeft == 0}
                    onClose={() => navigate(Route.MEMBERSHIP_JOIN)}
                />
            </Container>
        </SplashScreenGuard>
    )
}

export default Join
