import { useContext } from "react"
import TagManager from "react-gtm-module"

import { AppContext } from "../../stores/App/AppStore"
import GtmEventName from "./GtmEventName"
import GtmEventCategory from "./GtmEventCategory"
import GtmEventAction from "./GtmEventAction"
import { HighlightCarouselItem, Pillar, Programme } from "../../types"
import { DigitalResourceDetail } from "../../types/DigitalResourceDetail"

export interface GtmUserInfo {
    userName: string
    userAge?: number
    starterExchangeId?: string
    unionExchangeId?: string
}

const useGoogleTagManager = () => {
    const { state: appState } = useContext(AppContext)

    const setGtmUserInfo = (userInfo: GtmUserInfo) => {
        if (process.env.REACT_APP_STARTER_APP_ENV === `development`) {
            console.log(`GTM set data layer info: ${userInfo.userName} | ${userInfo.starterExchangeId} | ${userInfo.unionExchangeId}`)
        }

        TagManager.dataLayer({
            dataLayer: {
                userInfo,
            },
        })
    }

    const trackButtonClick = (name: GtmEventName, label?: string) => {
        trackEvent(name, GtmEventCategory.BUTTON, GtmEventAction.CLICK, label)
    }

    const trackDialogOpen = (name: GtmEventName, label?: string) => {
        trackEvent(name, GtmEventCategory.DIALOG, GtmEventAction.OPEN, label)
    }

    const trackDialogClose = (name: GtmEventName, label?: string) => {
        trackEvent(name, GtmEventCategory.DIALOG, GtmEventAction.CLOSE, label)
    }

    const trackAlertDialogOpen = (label?: string) => {
        trackEvent(GtmEventName.ALERT_DIALOG_OPEN, GtmEventCategory.DIALOG, GtmEventAction.OPEN, label)
    }

    const trackAlertDialogClose = (label?: string) => {
        trackEvent(GtmEventName.ALERT_DIALOG_CLOSE, GtmEventCategory.DIALOG, GtmEventAction.CLOSE, label)
    }

    const trackConfirmationDialogOpen = (label?: string) => {
        trackEvent(GtmEventName.CONFIRMATION_DIALOG_OPEN, GtmEventCategory.DIALOG, GtmEventAction.OPEN, label)
    }

    const trackConfirmationDialogDismiss = (label?: string) => {
        trackEvent(GtmEventName.CONFIRMATION_DIALOG_DISMISS, GtmEventCategory.DIALOG, GtmEventAction.DISMISS, label)
    }

    const trackConfirmationDialogConfirm = (label?: string) => {
        trackEvent(GtmEventName.CONFIRMATION_DIALOG_CONFIRM, GtmEventCategory.DIALOG, GtmEventAction.CONFIRM, label)
    }

    const trackHyperlinkClick = (label?: string) => {
        trackEvent(GtmEventName.REDIRECT, GtmEventCategory.HYPERLINK, GtmEventAction.CLICK, label)
    }

    const trackCarouselItemClick = (item: HighlightCarouselItem) => {
        trackEvent(GtmEventName.CAROUSEL_ITEM_CLICK, GtmEventCategory.CAROUSEL, GtmEventAction.CLICK, item.title)
    }

    const trackCarouselItemRedirect = (item: HighlightCarouselItem) => {
        trackEvent(GtmEventName.REDIRECT, GtmEventCategory.CAROUSEL, GtmEventAction.CLICK, item.url)
    }

    const trackCardClick = (name: GtmEventName, label?: string) => {
        trackEvent(name, GtmEventCategory.CARD, GtmEventAction.CLICK, label)
    }

    const trackProgrammeClick = (programme: Programme) => {
        trackCardClick(GtmEventName.PROGRAMME_CARD_CLICK, programme.name)
    }

    const trackProgrammeRedirect = (programme: Programme) => {
        trackEvent(GtmEventName.REDIRECT, GtmEventCategory.CARD, GtmEventAction.CLICK, programme.url)
    }

    const trackDigitalResourceClick = (digitalResource: DigitalResourceDetail) => {
        trackCardClick(GtmEventName.DIGITAL_RESOURCE_CLICK, digitalResource.title)
    }

    const trackDigitalResourceRedirect = (digitalResource: DigitalResourceDetail) => {
        trackEvent(GtmEventName.REDIRECT, GtmEventCategory.CARD, GtmEventAction.CLICK, digitalResource.externalLink)
    }

    const trackTextFieldProvided = (name: GtmEventName, label?: string) => {
        trackEvent(name, GtmEventCategory.TEXT_FIELD, GtmEventAction.PROVIDED, label)
    }

    const trackFormSubmit = (name: GtmEventName, label?: string) => {
        trackEvent(name, GtmEventCategory.FORM, GtmEventAction.SUBMIT, label)
    }

    const trackPromptClick = (name: GtmEventName, label?: string) => {
        trackEvent(name, GtmEventCategory.PROMPT, GtmEventAction.CLICK, label)
    }

    const trackMenuClick = (name: GtmEventName, label?: string) => {
        trackEvent(name, GtmEventCategory.MENU, GtmEventAction.CLICK, label)
    }

    const trackPillarClick = (pillar: Pillar) => {
        trackEvent(GtmEventName.PILLAR_CLICK, GtmEventCategory.PILLAR, GtmEventAction.CLICK, pillar.title)
    }

    const trackPillarRedirect = (pillar: Pillar) => {
        trackEvent(GtmEventName.REDIRECT, GtmEventCategory.PILLAR, GtmEventAction.CLICK, pillar.url)
    }

    const trackRedirectOnButtonClick = (link: string) => {
        trackEvent(GtmEventName.REDIRECT, GtmEventCategory.BUTTON, GtmEventAction.CLICK, link)
    }

    const trackFilterClick = (name: GtmEventName, label?: string) => {
        trackEvent(name, GtmEventCategory.FILTER, GtmEventAction.CLICK, label)
    }

    const trackEvent = (name: GtmEventName, category: GtmEventCategory, action: GtmEventAction, label?: string) => {
        if (process.env.REACT_APP_STARTER_APP_ENV === `development`) {
            const currentPageItemPrint = appState.currentPageItem ? ` | ${appState.currentPageItem}` : ``
            const labelPrint = label ? ` | ${label}` : ``
            console.log(`GTM Tagging: ${name} | ${appState.currentPageTitle}${currentPageItemPrint} | ${category} | ${action}${labelPrint}`)
        }

        TagManager.dataLayer({
            dataLayer: {
                event: name,
                pageTitle: appState.currentPageTitle,
                pageItem: appState.currentPageItem,
                eventProps: {
                    category,
                    action,
                    label,
                },
            },
        })
    }

    const trackPageView = (pageTitle: string, pageItem?: string) => {
        if (process.env.REACT_APP_STARTER_APP_ENV === `development`) {
            const pageItemPrint = pageItem ? ` | ${pageItem}` : ``
            console.log(`GTM Tagging: pageview | ${pageTitle}${pageItemPrint}`)
        }

        TagManager.dataLayer({
            dataLayer: {
                event: `pageview`,
                pageTitle,
                pageItem,
            },
        })
    }

    const sendUTMData = (
        utm_source: string | null,
        utm_medium: string | null,
        utm_campaign: string | null,
        utm_id: string | null,
        utm_term: string | null,
        utm_content: string | null,
        cid: string | null,
    ) => {
        TagManager.dataLayer({
            dataLayer: {
                event: `utmDataSend`,
                utm_source: utm_source,
                utm_medium: utm_medium,
                utm_campaign: utm_campaign,
                utm_id: utm_id,
                utm_term: utm_term,
                utm_content: utm_content,
                cid: cid,
            },
        })
    }

    return {
        setGtmUserInfo,
        trackEvent,
        trackButtonClick,
        trackDialogOpen,
        trackDialogClose,
        trackAlertDialogOpen,
        trackAlertDialogClose,
        trackConfirmationDialogOpen,
        trackConfirmationDialogDismiss,
        trackConfirmationDialogConfirm,
        trackHyperlinkClick,
        trackCarouselItemClick,
        trackCarouselItemRedirect,
        trackCardClick,
        trackProgrammeClick,
        trackProgrammeRedirect,
        trackDigitalResourceClick,
        trackDigitalResourceRedirect,
        trackTextFieldProvided,
        trackFormSubmit,
        trackPromptClick,
        trackMenuClick,
        trackPillarClick,
        trackPillarRedirect,
        trackRedirectOnButtonClick,
        trackFilterClick,
        trackPageView,
        sendUTMData,
    }
}

export default useGoogleTagManager