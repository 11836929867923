import { Box, Link, Typography, useTheme } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

import { hexToRGB, rgbToString } from "../../../helpers/utils/colors"
import LocalStorageKeys from "../../../types/LocalStorageKeys"
import { Route, STRAPI_URL } from "../../../router"
import { MyInfoReason } from "../../../types/MyInfoUserData"
import { MembershipType } from "../../../types/MembershipType"
import { domainCheckMembershipType } from "../../../helpers/utils/common"
import SignUpButton from "../../atoms/SignUpButton/SignUpButton"
import ActivateMembershipButton from "../../atoms/ActivateMembershipButton/ActivateMembershipButton"
import { useContext } from "react"
import { UserContext } from "../../../stores/User/UserStore"
import useGoogleTagManager from '../../../helpers/analytics/use-google-tag-manager'
import GtmEventName from '../../../helpers/analytics/GtmEventName'

import styles from "./LandingFooter.module.scss"

const UNION_SIGNUP_URL = process.env.REACT_APP_UNION_SIGNUP_URL ?? `https://www.ntuc.org.sg/Uportal/Home/Memberships/Become%20a%20member`

const LandingFooter = () => {
    const { t } = useTranslation()

    const navigate = useNavigate()
    const { trackButtonClick } = useGoogleTagManager()

    const { palette } = useTheme()
    const { state: userState } = useContext(UserContext)

    const membershipTypeFromDomainName = domainCheckMembershipType()
    const partner = userState.partner?.data

    const handleSignUpButtonClick = () => {
        trackButtonClick(GtmEventName.SIGN_UP_CLICK, `Landing footer sign up button`)

        if (membershipTypeFromDomainName === MembershipType.UPME) {
            window.location.replace(UNION_SIGNUP_URL)
        } else {
            localStorage.setItem(LocalStorageKeys.MYINFO_REASON, MyInfoReason.SIGNUP)
            window.location.href = `${STRAPI_URL}${Route.MYINFO_AUTHORISE}`
        }
    }

    const handleLoginButtonClick = () => {
        trackButtonClick(GtmEventName.SIGN_IN_CLICK, `Landing footer sign in button`)
        navigate(Route.PLS_LOGIN)
    }

    const endColorRgb = hexToRGB(palette.surface.secondary.dark)
    endColorRgb.a = 0.9

    const footerStyle = {
        background: `linear-gradient(180deg, ${palette.surface.secondary.main} 0%, ${rgbToString(endColorRgb)} 100%)`,
    }

    return (
        <Box className={styles.landingFooter} sx={footerStyle}>
            <Box className={styles.subtitle}>
                <Typography variant="subtitle2" color='primary' mb={1}>{t(`landing_page_footer_offer`)}</Typography>
            </Box>
            <Box className={styles.actionBox}>
                {
                    partner ?
                        <ActivateMembershipButton onClick={handleSignUpButtonClick} fullWidth /> :
                        <SignUpButton onClick={handleSignUpButtonClick} fullWidth />
                }
                <Box mt={1}>
                    <Link color="inherit" onClick={handleLoginButtonClick}>{t(`landing_page_login`)}</Link>
                </Box>
            </Box>
        </Box>
    )
}

export default LandingFooter