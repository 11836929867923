export enum LocalStorageKeys {
    DEBUG_MODE = `debugMode`,
    DISPLAY_INSTALLATION_INSTRUCTIONS = `displayedInstallationInstructions`,
    MEMBERSHIP_PLAN = `membershipPlan`,
    PARTNER = `partner`,
    VISITED_PARTNER_PAGE = `visitedPartnerPage`,
    CLIENT_ORDER_ID_KEY = `clientOrderId`,
    MYINFO_REASON = `myinfoReason`,
    REFERRAL_CODE = `referralCode`,
    USER_JUST_BECAME_UNION_MEMBER = `userJustBecameUnionMember`,
    DISPLAYED_WELCOME_UNION_MEMBER_DIALOG = `displayedWelcomeUnionMemberDialog`,
    USER_SESSION_KEY = `userSessionKey`,
    VISITED_PROFILE_UPDATE = `profileUpdate`,
    LAST_MENU_PAGE = `lastMenuPage`,
}

export default LocalStorageKeys