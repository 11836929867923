import { Box, Container, Skeleton } from "@mui/material"

import CommonBackButton from "../../../atoms/CommonBackButton/CommonBackButton"
import MarkdownContent from "../../../atoms/MarkdownContent/MarkdownContent"
import { Route } from "../../../../router"

import styles from "./DigitalResourceDetails.module.scss"
import clsx from "clsx"
import AppCardSkeleton from "../../../atoms/AppCard/AppCard.skeleton"

const DigitalResourceDetailsSkeleton = () => {
    const moreInfoTitleStyle = clsx(
        styles.moreInfoTitle,
        styles.skeleton,
    )

    return (
        <Container className={styles.container}>
            <Box className={styles.skeletonBackButtonBox}>
                <CommonBackButton path={Route.HOME} />
            </Box>

            <AppCardSkeleton />

            <Skeleton className={moreInfoTitleStyle} variant="rectangular" />

            <MarkdownContent />
        </Container>
    )
}

export default DigitalResourceDetailsSkeleton