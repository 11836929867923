import { anonymousClient, authenticatedClient } from './util'
import { HTTP_SUCCESS } from '../helpers/utils/http-status'
import { MembershipType } from '../types/MembershipType'
import ProgrammeApplicationSubmission, { programmeApplicationSubmissionPayload } from '../types/ProgrammeApplicationSubmission'
import FormFile from '../types/FormFile'
import { appendFileToFormData } from '../helpers/utils/common'
import slugify from 'slugify'
import { AxiosProgressEvent } from 'axios'

const fetchProgrammeApplicationForms = async (membershipType: MembershipType, isAuthenticated: boolean) => {
    const client = isAuthenticated ? authenticatedClient() : anonymousClient()

    return client.get(`/api/programme-application-forms?populate[files]=*&populate[questions][populate]=options&populate[image]=*&filters[membershipScope]=${membershipType}`).then(res => {
        if (res.status !== HTTP_SUCCESS) {
            throw new Error(`Could not fetch programme application forms`)
        }
        return res.data
    })
}

const fetchProgrammeApplicationForm = async (id: number, isAuthenticated: boolean) => {
    const client = isAuthenticated ? authenticatedClient() : anonymousClient()

    return client.get(`/api/programme-application-forms/${id}?populate[files]=*&populate[questions][populate]=options&populate[image]=*`).then(res => {
        if (res.status !== HTTP_SUCCESS) {
            throw new Error(`Could not fetch programme application form by id`)
        }
        return res.data
    })
}

const submitProgrammeApplication = async (programmeApplicationSubmission: ProgrammeApplicationSubmission, progressListener?: (progressPercent: number) => void) => {
    const multipartFormHeader = {
        headers: {
            'Content-Type': `multipart/form-data`,
        },
        onUploadProgress: (progressEvent: AxiosProgressEvent) => {
            if (progressEvent.total) {
                // eslint-disable-next-line no-magic-numbers
                const progressPercent = Math.max(Math.round(progressEvent.loaded * 100 / progressEvent.total), 1)
                progressListener?.(progressPercent)
            } else {
                console.error(`Total request length is unknown`)
            }
        },
    }

    return authenticatedClient()
        .post(
            `/api/programme-application-submissions`,
            createProgrammeApplicationSubmissionData(programmeApplicationSubmission),
            multipartFormHeader,
        ).then(res => {
            if (res.status !== HTTP_SUCCESS) {
                throw new Error(`Could not submit application form`)
            }
            return res
        }).then(res => res.data)
}

const createProgrammeApplicationSubmissionData = (programmeApplicationSubmission: ProgrammeApplicationSubmission): FormData | undefined => {
    const formData = new FormData()

    const payload = programmeApplicationSubmissionPayload(programmeApplicationSubmission)

    formData.append(`responses`, JSON.stringify(payload.responses))
    formData.append(`programmeApplicationFormId`, `${payload.programmeApplicationFormId}`)

    programmeApplicationSubmission.files.forEach((file: FormFile) => {
        if (file.value) {
            appendFileToFormData(formData, slugify(file.name, { lower: true }), file.value)
        }
    })

    return formData
}

export {
    fetchProgrammeApplicationForms,
    fetchProgrammeApplicationForm,
    submitProgrammeApplication,
}