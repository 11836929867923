import { fetchStarterOnboardingCarousel } from '../../clients/starter-onboarding-carousel'
import fromStrapiHighlightCarouselItem from '../../helpers/mapping/highlight-carousel-item'
import { HighlightCarouselItem } from '../../types/HighlightCarouselItem'

class UPMEOnboardingRepository {

    async retrieveStarterOnboardingCarousel(): Promise<HighlightCarouselItem[]> {
        const starterOnboardingCarouselResponse = await fetchStarterOnboardingCarousel()
        const carouselItems = starterOnboardingCarouselResponse.map(fromStrapiHighlightCarouselItem)
        return carouselItems
    }

}

export default UPMEOnboardingRepository