import { useContext } from 'react'

import { MembershipType } from '../../types/MembershipType'
import { ServiceResponse } from "../../types"
import { ProgrammeApplicationForm } from "../../types/ProgrammeApplicationForm"
import ProgrammeApplicationFormRepository from './programme-application-form-repository'
import { ProgrammeContext } from '../../stores/Programme/ProgrammeStore'
import { ProgrammeActions } from '../../stores/Programme/ProgrammeReducer'
import { starterDataError } from '../../helpers/utils/error-handling'
import ProgrammeApplicationSubmission from '../../types/ProgrammeApplicationSubmission'

const useProgrammeApplicationFormRepository = () => {
    const { state: programmeState, dispatch } = useContext(ProgrammeContext)
    const programmeApplicationFormRepository = new ProgrammeApplicationFormRepository()

    const retrieveProgrammeApplicationForms = (membershipType: MembershipType, isAuthenticated: boolean) => {
        const programmeApplicationFormsResponse: ServiceResponse<ProgrammeApplicationForm[]> = {}

        programmeApplicationFormRepository.retrieveProgrammeApplicationForms(membershipType, isAuthenticated)
            .then((programmeApplicationForms) => {
                programmeApplicationFormsResponse.data = programmeApplicationForms
                return programmeApplicationFormsResponse
            })
            .catch((error) => {
                programmeApplicationFormsResponse.error = starterDataError(error)
            })
            .finally(() => {
                dispatch({
                    type: ProgrammeActions.PROGRAMME_APPLICATION_FORMS_RETRIEVED,
                    programmeApplicationForms: programmeApplicationFormsResponse,
                })
            })
    }

    const retrieveProgrammeApplicationForm = (id: number, isAuthenticated: boolean) => {
        const programmeApplicationFormByIdResponse: ServiceResponse<ProgrammeApplicationForm> = {}

        programmeApplicationFormRepository.retrieveProgrammeApplicationForm(id, isAuthenticated)
            .then((programmeApplicationForm) => {
                programmeApplicationFormByIdResponse.data = programmeApplicationForm
                return programmeApplicationFormByIdResponse
            })
            .catch((error) => {
                programmeApplicationFormByIdResponse.error = starterDataError(error)
            })
            .finally(() => {
                dispatch({
                    type: ProgrammeActions.PROGRAMME_APPLICATION_FORM_RETRIEVED,
                    programmeApplicationForm: programmeApplicationFormByIdResponse,
                })
            })
    }

    const getProgrammeApplicationForm = (id: number | string | undefined): ProgrammeApplicationForm | undefined => {
        if (!id && !parseInt(`${id}`)) {
            return
        }

        const programmeApplicationFormId = parseInt(`${id}`)

        if (programmeState.programmeApplicationForms?.data?.length) {
            const programmeApplicationForm = programmeState.programmeApplicationForms.data.find((programmeApplicationForm) => programmeApplicationForm.id === programmeApplicationFormId)

            if (programmeApplicationForm) {
                return programmeApplicationForm
            }
        }
    }

    const submitProgrammeApplication = async (programmeApplicationSubmission: ProgrammeApplicationSubmission, progressListener?: (progressPercent: number) => void) => {
        return programmeApplicationFormRepository.submitProgrammeApplication(programmeApplicationSubmission, progressListener)
            .catch((error) => {
                throw starterDataError(error)
            })
    }

    return {
        retrieveProgrammeApplicationForms,
        retrieveProgrammeApplicationForm,
        getProgrammeApplicationForm,
        submitProgrammeApplication,
    }

}

export default useProgrammeApplicationFormRepository