import CustomFileInput, { FileType } from "../../../atoms/CustomFileInput/CustomFileInput"
import CommonBackButton from "../../../atoms/CommonBackButton/CommonBackButton"
import { Box, Container } from "@mui/system"
import { useTranslation } from "react-i18next"
import { Avatar, useTheme } from "@mui/material"
import { useContext, useEffect, useState } from "react"
import { LoadingButton } from "@mui/lab"
import { UserContext } from "../../../../stores/User/UserStore"
import AlertDialog from "../../../molecules/AlertDialog/AlertDialog"
import { ProfilePictures, getPictureResizes } from "./ProfilePictureSelection.utils"
import { useMembershipRepository } from "../../../../repositories/Membership/use-membership-repository"
import { ServiceResponseError } from "../../../../types/RemoteData"
import StarterErrorCodes from "../../../../types/ErrorCodes"
import SplashScreenGuard from "../../../../router/guards/SplashScreenGuard"
import { starterDataError } from "../../../../helpers/utils/error-handling"
import useTrackPageView from "../../../../helpers/hooks/use-track-page-view"
import useGoogleTagManager from '../../../../helpers/analytics/use-google-tag-manager'
import GtmEventName from '../../../../helpers/analytics/GtmEventName'

import styles from './ProfilePictureSelection.module.scss'

const ProfilePictureSelection = () => {
    useTrackPageView(`Profile Picture Page`)

    const { t } = useTranslation()
    const { palette } = useTheme()
    const { trackButtonClick } = useGoogleTagManager()

    const { state: userState } = useContext(UserContext)
    const { uploadProfilePicture, retrieveProfilePicture } = useMembershipRepository()

    const [profilePictures, setProfilePictures] = useState<ProfilePictures>()
    const [isLoading, setIsLoading] = useState(false)
    const [dialogMessage, setDialogMessage] = useState<string | undefined>()

    useEffect(() => {
        retrieveProfilePicture()
    }, [])

    const handlePictureFileChange = (file?: File) => {
        trackButtonClick(GtmEventName.CHOOSE_PICTURE_CLICK)

        getPictureResizes(file).then((profilePictures: ProfilePictures) => {
            setProfilePictures(profilePictures)
        })
    }

    const handleConfirmClick = () => {
        trackButtonClick(GtmEventName.CONFIRM_PICTURE_CLICK)

        setIsLoading(true)

        if (profilePictures) {
            uploadProfilePicture(profilePictures)
                .then(() => {
                    setIsLoading(false)
                    setDialogMessage(t(`profile_picture_successfully_updated`))
                    setProfilePictures(undefined)
                    retrieveProfilePicture()
                })
                .catch((error) => {
                    const serviceResponseError: ServiceResponseError = starterDataError(error)

                    if (serviceResponseError.errorCode === StarterErrorCodes.FILE_IS_TOO_BIG) {
                        setDialogMessage(t(`profile_picture_file_too_big_error_message`))
                    } else {
                        setDialogMessage(t(`error_default_message`))
                    }
                })
                .finally(() => {
                    setIsLoading(false)
                })
        }
    }

    const handlDialogClose = () => {
        setDialogMessage(undefined)
    }

    const avatarStyle = {
        borderColor: palette.functional?.border.main,
    }

    const fileSectionButtonLabel = userState.profilePicture?.data?.dataUrl ? t(`profile_picture_change_file`) : t(`profile_picture_select_file`)

    const displayedProfilePicture = profilePictures?.original.dataUrl ?? userState.profilePicture?.data?.dataUrl ?? userState.membership?.data?.profilePictureThumbnail

    return (
        <SplashScreenGuard>
            <Container className={styles.container}>
                <CommonBackButton />
                <Box className={styles.pageContent}>
                    <Box className={styles.avatarBox}>
                        <Avatar className={styles.avatar} sx={avatarStyle} src={displayedProfilePicture} />
                    </Box>
                    <Box className={styles.controls}>
                        <CustomFileInput className={styles.fileInputButton} label='Profile Picture' buttonLabel={fileSectionButtonLabel} allowedFileTypes={FileType.IMAGE_JPG} onChange={handlePictureFileChange} insetLabel hideFilename />
                        <LoadingButton variant='outlined' className={styles.confirmButton} loading={isLoading} onClick={handleConfirmClick} disabled={!profilePictures}>{t(`confirm`)}</LoadingButton>
                    </Box>
                </Box>
                <AlertDialog shouldShow={!!dialogMessage} alertText={dialogMessage} onClose={handlDialogClose} />
            </Container>
        </SplashScreenGuard>
    )
}

export default ProfilePictureSelection