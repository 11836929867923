import { useState } from 'react'
import { Box, MobileStepper, useTheme } from '@mui/material'
import SwipeableViews from 'react-swipeable-views'
import { autoPlay } from 'react-swipeable-views-utils'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

import Yellow from '../../../assets/carousel_yellow.jpg'
import Blue from '../../../assets/carousel_blue.jpg'
import Red from '../../../assets/carousel_red.jpg'
import Green from '../../../assets/carousel_green.jpg'
import theme from '../../../themes/starter'
import HighlightCarouselSkeleton from './HighlightCarousel.skeleton'
import HighlightCarouselItemComponent, { HighlightCarouselItemImageAspectRatio } from '../HighlightCarouselItemComponent/HighlightCarouselItemComponent'
import { HighlightCarouselItem } from '../../../types/HighlightCarouselItem'

import styles from './HighlightCarousel.module.scss'

const AutoPlaySwipeableViews = autoPlay(SwipeableViews)

interface HighlightCarouselProps {
    highlightCarouselItems?: HighlightCarouselItem[],
    autoplay?: boolean,
    aspectRatio?: HighlightCarouselItemImageAspectRatio,
    onCarouselItemClick?: (item: HighlightCarouselItem) => void,
    showBackground: boolean,
    useBlackFontColor?: boolean,
    hideMobileStepper?: boolean,
}

const HighlightCarousel = (props: HighlightCarouselProps) => {
    const { highlightCarouselItems, autoplay, aspectRatio = HighlightCarouselItemImageAspectRatio.RECTANGLE, onCarouselItemClick, showBackground, hideMobileStepper = false, useBlackFontColor = false } = props

    const { palette } = useTheme()

    const BackgroundColors = {
        YELLOW: {
            backgroundImage: Yellow,
            color: palette.primary.main,
        },
        BLUE: {
            backgroundImage: Blue,
            color: `#3333FF`,
        },
        RED: {
            backgroundImage: Red,
            color: `#EC008C`,
        },
        GREEN: {
            backgroundImage: Green,
            color: `#046E3B`,
        },
    }

    const backgrounds = [
        BackgroundColors.YELLOW,
        BackgroundColors.BLUE,
        BackgroundColors.RED,
        BackgroundColors.GREEN,
    ]

    const [activeStep, setActiveStep] = useState(0)
    const [background, setBackground] = useState(backgrounds[0])

    if (!highlightCarouselItems || highlightCarouselItems.length === 0) {
        return <HighlightCarouselSkeleton aspectRatio={aspectRatio} />
    }

    const handleStepChange = (step: number) => {
        let backgroundIndex = step
        if (step >= backgrounds.length) {
            backgroundIndex = ((step + 1) % backgrounds.length) - 1
            setBackground(backgrounds[backgroundIndex])
        } else {
            setBackground(backgrounds[step])
        }
        setActiveStep(step)
    }

    const handlePreviousStepClick = () => {
        let backgroundIndex = activeStep
        if (activeStep >= 1) {
            backgroundIndex = (activeStep - 1 + backgrounds.length) % backgrounds.length
            setBackground(backgrounds[backgroundIndex])
        }
        setActiveStep(Math.max(activeStep - 1, 0))
    }

    const handleNextStepClick = () => {
        let backgroundIndex = activeStep
        if (activeStep < highlightCarouselItems.length - 1) {
            backgroundIndex = (activeStep + 1) % backgrounds.length
            setBackground(backgrounds[backgroundIndex])
        }
        setActiveStep(Math.min(activeStep + 1, highlightCarouselItems.length - 1))
    }

    const handleOnCarouselItemClick = (item: HighlightCarouselItem) => {
        onCarouselItemClick?.(item)
    }

    const mapHighlightCarouselItem = (item: HighlightCarouselItem) => {
        return (
            <Box className={styles.itemContainer} key={`carousel-item-${item.id}`}>
                <Box className={styles.itemSlide}>
                    <HighlightCarouselItemComponent highlightCarouselItem={item} onCarouselItemClick={handleOnCarouselItemClick} aspectRatio={aspectRatio} showBackground={showBackground} fontColor={background.color} useBlackFontColor={useBlackFontColor} />
                </Box>
            </Box>
        )
    }

    const autoPlayAxis = theme.direction === `rtl` ? `x-reverse` : `x`

    const backgroundBasedOnActiveStep = showBackground ? { backgroundImage: `url('${background.backgroundImage}')`, backgroundSize: `cover`, backgroundPosition: `center`, width: `100% !important` } : { backgroundImage: `transparent` }

    const dotStyle = useBlackFontColor ? {
        '& .MuiMobileStepper-dotActive': {
            backgroundColor: `black`,
            borderColor: `black`,
        },
        '& .MuiMobileStepper-dot': {
            borderColor: `black`,
        },
    } : null

    return (
        <Box className={styles.carousel} sx={backgroundBasedOnActiveStep}>
            <AutoPlaySwipeableViews
                className={styles.swipeableViews}
                axis={autoPlayAxis}
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents
                autoplay={!!autoplay}
            >
                {highlightCarouselItems.map(mapHighlightCarouselItem)}
            </AutoPlaySwipeableViews>
            <MobileStepper
                className={styles.mobileStepper}
                sx={dotStyle}
                steps={highlightCarouselItems?.length}
                position={`static`}
                activeStep={activeStep}
                nextButton={hideMobileStepper ? !hideMobileStepper : <ChevronRightIcon color='primary' onClick={handleNextStepClick} />}
                backButton={hideMobileStepper ? !hideMobileStepper : <ChevronLeftIcon color='primary' onClick={handlePreviousStepClick} />}
            />
        </Box>
    )
}

export default HighlightCarousel