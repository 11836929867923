import { UpmeLimitedDigitalResourceDetailByCategory } from "../../types/LimitedDigitalResourceDetailByCategory"
import { Programme, getProgrammeRoute } from "../../types/Programme"

export const programmesMenuMapping = (programmesList: Programme[]) => {
    return programmesList.map(item => {
        const route = item.name ? getProgrammeRoute(item) : ``

        if (route && item.name) {
            return {
                name: item.name,
                route,
            }
        } else {
            return { name: ``, route: `` }
        }
    })
}

export const resourceMenuMapping = (categoryList: UpmeLimitedDigitalResourceDetailByCategory[]) => {
    return categoryList.map((category: UpmeLimitedDigitalResourceDetailByCategory) => {
        return {
            name: category.name,
            route: `/upme/digital-resource/${category.name}`,
        }
    })
}