import { useContext } from 'react'
import { Location } from 'react-router'

import SessionRepository from './session.repository'
import { UserActionTypes } from '../../stores/User/UserReducer'
import { UserContext } from '../../stores/User/UserStore'
import { SessionResponse } from '../../types/Session'
import MembershipRepository from '../Membership/membership.repository'
import { starterDataError } from '../../helpers/utils/error-handling'

const useSessionRepository = () => {
    const { state: userState, dispatch } = useContext(UserContext)
    const sessionRepository = new SessionRepository()
    const membershipRepository = new MembershipRepository()
    const sessionResponse: SessionResponse = {}

    const retrieveSession = (location: Location) => {
        sessionRepository.retrieve(location)
            .then(session => {
                if (session.userBecameUnionMember) {
                    membershipRepository.setUserJustBecameUnionMember()
                }

                sessionResponse.data = session
            })
            .catch(error => {
                sessionResponse.error = starterDataError(error)
            })
            .finally(() => {
                dispatch({
                    type: UserActionTypes.LOGIN,
                    session: sessionResponse,
                })
            })
    }

    const saveJwtAsSession = (jwt: string) => {
        const session = sessionRepository.setJwt(jwt)
        const sessionResponse: SessionResponse = {
            data: session,
        }
        dispatch({ type: UserActionTypes.LOGIN, session: sessionResponse })
    }

    const disposeSession = () => {
        sessionRepository.remove()
        dispatch({ type: UserActionTypes.LOGOUT })
    }

    const isAuthenticated = (): boolean => {
        return !!userState.session && !userState.session?.error
    }

    const isMember = async (): Promise<boolean> => {
        if (userState.session && userState.membership) {
            if (userState.membership?.data?.id) {
                return true
            }
        }

        return false
    }

    return {
        retrieveSession,
        saveJwtAsSession,
        disposeSession,
        isAuthenticated,
        isMember,
    }
}

export { useSessionRepository }