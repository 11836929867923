import { HTTP_SUCCESS } from "../helpers/utils/http-status"
import { anonymousClient } from "./util"

const fetchStarterOnboardingCarousel = async () => {
    return anonymousClient()
        .get(
            `/api/starter-onboarding-carousel?populate[items][populate][0]=image`,
        )
        .then(res => {
            if (res.status !== HTTP_SUCCESS) {
                throw new Error(`Could not Starter onboarding carousel`)
            }
            return res.data
        })
        .then(res => res.data.attributes.items)
}

export {
    fetchStarterOnboardingCarousel,
}