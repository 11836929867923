import { Box, Button, Container, Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import CommonBackButton from '../../../atoms/CommonBackButton/CommonBackButton'
import Spacer from '../../../atoms/Spacer/Spacer'
import BannerImage from '../../../atoms/BannerImage/BannerImage'
import taskImage from '../../../../assets/task.png'
import ErrorDialog from '../../../molecules/ErrorDialog/ErrorDialog'
import useMemberGuard from '../../../../router/guards/member-guard'
import { Route } from '../../../../router'
import { useAuthenticatedGuard } from '../../../../router/guards/authenticated-guard'
import { errorPopupMessageTemplate, generateErrorCode } from '../../../../helpers/logging/templates'
import { logAction } from '../../../../clients/logger'
import useTrackPageView from '../../../../helpers/hooks/use-track-page-view'
import useGoogleTagManager from '../../../../helpers/analytics/use-google-tag-manager'
import GtmEventName from '../../../../helpers/analytics/GtmEventName'

import styles from './Success.module.css'

const Success = () => {
    useTrackPageView(`Utap Claim Success Page`)

    useAuthenticatedGuard(Route.UTAP_CLAIM_SUCCESS)
    useMemberGuard()
    const { trackButtonClick } = useGoogleTagManager()

    const { t } = useTranslation()
    const navigate = useNavigate()
    const { id: claimReferenceNumber } = useParams()

    const handleHomeButtonClick = () => {
        trackButtonClick(GtmEventName.UTAP_SUCCESS_BACK_HOME_CLICK)
        return navigate(Route.HOME)
    }

    if (!claimReferenceNumber) {
        logAction(`error`, errorPopupMessageTemplate(`Missing Claim Reference Number, error code: ${generateErrorCode()}`))
        return <ErrorDialog acknowledgeOnly defaultOpen />
    }

    const contentHeading = t(`utap_claim_success_title`)
    const contentSubHeading = t(`utap_claim_success_description`)
    const referenceText = t(`payment_result_reference_text`)

    return (
        <Container>
            <CommonBackButton path={Route.UTAP_LANDING} />

            <Grid container className={styles.gridContainer}>
                <Grid container className={styles.claimSuccessContent}>
                    <Box className={styles.claimSuccessBannerImage}>
                        <BannerImage imageUrl={taskImage} className="App-logo" alt="logo" />
                    </Box>
                    <Typography variant={`h5`} mb={1}>{contentHeading}</Typography>
                    <Typography variant={`subtitle1`}>{contentSubHeading}</Typography>
                    <Grid>
                        <Typography variant={`subtitle1`}>{referenceText}</Typography>
                        <Typography variant={`subtitle1`}>{claimReferenceNumber}</Typography>
                    </Grid>
                </Grid>

                <Spacer flexNumber={2} />

                <Grid className={styles.claimSuccessButtonContainer}>
                    <Button variant='contained' onClick={handleHomeButtonClick} fullWidth>{t(`common_back_to_home`)}</Button>
                </Grid>
            </Grid>
        </Container>
    )
}

export default Success