import { fetchCuratedExperience, fetchCuratedExperiences, fetchCuratedExperiencesPage } from '../../clients/pillars'
import { CuratedExperienceDetail, CuratedExperiencePage } from '../../types'
import { fromStrapiCuratedExperience, fromStrapiCuratedExperiencePage } from '../../helpers/mapping/curatedExperience'

class CuratedExperienceRepository {

    async getAll(): Promise<CuratedExperienceDetail[]> {
        const { data } = await fetchCuratedExperiences()
        return data.map((i: unknown) => fromStrapiCuratedExperience(i))
    }

    async get(id: number): Promise<CuratedExperienceDetail> {
        const { data } = await fetchCuratedExperience(id)
        return fromStrapiCuratedExperience(data)
    }

    async getPage(): Promise<CuratedExperiencePage> {
        const { data } = await fetchCuratedExperiencesPage()
        return fromStrapiCuratedExperiencePage(data)
    }
}

export default CuratedExperienceRepository