import { ServiceResponse } from "../../types"
import { HighlightCarouselItem } from "../../types/HighlightCarouselItem"
import { UserInterestTopic } from "../../types/UserInterestTopic"

type MembershipState = {
    userInterestTopics?: ServiceResponse<UserInterestTopic[]>
    starterOnboardingCarousel?: ServiceResponse<HighlightCarouselItem[]>
}

const MembershipInitialState: MembershipState = {
    userInterestTopics: undefined,
    starterOnboardingCarousel: undefined,
}

export { MembershipInitialState }
export type { MembershipState }