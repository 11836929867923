import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Programme } from "../../../types/Programme"
import ProgrammeCard from '../../molecules/ProgrammeCard/ProgrammeCard'

import { UserContext } from "../../../stores/User/UserStore"
import HorizontalScrollComponent from "../../molecules/HorizontalScrollComponent/HorizontalScrollComponent"

interface ProgrammesHomeListProps {
    programmes?: Programme[]
}

const ProgrammesList = (props: ProgrammesHomeListProps) => {
    const { programmes } = props
    const { t } = useTranslation()

    const { state: userState } = useContext(UserContext)

    const renderProgrammeCard = (programme: Programme) => (
        <ProgrammeCard key={programme.id} programme={programme} />
    )

    return <HorizontalScrollComponent items={programmes} listTitle={t(`programmes`)} renderItem={renderProgrammeCard} isLandingPage={!!userState.membership?.data} />
}

export default ProgrammesList