import placeholderImage from '../../assets/placeholder.png'

export const isLocalhostStrapi = () => process.env.REACT_APP_STRAPI_URL?.includes(`localhost`)

export const absoluteImageUrl = (imageUrl: string) => {
    if (!imageUrl) {
        return placeholderImage
    }

    return isLocalhostStrapi() ? `${process.env.REACT_APP_STRAPI_URL}${imageUrl}` : imageUrl
}