import { useContext, useEffect } from 'react'
import { Button, Container, Grid, Typography } from '@mui/material'
import { Navigate, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { v4 as uuidv4 } from 'uuid'

import Spacer from '../../../atoms/Spacer/Spacer'
import CustomInput from '../../../atoms/CustomInput/CustomInput'
import CommonBackButton from '../../../atoms/CommonBackButton/CommonBackButton'
import useMemberGuard from '../../../../router/guards/member-guard'
import LocalStorageKeys from '../../../../types/LocalStorageKeys'
import { MyInfoReason } from '../../../../types/MyInfoUserData'
import { useAuthenticatedGuard } from '../../../../router/guards/authenticated-guard'
import { Route, STRAPI_URL } from '../../../../router'
import { UserContext } from '../../../../stores/User/UserStore'
import { MembershipStatus } from '../../../../types/MembershipStatus'
import { PaymentOrderReason } from '../../../../types/PaymentOrderReason'
import { useMembershipRepository } from '../../../../repositories/Membership/use-membership-repository'
import useTrackPageView from "../../../../helpers/hooks/use-track-page-view"
import useGoogleTagManager from "../../../../helpers/analytics/use-google-tag-manager"
import GtmEventName from "../../../../helpers/analytics/GtmEventName"

import styles from './Draft.module.css'

const Draft = () => {
    useAuthenticatedGuard(Route.MEMBERSHIP_DRAFT)
    useMemberGuard()

    useTrackPageView(`Draft Page`)

    const { t } = useTranslation()
    const navigate = useNavigate()
    const { trackButtonClick } = useGoogleTagManager()

    const { state: userState } = useContext(UserContext)
    const { retrieveMembership } = useMembershipRepository()
    const userMembership = userState.membership?.data
    const hasActiveMembership = userMembership?.status === MembershipStatus.ACTIVE

    useEffect(() => {
        retrieveMembership()
        window.scrollTo(0, 0)
    }, [])

    if (hasActiveMembership) {
        return <Navigate to={Route.HOME} />
    }

    const handleUpdateInformationButtonClick = () => {
        trackButtonClick(GtmEventName.DRAFT_UPDATE_INFO_CLICK)
        localStorage.setItem(LocalStorageKeys.MYINFO_REASON, MyInfoReason.SIGNUP)
        window.location.href = `${STRAPI_URL}${Route.MYINFO_AUTHORISE}`
    }

    const handleContinueApplicationButtonClick = () => {
        trackButtonClick(GtmEventName.DRAFT_CONTINUE_CLICK)
        if (userMembership?.id) {
            navigate(`/membership/payment-summary/${PaymentOrderReason.SIGNUP}`)
        }
    }

    const titleText = userMembership?.status === MembershipStatus.TERMINATED ? t(`draft_sign_up_title_terminated`) : t(`draft_sign_up_title`)
    const subTitleText = userMembership?.status === MembershipStatus.TERMINATED ? t(`draft_sign_up_subtitle_terminated`) : t(`draft_sign_up_subtitle`)

    return (
        <Container>
            <Grid container direction="column" spacing={1}>
                <CommonBackButton path={Route.HOME} />
                <Grid item>
                    <Typography variant="h6" mb={1} >{titleText}</Typography>
                    <Typography variant="subtitle1" mb={2} >{subTitleText}</Typography>
                </Grid>
                <Grid item>
                    <CustomInput
                        fullWidth
                        label={t(`sign_up_name`)}
                        readOnly
                        value={userMembership?.fullName ?? ``}
                        key={uuidv4()}
                        isSignUp={true}
                    />
                    <CustomInput
                        fullWidth
                        label={t(`sign_up_name_ecard`)}
                        readOnly
                        value={userMembership?.eCardName ?? ``}
                        key={uuidv4()}
                        isSignUp={true}
                    />
                    <CustomInput
                        fullWidth
                        label={t(`sign_up_birthdate`)}
                        readOnly
                        value={userMembership?.dateOfBirth?.toString() ?? ``}
                        key={uuidv4()}
                        isSignUp={true}
                    />
                    <CustomInput
                        fullWidth
                        label={t(`sign_up_gender`)}
                        readOnly
                        value={userMembership?.gender ?? ``}
                        key={uuidv4()}
                        isSignUp={true}
                    />
                    <CustomInput
                        fullWidth
                        label={t(`sign_up_resident_status`)}
                        readOnly
                        value={userMembership?.residentialStatus ?? ``}
                        key={uuidv4()}
                        isSignUp={true}
                    />
                    <CustomInput
                        fullWidth
                        label={t(`sign_up_phone_number`)}
                        readOnly
                        value={userMembership?.phoneNumber ?? ``}
                        key={uuidv4()}
                        isSignUp={true}
                    />
                    <CustomInput
                        fullWidth
                        label={t(`sign_up_email_address`)}
                        readOnly
                        value={userMembership?.emailAddress ?? ``}
                        key={uuidv4()}
                        isSignUp={true}
                    />
                    <CustomInput
                        fullWidth
                        label={t(`sign_up_postal_code`)}
                        readOnly
                        value={userMembership?.postalCode ?? ``}
                        key={uuidv4()}
                        isSignUp={true}
                    />
                    <CustomInput
                        fullWidth
                        label={t(`sign_up_school`)}
                        readOnly
                        value={userMembership?.institutionID?.name ?? ``}
                        key={uuidv4()}
                        isSignUp={true}
                    />
                    <CustomInput
                        fullWidth
                        label={t(`sign_up_years_left`)}
                        readOnly
                        value={userMembership?.yearsBeforeGraduation?.toString() ?? ``}
                        key={uuidv4()}
                        isSignUp={true}
                    />
                    <CustomInput
                        fullWidth
                        label={t(`sign_up_field_of_study`)}
                        readOnly
                        value={userMembership?.fieldOfStudyID?.name ?? ``}
                        key={uuidv4()}
                        isSignUp={true}
                    />
                    <Spacer height={24} />
                    <Grid className={styles.DraftPageButtonContainer}>
                        <Button variant='contained' onClick={handleUpdateInformationButtonClick} fullWidth >{t(`draft_sign_up_update_information`)}</Button>
                        <Button variant='contained' onClick={handleContinueApplicationButtonClick} fullWidth >{t(`draft_sign_up_continue_application`)}</Button>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    )
}

export default Draft