import { Box, Button, Typography, useTheme } from "@mui/material"
import { useTranslation } from "react-i18next"

import { hexToRGB, rgbToString } from "../../../helpers/utils/colors"
import { useContext } from "react"
import { UserContext } from "../../../stores/User/UserStore"
import { logAction } from "../../../clients/logger"
import { UPMEIntroductionPageMessageTemplate } from "../../../helpers/logging/templates"
import useGoogleTagManager from '../../../helpers/analytics/use-google-tag-manager'
import GtmEventName from '../../../helpers/analytics/GtmEventName'

import styles from "./UpmeUpdateNowFooter.module.scss"

const UNION_SIGNUP_URL = process.env.REACT_APP_UNION_SIGNUP_URL ?? `https://www.ntuc.org.sg/Uportal/Home/Memberships/Become%20a%20member`

const UpmeUpdateNowFooter = () => {
    const { t } = useTranslation()

    const theme = useTheme()
    const { trackButtonClick, trackRedirectOnButtonClick } = useGoogleTagManager()

    const { state: userState } = useContext(UserContext)

    const handleButtonClick = () => {
        trackButtonClick(GtmEventName.UPGRADE_NOW_CLICK)
        trackRedirectOnButtonClick(UNION_SIGNUP_URL)
        logAction(`debug`, UPMEIntroductionPageMessageTemplate(userState.membership?.data))
        window.location.replace(UNION_SIGNUP_URL)
    }

    const endColorRgb = hexToRGB(theme.palette.surface.secondary.dark)
    endColorRgb.a = 0.9

    const footerStyle = {
        background: `linear-gradient(180deg, ${theme.palette.surface.secondary.main} 0%, ${rgbToString(endColorRgb)} 100%)`,
        opacity: 0.9,
    }

    return (
        <Box className={styles.updateNowFooter} sx={footerStyle}>
            <Typography variant="h3">{t(`upme_update_now_footer_title`)}</Typography>
            <Typography className={styles.subtitle} variant="body2">{t(`upme_update_now_footer_subtitle`)}</Typography>
            <Button variant="contained" className={styles.button} onClick={handleButtonClick}>{t(`upme_update_now_footer_button`)}</Button>
        </Box>
    )
}

export default UpmeUpdateNowFooter