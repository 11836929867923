import { Box } from "@mui/material"
import { useContext, useEffect, useRef, useState } from "react"

import { useMembershipRepository } from "../../../repositories/Membership/use-membership-repository"
import { useUserRepository } from "../../../repositories/User/use-user-repository"
import { useHomePageRepository } from "../../../repositories/HomePage/use-home-page-repository"
import { UserContext } from "../../../stores/User/UserStore"
import { useNavigate } from "react-router-dom"
import { Route, pathMatchesMembershipPlanRoutePattern, pathMatchesPartnerRoutePattern } from "../../../router"
import styles from "./SplashScreen.module.css"
import { AppContext } from "../../../stores/App/AppStore"
import { fireEmptyVisitedSplashScreen } from "../../../stores/App/AppReducer"
import { MembershipStatus } from "../../../types/MembershipStatus"
import { PaymentOrderReason } from "../../../types/PaymentOrderReason"
import { useSessionRepository } from "../../../repositories/Session/use-session-repository"
import SplashScreenAnimation from "../../atoms/SplashScreenAnimation/SplashScreenAnimation"
import { domainCheckMembershipType } from "../../../helpers/utils/common"

// eslint-disable-next-line no-magic-numbers
const MIN_SPLASH_SCREEN_DELAY = process.env.REACT_APP_STARTER_APP_ENV === `development` ? 0 : 3500

const SplashScreen = () => {
    const navigate = useNavigate()
    const { state, dispatch } = useContext(AppContext)
    const { retrieveMembership } = useMembershipRepository()
    const { retrieveUser } = useUserRepository()
    const { disposeSession } = useSessionRepository()
    const { retrieveHomePage } = useHomePageRepository()
    const { state: userState } = useContext(UserContext)
    const [nextRoute, setNextRoute] = useState<Route | string>()
    const [isLoadingDone, setIsLoadingDone] = useState(false)

    const startTime = useRef(Date.now())

    const membershipTypeFromDomainName = domainCheckMembershipType()

    useEffect(() => {
        retrieveMembership()
        retrieveUser()
        retrieveHomePage(membershipTypeFromDomainName)

        if (!state.splashScreenVisit) {
            fireEmptyVisitedSplashScreen(dispatch)
        }
    }, [])

    useEffect(() => {
        checkUserAndNavigateNextPage()
    }, [userState.user, userState.membership])

    useEffect(() => {
        if (nextRoute) {
            setIsLoadingDone(true)
        }
    }, [nextRoute])

    const checkUserAndNavigateNextPage = () => {
        if (userState.user?.error) {
            navigateToNextPage(getLandingPage())
        } else if (userState.user?.data) {
            checkMembershipAndNavigateNextPage()
        }
    }

    const checkMembershipAndNavigateNextPage = () => {
        if (userState.membership?.error) {
            navigateToNextPage(getLandingPage())
        } else if (userState.membership?.data?.status === MembershipStatus.PENDING_ACTIVATION) {
            navigateToNextPage(`/membership/partner/${userState.membership.data.partner?.code}`)
        } else if (userState.membership?.data?.status === MembershipStatus.PROCESSING) {
            navigateToNextPage(`/membership/payment-summary/${PaymentOrderReason.SIGNUP}`)
        } else if (userState.membership?.data?.status === MembershipStatus.INCOMPLETE) {
            navigateToNextPage(Route.MEMBERSHIP_SIGN_UP)
        } else if (userState.membership?.data) {
            navigateToNextPage(state.splashScreenVisit?.nextPagePath ?? Route.HOME)
        }
    }

    const getLandingPage = (): Route | string => {
        disposeSession()

        if (state.splashScreenVisit?.nextPagePath) {
            const matchesMembershipPlanPagePattern = pathMatchesMembershipPlanRoutePattern(state.splashScreenVisit.nextPagePath)
            const matchesPartnershipLPage = pathMatchesPartnerRoutePattern(state.splashScreenVisit.nextPagePath)

            if (matchesMembershipPlanPagePattern || matchesPartnershipLPage) {
                return state.splashScreenVisit.nextPagePath
            }
        }

        return Route.LANDING_PAGE
    }

    const navigateToNextPage = (route: Route | string) => {
        const now = Date.now()
        const ellapsedTime = now - startTime.current

        if (ellapsedTime < MIN_SPLASH_SCREEN_DELAY) {
            setTimeout(() => {
                setNextRoute(route)
            }, MIN_SPLASH_SCREEN_DELAY - ellapsedTime)
        } else {
            setNextRoute(route)
        }
    }

    const handleSplashScreenAnimationEnded = () => {
        if (nextRoute) {
            navigate(nextRoute)
        }
    }

    return (
        <Box className={styles.container}>
            <SplashScreenAnimation isLoadingDone={isLoadingDone} loadingDoneCallback={handleSplashScreenAnimationEnded} />
        </Box>
    )
}

export default SplashScreen