import { fetchUser } from '../../clients/user'
import { UserInfo } from '../../types/User'

class UserRepository {

    async retrieveUser(): Promise<UserInfo> {
        const data = await fetchUser()
        return data
    }

}

export default UserRepository