import { useContext } from 'react'

import { AppContext } from '../../stores/App/AppStore'
import { UserContext } from '../../stores/User/UserStore'
import { ActionTypes } from '../../stores/App/AppReducer'
import { UserActionTypes } from '../../stores/User/UserReducer'
import { UtapLandingPage } from '../../types/UtapLandingPage'
import { UtapClaimsListServiceResponse } from '../../types/UtapClaimServiceResponse'
import { LastClaimDataResponse } from '../../types'
import { UtapClaimItem } from '../../types/UtapClaim'
import { UtapStatusResponse } from '../../types/UtapStatus'
import { MyInfoUserData } from '../../types/MyInfoUserData'

import UtapRepository from "./utap.repository"
import LastClaimData from '../../types/LastClaimData'

const useUtapRepository = () => {
    const { dispatch: appDispatch } = useContext(AppContext)
    const { dispatch: userDispatch } = useContext(UserContext)
    const utapRepository = new UtapRepository()

    const saveUtapClaim = async (claimForm: FormData) => {
        return utapRepository.createUtapClaim(claimForm)
    }

    const retrieveUtapClaims = async (myInfoUserData: MyInfoUserData, pageSize: number) => {
        const utapClaimsData: UtapClaimsListServiceResponse = {}

        return utapRepository.retrieveUtapClaims(myInfoUserData, pageSize)
            .then((utapClaims: UtapClaimItem[]) => {
                utapClaimsData.data = utapClaims
            })
            .catch((error) => {
                utapClaimsData.error = {
                    errorMessage: error.message,
                    errorCode: error.response?.status,
                }
            })
            .finally(() => {
                userDispatch({
                    type: UserActionTypes.UTAP_CLAIMS_RETRIEVE,
                    data: utapClaimsData,
                })
            })
    }

    const retrieveUtapClaimsLog = async () => {
        const lastClaimData: LastClaimDataResponse = {}

        return utapRepository.retrieveLastClaimData()
            .then((result: LastClaimData) => {
                lastClaimData.data = result
            })
            .catch((error) => {
                lastClaimData.error = {
                    errorMessage: error.message,
                    errorCode: error.response?.status,
                }
            })
            .finally(() => {
                userDispatch({
                    type: UserActionTypes.LAST_CLAIM_DATA_RETRIEVED,
                    data: lastClaimData,
                })
            })
    }

    const retrieveUtapStatus = async (myInfoUserData: MyInfoUserData) => {
        const utapStatusResponse: UtapStatusResponse = {}
        return utapRepository.retrieveUtapStatus(myInfoUserData)
            .then(utapStatus => {
                utapStatusResponse.data = utapStatus
            })
            .catch(error => {
                utapStatusResponse.error = {
                    errorMessage: error.message,
                    errorCode: error.response?.status,
                }
            })
            .finally(() => {
                userDispatch({
                    type: UserActionTypes.UTAP_STATUS_RETRIEVED,
                    data: utapStatusResponse,
                })
            })
    }

    const retrieveUtapLandingPage = async () => {
        return utapRepository.retrieveUtapLandingPage()
            .then((utapLanding: UtapLandingPage) => {
                appDispatch({
                    type: ActionTypes.UTAP_LANDING_RETRIEVE,
                    data: utapLanding,
                })
            })
            .catch((error) => {
                console.error(error.message)
            })
    }

    return {
        saveUtapClaim,
        retrieveUtapClaims,
        retrieveUtapStatus,
        retrieveUtapLandingPage,
        retrieveUtapClaimsLog,
    }
}

export { useUtapRepository }