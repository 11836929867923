import { Box, Container, Stack, Typography, Button } from "@mui/material"
import { useTranslation } from "react-i18next"
import CommonOverlayGradient from "../../atoms/CommonOverlayGradient/CommonOverlayGradient"
import { Deal } from "../../../types/Deal"
import useGoogleTagManager from '../../../helpers/analytics/use-google-tag-manager'
import GtmEventName from '../../../helpers/analytics/GtmEventName'

import styles from "./DealDetailsBottomOverlay.module.css"

interface DealDetailsOverlayProps {
    deal?: Deal,
    onShowToast: (value: boolean) => void
}

const DealDetailsBottomOverlay = ({ deal, onShowToast }: DealDetailsOverlayProps) => {
    return (
        <Stack className={styles.overlay}>
            <CommonOverlayGradient />
            <Container className={styles.overlayContent}>
                <BottomOverlayMessage deal={deal} onShowToast={onShowToast} />
                <BottomOverlayButton deal={deal} />
            </Container>
        </Stack>
    )
}

const BottomOverlayMessage = ({ deal, onShowToast }: DealDetailsOverlayProps) => {
    const { t } = useTranslation()
    const { trackButtonClick } = useGoogleTagManager()

    const handleCopyClick = () => {
        trackButtonClick(GtmEventName.COPY_PROMO_CODE, deal?.title)
        navigator.clipboard.writeText(deal?.promotionCode ?? ``)
        onShowToast(true)
    }

    if (deal?.promotionCode) {
        return <Box className={styles.overlayContentText}>
            <Typography variant={`body2`}>{t(`promo_code`)}: </Typography>
            <Typography variant={`subtitle1`}>{deal?.promotionCode}</Typography>
            <Typography variant={`subtitle2`} className={styles.overlayContentLink} onClick={handleCopyClick}> {t(`copy`)}</Typography>
        </Box>
    }

    return <></>
}

const BottomOverlayButton = ({ deal }: Pick<DealDetailsOverlayProps, 'deal'>) => {
    const { t } = useTranslation()
    const { trackRedirectOnButtonClick } = useGoogleTagManager()

    if (!deal?.externalLink) {
        return null
    }

    const handleExternalLinkClick = () => {
        if (deal.externalLink) {
            trackRedirectOnButtonClick(deal.externalLink)
            window.open(deal.externalLink, `_blank`)
        }
    }

    const externalLinkName = deal?.externalLinkName ? deal?.externalLinkName : t(`deal_external_link_default_name`)

    return (
        <Box className={styles.buttonContainer}>
            <Button variant="contained" onClick={handleExternalLinkClick}>{externalLinkName}</Button>
        </Box>
    )
}

export default DealDetailsBottomOverlay