import React, { useState } from "react"
import { Box, Container, Link, Typography } from "@mui/material"
import { t } from "i18next"
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

import CommonBackButton from "../../atoms/CommonBackButton/CommonBackButton"
import SplashScreenGuard from "../../../router/guards/SplashScreenGuard"
import FilterMenu from "../FilterMenu/FilterMenu"

import styles from './CardsList.module.scss'

interface WithId {
    id: number
}

interface CardsListProps<T extends WithId, P> {
    items: T[]
    itemsTitle: string
    cardRenderer: (item: T) => React.ReactElement<P>
    showFilterBy: boolean
    onFilterChange?: (type: string) => void
    categoryFilterList?: Category[]
}

interface Category {
    id: number
    name: string
    order: number
}

const CardsList = <T extends WithId, P>(props: CardsListProps<T, P>) => {
    const { items, itemsTitle, cardRenderer, showFilterBy, categoryFilterList, onFilterChange } = props

    const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false)

    const getFilterTypeList = <T extends Category>(list?: T[]): string[] => {
        const allCategory = t(`filter_by_all`)

        const categoryArr: string[] = [allCategory]

        if (list) {
            list.forEach(category => {
                categoryArr.push(category.name)
            })
        }

        return categoryArr
    }

    const handleFilterByMenuClick = () => {
        setIsFilterMenuOpen(true)
    }

    const handleFilterByMenuClose = () => {
        setIsFilterMenuOpen(false)
    }

    const handleFilterChange = (type: string) => {
        if (showFilterBy && onFilterChange) {
            setIsFilterMenuOpen(false)
            onFilterChange(type)
        } else {
            return
        }
    }

    const filter = showFilterBy && (
        <Link className={styles.filterBy} color="inherit" onClick={handleFilterByMenuClick}>
            <Typography variant='subtitle1' className={styles.filterName}>{t(`filter_by`, {colon: ``})}</Typography>
            <ArrowDropDownIcon />
        </Link>
    )

    const cardsList = items.map((item) => <Box key={`cardId-${item.id}`}>{cardRenderer(item)}</Box>)
    const filterMenu = showFilterBy && <FilterMenu isOpen={isFilterMenuOpen} handleMenuCloseClick={handleFilterByMenuClose} filterTypeList={getFilterTypeList(categoryFilterList)} onFilterChange={handleFilterChange} />

    return (
        <SplashScreenGuard>
            <Container>
                <CommonBackButton />
                <Box className={styles.headerSection} display="flex" alignItems="center">
                    <Typography className={styles.title} variant="h3">{itemsTitle}</Typography>
                    {filter}
                </Box>
                <Box className={styles.itemsList}>
                    {cardsList}
                </Box>
                {filterMenu}
            </Container>
        </SplashScreenGuard>
    )
}

export default CardsList