import { FC } from 'react'
import { Elements } from "@stripe/react-stripe-js"
import { StripeElementsOptions, loadStripe } from "@stripe/stripe-js"

import { StripePaymentElement } from "./StripePaymentElement"

const stripePromise = loadStripe(
    `${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`,
)

export interface StripePaymentModalProps {
    clientSecret?: string
    email?: string
    isFirstYearFree: boolean
    consent: boolean
    onConsentFalse: ()=> void
    onSuccess: () => void
}

export const StripePaymentModal: FC<StripePaymentModalProps> = ({
    clientSecret,
    email,
    isFirstYearFree,
    consent,
    onConsentFalse,
    onSuccess,
}) => {

    const options: StripeElementsOptions = {
        clientSecret,
        appearance: {
            theme: `stripe`,
            variables: {
                spacingGridRow: `16px`,
            },
        },
    }

    return (
        <Elements options={options} stripe={stripePromise}>
            <StripePaymentElement onConsentFalse={onConsentFalse} consent={consent} email={email} isFirstYearFree={isFirstYearFree} onSuccess={onSuccess} />
        </Elements>
    )
}