import { MutableRefObject } from "react"
import { useTranslation } from "react-i18next"
import { Grid, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"
import moment from "moment"
import clsx from "clsx"

import { Route } from "../../../router"
import { PaymentHistoryResult } from "../../../types"
import { PaymentStatus } from "../../../types/PaymentStatus"
import { DatePatterns } from "../../../types/date-patterns"
import useGoogleTagManager from '../../../helpers/analytics/use-google-tag-manager'
import GtmEventName from '../../../helpers/analytics/GtmEventName'

import styles from './MyActivityCard.module.css'

interface MyActivityCardProps {
    paymentOrderTransaction: PaymentHistoryResult
    reference?: MutableRefObject<null>
}

const DECIMALS = 2

const MyActivityCard = (props: MyActivityCardProps) => {
    const { paymentOrderTransaction, reference } = props

    const { t } = useTranslation()
    const navigate = useNavigate()
    const { trackCardClick } = useGoogleTagManager()
    const clientOrderId = paymentOrderTransaction.clientOrderId

    const handlePaymentOrderClick = () => {
        trackCardClick(GtmEventName.TRANSACTION_CARD_CLICK)
        navigate(Route.PROFILE_MY_ACTIVITY + `/${clientOrderId}`)
    }

    const dateStringFormatter = (dateString: Date): string => {
        return moment(dateString).format(DatePatterns.SHORT_DATE).toUpperCase()
    }

    const toTitleCase = (text: string) => {
        return text
            .split(` `)
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(` `)
    }

    const paymentOrderReason = toTitleCase(paymentOrderTransaction.reason)
    const transactionReason = t(`profile_my_activity_transaction_reason`, { reason: paymentOrderReason })
    const transactionDate = dateStringFormatter(paymentOrderTransaction.transactionDate)
    const transactionStatusColor = paymentOrderTransaction.status === PaymentStatus.CANCELLED
        || paymentOrderTransaction.status === PaymentStatus.FAILED
        ? `error`
        : `default`

    const transactionResult = paymentOrderTransaction.status === PaymentStatus.SUCCESS
        ? `$${paymentOrderTransaction.amount.toFixed(DECIMALS)}`
        : toTitleCase(paymentOrderTransaction.status)

    return (
        <Grid className={styles.cardContainer} ref={reference} onClick={handlePaymentOrderClick}>
            <Grid className={clsx(styles.cardContainerColumn, styles.alignCenter)}>
                <Typography variant={`subtitle1`}>{transactionReason}</Typography>
                <Typography variant={`body2`}>{transactionDate}</Typography>
            </Grid>
            <Grid className={clsx(styles.cardContainerColumn, styles.alignEnd)}>
                <Typography variant={`body1`}>{paymentOrderTransaction.clientOrderId}</Typography>
                <Typography variant={`body1`} color={transactionStatusColor}>{transactionResult}</Typography>
            </Grid>
        </Grid>
    )
}

export default MyActivityCard