import { useContext } from 'react'

import { AppContext } from '../../stores/App/AppStore'
import { ActionTypes } from '../../stores/App/AppReducer'
import MembershipSuccessPageRepository from './membership-success-page.repository'

const useMembershipSuccessPageRepository = () => {
    const { dispatch } = useContext(AppContext)

    const membershipSuccessPageRepository = new MembershipSuccessPageRepository()

    const retrieveMembershipSuccessPage = () => {
        membershipSuccessPageRepository.get()
            .then((data) => {
                dispatch({ type: ActionTypes.PAGE_MEMBERSHIP_SUCCESS_RETRIEVE, data })
            })
            .catch((error) => {
                console.error(error.message)
            })
    }

    return {
        retrieveMembershipSuccessPage,
    }
}

export { useMembershipSuccessPageRepository }