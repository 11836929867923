import { MembershipSettings } from "../../types"
import { absoluteImageUrl } from "../utils/environment"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const fromStrapiMembershipSettings = (strapiData: any): MembershipSettings => {
    const { unionLink, membershipCardFace, starterProfileUpdateDate, graduatedInstitution } = strapiData

    const bannerImageURL = membershipCardFace.url ? absoluteImageUrl(membershipCardFace.url) : ``

    return {
        unionLink,
        bannerImageURL,
        starterProfileUpdateDate,
        graduatedInstitution,
    }
}

export { fromStrapiMembershipSettings }