import { fetchUpmeBenefit, fetchUpmeBenefitCategories, fetchUpmeBenefits, fetchUpmeIntroductionCarouselItems, fetchUpmeIntroductionFAQEntries } from '../../clients/upme-onboarding'
import fromStrapiSimpleFAQEntry from '../../helpers/mapping/simple-faq-entry'
import fromStrapiUpmeBenefit from '../../helpers/mapping/upme-benefit'
import fromStrapiUpmeBenefitCategory from '../../helpers/mapping/upme-benefit-category'
import fromStrapiUpmeIntroductionCarouselItem from '../../helpers/mapping/upme-introduction-carousel-item'
import { SimpleFAQEntry } from '../../types/SimpleFAQEntry'
import { UpmeBenefit } from '../../types/UpmeBenefit'
import { UpmeBenefitCategory } from '../../types/UpmeBenefitCategory'
import { UpmeIntroductionCarouselItem } from '../../types/UpmeIntroductionCarouselItem'

class UPMEOnboardingRepository {
    async retrieveUpmeIntroductionCarouselItems(): Promise<UpmeIntroductionCarouselItem[]> {
        const upmeIntroductionCarouselResponse = await fetchUpmeIntroductionCarouselItems()
        const carouselItems = upmeIntroductionCarouselResponse.map(fromStrapiUpmeIntroductionCarouselItem)
        return carouselItems
    }

    async retrieveUpmeIntroductionFAQEntries(): Promise<SimpleFAQEntry[]> {
        const upmeIntroductionFAQEntriesResponse = await fetchUpmeIntroductionFAQEntries()
        const faqEntries = upmeIntroductionFAQEntriesResponse.data.attributes.questions.map(fromStrapiSimpleFAQEntry)
        return faqEntries
    }

    async retrieveUpmeBenefits(): Promise<UpmeBenefit[]> {
        const upmeBenefitsResponse = await fetchUpmeBenefits()
        const upmeBenefits = upmeBenefitsResponse.data.map(fromStrapiUpmeBenefit)

        upmeBenefits.sort((a: UpmeBenefit, b: UpmeBenefit) => {
            if (a.category.order === b.category.order) {
                if (a.category.name === b.category.name) {
                    if (a.order === b.order) {
                        return a.title.localeCompare(b.title)
                    }

                    if (a.order === 0) {
                        return 1
                    }

                    if (b.order === 0) {
                        // eslint-disable-next-line no-magic-numbers
                        return -1
                    }
                } else {
                    return a.category.name.localeCompare(b.category.name)
                }

                return a.order - b.order
            }

            if (a.category.order === 0) {
                return 1
            }

            if (b.category.order === 0) {
                // eslint-disable-next-line no-magic-numbers
                return -1
            }

            return a.category.order - b.category.order
        })

        return upmeBenefits
    }

    async retrieveUpmeBenefit(id: number): Promise<UpmeBenefit> {
        const upmeBenefitResponse = await fetchUpmeBenefit(id)
        const upmeBenefit = fromStrapiUpmeBenefit(upmeBenefitResponse.data)
        return upmeBenefit
    }

    async retrieveUpmeBenefitCategories(): Promise<UpmeBenefitCategory[]> {
        const upmeBenefitCategoriesResponse = await fetchUpmeBenefitCategories()
        const upmeBenefitCategories = upmeBenefitCategoriesResponse.data.map(fromStrapiUpmeBenefitCategory)

        upmeBenefitCategories.sort((a: UpmeBenefitCategory, b: UpmeBenefitCategory) => {
            if (a.order === b.order) {
                return a.name.localeCompare(b.name)
            }

            if (a.order === 0) {
                return 1
            }

            if (b.order === 0) {
                // eslint-disable-next-line no-magic-numbers
                return -1
            }

            return a.order - b.order
        })

        return upmeBenefitCategories
    }
}

export default UPMEOnboardingRepository