import { MembershipType } from "../../types/MembershipType"
import { ProgrammeApplicationForm } from "../../types/ProgrammeApplicationForm"
import { fetchProgrammeApplicationForm, fetchProgrammeApplicationForms, submitProgrammeApplication } from "../../clients/programme-application-forms"
import fromStrapiProgrammeApplicationForm from "../../helpers/mapping/programme-application-form"
import ProgrammeApplicationSubmission from "../../types/ProgrammeApplicationSubmission"

class ProgrammeApplicationFormRepository {
    async retrieveProgrammeApplicationForms(membershipType: MembershipType, isAuthenticated: boolean): Promise<ProgrammeApplicationForm[]> {
        const programmeApplicationFormsResponse = await fetchProgrammeApplicationForms(membershipType, isAuthenticated)
        const programmeApplicationForms = programmeApplicationFormsResponse.data.map(fromStrapiProgrammeApplicationForm)

        const sortedProgrammeApplicationForms = programmeApplicationForms.sort((a: ProgrammeApplicationForm, b: ProgrammeApplicationForm) => {
            if (a.order === b.order) {
                return a.name.localeCompare(b.name)
            }

            if (a.order === 0) {
                return 1
            }

            if (b.order === 0) {
                // eslint-disable-next-line no-magic-numbers
                return -1
            }

            return a.order - b.order
        })

        return sortedProgrammeApplicationForms
    }

    async retrieveProgrammeApplicationForm(id: number, isAuthenticated: boolean): Promise<ProgrammeApplicationForm> {
        const programmeApplicaitonFormResponse = await fetchProgrammeApplicationForm(id, isAuthenticated)
        const programmeApplicaitonForm = fromStrapiProgrammeApplicationForm(programmeApplicaitonFormResponse.data)
        return programmeApplicaitonForm
    }

    async submitProgrammeApplication(programmeApplicationSubmission: ProgrammeApplicationSubmission, progressListener?: (progressPercent: number) => void) {
        return submitProgrammeApplication(programmeApplicationSubmission, progressListener)
    }
}

export default ProgrammeApplicationFormRepository