import React, { useMemo, type Dispatch } from 'react'

import MembershipReducer, { type MembershipAction } from './MembershipReducer'
import { MembershipInitialState, type MembershipState } from './MembershipState'

const MembershipContext = React.createContext<{
    state: MembershipState,
    dispatch: Dispatch<MembershipAction>
}>({
    state: MembershipInitialState,
    dispatch: () => null,
})

const MembershipContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [state, dispatch] = React.useReducer(MembershipReducer, MembershipInitialState)
    const providerValues = useMemo(() => ({ state, dispatch }), [state])

    return (
        <MembershipContext.Provider value={providerValues}>
            {children}
        </MembershipContext.Provider>
    )
}

export { MembershipContextProvider, MembershipContext }