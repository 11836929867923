import { useContext, useEffect } from 'react'
import { Container, Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { AppContext } from '../../../stores/App/AppStore'
import { useApplicationTermsOfUsePageRepository } from '../../../repositories/PwaTermsAndConditionsPage/use-application-terms-of-use-page-repository'
import CommonBackButton from '../../atoms/CommonBackButton/CommonBackButton'
import MarkdownContent from '../../atoms/MarkdownContent/MarkdownContent'
import useTrackPageView from "../../../helpers/hooks/use-track-page-view"

import styles from './ApplicationTermsOfUse.module.css'

const ApplicationTermsOfUse = () => {
    useTrackPageView(`Terms of Use Page`)

    const { state } = useContext(AppContext)
    const { retrievePwaTermsAndConditionsPage } = useApplicationTermsOfUsePageRepository()
    const { t } = useTranslation()

    useEffect(() => {
        retrievePwaTermsAndConditionsPage()
        window.scrollTo(0, 0)
    }, [])

    return <Container>
        <CommonBackButton />
        <Typography variant={`h4`} className={styles.title}>{t(`application_terms_of_use_title`)}</Typography>
        <Grid container direction="column" spacing={2}>
            <Grid item>
                <MarkdownContent content={state.pages.applicationTermsOfUse.content} />
            </Grid>
        </Grid>
    </Container>
}

export default ApplicationTermsOfUse