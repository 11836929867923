import { anonymousClient } from './util'
import { HTTP_SUCCESS } from '../helpers/utils/http-status'

const fetchTermsAndConditions = async () => {
    return anonymousClient()
        .get(
            `/api/membership-terms-and-conditions`,
        ).then(res => {
            if (res.status !== HTTP_SUCCESS) {
                throw new Error(`Could not retrieve terms and conditions`)
            }
            return res
        }).then(res => res.data)
}

const fetchTermsAndConditionsByType = async (purpose: string) => {
    return anonymousClient()
        .get(
            `/api/membership-terms-and-conditions?filters[purpose]$eq=${purpose}`,
        ).then(res => {
            if (res.status !== HTTP_SUCCESS) {
                throw new Error(`Could not retrieve terms and conditions`)
            }
            return res
        }).then(res => res.data)
}

export {
    fetchTermsAndConditions,
    fetchTermsAndConditionsByType,
}
