import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Typography from '@mui/material/Typography'
import clsx from 'clsx'

import styles from './VerticalLinearStep.module.css'

export interface VerticalStepProps {
    steps?: string[]
    activeStep: number
}

const VerticalLinearStep = (props: VerticalStepProps) => {
    if (!props.steps) {
        return null
    }

    return (
        <div className={styles.box}>
            <Stepper orientation="vertical">
                {props.steps.map((step, index) => (
                    <Step key={step} active={index <= props.activeStep}
                        className={clsx(
                            { [styles.stepActive]: (index <= props.activeStep) },
                            { [styles.step]: (index > props.activeStep) },
                        )}>
                        <StepLabel>
                            <Typography>{step}</Typography>
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
        </div>
    )
}

export default VerticalLinearStep