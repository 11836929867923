import { Box, Container, Skeleton } from "@mui/material"

import CommonBackButton from "../../../atoms/CommonBackButton/CommonBackButton"
import UpmeBenefitCard from "../../../molecules/UpmeBenefitCard/UpmeBenefitCard"
import MarkdownContent from "../../../atoms/MarkdownContent/MarkdownContent"
import { Route } from "../../../../router"

import styles from "./UpmeBenefitDetails.module.scss"
import clsx from "clsx"

const UpmeBenefitDetailsSkeleton = () => {
    const moreInfoTitleStyle = clsx(
        styles.moreInfoTitle,
        styles.skeleton,
    )

    return (
        <Container className={styles.container}>
            <Box className={styles.skeletonBackButtonBox}>
                <CommonBackButton path={Route.UPME_CONVERSION_BENEFITS} />
            </Box>

            <UpmeBenefitCard className={styles.benefitCardstyles} />

            <Skeleton className={moreInfoTitleStyle} variant="rectangular" />

            <MarkdownContent />
        </Container>
    )
}

export default UpmeBenefitDetailsSkeleton