import { useContext } from 'react'

import { AppContext } from '../../stores/App/AppStore'
import { ActionTypes } from '../../stores/App/AppReducer'
import EventRepository from './event-page-repository'
import { EventRegistration } from '../../types/EventRegistration'
import { EventRegistrationResponse } from '../../types/EventRegistrationSuccess'
import { MembershipType } from '../../types/MembershipType'
import { EventDetail, ServiceResponse } from '../../types'
import { starterDataError } from '../../helpers/utils/error-handling'

const useEventRepository = () => {
    const { dispatch } = useContext(AppContext)
    const eventRepository = new EventRepository()

    const retrieveEvents = (membershipType: MembershipType) => {
        const eventsResponse: ServiceResponse<EventDetail[]> = {}

        eventRepository.getAll(membershipType)
            .then((eventsPageData) => {
                eventsResponse.data = eventsPageData
            })
            .catch((error) => {
                eventsResponse.error = starterDataError(error)
            })
            .finally(() => {
                dispatch({
                    type: ActionTypes.EVENTS_RETRIEVED,
                    events: eventsResponse,
                })
            })
    }

    const retrieveEventsPublic = (membershipType: MembershipType) => {
        const eventsResponse: ServiceResponse<EventDetail[]> = {}
        eventRepository.getAllEventsPublic(membershipType)
            .then((eventsPageData) => {
                eventsResponse.data = eventsPageData
            })
            .catch((error) => {
                eventsResponse.error = starterDataError(error)
            })
            .finally(() => {
                dispatch({
                    type: ActionTypes.EVENTS_RETRIEVED,
                    events: eventsResponse,
                })
            })
    }

    const retrieveEventDetails = (id: number) => {
        const eventResponse: ServiceResponse<EventDetail> = {}

        eventRepository.get(id)
            .then((eventDetails) => {
                eventResponse.data = eventDetails
            })
            .catch((error) => {
                eventResponse.error = starterDataError(error)
            })
            .finally(() => {
                dispatch({
                    type: ActionTypes.EVENT_RETRIEVED,
                    event: eventResponse,
                })
            })
    }

    const registerEvent = (data: EventRegistration) => {
        const registerEventData: EventRegistrationResponse = {}

        return eventRepository.post(data)
            .then((data) => {
                registerEventData.data = data
                return registerEventData
            })
            .catch((error) => {
                registerEventData.error = starterDataError(error)
                return registerEventData
            })
    }

    return {
        retrieveEventsPublic,
        retrieveEvents,
        retrieveEventDetails,
        registerEvent,
    }
}

export { useEventRepository }