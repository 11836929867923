import { anonymousClient } from './util'
import { HTTP_SUCCESS } from '../helpers/utils/http-status'
import { MembershipType } from '../types/MembershipType'

const fetchPillars = async (membershipType: MembershipType) => {
    return anonymousClient()
        .get(
            `/api/pillars?populate[programme][populate][1]=bannerImage&populate[image]=*&populate[faqEntries][populate]=*&filters[$or][0][membershipScope]=ALL&filters[$or][0][membershipScope]=${membershipType}`,
        ).then(res => {
            if (res.status !== HTTP_SUCCESS) {
                throw new Error(`Could not retrieve pillars`)
            }
            return res
        }).then(res => res.data)
}

const fetchCuratedExperience = async (id: number) => {
    return anonymousClient()
        .get(
            `/api/curated-experiences/${id}?populate=*`,
        ).then(res => {
            if (res.status !== HTTP_SUCCESS) {
                throw new Error(`Could not retrieve Curated Experience ${id}`)
            }
            return res
        }).then(res => res.data)
}

const fetchCuratedExperiences = async () => {
    return anonymousClient()
        .get(
            `/api/curated-experiences?populate=*`,
        ).then(res => {
            if (res.status !== HTTP_SUCCESS) {
                throw new Error(`Could not retrieve Curated Experiences`)
            }
            return res
        }).then(res => res.data)
}

const fetchCuratedExperiencesPage = async () => {
    return anonymousClient()
        .get(
            `/api/curated-experience-page`,
        ).then(res => {
            if (res.status !== HTTP_SUCCESS) {
                throw new Error(`Could not retrieve Curated Experiences`)
            }
            return res
        }).then(res => res.data)
}

export {
    fetchPillars,
    fetchCuratedExperience,
    fetchCuratedExperiences,
    fetchCuratedExperiencesPage,
}