import { Modal, IconButton, Grid } from '@mui/material'
import CancelRoundedIcon from '@mui/icons-material/CancelRounded'

import styles from './ImageModal.module.css'

interface ImageModalProps {
    imageUrl: string
    alt?: string
    defaultOpen: boolean
    onClose: () => void
}

const ImageModal = (props: ImageModalProps) => {
    const {
        imageUrl,
        alt,
        defaultOpen,
        onClose,
    } = props

    const altText = alt ?? `image preview`

    return (
        <Modal
            open={defaultOpen}
            onClose={onClose}
            className={styles.preview}
        >
            <Grid className={styles.imageContainer}>
                <IconButton onClick={onClose} className={styles.closeButton}>
                    <CancelRoundedIcon fontSize={`large`} />
                </IconButton>
                <img src={imageUrl} alt={altText} className={styles.imageModalImg} />
            </Grid>
        </Modal>
    )
}

export default ImageModal