import CommonBackButton from "../../atoms/CommonBackButton/CommonBackButton"
import { Box, Container, Grid, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"

import { Route } from "../../../router"

import styles from './Deals.module.scss'
import AppCardSkeleton from "../../atoms/AppCard/AppCard.skeleton"

const DealsSkeleton = () => {
    const { t } = useTranslation()

    return (
        <Box>
            <Container>
                <CommonBackButton path={Route.HOME} />
                <Typography className={styles.pageTitle} variant="h3">{t(`deals_and_promotions`)}</Typography>
            </Container>

            <Container id="benefitsList" className={styles.container}>
                <Grid className={styles.dealsList} container direction="column" alignItems="center" rowSpacing={2}>
                    <Grid item width="100%">
                        <AppCardSkeleton />
                    </Grid>
                    <Grid item width="100%">
                        <AppCardSkeleton />
                    </Grid>
                    <Grid item width="100%">
                        <AppCardSkeleton />
                    </Grid>
                </Grid>
            </Container>
        </Box >
    )
}

export default DealsSkeleton