import { useContext } from 'react'

import { UserContext } from '../../stores/User/UserStore'
import MembershipPlanRepository from './membership-plan.repository'
import { UserActionTypes } from '../../stores/User/UserReducer'
import { MembershipPlanResponse } from '../../types'
import { starterDataError } from '../../helpers/utils/error-handling'

const useMembershipPlanRepository = () => {
    const { dispatch } = useContext(UserContext)
    const membershipPlanRepository = new MembershipPlanRepository()

    const saveMembershipPlanKey = (membershipPlan?: string) => {
        membershipPlanRepository.setMembershipPlanKey(membershipPlan)
    }

    const getMembershipPlanKey = (): string | undefined => {
        return membershipPlanRepository.getMembershipPlanKey()
    }

    const retrieveMembershipPlan = (membershipPlanKey?: string) => {
        const planKey = membershipPlanKey ?? getMembershipPlanKey()

        if (planKey) {
            const membershipPlanResponse: MembershipPlanResponse = {}

            membershipPlanRepository.retrieveMembershipPlan(planKey)
                .then(membershipPlan => {
                    membershipPlanResponse.data = membershipPlan
                })
                .catch(error => {
                    membershipPlanResponse.error = starterDataError(error)
                })
                .finally(() => {
                    dispatchMembershipPlan(membershipPlanResponse)
                })
        }
    }

    const resetMembershipPlan = () => {
        membershipPlanRepository.setMembershipPlanKey()
    }

    const dispatchMembershipPlan = (membershipPlan: MembershipPlanResponse | undefined) => {
        dispatch({
            type: UserActionTypes.MEMBERSHIP_PLAN_RETRIEVED,
            membershipPlan,
        })
    }

    return {
        saveMembershipPlanKey,
        getMembershipPlanKey,
        retrieveMembershipPlan,
        resetMembershipPlan,
    }
}

export { useMembershipPlanRepository }