import { ProgrammeApplicationForm, StrapiProgrammeApplicationForm } from '../../types/ProgrammeApplicationForm'
import { absoluteImageUrl } from '../utils/environment'

const fromStrapiProgrammeApplicationForm = (programmeApplicationForm: StrapiProgrammeApplicationForm): ProgrammeApplicationForm => {
    return {
        id: programmeApplicationForm.id,
        name: programmeApplicationForm.attributes.name,
        image: programmeApplicationForm.attributes.image?.data ? absoluteImageUrl(programmeApplicationForm.attributes.image.data.attributes.url) : ``,
        code: programmeApplicationForm.attributes.code,
        order: programmeApplicationForm.attributes.order,
        summary: programmeApplicationForm.attributes.summary,
        description: programmeApplicationForm.attributes.description,
        termsAndConditions: programmeApplicationForm.attributes.termsAndConditions,
        submissionConfirmationMessage: programmeApplicationForm.attributes.submissionConfirmationMessage,
        allowMultipleSubmissions: programmeApplicationForm.attributes.allowMultipleSubmissions,
        questions: programmeApplicationForm.attributes.questions,
        files: programmeApplicationForm.attributes.files,
        hasApplied: programmeApplicationForm.attributes.hasApplied,
    }
}

export default fromStrapiProgrammeApplicationForm