import { ServiceResponse } from './RemoteData'

export enum MembershipStatus {
    ACTIVE = `ACTIVE`,
    UNION_ACTIVE = `UNION_ACTIVE`,
    PROCESSING = `PROCESSING`,
    INCOMPLETE = `INCOMPLETE`,
    PENDING_ACTIVATION = `PENDING_ACTIVATION`,
    TERMINATED = `TERMINATED`,
}

export enum UnionMembershipStatus {
    IS_MEMBER = `IS_MEMBER`,
    NOT_MEMBER = `NOT_MEMBER`,
}

export type CheckUnionMembershipStatusResponse = ServiceResponse<UnionMembershipStatus>

export const isMembershipStatusAllowedInApp = (status?: MembershipStatus): boolean => {
    return status === MembershipStatus.ACTIVE
        || status === MembershipStatus.TERMINATED
        || status === MembershipStatus.UNION_ACTIVE
}