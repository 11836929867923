import { TermsAndConditions } from '../../types'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const fromStrapiTerms = (strapiData: any): TermsAndConditions => {
    const { attributes } = strapiData
    const { title, content, purpose } = attributes

    const item: TermsAndConditions = {
        title,
        content,
        purpose,
    }

    return item
}

export {
    fromStrapiTerms,
}