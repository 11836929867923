import { fromStrapiEvent } from '../../helpers/mapping/event'
import { fetchEvents, fetchEvent, postEventRegistration, fetchEventsPublic } from '../../clients/events'
import { EventDetail, EventRegistrationSuccess } from '../../types'
import { EventRegistration } from '../../types/EventRegistration'
import { MembershipType } from '../../types/MembershipType'
import { fromStrapiEventPublic } from '../../helpers/mapping/event-public'

class EventRepository {

    async getAll(membershipType: MembershipType): Promise<EventDetail[]> {
        const events = await fetchEvents(membershipType)
        return events.map((i: unknown) => fromStrapiEvent(i))
    }

    async getAllEventsPublic(membershipType: MembershipType): Promise<EventDetail[]> {
        const events = await fetchEventsPublic(membershipType)
        return events.data.map((i: unknown) => fromStrapiEventPublic(i))
    }

    async get(id: number): Promise<EventDetail> {
        const event = await fetchEvent(id)
        return fromStrapiEvent(event)
    }

    async post(registrationData: EventRegistration): Promise<EventRegistrationSuccess> {
        return await postEventRegistration(registrationData)
    }
}

export default EventRepository