import { ChangeEvent, ChangeEventHandler, useEffect, useState } from 'react'
import { Box, Button, InputLabel, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

import styles from './CustomFileInput.module.scss'
import clsx from 'clsx'

const KILO_BYTE_CONVERSION = 1024

export enum FileType {
    ALL = `.jpg,.jpeg,.png,.pdf`,
    DOCUMENT = `.pdf`,
    IMAGE = `.jpg,.jpeg,.png`,
    IMAGE_JPG = `.jpg,.jpeg`,
    IMAGE_PNG = `.png`,
}

type CustomFileInputProps = {
    className?: string
    label?: string
    description?: string
    buttonLabel?: string
    disabled?: boolean
    fullWidth?: boolean
    insetLabel?: boolean
    readOnly?: boolean
    error?: boolean
    maxFileSize?: number
    allowedFiletypes?: object
    infoLabel?: boolean
    allowedFileTypes?: FileType
    hideFilename?: boolean
    mandatory?: boolean
    attemptedSubmission?: boolean
    onChange?: (value?: File, hasError?: boolean) => void
    onInfoLabelClick?: () => void
}

const CustomFileInput = (props: CustomFileInputProps) => {
    const {
        className, label, description, buttonLabel, disabled, insetLabel, error, maxFileSize, allowedFiletypes, infoLabel, allowedFileTypes = FileType.ALL, hideFilename, mandatory, attemptedSubmission,
        onChange, onInfoLabelClick,
    } = props

    const { t } = useTranslation()

    const [fileName, setFileName] = useState(``)
    const [localError, setLocalError] = useState<string | null>(null)

    useEffect(() => {
        if (error) {
            setLocalError(`Required`)
        } else {
            setLocalError(null)
        }
    }, [error])

    const handleChange: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = (event: ChangeEvent) => {
        setLocalError(null)
        let errorMessage = ``
        const target = event.target as HTMLInputElement
        const input: File | undefined = (target.files as FileList)[0]

        if (!input) {
            onChange?.(input)
            setFileName(``)
            return
        }

        const fileSize = input.size
        const fileType = input.type

        setFileName(input.name)

        if (maxFileSize) {
            if ((fileSize / KILO_BYTE_CONVERSION) > maxFileSize) {
                errorMessage = t(`file_too_large`)
                setLocalError(errorMessage)
                onChange?.(input, true)
                return
            }
        }
        if (allowedFiletypes) {
            if (!Object.values(allowedFiletypes).includes(fileType)) {
                errorMessage = t(`file_type_not_allowed`)
                setLocalError(errorMessage)
                onChange?.(input, true)
                return
            }
        }
        if (!errorMessage) {
            if (onChange) {
                onChange(input)
                return
            }
        }
    }

    let infoIcon = undefined

    if (infoLabel && onInfoLabelClick !== undefined) {
        infoIcon = <InfoOutlinedIcon fontSize='small' onClick={onInfoLabelClick} />
    }

    const fileInputClassName = clsx(
        styles.fileInput,
        className,
    )

    const mandatoryError = mandatory && !fileName && attemptedSubmission

    return (
        <Box className={fileInputClassName}>
            {!insetLabel && (
                <InputLabel>
                    <Typography variant='body2' className={styles.nameLabel}>
                        {label} {infoIcon} {mandatory ? ` *` : ``}
                    </Typography>
                </InputLabel>
            )}
            {
                description &&
                <Typography className={styles.description} variant='subtitle2' mb={1}>{description}</Typography>
            }
            <Box>
                <Box className={styles.controls}>
                    <Button className={styles.inputButton} variant='outlined' component='label' disabled={disabled}>
                        {buttonLabel ?? t(`select_file`)}
                        <input type='file' accept={allowedFileTypes} onChange={handleChange} hidden />
                    </Button>
                    {!hideFilename &&
                        <Box className={styles.fileName}>
                            <Typography variant='body1'>{fileName ?? ``}</Typography>
                        </Box>
                    }
                </Box>
                <Typography className={styles.error} variant='body1'>{localError ?? ``}</Typography>
                {
                    mandatoryError &&
                    <Typography className={styles.error} variant='body1'>{t(`file_input_mandatory`)}</Typography>
                }
            </Box>
        </Box>
    )
}

export default CustomFileInput