import { StrapiUpmeBenefitCategory, UpmeBenefitCategory } from '../../types/UpmeBenefitCategory'

const fromStrapiUpmeBenefitCategory = (strapiUpmeBenefitCategory: StrapiUpmeBenefitCategory): UpmeBenefitCategory => (
    {
        id: strapiUpmeBenefitCategory.id,
        name: strapiUpmeBenefitCategory.attributes.name,
        order: strapiUpmeBenefitCategory.attributes.order,
    }
)

export default fromStrapiUpmeBenefitCategory