import { useContext, useEffect, useRef, useState } from 'react'
import { Container, Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { useUtapRepository } from '../../../../repositories/Utap/use-utap-repository'
import { MyInfoUserData } from '../../../../types'
import { UtapClaimItem } from '../../../../types/UtapClaim'
import ClaimCard from '../../../molecules/ClaimCard/ClaimCard'
import CommonBackButton from '../../../atoms/CommonBackButton/CommonBackButton'
import { UserContext } from '../../../../stores/User/UserStore'
import SplashScreenGuard from '../../../../router/guards/SplashScreenGuard'
import ClaimsListSkeleton from './ClaimsList.skeleton'
import { Route } from '../../../../router'
import { createMyInfoUserData } from '../../../../helpers/utils/myinfoData'
import useTrackPageView from "../../../../helpers/hooks/use-track-page-view"

import styles from './ClaimsList.module.css'

const DEFAULT_PAGE_SIZE = 20

const ClaimsList = () => {
    useTrackPageView(`UTAP Claims List Page`)

    const { t } = useTranslation()
    const { state: userState } = useContext(UserContext)

    const { retrieveUtapClaims, retrieveUtapStatus } = useUtapRepository()

    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE)
    const [sentinelRef, setSentinelRef] = useState(null)
    const lastItemRef = useRef(null)

    const utapClaimsHistory: UtapClaimItem[] = userState.utapClaims?.data ?? []

    const uinFinData: MyInfoUserData = userState && createMyInfoUserData(userState)

    useEffect(() => {
        retrieveUtapClaims(uinFinData, pageSize)
        retrieveUtapStatus(uinFinData)
    }, [pageSize])

    useEffect(() => {
        if (sentinelRef) {
            setPageSize((prevPageSize) => prevPageSize + DEFAULT_PAGE_SIZE)
        }
    }, [sentinelRef])

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setSentinelRef(lastItemRef.current)
                }
            },
        )

        if (lastItemRef.current) {
            observer.observe(lastItemRef.current)
        }

        return () => {
            if (lastItemRef.current) {
                observer.unobserve(lastItemRef.current)
            }
        }
    }, [utapClaimsHistory])

    return (
        <SplashScreenGuard>
            <ClaimsListSkeleton condition={!!utapClaimsHistory}>
                <Grid className={styles.pageContainer}>
                    <Container className={styles.claimsHeading}>
                        <CommonBackButton path={Route.UTAP_LANDING} disableLabel />
                        <Typography variant={`h6`}>{t(`utap_claims`)}</Typography>
                    </Container>

                    {
                        utapClaimsHistory.length === 0 &&
                        (
                            <Container className={styles.errorContainer}>
                                <Typography variant={`subtitle1`}>{t(`utap_claims_empty_title`)}</Typography>
                                <Typography variant={`body1`}>{t(`utap_claims_empty_description`)}</Typography>
                            </Container>
                        )
                    }

                    <Container className={styles.gridContainer}>
                        {
                            utapClaimsHistory.map((utapClaim: UtapClaimItem, index: number) => {
                                if (index === utapClaimsHistory.length - 1) {
                                    return <ClaimCard utapClaim={utapClaim} key={utapClaim.referenceNumber} reference={lastItemRef} />
                                }
                                return <ClaimCard utapClaim={utapClaim} key={utapClaim.referenceNumber} />
                            })
                        }
                    </Container>
                </Grid>
            </ClaimsListSkeleton>
        </SplashScreenGuard >
    )
}

export default ClaimsList