import { fetchApplicationTermsOfUsePage } from '../../clients/pages'
import { ApplicationTermsOfUsePage } from '../../types'
import { fromStrapiApplicationTermsOfUsePage } from '../../helpers/mapping/pwa-terms-and-conditions-page'

class ApplicationTermsOfUsePageRepository {

    async getPage(): Promise<ApplicationTermsOfUsePage> {
        const { data } = await fetchApplicationTermsOfUsePage()
        return fromStrapiApplicationTermsOfUsePage(data)
    }
}

export default ApplicationTermsOfUsePageRepository