import { fetchJoinPage } from '../../clients/pages'
import { fromStrapiJoinPage } from '../../helpers/mapping/join-page'
import { JoinPage } from '../../types'

class JoinPageRepository {

    async get(): Promise<JoinPage> {
        const { data }= await fetchJoinPage()
        return fromStrapiJoinPage(data)
    }
}

export default JoinPageRepository