import { useContext, useEffect, useState } from 'react'
import { useTranslation } from "react-i18next"
import { Box, Button, Container, Divider, Grid, Typography, useTheme, Link } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import SplashScreenGuard from "../../../../router/guards/SplashScreenGuard"
import { useAuthenticatedGuard } from "../../../../router/guards/authenticated-guard"
import { UserContext } from "../../../../stores/User/UserStore"
import { useMembershipRepository } from "../../../../repositories/Membership/use-membership-repository"
import { Route, STRAPI_URL } from '../../../../router'
import CommonBackButton from '../../../atoms/CommonBackButton/CommonBackButton'
import { MembershipType } from '../../../../types/MembershipType'
import LocalStorageKeys from '../../../../types/LocalStorageKeys'
import { MyInfoReason } from '../../../../types/MyInfoUserData'
import { MembershipContext } from '../../../../stores/Membership/MembershipStore'
import { useUserInterestTopicsRepository } from '../../../../repositories/UserInterestTopics/use-user-interest-topics'
import { UserInterestTopic } from '../../../../types/UserInterestTopic'
import useProfileUpdateGuard from '../../../../router/guards/profile-update-guard'
import ProfileUpdateSummarySkeleton from './ProfileUpdateSummary.skeleton'
import UpmeProfileUpdateInfo from '../../../molecules/UpmeProfileUpdateInfo/UpmeProfileUpdateInfo'
import StarterProfileUpdateInfo from '../../../molecules/StarterProfileUpdateInfo/StarterProfileUpdateInfo'
import useTrackPageView from '../../../../helpers/hooks/use-track-page-view'
import useGoogleTagManager from '../../../../helpers/analytics/use-google-tag-manager'
import GtmEventName from '../../../../helpers/analytics/GtmEventName'

import styles from './ProfileUpdateSummary.module.scss'

const UPME_PROFILE_UPDATE_URL = `https://e-services.ntuc.org.sg/updateparticulars`

const ProfileUpdateSummary = () => {
    useTrackPageView(`Update Profile Page`)

    useAuthenticatedGuard(Route.PROFILE_SUMMARY)
    useProfileUpdateGuard()

    const [isLoading, setIsLoading] = useState(false)
    const [interestTopics, setInterestTopics] = useState<UserInterestTopic[]>()

    const { t } = useTranslation()
    const navigate = useNavigate()
    const { trackButtonClick } = useGoogleTagManager()

    const { state: userState } = useContext(UserContext)
    const { state: membershipState } = useContext(MembershipContext)

    const { retrieveMembership } = useMembershipRepository()
    const { retrieveUserInterestTopics } = useUserInterestTopicsRepository()
    const { palette } = useTheme()

    const userMembership = userState.membership?.data
    const upmeInterestTopics = userMembership?.upmeInterestTopics
    const starterInterestTopics = userMembership?.starterInterestTopics

    const memberInterestTopics = userMembership?.type === MembershipType.UPME ? upmeInterestTopics : starterInterestTopics

    useEffect(() => {
        retrieveMembership()
        retrieveUserInterestTopics()
    }, [])

    useEffect(() => {
        if (membershipState.userInterestTopics?.data) {
            setInterestTopics(membershipState.userInterestTopics.data.filter(topic => {
                return topic.membershipScope === userMembership?.type
            }))
        }
    }, [membershipState.userInterestTopics?.data])

    const mappedInterestTopicOfUserFromTopics = (interestTopics?: UserInterestTopic[], memberTopics?: number[]) => {
        const filteredMemberTopics = interestTopics?.filter((topic) => {
            return memberTopics?.includes(topic.id)
        })

        return filteredMemberTopics
    }

    const interestEntry = (topic: UserInterestTopic) => {
        return (
            <Box key={`topic-${topic.id}`} className={styles.topicEntry}>
                <Box component="img" className={styles.topicImage} alt={`${topic.title}`} src={topic.image} />
                <Typography variant="subtitle1" className={styles.topicTitle} mb={1}>{topic.title}</Typography>
            </Box>
        )
    }

    const handleUpdateInterests = () => {
        trackButtonClick(GtmEventName.UPDATE_INTERESTS_CLICK)
        const currentLocation = window.location.pathname
        navigate(Route.MEMBERSHIP_USER_INTERESTS, { state: { from: currentLocation } })
    }

    const handleUpdateProfile = (membershipType: MembershipType) => {
        trackButtonClick(GtmEventName.UPDATE_PROFILE_CLICK)

        if (membershipType === MembershipType.STARTER) {
            setIsLoading(true)
            localStorage.setItem(LocalStorageKeys.VISITED_PROFILE_UPDATE, MyInfoReason.PROFILE_UPDATE)
            window.location.href = `${STRAPI_URL}${Route.MYINFO_AUTHORISE}?reason=${MyInfoReason.PROFILE_UPDATE}`
        } else {
            window.open(`${UPME_PROFILE_UPDATE_URL}`, `_blank`)
        }
    }

    const profileInterests = memberInterestTopics?.length ? mappedInterestTopicOfUserFromTopics(interestTopics, memberInterestTopics)?.map(interestEntry) : <Typography variant="body1">{t(`profile_update_interests_none`)}</Typography>

    const updateStarterProfileButton = userMembership?.type === MembershipType.STARTER && <Button variant="contained" disabled={isLoading} onClick={() => handleUpdateProfile(userMembership?.type)} fullWidth>{t(`profile_update_button`)}</Button>

    const updateUpmeProfileLink = userMembership?.type === MembershipType.UPME && <Link color="inherit" component="button" target="_blank" rel="noreferrer" onClick={() => handleUpdateProfile(userMembership?.type)}>{t(`profile_update_upme_link`)}</Link>

    const updateProfile = updateStarterProfileButton || updateUpmeProfileLink

    const profileUpdateInfo = userMembership?.type === MembershipType.UPME ? userMembership && <UpmeProfileUpdateInfo userMembership={userMembership} /> : userMembership && <StarterProfileUpdateInfo userMembership={userMembership} />

    const memberThemeColor = {
        color: palette.primary.main,
    }

    return (
        <SplashScreenGuard>
            <ProfileUpdateSummarySkeleton condition={!!userMembership}>
                <Container>
                    <CommonBackButton path={Route.HOME} />
                    <Grid container direction="column" spacing={1}>
                        <Box className={styles.personalInfo}>
                            <Typography variant="h3" mb={1} sx={memberThemeColor}>{t(`sign_up_title`)}</Typography>
                            {profileUpdateInfo}
                            <Box className={styles.updateButton}>
                                {updateProfile}
                            </Box>
                        </Box>
                        <Divider />
                        <Box className={styles.interests}>
                            <Typography variant="h3" mb={1} sx={memberThemeColor}>{t(`profile_interests`)}</Typography>
                            <Box className={styles.topicsList}>
                                {profileInterests}
                            </Box>
                            <Box className={styles.updateButton}>
                                <Button variant="contained" disabled={isLoading} onClick={handleUpdateInterests} fullWidth>{t(`profile_interests_button`)}</Button>
                            </Box>
                        </Box>
                    </Grid>
                </Container>
            </ProfileUpdateSummarySkeleton>
        </SplashScreenGuard>
    )
}

export default ProfileUpdateSummary