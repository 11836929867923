import { fromStrapiPrivacyPolicy } from '../../helpers/mapping/privacyPolicy'
import { PrivacyPolicyPage } from '../../types'
import { fetchPrivacyPolicyPage } from '../../clients/pages'

class PrivacyPolicyRepository {

    async getPage(): Promise<PrivacyPolicyPage> {
        const { data } = await fetchPrivacyPolicyPage()
        return fromStrapiPrivacyPolicy(data)
    }
}

export default PrivacyPolicyRepository