import { Box, Typography } from "@mui/material"
import { useContext, useEffect } from 'react'
import { useTranslation } from "react-i18next"

import { AppContext } from '../../../stores/App/AppStore'
import SplashScreenGuard from "../../../router/guards/SplashScreenGuard"
import HighlightCarousel from "../../molecules/HighlightCarousel/HighlightCarousel"
import { HighlightCarouselItemImageAspectRatio } from "../../molecules/HighlightCarouselItemComponent/HighlightCarouselItemComponent"
import DealsHomeList from '../../organisms/DealsHomeList/DealsHomeList'
import { useHomeCarouselRepository } from "../../../repositories/HomeCarousel/use-home-carousel-repository"
import useDealRepository from "../../../repositories/Deal/use-deal-repository"
import { useDigitalResourceRepository } from "../../../repositories/DigitalResource/use-digital-resource.repository"
import { domainCheckMembershipType } from "../../../helpers/utils/common"
import ProgrammeApplicationFormsHomeList from "../../organisms/ProgrammeApplicationFormsHomeList/ProgrammeApplicationFormsHomeList"
import { useProgrammeRepository } from '../../../repositories/Programme/use-programme-repository'
import ProgrammesList from '../../organisms/ProgrammesList/ProgrammesList'
import LandingFooter from "../../molecules/LandingFooter/LandingFooter"
import { ProgrammeContext } from "../../../stores/Programme/ProgrammeStore"
import useTrackPageView from "../../../helpers/hooks/use-track-page-view"

import styles from "./DefaultLandingPage.module.scss"

const DEFAULT_PAGE_SIZE = 3

const DefaultLandingPage = () => {
    useTrackPageView(`Default Landing Page`)

    const { t } = useTranslation()

    const { state } = useContext(AppContext)
    const { state: programmeState } = useContext(ProgrammeContext)

    const { retrieveProgrammes } = useProgrammeRepository()
    const { retrieveHomeCarousel } = useHomeCarouselRepository()
    const { retrieveDeals } = useDealRepository()
    const { retrieveLimitedDigitalResourcesByCategory } = useDigitalResourceRepository()

    const membershipTypeFromDomainName = domainCheckMembershipType()

    const homeCarouselItems = state.homeCarouselItems?.data
    const deals = state.deals?.data
    const programmes = programmeState.programmes?.data

    useEffect(() => {
        retrieveHomeCarousel(membershipTypeFromDomainName)
        retrieveLimitedDigitalResourcesByCategory(membershipTypeFromDomainName)
        retrieveDeals(membershipTypeFromDomainName, DEFAULT_PAGE_SIZE)
        retrieveProgrammes(membershipTypeFromDomainName)
    }, [membershipTypeFromDomainName])

    return (
        <SplashScreenGuard>
            <Box className={styles.container}>
                <Box className={styles.carouselGridItem}>
                    <HighlightCarousel highlightCarouselItems={homeCarouselItems} aspectRatio={HighlightCarouselItemImageAspectRatio.RECTANGLE} showBackground={true} />
                </Box>
                <Box>
                    <Typography variant='h3' mt={2} color={`primary`}>{t(`pillar_section`)}</Typography>
                </Box>
                <Box className={styles.programmeApplicationFormsList} >
                    <ProgrammeApplicationFormsHomeList membershipType={membershipTypeFromDomainName} />
                </Box>
                <Box className={styles.dealsList}>
                    <DealsHomeList deals={deals} />
                </Box>
                <Box className={styles.programmesList}>
                    <ProgrammesList programmes={programmes} />
                </Box>
                <LandingFooter />
            </Box>
        </SplashScreenGuard>
    )
}

export default DefaultLandingPage
