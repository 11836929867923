import i18next from 'i18next'
import { z as zod } from 'zod'

const PHONE_NUMBER_LENGTH = 8

const MINIMUM_CLAIM_VALUE = 1

const phoneNumberValidator = zod
    .string()
    .min(PHONE_NUMBER_LENGTH, `Must be 8 digits`)
    .max(PHONE_NUMBER_LENGTH, `Must be 8 digits only`)

const emailAddressValidator = zod
    .string()
    .email(`Must be a valid email`)

const postalCodeValidator = zod
    .string()
    .regex(/\d{5,6}/, { message: i18next.t(`sign_up_postal_code_wrong_format`) })

const floorNumberValidator = zod.string().regex(/\d*/)

const unitNumberValidator = zod.string().regex(/\d*/)

const netFeePaidValidator = () => {
    return zod.string()
        .regex(/^\d*(?:(?=\.)\.\d{0,2})?$/, { message: i18next.t(`utap_claim_course_fee_invalid`) })
        .refine(input => {
            const fees = parseFloat(input)
            return fees >= MINIMUM_CLAIM_VALUE
        }, { message: i18next.t(`utap_claim_course_fee_minimum`, { minimum: MINIMUM_CLAIM_VALUE }) })
}

const UTAP_FORM_VALIDATION = {
    MINIMUM_CLAIM_VALUE,
    phoneNumberValidator,
    emailAddressValidator,
    postalCodeValidator,
    floorNumberValidator,
    unitNumberValidator,
    netFeePaidValidator,
}

export default UTAP_FORM_VALIDATION