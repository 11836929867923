import { getLocalVersion } from '../../helpers/utils/version'
import {
    ApplicationTermsOfUsePage,
    Bank,
    CuratedExperienceDetail,
    CuratedExperiencePage,
    EventDetail,
    FaqEntry,
    FaqTags,
    FieldOfStudy,
    HomePage,
    Institution,
    JoinPage,
    MembershipCardPage,
    MembershipSuccessPage,
    Pillar,
    PrivacyPolicyPage,
    RenewalSuccessPageServiceResponse,
    StripePaymentOrderHistoryServiceResponse,
    StripePaymentOrderResultServiceResponse,
    StripePaymentOrderServiceResponse,
    SplashScreenVisit,
    TermsAndConditions,
    TrainingProvider,
    UtapCoursesServiceResponse,
    UtapLandingPage,
    Version,
    DealsServiceResponse,
    ServiceResponse,
    HighlightCarouselItem,
} from '../../types'
import { ContactUsPage } from '../../types/ContactUsPage'
import { SimpleFAQEntry } from '../../types/SimpleFAQEntry'

type AppState = {
    currentPageTitle: string,
    currentPageItem?: string,
    events?: ServiceResponse<EventDetail[]>,
    pillars: {
        pillarsArray?: ServiceResponse<Pillar[]>,
        curatedExperience: {
            experiences: CuratedExperienceDetail[],
            curatedExperiencePage: CuratedExperiencePage
        }
    }
    pages: {
        aboutCarouselItems: ServiceResponse<HighlightCarouselItem[]>
        aboutFaqItems: ServiceResponse<SimpleFAQEntry[]>,
        home: HomePage,
        join: JoinPage,
        success: MembershipSuccessPage,
        renewalSuccess: RenewalSuccessPageServiceResponse,
        membershipCard: MembershipCardPage,
        privacyPolicy: PrivacyPolicyPage,
        contactUsPage: ContactUsPage,
        faqs: FaqEntry[],
        faqTags: FaqTags[],
        applicationTermsOfUse: ApplicationTermsOfUsePage,
    }
    terms: TermsAndConditions[],
    paymentOrder: StripePaymentOrderServiceResponse,
    paymentOrderResult: StripePaymentOrderResultServiceResponse,
    paymentOrderHistory: StripePaymentOrderHistoryServiceResponse,
    institutions: Institution[],
    fieldsOfStudy: FieldOfStudy[],
    version: Version,
    trainingCourse: UtapCoursesServiceResponse,
    trainingProvider: TrainingProvider[],
    bank: Bank[],
    utapLanding: UtapLandingPage,
    splashScreenVisit?: SplashScreenVisit,
    deals?: DealsServiceResponse
    homeCarouselItems?: ServiceResponse<HighlightCarouselItem[]>
}

const AppInitialState: AppState = {
    currentPageTitle: `STARTER`,
    currentPageItem: undefined,
    events: undefined,
    pillars: {
        pillarsArray: undefined,
        curatedExperience: {
            experiences: [],
            curatedExperiencePage: {},
        },
    },
    pages: {
        aboutCarouselItems: {},
        aboutFaqItems: {},
        home: {},
        join: {},
        success: {},
        renewalSuccess: {},
        membershipCard: {},
        privacyPolicy: {},
        contactUsPage: {},
        faqs: [],
        faqTags: [],
        applicationTermsOfUse: {},
    },
    terms: [],
    paymentOrder: {},
    paymentOrderResult: {},
    paymentOrderHistory: {},
    institutions: [],
    fieldsOfStudy: [],
    version: {
        current: getLocalVersion(),
        upcoming: undefined,
    },
    trainingCourse: {},
    trainingProvider: [],
    bank: [],
    utapLanding: {},
    splashScreenVisit: undefined,
    deals: undefined,
    homeCarouselItems: undefined,
}

export { AppInitialState }
export type { AppState }