import { useContext } from 'react'

import { UserActionTypes } from '../../stores/User/UserReducer'
import { UserContext } from '../../stores/User/UserStore'
import MyInfoRepository from './myinfo.repository'
import { useSessionRepository } from '../Session/use-session-repository'
import { MyInfoUserDataResponse } from '../../types/MyInfoUserData'
import MembershipRepository from '../Membership/membership.repository'
import { starterDataError } from '../../helpers/utils/error-handling'

const useMyInfoUserDataRepository = () => {
    const { dispatch } = useContext(UserContext)
    const { saveJwtAsSession } = useSessionRepository()
    const myInfoRepository = new MyInfoRepository()
    const membershipRepository = new MembershipRepository()

    const retrieveMyInfoUserData = (code: string, state: string, liftAgeLimit: boolean, reason?: string) => {
        const myInfoUserData: MyInfoUserDataResponse = {}

        myInfoRepository.get(code, state, liftAgeLimit, reason)
            .then(data => {
                if (data.jwt) {
                    saveJwtAsSession(data.jwt)
                }

                if (data.userBecameUnionMember) {
                    membershipRepository.setUserJustBecameUnionMember()
                }

                myInfoUserData.data = {
                    contentHash: data.contentHash,
                    identifierHash: data.identifierHash,
                    content: data.content,
                }
            })
            .catch(error => {
                myInfoUserData.error = starterDataError(error)
            })
            .finally(() => {
                dispatch({
                    type: UserActionTypes.MYINFO_DATA_RETRIEVED,
                    data: myInfoUserData,
                })
            })
    }

    return {
        retrieveMyInfoUserData,
    }
}

export { useMyInfoUserDataRepository }