import { useEffect, useState } from 'react'
import clsx from 'clsx'
import { ZodString } from 'zod'
import { InputLabel, TextField, Typography } from '@mui/material'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

import Spacer from '../Spacer/Spacer'

import styles from './CustomDatePicker.module.css'

type CustomDatePickerProps = {
    fullWidth?: boolean,
    eagerChange?: boolean,
    insetLabel?: boolean
    label: string
    onChange?: (value: Date | null) => void
    value: Date | null
    readOnly?: boolean
    error?: boolean, validation?: ZodString
    minDate?: Date | null
    maxDate?: Date | null
}

const CustomDatePicker = ({
    fullWidth,
    insetLabel,
    label,
    onChange,
    value,
    readOnly,
    error,
    minDate,
    maxDate,
}: CustomDatePickerProps) => {
    const [dateValue, setDateValue] = useState<Date | null>(value)
    const [localError, setLocalError] = useState<string | null>(null)

    useEffect(() => {
        if (error) {
            setLocalError(`Required`)
        } else {
            setLocalError(null)
        }
    }, [error])

    const handleChange = (value: Date | null) => {
        setDateValue(value)
        if (onChange) {
            onChange(value)
        }
    }

    return (
        <>
            {!insetLabel && (
                <InputLabel>
                    <Typography variant="body2">
                        {label}
                    </Typography>
                </InputLabel>
            )}
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                    inputFormat="DD/MM/yyyy"
                    className={clsx(
                        { [styles.readOnly]: readOnly },
                        { [styles.fullWidth]: fullWidth },
                    )}
                    value={dateValue}
                    onChange={handleChange}
                    minDate={minDate ?? undefined}
                    maxDate={maxDate ?? undefined}
                    renderInput={(params) => <TextField
                        {...params} sx={{ width: `100%` }}
                        error={!!localError || error}
                        helperText={localError}
                        color='functional'
                    />}
                />
            </LocalizationProvider>
            {!localError && (
                <Spacer height={23} />
            )}
        </>
    )

}

export default CustomDatePicker