import { ArrowBackIosNew, Close } from '@mui/icons-material'
import { ButtonBase, Grid } from '@mui/material'
import { useNavigate, useSearchParams } from 'react-router-dom'
import clsx from 'clsx'

import { Route } from '../../../router'

import styles from './CommonBackButton.module.css'
import { UserContext } from '../../../stores/User/UserStore'
import { useContext } from 'react'
import { isMembershipStatusAllowedInApp } from '../../../types/MembershipStatus'
import { fireEmptyVisitedSplashScreen } from '../../../stores/App/AppReducer'
import { AppContext } from '../../../stores/App/AppStore'

type CommonBackButtonProps = {
    label?: string,
    path?: string,
    disableLabel?: boolean
    icon?: `Back` | `Close`,
    isLight?: boolean,
    onClick?: () => void,
}

const CommonBackButton = ({
    label,
    path,
    disableLabel,
    icon,
    isLight = false,
    onClick,
}: CommonBackButtonProps) => {
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()

    const { state: userState } = useContext(UserContext)
    const { dispatch: dispatchAppEvent } = useContext(AppContext)

    const handleClick = () => {
        if (!isMembershipStatusAllowedInApp(userState.membership?.data?.status)) {
            fireEmptyVisitedSplashScreen(dispatchAppEvent)
        }

        if (onClick) {
            onClick()
        } else if (path) {
            navigate(path)
        } else if (searchParams.get(`fromAuth`)) {
            navigate(Route.HOME)
        } else if (window.history.state && window.history.state.idx > 0) {
            // eslint-disable-next-line no-magic-numbers
            navigate(-1)
        } else {
            navigate(Route.HOME, { replace: true })
        }
    }

    return (
        <Grid item>
            <ButtonBase
                className={clsx({
                    [styles.button]: true, //always applies
                    [styles.light]: isLight, //only when open === true
                })}
                // eslint-disable-next-line no-magic-numbers
                onClick={handleClick}
            >
                {!icon || icon == `Back` ? <ArrowBackIosNew /> : <Close />}
                {!disableLabel ? label ?? `Back` : null}
            </ButtonBase>
        </Grid>
    )
}

export default CommonBackButton