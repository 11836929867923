import { useContext, useEffect } from "react"
import { useParams } from "react-router-dom"
import { startCase } from 'lodash'

import { DigitalResourceDetail } from "../../../../types/DigitalResourceDetail"
import DigitalResourceCard from "../../../molecules/DigitalResourceCard/DigitalResourceCard"
import { useMembershipRepository } from "../../../../repositories/Membership/use-membership-repository"
import { useDigitalResourceRepository } from "../../../../repositories/DigitalResource/use-digital-resource.repository"
import { UserContext } from "../../../../stores/User/UserStore"
import { MembershipType } from "../../../../types/MembershipType"
import { UPMEContentContext } from "../../../../stores/UPMEContent/UPMEContentStore"
import CardsList from "../../../molecules/CardsList/CardsList"
import useTrackPageView from "../../../../helpers/hooks/use-track-page-view"

const DigitalResourceListByCategory = () => {
    const { category } = useParams()

    useTrackPageView(`Digital Resources Page`, category)

    const { retrieveDigitalResourceCategories, retrieveDigitalResourceByCategory } = useDigitalResourceRepository()
    const { retrieveMembership } = useMembershipRepository()

    const { state: userState } = useContext(UserContext)
    const { state: upmeContentState } = useContext(UPMEContentContext)

    const userMembershipType = userState.membership?.data?.type
    const membershipType = userMembershipType === MembershipType.UPME ? MembershipType.UPME : MembershipType.STARTER
    const categoryId = upmeContentState.digitalResourceCategories?.data?.find((cat) => cat.name.toLowerCase() === category?.toLowerCase())?.id
    const digitalResourceList = upmeContentState?.digitalResourceListByCategory?.data ?? []

    useEffect(() => {
        retrieveMembership()
    }, [])

    useEffect(() => {
        retrieveDigitalResourceCategories()

        if (categoryId) {
            retrieveDigitalResourceByCategory(categoryId, membershipType)
        }
    }, [categoryId, userState.membership])

    const resourceId = (resource: DigitalResourceDetail) => {
        return `resource-${resource.id}`
    }

    const resourceCard = (resource: DigitalResourceDetail) => {
        const placeHolderImage = upmeContentState.digitalResourceCategories?.data?.find((categoryState) => categoryState.name.toLowerCase() === category?.toLowerCase())?.placeHolderImage ?? ``
        return <DigitalResourceCard digitalResource={resource} id={resourceId(resource)} key={resourceId(resource)} placeHolderImage={placeHolderImage} fullWidth />
    }

    return (
        <CardsList itemsTitle={startCase(category)} items={digitalResourceList} cardRenderer={resourceCard} showFilterBy={false} />
    )
}

export default DigitalResourceListByCategory
