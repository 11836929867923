import { Membership, SignUpForm, UserInfo } from "../../types"

const maskEmail = (userEmail : string | undefined) => {
    if (userEmail){
        const splitted = userEmail.split(`@`)
        let firstPart = splitted[0]
        // eslint-disable-next-line no-magic-numbers
        const avgLength = firstPart.length / 2
        firstPart = firstPart.substring(0, (firstPart.length - avgLength))
        const secondPart = splitted[1]
        return firstPart + `...@` + secondPart
    }
    else{
        return ``
    }
}

const maskPhoneNumber = (phoneNumber : string | undefined) => {
    if (phoneNumber){
        // eslint-disable-next-line no-magic-numbers
        const last4digits = phoneNumber.slice(-4)
        return `****` + last4digits
    }
    else{
        return ``
    }

}

const myinfoCallbackPageMessageTemplate = (unionMembershipStatus: string, fetchReason: string) => {
    return `User fetch myinfo with reason: ${fetchReason} with union membership status: ${unionMembershipStatus}.`
}

const paymentPageMessageTemplate = (paymentAmt?: string, membership?: Membership, user?: UserInfo, partnershipKey?: string ) => {
    return `User with partnership key ${partnershipKey},
    membership: {id: ${membership?.id}, email: ${maskEmail(membership?.emailAddress)}, status: ${membership?.status}} and
    user: {id: ${user?.id}, liftAgeLimit : ${user?.liftAgeLimit}, liftNTUCUnionCheck: ${user?.liftNTUCUnionCheck}}
    made ${paymentAmt} in payment.`
}

const membershipFormPageMessageTemplate = (formData: SignUpForm) => {
    return `User completed membership registration with the 
    form data: {name: ${formData.fullName}, email: ${maskEmail(formData.emailAddress)}, number: ${maskPhoneNumber(formData.phoneNumber)}, 
    residential status: ${formData.residentialStatus} and postal code: ${formData.postalCode}}.`
}

const profilePageMessageTemplate = (membership?: Membership) => {
    return `User with membership details: 
    {id: ${membership?.id}, status: ${membership?.status}} pressed Union member CTA button on profile page.`
}

const UPMEIntroductionPageMessageTemplate = (membership?: Membership) => {
    return `User with membership details: 
    {id: ${membership?.id}, status: ${membership?.status}} pressed Union member external CTA button on 
    UPME Introduction Page.`
}

const UPMEUserInterestPageMessageTemplate = (membership?: Membership, interestId?: number[]) => {
    return `User with membership details: 
    {id: ${membership?.id}, status: ${membership?.status}} selected their interests: ${interestId?.toString()} on 
    UPME User Interest Page.`
}

const EventsDetailsPageRegistrationMessageTemplate = (membership?: Membership, eventType?: string) => {
    return `User with membership details: 
    {id: ${membership?.id}, status: ${membership?.status}} pressed ${eventType} registration button on 
    Event Details Page.`
}

const errorPopupMessageTemplate = (errorMessage?: string, membershipId?: string) => {
    if (!errorMessage || errorMessage.length === 0){
        return ``
    }
    return `User with membershipId: ${membershipId} encountered error: ${errorMessage}.`
}

const generateErrorCode = () => {
    const crypto = window.crypto
    const array = new Uint32Array(1)
    return crypto.getRandomValues(array).toString()
}

export {
    myinfoCallbackPageMessageTemplate,
    paymentPageMessageTemplate,
    membershipFormPageMessageTemplate,
    profilePageMessageTemplate,
    UPMEIntroductionPageMessageTemplate,
    UPMEUserInterestPageMessageTemplate,
    EventsDetailsPageRegistrationMessageTemplate,
    errorPopupMessageTemplate,
    generateErrorCode,
}