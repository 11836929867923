import moment from "moment"
import { DatePatterns } from "../../types/date-patterns"

const eventDateStringWithTimeFormatter = (startDate: Date, startTime: string, endTime: string, endDate: Date): string => {
    if (!startDate && !endDate && !startTime && !endTime) {
        return ``
    }

    const eventStartDate = startDate ? moment(startDate).format(DatePatterns.FULL_DATE) : ``
    const eventEndDate = endDate ? moment(endDate).format(DatePatterns.FULL_DATE) : ``
    const dateDisplay = eventStartDate && eventEndDate ? `${eventStartDate} - ${eventEndDate}` : eventStartDate

    const startTimeDisplay = startTime ? moment(startTime, DatePatterns.TWENTY_FOUR_HOUR_TIME).format(DatePatterns.SHORT_TIME) : ``
    const endTimeDisplay = endTime ? moment(endTime, DatePatterns.TWENTY_FOUR_HOUR_TIME).format(DatePatterns.SHORT_TIME) : ``
    const timeDisplay = startTimeDisplay && endTimeDisplay ? `${startTimeDisplay} - ${endTimeDisplay}` : startTimeDisplay || endTimeDisplay

    if (dateDisplay && timeDisplay) {
        return `${dateDisplay} | ${timeDisplay}`
    }

    if (dateDisplay) {
        return dateDisplay
    }

    return timeDisplay
}

const eventDayDateStringFormatter = (startDate: Date, endDate?: Date | undefined): string => {
    if (!startDate) {
        return ``
    }

    const eventStartDate = startDate ? moment(startDate).format(DatePatterns.DAY_DATE) : ``
    const eventEndDate = endDate ? moment(endDate).format(DatePatterns.FULL_DATE) : ``
    const dateDisplay = eventStartDate && eventEndDate ? `${eventStartDate} - ${eventEndDate}` : eventStartDate

    return dateDisplay
}

export {
    eventDayDateStringFormatter,
    eventDateStringWithTimeFormatter,
}