import { HighlightCarouselItem, StrapiHighlightCarouselItem } from '../../types/HighlightCarouselItem'
import { absoluteImageUrl } from '../utils/environment'

const fromStrapiHighlightCarouselItem = (strapiHighlightCarouselItem: StrapiHighlightCarouselItem): HighlightCarouselItem => (
    {
        id: strapiHighlightCarouselItem.id,
        title: strapiHighlightCarouselItem.title,
        description: strapiHighlightCarouselItem.description,
        image: absoluteImageUrl(strapiHighlightCarouselItem.image?.data?.attributes?.url),
        url: strapiHighlightCarouselItem.url,
        route: strapiHighlightCarouselItem.route,
        membershipPlanUrl: strapiHighlightCarouselItem.membershipPlanUrl,
        hideForLoggedInUsers: strapiHighlightCarouselItem.hideForLoggedInUsers ?? false,
    }
)

export default fromStrapiHighlightCarouselItem