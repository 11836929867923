import { fromStrapiBank } from '../../helpers/mapping/bank'
import { Bank } from '../../types'
import { fetchBank } from '../../clients/bank'

class BankRepository {

    async getAll(): Promise<Bank[]> {
        const { data } = await fetchBank()
        return data.map((i: unknown) => fromStrapiBank(i))
    }
}

export default BankRepository