import { MutableRefObject } from "react"
import { useTranslation } from 'react-i18next'
import { Grid, Typography } from "@mui/material"
import { EventDetail } from "../../../types"

import EventCard from "../../molecules/EventCard/EventCard"

const EventsList = ({ events, reference }: { events: EventDetail[], reference?: MutableRefObject<null> }) => {
    const { t } = useTranslation()

    const sortedEvents = [...events].sort((a: EventDetail, b: EventDetail) => {
        if (a.order === b.order) {
            return a.title.localeCompare(b.title)
        }

        if (a.order === 0) {
            return 1
        }

        if (b.order === 0) {
            // eslint-disable-next-line no-magic-numbers
            return -1
        }

        return a.order - b.order
    })

    const eventsComponents = sortedEvents.map((event) => (
        <Grid item key={event.id}>
            <EventCard event={event} ref={reference} fullWidth />
        </Grid>
    ))

    if (!events.length) {
        return (
            <Typography variant="h6" alignItems="center">{t(`event_no_registered_events`)}</Typography>
        )
    }

    return (
        <Grid container direction='column' spacing={2}>
            {eventsComponents}
        </Grid>
    )
}

export default EventsList