import { useState, useEffect, useContext } from "react"
import { useTranslation } from 'react-i18next'
import { Divider, Grid, Box, useTheme } from "@mui/material"
import { AnchorDirection } from "../../../types/AnchorDirection"
import { useLocation, useNavigate } from "react-router-dom"
import { isNil } from 'lodash'

import { Route } from "../../../router"
import CustomDrawer from "../../atoms/CustomDrawer/CustomDrawer"
import ProfileInfo from "../ProfileInfo/ProfileInfo"
import { MembershipType } from "../../../types/MembershipType"
import MenuEntry from "../../atoms/MenuEntry/MenuEntry"
import ProfileReferralCode from "../ProfileReferralCode/ProfileReferralCode"
import { UserState } from "../../../stores/User/UserState"
import { getTemporaryCustomerPortalUrl } from "../../../clients/stripe-payment-order"
import LogoutDialog from "../LogoutDialog/LogoutDialog"
import { useSessionRepository } from "../../../repositories/Session/use-session-repository"
import { useMembershipRepository } from "../../../repositories/Membership/use-membership-repository"
import { useMembershipSettingsRepository } from "../../../repositories/MembershipSettings/use-membership-settings-repository"
import ConfirmationDialog from "../ConfirmationDialog/ConfirmationDialog"
import { MembershipStatus } from "../../../types/MembershipStatus"
import { formatToTwoDecimalPlaces, isNewCalendarYear } from "../../../helpers/utils/common"
import { useUtapRepository } from "../../../repositories/Utap/use-utap-repository"
import { UserContext } from "../../../stores/User/UserStore"
import { createMyInfoUserData } from "../../../helpers/utils/myinfoData"
import LocalStorageKeys from "../../../types/LocalStorageKeys"
import useGoogleTagManager from '../../../helpers/analytics/use-google-tag-manager'
import GtmEventName from '../../../helpers/analytics/GtmEventName'

import styles from "./ProfileMenu.module.scss"

enum MemberShipButtonState {
    NOT_MEMBER = `NOT_MEMBER`,
    TERMINATED = `TERMINATED`,
    ACTIVE = `ACTIVE`,
}
interface ProfileMenuProps {
    expanded: boolean
    handleMenuCloseClick: () => void
    membershipType?: MembershipType
    user: UserState
}

const PLS_URL = process.env.REACT_APP_PLS_URL
const PLS_SINGPASS_CLIENT_ID = process.env.REACT_APP_PLS_SINGPASS_CLIENT_ID

const ProfileMenu = (props: ProfileMenuProps) => {
    const { expanded, handleMenuCloseClick, membershipType, user } = props

    const { t } = useTranslation()
    const location = useLocation()
    const navigate = useNavigate()
    const { palette } = useTheme()
    const { trackButtonClick } = useGoogleTagManager()

    const { state: userState } = useContext(UserContext)

    const { disposeSession, isMember } = useSessionRepository()
    const { retrieveMembership } = useMembershipRepository()
    const { retrieveMembershipSettings } = useMembershipSettingsRepository()
    const { retrieveUtapStatus } = useUtapRepository()

    const [membershipButtonState, setMembershipButtonState] = useState(MemberShipButtonState.NOT_MEMBER)
    const [isLogoutDialogOpen, setIsLogoutDialogOpen] = useState(false)
    const [isCancelMembershipDialogOpen, setIsCancelMembershipDialogOpen] = useState(false)
    const [isExpanded, setIsExpanded] = useState(expanded)

    const userMembership = userState.membership?.data

    const hasUtapBalanceValueWithNric = userMembership?.nric && !isNil(userMembership?.utapBalance)
    const isDisplayTentativeUtapMessageForNewCalendarYear = isNewCalendarYear(userMembership?.utapBalanceLastUpdated)
    const tentativeLastKnownBalance = !isDisplayTentativeUtapMessageForNewCalendarYear && !isNil(userMembership?.utapBalance)

    const handleMembershipStatusChange = () => {
        if (userMembership?.status) {
            switch (userMembership.status) {
                case MembershipStatus.TERMINATED:
                    setMembershipButtonState(MemberShipButtonState.TERMINATED)
                    break
                case MembershipStatus.ACTIVE:
                case MembershipStatus.UNION_ACTIVE:
                    setMembershipButtonState(MemberShipButtonState.ACTIVE)
                    break
            }
        }
    }

    useEffect(() => {
        isMember().then((hasMembership => {
            hasMembership ? handleMembershipStatusChange() : setMembershipButtonState(MemberShipButtonState.NOT_MEMBER)
        }))
    }, [user.session, userMembership?.status])

    useEffect(() => {
        retrieveMembership()
        retrieveMembershipSettings()
        if (userMembership?.nric) {
            // Azure#9806 fetch utap bal only when full nric is stored
            const uinFinData = userState && createMyInfoUserData(userState)
            retrieveUtapStatus(uinFinData)
        }
    }, [])

    useEffect(() => {
        setIsExpanded(expanded)

        if (expanded) {
            localStorage.setItem(LocalStorageKeys.LAST_MENU_PAGE, location.pathname)
        }
    }, [expanded])

    const handleMembershipDraft = () => {
        handleMenuCloseClick()
        navigate(Route.MEMBERSHIP_DRAFT)
    }

    const handleMyActivityClick = () => {
        trackButtonClick(GtmEventName.VIEW_ACTIVITY_CLICK)
        handleMenuCloseClick()
        navigate(Route.PROFILE_MY_ACTIVITY)
    }

    const handleOnStripeCustomerProfileClick = () => {
        trackButtonClick(GtmEventName.MANAGE_SUBSCRIPTION_CLICK)
        handleMenuCloseClick()
        getTemporaryCustomerPortalUrl().then(url => {
            window.location.replace(url)
        })
    }

    const handleContactUsButtonClick = () => {
        trackButtonClick(GtmEventName.CONTACT_US_CLICK)
        handleMenuCloseClick()
        navigate(Route.CONTACT_US)
    }

    const handleLogOutButtonClick = () => {
        trackButtonClick(GtmEventName.LOG_OUT_CLICK)
        setIsLogoutDialogOpen(true)
    }

    const handleLogoutDialogConfirm = async (logoutFromLinkPass: boolean) => {
        disposeSession()
        if (logoutFromLinkPass && PLS_URL && PLS_SINGPASS_CLIENT_ID) {
            const logoutURL = new URL(`${PLS_URL}/logout?client_id=${encodeURIComponent(PLS_SINGPASS_CLIENT_ID)}&redirect_uri=${encodeURIComponent(window.location.origin)}`)
            window.location.replace(logoutURL)
        }
        setIsLogoutDialogOpen(false)
        handleMenuCloseClick()
        navigate(Route.ROOT)
    }

    const handleLogoutDialogDismiss = () => {
        setIsLogoutDialogOpen(false)
    }

    const handleCancelMembershipDialog = () => {
        setIsCancelMembershipDialogOpen(false)
    }

    const handleCancelMembershipButtonClick = () => {
        trackButtonClick(GtmEventName.CANCEL_MEMBERSHIP_CLICK)
        setIsCancelMembershipDialogOpen(true)
    }

    const handleAvatarClick = () => {
        handleMenuCloseClick()
    }

    const handleUnionCardClick = () => {
        trackButtonClick(GtmEventName.UNION_KNOW_MORE_CLICK)
        handleMenuCloseClick()
        navigate(`${Route.UPME_CONVERSION_BENEFITS}`)
    }

    const handleProfileUpdateClick = () => {
        trackButtonClick(GtmEventName.UPDATE_PROFILE_CLICK)
        handleMenuCloseClick()
        navigate(`${Route.PROFILE_SUMMARY}`)
    }

    let profileCardMembership = undefined
    let profileActivity = undefined
    let profileCancelMembership = undefined
    let profileStripeUserPortal = undefined
    let profileUtapBalance = undefined
    let profileUpdate = undefined

    const displayUtapBalanceEntry = (membershipType?: MembershipType) => {
        if (!membershipType || membershipType === MembershipType.UPME) {
            return undefined
        }

        if (hasUtapBalanceValueWithNric || tentativeLastKnownBalance) {
            return (
                <Box key={t(`profile_entry_utap_balance`)}>
                    <MenuEntry entryName={t(`profile_entry_utap_balance`, { balance: formatToTwoDecimalPlaces(userMembership?.utapBalance) })} />
                </Box>
            )
        } else {
            return (
                <Box key={t(`profile_entry_utap_balance_tentative`)}>
                    <MenuEntry entryName={t(`profile_entry_utap_balance_tentative`)} />
                </Box>
            )
        }
    }

    switch (membershipButtonState) {
        case (MemberShipButtonState.NOT_MEMBER):
            break
        case (MemberShipButtonState.TERMINATED):
            profileCardMembership = (
                <Box key={t(`profile_join_again`)} onClick={() => handleMembershipDraft()}>
                    <MenuEntry entryName={t(`profile_join_again`)} />
                </Box>
            )

            profileActivity = (
                <Box key={t(`profile_entry_activity_history`)} onClick={() => handleMyActivityClick()}>
                    <MenuEntry entryName={t(`profile_entry_activity_history`)} />
                </Box>
            )
            break
        case (MemberShipButtonState.ACTIVE):
            profileUtapBalance = displayUtapBalanceEntry(userMembership?.type)

            profileUpdate = (
                <Box key={t(`profile_entry_profile_update`)} onClick={() => handleProfileUpdateClick()}>
                    <MenuEntry entryName={t(`profile_entry_profile_update`)} />
                </Box>
            )

            profileActivity = (
                <Box key={t(`profile_entry_activity_history`)} onClick={() => handleMyActivityClick()}>
                    <MenuEntry entryName={t(`profile_entry_activity_history`)} />
                </Box>
            )

            if (userMembership?.hasStripeSubscription && membershipType === MembershipType.STARTER) {
                profileStripeUserPortal = (
                    <Box key={t(`profile_entry_subscription`)} onClick={() => handleOnStripeCustomerProfileClick()}>
                        <MenuEntry entryName={t(`profile_entry_subscription`)} />
                    </Box>
                )

                if (userMembership?.provisionalTerminationDateOnCancellation && !userMembership.cancelSubscriptionAtCurrentPeriod) {
                    profileCancelMembership = (
                        <Box key={t(`cancel_membership`)} onClick={() => handleCancelMembershipButtonClick()}>
                            <MenuEntry entryName={t(`cancel_membership`)} />
                        </Box>
                    )
                }
            }

            break
    }

    const warningLogOutStyle = {
        color: palette.warning.main,
    }

    const dividerColor = {
        backgroundColor: palette.disabled.main,
    }

    return (
        <CustomDrawer onClose={handleMenuCloseClick} isOpen={isExpanded} anchor={AnchorDirection.LEFT} >
            <Box>
                <Box className={styles.profileContainer}>
                    <ProfileInfo membershipType={membershipType} user={user} onAvatarClick={handleAvatarClick} onUnionCardClick={handleUnionCardClick} />
                    <Divider sx={dividerColor} />
                    <Grid item className={styles.profileMenuEntries}>
                        <ProfileReferralCode user={user?.user?.data} userMembership={user?.membership?.data} />
                        {profileCardMembership}
                        {profileUtapBalance}
                        {profileUpdate}
                        {profileActivity}
                        {profileCancelMembership}
                        {profileStripeUserPortal}

                        <Box key={t(`common_contact_us`)} onClick={handleContactUsButtonClick}>
                            <MenuEntry entryName={t(`common_contact_us`)} />
                        </Box>
                        <Box key={t(`profile_entry_log_out`)} onClick={handleLogOutButtonClick} sx={warningLogOutStyle}>
                            <MenuEntry entryName={t(`profile_entry_log_out`)} />
                        </Box>
                    </Grid>
                </Box>
                <ConfirmationDialog
                    confirmationTitle={t(`cancel_membership`)}
                    confirmationText={t(`cancel_membership_content`)}
                    buttonText={t(`close`)}
                    defaultOpen={isCancelMembershipDialogOpen}
                    onConfirm={handleCancelMembershipDialog}
                    acknowledgeOnly
                />
                <LogoutDialog
                    dialogText={t(`logout_message`)}
                    confirmationButtonText={t(`logout_confirm`)}
                    dismissButtonText={t(`logout_dismiss`)}
                    isOpen={isLogoutDialogOpen}
                    onDismiss={handleLogoutDialogDismiss}
                    onConfirm={handleLogoutDialogConfirm}
                />
            </Box>
        </CustomDrawer>
    )
}

export default ProfileMenu