import { useContext } from 'react'

import UPMEOnboardingRepository from './starter-onboarding-carousel.repository'
import { ServiceResponse } from '../../types'
import { MembershipContext } from '../../stores/Membership/MembershipStore'
import { MembershipActions } from '../../stores/Membership/MembershipReducer'
import { HighlightCarouselItem } from '../../types/HighlightCarouselItem'
import { starterDataError } from '../../helpers/utils/error-handling'

const useStarterOnboardingCarouselRepository = () => {
    const { dispatch } = useContext(MembershipContext)

    const upmeOnboardingRepository = new UPMEOnboardingRepository()

    const retrieveStarterOnboardingCarousel = () => {
        const starterOnboardingCarouselResponse: ServiceResponse<HighlightCarouselItem[]> = {}

        upmeOnboardingRepository.retrieveStarterOnboardingCarousel()
            .then(carouselItems => {
                starterOnboardingCarouselResponse.data = carouselItems
            })
            .catch(error => {
                starterOnboardingCarouselResponse.error = starterDataError(error)
            })
            .finally(() => {
                dispatch({
                    type: MembershipActions.STARTER_ONBOARDING_CAROUSEL_RETRIEVED,
                    starterOnboardingCarousel: starterOnboardingCarouselResponse,
                })
            })
    }

    return {
        retrieveStarterOnboardingCarousel,
    }
}

export { useStarterOnboardingCarouselRepository }