import { useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { Box, Button, Container, Grid, Typography } from '@mui/material'
import { useTranslation } from "react-i18next"

import CommonBackButton from '../../atoms/CommonBackButton/CommonBackButton'
import { Route } from "../../../router"
import { EventDetail } from "../../../types"
import { AppContext } from '../../../stores/App/AppStore'
import { EventCardSkeleton } from "../../molecules/EventCard/EventCard.skeleton"
import { useSessionRepository } from "../../../repositories/Session/use-session-repository"
import { useRequestedRoute } from "../../../router/hooks/requested-route"
import { useEventRepository } from "../../../repositories/EventPage/use-event-page-repository"
import { eventDateStringWithTimeFormatter, eventDayDateStringFormatter } from "../../../helpers/utils/event-date-string-formatter"
import { eventAddressFormatter, registrationFormRequiredState } from "../../../helpers/utils/event-formatter"
import BannerImage from "../../atoms/BannerImage/BannerImage"
import SplashScreenGuard from "../../../router/guards/SplashScreenGuard"
import Spacer from "../../atoms/Spacer/Spacer"
import { QuestionResponses } from "../../../types/QuestionResponses"
import StarterErrorCodes from "../../../types/ErrorCodes"
import { ServiceResponseError } from "../../../types/RemoteData"
import ConfirmationDialog from "../../molecules/ConfirmationDialog/ConfirmationDialog"
import { UserContext } from "../../../stores/User/UserStore"
import { PaymentOrderReason } from "../../../types/PaymentOrderReason"
import MarkdownContent from "../../atoms/MarkdownContent/MarkdownContent"
import { MembershipScope } from "../../../types/MembershipScope"
import { MembershipType } from "../../../types/MembershipType"
import AlertDialog from "../../molecules/AlertDialog/AlertDialog"
import { EventDetailsState } from "./EventDetailsState"
import { logAction } from "../../../clients/logger"
import { EventsDetailsPageRegistrationMessageTemplate, errorPopupMessageTemplate, generateErrorCode } from "../../../helpers/logging/templates"
import { useAuthenticatedGuard } from "../../../router/guards/authenticated-guard"
import useTrackPageView from "../../../helpers/hooks/use-track-page-view"
import useGoogleTagManager from '../../../helpers/analytics/use-google-tag-manager'
import GtmEventName from '../../../helpers/analytics/GtmEventName'

import styles from './EventDetails.module.css'

const EventDetails = () => {
    useAuthenticatedGuard(Route.PILLARS_EVENT)

    const { id: eventId } = useParams()

    const { t } = useTranslation()
    const { state } = useContext(AppContext)
    const { state: userState } = useContext(UserContext)
    const { trackFormSubmit, trackRedirectOnButtonClick } = useGoogleTagManager()

    const { retrieveEventDetails, registerEvent } = useEventRepository()
    const { isAuthenticated } = useSessionRepository()
    const navigate = useNavigate()
    const { setRequestedRoute } = useRequestedRoute()

    const [eventDetails, setEventDetails] = useState<EventDetail>()
    const [loading, setLoading] = useState<boolean>(false)
    const [registrationSuccess, setRegistrationSuccess] = useState<boolean>(false)
    const [showRegistrationForm, setShowRegistrationForm] = useState<boolean>(true)
    const [dialogErrorMessage, setDialogErrorMessage] = useState<string>()
    const [isErrorDialogOpen, setIsErrorDialogOpen] = useState<boolean>(false)
    const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState<boolean>(false)
    const [showEventIneligibleDialog, setShowEventIneligibleDialog] = useState<boolean>(false)

    const membershipStatus = userState.membership?.data?.status
    const hasRegistered = eventDetails?.registered ?? false
    const userMembership = userState.membership?.data?.type
    const events = state.events

    useTrackPageView(() => {
        if (eventDetails) {
            return {
                title: `Event Details`,
                item: eventDetails.title,
            }
        }
    }, [eventDetails])

    useEffect(() => {
        if (events?.data?.length) {
            fetchEventDetailsFromState()
        } else {
            retrieveEventDetails(Number(eventId))
        }
    }, [registrationSuccess])

    useEffect(() => {
        fetchEventDetailsFromState()
    }, [state])

    useEffect(() => {
        if (registrationSuccess) {
            setShowRegistrationForm(false)
        }
    }, [registrationSuccess])

    useEffect(() => {
        logAction(`error`, errorPopupMessageTemplate(dialogErrorMessage, userState.membership?.data?.id?.toString() ?? `not found`))
    }, [dialogErrorMessage])

    useEffect(() => {
        if (userMembership && eventDetails) {
            const isAccessible = canAccessEvent(eventDetails?.membershipScope, userMembership, hasRegistered)
            setShowEventIneligibleDialog(!isAccessible)
        }
    }, [userMembership, events])

    const fetchEventDetailsFromState = () => {
        const currentEvent = events?.data?.find(event => event.id == Number(eventId))
        if (currentEvent) {
            setEventDetails(currentEvent)
            if (userMembership) {
                const isAccessible = canAccessEvent(currentEvent?.membershipScope, userMembership, hasRegistered)
                setShowEventIneligibleDialog(!isAccessible)
            }
        }

        if (!currentEvent && hasRegistered) {
            retrieveEventDetails(Number(eventId))
        }
    }

    const handleLoginClick = () => {
        setRequestedRoute(Route.PILLARS_EVENT, eventId)
        navigate(Route.PLS_LOGIN)
    }

    const handleAuthentication = () => isAuthenticated()

    const handleMemberRegistrationError = (error: ServiceResponseError) => {
        switch (error.errorCode) {
            case StarterErrorCodes.MEMBERSHIP_ALREADY_SIGN_UP_EVENT:
                setDialogErrorMessage(t(`event_registration_sign_up_error`) + ` error code: ${generateErrorCode()}`)
                break
            case StarterErrorCodes.MEMBERSHIP_NOT_ACTIVE:
                setDialogErrorMessage(t(`event_registration_terminated_member_error`) + ` error code: ${generateErrorCode()}`)
                break
            case StarterErrorCodes.EVENT_REGISTRATION_IS_NOT_OPEN:
                setDialogErrorMessage(t(`event_registration_not_open`) + ` error code: ${generateErrorCode()}`)
                break
            case StarterErrorCodes.EVENT_REGISTRATION_DEADLINE:
                setDialogErrorMessage(t(`event_registration_deadline_past`) + ` error code: ${generateErrorCode()}`)
                break
            case StarterErrorCodes.EVENT_NOT_ACCESSIBLE_FOR_MEMBERSHIP_TYPE:
                setDialogErrorMessage(t(`event_registration_not_accessible_membership_type`) + ` error code: ${generateErrorCode()}`)
                break
            default:
                setDialogErrorMessage((error.errorMessage ?? t(`error_unknown`)) + ` error code: ${generateErrorCode()}`)
                break
        }
        setIsErrorDialogOpen(true)
    }

    const handleErrorDialogClose = () => {
        setIsErrorDialogOpen(false)
    }

    const handleSuccessDialogClose = () => {
        setIsSuccessDialogOpen(false)
    }

    const handleComeBackButtonClick = async () => {
        navigate(`/membership/payment-summary/${PaymentOrderReason.SIGNUP}`, { replace: true })
    }

    const canAccessEvent = (eventScope: MembershipScope, memberType: MembershipType, hasRegistered: boolean) => {
        if (eventScope === MembershipScope.ALL || hasRegistered) {
            return true
        }

        const isStarter = memberType === MembershipType.STARTER
        const isUPME = memberType === MembershipType.UPME

        const isStarterEventScope = eventScope === MembershipScope.STARTER
        const isUPMEEventScope = eventScope === MembershipScope.UPME

        return (isStarter && isStarterEventScope) || (isUPME && isUPMEEventScope)
    }

    const handleShowEventIneligibleDialogClose = () => {
        navigate(Route.PILLARS_EVENT)
    }

    if (showEventIneligibleDialog) {
        return <AlertDialog
            alertText={t(`event_page_inaccessible`)}
            buttonText={t(`event_back_to_events`)}
            shouldShow={showEventIneligibleDialog}
            onClose={handleShowEventIneligibleDialogClose} />
    }

    const isAbleToAccessEvent = eventDetails && userMembership && canAccessEvent(eventDetails?.membershipScope, userMembership, hasRegistered)

    if (isAbleToAccessEvent) {
        const formattedDate = eventDayDateStringFormatter(eventDetails.eventDate, eventDetails.eventEndDate)
        const formattedDateWithTime = eventDateStringWithTimeFormatter(eventDetails.eventDate, eventDetails.startTime, eventDetails.endTime, eventDetails.eventEndDate)

        const formattedAddress = eventAddressFormatter(eventDetails.blkHouseNumber, eventDetails.streetName, eventDetails.floorNumber, eventDetails.unitNumber, eventDetails.postalCode, eventDetails.buildingName)

        const handleRegistrationClick = async (responses: QuestionResponses) => {
            const requiredFormState = registrationFormRequiredState(eventDetails?.questionnaire)

            const isFormValid = Object.keys(requiredFormState).every(key => {
                const responseKey = key.toLowerCase()
                const response = responses[responseKey]

                return !requiredFormState[key] || (response?.answer !== undefined && response?.answer !== ``)
            })

            if (eventId && isFormValid) {
                trackFormSubmit(GtmEventName.EVENT_REGISTRATION_CLICK, eventDetails.title)
                setLoading(true)
                logAction(`debug`, EventsDetailsPageRegistrationMessageTemplate(userState.membership?.data, `internal`))
                const response = await registerEvent({ eventId: Number(eventId), responses })

                if (response?.data) {
                    setLoading(false)
                    setIsSuccessDialogOpen(true)
                    setRegistrationSuccess(true)
                }

                if (response?.error) {
                    setLoading(false)
                    handleMemberRegistrationError(response?.error)
                }
            }
        }

        const handleExternalLinkClick = () => {
            trackRedirectOnButtonClick(eventDetails.externalLink)
            logAction(`debug`, EventsDetailsPageRegistrationMessageTemplate(userState.membership?.data, `external`))
            window.open(eventDetails.externalLink, `_blank`)
        }

        const handleShowMapClick = () => {
            window.open(eventDetails.mapUrl, `_blank`)
        }

        const eventDetailsEmail = eventDetails.email && `Email: ${eventDetails.email}`
        const eventDetailsContactNumber = eventDetails.contactNumber && `Contact: ${eventDetails.contactNumber}`
        const eventContactDetailsComponent = (eventDetailsEmail || eventDetailsContactNumber) &&
            (<Grid mb={2} item>
                <Typography variant="h6" className={styles.headerDetails}>Contact Details</Typography>
                <Typography >{eventDetailsEmail}</Typography>
                <Typography >{eventDetailsContactNumber}</Typography>
            </Grid>)

        const externalLinkDetails = eventDetails.externalLinkDetails ? eventDetails.externalLinkDetails : t(`event_external_link`)
        const externalLinkButton = eventDetails.externalLink && <Button variant='contained' className={styles.externalLink} onClick={handleExternalLinkClick}>{externalLinkDetails}</Button>

        const eventDetailsAddressComponent = eventDetails.noAddress ? (<Typography>{t(`event_no_address_placeholder`)}</Typography>) : (<Typography >{formattedAddress}</Typography>)
        const eventDetailsMapLink = eventDetails.mapUrl && (<Typography className={styles.mapUrl} onClick={handleShowMapClick}>{t(`event_show_map`)}</Typography>)

        const dateAndTimeComponent = () => {
            if (formattedDateWithTime) {
                return (<Grid mb={2} item>
                    <Typography variant="h6" className={styles.headerDetails}>Date and Time</Typography>
                    <Typography >{formattedDateWithTime}</Typography>
                </Grid>)
            } else {
                return null
            }
        }

        return (
            <SplashScreenGuard>
                <Container>
                    <CommonBackButton />

                    <Grid container className={styles.gridContainer} direction="column">
                        <Box className={styles.bannerImageBox}>
                            <BannerImage imageUrl={eventDetails.headerImage} className={styles.bannerImage} alt="logo" />
                        </Box>
                        <Spacer height={30} />
                        <Grid item>
                            <Typography variant="subtitle1">{formattedDate}</Typography>
                        </Grid>

                        <Grid item>
                            <Typography variant="h6" className={styles.title}>{eventDetails.title}</Typography>
                        </Grid>

                        <MarkdownContent content={eventDetails.description} />

                        {dateAndTimeComponent()}

                        <Grid mb={2} item>
                            <Typography variant="h6" className={styles.headerDetails}>Location</Typography>
                            <Typography >{eventDetails.location}</Typography>
                            {eventDetailsAddressComponent}
                            {eventDetailsMapLink}
                        </Grid>

                        {eventContactDetailsComponent}

                        <EventDetailsState
                            eventDetails={eventDetails}
                            hasRegistered={hasRegistered}
                            registrationSuccess={registrationSuccess}
                            showRegistrationForm={showRegistrationForm}
                            membershipStatus={membershipStatus}
                            loading={loading}
                            isAuthenticated={handleAuthentication}
                            handleRegistration={handleRegistrationClick}
                            handleLogin={handleLoginClick}
                            handleSignUp={handleComeBackButtonClick}
                        />

                        <Grid item className={styles.centered}>
                            {externalLinkButton}
                        </Grid>

                        <ConfirmationDialog
                            buttonText={t(`common_button_confirmation`)}
                            confirmationTitle={`Something went wrong`}
                            confirmationText={dialogErrorMessage}
                            dismissButtonText={t(`common_back_to_home`)}
                            defaultOpen={isErrorDialogOpen}
                            onConfirm={handleErrorDialogClose}
                        />
                        <ConfirmationDialog
                            acknowledgeOnly
                            buttonText={t(`common_button_confirmation`)}
                            confirmationTitle={t(`event_registration_success_title`)}
                            confirmationText={t(`event_registration_success`)}
                            defaultOpen={isSuccessDialogOpen}
                            onConfirm={handleSuccessDialogClose}
                        />
                    </Grid>
                </Container>
            </SplashScreenGuard>
        )
    } else {
        return <EventCardSkeleton />
    }
}

export default EventDetails