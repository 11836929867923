import FormFile from "./FormFile"
import { toStrapiQuestionAnswer } from "./QuestionAnswer"
import { QuestionResponses, StrapiQuestionResponses } from "./QuestionResponses"

interface ProgrammeApplicationSubmission {
    programmeApplicationFormId: number
    responses: QuestionResponses
    files: FormFile[]
}

export const programmeApplicationSubmissionPayload = (programmeApplicationSubmission: ProgrammeApplicationSubmission) => {
    const strapiResponses: StrapiQuestionResponses = {}

    for (const questionResponse in programmeApplicationSubmission.responses) {
        strapiResponses[questionResponse] = toStrapiQuestionAnswer(programmeApplicationSubmission.responses[questionResponse])
    }

    return {
        programmeApplicationFormId: programmeApplicationSubmission.programmeApplicationFormId,
        responses: strapiResponses,
    }
}

export default ProgrammeApplicationSubmission