import { useContext, useEffect, useState } from 'react'
import { Container, Grid, InputAdornment, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { LoadingButton } from '@mui/lab'
import { AxiosError } from 'axios'
import moment from 'moment'
import i18next from 'i18next'

import { Route } from '../../../../router'
import { UtapClaimFormSubmissionResponse } from '../../../../types/UtapClaimFormSubmissionResponse'
import { UserContext } from '../../../../stores/User/UserStore'
import { useUtapRepository } from '../../../../repositories/Utap/use-utap-repository'
import { useMembershipRepository } from '../../../../repositories/Membership/use-membership-repository'
import { useAuthenticatedGuard } from '../../../../router/guards/authenticated-guard'
import { ERROR_500, getAxiosErrorMessage } from '../../../../helpers/utils/error-handling'
import TrainingCourseSelect from '../../../molecules/TrainingCourseSelect/TrainingCourseSelect'
import useMemberGuard from '../../../../router/guards/member-guard'
import { UnionMembershipStatus } from '../../../../types/MembershipStatus'
import BankSelect from '../../../molecules/BankSelect/BankSelect'
import CommonBackButton from '../../../atoms/CommonBackButton/CommonBackButton'
import CustomInput from '../../../atoms/CustomInput/CustomInput'
import CustomDatePicker from '../../../atoms/CustomDatePicker/CustomDatePicker'
import CustomFileInput from '../../../atoms/CustomFileInput/CustomFileInput'
import ConfirmationDialog from '../../../molecules/ConfirmationDialog/ConfirmationDialog'
import ErrorDialog from '../../../molecules/ErrorDialog/ErrorDialog'
import ImageModal from '../../../molecules/ImagePreviewModal/ImageModal'
import UTAP_FORM_VALIDATION from '../../../../helpers/validation/rules/utap-claim-form'
import AlertDialog from '../../../molecules/AlertDialog/AlertDialog'
import LastClaimData from '../../../../types/LastClaimData'
import { AppContext } from '../../../../stores/App/AppStore'
import TrainingProviderSelect from '../../../molecules/TrainingProviderSelect/TrainingProviderSelect'
import UtapCourseAttendedCheckBox from '../../../molecules/UtapCourseAttendedCheckBox/UtapCourseAttendedCheckBox'
import CLAIM_FORM_CONSTANTS from './ClaimForm.constants'
import SplashScreenGuard from '../../../../router/guards/SplashScreenGuard'
import { createMyInfoUserData } from '../../../../helpers/utils/myinfoData'
import { appendFileToFormData } from '../../../../helpers/utils/common'
import { logAction } from '../../../../clients/logger'
import { errorPopupMessageTemplate, generateErrorCode } from '../../../../helpers/logging/templates'
import useTrackPageView from "../../../../helpers/hooks/use-track-page-view"
import useGoogleTagManager from '../../../../helpers/analytics/use-google-tag-manager'
import GtmEventName from '../../../../helpers/analytics/GtmEventName'

type CustomSelectValue = string | number | undefined

const ClaimForm = () => {
    useTrackPageView(`UTAP Claim Form Page`)

    useAuthenticatedGuard(Route.UTAP_CLAIM_FORM)
    useMemberGuard()

    const { saveUtapClaim, retrieveUtapStatus, retrieveUtapClaimsLog } = useUtapRepository()
    const { retrieveMembership, retrieveUnionMembership } = useMembershipRepository()
    const { state: userState } = useContext(UserContext)
    const { state: appState } = useContext(AppContext)

    const { t } = useTranslation()
    const navigate = useNavigate()
    const { trackFormSubmit } = useGoogleTagManager()

    const userMembership = userState.membership?.data
    const utapStatus = userState.utapStatus?.data
    const isUnionMember = userState.unionMembershipStatus?.data === UnionMembershipStatus.IS_MEMBER
    const lastClaimData: LastClaimData | undefined = userState.lastClaimData?.data

    const [email, setEmail] = useState(``)
    const [phoneNumber, setPhoneNumber] = useState(``)
    const [postalCode, setPostalCode] = useState(``)

    const [blockHouseNumber, setBlockHouseNumber] = useState(``)
    const [streetName, setStreetName] = useState(``)
    const [floorNumber, setFloorNumber] = useState(``)
    const [unitNumber, setUnitNumber] = useState(``)
    const [buildingName, setBuildingName] = useState(``)

    const [courseHoursAttended, setCourseHoursAttended] = useState<boolean>(false)
    const [courseId, setCourseId] = useState<CustomSelectValue>()
    const [trainerId, setTrainerId] = useState<CustomSelectValue>()
    const [startDate, setStartDate] = useState<Date | null>(null)
    const [endDate, setEndDate] = useState<Date | null>(null)
    const [netFee, setNetFee] = useState(``)
    const [proofOfPayment, setProofOfPayment] = useState<File | undefined>(undefined)
    const [proofOfCompletion, setProofOfCompletion] = useState<File | undefined>(undefined)
    const [bankId, setBankId] = useState<CustomSelectValue>()
    const [accountHolder, setAccountHolder] = useState(``)
    const [accountNumber, setAccountNumber] = useState(``)
    const [bankBookStatement, setBankBookStatement] = useState<File | undefined>(undefined)
    const [formRequiredState, setFormRequiredState] = useState(
        CLAIM_FORM_CONSTANTS.formRequiredStateTemplate,
    )
    const [loading, setLoading] = useState(false)
    const [isBankStatementPreviewOpen, setIsBankStatementPreviewOpen] = useState(false)
    const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false)
    const [dialogErrorMessageTitle, setDialogErrorMessageTitle] = useState(
        t(`sign_up_failure`),
    )
    const [dialogErrorMessage, setDialogErrorMessage] = useState(``)
    const [showMaxClaimsReachedDialog, setShowMaxClaimsReachedDialog] = useState<boolean>(false)
    const [showClaimsIneligibleDialog, setShowClaimsIneligibleDialog] = useState<boolean>(false)

    const myInfoData = userState.myInfoUserData?.data?.content

    const uinFinData = userState && createMyInfoUserData(userState)

    useEffect(() => {
        retrieveMembership()
        retrieveUnionMembership()
        retrieveUtapClaimsLog()
        retrieveUtapStatus(uinFinData)
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        if (isUnionMember) {
            navigate(Route.HOME)
        }
    }, [isUnionMember])

    useEffect(() => {
        if (userMembership) {
            setEmail(userMembership.emailAddress ?? ``)
            setPhoneNumber(userMembership.phoneNumber ?? ``)
            setPostalCode(userMembership.postalCode ?? ``)
            setBlockHouseNumber(userMembership.block ?? ``)
            setStreetName(userMembership.street ?? ``)
            setFloorNumber(userMembership.floor ?? ``)
            setUnitNumber(userMembership.unit ?? ``)
            setBuildingName(userMembership.building ?? ``)
        }
    }, [userState.membership])

    useEffect(() => {
        if (lastClaimData) {
            setPostalCode(postalCode ?? lastClaimData.address.postalCode ?? ``)
            setBlockHouseNumber(lastClaimData.address.blockHouseNumber ?? ``)
            setStreetName(lastClaimData.address.streetName ?? ``)
            setFloorNumber(
                lastClaimData.address.floorNumber
                    ? lastClaimData.address.floorNumber.toString()
                    : ``,
            )
            setUnitNumber(
                lastClaimData.address.unitNumber
                    ? lastClaimData.address.unitNumber.toString()
                    : ``,
            )
            setBuildingName(lastClaimData.address.buildingName ?? ``)
            setBankId(lastClaimData.bankInformation.bankId)
            setAccountHolder(lastClaimData.bankInformation.accountHolderName)
            setAccountNumber(lastClaimData.bankInformation.accountNumber)
        } else if (myInfoData) {
            setBlockHouseNumber(userState?.myInfoUserData?.data?.content?.block ?? ``)
            setStreetName(userState?.myInfoUserData?.data?.content?.street ?? ``)
            setFloorNumber(userState?.myInfoUserData?.data?.content?.floor ?? ``)
            setUnitNumber(userState?.myInfoUserData?.data?.content?.unit ?? ``)
            setBuildingName(userState?.myInfoUserData?.data?.content?.building ?? ``)
        }
    }, [lastClaimData])

    useEffect(() => {
        if (utapStatus && !utapStatus.status) {
            setShowClaimsIneligibleDialog(true)
        }
        if (utapStatus?.status && utapStatus?.totalClaimsAmount) {
            setShowMaxClaimsReachedDialog(Number(utapStatus?.totalClaimsAmount) <= 0)
        }
    }, [utapStatus?.status])

    useEffect(() => {
        logAction(`error`, errorPopupMessageTemplate(dialogErrorMessage, userState.membership?.data?.id?.toString() ?? `not found`))
    }, [dialogErrorMessage])

    useEffect(() => {
        setCourseId(undefined)
    }, [trainerId])

    const setDummyData = () => {
        if (process.env.REACT_APP_STARTER_APP_ENV === `development`) {
            setBlockHouseNumber(`42`)
            setStreetName(`Beach Road`)
            setFloorNumber(`42`)
            setUnitNumber(`42`)
            setBuildingName(`Montparnasse`)
            setCourseId(`C00032177`)
            setStartDate(moment(`2023-11-16`).toDate())
            setEndDate(moment(`2023-11-18`).toDate())
            setNetFee(`20`)
            setBankId(appState.bank[0].id)
            setAccountHolder(`Jonh Doe`)
            setAccountNumber(`2938472398470123`)
        }
    }

    const handleSubmit = () => {
        if (areInputsValid()) {
            trackFormSubmit(GtmEventName.SUBMIT_UTAP_CLAIM)
            handleSaveClaim()
        } else {
            handleShowValidationErrors()
        }
    }

    const areInputsValid = (): boolean => {
        return (
            !!phoneNumber &&
            !!email &&
            !!postalCode &&
            !!blockHouseNumber &&
            !!streetName &&
            !!trainerId &&
            !!courseId &&
            !!courseHoursAttended &&
            !!startDate &&
            !!endDate &&
            !!netFee && parseFloat(netFee) >= UTAP_FORM_VALIDATION.MINIMUM_CLAIM_VALUE &&
            !!proofOfPayment &&
            !!proofOfCompletion &&
            !!bankId &&
            !!accountHolder &&
            !!accountNumber &&
            !!bankBookStatement
        )
    }

    const handleShowValidationErrors = () => {
        const requiredState = {
            phoneNumber: !phoneNumber,
            email: !email,
            postalCode: !postalCode,
            blockHouseNumber: !blockHouseNumber,
            streetName: !streetName,
            trainingProvider: !trainerId,
            trainingCourse: !courseId,
            courseHoursAttended: !courseHoursAttended,
            startDate: !startDate,
            endDate: !endDate,
            netFee: !netFee,
            proofOfPayment: !proofOfPayment,
            proofOfCompletion: !proofOfCompletion,
            bank: !bankId,
            accountHolder: !accountHolder,
            accountNumber: !accountNumber,
            bankBookStatement: !bankBookStatement,
        }

        setFormRequiredState(requiredState)
    }

    const handleSaveClaim = async () => {
        const claimData = createClaimData()

        if (claimData) {
            setLoading(true)
            await saveUtapClaim(claimData)
                .then((claimSubmissionResponse: UtapClaimFormSubmissionResponse) => {
                    setLoading(false)
                    navigate(`/utap/success/${claimSubmissionResponse.referenceNumber}`)
                })
                .catch(handleSaveClaimError)
        }
    }

    const handleShowMaxClaimsReachedDialogClose = () => {
        navigate(Route.HOME)
    }

    const createClaimData = (): FormData | undefined => {
        if (proofOfPayment != undefined && proofOfCompletion != undefined && bankBookStatement != undefined) {
            const formData = new FormData()
            formData.append(`claimForm`, JSON.stringify(createClaimMetaData()))
            formData.append(`myInfoUserData`, JSON.stringify(uinFinData))
            appendFileToFormData(formData, `proofOfPayment`, proofOfPayment)
            appendFileToFormData(formData, `proofOfCompletion`, proofOfCompletion)
            appendFileToFormData(formData, `bankBookStatement`, bankBookStatement)

            return formData
        } else {
            return undefined
        }
    }

    const createClaimMetaData = () => {
        if (!!phoneNumber && !!courseId && !!bankId) {
            return {
                emailAddress: email,
                phoneNumber,
                postalCode,
                blockHouseNumber,
                streetName,
                floorNumber: floorNumber ?? ``,
                unitNumber: unitNumber ?? ``,
                buildingName,
                courseId,
                trainerId,
                // To circumvent timezone issue. Local is GMT+8, server is UTC
                courseStartDate: moment(startDate).format(`YYYY-MM-DD`),
                courseEndDate: moment(endDate).format(`YYYY-MM-DD`),
                claimedNettFee: netFee,
                bankId,
                accountHolderName: accountHolder,
                accountNumber,
                courseHoursAttended,
            }
        }
    }

    const handleSaveClaimError = (error: AxiosError) => {
        setLoading(false)
        setDialogErrorMessageTitle(t(`sign_up_failure`))
        handleErrorMessaging(error)
    }

    const handleErrorMessaging = (error: AxiosError) => {
        if (error.response?.status === ERROR_500) {
            const enhancedErrorMessage = getAxiosErrorMessage(error) + t(`utap_claim_enhanced_500_error_message`) + ` error code: ${generateErrorCode()}`
            setDialogErrorMessage(enhancedErrorMessage)
        } else {
            const enhancedErrorMessage = getAxiosErrorMessage(error) + t(`utap_claim_enhanced_400_error_message`) + ` error code: ${generateErrorCode()}`
            setDialogErrorMessage(enhancedErrorMessage)
        }
        setIsErrorDialogOpen(true)
    }

    const handleErrorDialogClose = () => {
        setIsErrorDialogOpen(false)
    }

    const handleBankStatementPreviewOpen = () => {
        setIsBankStatementPreviewOpen(true)
    }

    const handleBankStatementPreviewClose = () => {
        setIsBankStatementPreviewOpen(false)
    }

    if (userState.utapClaims?.error) {
        return <ErrorDialog acknowledgeOnly defaultOpen />
    }

    return (
        <SplashScreenGuard nextRoute={Route.UTAP_LANDING}>
            <Container>
                <Grid container direction="column" spacing={1}>
                    <CommonBackButton />
                    <Grid item>
                        <Typography variant="h6" mb={2} onClick={setDummyData}>
                            {t(`utap_claim_title`)}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="h6" mb={2}>
                            {t(`utap_claim_application_title`)}
                        </Typography>
                        <CustomInput
                            fullWidth
                            label={t(`sign_up_email_address`)}
                            onChange={setEmail}
                            placeholder={t(`common_placeholder`)}
                            validation={UTAP_FORM_VALIDATION.emailAddressValidator}
                            value={email}
                            error={formRequiredState.email}
                            readOnly={!!userMembership?.emailAddress}
                            isSignUp={false} />
                        <CustomInput
                            fullWidth
                            label={t(`sign_up_phone_number`)}
                            numbersOnly
                            onChange={setPhoneNumber}
                            validation={UTAP_FORM_VALIDATION.phoneNumberValidator}
                            params={
                                phoneNumber?.startsWith(`+`)
                                    ? undefined
                                    : {
                                        InputProps: {
                                            startAdornment: (
                                                <InputAdornment position="start">+65</InputAdornment>
                                            ),
                                        },
                                    }
                            }
                            placeholder={t(`common_placeholder`)}
                            value={phoneNumber || ``}
                            error={formRequiredState.phoneNumber}
                            readOnly={!!userMembership?.phoneNumber}
                            isSignUp={false} />
                        <CustomInput
                            fullWidth
                            label={t(`sign_up_postal_code`)}
                            onChange={setPostalCode}
                            placeholder={t(`common_placeholder`)}
                            validation={UTAP_FORM_VALIDATION.postalCodeValidator}
                            value={postalCode}
                            error={formRequiredState.email}
                            readOnly={!!userMembership?.emailAddress}
                            isSignUp={false} />
                        <CustomInput
                            fullWidth
                            label={t(`sign_up_block_house_number`)}
                            onChange={setBlockHouseNumber}
                            placeholder={t(`common_placeholder`)}
                            value={blockHouseNumber}
                            error={formRequiredState.blockHouseNumber}
                            isSignUp={false} />
                        <CustomInput
                            fullWidth
                            label={t(`sign_up_block_street_name`)}
                            onChange={setStreetName}
                            placeholder={t(`common_placeholder`)}
                            value={streetName}
                            error={formRequiredState.streetName}
                            isSignUp={false} />
                        <CustomInput
                            fullWidth
                            label={t(`sign_up_block_floor_number`)}
                            onChange={setFloorNumber}
                            placeholder={t(`common_placeholder`)}
                            validation={UTAP_FORM_VALIDATION.floorNumberValidator}
                            value={floorNumber}
                            numbersOnly
                            isSignUp={false}
                        />
                        <CustomInput
                            fullWidth
                            label={t(`sign_up_block_unit_number`)}
                            onChange={setUnitNumber}
                            placeholder={t(`common_placeholder`)}
                            validation={UTAP_FORM_VALIDATION.unitNumberValidator}
                            value={unitNumber}
                            numbersOnly
                            isSignUp={false}
                        />
                        <CustomInput
                            fullWidth
                            label={t(`sign_up_block_building_name`)}
                            onChange={setBuildingName}
                            placeholder={t(`common_placeholder`)}
                            value={buildingName}
                            isSignUp={false}
                        />
                    </Grid>
                    <Grid item>
                        <Typography variant="h6" mb={2}>
                            {t(`utap_claim_course_title`)}
                        </Typography>
                        <TrainingProviderSelect
                            label={t(`utap_claim_training_provider`)}
                            placeholder={t(`common_placeholder`)}
                            onChange={setTrainerId}
                            value={trainerId}
                            error={formRequiredState.trainingProvider}
                            isSignUp={false} />
                        <TrainingCourseSelect
                            label={t(`utap_claim_course`)}
                            placeholder={t(`common_placeholder`)}
                            onChange={setCourseId}
                            value={courseId}
                            error={formRequiredState.trainingCourse}
                            isSignUp={false}
                            trainerId={trainerId}
                            disabled={!trainerId} />
                        <CustomDatePicker
                            fullWidth
                            label={t(`utap_claim_course_start_date`)}
                            onChange={setStartDate}
                            value={startDate}
                            maxDate={new Date()}
                            error={formRequiredState.startDate} />
                        <CustomDatePicker
                            fullWidth
                            label={t(`utap_claim_course_end_date`)}
                            onChange={setEndDate}
                            value={endDate}
                            minDate={startDate}
                            maxDate={new Date()}
                            error={formRequiredState.endDate} />
                        <CustomInput
                            fullWidth
                            label={t(`utap_claim_course_fee`)}
                            placeholder={t(`common_placeholder`)}
                            onChange={setNetFee}
                            validation={UTAP_FORM_VALIDATION.netFeePaidValidator()}
                            value={netFee}
                            error={formRequiredState.netFee}
                            params={{ type: `number` }}
                            isSignUp={false} />
                        <UtapCourseAttendedCheckBox
                            courseHoursAttended={courseHoursAttended}
                            onCourseAttendedChange={setCourseHoursAttended}
                            error={formRequiredState.courseHoursAttended} />
                    </Grid>
                    <Grid item>
                        <Typography variant="h6" mt={2}> {t(`utap_claim_supporting_doc_title`)} </Typography>
                    </Grid>
                    <Grid item>
                        <CustomFileInput
                            fullWidth
                            label={t(`utap_claim_proof_of_payment`)}
                            onChange={setProofOfPayment}
                            error={formRequiredState.proofOfPayment}
                            maxFileSize={CLAIM_FORM_CONSTANTS.ALLOWED_FILE_SIZE}
                            allowedFiletypes={CLAIM_FORM_CONSTANTS.ALLOWED_FILE_TYPE} />
                    </Grid>
                    <Grid item>
                        <CustomFileInput
                            fullWidth
                            label={t(`utap_claim_proof_of_completion`)}
                            onChange={setProofOfCompletion}
                            error={formRequiredState.proofOfCompletion}
                            maxFileSize={CLAIM_FORM_CONSTANTS.ALLOWED_FILE_SIZE}
                            allowedFiletypes={CLAIM_FORM_CONSTANTS.ALLOWED_FILE_TYPE} />
                    </Grid>
                    <Grid item mt={2}>
                        <Typography variant="h6" mb={2}>
                            {t(`utap_claim_bank_account_title`)}
                        </Typography>
                        <BankSelect
                            label={t(`utap_claim_bank`)}
                            placeholder={t(`common_placeholder`)}
                            onChange={setBankId}
                            value={bankId}
                            error={formRequiredState.bank}
                            isSignUp={false} />
                        <CustomInput
                            fullWidth
                            label={t(`utap_claim_account_holder_name`)}
                            placeholder={t(`common_placeholder`)}
                            onChange={setAccountHolder}
                            value={accountHolder}
                            error={formRequiredState.accountHolder}
                            isSignUp={false} />
                        <CustomInput
                            fullWidth
                            label={t(`utap_claim_account_number`)}
                            placeholder={t(`common_placeholder`)}
                            onChange={setAccountNumber}
                            value={accountNumber}
                            error={formRequiredState.accountNumber}
                            isSignUp={false} />
                    </Grid>
                    <Grid item mb={2}>
                        <CustomFileInput
                            fullWidth
                            label={t(`utap_claim_bank_book_cover_statement`)}
                            onChange={setBankBookStatement}
                            error={formRequiredState.bankBookStatement}
                            maxFileSize={CLAIM_FORM_CONSTANTS.ALLOWED_FILE_SIZE}
                            allowedFiletypes={CLAIM_FORM_CONSTANTS.ALLOWED_FILE_TYPE}
                            infoLabel
                            onInfoLabelClick={handleBankStatementPreviewOpen} />
                    </Grid>
                    <Grid item>
                        <LoadingButton variant='contained' onClick={handleSubmit} fullWidth loading={loading} disabled={loading}>{t(`submit`)}</LoadingButton>
                    </Grid>
                </Grid>
                <ConfirmationDialog
                    buttonText={t(`common_button_confirmation`)}
                    confirmationTitle={dialogErrorMessageTitle}
                    confirmationText={dialogErrorMessage}
                    dismissButtonText={t(`common_back_to_home`)}
                    defaultOpen={isErrorDialogOpen}
                    onConfirm={handleErrorDialogClose}
                    onDismiss={() => navigate(Route.HOME)} />
                <ImageModal
                    imageUrl={CLAIM_FORM_CONSTANTS.BANK_STATEMENT_PREVIEW}
                    alt={`bank statement example`}
                    defaultOpen={isBankStatementPreviewOpen}
                    onClose={handleBankStatementPreviewClose} />
                <AlertDialog
                    alertText={t(`utap_landing_cannot_claim_message`)}
                    buttonText={t(`common_back_to_home`)}
                    shouldShow={showMaxClaimsReachedDialog}
                    onClose={handleShowMaxClaimsReachedDialogClose} />
                <AlertDialog
                    alertText={i18next.t(`utap_landing_ineligible_claim_message`, {
                        remarks: utapStatus?.remarks,
                    })}
                    buttonText={t(`common_back_to_home`)}
                    shouldShow={showClaimsIneligibleDialog}
                    onClose={handleShowMaxClaimsReachedDialogClose} />
            </Container>
        </SplashScreenGuard>
    )
}

export default ClaimForm