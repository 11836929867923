import { FaqEntry } from "../../types"
import { FaqTags } from "../../types/FaqTags"

export const sortFaqTagsAndEntries = (faqTags: FaqTags[]): FaqTags[] => {
    return faqTags.map(tag => {
        const sortedEntries = sortFaqEntries(tag?.faqEntries ?? [])
        return {
            ...tag,
            faqEntries: sortedEntries,
        }
    }).sort((a, b) => {
        const aOrder = a.order ?? 0
        const bOrder = b.order ?? 0
        if (aOrder === bOrder) {
            return a.tag.localeCompare(b.tag)
        }

        if (aOrder === 0) {
            return 1
        }

        if (bOrder === 0) {
            // eslint-disable-next-line no-magic-numbers
            return -1
        }

        return aOrder - bOrder
    })
}

export const sortFaqEntries = (faqEntries: FaqEntry[]) => {
    return faqEntries.sort((a: FaqEntry, b: FaqEntry) => {
        const aOrder = a.order ?? 0
        const bOrder = b.order ?? 0

        if (aOrder === bOrder) {
            return a.question.localeCompare(b.question)
        }

        if (aOrder === 0) {
            return 1
        }

        if (bOrder === 0) {
            // eslint-disable-next-line no-magic-numbers
            return -1
        }

        return aOrder - bOrder
    })
}