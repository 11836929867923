import { Box, Skeleton } from '@mui/material'

import styles from './CuratedExperienceDetailsContent.module.css'

const CuratedExperienceDetailsContentSkeleton = () => (
    <Box className={styles.skeletonContent}>
        <Skeleton variant="rounded" className={styles.skeletonHeaderImage}/>
        <Skeleton variant="text" className={styles.skeletonOverline}/>
        <Skeleton variant="text" className={styles.skeletonTitle}/>
        <Skeleton variant="text"/>
        <Skeleton variant="text"/>
    </Box>
)

export {
    CuratedExperienceDetailsContentSkeleton,
}