import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { UpmeLimitedDigitalResourceDetailByCategory } from '../../../types/LimitedDigitalResourceDetailByCategory'
import DigitalResourceCard from '../../molecules/DigitalResourceCard/DigitalResourceCard'
import HorizontalScrollComponent from '../../molecules/HorizontalScrollComponent/HorizontalScrollComponent'
import { DigitalResourceDetail } from '../../../types/DigitalResourceDetail'
import useGoogleTagManager from '../../../helpers/analytics/use-google-tag-manager'
import GtmEventName from '../../../helpers/analytics/GtmEventName'

import styles from './DigitalResourcesHomeList.module.scss'

const RESOURCES_MODULES_MARGIN = 3

interface DigitalResourcesHomeListProps {
    resources?: UpmeLimitedDigitalResourceDetailByCategory[];
}

const DigitalResourcesHomeList = (props: DigitalResourcesHomeListProps) => {
    const { resources } = props

    const { t } = useTranslation()
    const { trackButtonClick } = useGoogleTagManager()

    const resourceTitle = resources?.length ? (
        <Typography className={styles.moduleTitle} variant='h5' color='primary'>{t(`upme_digital_resource_title`)}</Typography>
    ) : null

    const renderResourceCard = (resource: DigitalResourceDetail, placeHolderImage: string) => (
        <DigitalResourceCard key={resource.id} digitalResource={resource} placeHolderImage={placeHolderImage} />
    )

    const scrollingWrapperComponentByCategory = resources?.map((category: UpmeLimitedDigitalResourceDetailByCategory, index: number) => {
        const resourceCategoryItems = category.items

        const handleOnSeeAllClick = () => {
            trackButtonClick(GtmEventName.SEE_ALL_CLICK, `Digital Resources: ${category.name}`)
        }

        return (
            <HorizontalScrollComponent
                key={`${category.name}`}
                items={resourceCategoryItems}
                seeAllRoute={`/upme/digital-resource/${category.name}`}
                listTitle={t(`upme_digital_resource_category`, { category: category.name })}
                renderItem={(resource) => renderResourceCard(resource, category.placeHolderImage)}
                mt={index ? RESOURCES_MODULES_MARGIN : 0}
                onSeeAllClick={handleOnSeeAllClick}
            />
        )
    })

    return (
        <Box>
            {resourceTitle}
            {scrollingWrapperComponentByCategory}
        </Box>
    )
}

export default DigitalResourcesHomeList
