import React, { useContext, useEffect, useState } from "react"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import { AppContext } from "../../stores/App/AppStore"
import { ActionTypes } from "../../stores/App/AppReducer"
import useGoogleTagManager from '../../helpers/analytics/use-google-tag-manager'
import { Route } from ".."

interface SplashScreenGuardProps {
    children: React.ReactElement
    nextRoute?: Route | string
}

const SplashScreenGuard: React.FC<SplashScreenGuardProps> = (props: SplashScreenGuardProps) => {
    const { children, nextRoute } = props

    const { state, dispatch } = useContext(AppContext)
    const navigate = useNavigate()
    const location = useLocation()
    const [done, setDone] = useState(false)
    const { sendUTMData } = useGoogleTagManager()

    const [searchParams, setSearchParams] = useSearchParams()
    const utm_source = searchParams.get(`utm_source`)
    const utm_medium = searchParams.get(`utm_medium`)
    const utm_campaign = searchParams.get(`utm_campaign`)
    const utm_id = searchParams.get(`utm_id`)
    const utm_term = searchParams.get(`utm_term`)
    const utm_content = searchParams.get(`utm_content`)
    const cid = searchParams.get(`cid`)

    useEffect(() => {
        if (utm_source || utm_medium || utm_campaign || utm_id || utm_term || utm_content || cid) {
            searchParams.delete(`utm_source`)
            searchParams.delete(`utm_medium`)
            searchParams.delete(`utm_campaign`)
            searchParams.delete(`utm_id`)
            searchParams.delete(`utm_term`)
            searchParams.delete(`utm_content`)
            searchParams.delete(`cid`)
            sendUTMData(utm_source, utm_medium, utm_campaign, utm_id, utm_term, utm_content, cid)
        }
        setSearchParams(searchParams)

        const pathName = location.pathname
        const nextPagePath = nextRoute ?? `${pathName}?${searchParams.toString()}`

        if (!state.splashScreenVisit) {
            dispatch({
                type: ActionTypes.VISITED_SPLASH_SCREEN,
                data: {
                    nextPagePath,
                },
            })
            navigate(Route.ROOT)
        }

        setDone(true)
    }, [])

    const emptyContainerStyle = {
        flexGrow: 1,
    }

    return done ? children : <div style={emptyContainerStyle}></div>
}

export default SplashScreenGuard