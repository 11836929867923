import { Slide, Snackbar, SnackbarContent, Typography } from "@mui/material"
import { Container } from "@mui/system"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { curatedExperienceValidityStringFormatter } from "../../../helpers/utils/curated-experience-validity-string-formatter"
import { CuratedExperienceDetail } from "../../../types"
import CuratedExperienceBottomOverlay from "../../molecules/CuratedExperienceBottomOverlay/CuratedExperienceBottomOverlay"
import { CuratedExperienceDetailsContentSkeleton } from "./CuratedExperienceDetailsContent.skeleton"
import { UserStatus } from "../../pages/CuratedExperienceDetails/CuratedExperienceDetails"

import styles from './CuratedExperienceDetailsContent.module.css'
import MarkdownContent from "../../atoms/MarkdownContent/MarkdownContent"

interface CuratedExperienceDetailsContentProps {
    curatedExperience?: CuratedExperienceDetail,
    userStatus: UserStatus,
}

const CuratedExperienceDetailsContent = (props: CuratedExperienceDetailsContentProps) => {
    const { curatedExperience, userStatus } = props

    const { t } = useTranslation()

    const [showToast, setShowToast] = useState(false)

    const handleDismissToast = () => {
        setShowToast(false)
    }

    if (curatedExperience) {
        const { headerImage, startDate, endDate, title, description } = curatedExperience

        return <>
            <>
                <Container
                    component="img"
                    className={styles.curatedExperienceHeaderImage}
                    src={headerImage}
                />
                <Typography variant="overline" className={styles.overline}>
                    {curatedExperienceValidityStringFormatter({
                        startDate, endDate,
                    })}
                </Typography>
                <Typography variant="h6" className={styles.title}>{title}</Typography>
                <MarkdownContent className={styles.curatedExperienceMarkdown} content={description}/>
            </>

            <CuratedExperienceBottomOverlay curatedExperience={curatedExperience} userStatus={userStatus} setShowToast={setShowToast} />

            <Snackbar
                anchorOrigin={{ vertical: `bottom`, horizontal: `center` }}
                autoHideDuration={3000}
                open={showToast}
                TransitionComponent={Slide}
                onClose={handleDismissToast}
            >
                <SnackbarContent className={styles.toastContent} message={t(`promo_code_copied`)} />
            </Snackbar>
        </>
    } else {
        return <CuratedExperienceDetailsContentSkeleton />
    }
}

export default CuratedExperienceDetailsContent