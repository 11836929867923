import { useContext, useEffect, useState } from "react"
import CustomSelect, {
    CustomSelectOption,
    CustomSelectProps,
} from "../../atoms/CustomSelect/CustomSelect"
import { AppContext } from "../../../stores/App/AppStore"
import { TrainingCourse } from "../../../types"
import { useTrainingCourseRepository } from "../../../repositories/TrainingCourse/use-training-course-repository"

type TrainingCourseSelectProps = Omit<CustomSelectProps, `options`> & {
    trainerId: number | string | undefined
};

const createOptions = (courses: TrainingCourse[]) => {
    const options: CustomSelectOption[] = []
    const trainingCourses = [...courses].sort((a, b) => a.id - b.id)

    trainingCourses.forEach((i) =>
        options.push({ label: i.name, value: i.id, id: i.code }),
    )

    return options
}

const TrainingCourseSelect = (props: TrainingCourseSelectProps) => {
    const { state } = useContext(AppContext)
    const { retrieveTrainingCourse } = useTrainingCourseRepository()
    const [options, setOptions] = useState<CustomSelectOption[]>([])
    const { trainerId, ...restOfProps } = props

    useEffect(() => {
        if (trainerId) {
            retrieveTrainingCourse(trainerId)
        }
    }, [trainerId])

    useEffect(() => {
        setOptions(createOptions(state.trainingCourse.data ?? []))
    }, [state.trainingCourse])

    return <CustomSelect {...restOfProps} options={options} />
}

export default TrainingCourseSelect