import { fetchStarterHomeCarousel } from '../../clients/starter-home-carousel'
import { fetchUpmeHomeCarousel } from '../../clients/upme-home-carousel'
import fromStrapiHighlightCarouselItem from '../../helpers/mapping/highlight-carousel-item'
import { HighlightCarouselItem } from '../../types/HighlightCarouselItem'
import { MembershipType } from '../../types/MembershipType'

class HomeCarouselRepository {
    async retrieveStarterHomeCarousel(membershipType: MembershipType): Promise<HighlightCarouselItem[]> {
        const homeCarouselResponse = membershipType === MembershipType.STARTER ? await fetchStarterHomeCarousel() : await fetchUpmeHomeCarousel()
        const carouselItems = homeCarouselResponse.map(fromStrapiHighlightCarouselItem)
        return carouselItems
    }
}

export default HomeCarouselRepository