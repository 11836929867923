import { Box, Skeleton } from '@mui/material'

import { HighlightCarouselItemImageAspectRatio } from './HighlightCarouselItemComponent'

import styles from './HighlightCarouselItemComponent.module.scss'

interface HighlightCarouselItemComponentSkeletonProps {
    aspectRatio?: HighlightCarouselItemImageAspectRatio,
}

const HighlightCarouselItemComponentSkeleton = (props: HighlightCarouselItemComponentSkeletonProps) => {
    const { aspectRatio } = props

    const imageSkeletonStyle = {
        aspectRatio,
    }

    return (
        <Box className={styles.skeletonContainer}>
            <Box className={styles.imageSkeleton} sx={imageSkeletonStyle}>
                <Skeleton variant="rectangular" />
            </Box>
            <Box className={styles.contentSkeleton}>
                <Box className={styles.titleSkeletonContainer}>
                    <Skeleton className={styles.titleSkeleton} variant="rectangular" />
                    <Skeleton className={styles.titleSkeleton} variant="rectangular" />
                </Box>
                <Box className={styles.descriptionSkeletonContainer}>
                    <Skeleton className={styles.descriptionSkeleton} variant="rectangular" />
                    <Skeleton className={styles.descriptionSkeleton} variant="rectangular" width="70%" />
                    <Skeleton className={styles.descriptionSkeleton} variant="rectangular" width="90%" />
                </Box>
            </Box>
        </Box>
    )
}

export default HighlightCarouselItemComponentSkeleton