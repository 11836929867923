import { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { Deal } from "../../../types/Deal"
import DealCard from "../../molecules/DealCard/DealCard"
import { Route } from "../../../router"
import { UserContext } from "../../../stores/User/UserStore"
import HorizontalScrollComponent from "../../molecules/HorizontalScrollComponent/HorizontalScrollComponent"
import useGoogleTagManager from '../../../helpers/analytics/use-google-tag-manager'
import GtmEventName from '../../../helpers/analytics/GtmEventName'

interface DealsHomeListProps {
    deals?: Deal[]
}

const DealsHomeList = (props: DealsHomeListProps) => {
    const { deals } = props
    const { t } = useTranslation()
    const { trackButtonClick } = useGoogleTagManager()

    const { state: userState } = useContext(UserContext)

    const renderDealCard = (deal: Deal) => (
        <DealCard key={deal.id} deal={deal} />
    )

    const handleSeeAllClick = () => {
        trackButtonClick(GtmEventName.SEE_ALL_CLICK, `Deals`)
    }

    return <HorizontalScrollComponent items={deals} listTitle={t(`deals_and_promotions`)} seeAllRoute={Route.DEALS_LIST} onSeeAllClick={handleSeeAllClick} renderItem={renderDealCard} isLandingPage={!!userState.membership?.data} />
}

export default DealsHomeList