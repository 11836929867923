import { Box, BoxProps, LinearProgress, Typography } from "@mui/material"

import styles from './ProgressBar.module.scss'

interface ProgressBarProps extends BoxProps {
    progress: number
}

const ProgressBar = (props: ProgressBarProps) => {
    const { progress, ...inheritedProps } = props

    if (progress === 0) {
        return <></>
    }

    return (
        <Box className={styles.progressContainer} {...inheritedProps}>
            <Box className={styles.progressBar}>
                <LinearProgress variant='determinate' value={progress} />
            </Box>
            <Box className={styles.progressLabel}>
                <Typography variant='body2' color='text.secondary'>{`${progress}%`}</Typography>
            </Box>
        </Box>
    )
}

export default ProgressBar