import { Box, Typography, useTheme} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Membership } from '../../../types'

import styles from "./StarterProfileUpdateInfo.module.scss"

interface StarterProfileInfoProps {
    userMembership: Membership
}

const StarterProfileUpdateInfo = (props: StarterProfileInfoProps) => {
    const { userMembership } = props
    const { t } = useTranslation()

    const { palette } = useTheme()

    const labelTextLighter = {
        color: palette?.functional?.text.lighter,
    }

    const formatYearsOfGraduation = userMembership?.yearsBeforeGraduation === 1 ? t(`profile_one_year`) : t(`profile_years`, { num: userMembership?.yearsBeforeGraduation })

    return (
        <>
            <Box className={styles.info}>
                <Typography variant="body2" sx={labelTextLighter} className={styles.profileLabel}>{t(`sign_up_name`)}</Typography>
                <Typography variant="subtitle1" className={styles.profileDetails} mb={1}>{userMembership?.fullName}</Typography>
            </Box>
            <Box className={styles.info}>
                <Typography variant="body2" sx={labelTextLighter} className={styles.profileLabel}>{t(`sign_up_phone_number`)}</Typography>
                <Typography variant="subtitle1" className={styles.profileDetails} mb={1}>{userMembership?.phoneNumber ?? t(`profile_update_value_missing`)}</Typography>
            </Box>
            <Box className={styles.info}>
                <Typography variant="body2" sx={labelTextLighter} className={styles.profileLabel}>{t(`sign_up_email_address`)}</Typography>
                <Typography variant="subtitle1" className={styles.profileDetails} mb={1}>{userMembership?.emailAddress ?? t(`profile_update_value_missing`)}</Typography>
            </Box>
            <Box className={styles.info}>
                <Typography variant="body2" sx={labelTextLighter} className={styles.profileLabel}>{t(`sign_up_school`)}</Typography>
                <Typography variant="subtitle1" className={styles.profileDetails} mb={1}>{userMembership?.institutionID?.name ?? t(`profile_update_value_missing`)}</Typography>
            </Box>
            <Box className={styles.info}>
                <Typography variant="body2" sx={labelTextLighter} className={styles.profileLabel}>{t(`sign_up_years_left`)}</Typography>
                <Typography variant="subtitle1" className={styles.profileDetails} mb={1}>{formatYearsOfGraduation ?? t(`profile_update_value_missing`)}</Typography>
            </Box>
            <Box className={styles.info}>
                <Typography variant="body2" sx={labelTextLighter} className={styles.profileLabel}>{t(`sign_up_field_of_study`)}</Typography>
                <Typography variant="subtitle1" className={styles.profileDetails} mb={1}>{userMembership?.fieldOfStudyID?.name ?? t(`profile_update_value_missing`)}</Typography>
            </Box>
            <Box className={styles.info}>
                <Typography variant="body2" sx={labelTextLighter} className={styles.profileLabel}>{t(`sign_up_name_ecard`)}</Typography>
                <Typography variant="subtitle1" className={styles.profileDetails} mb={1}>{userMembership?.eCardName ?? t(`profile_update_value_missing`)}</Typography>
            </Box>
        </>
    )
}

export default StarterProfileUpdateInfo