import CommonBackButton from "../../../atoms/CommonBackButton/CommonBackButton"
import { Box, Container, Grid, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"

import UpmeBenefitCardSkeleton from "../../../molecules/UpmeBenefitCard/UpmeBenefitCard.skeleton"
import ScrollingTabsSkeleton from "../../../organisms/ScrollingTabs/ScrollingTabs.skeleton"
import { Route } from "../../../../router"

import styles from './UpmeBenefitsList.module.scss'

const UpmeBenefitsListSkeleton = () => {
    const { t } = useTranslation()

    return (
        <Box>
            <Container className={styles.hiddeableHeader}>
                <CommonBackButton path={Route.UPME_CONVERSION_INTRO} />
                <Typography className={styles.pageTitle} variant="h3">{t(`upme_benefits_list_title`)}</Typography>
            </Container>

            <ScrollingTabsSkeleton className={styles.scrollingTabsSkeleton} />

            <Container id="benefitsList" className={styles.container}>
                <Grid className={styles.benefitsList} container direction="column" alignItems="center" rowSpacing={2}>
                    <Grid item width="100%">
                        <UpmeBenefitCardSkeleton />
                    </Grid>
                    <Grid item width="100%">
                        <UpmeBenefitCardSkeleton />
                    </Grid>
                    <Grid item width="100%">
                        <UpmeBenefitCardSkeleton />
                    </Grid>
                </Grid>
            </Container>
        </Box >
    )
}

export default UpmeBenefitsListSkeleton