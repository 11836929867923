import { Link, useLocation } from "react-router-dom"
import { Box, Typography, useTheme } from "@mui/material"
import { useTranslation } from "react-i18next"
import { Route } from "../../../router"
import { domainCheckMembershipType } from "../../../helpers/utils/common"
import { MembershipType } from "../../../types/MembershipType"
import useGoogleTagManager from "../../../helpers/analytics/use-google-tag-manager"

import styles from './AppFooter.module.scss'
import GtmEventName from "../../../helpers/analytics/GtmEventName"
import GtmEventCategory from "../../../helpers/analytics/GtmEventCategory"
import GtmEventAction from "../../../helpers/analytics/GtmEventAction"

export const ABOUT_NTUC_MEMBERSHIP_URL = ` https://www.ntuc.org.sg/uportal/memberships/ntuc-membership`

interface AppFooterProps {
    isUpmeMember: boolean
}

const AppFooter = (props: AppFooterProps) => {
    const { isUpmeMember } = props

    const { t } = useTranslation()
    const location = useLocation()
    const { trackEvent } = useGoogleTagManager()

    const theme = useTheme()
    const { palette } = theme

    const membershipTypeFromDomainName = domainCheckMembershipType()

    const handleAboutClick = (label: string) => {
        return () => {
            trackEvent(GtmEventName.FOOTER_BUTTON_CLICK, GtmEventCategory.BUTTON, GtmEventAction.CLICK, label)
        }
    }

    const renderAboutPage = () => {
        if (membershipTypeFromDomainName === MembershipType.STARTER && !isUpmeMember) {
            return (<Link to={Route.ABOUT} onClick={handleAboutClick(`About Young NTUC`)}>
                <Typography variant="overline">{t(`about_young_ntuc`)}</Typography>
            </Link>)
        } else {
            return (<a href={ABOUT_NTUC_MEMBERSHIP_URL} target="blank" onClick={handleAboutClick(`About MyNTUC`)}>
                <Typography variant="overline">{t(`about_myntuc`)}</Typography>
            </a>)
        }
    }

    const aboutPage = renderAboutPage()

    const isLandingPage = location.pathname === Route.LANDING_PAGE

    const marginBottom = isLandingPage ? { marginBottom: `150px` } : {}

    const footerStyle = {
        ...marginBottom,
        backgroundColor: palette.surface.tertiary.main,
        color: `green`,
    }

    return <Box className={styles.footer} sx={footerStyle}>
        {aboutPage}
        <Typography variant="overline"> | </Typography>
        <Link to={Route.FAQ} onClick={handleAboutClick(`FAQ`)}>
            <Typography variant="overline">{t(`faq`)}</Typography>
        </Link>
        <Typography variant="overline"> | </Typography>
        <Link to={Route.APPLICATION_TERMS_OF_USE} onClick={handleAboutClick(`Terms of use`)}>
            <Typography variant="overline">{t(`application_terms_of_use_link`)}</Typography>
        </Link>
        <Typography variant="overline"> | </Typography>
        <Link to={Route.PRIVACY_POLICY} onClick={handleAboutClick(`Privacy Policy`)}>
            <Typography variant="overline">{t(`privacy_policy`)}</Typography>
        </Link>
        <Typography variant="overline"> | </Typography>
        <Link to={Route.CONTACT_US} onClick={handleAboutClick(`Contact Us`)}>
            <Typography variant="overline">{t(`common_contact_us`)}</Typography>
        </Link>
    </Box>
}

export default AppFooter
