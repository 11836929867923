import axios from "axios"

const UNDER_MAINTENANCE_FLAG = `ON`

const isUnderMaintenance = async () => {
    return axios.get(`${process.env.REACT_APP_MAINTENANCE_TOGGLE_URL}`)
        .then(res => {
            return res.data === UNDER_MAINTENANCE_FLAG
        })
        .catch(() => {
            return false
        })
}

export {
    isUnderMaintenance,
}