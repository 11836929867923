import { useNavigate, useParams } from "react-router-dom"
import { Box, Button, Container } from "@mui/material"
import { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import { useMembershipPlanRepository } from "../../../repositories/MembershipPlan/use-membership-plan-repository"
import MembershipPlanTNCsDialogDialog from "../../molecules/MembershipPlanTnCsDialog/MembershipPlanTNCsDialog"
import MembershipJoinPageContent from "../../molecules/MembershipJoinPageContent/MembershipJoinPageContent"
import { usePartnerRepository } from "../../../repositories/Partner/use-partner-repository"
import PartnerOrPlanLandingPageSkeleton from "./PartnerOrPlanLandingPage.skeleton"
import SplashScreenGuard from "../../../router/guards/SplashScreenGuard"
import { logLandingPageAction } from "../../../clients/logger"
import LocalStorageKeys from "../../../types/LocalStorageKeys"
import { MyInfoReason } from "../../../types/MyInfoUserData"
import { UserContext } from "../../../stores/User/UserStore"
import { Route, STRAPI_URL } from "../../../router"
import { MembershipStatus } from "../../../types/MembershipStatus"
import { PaymentOrderReason } from "../../../types/PaymentOrderReason"
import SignUpButton from "../../atoms/SignUpButton/SignUpButton"
import ActivateMembershipButton from "../../atoms/ActivateMembershipButton/ActivateMembershipButton"
import useTrackPageView from "../../../helpers/hooks/use-track-page-view"
import useGoogleTagManager from '../../../helpers/analytics/use-google-tag-manager'
import GtmEventName from '../../../helpers/analytics/GtmEventName'

import styles from './PartnerOrPlanLandingPage.module.scss'

const PartnerOrPlanLandingPage = () => {
    const { partnerCode, plan, referralCode } = useParams()

    const { t } = useTranslation()
    const { trackButtonClick } = useGoogleTagManager()

    const navigate = useNavigate()
    const { saveMembershipPlanKey, retrieveMembershipPlan } = useMembershipPlanRepository()
    const { retrievePartner, setVisitedPartnerPage } = usePartnerRepository()
    const { state: userState } = useContext(UserContext)

    const [showMembershipTNCsDialog, setShowMembershipTNCsDialog] = useState<boolean>(false)

    const membershipPlanKey = plan?.toUpperCase()

    const membershipPlan = userState.membershipPlan?.data
    const partner = userState.partner?.data
    const membership = userState.membership?.data

    useTrackPageView(() => {
        if (partner) {
            return {
                title: `Partner Landing Page`,
                item: partner.name,
            }
        }
    }, [partner])

    useTrackPageView(() => {
        if (membershipPlan) {
            return {
                title: `Membership Plan Landing Page`,
                item: membershipPlan.name,
            }
        }
    }, [membershipPlan])

    useEffect(() => {
        if (partnerCode) {
            retrievePartner(partnerCode)
        } else if (membershipPlanKey) {
            saveMembershipPlanKey(membershipPlanKey)
            retrieveMembershipPlan(membershipPlanKey)
        }
    }, [])

    useEffect(() => {
        setVisitedPartnerPage()

        if (userState?.partner?.error) {
            navigate(Route.LANDING_PAGE)
        }
    }, [userState.partner])

    useEffect(() => {
        if (userState?.membershipPlan?.error) {
            navigate(Route.LANDING_PAGE)
        }
    }, [userState.membershipPlan])

    useEffect(() => {
        if (referralCode) {
            localStorage.setItem(LocalStorageKeys.REFERRAL_CODE, referralCode.toUpperCase())
        }
    }, [referralCode])

    const handleSignUpButtonClick = () => {
        trackButtonClick(GtmEventName.SIGN_UP_CLICK, membershipPlan?.key)
        localStorage.setItem(LocalStorageKeys.MYINFO_REASON, MyInfoReason.SIGNUP)
        logLandingPageAction(`Sign Up`, membershipPlan ? `membershipPlan` : `partnership`, plan?.toUpperCase() ?? partner?.code)
        window.location.href = `${STRAPI_URL}${Route.MYINFO_AUTHORISE}`
    }

    const handleDiscoverNtucButtonClick = () => {
        trackButtonClick(GtmEventName.DISCOVER_NTUC_CLICK, partner?.name ?? membershipPlanKey)
        logLandingPageAction(`Discover NTUC Starter`, membershipPlan ? `membershipPlan` : `partnership`, plan?.toUpperCase() ?? partner?.code)
        navigate(Route.LANDING_PAGE)
    }

    const handleActivateMembershipButtonClick = () => {
        trackButtonClick(GtmEventName.ACTIVATE_ACCOUNT_CLICK, partner?.name)
        logLandingPageAction(`Login`, `partnership`, partnerCode ?? ``)

        if (userState.membership?.data?.status === MembershipStatus.PENDING_ACTIVATION) {
            navigate(`/membership/payment-summary/${PaymentOrderReason.SIGNUP}`, { replace: true })
        } else {
            localStorage.setItem(LocalStorageKeys.MYINFO_REASON, MyInfoReason.SIGNUP)
            window.location.href = `${STRAPI_URL}${Route.MYINFO_AUTHORISE}`
        }
    }

    const handleTermsAndConditionButtonClick = () => {
        trackButtonClick(GtmEventName.TERMS_AND_CONDITIONS_CLICK, membershipPlanKey)
        setShowMembershipTNCsDialog(true)
    }

    const joinPageContent = {
        mainImage: membershipPlan?.bannerTop ?? partner?.logo,
        description: membershipPlan?.description ?? partner?.description,
        bottomImage: membershipPlan?.bannerBottom,
    }

    if (!joinPageContent.mainImage || !joinPageContent.description) {
        return (
            <SplashScreenGuard>
                <PartnerOrPlanLandingPageSkeleton />
            </SplashScreenGuard>
        )
    }

    const discoverNtucButton = (
        <Button variant='outlined' className={styles.loginButton} onClick={handleDiscoverNtucButtonClick}>
            {t(`landing_page_discover_starter`)}
        </Button>
    )

    return (
        <SplashScreenGuard>
            <Container className={styles.container}>
                <Box className={styles.pageContent}>
                    <MembershipJoinPageContent mainImage={joinPageContent.mainImage} description={joinPageContent.description} bottomImage={joinPageContent.bottomImage} />
                    {
                        membershipPlan &&
                        <MembershipPlanTNCsDialogDialog
                            membershipPlan={membershipPlan}
                            buttonText={t(`close`)}
                            shouldShow={showMembershipTNCsDialog}
                            onClose={() => setShowMembershipTNCsDialog(false)}
                            onLinkTextClick={handleTermsAndConditionButtonClick}
                        />
                    }
                </Box>
                <Box className={styles.bottomButtons}>
                    {
                        membershipPlan ?
                            <SignUpButton onClick={handleSignUpButtonClick} /> :
                            <ActivateMembershipButton onClick={handleActivateMembershipButtonClick} />
                    }
                    {!membership && discoverNtucButton}
                </Box>
            </Container>
        </SplashScreenGuard>
    )
}

export default PartnerOrPlanLandingPage