import { Card, Skeleton } from '@mui/material'

import styles from './MembershipCardContent.module.css'

const MembershipCardContentSkeleton = () => (
    <>
        <Skeleton variant={`circular`} className={styles.skeletonUnionLogo} />
        <Card className={styles.skeletonCardBody}>
            <Skeleton variant={`text`} className={styles.skeletonUnionText} />
            <Skeleton variant={`rounded`} className={styles.skeletonUnionCard} />
            <Skeleton variant={`text`} className={styles.skeletonUnionText} />
            <Skeleton variant={`text`} className={styles.skeletonUnionText} />
            <Skeleton variant={`text`} className={styles.skeletonUnionText} />
        </Card>
    </>
)

export { MembershipCardContentSkeleton }