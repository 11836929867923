import { isNil } from 'lodash'
import { MembershipType } from '../../types/MembershipType'
import moment from 'moment'

const FILE_EXTENSION_DELIMITER = `.`

export const formatToTwoDecimalPlaces = (num?: number) => {
    if (!isNil(num)) {
        // eslint-disable-next-line no-magic-numbers
        return num.toFixed(2)
    } else {
        return undefined
    }
}

export const appendFileToFormData = (formData: FormData, key: string, value: File) => {
    const fieldName = `${key}.${value.name.split(FILE_EXTENSION_DELIMITER).pop()}`
    formData.append(key, value, fieldName)
}

export const isNewCalendarYear = (date?: Date): boolean => {
    if (!date) {
        return false
    }
    const lastUpdatedDate = moment(date)
    const today = moment()

    return lastUpdatedDate.year() !== today.year()
}

export const domainCheckMembershipType = (): MembershipType => {
    const isUpme = isUpmeDomain()
    const membershipType = isUpme ? MembershipType.UPME : MembershipType.STARTER
    return membershipType
}

export const isUpmeDomain = () => {
    const locationHostName = window.location.hostname
    return locationHostName === process.env.REACT_APP_UPME_DOMAIN_NAME
}

export default {
    isNewCalendarYear,
    formatToTwoDecimalPlaces,
    isUpmeDomain,
    domainCheckMembershipType,
    appendFileToFormData,
}