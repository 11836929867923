import { ProfilePictures } from '../components/pages/Profile/ProfilePictureSelection/ProfilePictureSelection.utils'
import { appendFileToFormData } from '../helpers/utils/common'
import { HTTP_SUCCESS, HTTP_UNAUTHORIZED } from '../helpers/utils/http-status'
import { MyInfoUserData, SignUpForm } from '../types'
import { authenticatedClient } from './util'

const postMembership = async (signUpForm: SignUpForm, myInfoUserData: MyInfoUserData) => {
    return authenticatedClient()
        .post(
            `/api/memberships`,
            {
                signUpForm,
                myInfoUserData,
            },
        ).then(res => {
            if (res.status !== HTTP_SUCCESS) {
                throw new Error(`Could not save membership`)
            }
            return res
        }).then(res => res.data)
}

const updateMembership = async (signUpForm: SignUpForm, membershipID: number) => {
    return authenticatedClient()
        .put(
            `/api/memberships/${membershipID}`,
            signUpForm,
        ).then(res => {
            if (res.status !== HTTP_SUCCESS) {
                throw new Error(`Could not update membership`)
            }
            return res
        }).then(res => res.data)
}

const retrieveMembership = async () => {
    return authenticatedClient()
        .get(
            `/api/memberships`,
        ).then(res => {
            if (res.status !== HTTP_SUCCESS) {
                throw new Error(`Could not retrieve membership`)
            }
            return res
        }).then(res => res.data)
}

const joinProgramme = async (programme: string) => {
    return authenticatedClient()
        .post(
            `/api/programme/join`,
            { programme },
        ).then(res => {
            if (res.status !== HTTP_SUCCESS) {
                throw new Error(`Could not join programme`)
            }
            return res
        }).then(res => res.data)
}

const retrieveUnionMembership = async () => {
    return authenticatedClient()
        .get(
            `/api/memberships/check-union-membership-status`,
        ).then(res => {
            if (res.status !== HTTP_SUCCESS) {
                throw new Error(`Could not retrieve union membership`)
            }
            return res
        }).then(res => res.data)
}

const retrieveUnionMembershipWithNRIC = async (nric: string) => {
    return authenticatedClient()
        .post(
            `/api/memberships/check-union-membership-status-with-nric`,
            { nric },
        ).then(res => {
            switch (res.status) {
                case HTTP_SUCCESS:
                    return res
                case HTTP_UNAUTHORIZED:
                    throw new Error(`NRIC mismatch`)
                default:
                    throw new Error(`Could not retrieve union membership`)
            }
        }).then(res =>
            res.data,
        )
}

const cancelMembership = async (terminateOnCancelDate: Date) => {
    return authenticatedClient()
        .post(
            `/api/memberships/cancel`,
            { terminateOnCancelDate },
        ).then(res => {
            if (res.status !== HTTP_SUCCESS) {
                throw new Error(`Cannot cancel membership`)
            }
            return res
        }).then(res => res.data)
}

const resumeRecurringMembership = async (resumeRecurringMembership: boolean) => {
    return authenticatedClient()
        .post(
            `/api/memberships/resume`,
            { resumeRecurringMembership },
        ).then(res => {
            if (res.status !== HTTP_SUCCESS) {
                throw new Error(`Cannot resume recurring membership`)
            }
            return res
        }).then(res =>res.data)
}

const uploadProfilePicture = async (profilePictures: ProfilePictures) => {
    const multipartFormHeader = {
        headers: {
            'Content-Type': `multipart/form-data`,
        },
    }

    const formData = new FormData()
    appendFileToFormData(formData, `original`, new File([profilePictures.original.blob], `original.jpg`, { type: `image/jpeg` }))
    appendFileToFormData(formData, `thumbnail`, new File([profilePictures.thumbnail.blob], `thumbnail.jpg`, { type: `image/jpeg` }))

    return authenticatedClient()
        .post(
            `/api/memberships/profile-picture`,
            formData,
            multipartFormHeader,
        ).then(res => {
            if (res.status !== HTTP_SUCCESS) {
                throw new Error(`Could not save utap claim`)
            }
            return res
        }).then(res => res.data)
}

const retrieveProfilePicture = async () => {
    return authenticatedClient()
        .get(
            `/api/memberships/profile-picture`,
            {
                responseType: `arraybuffer`,
            },
        ).then(res => {
            if (res.status !== HTTP_SUCCESS) {
                throw new Error(`Cannot retrieve original profile image`)
            }
            return res
        }).then(res => res.data)
}

const fetchAddressFromPostalCode = async (postalCode: string) => {
    return authenticatedClient()
        .post(
            `/api/memberships/get-address-from-postal-code?postalCode`,
            {postalCode},
        ).then(res => {
            if (res.status !== HTTP_SUCCESS) {
                throw new Error(`Could not retrieve address from UCEM API`)
            }
            return res
        }).then(res => res.data)
}

export {
    cancelMembership,
    joinProgramme,
    postMembership,
    retrieveMembership,
    retrieveUnionMembership,
    retrieveUnionMembershipWithNRIC,
    updateMembership,
    uploadProfilePicture,
    retrieveProfilePicture,
    fetchAddressFromPostalCode,
    resumeRecurringMembership,
}