import { Box, BoxProps, Typography, useTheme } from "@mui/material"
import { useState } from "react"
import clsx from "clsx"

import styles from "./SelectableCard.module.scss"

interface SelectableCardProps extends BoxProps {
    itemId: number,
    image: string,
    label: string,
    selected?: boolean,
    selectable?: boolean,
    onItemClick?: (id: number, selected: boolean) => void,
}

const SelectableCard = (props: SelectableCardProps) => {
    const theme = useTheme()

    const { itemId, image, label, selected, selectable, onItemClick, className, ...inheritedProps } = props

    const [isSelected, setIsSelected] = useState(selected)
    const handleClick = () => {
        if (!isSelected && selectable !== undefined && !selectable) {
            return
        }

        setIsSelected(!isSelected)
        onItemClick?.(itemId, !isSelected)
    }

    const cardStyle = clsx(
        styles.container,
        className,
    )

    const backgroundColor = isSelected ? theme.palette.primary.lighter : `transparent`

    return (
        <Box className={cardStyle} onClick={handleClick} {...inheritedProps} sx={{ backgroundColor: backgroundColor }}>
            <Box className={styles.image}>
                <img src={image} alt={`Image for card with label ${label}`} />
            </Box>
            <Box className={styles.label}>
                <Typography variant="subtitle1">{label}</Typography>
            </Box>
        </Box>
    )
}

export default SelectableCard