import { authenticatedClient } from './util'
import { HTTP_SUCCESS } from '../helpers/utils/http-status'
import { MyInfoReason } from '../types/MyInfoUserData'

const fetchMyInfoUserData = async (code: string, state: string, liftAgeLimit: boolean, reason?: string) => {
    const requestBody = {
        code,
        state,
        liftAgeLimit,
        reason: reason ?? MyInfoReason.SIGNUP,
    }

    return authenticatedClient(true).post(`/api/pls/myinfo/data`, requestBody)
        .then(res => {
            if (res.status !== HTTP_SUCCESS) {
                throw new Error(`Could not retrieve myInfo data`)
            }
            return res
        })
        .then(res => res.data)
}

export {
    fetchMyInfoUserData,
}