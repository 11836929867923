import { useContext, useEffect, useState } from "react"
import { AppBar, Box, IconButton, AppBarProps } from "@mui/material"
import MenuIcon from '@mui/icons-material/Menu'
import clsx from "clsx"
import { useNavigate } from "react-router"

import { AppContext } from "../../../stores/App/AppStore"
import { useHomePageRepository } from "../../../repositories/HomePage/use-home-page-repository"
import UserAvatar from "../../atoms/UserAvatar/UserAvatar"
import { UserContext } from "../../../stores/User/UserStore"
import ProfileMenu from "../ProfileMenu/ProfileMenu"
import MainMenu from "../MainMenu/MainMenu"
import { Route } from "../../../router"
import useGoogleTagManager from '../../../helpers/analytics/use-google-tag-manager'
import GtmEventName from '../../../helpers/analytics/GtmEventName'

import styles from "./HeaderPageComponent.module.scss"

const HeaderPageComponent = (props: AppBarProps) => {
    const { className, ...inheritedProps } = props

    const { retrieveHomePage } = useHomePageRepository()
    const { state } = useContext(AppContext)
    const { state: userState } = useContext(UserContext)

    const navigate = useNavigate()
    const { trackButtonClick, trackMenuClick } = useGoogleTagManager()

    // for stripe customer portal redirection
    const url = new URL(window.location.href)
    const hasProfileMenu = url.searchParams.has(`profileMenu`)

    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(hasProfileMenu ?? false)

    const userMembership = userState.membership?.data
    const membershipType = userMembership?.type

    useEffect(() => {
        if (userMembership) {
            retrieveHomePage(userMembership.type)
        }
    }, [])

    const handleAvatarProfileClick = () => {
        trackMenuClick(GtmEventName.OPEN_PROFILE_MENU)
        setIsProfileMenuOpen(true)
    }

    const handleAvatarProfileClose = () => {
        if (hasProfileMenu) {
            const url = new URL(window.location.href)
            url.searchParams.delete(`profileMenu`)
            window.history.replaceState({}, ``, url.toString())
            setIsProfileMenuOpen(false)
        } else {
            setIsProfileMenuOpen(false)
        }

    }

    const handleMenuClick = () => {
        trackMenuClick(GtmEventName.OPEN_MAIN_MENU)
        setIsMenuOpen(true)
    }

    const handleMenuClose = () => {
        setIsMenuOpen(false)
    }

    const handleLogoClick = () => {
        trackButtonClick(GtmEventName.HEADER_TITLE_CLICK)

        if (userMembership) {
            navigate(Route.HOME, { replace: true })
        } else {
            navigate(Route.LANDING_PAGE, { replace: true })
        }
    }

    const appBarClassName = clsx(
        className,
        styles.appBar,
    )

    const isLoggedInUserAvatar = userState.membership?.data && (
        <IconButton onClick={handleAvatarProfileClick}>
            <UserAvatar />
        </IconButton>
    )

    const isLoggedInUserShowHamburger = userState.membership?.data && (
        <IconButton className={styles.hamburgerMenu} onClick={handleMenuClick}>
            <MenuIcon fontSize={`large`} />
        </IconButton>
    )

    return (
        <>
            <AppBar id="starter-app-bar" className={appBarClassName} position="sticky" color="primary" {...inheritedProps}>
                <Box className={styles.leftIconContainer}>
                    {isLoggedInUserAvatar}
                </Box>
                <Box component='img' className={styles.logo} src={state.pages.home.bannerImageURL} alt="header logo" onClick={handleLogoClick} />
                <Box className={styles.rightContainer}>
                    {isLoggedInUserShowHamburger}
                </Box>
            </AppBar>
            <ProfileMenu expanded={isProfileMenuOpen} handleMenuCloseClick={handleAvatarProfileClose} membershipType={membershipType} user={userState} />
            <MainMenu isOpen={isMenuOpen} handleMenuCloseClick={handleMenuClose} membershipType={membershipType} />
        </>
    )
}

export default HeaderPageComponent