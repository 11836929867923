import { Box, Container, Skeleton } from "@mui/material"

import CommonBackButton from "../../atoms/CommonBackButton/CommonBackButton"
import MarkdownContent from "../../atoms/MarkdownContent/MarkdownContent"
import { Route } from "../../../router"
import Spacer from "../../atoms/Spacer/Spacer"
import AppCardSkeleton from "../../atoms/AppCard/AppCard.skeleton"

import styles from './DealDetails.module.scss'

const DealDetailsSkeleton = () => {

    return (
        <Container className={styles.container}>
            <Box className={styles.skeletonBackButtonBox}>
                <CommonBackButton path={Route.DEALS_LIST} />
            </Box>

            <AppCardSkeleton />

            <Spacer height={24} />

            <MarkdownContent />

            <Skeleton className={styles.descriptionSkeleton} variant="rectangular" />
            <Skeleton className={styles.descriptionSkeleton} variant="rectangular" />
        </Container>
    )
}

export default DealDetailsSkeleton