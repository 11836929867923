import { anonymousClient } from './util'
import { HTTP_SUCCESS } from '../helpers/utils/http-status'
import { MembershipType } from '../types/MembershipType'

const fetchDigitalResourcesByCategory = async (categoryId: number, membershipType: MembershipType) => {
    return anonymousClient()
        .get(`/api/digital-resources?populate[0]=image&populate[1]=category&filters[category][id][$eq]=${categoryId}&filters[$or][0][membershipScope]=ALL&filters[$or][0][membershipScope]=${membershipType}`)
        .then(res => {
            if (res.status !== HTTP_SUCCESS) {
                throw new Error(`Could not fetch UPME Digital Resources by category`)
            }
            return res.data
        })
}

const fetchDigitalResourceCategories = async () => {
    return anonymousClient()
        .get(`/api/digital-resource-categories?populate[0]=placeHolderImage&filters`).then(res => {
            if (res.status !== HTTP_SUCCESS) {
                throw new Error(`Could not fetch UPME Digital Resource categories`)
            }
            return res.data
        })
}

const fetchDigitalResourceById = async (id: number) => {
    return anonymousClient()
        .get(`/api/digital-resources/${id}?populate[0]=image&populate[1]=category`).then(res => {
            if (res.status !== HTTP_SUCCESS) {
                throw new Error(`Could not fetch UPME Digital Resource by Id`)
            }
            return res.data
        })
}

const fetchLimitedDigitalResourcesByCategory = async (membershipType: MembershipType) => {
    return anonymousClient()
        .get(`api/fetch-limit-resources-by-category`, { params: { membershipType } }).then(res => {
            if (res.status !== HTTP_SUCCESS) {
                throw new Error(`Could not fetch limited digital resource by category`)
            }
            return res.data
        })
}

export {
    fetchDigitalResourcesByCategory,
    fetchDigitalResourceById,
    fetchDigitalResourceCategories,
    fetchLimitedDigitalResourcesByCategory,
}