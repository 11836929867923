import { useContext, useEffect, useState } from 'react'
import CustomSelect, { CustomSelectOption, CustomSelectProps } from '../../atoms/CustomSelect/CustomSelect'
import { AppContext } from '../../../stores/App/AppStore'
import { Bank } from '../../../types'
import { useBankRepository } from '../../../repositories/Bank/use-bank-repository'

type BankSelectProps = Omit<CustomSelectProps, `options`>

const createOptions = (bank: Bank[]) => {
    const options: CustomSelectOption[] = []
    const localCopy = [...bank].sort((a, b) => a.id - b.id)

    localCopy.forEach((i) => options.push({ label: i.name, value: i.id, id: i.code }))

    return options
}

const BankSelect = (props: BankSelectProps) => {
    const { state } = useContext(AppContext)
    const { retrieveBank } = useBankRepository()
    const [options, setOptions] = useState<CustomSelectOption[]>([])

    useEffect(() => {
        if (state.bank.length < 1) {
            retrieveBank()
        }
    }, [])

    useEffect(() => {
        setOptions(createOptions(state.bank))
    }, [state.bank])

    return (
        <CustomSelect
            {...props}
            options={options}
        />
    )
}

export default BankSelect