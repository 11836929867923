import { Box, Container, Grid, Skeleton } from "@mui/material"

import styles from './PartnerOrPlanLandingPage.module.scss'

const DESCRIPTION_LINES_COUNT = 10

const PartnerOrPlanLandingPageSkeleton = () => {
    const descriptionLines = () => {
        const descripionLines = []

        for (let i = 0; i < DESCRIPTION_LINES_COUNT; i++) {
            descripionLines.push(<Skeleton variant='text' />)
        }

        return descripionLines
    }

    return (
        <Container className={styles.skeleton}>
            <Grid item className={styles.banner}>
                <Skeleton variant='rectangular' />
            </Grid>
            <Grid item className={styles.description}>
                {descriptionLines()}
            </Grid>
            <Box className={styles.bottomButtons}>
                <Skeleton variant='rectangular' />
                <Skeleton variant='rectangular' />
            </Box>
        </Container>
    )
}

export default PartnerOrPlanLandingPageSkeleton