import { useContext } from 'react'

import { AppContext } from '../../stores/App/AppStore'
import { ActionTypes } from '../../stores/App/AppReducer'
import PillarRepository from './pillar.repository'
import { MembershipType } from '../../types/MembershipType'
import { Pillar, ServiceResponse } from '../../types'
import { starterDataError } from '../../helpers/utils/error-handling'

const usePillarRepository = () => {
    const { dispatch } = useContext(AppContext)
    const pillarRepository = new PillarRepository()

    const retrievePillars = (membershipType: MembershipType | undefined) => {
        const pillarsResponse: ServiceResponse<Pillar[]> = {}

        pillarRepository.getAll(membershipType ?? MembershipType.STARTER)
            .then((data) => {
                pillarsResponse.data = data
            })
            .catch((error) => {
                pillarsResponse.error = starterDataError(error)
            })
            .finally(() => {
                dispatch({
                    type: ActionTypes.PILLARS_RETRIEVED,
                    data: pillarsResponse,
                })
            })
    }

    return {
        retrievePillars,
    }
}

export { usePillarRepository }