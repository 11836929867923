import { Box, Card, CardActionArea, CardContent, Skeleton } from "@mui/material"
import clsx from "clsx"

import styles from './UpmeBenefitCard.module.scss'

const CardComparisonSkeleton = () => {
    return (
        <Box>
            <Skeleton className={styles.skeleton} variant="rectangular" />
            <Skeleton className={styles.skeleton} variant="rectangular" />
        </Box>
    )
}

const TitleSkeletonLine = () => {
    const style = clsx(
        styles.benefitTitle,
        styles.skeleton,
    )

    return <Skeleton className={style} variant="rectangular" />
}

const SummarySkeletonLine = () => {
    const style = clsx(
        styles.benefitSummary,
        styles.skeleton,
    )

    return <Skeleton className={style} variant="rectangular" />
}

const UpmeBenefitCardSkeleton = () => {
    const cardActionAreaStyle = clsx(
        styles.benefitCardArea,
        styles.skeleton,
    )

    return (
        <CardActionArea className={cardActionAreaStyle}>
            <Card className={styles.benefitCard} elevation={2}>
                <Skeleton className={styles.benefitImage} variant="rectangular" />
                <CardContent className={styles.benefitContent}>
                    <TitleSkeletonLine />
                    <TitleSkeletonLine />

                    <SummarySkeletonLine />
                    <SummarySkeletonLine />
                    <SummarySkeletonLine />

                    <Box className={styles.benefitComparison}>
                        <CardComparisonSkeleton />
                        <CardComparisonSkeleton />
                    </Box>
                </CardContent>
            </Card>
        </CardActionArea>
    )
}

export default UpmeBenefitCardSkeleton