import { MembershipCardPage } from '../../types'
import { fetchMembershipCardPage } from '../../clients/pages'
import { fromStrapiMembershipCardPage } from '../../helpers/mapping/membership-page'

class MembershipCardPageRepository {

    async getMembershipCardPage(): Promise<MembershipCardPage> {
        const { data } = await fetchMembershipCardPage()
        return fromStrapiMembershipCardPage(data)
    }
}

export default MembershipCardPageRepository