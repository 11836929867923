import { fromStrapiPillar } from '../../helpers/mapping/pillar'
import { fetchPillars } from '../../clients/pillars'
import { Pillar } from '../../types'
import { MembershipType } from '../../types/MembershipType'

class PillarRepository {

    async getAll(membershipType: MembershipType): Promise<Pillar[]> {
        const { data } = await fetchPillars(membershipType)
        return data.map((i: unknown) => fromStrapiPillar(i))
    }
}

export default PillarRepository