import { useContext } from "react"

import { UPMEContentContext } from "../../stores/UPMEContent/UPMEContentStore"
import { ServiceResponse } from "../../types"
import { UPMEActions } from "../../stores/UPMEContent/UPMEContentReducer"
import { DigitalResourceCategory } from "../../types/DigitalResourceCategory"
import { DigitalResourceDetail } from "../../types/DigitalResourceDetail"
import { MembershipType } from "../../types/MembershipType"
import { UpmeLimitedDigitalResourceDetailByCategory } from "../../types/LimitedDigitalResourceDetailByCategory"

import DigitalResourceRepository from "./digital-resource.repository"
import { starterDataError } from "../../helpers/utils/error-handling"

const useDigitalResourceRepository = () => {
    const { dispatch } = useContext(UPMEContentContext)

    const upmeDigitalResourceRepository = new DigitalResourceRepository()

    const retrieveDigitalResourceCategories = () => {
        const upmeDigitalResourceCategoryResponse: ServiceResponse<DigitalResourceCategory[]> = {}

        upmeDigitalResourceRepository.retrieveDigitalResourceCategories()
            .then(category => {
                upmeDigitalResourceCategoryResponse.data = category
            })
            .catch(error => {
                upmeDigitalResourceCategoryResponse.error = starterDataError(error)
            })
            .finally(() => {
                dispatch({
                    type: UPMEActions.UPME_DIGITAL_RESOURCE_CATEGORIES_RETRIEVED,
                    category: upmeDigitalResourceCategoryResponse,
                })
            })
    }

    const retrieveDigitalResourceByCategory = (categoryId: number, membershipType: MembershipType) => {
        const upmeDigitalResourceByCategoryResponse: ServiceResponse<DigitalResourceDetail[]> = {}

        upmeDigitalResourceRepository.retrieveDigitalResourcesByCategory(categoryId, membershipType)
            .then(category => {
                upmeDigitalResourceByCategoryResponse.data = category
            })
            .catch(error => {
                upmeDigitalResourceByCategoryResponse.error = starterDataError(error)
            })
            .finally(() => {
                dispatch({
                    type: UPMEActions.UPME_DIGITAL_RESOURCE_BY_CATEGORY_RETRIEVED,
                    digitalResourceListByCategory: upmeDigitalResourceByCategoryResponse,
                })
            })
    }

    const retrieveDigitalResourceDetails = (resourceId: number) => {
        const upmeDigitalResourceDetailsResponse: ServiceResponse<DigitalResourceDetail> = {}

        upmeDigitalResourceRepository.retrieveDigitalResourceById(resourceId)
            .then(resource => {
                upmeDigitalResourceDetailsResponse.data = resource
            })
            .catch(error => {
                upmeDigitalResourceDetailsResponse.error = starterDataError(error)
            })
            .finally(() => {
                dispatch({
                    type: UPMEActions.UPME_DIGITAL_RESOURCE_DETAILS_RETRIEVED,
                    digitalResourceDetails: upmeDigitalResourceDetailsResponse,
                })
            })
    }

    const retrieveLimitedDigitalResourcesByCategory = (membershipType: MembershipType) => {
        const upmeLimitedDigitalResourceResponse: ServiceResponse<UpmeLimitedDigitalResourceDetailByCategory[]> = {}

        upmeDigitalResourceRepository.retrieveUpmeLimitedDigitalResourcesByCategory(membershipType)
            .then(resource => {
                upmeLimitedDigitalResourceResponse.data = resource
            })
            .catch(error => {
                upmeLimitedDigitalResourceResponse.error = starterDataError(error)
            })
            .finally(() => {
                dispatch({
                    type: UPMEActions.UPME_LIMITED_DIGITAL_RESOURCES_BY_CATEGORY_RETRIEVED,
                    digitalResourceLimitedList: upmeLimitedDigitalResourceResponse,
                })
            })
    }

    return {
        retrieveDigitalResourceCategories,
        retrieveDigitalResourceByCategory,
        retrieveDigitalResourceDetails,
        retrieveLimitedDigitalResourcesByCategory,
    }
}

export { useDigitalResourceRepository }