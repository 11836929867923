import { createBrowserRouter } from 'react-router-dom'

import App from "../App"
import HomePage from "../components/pages/Home/Home"
import SignUp from "../components/pages/Membership/SignUp/SignUp"
import PassThrough from "../components/organisms/PassThrough/PassThrough"
import Join from "../components/pages/Membership/Join/Join"
import MyInfoCallback from "../components/pages/MyInfoCallback/MyInfoCallback"
import PlsCallback from "../components/pages/PlsCallback/PlsCallback"
import PlsLogin from "../components/pages/PlsLogin/PlsLogin"
import Success from "../components/pages/Membership/Success/Success"
import Events from "../components/pages/Events/Events"
import MembershipCard from "../components/pages/MembershipCard/MembershipCard"
import CuratedExperiences from "../components/pages/CuratedExperiences/CuratedExperiences"
import CuratedExperienceDetails from '../components/pages/CuratedExperienceDetails/CuratedExperienceDetails'
import EventDetails from "../components/pages/EventDetails/EventDetails"
import PrivacyPolicy from '../components/pages/PrivacyPolicy/PrivacyPolicy'
import Faq from '../components/pages/FAQ/FAQ'
import FAQDetails from '../components/pages/FAQ/FAQDetails'
import About from '../components/pages/About/About'
import ApplicationTermsOfUse from '../components/pages/ApplicationTermsOfUse/ApplicationTermsOfUse'
import PaymentSummary from '../components/pages/Membership/PaymentSummary/PaymentSummary'
import Draft from '../components/pages/Membership/Draft/Draft'
import ProfileUpdateSummary from '../components/pages/Profile/ProfileUpdateSummary/ProfileUpdateSummary'
import MyActivity from '../components/pages/Profile/MyActivity/MyActivity'
import MyActivityDetails from '../components/pages/Profile/MyActivityDetails/MyActivityDetails'
import ClaimForm from '../components/pages/Utap/Claim/ClaimForm'
import ClaimsList from '../components/pages/Utap/ClaimsList/ClaimsList'
import UtapSuccess from '../components/pages/Utap/Success/Success'
import UTAPLanding from "../components/pages/Utap/Landing/UTAPLanding"
import ProgrammeCVP from '../components/pages/Programme/ProgrammeCVP'
import DefaultLandingPage from "../components/pages/DefaultLandingPage/DefaultLandingPage"
import SplashScreen from '../components/pages/SplashScreen/SplashScreen'
import UpmeConversionIntroPage from '../components/pages/UpmeConversion/UpmeConversionIntroduction/UpmeConversionIntroduction'
import UpmeBenefitsList from '../components/pages/UpmeConversion/UpmeBenefitsList/UpmeBenefitsList'
import UpmeBenefitDetails from '../components/pages/UpmeConversion/UpmeBenefitDetails/UpmeBenefitDetails'
import UpmeWelcomePage from '../components/pages/UpmeConversion/UpmeWelcomePage/UpmeWelcomePage'
import DigitalResourceListByCategory from '../components/pages/DigitalResource/DigitalResourceListByCategory/DigitalResourceListByCategory'
import DigitalResourceDetails from '../components/pages/DigitalResource/DigitalResourceDetails/DigitalResourceDetails'
import Deals from '../components/pages/Deals/Deals'
import DealDetails from '../components/pages/DealDetails/DealDetails'
import UserInterestsSelection from '../components/pages/Membership/UserInterestsSelection/UserInterestsSelection'
import StarterOnboarding from '../components/pages/Membership/StarterOnboarding/StarterOnboarding'
import ProfilePictureSelection from '../components/pages/Profile/ProfilePictureSelection/ProfilePictureSelection'
import PartnerOrPlanLandingPage from '../components/pages/PartnerOrPlanLandingPage/PartnerOrPlanLandingPage'
import ProgrammeApplicationForm from '../components/pages/ProgrammeApplicationForm/ProgrammeApplicationForm/ProgrammeApplicationForm'
import ProgrammeApplicationFormTermsAndConditions from '../components/pages/ProgrammeApplicationForm/ProgrammeApplicationFormTermsAndConditions/ProgrammeApplicationFormTermsAndConditions'
import ContactUs from '../components/pages/ContactUs/ContactUs'

const STRAPI_URL = process.env.REACT_APP_STRAPI_URL

enum Route {
    ROOT = `/`,
    LANDING_PAGE = `/landing-page`,
    HOME = `/home`,
    PROFILE = `/profile`,
    PROFILE_MY_ACTIVITY = `/profile/my-activity`,
    PROFILE_MY_ACTIVITY_DETAILS = `/profile/my-activity/:id`,
    PRIVACY_POLICY = `/privacy-policy`,
    FAQ = `/faq`,
    FAQ_FOR_TAG = `/faq/tag`,
    FAQ_FOR_PILLAR = `/faq/pillar`,
    FAQ_DETAILS = `/faq/details`,
    ABOUT = `/about`,
    APPLICATION_TERMS_OF_USE = `/application-terms-of-use`,
    DEALS_LIST = `/deals`,
    DEAL_DETAILS = `/deals/:id`,
    MEMBERSHIP = `/membership`,
    MEMBERSHIP_JOIN = `/membership/join`,
    MEMBERSHIP_PARTNER = `/membership/partner`,
    MEMBERSHIP_CARD = `/membership/card`,
    MEMBERSHIP_SIGN_UP = `/membership/sign-up`,
    MEMBERSHIP_DRAFT = `/membership/draft`,
    MEMBERSHIP_PAYMENT_SUMMARY = `/membership/payment-summary/:reason`,
    MEMBERSHIP_SUCCESS = `/membership/success`,
    MEMBERSHIP_STARTER_ONBOARDING = `/membership/starter-onboarding`,
    MEMBERSHIP_USER_INTERESTS = `/membership/user-interests`,
    MYINFO_CALLBACK = `/myinfo/callback`,
    MYINFO_AUTHORISE = `/api/pls/myinfo`,
    PILLARS = `/pillars`,
    PROGRAMME_DETAILS = `/programme/:programme`,
    PLS = `/pls`,
    PLS_LOGIN = `/pls/login`,
    PLS_CALLBACK = `/pls/callback`,
    PILLARS_EVENT = `/pillars/career/events`,
    PILLARS_CURATED_EXPERIENCE = `/pillars/curated-experience/experiences`,
    PILLARS_CURATED_EXPERIENCE_DETAILS = `/pillars/curated-experience/experiences/:id`,
    PILLARS_EVENT_DETAILS = `/pillars/career/events/:id`,
    PROFILE_SUMMARY = `/profile/summary`,
    PROFILE_UPDATE = `/profile/update`,
    PROFILE_PICTURE_SELECTION = `/profile/picture-selection`,
    PROGRAMME_APPLICATION = `/programme-application`,
    PROGRAMME_APPLICATION_TERMS_AND_CONDITIONS = `/programme-application/terms-and-conditions`,
    UTAP_LANDING = `/utap`,
    UTAP_CLAIM_FORM = `/utap/claim`,
    UTAP_CLAIM_SUCCESS = `/utap/success/:id`,
    UTAP_CLAIMS = `/utap/claims`,
    UPME = `/upme`,
    UPME_CONVERSION_INTRO = `/upme/introduction`,
    UPME_CONVERSION_BENEFITS = `/upme/benefits`,
    UPME_CONVERSION_BENEFIT_DETAILS = `/upme/benefits/:id`,
    UPME_WELCOME_PAGE = `/upme/welcome`,
    UPME_DIGITAL_RESOURCE_CATEGORY = `/upme/digital-resource/:category`,
    UPME_DIGITAL_RESOURCE = `/upme/digital-resource/:category/:id`,
    CONTACT_US = `/contact-us`,
}

const FAQ_FOR_TAG_WITH_PARAMS = `${Route.FAQ_FOR_TAG}/:tagId`
const FAQ_FOR_PILLAR_WITH_PARAMS = `${Route.FAQ_FOR_PILLAR}/:pillarId`
const FAQ_DETAILS_WITH_PARAMS = `${Route.FAQ_DETAILS}/:id`
const MEMBERSHIP_JOIN_ROUTE_WITH_PARAMS = `${Route.MEMBERSHIP_JOIN}/:plan?/:referralCode?`
const MEMBERSHIP_PARTNER_WITH_PARAMS = `/membership/partner/:partnerCode`
const PROGRAMME_APPLICATION_WITH_PARAMS = `${Route.PROGRAMME_APPLICATION}/:programmeApplicationFormId`

const routes = [
    {
        path: Route.ROOT,
        element: <App />,
        children: [
            {
                path: Route.ROOT,
                element: <SplashScreen />,
            },
            {
                path: Route.HOME,
                element: <HomePage />,
            },
            {
                path: Route.LANDING_PAGE,
                element: <DefaultLandingPage />,
            },
            {
                path: Route.PROFILE,
                element: <PassThrough />,
                children: [
                    {
                        path: Route.PROFILE_MY_ACTIVITY,
                        element: <MyActivity />,
                    },
                    {
                        path: Route.PROFILE_MY_ACTIVITY_DETAILS,
                        element: <MyActivityDetails />,
                    },
                    {
                        path: Route.PROFILE_SUMMARY,
                        element: <ProfileUpdateSummary />,
                    },
                    {
                        path: Route.PROFILE_UPDATE,
                        element: <SignUp />,
                    },
                    {
                        path: Route.PROFILE_PICTURE_SELECTION,
                        element: <ProfilePictureSelection />,
                    },
                ],
            },
            {
                path: Route.PRIVACY_POLICY,
                element: <PrivacyPolicy />,
            },
            {
                path: Route.CONTACT_US,
                element: <ContactUs />,
            },
            {
                path: Route.FAQ,
                element: <PassThrough />,
                children: [
                    {
                        path: Route.FAQ,
                        element: <Faq />,
                    },
                    {
                        path: FAQ_FOR_TAG_WITH_PARAMS,
                        element: <Faq />,
                    },
                    {
                        path: FAQ_FOR_PILLAR_WITH_PARAMS,
                        element: <Faq />,
                    },
                    {
                        path: FAQ_DETAILS_WITH_PARAMS,
                        element: <FAQDetails />,
                    },
                ],
            },
            {
                path: Route.ABOUT,
                element: <About />,
            },
            {
                path: Route.APPLICATION_TERMS_OF_USE,
                element: <ApplicationTermsOfUse />,
            },
            {
                path: Route.MEMBERSHIP,
                element: <PassThrough />,
                children: [
                    {
                        path: Route.MEMBERSHIP_JOIN,
                        element: <Join />,
                    },
                    {
                        path: MEMBERSHIP_JOIN_ROUTE_WITH_PARAMS,
                        element: <PartnerOrPlanLandingPage />,
                    },
                    {
                        path: Route.MEMBERSHIP_CARD,
                        element: <MembershipCard />,
                    },
                    {
                        path: MEMBERSHIP_PARTNER_WITH_PARAMS,
                        element: <PartnerOrPlanLandingPage />,
                    },
                    {
                        path: Route.MEMBERSHIP_SIGN_UP,
                        element: <SignUp />,
                    },
                    {
                        path: Route.MEMBERSHIP_DRAFT,
                        element: <Draft />,
                    },
                    {
                        path: Route.MEMBERSHIP_PAYMENT_SUMMARY,
                        element: <PaymentSummary />,
                    },
                    {
                        path: Route.MEMBERSHIP_SUCCESS,
                        element: <Success />,
                    },
                    {
                        path: Route.MEMBERSHIP_USER_INTERESTS,
                        element: <UserInterestsSelection />,
                    },
                    {
                        path: Route.MEMBERSHIP_STARTER_ONBOARDING,
                        element: <StarterOnboarding />,
                    },
                ],
            },
            {
                path: Route.MYINFO_CALLBACK,
                element: <MyInfoCallback />,
            },
            {
                path: Route.PROGRAMME_DETAILS,
                element: <ProgrammeCVP />,
            },
            {
                path: Route.PILLARS,
                element: <PassThrough />,
                children: [
                    {
                        path: Route.PILLARS_EVENT,
                        element: <PassThrough />,
                        children: [
                            {
                                path: Route.PILLARS_EVENT,
                                element: <Events />,
                            },
                            {
                                path: Route.PILLARS_EVENT_DETAILS,
                                element: <EventDetails />,
                            },
                        ],
                    },
                    {
                        path: Route.PILLARS_CURATED_EXPERIENCE,
                        element: <PassThrough />,
                        children: [
                            {
                                path: Route.PILLARS_CURATED_EXPERIENCE,
                                element: <CuratedExperiences />,
                            },
                            {
                                path: Route.PILLARS_CURATED_EXPERIENCE_DETAILS,
                                element: <CuratedExperienceDetails />,
                            },
                        ],
                    },
                ],
            },
            {
                path: Route.PLS,
                element: <PassThrough />,
                children: [
                    {
                        path: Route.PLS_LOGIN,
                        element: <PlsLogin />,
                    },
                    {
                        path: Route.PLS_CALLBACK,
                        element: <PlsCallback />,
                    },
                ],
            },
            {
                path: Route.UTAP_LANDING,
                element: <PassThrough />,
                children: [
                    {
                        path: Route.UTAP_LANDING,
                        element: <UTAPLanding />,
                    },
                    {
                        path: Route.UTAP_CLAIM_FORM,
                        element: <ClaimForm />,
                    },
                    {
                        path: Route.UTAP_CLAIM_SUCCESS,
                        element: <UtapSuccess />,
                    },
                    {
                        path: Route.UTAP_CLAIMS,
                        element: <ClaimsList />,
                    },
                ],
            },
            {
                path: Route.UPME,
                element: <PassThrough />,
                children: [
                    {
                        path: Route.UPME_CONVERSION_INTRO,
                        element: <UpmeConversionIntroPage />,
                    },
                    {
                        path: Route.UPME_CONVERSION_BENEFITS,
                        element: <UpmeBenefitsList />,
                    },
                    {
                        path: Route.UPME_CONVERSION_BENEFIT_DETAILS,
                        element: <UpmeBenefitDetails />,
                    },
                    {
                        path: Route.UPME_WELCOME_PAGE,
                        element: <UpmeWelcomePage />,
                    },
                    {
                        path: Route.UPME_DIGITAL_RESOURCE_CATEGORY,
                        element: <DigitalResourceListByCategory />,
                    },
                    {
                        path: Route.UPME_DIGITAL_RESOURCE,
                        element: <DigitalResourceDetails />,
                    },
                ],
            },
            {
                path: Route.DEALS_LIST,
                element: <Deals />,
            },
            {
                path: Route.DEAL_DETAILS,
                element: <DealDetails />,
            },
            {
                path: Route.PROGRAMME_APPLICATION,
                element: <PassThrough />,
                children: [
                    {
                        path: PROGRAMME_APPLICATION_WITH_PARAMS,
                        element: <ProgrammeApplicationForm />,
                    },
                    {
                        path: Route.PROGRAMME_APPLICATION_TERMS_AND_CONDITIONS,
                        element: <ProgrammeApplicationFormTermsAndConditions />,
                    },
                ],
            },
        ],
    },
]

const router = createBrowserRouter(routes)

const routeFromPathName = (pathName: string): Route => {
    const routeEntry = Object.entries(Route).find(entry => entry[1] === pathName)

    if (routeEntry) {
        return routeEntry[0] as Route
    }

    return Route.ROOT
}

const pathMatchesPaymentSummaryRoutePattern = (path: string): boolean => {
    return path.startsWith(`/membership/payment-summary`)
}

const pathMatchesMembershipPlanRoutePattern = (path: string): boolean => {
    const membershipPlanRoutePattern = `^${Route.MEMBERSHIP_JOIN}(?:/([^/]+)(?:/([^/]+))?)?$`
    return new RegExp(membershipPlanRoutePattern).test(path)
}

const pathMatchesPartnerRoutePattern = (path: string): boolean => {
    const partnerRoutePattern = `^${Route.MEMBERSHIP_PARTNER}/([^/]+)$`
    return new RegExp(partnerRoutePattern).test(path)
}

const pathMatchesProfileUpdateRoutePattern = (path: string): boolean => {
    return path === `${Route.PROFILE_UPDATE}`
}

export {
    router,
    Route,
    STRAPI_URL,
    routeFromPathName,
    pathMatchesPaymentSummaryRoutePattern,
    pathMatchesMembershipPlanRoutePattern,
    pathMatchesPartnerRoutePattern,
    pathMatchesProfileUpdateRoutePattern,
}