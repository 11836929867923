import { useContext, useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { AppContext } from '../../../stores/App/AppStore'
import { EventDetail } from '../../../types'
import EventCard from '../../molecules/EventCard/EventCard'
import HorizontalScrollComponent from '../../molecules/HorizontalScrollComponent/HorizontalScrollComponent'
import { useMembershipRepository } from '../../../repositories/Membership/use-membership-repository'
import { useEventRepository } from '../../../repositories/EventPage/use-event-page-repository'
import { UserContext } from '../../../stores/User/UserStore'
import { MembershipType } from '../../../types/MembershipType'
import useGoogleTagManager from '../../../helpers/analytics/use-google-tag-manager'
import GtmEventName from '../../../helpers/analytics/GtmEventName'

import styles from './MyEventsHomeList.module.scss'

const DEFAULT_ITEMS_SIZE = 3

interface MyEventsHomeListProps {
    membershipType?: MembershipType
}

const MyEventsHomeList = (props: MyEventsHomeListProps) => {
    const { membershipType } = props
    const { t } = useTranslation()
    const { state: appState } = useContext(AppContext)
    const { state: userState } = useContext(UserContext)
    const { retrieveMembership } = useMembershipRepository()
    const { retrieveEvents, retrieveEventsPublic } = useEventRepository()
    const { trackCardClick } = useGoogleTagManager()

    const events = appState.events?.data?.filter((event) => event.registered)

    useEffect(() => {
        retrieveMembership()
    }, [])

    useEffect(() => {
        if (userState.membership?.data) {
            retrieveEvents(userState.membership?.data.type)
        } else if (membershipType) {
            retrieveEventsPublic(membershipType)
        }
    }, [userState.membership, membershipType])

    const handleMyEventCardClick = (event: EventDetail) => {
        trackCardClick(GtmEventName.REGISTERED_EVENT_CLICK, event.title)
    }

    const renderEventCard = (event: EventDetail) => <EventCard event={event} onEventClick={handleMyEventCardClick} />

    return (
        <Box>
            <Typography className={styles.title} variant='h3' color='primary'>{t(`my_events`)}</Typography>
            <HorizontalScrollComponent items={events} noItemsMessage={t(`my_events_no_events`)} renderItem={renderEventCard} maxNumOfItems={DEFAULT_ITEMS_SIZE} isLandingPage />
        </Box>
    )
}

export default MyEventsHomeList