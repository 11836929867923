import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import LoadingPageContent from '../../organisms/LoadingPageContent/LoadingPageContent'

const PlsLogin = () => {
    const { t } = useTranslation()

    useEffect(() => {
        const authUrl = `${process.env.REACT_APP_STRAPI_URL}/api/pls/singpass`

        window.location.replace(authUrl)
    }, [])

    return (
        <LoadingPageContent title={t(`linkpass_retrieving_title`)} message={t(`linkpass_retrieving_message`)} />
    )
}

export default PlsLogin