import { useContext } from 'react'

import { AppContext } from '../../stores/App/AppStore'
import { ActionTypes } from '../../stores/App/AppReducer'
import { MembershipType } from '../../types/MembershipType'
import { ServiceResponse } from "../../types"
import { Deal } from "../../types/Deal"
import DealRepository from './deal-repository'
import { starterDataError } from '../../helpers/utils/error-handling'

const useDealRepository = () => {
    const { dispatch } = useContext(AppContext)
    const dealRepository = new DealRepository()

    const retrieveDeals = (membershipType?: MembershipType, pageSize?: number) => {
        if (!membershipType) {
            return
        }

        const dealsResponse: ServiceResponse<Deal[]> = {}

        dealRepository.retrieveAllDeals(membershipType, pageSize)
            .then((deals) => {
                dealsResponse.data = deals
                return dealsResponse
            })
            .catch((error) => {
                dealsResponse.error = starterDataError(error)
            })
            .finally(() => {
                dispatch({
                    type: ActionTypes.DEALS_RETRIEVED,
                    deals: dealsResponse,
                })
            })
    }

    const retrieveDeal = (id: number) => {
        const dealByIdResponse: ServiceResponse<Deal> = {}

        dealRepository.retrieveDealById(id)
            .then((deal) => {
                dealByIdResponse.data = deal
                return dealByIdResponse
            })
            .catch((error) => {
                dealByIdResponse.error = starterDataError(error)
            })
            .finally(() => {
                dispatch({
                    type: ActionTypes.DEAL_RETRIEVED,
                    deal: dealByIdResponse,
                })
            })
    }

    return {
        retrieveDeals,
        retrieveDeal,
    }

}

export default useDealRepository