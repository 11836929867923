import { Alignment, Fit, Layout, useRive, useStateMachineInput } from '@rive-app/react-canvas'
import { useEffect } from 'react'

const SPLASH_ANIMATION_PATH = `/ntuc_splash_screen.riv`
const END_ANIMATION_DURATION = 1000

interface SplashScreenAnimationProps {
    isLoadingDone: boolean,
    loadingDoneCallback: () => void,
}

const SplashScreenAnimation = (props: SplashScreenAnimationProps) => {
    const { isLoadingDone, loadingDoneCallback } = props

    const { rive, RiveComponent } = useRive({
        src: SPLASH_ANIMATION_PATH,
        stateMachines: `Loading`,
        autoplay: true,
        layout: new Layout({
            fit: Fit.Cover,
            alignment: Alignment.TopCenter,
        }),
    })

    const loadingFinishedTrigger = useStateMachineInput(rive, `Loading`, `loadingFinished`)

    useEffect(() => {
        if (isLoadingDone) {
            loadingFinishedTrigger?.fire()
            setTimeout(() => {
                loadingDoneCallback()
            }, END_ANIMATION_DURATION)
        }
    }, [isLoadingDone])

    return <RiveComponent />

}

export default SplashScreenAnimation
