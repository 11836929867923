import { Box, Container, Skeleton } from "@mui/material"
import CommonBackButton from "../../atoms/CommonBackButton/CommonBackButton"
import MarkdownContent from "../../atoms/MarkdownContent/MarkdownContent"
import { Route } from "../../../router"

import styles from './FAQ.module.css'

const FAQDetailsSkeleton = () => {
    return (
        <Container className={styles.container}>
            <Box className={styles.skeletonBackButtonBox}>
                <CommonBackButton path={Route.FAQ} />
            </Box>
            <Box className={styles.skeleton}>
                <Skeleton variant="text" className={styles.skeletonTitle}/>
                <Skeleton variant="text" className={styles.skeletonTitle}/>
            </Box>
            <MarkdownContent />
            <Box >
                <Skeleton variant="rectangular" className={styles.skeletonImage}/>
            </Box>
        </Container>
    )
}

export default FAQDetailsSkeleton