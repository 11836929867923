import { MutableRefObject } from "react"
import { Grid, Typography } from "@mui/material"
import moment from "moment"
import clsx from "clsx"

import { UtapClaimItem } from "../../../types/UtapClaim"
import { UtapClaimStatus } from "../../../types/UtapClaimStatus"

import styles from './ClaimCard.module.css'

interface ClaimCardProps {
    utapClaim: UtapClaimItem
    reference?: MutableRefObject<null>
}

const ClaimCard = (props: ClaimCardProps) => {
    const {utapClaim, reference} = props

    const dateStringFormatter = (dateString: string): string => {
        const parsedDate = moment(dateString, `DD-MM-YYYY`)
        const formattedDate = parsedDate.format(`DD MMM YYYY`)

        return formattedDate
    }

    const toTitleCase = (text: string) => {
        return text
            .split(` `)
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(` `)
    }

    const claimReferenceNumber = utapClaim.referenceNumber
    const claimSubmissionDate = dateStringFormatter(utapClaim.submittedOn)
    const claimSubmissionResult = toTitleCase(utapClaim.status)
    const claimCourseName = utapClaim.courseName

    let claimStatusColor: string
    switch (utapClaim.status) {
        case UtapClaimStatus.REJECTED:
            claimStatusColor = `error`
            break
        case UtapClaimStatus.REVIEWING:
            claimStatusColor = `orange`
            break
        case UtapClaimStatus.APPROVED:
            claimStatusColor = `green`
            break
        default:
            claimStatusColor = `default`
            break
    }

    return (
        <Grid className={styles.cardContainer} ref={reference}>
            <Grid className={clsx(styles.cardContainerColumn, styles.alignCenter)}>
                <Typography variant={`subtitle1`}>{claimReferenceNumber}</Typography>
                <Typography variant={`body2`}>{}</Typography>
            </Grid>
            <Grid className={clsx(styles.cardContainerColumn, styles.alignEnd)}>
                <Typography variant={`body1`}>{claimSubmissionDate}</Typography>
                <Typography variant={`body1`} color={claimStatusColor}>{claimSubmissionResult}</Typography>
            </Grid>
            <Grid className={clsx(styles.cardContainerColumn, styles.alignEnd)}>
                <Typography variant={`body1`}>{claimCourseName}</Typography>
                <Typography variant={`body2`}>{}</Typography>
            </Grid>
        </Grid>
    )
}

export default ClaimCard