import { CuratedExperienceDetail, CuratedExperiencePage } from '../../types'
import { absoluteImageUrl } from '../utils/environment'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const fromStrapiCuratedExperience = (strapiData: any): CuratedExperienceDetail => {
    const { id, attributes } = strapiData
    const { title, shortDescription, startDate, endDate, description, promoCode, externalLink, order } = attributes
    const headerImage = absoluteImageUrl(attributes.headerImage.data.attributes.url)

    const item: CuratedExperienceDetail = {
        id,
        headerImage,
        title,
        shortDescription,
        description,
        promoCode,
        externalLink,
        order,
    }

    if (startDate) {
        item.startDate = new Date(startDate)
    }

    if (endDate) {
        item.endDate = new Date(endDate)
    }

    return item
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const fromStrapiCuratedExperiencePage = (strapiData: any): CuratedExperiencePage => {
    const { attributes: { seeMoreURL } } = strapiData

    return { seeMoreURL }
}

export {
    fromStrapiCuratedExperience,
    fromStrapiCuratedExperiencePage,
}