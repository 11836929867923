import { ServiceResponse } from "./RemoteData"

export interface MyInfoUserDataContent {
    uinFin: string
    name: string
    dob: string
    gender: string
    email: string
    phoneNumber: string
    residentialStatus: string
    postalCode: string
    block?: string
    street?: string
    floor?: string
    unit?: string
    building?: string
}

export interface MyInfoUserData {
    contentHash?: string
    identifierHash?: string
    content?: MyInfoUserDataContent,
    jwt?: string,
    userBecameUnionMember?: boolean,
}

export type MyInfoUserDataResponse = ServiceResponse<MyInfoUserData>

export enum MyInfoReason {
    SIGNUP = `SIGNUP`,
    PROFILE_UPDATE = `PROFILE_UPDATE`,
    UTAP = `UTAP_CLAIM`,
    UTAP_CLAIM_LIST = `UTAP_CLAIM_LIST`
}
