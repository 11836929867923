import { Button, Grid, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { EventRegistrationForm } from "../../molecules/EventRegistrationForm/EventRegistrationForm"
import { EventDetail } from "../../../types/EventDetail"
import { QuestionResponses } from "../../../types/QuestionResponses"
import { MembershipStatus } from "../../../types/MembershipStatus"
import MarkdownContent from "../../atoms/MarkdownContent/MarkdownContent"
import { isRegistrationOpen } from "../../../helpers/utils/event-formatter"

import styles from "./EventDetails.module.css"
interface EventDetailsStateProps {
    eventDetails: EventDetail;
    hasRegistered?: boolean;
    registrationSuccess: boolean;
    showRegistrationForm: boolean;
    loading: boolean;
    membershipStatus?: MembershipStatus;
    isAuthenticated: () => boolean;
    handleRegistration: (responses: QuestionResponses) => void;
    handleLogin: () => void;
    handleSignUp: () => void;
}

export const EventDetailsState = (props: EventDetailsStateProps) => {
    const { t } = useTranslation()
    const {
        eventDetails,
        hasRegistered,
        registrationSuccess,
        showRegistrationForm,
        loading,
        membershipStatus,
        handleRegistration,
        handleLogin,
        isAuthenticated,
        handleSignUp,
    } = props

    const isTerminated = membershipStatus === MembershipStatus.TERMINATED
    const handleRegistrationClick = isAuthenticated()
        ? handleRegistration
        : handleLogin
    const isRegistrationFormShown = isRegistrationOpen(
        eventDetails.registrationDeadline,
    )

    const showInstructions = eventDetails.instructions ? (
        <Grid
            container
            direction="column"
            spacing={2}
            justifyContent="center"
            alignItems="center"
        >
            <Grid item>
                <Typography variant="subtitle1" align="center">
                    {t(`event_registration_instruction`)}
                </Typography>
            </Grid>
            <Grid item>
                <MarkdownContent content={eventDetails.instructions} />
            </Grid>
        </Grid>
    ) : (
        <Typography variant="subtitle1" className={styles.centered}>
            {t(`event_registration_success`)}
        </Typography>
    )

    const terminatedDisplay = (
        <>
            <Grid item mt={2}>
                <Typography variant="subtitle1" className={styles.centered}>
                    {t(`event_registration_terminated_member_message`)}
                </Typography>
            </Grid>
            <Grid item mt={2} mb={2} className={styles.centered}>
                <Button variant="contained" className={styles.comeBackButton} onClick={handleSignUp}>{t(`home_page_come_back`)}</Button>
            </Grid>
        </>
    )

    if (!hasRegistered && isTerminated) {
        return terminatedDisplay
    } else if (hasRegistered) {
        if (isTerminated) {
            return (
                <>
                    <Typography variant="subtitle1" className={styles.centered}>
                        {showInstructions}
                    </Typography>
                    {terminatedDisplay}
                </>
            )
        } else {
            return (
                <Typography variant="subtitle1" className={styles.centered}>
                    {showInstructions}
                </Typography>
            )
        }
    } else if (registrationSuccess && !showRegistrationForm) {
        return (
            <Grid mt={2} mb={2} className={styles.centered} item>
                <Typography variant="subtitle1">
                    {t(`event_registration_success`)}
                </Typography>
            </Grid>
        )
    } else if (eventDetails.openToRegistration) {
        if (isRegistrationFormShown) {
            return (
                <Grid item>
                    <EventRegistrationForm
                        handleRegistration={handleRegistrationClick}
                        questionnaireList={eventDetails.questionnaire}
                        isLoading={loading}
                    />
                </Grid>
            )
        } else {
            return (
                <Grid mt={2} item>
                    <Typography className={styles.centered} variant="subtitle1">
                        {t(`event_registration_closed`)}
                    </Typography>
                </Grid>
            )
        }
    } else {
        return null
    }
}
