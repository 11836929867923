import { useNavigate, useParams } from "react-router-dom"
import { Container, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useContext, useEffect, useState } from "react"

import { useUpmeOnboardingRepository } from "../../../../repositories/UPMEOnboarding/use-upme-onboarding-repository"
import { UPMEContentContext } from "../../../../stores/UPMEContent/UPMEContentStore"
import UpmeBenefitCard from "../../../molecules/UpmeBenefitCard/UpmeBenefitCard"
import CommonBackButton from "../../../atoms/CommonBackButton/CommonBackButton"
import MarkdownContent from "../../../atoms/MarkdownContent/MarkdownContent"
import UpmeBenefitDetailsSkeleton from "./UpmeBenefitDetails.skeleton"
import UpmeUpdateNowFooter from "../../../molecules/UpmeUpdateNowFooter/UpmeUpdateNowFooter"
import SplashScreenGuard from "../../../../router/guards/SplashScreenGuard"
import { Route } from "../../../../router"
import useTrackPageView from "../../../../helpers/hooks/use-track-page-view"

import styles from "./UpmeBenefitDetails.module.scss"
import { UpmeBenefit } from "../../../../types/UpmeBenefit"

const UpmeBenefitDetails = () => {
    const { id: benefitId } = useParams()

    const { t } = useTranslation()
    const navigate = useNavigate()

    const { retrieveUpmeBenefit } = useUpmeOnboardingRepository()

    const { state: upmeContentState } = useContext(UPMEContentContext)

    const [benefit, setBenefit] = useState<UpmeBenefit>()

    useEffect(() => {
        if (benefitId && parseInt(benefitId)) {
            const foundBenefit = upmeContentState.benefits?.data?.find(benefit => benefit.id === parseInt(benefitId))
            setBenefit(foundBenefit)
        }
    }, [upmeContentState.benefits])

    useTrackPageView(() => {
        if (benefit) {
            return {
                title: `NTUC Benefits Details`,
                item: benefit.title,
            }
        }
    }, [benefit])

    useEffect(() => {
        if (benefitId && parseInt(benefitId)) {
            retrieveUpmeBenefit(parseInt(benefitId))
        } else {
            navigate(Route.ROOT)
        }
        window.scrollTo(0, 0)
    }, [])

    if (!benefit) {
        return (
            <SplashScreenGuard>
                <UpmeBenefitDetailsSkeleton />
            </SplashScreenGuard>
        )
    }

    return (
        <SplashScreenGuard>
            <Container className={styles.container}>
                <CommonBackButton path={Route.UPME_CONVERSION_BENEFITS} />

                <UpmeBenefitCard className={styles.benefitCard} benefit={benefit} disabled />

                <Typography className={styles.moreInfoTitle} variant="h5">{t(`more_info`)}</Typography>

                <MarkdownContent content={benefit?.description} />

                <UpmeUpdateNowFooter />
            </Container>
        </SplashScreenGuard>
    )
}

export default UpmeBenefitDetails