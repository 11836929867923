import { Skeleton, ListItem, ListItemText } from "@mui/material"
import styles from "./MenuEntry.module.css"

interface MenuEntryProps {
    entryName: string
}

const MenuEntry = (props: MenuEntryProps) => {
    const { entryName } = props

    if(!entryName) {
        return (
            <Skeleton variant="text" sx={{ fontSize: `1rem` }} width='40%' />
        )
    }

    return (
        <ListItem className={styles.listItem}>
            <ListItemText primary={`${entryName}`} />
        </ListItem>
    )
}

export default MenuEntry