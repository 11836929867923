import { useContext } from 'react'

import { UserContext } from '../../stores/User/UserStore'
import { UserActionTypes } from '../../stores/User/UserReducer'
import { PartnerResponse } from '../../types/Partner'
import PartnerRepository from './partner.repository'
import LocalStorageKeys from '../../types/LocalStorageKeys'

const usePartnerRepository = () => {
    const { dispatch } = useContext(UserContext)

    const partnerRepository = new PartnerRepository()

    const savePartnerCode = (partnerCode?: string) => {
        const partnerResponse: PartnerResponse = {}

        partnerRepository.setPartnerCode(partnerCode)
            .then(partner => {
                partnerResponse.data = partner
            })
            .catch(error => {
                partnerResponse.error = {
                    errorMessage: error.message,
                    errorCode: error.response?.status,
                }
            })
            .finally(() => {
                dispatchPartner(partnerResponse)
            })
    }

    const retrievePartner = (partnerCode: string) => {
        savePartnerCode(partnerCode)
    }

    const resetPartner = () => {
        partnerRepository.setPartnerCode()
    }

    const dispatchPartner = (partner: PartnerResponse | undefined) => {
        dispatch({
            type: UserActionTypes.PARTNER_RETRIEVED,
            partner,
        })
    }

    const hasVisitedPartnerPage = (): boolean => {
        return !!localStorage.getItem(LocalStorageKeys.VISITED_PARTNER_PAGE)
    }

    const setVisitedPartnerPage = () => {
        localStorage.setItem(LocalStorageKeys.VISITED_PARTNER_PAGE, `true`)
    }

    const clearVisitedPartnerPage = () => {
        localStorage.removeItem(LocalStorageKeys.VISITED_PARTNER_PAGE)
    }

    return {
        savePartner: savePartnerCode,
        retrievePartner,
        resetPartner,
        hasVisitedPartnerPage,
        setVisitedPartnerPage,
        clearVisitedPartnerPage,
    }
}

export { usePartnerRepository }