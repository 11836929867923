import { QuestionProps } from "../../types/QuestionComponent"

interface FormKeys {
    [key: string]: boolean
}

const DIGIT = 10

const appendLeadingZero = (number: number) => (number < DIGIT ? `0${number}` : number)

const eventAddressFormatter = (
    blkHouseNumber = ``,
    streetName = ``,
    floorNumber = 0,
    unitNumber = 0,
    postalCode = ``,
    buildingName = ``,
) => {
    const blkHouseNum = blkHouseNumber ? `${blkHouseNumber} ` : ``
    const formattedFloorNumber = floorNumber ? appendLeadingZero(floorNumber) : ``
    const formattedUnitNumber = unitNumber ? appendLeadingZero(unitNumber) : `00`
    const formattedFloorUnit = formattedFloorNumber && formattedUnitNumber ? `#${formattedFloorNumber}-${formattedUnitNumber}` : ``
    const formattedStreet = streetName ?? ``
    const formattedBuildingName = buildingName ?? ``

    const addressLine = blkHouseNum + formattedStreet + ` ` + formattedBuildingName + ` ` + formattedFloorUnit
    const postal = postalCode ? `Singapore ${postalCode}` : ``

    return `${addressLine}\n${postal}`
}

const registrationFormRequiredState = (questionnaire: QuestionProps[]) => {
    const questionKeys: FormKeys = {}

    questionnaire.forEach(question => {
        const questionType = question.__component.split(`.`)[1]

        if (questionType) {
            const key = `${questionType}_${question.id}`
            questionKeys[key] = question.mandatoryField
        }
    })

    return questionKeys
}

const isRegistrationOpen = (registrationDeadline: Date) => {
    const deadline = new Date(registrationDeadline)
    const currentDate = new Date()
    const deadlineDateString = deadline.toISOString().split(`T`)[0]
    const currentDateString = currentDate.toISOString().split(`T`)[0]
    return deadlineDateString >= currentDateString
}

export {
    isRegistrationOpen,
    eventAddressFormatter,
    registrationFormRequiredState,
}