import { useContext, useEffect, useState } from 'react'
import { Button, Container, Grid, Typography } from "@mui/material"
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { useSessionRepository } from '../../../../repositories/Session/use-session-repository'
import { useUtapRepository } from '../../../../repositories/Utap/use-utap-repository'
import { useMembershipRepository } from '../../../../repositories/Membership/use-membership-repository'
import { Route, STRAPI_URL } from '../../../../router'
import { LocalStorageKeys } from '../../../../types/LocalStorageKeys'
import { MyInfoReason } from '../../../../types/MyInfoUserData'
import { AppContext } from '../../../../stores/App/AppStore'
import { UserContext } from '../../../../stores/User/UserStore'
import CommonBackButton from '../../../atoms/CommonBackButton/CommonBackButton'
import MarkdownContent from '../../../atoms/MarkdownContent/MarkdownContent'
import Spacer from '../../../atoms/Spacer/Spacer'
import SplashScreenGuard from '../../../../router/guards/SplashScreenGuard'
import useTrackPageView from "../../../../helpers/hooks/use-track-page-view"
import useGoogleTagManager from '../../../../helpers/analytics/use-google-tag-manager'
import GtmEventName from '../../../../helpers/analytics/GtmEventName'

import styles from './UTAPLanding.module.css'

enum Status {
    LOGGED_OUT = `LOGGED_OUT`,
    LOGGED_IN = `LOGGED_IN`,
    MEMBER = `MEMBER`,
}

const UTAPLanding = () => {
    useTrackPageView(`UTAP Landing Page`)

    const { t } = useTranslation()
    const navigate = useNavigate()
    const { trackButtonClick } = useGoogleTagManager()

    const { state } = useContext(AppContext)
    const { state: userState } = useContext(UserContext)

    const { retrieveUtapLandingPage } = useUtapRepository()
    const { isMember, isAuthenticated } = useSessionRepository()
    const { retrieveMembership } = useMembershipRepository()

    const [status, setStatus] = useState<Status>(Status.LOGGED_OUT)

    const hasNRIC = userState?.membership?.data?.nric

    useEffect(() => {
        retrieveUtapLandingPage()
    }, [])

    useEffect(() => {
        isAuthenticated() ? isMember().then((value) => value ? setStatus(Status.MEMBER) : setStatus(Status.LOGGED_IN)) : setStatus(Status.LOGGED_OUT)
    }, [])

    useEffect(() => {
        retrieveMembership()
    }, [])

    const handleClaimButton = () => {
        switch (status) {
            case Status.LOGGED_IN:
                navigate(Route.MEMBERSHIP_JOIN)
                break
            case Status.LOGGED_OUT:
                navigate(Route.PLS_LOGIN)
                break
            case Status.MEMBER:
                handleClaimButtonClick()
                break
        }
    }

    const handleClaimButtonClick = () => {
        trackButtonClick(GtmEventName.UTAP_CLAIM_CLICK)

        if (!hasNRIC) {
            localStorage.setItem(LocalStorageKeys.MYINFO_REASON, MyInfoReason.UTAP)
            window.location.href = `${STRAPI_URL}${Route.MYINFO_AUTHORISE}?reason=${MyInfoReason.UTAP}`
        } else {
            navigate(Route.UTAP_CLAIM_FORM)
        }
    }

    const handleMyClaimsButtonClick = () => {
        trackButtonClick(GtmEventName.UTAP_MY_CLAIMS_CLICK)

        if (!hasNRIC) {
            localStorage.setItem(LocalStorageKeys.MYINFO_REASON, MyInfoReason.UTAP_CLAIM_LIST)
            window.location.href = `${STRAPI_URL}${Route.MYINFO_AUTHORISE}?reason=${MyInfoReason.UTAP_CLAIM_LIST}`
        } else {
            navigate(Route.UTAP_CLAIMS)
        }
    }

    const buttonText = (): string => {
        switch (status) {
            case Status.LOGGED_IN:
                return t(`utap_landing_become_a_member`)
            case Status.LOGGED_OUT:
                return t(`utap_landing_become_a_member`)
            case Status.MEMBER:
                return t(`utap_landing_claim`)
        }
    }

    return (
        <SplashScreenGuard>
            <Container>
                <CommonBackButton path={Route.HOME} />
                <Typography variant={`h3`}>
                    {state.utapLanding.title}
                </Typography>
                <MarkdownContent content={state.utapLanding.description} />
                <Spacer />
                <Grid className={styles.claimsLandingButtonContainer}>
                    <Button variant='contained' onClick={handleClaimButton} fullWidth>{buttonText()}</Button>
                    {
                        status === Status.MEMBER &&
                        <Button variant='contained' onClick={handleMyClaimsButtonClick} fullWidth>{t(`utap_claims`)}</Button>
                    }
                </Grid>
            </Container>
        </SplashScreenGuard >
    )
}

export default UTAPLanding