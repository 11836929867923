import { Box, List, Typography } from "@mui/material"
import { useNavigate } from 'react-router-dom'
import MenuEntry from "../MenuEntry/MenuEntry"

import styles from "./MenuSection.module.scss"

interface MenuSectionList {
    name: string
    route: string
}

interface MenuSectionProps {
    sectionTitle: string
    list: MenuSectionList[]
    onMenuClose: () => void
}

const MenuSection = (props: MenuSectionProps) => {
    const { sectionTitle, list, onMenuClose } = props

    const navigate = useNavigate()

    const sectionTitleColor = `functional.text.lighter`

    const handleMenuEntryClick = (route: string) => {
        onMenuClose()
        navigate(route)
    }

    const menuListItems = list?.map((item: MenuSectionList) => {
        if (item.name && item.route) {
            return (
                <Box key={item.name} onClick={() => handleMenuEntryClick(item.route)}>
                    <MenuEntry entryName={`${item.name}`} />
                </Box>
            )
        }
    })

    return (
        <Box className={styles.sectionContainer}>
            <List>
                <Typography variant="h6" color={sectionTitleColor} className={styles.sectionTitle}>{sectionTitle}</Typography>
                {menuListItems}
            </List>
        </Box>
    )
}

export default MenuSection
