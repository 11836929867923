import { Box, Container, Skeleton } from "@mui/material"

import styles from "./UserInterestsSelection.module.scss"
import clsx from "clsx"
import SelectableCardSkeleton from "../../../molecules/SelectableCard/SelectableCard.skeleton"

const TitleSkeletonLine = () => {
    const style = clsx(
        styles.title,
        styles.skeleton,
    )
    return <Skeleton className={style} variant="rectangular" />
}

const SubtitleSkeletonLine = () => {
    const style = clsx(
        styles.subtitle,
        styles.skeleton,
    )
    return <Skeleton className={style} variant="rectangular" />
}

const SelectableCardSkeletonLine = () => {
    return <SelectableCardSkeleton className={styles.topicCard} />
}

const UserInterestsSelectionSkeleton = () => {
    return (
        <Container className={styles.container}>
            <TitleSkeletonLine />
            <TitleSkeletonLine />

            <SubtitleSkeletonLine />
            <SubtitleSkeletonLine />
            <SubtitleSkeletonLine />

            <Box className={styles.topicsList}>
                <SelectableCardSkeletonLine />
                <SelectableCardSkeletonLine />
                <SelectableCardSkeletonLine />
                <SelectableCardSkeletonLine />
                <SelectableCardSkeletonLine />
                <SelectableCardSkeletonLine />
            </Box>

            <Box className={styles.bottomButton}>
                <Skeleton className={styles.skeleton} variant="rectangular" />
            </Box>
        </Container>
    )
}

export default UserInterestsSelectionSkeleton