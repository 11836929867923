import { Box, Skeleton } from '@mui/material'

import styles from './CuratedExperienceCard.module.css'

const CuratedExperienceCardSkeleton = () => (
    <Box className={styles.skeletonCuratedExperienceCard}>
        <Skeleton variant="rounded" className={styles.skeletonCuratedExperienceCardImage}/>
        <Skeleton variant="text" className={styles.skeletonCuratedExperienceCardOverline}/>
        <Skeleton variant="text" className={styles.skeletonCuratedExperienceCardTitle}/>
        <Skeleton variant="text" className={styles.skeletonCuratedExperienceDescription}/>
        <Skeleton variant="text" className={styles.skeletonCuratedExperienceDescription}/>
    </Box>
)

export {
    CuratedExperienceCardSkeleton,
}