import { absoluteImageUrl } from '../utils/environment'
import { Partner, StrapiPartner } from '../../types/Partner'

const fromStrapiPartner = (strapiPartner: StrapiPartner): Partner => (
    {
        name: strapiPartner.name,
        code: strapiPartner.code,
        description: strapiPartner.description,
        logo: absoluteImageUrl(strapiPartner.logo),
    }
)

export default fromStrapiPartner