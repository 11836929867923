import { useContext } from 'react'

import { AppContext } from '../../stores/App/AppStore'
import { ActionTypes } from '../../stores/App/AppReducer'
import CuratedExperiencePageRepository from './curated-experience-page-repository'

const useCuratedExperienceRepository = () => {
    const { dispatch, state } = useContext(AppContext)
    const curatedExperienceRepository = new CuratedExperiencePageRepository()

    const retrieveCuratedExperiences = () => {
        curatedExperienceRepository.getAll()
            .then((curatedExperiencesPageData) => {
                dispatch({
                    type: ActionTypes.CURATED_EXPERIENCES_RETRIEVED,
                    curatedExperienceDetails: curatedExperiencesPageData,
                })
            })
            .catch((error) => {
                console.error(error.message)
            })
    }

    const retrieveCuratedExperience = (id: number) => {
        const selectedCuratedExperience = state.pillars.curatedExperience.experiences.find(experience => experience.id === id)

        if (!selectedCuratedExperience) {
            curatedExperienceRepository.get(id)
                .then((curatedExperiencesDetailsData) => {
                    dispatch({
                        type: ActionTypes.CURATED_EXPERIENCE_RETRIEVED,
                        curatedExperienceDetail: curatedExperiencesDetailsData,
                    })
                })
                .catch((error) => {
                    console.error(error.message)
                })
        }
    }

    const retrieveCuratedExperiencePage = () => {
        curatedExperienceRepository.getPage()
            .then((curatedExperiencePage) => {
                dispatch({
                    type: ActionTypes.CURATED_EXPERIENCES_PAGE_RETRIEVED,
                    curatedExperiencePage: curatedExperiencePage,
                })
            })
            .catch((error) => {
                console.error(error.message)
            })
    }

    return {
        retrieveCuratedExperiencePage,
        retrieveCuratedExperiences,
        retrieveCuratedExperience,
    }
}

export { useCuratedExperienceRepository }