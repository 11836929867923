import { Box, Card, CardActionArea, CardActionAreaProps, CardContent, Skeleton } from "@mui/material"
import clsx from "clsx"

import { CARD_AREA_STYLE_BIGGER, CARD_AREA_STYLE_NORMAL } from "./AppCard"

import styles from './AppCard.module.scss'

interface AppCardSkeletonProps extends CardActionAreaProps {
    fullWidth?: boolean
    bigger?: boolean
}

const TitleSkeletonLine = () => {
    const style = clsx(
        styles.title,
        styles.skeleton,
    )

    return <Skeleton className={style} variant="text" />
}

const SummarySkeletonLine = () => {
    const style = clsx(
        styles.summary,
        styles.skeleton,
    )

    return <Skeleton className={style} variant="text" />
}

const AppCardSkeleton = (props: AppCardSkeletonProps) => {
    const { className, fullWidth, bigger, sx, ...inherited } = props

    const defaultStyle = bigger ? CARD_AREA_STYLE_BIGGER : CARD_AREA_STYLE_NORMAL

    const cardActionAreaStyle = {
        ...sx,
        ...defaultStyle,
        width: fullWidth ? `100%` : `75dvw`,
    }

    const cardActionAreaClassName = clsx(
        styles.cardActionArea,
        className,
    )

    return (
        <CardActionArea className={cardActionAreaClassName} sx={cardActionAreaStyle} {...inherited}>
            <Card className={styles.card} elevation={2}>
                <Skeleton className={styles.image} variant="rectangular" />
                <CardContent className={styles.content}>
                    <Box className={styles.titleAndSummary}>
                        <TitleSkeletonLine />
                        <TitleSkeletonLine />

                        <SummarySkeletonLine />
                        <SummarySkeletonLine />
                        <SummarySkeletonLine />
                        <SummarySkeletonLine />
                    </Box>
                </CardContent>
            </Card>
        </CardActionArea>
    )
}

export default AppCardSkeleton