import react, { useEffect, useState } from "react"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import { TransitionProps } from "@mui/material/transitions"
import { Box, Button, DialogTitle, Typography, Zoom } from "@mui/material"

import MarkdownContent from "../../atoms/MarkdownContent/MarkdownContent"
import { MembershipPlan } from "../../../types"

import styles from './MembershipPlanTNCsDialog.module.css'

const Transition = react.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<string, string>;
    },
    ref: React.Ref<unknown>,
) {
    return <Zoom ref={ref} {...props} />
})

export interface MembershipPlanTNCsDialogProps {
    membershipPlan: MembershipPlan
    buttonText: string
    shouldShow?: boolean
    onClose?: () => void
    onLinkTextClick?: () => void
}

const MembershipPlanTNCsDialogDialog = (props: MembershipPlanTNCsDialogProps) => {
    const {
        membershipPlan,
        buttonText,
        shouldShow = false,
        onClose,
        onLinkTextClick,
    } = props

    const [isVisible, setIsVisible] = useState(shouldShow)

    const handleClose = () => onClose?.() ?? setIsVisible(false)

    useEffect(() => {
        setIsVisible(shouldShow)
    }, [shouldShow])

    return (
        <Box>
            <Button className={styles.membershipPlanTNCs} onClick={onLinkTextClick}>
                {membershipPlan.termsAndConditionsLinkText}
            </Button>
            <Dialog open={isVisible} TransitionComponent={Transition} onClose={handleClose} className={styles.dialogBox} >
                <DialogTitle className={styles.title}>
                    <Typography className={styles.titleText}>
                        {membershipPlan.termsAndConditionsHeader}
                    </Typography>
                </DialogTitle>
                <DialogContent >
                    <MarkdownContent className={styles.description} content={membershipPlan.termsAndConditions} />
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' onClick={handleClose} fullWidth>{buttonText}</Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}

export default MembershipPlanTNCsDialogDialog
