import { REQUESTED_ROUTE_KEY } from '../guards/authenticated-guard'
import { Route } from '../index'

const useRequestedRoute = () => {

    const getRequestedRoute = () => {
        return localStorage.getItem(REQUESTED_ROUTE_KEY)
    }

    const setRequestedRoute = (route: Route, id?: string) => {
        if (id) {
            localStorage.setItem(REQUESTED_ROUTE_KEY, `${route}/${id}`)
        } else {
            localStorage.setItem(REQUESTED_ROUTE_KEY, `${route}`)
        }
    }

    return {
        getRequestedRoute,
        setRequestedRoute,
    }
}

export { useRequestedRoute }