import { Box } from "@mui/system"
import { CuratedExperienceDetail } from "../../../types"
import CuratedExperienceCard from "../../molecules/CuratedExperienceCard/CuratedExperienceCard"
import { CuratedExperienceCardSkeleton } from "../../molecules/CuratedExperienceCard/CuratedExperienceCard.skeleton"

import styles from './CuratedExperiencesList.module.css'

interface CuratedExperiencesProps {
    curatedExperiences: CuratedExperienceDetail[]
}

const CuratedExperiences = (props: CuratedExperiencesProps) => {
    const { curatedExperiences } = props

    const sortAndCreateCard = (arr: CuratedExperienceDetail[]) => [...arr]
        .sort((a, b) => a.order - b.order)
        .map((experience) => <CuratedExperienceCard curatedExperience={experience} key={experience.id} />)

    return curatedExperiences.length ? (
        <Box className={styles.content}>
            {sortAndCreateCard(curatedExperiences)}
        </Box>
    ) : (
        <Box className={styles.content}>
            <CuratedExperienceCardSkeleton />
            <CuratedExperienceCardSkeleton />
        </Box>
    )
}

export default CuratedExperiences