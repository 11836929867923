import React from 'react'
import { Box, CardActionAreaProps, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { EventDetail } from '../../../types'
import { eventDateStringWithTimeFormatter } from '../../../helpers/utils/event-date-string-formatter'
import { Route } from '../../../router'
import LandingPageAccessGuard from '../LandingPageAccessGuard/LandingPageAccessGuard'
import AppCard from '../../atoms/AppCard/AppCard'
import useGoogleTagManager from '../../../helpers/analytics/use-google-tag-manager'
import GtmEventName from '../../../helpers/analytics/GtmEventName'

import styles from './EventCard.module.scss'

interface EventCardProps extends CardActionAreaProps {
    event: EventDetail
    fullWidth?: boolean
    onEventClick?: (event: EventDetail) => void
}

const EventCard: React.FC<EventCardProps> = (props: EventCardProps) => {
    const { event, fullWidth, onEventClick, ...inheritedProps } = props

    const { t } = useTranslation()
    const navigate = useNavigate()
    const { trackCardClick } = useGoogleTagManager()

    const formattedDate = eventDateStringWithTimeFormatter(event.eventDate, event.startTime, event.endTime, event.eventEndDate)

    const handleCardClick = () => {
        trackCardClick(GtmEventName.DEAL_CARD_CLICK, event?.title)
        onEventClick?.(event)
        navigate(`${Route.PILLARS_EVENT}/${event.id}`)
    }

    const ribbonText = event.registered ? t(`event_registered`) : undefined

    const handleOnLandingPageGuardClickHandled = () => {
        trackCardClick(GtmEventName.DEAL_CARD_CLICK, event?.title)
    }

    return (
        <LandingPageAccessGuard onClickHandled={handleOnLandingPageGuardClickHandled}>
            <AppCard onClick={handleCardClick} image={event.headerImage} title={event.title} summary={event.summary} fullWidth={fullWidth} ribbonText={ribbonText} {...inheritedProps}>
                <Box className={styles.content}>
                    <Typography variant='body2' color='functional.text.lighter' className={styles.details}>{formattedDate}</Typography>
                    <Typography variant='body2' color='functional.text.lighter' className={styles.details}>{event.location}</Typography>
                </Box>
            </AppCard>
        </LandingPageAccessGuard>
    )

}

export default EventCard