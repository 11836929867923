import { fetchUserInterestsTopics, setUserInterests } from "../../clients/user-interest-topic"
import fromStrapiUserInterestTopic from "../../helpers/mapping/user-interest-topic"
import { UserInterestTopic } from "../../types/UserInterestTopic"

class UserInterestTopicsRepository {

    async retrieveUserInterestsTopics(): Promise<UserInterestTopic[]> {
        const upmeBenefitTopicsResponse = await fetchUserInterestsTopics()
        const userInterestTopics = upmeBenefitTopicsResponse.data.map(fromStrapiUserInterestTopic)

        userInterestTopics.sort((a: UserInterestTopic, b: UserInterestTopic) => {
            if (a.order === b.order) {
                return a.title.localeCompare(b.title)
            }

            if (a.order === 0) {
                return 1
            }

            if (b.order === 0) {
                // eslint-disable-next-line no-magic-numbers
                return -1
            }

            return a.order - b.order
        })

        return userInterestTopics
    }

    async setUserInterests(interestsIds: number[]) {
        return setUserInterests(interestsIds)
    }
}

export default UserInterestTopicsRepository