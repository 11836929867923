import { useContext } from 'react'

import { UPMEContentContext } from '../../stores/UPMEContent/UPMEContentStore'
import UPMEOnboardingRepository from './upme-onboarding.repository'
import { ServiceResponse } from '../../types'
import { UPMEActions } from '../../stores/UPMEContent/UPMEContentReducer'
import { UpmeIntroductionCarouselItem } from '../../types/UpmeIntroductionCarouselItem'
import { SimpleFAQEntry } from '../../types/SimpleFAQEntry'
import { UpmeBenefit } from '../../types/UpmeBenefit'
import { UpmeBenefitCategory } from '../../types/UpmeBenefitCategory'
import { starterDataError } from '../../helpers/utils/error-handling'

const useUpmeOnboardingRepository = () => {
    const { state, dispatch } = useContext(UPMEContentContext)

    const upmeOnboardingRepository = new UPMEOnboardingRepository()

    const retrieveUpmeIntroductionCarouselItems = () => {
        const upmeIntroductionCarouselItemsResponse: ServiceResponse<UpmeIntroductionCarouselItem[]> = {}

        upmeOnboardingRepository.retrieveUpmeIntroductionCarouselItems()
            .then(carouselItems => {
                upmeIntroductionCarouselItemsResponse.data = carouselItems
            })
            .catch(error => {
                upmeIntroductionCarouselItemsResponse.error = starterDataError(error)
            })
            .finally(() => {
                dispatch({
                    type: UPMEActions.UPME_INTRODUCTION_CAROUSEL_ITEMS_RETRIEVED,
                    introductionCarouselItems: upmeIntroductionCarouselItemsResponse,
                })
            })
    }

    const retrieveUpmeIntroductionFAQEntries = () => {
        const upmeIntroductionFAQEntriesResponse: ServiceResponse<SimpleFAQEntry[]> = {}

        upmeOnboardingRepository.retrieveUpmeIntroductionFAQEntries()
            .then(faqEntries => {
                upmeIntroductionFAQEntriesResponse.data = faqEntries
            })
            .catch(error => {
                upmeIntroductionFAQEntriesResponse.error = starterDataError(error)
            })
            .finally(() => {
                dispatch({
                    type: UPMEActions.UPME_INTRODUCTION_FAQ_ENTRIES_RETRIEVED,
                    introductionFAQEntries: upmeIntroductionFAQEntriesResponse,
                })
            })
    }

    const retrieveUpmeBenefits = () => {
        const upmeBenefitsResponse: ServiceResponse<UpmeBenefit[]> = {}

        upmeOnboardingRepository.retrieveUpmeBenefits()
            .then(benefits => {
                upmeBenefitsResponse.data = benefits
            })
            .catch(error => {
                upmeBenefitsResponse.error = starterDataError(error)
            })
            .finally(() => {
                dispatch({
                    type: UPMEActions.UPME_BENEFITS_RETRIEVED,
                    benefits: upmeBenefitsResponse,
                })
            })
    }

    const retrieveUpmeBenefit = (id: number) => {
        const upmeBenefitResponse: ServiceResponse<UpmeBenefit> = {}

        if (state.benefits?.data?.find(benefit => benefit.id === id)) {
            return
        }

        upmeOnboardingRepository.retrieveUpmeBenefit(id)
            .then(benefit => {
                upmeBenefitResponse.data = benefit
            })
            .catch(error => {
                upmeBenefitResponse.error = starterDataError(error)
            })
            .finally(() => {
                dispatch({
                    type: UPMEActions.UPME_BENEFIT_RETRIEVED,
                    benefit: upmeBenefitResponse,
                })
            })
    }

    const retrieveUpmeBenefitCategories = () => {
        const upmeBenefitCategoriesResponse: ServiceResponse<UpmeBenefitCategory[]> = {}

        upmeOnboardingRepository.retrieveUpmeBenefitCategories()
            .then(benefitCategories => {
                upmeBenefitCategoriesResponse.data = benefitCategories
            })
            .catch(error => {
                upmeBenefitCategoriesResponse.error = starterDataError(error)
            })
            .finally(() => {
                dispatch({
                    type: UPMEActions.UPME_BENEFIT_CATEGORIES_RETRIEVED,
                    benefitCategories: upmeBenefitCategoriesResponse,
                })
            })
    }

    return {
        retrieveUpmeIntroductionCarouselItems,
        retrieveUpmeIntroductionFAQEntries,
        retrieveUpmeBenefits,
        retrieveUpmeBenefit,
        retrieveUpmeBenefitCategories,
    }
}

export { useUpmeOnboardingRepository }