import { Box, Typography } from "@mui/material"
import { CardActionAreaProps } from '@mui/material/CardActionArea'
import { useNavigate } from "react-router-dom"
import { useTranslation } from 'react-i18next'
import moment from "moment"

import { Deal } from "../../../types/Deal"
import { DatePatterns } from "../../../types/date-patterns"
import LandingPageAccessGuard from '../LandingPageAccessGuard/LandingPageAccessGuard'
import AppCard from '../../atoms/AppCard/AppCard'
import useGoogleTagManager from '../../../helpers/analytics/use-google-tag-manager'
import GtmEventName from '../../../helpers/analytics/GtmEventName'

import styles from "./DealCard.module.scss"

interface DealCardProps extends CardActionAreaProps {
    deal?: Deal,
    fullWidth?: boolean
}

const DealCard = (props: DealCardProps) => {
    const { deal, fullWidth, ...inheritedProps } = props

    const { t } = useTranslation()
    const navigate = useNavigate()
    const { trackCardClick } = useGoogleTagManager()

    const handleCardClick = () => {
        trackCardClick(GtmEventName.DEAL_CARD_CLICK, deal?.title)
        navigate(`/deals/${deal?.id}`)
    }

    const formattedStartDate = moment(deal?.startDate).format(DatePatterns.ABBREVIATED_DATE)
    const formattedEndDate = deal?.endDate ? moment(deal?.endDate).format(DatePatterns.ABBREVIATED_DATE) : ``
    const dealValidity = formattedStartDate && formattedEndDate ? t(`deals_validity_full`, { start: formattedStartDate, end: formattedEndDate }) : t(`deals_validity_start_only`, { start: formattedStartDate })

    const handleOnLandingPageGuardClickHandled = () => {
        trackCardClick(GtmEventName.DEAL_CARD_CLICK, deal?.title)
    }

    return (
        <LandingPageAccessGuard onClickHandled={handleOnLandingPageGuardClickHandled}>
            <AppCard onClick={handleCardClick} image={deal?.image} title={deal?.title} summary={deal?.summary} fullWidth={fullWidth} {...inheritedProps}>
                <Box className={styles.content}>
                    <Typography className={styles.date} variant="body2">{dealValidity}</Typography>
                </Box>
            </AppCard>
        </LandingPageAccessGuard>
    )
}

export default DealCard