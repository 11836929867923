import { useContext } from 'react'

import HomeCarouselRepository from './home-carousel.repository'
import { ServiceResponse } from '../../types'
import { HighlightCarouselItem } from '../../types/HighlightCarouselItem'
import { ActionTypes } from '../../stores/App/AppReducer'
import { AppContext } from '../../stores/App/AppStore'
import { MembershipType } from '../../types/MembershipType'
import { starterDataError } from '../../helpers/utils/error-handling'

const useHomeCarouselRepository = () => {
    const { dispatch } = useContext(AppContext)

    const homeCarouselRepository = new HomeCarouselRepository()

    const retrieveHomeCarousel = (membershipType: MembershipType) => {
        const homeCarouselResponse: ServiceResponse<HighlightCarouselItem[]> = {}

        homeCarouselRepository.retrieveStarterHomeCarousel(membershipType)
            .then(carouselItems => {
                homeCarouselResponse.data = carouselItems
            })
            .catch(error => {
                homeCarouselResponse.error = starterDataError(error)
            })
            .finally(() => {
                dispatch({
                    type: ActionTypes.HOME_CAROUSEL_RETRIEVED,
                    homeCarouselItems: homeCarouselResponse,
                })
            })
    }

    return {
        retrieveHomeCarousel,
    }
}

export { useHomeCarouselRepository }