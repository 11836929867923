import { useContext } from 'react'

import { ActionTypes } from '../../stores/App/AppReducer'
import { AppContext } from '../../stores/App/AppStore'
import { PaymentOrderUrl } from '../../types/PaymentOrder'
import { CreatePaymentOrderRequest, PaymentIntentResponse, StripePaymentOrderRequest, StripePaymentOrderResult, PaymentHistoryResult } from '../../types/StripePaymentOrder'
import { StripePaymentOrderResultServiceResponse, StripePaymentOrderServiceResponse, StripePaymentOrderHistoryServiceResponse } from "../../types/StripePaymentOrderServiceResponse"

import PaymentOrderRepository from './paymentOrder.repository'
import { PaymentOrderReason } from '../../types/PaymentOrderReason'
import { CustomErrorCode } from '../../types/CustomErrorCode'

const usePaymentOrderRepository = () => {
    const { dispatch } = useContext(AppContext)

    const paymentOrderRepository = new PaymentOrderRepository()

    const getPaymentOrder = async (order: StripePaymentOrderRequest) => {
        const paymentOrderResultData: StripePaymentOrderResultServiceResponse = {}

        return paymentOrderRepository.getPaymentOrder(order)
            .then((paymentOrderResult: StripePaymentOrderResult) => {
                paymentOrderResultData.data = paymentOrderResult

            })
            .catch((error) => {
                paymentOrderResultData.error = {
                    errorMessage: error.message,
                    errorCode: error.response?.status,
                }
            })
            .finally(() => {
                dispatch({
                    type: ActionTypes.PAYMENT_ORDER_RESULT_RETRIEVED,
                    paymentOrderResultData,
                })
            })
    }

    const createPaymentOrder = async (reason: PaymentOrderReason, exchangeId?: string, plan?: string) => {
        const paymentOrderData: StripePaymentOrderServiceResponse = {}

        const createPaymetOrderRequest: CreatePaymentOrderRequest = {
            exchangeId,
            reason,
            plan,
        }

        return paymentOrderRepository.createPaymentOrder(createPaymetOrderRequest)
            .then((paymentOrder: PaymentIntentResponse) => {
                paymentOrderData.data = paymentOrder

                if (paymentOrder.clientOrderId) {
                    paymentOrderRepository.setClientOrderIdInLocalStorage(paymentOrder.clientOrderId)
                }
                return paymentOrderData
            })
            .catch((error) => {
                if (error.response?.data?.error?.details?.code !== CustomErrorCode.TOO_MANY_ENDPOINT_CALLS) {
                    paymentOrderData.error = {
                        errorMessage: error.message,
                        errorCode: error.response?.status,
                    }
                }
            })
            .finally(() => {
                dispatch({
                    type: ActionTypes.PAYMENT_ORDER_RETRIEVED,
                    paymentOrderData,
                })
            })
    }

    const getPaymentUrl = async (clientOrderId: string) => {
        return paymentOrderRepository.getPaymentUrl(clientOrderId)
            .then((paymentOrderUrl: PaymentOrderUrl) => {
                return paymentOrderUrl
            })
    }

    const getPaymentOrderHistory = async (exchangeId: string, pageSize: number) => {
        const paymentOrderHistoryData: StripePaymentOrderHistoryServiceResponse = {}

        return paymentOrderRepository.getPaymentOrderHistory(exchangeId, pageSize)
            .then((paymentOrderHistory: PaymentHistoryResult[]) => {
                paymentOrderHistoryData.data = paymentOrderHistory
            })
            .catch((error) => {
                paymentOrderHistoryData.error = {
                    errorMessage: error.message,
                    errorCode: error.response?.status,
                }
            })
            .finally(() => {
                dispatch({
                    type: ActionTypes.PAYMENT_ORDER_HISTORY_RETRIEVED,
                    paymentOrderHistoryData,
                })
            })
    }

    const getClientOrderIdFromLocalStorage = () => {
        return paymentOrderRepository.getClientOrderIdFromLocalStorage()
    }

    return {
        getPaymentOrder,
        createPaymentOrder,
        getPaymentUrl,
        getPaymentOrderHistory,
        getClientOrderIdFromLocalStorage,
    }
}

export { usePaymentOrderRepository }