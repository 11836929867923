import { t } from 'i18next'
import moment from 'moment/moment'
import { Membership } from '../../types'
import { MembershipStatus } from '../../types/MembershipStatus'
import { PromptVariant } from '../../components/molecules/Prompt/Prompt'

interface MessagePrompt {
    message: string
    variant: PromptVariant
}

export const getAgeFromDateOfBirth = (dateOfBirth?: Date | string) => {
    if (!dateOfBirth) {
        return 0
    }

    const today = moment()
    const birthDate = moment(dateOfBirth)

    return today.diff(birthDate, `years`)
}

export const isPastDateLimit = (optInDateString: string | undefined, daysBeforeActivation: number | undefined) => {
    if (!optInDateString || !daysBeforeActivation) {
        return false
    }
    const optInDate = moment(optInDateString).add(daysBeforeActivation, `days`)

    return (optInDate.isBefore())
}

export const isPassedDate = (referenceDate: string | undefined): boolean => {
    if (!referenceDate) {
        return false
    }

    const today = moment()

    return today.isAfter(moment(referenceDate))
}

const isLessThan6MonthsTo25 = (dateOfBirth: Date | string) => {
    const today = moment()

    const nextBirthday = moment(dateOfBirth).set(`year`, today.year())

    if (nextBirthday.isBefore(today)) {
        nextBirthday.add(1, `year`)
    }

    // eslint-disable-next-line no-magic-numbers
    return nextBirthday.diff(today, `months`, true) <= 6
}

export const reaches25Within6Months = (dateOfBirth?: Date | string): boolean => {
    if (!dateOfBirth) {
        return false
    }

    const today = moment()
    const birthDate = moment(dateOfBirth)

    const userAge = today.diff(birthDate, `years`)

    // eslint-disable-next-line no-magic-numbers
    if (userAge < 24) {
        return false
    }

    // eslint-disable-next-line no-magic-numbers
    if (userAge >= 25) {
        return true
    }

    const isUserLessThan6MonthsTo25 = isLessThan6MonthsTo25(dateOfBirth)

    return isUserLessThan6MonthsTo25
}

export const messagePromptToUnionBaseOnAge = (dateOfBirth: Date | string, membership?: Membership): MessagePrompt | undefined => {
    const today = moment()
    const birthDate = moment(dateOfBirth)

    const userAge = today.diff(birthDate, `years`)

    // eslint-disable-next-line no-magic-numbers
    if (userAge >= 25 && membership?.status === MembershipStatus.TERMINATED) {
        return {
            message: t(`prompt_become_union_member_25_and_expired`),
            variant: `success`,
        }
    } else if (isLessThan6MonthsTo25(dateOfBirth)) {
        return {
            message: t(`prompt_become_union_member_6_months_to_expiry`),
            variant: `info`,
        }
    } else {
        return undefined
    }
}

export const is6MonthsToMembershipExpiryDate = (expiryDate?: Date | string): boolean => {
    if (!expiryDate) {
        return false
    }

    const membershipExpiryDate = moment(expiryDate)

    // eslint-disable-next-line no-magic-numbers
    return membershipExpiryDate < moment().add(6, `months`)
}

export const isUserUnderAge25OnExpiryDate = (dateOfBirth?: Date | string, expiryDate?: Date | string): boolean => {
    if (!dateOfBirth || !expiryDate) {
        return false
    }

    const dob = moment(dateOfBirth)
    const membershipExpiryDate = moment(expiryDate)

    // eslint-disable-next-line no-magic-numbers
    return membershipExpiryDate.diff(dob, `years`) < 25
}

export const isValidMembershipExpiryDate = (expiryDate?: Date | string): boolean => {
    if (!expiryDate) {
        return false
    }

    const validDate = moment().isBefore(moment(expiryDate))

    return validDate
}