import { AccountCircle } from "@mui/icons-material"
import { Avatar } from "@mui/material"
import { useContext, useEffect } from "react"
import { UserContext } from "../../../stores/User/UserStore"
import { useUserRepository } from "../../../repositories/User/use-user-repository"

import styles from './UserAvatar.module.css'

interface UserAvatarProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    enhancedStyle?: any
    onClick?: () => void
}

const UserAvatar: React.FC<UserAvatarProps> = (props: UserAvatarProps) => {
    const { enhancedStyle, onClick } = props
    const { state: userState } = useContext(UserContext)
    const { retrieveUser } = useUserRepository()

    useEffect(() => {
        retrieveUser()
    }, [])

    const avatarStyle = {
        width: `28px`,
        height: `28px`,
        fontSize: `1rem`,
        ...enhancedStyle,
    }

    const capitalFirstLetter = (input: string): string => {
        return input[0].toUpperCase()
    }

    const userInitials = (): string => {
        if (userState.user?.data?.name) {
            const parts = userState.user?.data?.name.trim().split(/\W/)
            const firstCharacter = capitalFirstLetter(parts[0])
            const secondCharacter = parts.length > 1 && parts[1] ? capitalFirstLetter(parts[1]) : ``

            return `${firstCharacter}${secondCharacter}`
        }
        return `??`
    }

    if (userState.user?.data) {
        const userInitialsString = userInitials()
        return <Avatar sx={avatarStyle} onClick={onClick} src={userState.membership?.data?.profilePictureThumbnail}>{userInitialsString}</Avatar>
    }

    return <AccountCircle className={styles.icon} fontSize={`medium`} />
}

export default UserAvatar