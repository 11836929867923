import { useContext, useEffect, useRef, useState } from 'react'
import { Button, Container, Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { AppContext } from '../../../stores/App/AppStore'
import { useEventRepository } from '../../../repositories/EventPage/use-event-page-repository'
import CommonBackButton from '../../atoms/CommonBackButton/CommonBackButton'
import EventsList from '../../organisms/EventsList/EventsList'
import SplashScreenGuard from '../../../router/guards/SplashScreenGuard'
import { useMembershipRepository } from '../../../repositories/Membership/use-membership-repository'
import { UserContext } from '../../../stores/User/UserStore'
import useTrackPageView from "../../../helpers/hooks/use-track-page-view"
import useGoogleTagManager from '../../../helpers/analytics/use-google-tag-manager'
import GtmEventName from '../../../helpers/analytics/GtmEventName'

import styles from "./Events.module.css"

const DEFAULT_PAGE_SIZE = 20

const Event = () => {
    useTrackPageView(`Events Page`)

    const { state } = useContext(AppContext)
    const { state: userState } = useContext(UserContext)
    const { retrieveMembership } = useMembershipRepository()
    const { retrieveEvents } = useEventRepository()
    const { t } = useTranslation()
    const { trackFilterClick } = useGoogleTagManager()

    const allEvents = state.events?.data ?? []
    const userMembership = userState.membership?.data

    const [showAll, setShowAll] = useState<boolean>(true)
    const [sentinelRef, setSentinelRef] = useState(null)
    const lastItemRef = useRef(null)
    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE)

    useEffect(() => {
        retrieveMembership()
    }, [])

    useEffect(() => {
        // retrieveRegisteredEvents(pageSize)
    }, [showAll, pageSize])

    useEffect(() => {
        if (userMembership) {
            retrieveEvents(userMembership.type)
        }
    }, [userState.membership])

    useEffect(() => {
        if (sentinelRef) {
            setPageSize((prevPageSize) => prevPageSize + DEFAULT_PAGE_SIZE)
        }
    }, [sentinelRef])

    const handleIntersection = (entry: IntersectionObserverEntry) => {
        if (entry.isIntersecting) {
            setSentinelRef(lastItemRef.current)
        }
    }

    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) => {
            handleIntersection(entry)
        })

        if (lastItemRef.current) {
            observer.observe(lastItemRef.current)
        }

        return () => {
            if (lastItemRef.current) {
                observer.unobserve(lastItemRef.current)
            }
        }
    }, [allEvents])

    const handleRegisteredEventsListClicked = () => {
        if (showAll) {
            trackFilterClick(GtmEventName.FILTER_EVENTS, `registered`)
        }
        setShowAll(false)
    }

    const handleAllEventsListClicked = () => {
        if (!showAll) {
            trackFilterClick(GtmEventName.FILTER_EVENTS, `all`)
        }
        setShowAll(true)
    }

    const currentList = showAll ? allEvents : allEvents.filter(event => event.registered)
    const styleAllEventsButton = showAll ? `${styles.currentButton}` : `${styles.button}`
    const styleRegisteredEventsButton = showAll ? `${styles.button}` : `${styles.currentButton}`

    return (
        <SplashScreenGuard>
            <Container>
                <CommonBackButton />
                <Grid container className={styles.gridContainer} direction="column" spacing={2}>
                    <Grid className={styles.right} item xs={4}>
                        <Button variant='contained' className={styleAllEventsButton} onClick={handleAllEventsListClicked}>{t(`event_all_list`)}</Button>
                        <Button variant='contained' className={styleRegisteredEventsButton} onClick={handleRegisteredEventsListClicked}>{t(`event_registered_list`)}</Button>
                    </Grid>
                    <Grid item>
                        <EventsList events={currentList} reference={lastItemRef} />
                    </Grid>
                </Grid>
            </Container>
        </SplashScreenGuard>
    )
}

export default Event