import { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import HorizontalScrollComponent from '../../molecules/HorizontalScrollComponent/HorizontalScrollComponent'
import ProgrammeApplicationFormCard from '../../molecules/ProgrammeApplicationFormCard/ProgrammeApplicationFormCard'
import { useMembershipRepository } from '../../../repositories/Membership/use-membership-repository'
import { UserContext } from '../../../stores/User/UserStore'
import useProgrammeApplicationFormRepository from '../../../repositories/ProgrammeApplicationForm/use-programme-application-form-repository'
import { ProgrammeApplicationForm } from '../../../types/ProgrammeApplicationForm'
import { ProgrammeContext } from '../../../stores/Programme/ProgrammeStore'
import { MembershipType } from '../../../types/MembershipType'

const DEFAULT_ITEMS_SIZE = 3

interface ProgrammeApplicationFormsHomeListProps {
    membershipType?: MembershipType
}

const ProgrammeApplicationFormsHomeList = (props: ProgrammeApplicationFormsHomeListProps) => {
    const { membershipType } = props

    const { t } = useTranslation()
    const { state: userState } = useContext(UserContext)
    const { state: programmeState } = useContext(ProgrammeContext)
    const { retrieveMembership } = useMembershipRepository()
    const { retrieveProgrammeApplicationForms } = useProgrammeApplicationFormRepository()

    useEffect(() => {
        if (!membershipType) {
            retrieveMembership()
        } else {
            retrieveProgrammeApplicationForms(membershipType, false)
        }
    }, [])

    useEffect(() => {
        if (userState.membership?.data) {
            retrieveProgrammeApplicationForms(userState.membership?.data.type, true)
        }
    }, [userState.membership])

    const renderProgrammeApplicationFormCard = (programmeApplicationForm: ProgrammeApplicationForm) => <ProgrammeApplicationFormCard programmeApplicationForm={programmeApplicationForm} />

    return (
        <HorizontalScrollComponent listTitle={t(`programme_application_form_home_list_title`)} items={programmeState.programmeApplicationForms?.data} renderItem={renderProgrammeApplicationFormCard} maxNumOfItems={DEFAULT_ITEMS_SIZE} isLandingPage />
    )
}

export default ProgrammeApplicationFormsHomeList
