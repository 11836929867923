const enum GtmEventCategory {
    BUTTON = `Button`,
    HYPERLINK = `Hyperlink`,
    DIALOG = `Dialog`,
    CAROUSEL = `Carousel`,
    CARD = `Card`,
    TEXT_FIELD = `Text Field`,
    FORM = `Form`,
    PROMPT = `Prompt`,
    MENU = `Menu`,
    PILLAR = `Pillar`,
    FILTER = `Filter`,
}

export default GtmEventCategory