import { Bank } from '../../types'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const fromStrapiBank = (strapiData: any): Bank => {
    const { id, attributes } = strapiData
    const { name, code } = attributes

    return {
        id,
        name,
        code,
    }
}

export {
    fromStrapiBank,
}