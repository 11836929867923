import { FieldOfStudy } from './../../types'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const fromStrapiFieldOfStudy = (strapiData: any): FieldOfStudy => {
    const { id, attributes } = strapiData
    const { name, order } = attributes

    const item: FieldOfStudy = {
        id,
        name,
        order,
    }

    return item
}

export {
    fromStrapiFieldOfStudy,
}