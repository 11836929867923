import { fetchMembershipSuccessPage } from '../../clients/pages'
import { fromStrapiMembershipSuccessPage } from '../../helpers/mapping/membership-success-page'
import { MembershipSuccessPage } from '../../types'

class MembershipSuccessPageRepository {

    async get(): Promise<MembershipSuccessPage> {
        const { data } = await fetchMembershipSuccessPage()
        return fromStrapiMembershipSuccessPage(data)
    }
}

export default MembershipSuccessPageRepository