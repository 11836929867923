const curatedExperienceValidityStringFormatter = ({ startDate, endDate }: {
    startDate?: Date, endDate?: Date
}): string => {
    if (startDate && endDate) {
        return `Valid from ${dateFormatter(startDate)} till ${dateFormatter(endDate)}`
    } else if (startDate) {
        return `Valid from ${dateFormatter(startDate)}`
    } else if (endDate) {
        return `Valid until ${dateFormatter(endDate)}`
    } else {
        return ``
    }
}
const dateFormatter = (date: Date, monthFormat: 'short' | 'long' = `short`): string => {
    return date.toLocaleDateString(`en-GB`, { year: `numeric`, month: monthFormat, day: `numeric` })
}

export {
    curatedExperienceValidityStringFormatter,
}