import { Box, CardActionAreaProps, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

import UpmeBenefitCardSkeleton from "./UpmeBenefitCard.skeleton"
import { MembershipType } from "../../../types/MembershipType"
import { UpmeBenefit } from "../../../types/UpmeBenefit"
import { Route } from "../../../router"
import AppCard from "../../atoms/AppCard/AppCard"
import useGoogleTagManager from '../../../helpers/analytics/use-google-tag-manager'
import GtmEventName from '../../../helpers/analytics/GtmEventName'

import styles from './UpmeBenefitCard.module.scss'

interface UpmeBenefitCardProps extends CardActionAreaProps {
    benefit?: UpmeBenefit,
    disabled?: boolean,
}

interface CardComparisonProps {
    benefit: UpmeBenefit
    type: MembershipType
}

const CardComparison = (props: CardComparisonProps) => {
    const { benefit, type } = props

    const { t } = useTranslation()

    let typeLabel, label, isTypeEnabled

    switch (type) {
        case MembershipType.STARTER:
            typeLabel = t(`member_starter`)
            label = benefit.isStarterDetails ?? (benefit.isStarter ? t(`benefit_available`) : t(`benefit_unavailable`))
            isTypeEnabled = benefit.isStarter
            break
        case MembershipType.UPME:
            typeLabel = t(`member_union`)
            label = benefit.isUpmeDetails ?? (benefit.isUpme ? t(`benefit_available`) : t(`benefit_unavailable`))
            isTypeEnabled = benefit.isUpme
            break
    }

    let labelColor = `functional.text.main`

    if (benefit.isStarter !== benefit.isUpme) {
        labelColor = isTypeEnabled ? `success.main` : `error.main`
    }

    return (
        <Box>
            <Typography className={styles.comparisonTypeLabel}>{typeLabel}</Typography>
            <Typography className={styles.comparisonLabel} color={labelColor}>{label}</Typography>
        </Box>
    )
}

const UpmeBenefitCard = (props: UpmeBenefitCardProps) => {
    const { benefit, ...inheritedProps } = props

    const navigate = useNavigate()
    const { trackCardClick } = useGoogleTagManager()

    if (!benefit) {
        return <UpmeBenefitCardSkeleton />
    }

    const handleCardClick = () => {
        trackCardClick(GtmEventName.NTUC_BENEFIT_CARD_CLICK, benefit.title)
        navigate(`${Route.UPME_CONVERSION_BENEFITS}/${benefit.id}`)
    }

    return (
        <AppCard onClick={handleCardClick} image={benefit.image} title={benefit.title} summary={benefit.summary} fullWidth bigger contentBelow {...inheritedProps}>
            <Box className={styles.benefitComparison}>
                <CardComparison benefit={benefit} type={MembershipType.STARTER} />
                <CardComparison benefit={benefit} type={MembershipType.UPME} />
            </Box>
        </AppCard>
    )
}

UpmeBenefitCard.displayName = `UPME Benefit Card`

export default UpmeBenefitCard