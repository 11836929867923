import React from 'react'
import { Container, Grid, Skeleton } from '@mui/material'
import SkeletonProps from '../../../types/SkeletonProps'
import { PillarCardSkeleton } from '../../molecules/PillarCard/PillarCard.skeleton'

const HomeSkeleton: React.FC<SkeletonProps> = (props: SkeletonProps) => {
    const { children, condition } = props

    if (condition) {
        return children
    }

    return (
        <Container>
            <Grid container display='flex' justifyContent="center" alignItems="center" direction='column'>
                <Grid item width='100%'>
                    <Skeleton variant='rectangular' width='100%' height='40vw' />
                </Grid>
                <Grid item flexGrow={1} marginTop={1} width='100%'>
                    <Skeleton variant="text" sx={{ fontSize: `1rem` }} width='70%' />
                    <Skeleton variant="text" sx={{ fontSize: `1rem` }} width='100%' />
                    <Skeleton variant="text" sx={{ fontSize: `1rem` }} width='100%' />
                    <Skeleton variant="text" sx={{ fontSize: `1rem` }} width='40%' />
                </Grid>
                <Grid item marginTop={1} width='100%'>
                    <Skeleton variant='rectangular' width='100%' height='25vw' />
                </Grid>
                <Grid item marginTop={1} width='100%' justifyContent="flex-start">
                    <Skeleton variant="text" sx={{ fontSize: `1rem` }} width='40%' />
                </Grid>
                <Grid item marginTop={1} width='100%' justifyContent="flex-start">
                    <PillarCardSkeleton />
                    <PillarCardSkeleton />
                </Grid>
            </Grid>
        </Container>
    )
}

export default HomeSkeleton