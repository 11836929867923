/* eslint-disable @typescript-eslint/no-explicit-any */

const arrayUpsert = (arr: any[], key: string, value: any): any[] => {
    const index = arr.indexOf((i: any) => i[key] === value[key])
    let result = [...arr]

    if (index >= 0) {
        result[index] = value
    }
    else {
        result = [...arr, value]
    }

    return result
}

export {
    arrayUpsert,
}