import { Container, Grid, Typography } from "@mui/material"
import { useTranslation } from 'react-i18next'
import { useNavigate } from "react-router-dom"
import useMediaQuery from '@mui/material/useMediaQuery'

import { MembershipType } from "../../../types/MembershipType"
import UserAvatar from "../../atoms/UserAvatar/UserAvatar"
import { UserState } from "../../../stores/User/UserState"
import { Route } from "../../../router"
import UpgradeUnionCard from "../UpgradeUnionCard/UpgradeUnionCard"
import MembershipStatusPill from "../../atoms/MembershipStatusPill/MembershipStatusPill"
import useGoogleTagManager from '../../../helpers/analytics/use-google-tag-manager'
import GtmEventName from '../../../helpers/analytics/GtmEventName'

import styles from './ProfileInfo.module.scss'

interface ProfileInfoProps {
    membershipType?: MembershipType
    user: UserState
    onAvatarClick: () => void
    onUnionCardClick: () => void
}

const ProfileInfo = (props: ProfileInfoProps) => {
    const { membershipType, user, onAvatarClick, onUnionCardClick } = props

    const { t } = useTranslation()
    const navigate = useNavigate()
    const { trackButtonClick } = useGoogleTagManager()

    const userMembership = user.membership?.data

    const handleShowMemberBenefitsClick = () => {
        trackButtonClick(GtmEventName.VIEW_NTUC_BENEFITS_CLICK)
        navigate(`${Route.UPME_CONVERSION_BENEFITS}?fromMenu`)
    }

    const handleAvatarClick = () => {
        trackButtonClick(GtmEventName.PROFILE_PICTURE_CLICK)
        onAvatarClick()
        navigate(Route.PROFILE_PICTURE_SELECTION)
    }

    const upmeBenefitsLink = membershipType === MembershipType.UPME && (
        <Typography variant="subtitle2" className={styles.benefits} onClick={handleShowMemberBenefitsClick}>
            {t(`profile_upme_benefits`)}
        </Typography>
    )

    const isSmallScreen = useMediaQuery(`(max-width:768px)`)
    const avatarSize = isSmallScreen ? `3rem` : `5rem`

    const enhancedAvatarStyle = {
        width: avatarSize,
        height: avatarSize,
    }

    const upgradeToUnionCard = userMembership?.type === MembershipType.STARTER && <UpgradeUnionCard handleUnionCardClick={onUnionCardClick} />

    return (
        <Container className={styles.container}>
            <Grid container direction="column">
                <Grid container direction="row" alignItems="center">
                    <Grid item xs={2} sm={2} className={styles.avatar}>
                        <UserAvatar enhancedStyle={enhancedAvatarStyle} onClick={handleAvatarClick} />
                    </Grid>
                    <Grid item xs={10} sm={10} className={styles.rightColumn}>
                        <Typography className={styles.name} variant="h1" mb={1}>{userMembership?.fullName ?? user?.session?.data?.name}</Typography>
                        <MembershipStatusPill membershipStatus={userMembership?.status} membershipType={membershipType} />
                        {upmeBenefitsLink}
                    </Grid>
                </Grid>
                {upgradeToUnionCard}
            </Grid>

        </Container>
    )
}

export default ProfileInfo