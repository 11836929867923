import { StrapiDeal, Deal } from '../../types/Deal'
import { absoluteImageUrl } from '../utils/environment'
import fromStrapiDealCategory from '../mapping/deal-category'

const fromStrapiDeal= (strapiDeal: StrapiDeal): Deal => {
    return {
        id: strapiDeal.id,
        title: strapiDeal.attributes.title,
        summary: strapiDeal.attributes.summary,
        membershipScope: strapiDeal.attributes.membershipScope,
        description:  strapiDeal.attributes.description,
        order: strapiDeal.attributes.order,
        merchantWebsite: strapiDeal.attributes.merchantWebsite,
        merchantName: strapiDeal.attributes.merchantName,
        startDate: strapiDeal.attributes.startDate,
        endDate: strapiDeal.attributes.endDate,
        promotionCode: strapiDeal.attributes.promotionCode,
        externalLink: strapiDeal.attributes.externalLink,
        externalLinkName: strapiDeal.attributes.externalLinkName,
        termsAndConditions: strapiDeal.attributes.termsAndConditions,
        merchantAddress: strapiDeal.attributes.merchantAddress,
        image: strapiDeal.attributes.image?.data ? absoluteImageUrl(strapiDeal.attributes.image.data.attributes.url) : ``,
        category: fromStrapiDealCategory(strapiDeal.attributes.category.data),
    }
}

export default fromStrapiDeal