/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box } from '@mui/system'

const Spacer = ({ height, flexNumber }: { height?: number, flexNumber?: number }) => {
    // Default space eater in flex grids
    let sxOverride: any = { flex: 2, flexGrow: 2 }

    if (height) {
        sxOverride = { height: `${height}px` }
    }
    if (flexNumber) {
        sxOverride = { flex: flexNumber, flexGrow: flexNumber }
    }

    return <Box sx={sxOverride} />
}

export default Spacer