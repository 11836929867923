import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Route } from '..'
import { useSessionRepository } from '../../repositories/Session/use-session-repository'

export default function useNonMemberGuard(nextRoute?: Route) {
    const { isMember } = useSessionRepository()
    const navigate = useNavigate()

    useEffect(() => {
        isMember().then(isMember => {
            if (isMember) {
                navigate(nextRoute ?? Route.HOME)
            }
        })
    })
}
