import { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { UserContext } from '../../../../stores/User/UserStore'
import { usePartnerRepository } from '../../../../repositories/Partner/use-partner-repository'
import { useMembershipRepository } from '../../../../repositories/Membership/use-membership-repository'
import LoadingPageContent from '../../../organisms/LoadingPageContent/LoadingPageContent'
import useTrackPageView from "../../../../helpers/hooks/use-track-page-view"

import SignUpFormComponent from './SignUpFormComponent'

const SignUp = () => {
    useTrackPageView(`Sign Up Form`)

    const { t } = useTranslation()

    const { state: userState } = useContext(UserContext)
    const { clearVisitedPartnerPage } = usePartnerRepository()
    const { retrieveMembership } = useMembershipRepository()

    useEffect(() => {
        clearVisitedPartnerPage()
        retrieveMembership()
    }, [])

    if (userState.membership?.data) {
        return (
            <SignUpFormComponent />
        )
    } else {
        return (
            <LoadingPageContent title={t(`membership_retrieving_title`)} message={t(`membership_retrieving_message`)} />
        )
    }
}

export default SignUp