import { Box, Typography, useTheme} from '@mui/material'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

import { Membership } from '../../../types'
import { DatePatterns } from '../../../types/date-patterns'

import styles from "./UpmeProfileUpdateInfo.module.scss"

interface UpmeProfileUpdateInfoProps {
    userMembership: Membership
}

const UpmeProfileUpdateInfo = (props: UpmeProfileUpdateInfoProps) => {
    const { userMembership } = props
    const { t } = useTranslation()

    const { palette } = useTheme()

    const labelTextLighter = {
        color: palette?.functional?.text.lighter,
    }

    const formatUnionStart = userMembership?.unionMemberStartDate && moment(userMembership?.unionMemberStartDate).format(DatePatterns.FULL_DATE)

    return (
        <>
            <Box className={styles.info}>
                <Typography variant="body2" sx={labelTextLighter} className={styles.profileLabel} mb={1}>{t(`sign_up_name`)}</Typography>
                <Typography variant="subtitle1" className={styles.profileDetails} mb={1}>{userMembership?.fullName}</Typography>
            </Box>
            <Box className={styles.info}>
                <Typography variant="body2" sx={labelTextLighter} className={styles.profileLabel} mb={1}>{t(`sign_up_phone_number`)}</Typography>
                <Typography variant="subtitle1" className={styles.profileDetails} mb={1}>{userMembership?.unionPhoneNumber ?? t(`profile_update_value_missing`)}</Typography>
            </Box>
            <Box className={styles.info}>
                <Typography variant="body2" sx={labelTextLighter} className={styles.profileLabel} mb={1}>{t(`sign_up_email_address`)}</Typography>
                <Typography variant="subtitle1" className={styles.profileDetails} mb={1}>{userMembership?.unionEmailAddress ?? t(`profile_update_value_missing`)}</Typography>
            </Box>
            <Box className={styles.info}>
                <Typography variant="body2" sx={labelTextLighter} className={styles.profileLabel} mb={1}>{t(`profile_update_union_start_date`)}</Typography>
                <Typography variant="subtitle1" className={styles.profileDetails} mb={1}>{formatUnionStart ?? t(`profile_update_value_missing`)}</Typography>
            </Box>
        </>
    )
}

export default UpmeProfileUpdateInfo