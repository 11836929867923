import { fetchHomePage } from '../../clients/pages'
import { fromStrapiHomePage } from '../../helpers/mapping/home-page'
import { HomePage } from '../../types'
import { MembershipType } from '../../types/MembershipType'

class HomePageRepository {

    async get(membershipType: MembershipType): Promise<HomePage> {
        const homePageContent = await fetchHomePage(membershipType)
        return fromStrapiHomePage(homePageContent)
    }
}

export default HomePageRepository