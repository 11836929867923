import { useContext, useEffect, useState } from 'react'
import { Button, Container, Grid, Typography } from '@mui/material'
import { useParams, useNavigate } from 'react-router-dom'

import { AppContext } from '../../../stores/App/AppStore'
import { UserContext } from "../../../stores/User/UserStore"
import { useMembershipRepository } from "../../../repositories/Membership/use-membership-repository"
import { useFAQRepository } from '../../../repositories/FAQ/use-faq-repository'
import { FaqEntry } from '../../../types'
import CommonBackButton from '../../atoms/CommonBackButton/CommonBackButton'
import MarkdownContent from '../../atoms/MarkdownContent/MarkdownContent'
import FAQDetailsSkeleton from './FAQDetails.skeleton'
import SplashScreenGuard from '../../../router/guards/SplashScreenGuard'
import useTrackPageView from "../../../helpers/hooks/use-track-page-view"
import useGoogleTagManager from '../../../helpers/analytics/use-google-tag-manager'
import GtmEventName from '../../../helpers/analytics/GtmEventName'

import styles from './FAQ.module.css'

const FAQDetails = () => {
    const { id: faqId } = useParams()

    const navigate = useNavigate()
    const { trackButtonClick } = useGoogleTagManager()

    const { state: appState } = useContext(AppContext)
    const { state: userState } = useContext(UserContext)
    const { retrieveFAQs } = useFAQRepository()
    const { retrieveMembership } = useMembershipRepository()

    const [faqEntry, setFaqEntry] = useState<FaqEntry>()

    useTrackPageView(() => {
        if (faqEntry) {
            return {
                title: `FAQ Entry`,
                item: faqEntry?.question,
            }
        }
    }, [faqEntry])

    const membershipType = userState.membership?.data?.type

    useEffect(() => {
        retrieveMembership()
    }, [])

    useEffect(() => {
        if (membershipType) {
            retrieveFAQs(membershipType)
        }
        window.scrollTo(0, 0)
    }, [membershipType])

    useEffect(() => {
        if (faqId && parseInt(faqId)) {
            const faqEntryFromId = appState.pages?.faqs?.find(faq => faq.id === parseInt(faqId))
            setFaqEntry(faqEntryFromId)
        }
    }, [appState.pages.faqs])

    if (!faqEntry) {
        return (<FAQDetailsSkeleton />)
    }

    const handleFaqRouteButtonClick = () => {
        if (faqEntry?.route) {
            trackButtonClick(GtmEventName.FAQ_ENTRY_ROUTE_CLICK, faqEntry.question)
            navigate(faqEntry?.route)
        } else {
            return
        }
    }

    const handleFaqExternalLinkButtonClick = () => {
        if (faqEntry.externalLink) {
            trackButtonClick(GtmEventName.FAQ_ENTRY_EXTERNAL_LINK_CLICK, faqEntry.question)
            window.open(faqEntry.externalLink,`_blank`)
        } else {
            return
        }
    }

    const faqRouteDetails = faqEntry?.route ? faqEntry?.routeButtonText : null
    const faqRouteButton = faqRouteDetails && <Button variant='contained' className={styles.routeDetails} onClick={handleFaqRouteButtonClick}>{faqRouteDetails}</Button>

    const faqExternalLinkDetails = faqEntry?.externalLink ? faqEntry?.externalLinkButtonText : null
    const faqExternalLinkButton = faqExternalLinkDetails && <Button variant='contained' className={styles.routeDetails} onClick={handleFaqExternalLinkButtonClick}>{faqExternalLinkDetails}</Button>

    return (
        <SplashScreenGuard>
            <Container className={styles.container}>
                <CommonBackButton />
                <Typography variant={`h3`} className={styles.title}>{faqEntry?.question}</Typography>
                <Grid container direction="column" spacing={2} >
                    <Grid item className={styles.gridItem}>
                        <MarkdownContent content={faqEntry?.answer} />
                    </Grid>
                    <Grid item className={styles.centered}>
                        {faqExternalLinkButton}
                        {faqRouteButton}
                    </Grid>
                </Grid>
            </Container>
        </SplashScreenGuard>
    )
}

export default FAQDetails