/* eslint-disable @typescript-eslint/no-explicit-any */

const getZodErrorMessage = (errors: any): string => errors[0].message

const getMultiZodErrorMessage = (errors: any): string => {
    let message = ``

    errors.forEach((m: any) => message = message + m.message + `\n`)

    return message.trim()
}

export {
    getZodErrorMessage,
    getMultiZodErrorMessage,
}