import { Box, Typography } from "@mui/material"
import { Pillar, isCurrentPillar, isUpcomingPillar } from "../../../types/Pillar"
import PillarCard from "../../molecules/PillarCard/PillarCard"
import { PillarCardSkeleton } from "../../molecules/PillarCard/PillarCard.skeleton"
import { usePillarRepository } from '../../../repositories/Pillar/use-pillar-repository'
import { useContext, useEffect } from "react"
import { AppContext } from "../../../stores/App/AppStore"
import { UserContext } from "../../../stores/User/UserStore"
import { useTranslation } from "react-i18next"

const PillarsList = () => {
    const { t } = useTranslation()
    const { retrievePillars } = usePillarRepository()

    const { state: appState } = useContext(AppContext)
    const { state: userState } = useContext(UserContext)

    const userMembership = userState.membership?.data

    useEffect(() => {
        if (userMembership) {
            retrievePillars(userMembership.type)
        }
    }, [userMembership])

    const sortedPillars = (inputPillars: Pillar[]) => {
        const sortedPillars = [...inputPillars].sort((a, b) => a.order - b.order)
        return sortedPillars.map((pillar) => <PillarCard pillar={pillar} key={pillar.id} />)
    }

    if (!appState.pillars.pillarsArray || appState.pillars.pillarsArray.error) {
        return (
            <Box>
                <PillarCardSkeleton />
                <PillarCardSkeleton />
            </Box>
        )
    }

    if (appState.pillars.pillarsArray.data?.length) {
        const pillars = appState.pillars.pillarsArray.data

        const currentPillars = pillars.filter(isCurrentPillar)
        const upcomingPillars = pillars.filter(isUpcomingPillar)

        const currentPillarCards = sortedPillars(currentPillars)
        const upcomingPillarCards = sortedPillars(upcomingPillars)

        return (
            <Box>
                <Typography variant='h6' mb={2} >{t(`pillar_section`)}</Typography>
                {currentPillarCards}
                {upcomingPillarCards}
            </Box>
        )
    }

    return <></>
}

export default PillarsList