import { Container, Typography, Grid, Snackbar, SnackbarContent, Slide } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useContext, useEffect, useState } from "react"
import { useParams, useNavigate } from "react-router-dom"
import CommonBackButton from "../../atoms/CommonBackButton/CommonBackButton"
import MarkdownContent from "../../atoms/MarkdownContent/MarkdownContent"
import SplashScreenGuard from "../../../router/guards/SplashScreenGuard"
import DealCard from "../../molecules/DealCard/DealCard"
import useDealRepository from "../../../repositories/Deal/use-deal-repository"
import { AppContext } from '../../../stores/App/AppStore'
import { UserContext } from "../../../stores/User/UserStore"
import DealDetailsBottomOverlay from "../../molecules/DealDetailsBottomOverlay/DealDetailsBottomOverlay"
import Spacer from "../../atoms/Spacer/Spacer"
import { Route } from '../../../router'
import { Deal, MerchantAddress } from "../../../types/Deal"
import { isAccessible } from "../../../helpers/utils/membershipScope"
import { MembershipType } from "../../../types/MembershipType"
import DealDetailsSkeleton from "../DealDetails/DealDetails.skeleton"
import useTrackPageView from "../../../helpers/hooks/use-track-page-view"

import styles from "./DealDetails.module.scss"

const DealDetails = () => {
    const { id: dealId } = useParams()

    const { t } = useTranslation()
    const { retrieveDeal } = useDealRepository()
    const navigate = useNavigate()
    const { state } = useContext(AppContext)
    const { state: userState } = useContext(UserContext)

    const [dealDetails, setDealDetails] = useState<Deal | undefined>()
    const [showToast, setShowToast] = useState(false)

    const deal = dealId && parseInt(dealId) ? state.deals?.data?.find(deal => deal.id === parseInt(dealId)) : undefined

    const userMembershipType = userState.membership?.data?.type
    const membershipType = userMembershipType === MembershipType.UPME ? MembershipType.UPME : MembershipType.STARTER
    const isPageAccessible = dealDetails && isAccessible(dealDetails?.membershipScope, membershipType)

    useTrackPageView(() => {
        if (deal) {
            return {
                title: `Deal Details`,
                item: deal.title,
            }
        }
    }, [deal])

    useEffect(() => {
        if (dealId && parseInt(dealId)) {
            retrieveDeal(parseInt(dealId))
        } else {
            navigate(Route.DEALS_LIST)
        }
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        if (deal) {
            setDealDetails(deal)
        }
    }, [deal])

    if (!deal || !isPageAccessible) {
        return (
            <SplashScreenGuard>
                <DealDetailsSkeleton />
            </SplashScreenGuard>
        )
    }

    const handleDismissToast = () => {
        setShowToast(false)
    }

    const handleMerchantWebsiteClick = () => {
        window.open(dealDetails?.merchantWebsite, `_blank`)
    }

    const merchantAddresses = (locations: MerchantAddress[]) => {
        if (locations.length === 0) {
            return null
        }

        return (
            <>
                <Typography variant="h6" className={styles.address}>{t(`dealAddress`, { count: locations.length })}</Typography>
                {locations.map((location: MerchantAddress) => <Typography mb={1} key={location.id}>{location.address}</Typography>)}
            </>
        )
    }

    const description = dealDetails?.description && (
        <Grid mt={2} mb={2} item>
            <MarkdownContent content={dealDetails?.description} />
        </Grid>
    )

    const merchantAddress = () => {
        const merchantAddress = dealDetails?.merchantAddress && merchantAddresses(dealDetails?.merchantAddress)
        const merchantWebsiteLink = dealDetails?.merchantWebsite && (<Typography className={styles.merchantWebsite} onClick={handleMerchantWebsiteClick}>{dealDetails?.merchantName}</Typography>)

        if (merchantAddress || merchantWebsiteLink) {
            return (
                <Grid mt={2} mb={2} item>
                    <Typography variant="h5" className={styles.headerDetails}>{t(`deal_merchant_info`)}</Typography>
                    {merchantWebsiteLink}
                    {merchantAddress}
                </Grid>
            )
        }

        return null
    }

    const termsAndConditions = () => {
        if (dealDetails?.termsAndConditions) {
            return (
                <Grid mt={2} mb={2} item>
                    <Typography variant="h5" className={styles.headerDetails}>{t(`deal_terms_and_conditions`)}</Typography>
                    <MarkdownContent className={styles.curatedExperienceMarkdown} content={dealDetails?.termsAndConditions} />
                </Grid>
            )
        }

        return null
    }

    const promoCodeOrExternalLink = () => {
        if (dealDetails?.promotionCode || dealDetails?.externalLink) {
            return <DealDetailsBottomOverlay deal={dealDetails} onShowToast={setShowToast} />
        }

        return null
    }

    return (
        <SplashScreenGuard>
            <Container className={styles.container}>
                <CommonBackButton />
                <DealCard deal={dealDetails} fullWidth />
                <Spacer height={24} />
                <Typography variant="h4" className={styles.headerDetails}>{dealDetails?.category.name}</Typography>

                {description}

                {merchantAddress()}

                {termsAndConditions()}

                {promoCodeOrExternalLink()}

                <Snackbar
                    anchorOrigin={{ vertical: `bottom`, horizontal: `center` }}
                    autoHideDuration={3000}
                    open={showToast}
                    TransitionComponent={Slide}
                    onClose={handleDismissToast}
                >
                    <SnackbarContent className={styles.toastContent} message={t(`promo_code_copied`)} />
                </Snackbar>
            </Container>
        </SplashScreenGuard>
    )
}

export default DealDetails