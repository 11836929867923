import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import { Languages } from './languages'
import { isProduction } from '../utils/app'

import ENGLISH_RESOURCE from './translations/en'

i18n.use(initReactI18next)
    .init({
        returnNull: false,
        lng: Languages.EN,
        fallbackLng: Languages.EN,
        debug: !isProduction,
        resources: {
            [Languages.EN]: ENGLISH_RESOURCE,
        },
    })

export default i18n