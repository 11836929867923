import { useContext, useEffect, useState } from 'react'
import { Container, Divider, Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import moment from 'moment'

import PaymentSummarySkeleton from './PaymentSummary.skeleton'
import CommonBackButton from '../../../atoms/CommonBackButton/CommonBackButton'
import ErrorDialog from '../../../molecules/ErrorDialog/ErrorDialog'
import { Route } from '../../../../router'
import { AppContext } from '../../../../stores/App/AppStore'
import { UserContext } from '../../../../stores/User/UserStore'
import { usePaymentOrderRepository } from '../../../../repositories/PaymentOrder/use-paymentOrder-repository'
import { useMembershipPlanRepository } from '../../../../repositories/MembershipPlan/use-membership-plan-repository'
import { PaymentSummaryRouteParams } from '../../../../types/PaymentSummaryRouteParams'
import { ErrorMessageVariants } from '../../../../types/ErrorMessageVariants'
import { PaymentOrderReason } from '../../../../types/PaymentOrderReason'
import { DatePatterns } from '../../../../types/date-patterns'
import { StripePaymentModal } from '../../../molecules/StripeModal/StripePaymentModal'
import MembershipPlanRepository from '../../../../repositories/MembershipPlan/membership-plan.repository'
import { useMembershipRepository } from '../../../../repositories/Membership/use-membership-repository'
import ConfirmationDialog from '../../../molecules/ConfirmationDialog/ConfirmationDialog'
import { DialogContent } from '../../../../types/DialogContent'
import { PaymentStatus } from '../../../../types/PaymentStatus'
import { MembershipStatus } from '../../../../types/MembershipStatus'
import TermsAndConditionsCheckbox from '../../../organisms/TermsAndConditionsCheckbox/TermsAndConditionsCheckbox'
import { logAction } from '../../../../clients/logger'
import { errorPopupMessageTemplate, generateErrorCode, paymentPageMessageTemplate } from '../../../../helpers/logging/templates'
import useTrackPageView from "../../../../helpers/hooks/use-track-page-view"

import styles from './PaymentSummary.module.css'

const membershipPlanRepository = new MembershipPlanRepository()

const DECIMALS = 2

const PaymentSummary = () => {
    useTrackPageView(`Payment Page`)

    const { t } = useTranslation()

    const navigate = useNavigate()
    const { createPaymentOrder } = usePaymentOrderRepository()
    const { retrieveMembership } = useMembershipRepository()
    const { retrieveMembershipPlan } = useMembershipPlanRepository()
    const { getPaymentOrder, getClientOrderIdFromLocalStorage } = usePaymentOrderRepository()

    const { state: appState } = useContext(AppContext)
    const { state: userState } = useContext(UserContext)

    const [dialogContent, setDialogContent] = useState<DialogContent>()
    const [formStarterTermsConsent, setFormStarterTermsConsent] = useState(false)
    const [dialogErrorMessageTitle, setDialogErrorMessageTitle] = useState(t(`sign_up_failure`))
    const [dialogErrorMessage, setDialogErrorMessage] = useState(``)
    const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false)
    const [hasRequestedPaymentOrder, setHasRequestedPaymentOrder] = useState(false)

    const { reason } = useParams<PaymentSummaryRouteParams>()

    const userEmail = userState.membership?.data?.emailAddress
    const membershipExchangeId = userState.membership?.data?.exchangeID
    const membershipPlanKey = membershipPlanRepository.getMembershipPlanKey()

    if (!reason) {
        throw Error(`You must provide a reason to display this page`)
    }

    useEffect(() => {
        retrieveMembershipPlan()
    }, [])

    useEffect(() => {
        retrieveMembership()
    }, [membershipPlanKey])

    useEffect(() => {
        logAction(`error`, errorPopupMessageTemplate(dialogErrorMessage, userState.membership?.data?.id?.toString() ?? `not found`))
    }, [dialogErrorMessage])

    useEffect(() => {
        if (appState.paymentOrderResult.data) {
            navigateToNextPage()
        } else if (!hasRequestedPaymentOrder) {
            createPaymentOrder(reason, membershipExchangeId, membershipPlanKey)
            setHasRequestedPaymentOrder(true)
        }
    }, [userState.membership])

    useEffect(() => {
        switch (appState.paymentOrderResult.data?.status) {
            case PaymentStatus.SUCCESS:
                retrieveMembership()
                break
            case PaymentStatus.CANCELLED:
                setDialogContent({
                    title: t(`payment_result_heading_unsuccessful`),
                    message: t(`payment_result_subheading_cancelled`),
                })
                break
            case PaymentStatus.FAILED:
                setDialogContent({
                    title: t(`payment_result_heading_unsuccessful`),
                    message: t(`payment_result_subheading_failed`),
                })
                break
            case PaymentStatus.PENDING:
                setDialogContent({
                    title: t(`payment_result_heading_unsuccessful`),
                    message: t(`payment_result_subheading_pending`),
                })
                break
            case PaymentStatus.NEW:
                retrieveMembership()
                break
        }
    }, [appState.paymentOrderResult])

    const handleOnSuccess = () => {
        logAction(`debug`, paymentPageMessageTemplate(appState.paymentOrder.data?.firstPaymentAmount.toString(), userState.membership?.data, userState.user?.data))
        const clientOrderId = getClientOrderIdFromLocalStorage()
        getPaymentOrder({ clientOrderId })
    }

    const handleOnStarterTermsConsentChange = (starterTermsConsent: boolean) => {
        setFormStarterTermsConsent(starterTermsConsent)
    }

    const showConsentDialog = () => {
        setDialogErrorMessageTitle(t(`sign_up_failure_terms_and_conditions`))
        setDialogErrorMessage(t(`sign_up_terms_and_conditions`) + ` error code: ${generateErrorCode()}`)
        setIsErrorDialogOpen(true)
    }

    const handleErrorDialogClose = () => {
        setIsErrorDialogOpen(false)
    }

    const handleOnDialogConfirm = () => {
        navigate(Route.ROOT)
    }

    const handleBackButtonClick = () => {
        window.location.assign(`${window.location.origin}`)
    }

    const paymentAmountFormatter = (paymentAmount: number): string => {
        return paymentAmount.toFixed(DECIMALS)
    }

    const navigateToNextPage = () => {
        if (userState.membership?.data?.status === MembershipStatus.TERMINATED || userState.membership?.data?.status === MembershipStatus.ACTIVE) {
            navigate(Route.MEMBERSHIP_SUCCESS)
        } else {
            navigate(Route.MEMBERSHIP_SIGN_UP)
        }
    }

    const dateStringFormatter = (dateString: string | Date): string => {
        return moment(dateString).format(DatePatterns.DATE)
    }

    if (appState.paymentOrder.error) {
        return <ErrorDialog variant={ErrorMessageVariants.PAYMENT} acknowledgeOnly defaultOpen />
    }

    if (!appState.paymentOrder.data) {
        return <PaymentSummarySkeleton />
    }

    const paymentSummaryMembership = appState.paymentOrder.data.reason === PaymentOrderReason.RENEWAL
        ? t(`payment_summary_details_membership_renewal`)
        : t(`payment_summary_details_membership`)

    const paymentSummaryAmount = paymentAmountFormatter(appState.paymentOrder.data.firstPaymentAmount)
    const startDate = dateStringFormatter(appState.paymentOrder.data.startDate)
    const endDate = dateStringFormatter(appState.paymentOrder.data.endDate)
    const cancelAt = dateStringFormatter(appState.paymentOrder.data.cancelAt)
    const stripeClientSecret = appState.paymentOrder.data?.clientSecret
    const isFirstYearFree = appState.paymentOrder.data?.firstPaymentAmount === 0

    return (
        <Container>
            <CommonBackButton onClick={handleBackButtonClick} />

            <Grid container className={styles.gridContainer}>

                <Grid className={styles.paymentSummaryHeading}>
                    <Typography variant={`h5`} mb={1}>{isFirstYearFree ? t(`payment_summary_setup_payment_method`) : t(`payment_summary_title`)}</Typography>
                    <Typography variant={`body1`}>{isFirstYearFree ? t(`payment_summary_setup_payment_method_subtitle`) : t(`payment_summary_subtitle`)}</Typography>
                </Grid>

                <Grid className={styles.paymentSummaryDetails}>
                    <Typography variant={`h6`} mb={2}>{t(`payment_summary_details`)}</Typography>
                    <Grid container direction={`row`} justifyContent={`space-between`} alignItems={`start`}>
                        <Grid className={styles.paymentSummaryDetailsLeftCol}>
                            <Typography variant={`subtitle1`}>{paymentSummaryMembership}</Typography>
                            <Typography variant={`body2`} mb={1}>{t(`payment_summary_subscription_duration`, { start: startDate, end: endDate })}</Typography>
                        </Grid>
                        <Typography variant={`subtitle1`}>{t(`payment_summary_amount`, { amount: paymentSummaryAmount })}</Typography>
                    </Grid>

                    <Divider className={styles.paymentSummaryDetailsDivider} />

                    <Grid container direction={`row`} justifyContent={`space-between`} alignItems={`start`}>
                        <Typography variant={`subtitle1`}>{t(`payment_summary_details_membership_total`)}</Typography>
                        <Typography variant={`subtitle1`}>{t(`payment_summary_amount`, { amount: paymentSummaryAmount })}</Typography>
                    </Grid>

                    <Grid container direction={`row`} justifyContent={`space-between`} alignItems={`start`} paddingTop={4} spacing={2}>
                        <Grid item>
                            <Typography variant={`body2`}>{t(`payment_summary_recurring_amount`, { recurringAmount: appState.paymentOrder.data.nextPaymentsAmounts })}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant={`body2`}>{t(`payment_summary_automatic_cancellation`, { cancelAt })}</Typography>
                        </Grid>
                    </Grid>

                    <Grid container paddingTop={4} direction={`row`}>
                        <TermsAndConditionsCheckbox starterTemsConsent={formStarterTermsConsent} onTermsConsentChange={handleOnStarterTermsConsentChange} />
                    </Grid>

                    <Grid container paddingTop={4} direction={`row`}>
                        <StripePaymentModal onConsentFalse={showConsentDialog} consent={formStarterTermsConsent} clientSecret={stripeClientSecret} email={userEmail} isFirstYearFree={isFirstYearFree} onSuccess={handleOnSuccess} />
                    </Grid>
                </Grid>
            </Grid>

            <ConfirmationDialog
                buttonText={t(`common_button_confirmation`)}
                confirmationTitle={dialogErrorMessageTitle}
                confirmationText={dialogErrorMessage}
                dismissButtonText={t(`common_back_to_home`)}
                defaultOpen={isErrorDialogOpen}
                onConfirm={handleErrorDialogClose}
                onDismiss={() => navigate(Route.ROOT)}
            />
            <ConfirmationDialog
                acknowledgeOnly
                buttonText={t(`common_button_confirmation`)}
                confirmationTitle={dialogContent?.title}
                confirmationText={dialogContent?.message}
                defaultOpen={!!dialogContent}
                onConfirm={handleOnDialogConfirm}
            />
        </Container>
    )
}

export default PaymentSummary
