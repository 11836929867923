import { HTTP_SUCCESS } from '../helpers/utils/http-status'
import { StripePaymentOrderRequest, CreatePaymentOrderRequest } from '../types/StripePaymentOrder'

import { authenticatedClient } from './util'

const createPaymentSubscription = async (paymentOrderRequest: CreatePaymentOrderRequest) => {
    return authenticatedClient()
        .post(`/api/stripe-payment/setup`, paymentOrderRequest)
        .then(res => {
            if (res.status !== HTTP_SUCCESS) {
                throw new Error(`Could not retrieve stripe payment`)
            }
            return res
        }).then(res => res.data)
}

const getStripePaymentOrder = async (order: StripePaymentOrderRequest) => {
    return authenticatedClient()
        .post(
            `/api/stripe-payment/order`,
            order,
        ).then(res => {
            if (res.status !== HTTP_SUCCESS) {
                throw new Error(`Could not get stripe payment order`)
            }
            return res
        }).then(res => res.data)
}

const getPaymentOrderHistory = async (exchangeId: string, pageSize: number) => {
    return authenticatedClient()
        .post(`api/stripe-payment/history`,
            { exchangeId, pageSize },
        ).then(res => {
            if (res.status !== HTTP_SUCCESS) {
                throw new Error(`Could not retrieve payment order history`)
            }
            return res
        }).then(res => res.data)
}

const getTemporaryCustomerPortalUrl = async () => {
    return authenticatedClient().post(`/api/stripe-payment/portal`).then(response => {
        return response.data.url
    })
}

export {
    createPaymentSubscription,
    getStripePaymentOrder,
    getPaymentOrderHistory,
    getTemporaryCustomerPortalUrl,
}