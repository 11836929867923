import { Grid, TextField, Typography } from "@mui/material"
import { t } from "i18next"
import { ChangeEvent, useState } from "react"

const ONE_ROW = 1
const TWO_ROWS = 2

export interface FreeTextInputProps {
    key: string
    isMandatory: boolean
    question: string
    multiline?: boolean
    attemptedSubmission?: boolean
    disabled?: boolean
    onChange: (text: string) => void
}

export const FreeTextInput = (props: FreeTextInputProps) => {
    const { key, isMandatory, question, multiline, attemptedSubmission, disabled, onChange } = props

    const [answer, setAnswer] = useState(``)

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        setAnswer(e.target.value)
        onChange(e.target.value)
    }

    const isInErrorState = isMandatory && !answer && attemptedSubmission
    const helperText = isInErrorState ? t(`event_required_field`) : null
    const placeholderText = t(`questionnaire_free_text_answer_placeholder`)
    const rows = multiline ? TWO_ROWS : ONE_ROW

    return (
        <Grid container direction="column" mb={3}>
            <Grid item xs={12}>
                <Typography variant="subtitle1">
                    {question} {isMandatory && `*`}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    key={key}
                    error={isInErrorState}
                    helperText={helperText}
                    value={answer}
                    multiline={multiline}
                    rows={rows}
                    onChange={handleInputChange}
                    color='functional'
                    placeholder={placeholderText}
                    disabled={disabled}
                    fullWidth
                />
            </Grid>
        </Grid>

    )
}