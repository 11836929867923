import { fetchProgrammes } from "../../clients/programmes"
import { fromStrapiProgramme } from "../../helpers/mapping/programme"
import { Programme } from "../../types"
import { MembershipType } from "../../types/MembershipType"

class ProgrammeRepository {

    async retrieveAllProgrammes(membershipType: MembershipType): Promise<Programme[]> {
        const { data } = await fetchProgrammes(membershipType)
        const programmes = data.map((i: unknown) => fromStrapiProgramme(i))

        const sortedProgrammes = programmes.sort((a: Programme, b: Programme) => {
            if (a.order === b.order) {
                return a.name.localeCompare(b.name)
            }

            if (a.order === 0) {
                return 1
            }

            if (b.order === 0) {
                // eslint-disable-next-line no-magic-numbers
                return -1
            }

            return a.order - b.order
        })

        return sortedProgrammes
    }
}

export default ProgrammeRepository