import { anonymousClient } from './util'
import { HTTP_SUCCESS } from '../helpers/utils/http-status'
import { logAction } from './logger'

const fetchBank = async () => {
    return anonymousClient()
        .get(
            `/api/banks`,
        ).then(res => {
            logAction(`info`, `fetched bank data`)
            if (res.status !== HTTP_SUCCESS) {
                logAction(`error`, `Error fetching bank data`)
                throw new Error(`Could not retrieve bank data`)
            }
            return res
        }).then(res => res.data)
}

export {
    fetchBank,
}