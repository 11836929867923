import { useContext } from 'react'

import { AppContext } from '../../stores/App/AppStore'
import { ActionTypes } from '../../stores/App/AppReducer'
import JoinPageRepository from './join-page.repository'

const useJoinPageRepository = () => {
    const { dispatch } = useContext(AppContext)
    const joinPageRepository = new JoinPageRepository()

    const retrieveJoinPage = () => {
        joinPageRepository.get()
            .then((data) => {
                dispatch({ type: ActionTypes.PAGE_JOIN_RETRIEVE, data })
            })
            .catch((error) => {
                console.error(error.message)
            })
    }

    return {
        retrieveJoinPage,
    }
}

export { useJoinPageRepository }