import React from 'react'
import { Box, Card, CardActionArea, CardContent, CardMedia, Grid } from '@mui/material'
import Typography from '@mui/material/Typography'
import { CuratedExperienceDetail } from '../../../types'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Route } from '../../../router'
import useGoogleTagManager from "../../../helpers/analytics/use-google-tag-manager"
import GtmEventName from "../../../helpers/analytics/GtmEventName"

import styles from './CuratedExperienceCard.module.css'

type CuratedExperienceProps = {
    curatedExperience: CuratedExperienceDetail,
}

const CuratedExperienceCard: React.FC<CuratedExperienceProps> = ({ curatedExperience }) => {
    const { t } = useTranslation()

    const { trackCardClick } = useGoogleTagManager()
    const { startDate, endDate, headerImage, title, shortDescription } = curatedExperience

    const dateFormatter = (date: Date, monthFormat: 'short' | 'long' = `short`): string => {
        return date.toLocaleDateString(`en-GB`, { year: `numeric`, month: monthFormat, day: `numeric` })
    }

    const validityFormatter = (): string => {
        if (startDate && endDate) {
            return t(`valid_from_till`, { startDate: dateFormatter(startDate), endDate: dateFormatter(endDate) })
        } else if (startDate) {
            return t(`valid_from`, { startDate: dateFormatter(startDate) })
        } else if (endDate) {
            return t(`valid_until`, { endDate: dateFormatter(endDate) })
        } else {
            return ``
        }
    }

    const handleCardClick = () => {
        trackCardClick(GtmEventName.CURATED_EXPERIENCES_CARD_CLICK)
    }

    return <Link className={styles.relative} to={`${Route.PILLARS_CURATED_EXPERIENCE}/${curatedExperience.id}`} onClick={handleCardClick}>
        <Grid item mb={3}>
            <Box className={styles.relative}>
                <CardActionArea>
                    <Card elevation={2} className={styles.curatedExperienceCardBox}>
                        <CardMedia
                            component="img"
                            className={styles.curatedExperienceCardMedia}
                            image={headerImage}
                        />
                        <CardContent>
                            <Typography variant="overline" className={styles.overline}>{validityFormatter()}</Typography>
                            <Typography variant="h6" className={styles.title}>{title}</Typography>
                            <Typography variant="body2" className={styles.body2}>{shortDescription}</Typography>
                        </CardContent>
                    </Card>
                </CardActionArea>
            </Box>
        </Grid>
    </Link>
}

export default CuratedExperienceCard
export { CuratedExperienceCardSkeleton } from './CuratedExperienceCard.skeleton'