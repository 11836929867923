import { useContext, useEffect, useRef, useState } from 'react'
import { Container, Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { Route } from '../../../../router'
import { AppContext } from '../../../../stores/App/AppStore'
import { UserContext } from '../../../../stores/User/UserStore'
import { usePaymentOrderRepository } from '../../../../repositories/PaymentOrder/use-paymentOrder-repository'
import { PaymentHistoryResult } from '../../../../types'
import CommonBackButton from '../../../atoms/CommonBackButton/CommonBackButton'
import MyActivityCard from '../../../molecules/MyActivityCard/MyActivityCard'
import ErrorDialog from '../../../molecules/ErrorDialog/ErrorDialog'
import MyActivitySkeleton from './MyActivity.skeleton'
import SplashScreenGuard from '../../../../router/guards/SplashScreenGuard'
import { logAction } from '../../../../clients/logger'
import { errorPopupMessageTemplate, generateErrorCode } from '../../../../helpers/logging/templates'
import useTrackPageView from '../../../../helpers/hooks/use-track-page-view'

import styles from './MyActivity.module.css'

const DEFAULT_PAGE_SIZE = 20

const MyActivity = () => {
    useTrackPageView(`My Activity Page`)

    const { t } = useTranslation()

    const { state } = useContext(AppContext)
    const { state: userState } = useContext(UserContext)

    const { getPaymentOrderHistory } = usePaymentOrderRepository()
    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE)
    const [sentinelRef, setSentinelRef] = useState(null)
    const lastItemRef = useRef(null)

    const paymentOrderHistory: PaymentHistoryResult[] | undefined = state.paymentOrderHistory.data

    useEffect(() => {
        getPaymentOrderHistory(userState.membership?.data?.exchangeID ?? ``, pageSize)
    }, [pageSize])

    useEffect(() => {
        if (sentinelRef) {
            setPageSize((prevPageSize) => prevPageSize + DEFAULT_PAGE_SIZE)
        }
    }, [sentinelRef])

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setSentinelRef(lastItemRef.current)
                }
            },
        )

        if (lastItemRef.current) {
            observer.observe(lastItemRef.current)
        }

        return () => {
            if (lastItemRef.current) {
                observer.unobserve(lastItemRef.current)
            }
        }
    }, [paymentOrderHistory])

    if (state.paymentOrderHistory.error) {
        logAction(`error`, errorPopupMessageTemplate(state.paymentOrderHistory.error.errorMessage + ` error code: ${generateErrorCode()}`,
            userState.membership?.data?.id?.toString() ?? `not found`))
        return <ErrorDialog acknowledgeOnly defaultOpen />
    }

    return (
        <SplashScreenGuard>
            <MyActivitySkeleton condition={!!paymentOrderHistory}>
                <Grid className={styles.pageContainer}>
                    <Container className={styles.myActivityHeading}>
                        <CommonBackButton disableLabel path={Route.HOME} />
                        <Typography variant={`h6`}>{t(`profile_my_activity`)}</Typography>
                    </Container>

                    {
                        paymentOrderHistory?.length === 0 &&
                        (
                            <Container className={styles.errorContainer}>
                                <Typography variant={`subtitle1`}>{t(`profile_my_activity_empty_title`)}</Typography>
                                <Typography variant={`body1`}>{t(`profile_my_activity_empty_description`)}</Typography>
                            </Container>
                        )
                    }

                    <Container className={styles.gridContainer}>
                        {
                            paymentOrderHistory?.map((paymentOrderTransaction: PaymentHistoryResult, index: number) => {
                                if (index === paymentOrderHistory.length - 1) {
                                    return <MyActivityCard paymentOrderTransaction={paymentOrderTransaction} key={paymentOrderTransaction.clientOrderId} reference={lastItemRef} />
                                }
                                return <MyActivityCard paymentOrderTransaction={paymentOrderTransaction} key={paymentOrderTransaction.clientOrderId} />
                            })
                        }
                    </Container>
                </Grid>
            </MyActivitySkeleton>
        </SplashScreenGuard>
    )
}

export default MyActivity