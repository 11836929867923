import { anonymousClient } from './util'
import { HTTP_SUCCESS } from '../helpers/utils/http-status'

const fetchVersion = async () => {
    return anonymousClient()
        .get(
            `/api/version`,
        ).then(res => {
            if (res.status !== HTTP_SUCCESS) {
                throw new Error(`Could not retrieve app version`)
            }
            return res
        }).then(res => res.data)
}

export {
    fetchVersion,
}