import react, { useEffect, useState } from "react"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import { TransitionProps } from "@mui/material/transitions"
import { Button, Zoom } from "@mui/material"
import { useTranslation } from "react-i18next"
import useGoogleTagManager from '../../../helpers/analytics/use-google-tag-manager'

import styles from './AlertDialog.module.css'

const Transition = react.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<string, string>;
    },
    ref: React.Ref<unknown>,
) {
    return <Zoom ref={ref} {...props} />
})

export interface AlertDialogProps {
    alertText?: string
    buttonText?: string
    shouldShow?: boolean
    onClose?: () => void
}

const AlertDialog = (props: AlertDialogProps) => {
    const { t } = useTranslation()
    const { trackAlertDialogClose, trackAlertDialogOpen } = useGoogleTagManager()

    const {
        alertText,
        buttonText = t(`common_button_confirmation`),
        shouldShow = false,
        onClose,
    } = props

    const [isVisible, setIsVisible] = useState(shouldShow)

    useEffect(() => {
        if (isVisible) {
            trackAlertDialogOpen(alertText)
        }
    }, [isVisible])

    useEffect(() => {
        setIsVisible(shouldShow)
    }, [shouldShow])

    const handleClose = () => {
        trackAlertDialogClose(alertText)
        onClose?.() ?? setIsVisible(false)
    }

    return (
        <Dialog open={isVisible} TransitionComponent={Transition} onClose={handleClose}>
            <DialogContent >
                <DialogContentText className={styles.description}>
                    {alertText}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={handleClose} fullWidth>{buttonText}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default AlertDialog
