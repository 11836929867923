import { HTTP_SUCCESS } from "../helpers/utils/http-status"
import { MembershipType } from "../types/MembershipType"
import { anonymousClient } from "./util"

const fetchProgrammes = async (membershipType: MembershipType) => {
    return anonymousClient()
        .get(
            `/api/programmes?populate[bannerImage]=*&filters[$or][0][membershipScope]=ALL&filters[$or][0][membershipScope]=${membershipType}`,
        ).then(res => {
            if (res.status !== HTTP_SUCCESS) {
                throw new Error(`Could not retrieve programmes`)
            }
            return res
        }).then(res => res.data)
}

export {
    fetchProgrammes,
}
