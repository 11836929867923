const translation = {
    about_starter: `What's Starter?`,
    about_view_all: `View All`,
    pillar_section: `Our offerings`,
    upcoming: `Coming Soon`,
    activate_membership: `Activate your membership!`,
    alert_access_header: `Sign up or log in to access this offering.`,
    alert_access_text: `Become a {{type}} member and enjoy an amazing variety of offerings!`,
    alert_access_text_membership_starter: `Starter`,
    alert_access_text_membership_upme: `Union`,
    benefit_available: `Available`,
    benefit_unavailable: `Not available`,
    cancel_membership: `Cancel Membership`,
    cancel_membership_true: `Cancel my membership`,
    cancel_membership_failed: `Failed to cancel membership. Please try again later.`,
    cancel_membership_content: `Please contact us at youngntuc@ntuc.org.sg to request for membership cancellation`,
    clipboard_write_text_error: `An error occured while copying text`,
    clipboard_write_text_no_permission: `Browser permission not set`,
    common_placeholder: `Enter`,
    common_button_confirmation: `OK`,
    common_back_to_home: `Back to Home`,
    common_try_again: `Try again`,
    common_contact_us: `Contact us`,
    common_see_all: `See all`,
    common_know_more: `Know More`,
    common_error_message: `Something went wrong :(\n\nPlease try again later.`,
    common_validation_error_phone_number: `Must be a valid Singapore phone number`,
    date: `Date`,
    apply: `Apply`,
    deals_and_promotions: `Deals & Promotions`,
    deals_validity_full: `Valid from {{ start }} till {{ end }}`,
    deals_validity_start_only: `Valid from {{ start }}`,
    deal_external_link_default_name: `Find out more`,
    deal_merchant_info: `Merchant Website`,
    deal_terms_and_conditions: `Terms & Conditions`,
    dealAddress_one: `Location:`,
    dealAddress_other: `Locations:`,
    draft_sign_up_title: `Resume Signup`,
    draft_sign_up_subtitle: `It seems you haven't complete your signup yet. Complete it now.`,
    draft_sign_up_title_terminated: `Welcome back to NTUC Starter!`,
    draft_sign_up_subtitle_terminated: `Please complete the signup form with your updated details.`,
    draft_sign_up_update_information: `Update information`,
    draft_sign_up_continue_application: `Continue application`,
    error_default_title: `It's lemonade time!`,
    error_default_message: `Life just threw us some lemons. Please try to log in again later.`,
    error_unknown: `An unknown error has occurred`,
    error_uin_not_matching_user: `UIN/NRIC does not match user's. Please make sure you use your own Singpass account for login and MyInfo.`,
    error_user_is_union_member: `You're a NTUC Union Member! STARTER is good, but you're already on something great. If you think this is an error, please reach us at [youngntuc@ntuc.org.sg](mailto:youngntuc@ntuc.org.sg).`,
    error_user_age_invalid: `You must be between 18 to 25 to become a Starter member. If you are already 25 years old or above, click on the button below to become a NTUC Member today!`,
    error_email_already_used: `The email address associated to your Singpass account is already used. Please log in with another account or reach us at [youngntuc@ntuc.org.sg](mailto:youngntuc@ntuc.org.sg).`,
    error_union_email_already_used: `The email address associated to your Union Membership is already used is our system. Please log in with another account or reach us at [youngntuc@ntuc.org.sg](mailto:youngntuc@ntuc.org.sg).`,
    event_page_inaccessible: `Sorry this page is not accessible.`,
    event_registration: `Registration`,
    event_registration_message: `We would like to know you better!`,
    event_register: `Register`,
    event_external_link: `Find out more`,
    event_required_field: `required`,
    event_back_to_events: `Back to events list`,
    event_all_list: `All`,
    event_registered: `Registered`,
    event_registered_list: `Registered Events`,
    event_registration_sign_up_error: `You have already signed up for this event before.`,
    event_registration_not_open: `Sorry, this event registration is not open for registration.`,
    event_registration_deadline_past: `Sorry, this event registration has already closed.`,
    event_registration_success_title: `Congratulations!`,
    event_registration_success: `You are registered for this event. Please await for further details closer to event date.`,
    event_registration_instruction: `You are registered for this event.`,
    event_registration_closed: `Registration has closed.`,
    event_registration_not_accessible_membership_type: `This event is not accessible for your membership type`,
    event_registration_terminated_member_message: `Your membership has been terminated and you will need to sign up again.`,
    event_registration_terminated_member_error: `You are not an active Starter Member.`,
    event_show_map: `show map`,
    event_no_address_placeholder: `To be confirmed`,
    event_no_registered_events: `There is currently no registered events.`,
    events_and_trainings: `Events & Trainings`,
    filter_by: `Filter By{{ colon }}`,
    filter_by_all: `All`,
    file_input_mandatory: `This file is mandatory. Please select a file.`,
    file_too_large: `File size is too large`,
    file_type_not_allowed: `File type is not allowed`,
    join_title: `Join as a member now`,
    join_description: `With Starter, you can accelerate your career and life. The Starter app has helped more than 2 million people achieve their goals!`,
    join_create_account: `Create your account`,
    join_become_member: `Become a member`,
    join_myInfo_title: `We will require the following information from MyInfo as part of the signup:`,
    join_myInfo_field1: `   • Name as per NRIC/Passport`,
    join_myInfo_field2: `   • Date of Birth`,
    join_myInfo_field3: `   • Gender`,
    join_myInfo_field4: `   • Residential Status`,
    join_myInfo_field5: `   • Phone Number`,
    join_myInfo_field6: `   • Email Address`,
    join_myInfo_field7: `   • Postal Code`,
    landing_page_learn_more: `Learn More`,
    landing_page_sign_up: `Sign Up Now!`,
    landing_page_login: `Already a member? Login here`,
    landing_page_footer_offer: `Enjoy an amazing variety of offerings`,
    landing_page_discover_starter: `Discover NTUC Starter`,
    linkpass_retrieving_title: `Authenticating...`,
    linkpass_retrieving_message: `Please wait while we are logging you in`,
    linkpass_retrieving_error_title: `It's lemonade time!`,
    linkpass_retrieving_error_message: `Life just threw us some lemons. Please try to log in again later.`,
    maintenance_page_title: `NTUC Starter is currently under maintenance.`,
    maintenance_page_message: `Life is a marathon, not a sprint. Take a break and please come back later.`,
    main_menu_about_starter: `What's Starter`,
    main_menu_about_upme: `What's NTUC Membership?`,
    main_menu_explore_offers: `Explore Our Offerings`,
    main_menu_programmes: `Programmes`,
    main_menu_resources: `Resources`,
    member_union: `NTUC member`,
    member_starter: `Starter member`,
    member_upme: `NTUC member`,
    membership_type_starter: `Starter`,
    membership_type_upme: `NTUC`,
    more_info: `More info`,
    my_events: `Your events`,
    my_events_no_events: `You have no upcoming events. Register for an event or training.`,
    myinfo_missing_required_information: `We need your valid ID number, name and date of birth from MyInfo to proceed. Please check in with Singpass to verify that your information is correct before trying again.`,
    myinfo_retrieval_error_title: `Oops!`,
    myinfo_retrieval_error_message: `We were unable to retrieve your information on MyInfo. Please try again later.`,
    myinfo_retrieval_error_invalid_age_title: `Are you below {{ minAge }} or above {{ maxAge }}?`,
    myinfo_retrieval_error_invalid_age: `If you are continuing or have the intention to continue with your full-time studies, please write into youngntuc@ntuc.org.sg for us to assess your membership application!`,
    myinfo_retrieving_title: `Getting data...`,
    myinfo_retrieving_message: `Please wait while we are retrieving your information from MyInfo`,
    payment_confirmation_dialog_message: `You will be redirected to an external website to proceed to payment. Please do not navigate back or close your browser.`,
    payment_confirmation_dialog_button: `Got it!`,
    payment_result_reference_text: `Reference no.`,
    payment_result_heading_unsuccessful: `Your membership payment is unsuccessful`,
    payment_result_heading_pending: `Your payment is pending`,
    payment_result_subheading_cancelled: `Your payment was cancelled. Please try again.`,
    payment_result_subheading_failed: `We were not able to complete your payment. Please try again.`,
    payment_result_subheading_pending: `You will receive an email within 24 hours when your status is updated`,
    payment_summary_automatic_cancellation: `Your membership will be automatically cancelled on {{ cancelAt }}.`,
    payment_summary_setup_payment_method: `Setup Payment Method`,
    payment_summary_setup_payment_method_subtitle: `Please setup your card payment method for automatic renewal of your membership.`,
    payment_summary_title: `Proceed to payment`,
    payment_summary_subtitle: `Please check your payment details`,
    payment_summary_details: `Payment details`,
    payment_summary_details_membership: `NTUC Starter Membership`,
    payment_summary_details_membership_renewal: `NTUC Starter Membership renewal`,
    payment_summary_details_membership_total: `Total`,
    payment_summary_amount: `\${{ amount }}`,
    payment_summary_subscription_duration: `{{ start }} - {{ end }}`,
    payment_summary_order_failure: `Something went wrong :(\n\nPlease try completing the payment in a short while!`,
    payment_summary_recurring_amount: `Your membership will be automatically renewed every year at the rate of \${{ recurringAmount }} until cancellation.`,
    profile_upme_benefits: `View NTUC Membership Benefits`,
    profile_entry_profile_update: `Update your profile`,
    profile_entry_utap_balance: `UTAP Balance: $\{{ balance }}`,
    profile_entry_utap_balance_tentative: `UTAP Balance: $200 (Tentative)`,
    profile_entry_activity_history: `View Activity History`,
    profile_entry_subscription: `Manage Subscription`,
    profile_entry_log_out: `Log Out`,
    profile_terminated: `Terminated`,
    profile: `Profile`,
    profile_complete_your_application: `Complete your application`,
    profile_become_a_member: `Become a member`,
    profile_manage_subscription: `Manage my subscription`,
    profile_my_activity: `My activity`,
    profile_my_activity_empty_title: `No membership activity yet`,
    profile_my_activity_empty_description: `View your membership transactions here`,
    profile_my_activity_transaction_reason: `{{ reason }} - STARTER Membership`,
    profile_my_activity_transaction_reason_short: `{{ reason }}`,
    profile_my_activity_transaction_retrieval_error: `Unable to retrieve transaction details. Please try again later`,
    profile_my_activity_transaction_no_match_error: `Payment order transaction does not exist. Please try again later`,
    profile_my_activity_transaction_go_back: `Back to My Activity`,
    profile_picture_select_file: `Choose a picture`,
    profile_picture_change_file: `Change picture`,
    profile_picture_successfully_updated: `Your profile picture has been updated successfully!`,
    profile_picture_file_too_big_error_message: `The profile picture you've selected is too big. Please choose another one.`,
    profile_join_again: `Join again`,
    profile_referral_text: `Refer your friends: Code `,
    profile_referral_modal_title: `Refer your friends`,
    profile_referral_modal_description: `Share the good vibes with your friends! Let them enjoy all the benefits of the NTUC Starter Membership!`,
    profile_referral_modal_explanation: `Touch the button below to copy the invitation and paste it in your social media to spread something.`,
    profile_referral_modal_copy_button: `Copy Invitation`,
    profile_referral_modal_copy_snackbar: `Invitation copied`,
    profile_referral_modal_invitation: `Hey! I thought you might be interested in joining the NTUC Starter Membership programme. Subscribe to enjoy great benefits. Use my referral code {{ referralCode }} or click the link below to sign up now!\n\n{{ link }}`,
    profile_check_signup_status: `Check signup status`,
    profile_become_union_member: `Become a Union member`,
    profile_years: `{{ num }} years`,
    profile_one_year: `Less than 1 year`,
    profile_update_button: `Update profile`,
    profile_interests: `Your Interests`,
    profile_interests_button: `Update interests`,
    profile_update_upme_link: `Update your union profile`,
    profile_update_interests_none: `You have not updated your interests. Update now!`,
    profile_update_union_start_date: `Union Membership Start Date`,
    profile_update_value_missing: `Unavailable`,
    profile_upgrade_card_title: `Upgrade to NTUC Union Member`,
    profile_upgrade_card_content: `Receive even more benefits when you upgrade to a Union Member.`,
    programme_application_form_not_found_title: `Form not found`,
    programme_application_form_not_found_message: `The form you are trying to access does not exist`,
    programme_application_already_submitted_title: `Application already sent`,
    programme_application_already_submitted_message: `You have already submitted an application through this form and cannot submit another one.`,
    programme_application_file_too_big_title: `File is too heavy`,
    programme_application_file_too_big_message: `One of the files you've selected is too big. Please select files that are no more than 10MB.`,
    programme_application_membership_not_active_title: `Membership is not active`,
    programme_application_membership_not_active_message: `Your membership is not active. Please signup again to enjoy this benefit.`,
    programme_application_has_errors_message: `There are some errors in your form. Please check them and try to submit again.`,
    programme_application_submission_success_title: `Application received!`,
    programme_application_submission_success_message: `We've received your application and will get in touch with you soon.`,
    programme_application_form_submit: `Send`,
    programme_application_form_home_list_title: `Apply for benefits`,
    programme_application_form_submitted: `Applied`,
    programme_application_form_terms_and_conditions: `Terms & Conditions`,
    prompt_for_graduated_profile: `If you are working, please upgrade now to enjoy NTUC membership benefits.`,
    prompt_for_graduated_profile_button: `Upgrade now`,
    prompt_update_profile: `Update your profile to help us suggest offerings that suits your preferences!`,
    prompt_become_union_member_6_months_to_expiry: `Upgrade to NTUC Member. Your Starter membership is expiring soon. Please upgrade now to enjoy NTUC Membership benefits.`,
    prompt_become_union_member_25_and_expired: `Upgrade to NTUC Member. Your Starter membership has expired. Please upgrade now to enjoy NTUC Membership benefits.`,
    prompt_become_union_member_button: `View benefits`,
    prompt_upgrade_to_recurring_payment: `Upgrade to recurring subscription now!`,
    prompt_resume_recurring_payment: `Resume your Starter subscription now!`,
    pwa_instructions_title: `Save {{type}} to your home screen`,
    pwa_instructions_message: `Here's how to have a better experience`,
    pwa_instructions_ios_1: `Tap`,
    pwa_instructions_ios_2: `then “Add to Home screen”`,
    pwa_instructions_android_1: `Tap`,
    pwa_instructions_android_2: `then “Add to Home screen”`,
    pwa_instructions_close: `Close`,
    reference: `Reference`,
    renewal_updated_expiry: `Updated expiry date:`,
    recurring_sign_up_button: `Subscribe now`,
    recurring_resuming_error: `Please contact support via youngntuc@ntuc.org.sg to resume your membership`,
    recurring_resuming_button: `Resume now`,
    recurring_resuming_text: `We will resume your membership subscription and your membership expiry date will be on {{expiryDate}}. Do you want to continue?`,
    summary: `Summary`,
    select_file: `Select File`,
    sign_up_missing_myinfo: `We need your valid ID number, name and date of birth from MyInfo to proceed. Please check in with Singpass to verify that your information is correct before trying again.`,
    sign_up_missing_myinfo_fields: `We need your valid ID number, name and date of birth from MyInfo to proceed. Please check in with Singpass to verify that your information is correct before trying again.`,
    sign_up_title: `Personal & Contact Details`,
    sign_up_name: `Name as per NRIC / Passport`,
    sign_up_birthdate: `Date of Birth`,
    sign_up_valid_age_warning: `Must be 18 to 25 years old`,
    sign_up_gender: `Gender`,
    sign_up_resident_status: `Residential Status`,
    sign_up_phone_number: `Phone Number`,
    sign_up_email_address: `Email Address`,
    sign_up_postal_code: `Postal Code`,
    sign_up_postal_code_wrong_format: `Postal Code must be 5 or 6 digits`,
    sign_up_block_house_number: `Block / House number`,
    sign_up_block_street_name: `Street Name`,
    sign_up_block_floor_number: `Floor Number`,
    sign_up_block_unit_number: `Unit Number`,
    sign_up_block_building_name: `Building Name`,
    sign_up_school: `Current school / institution`,
    sign_up_years_left: `Year(s) before Graduation / ORD`,
    sign_up_field_of_study: `Field of study`,
    sign_up_name_ecard: `Name on e-card`,
    sign_up_consent_starter_terms_1: `By clicking here, I acknowledge that I have read, understood and I agree to the `,
    sign_up_consent_starter_terms_2: `terms and conditions.`,
    sign_up_referral_code: `Referral code`,
    sign_up_success_title: `Congratulations! You are now a member`,
    sign_up_terms_and_conditions: `To get STARTED, you'll have to agree to the Terms and Conditions. Let's go!`,
    sign_up_success_description: `Your membership is active and you can start to utilise your benefits now.`,
    sign_up_success_explore: `Explore other benefits`,
    sign_up_success_explore_1: `Get these benefits when you sign up for the new NTUC Link Credit card with`,
    sign_up_success_explore_1_2: `! NTUC Union members can now save up to 21% when they shop at FairPrice, Kopitiam, Cheers, Unity & more!`,
    sign_up_success_explore_2: `Protect yourself with`,
    sign_up_failure: `Oops!`,
    sign_up_failure_terms_and_conditions: `Please…`,
    sign_up_confirmation_signup: `Signup`,
    sign_up_confirmation_continue: `Continue`,
    simple_faq_title: `Frequently Asked Questions`,
    skip: `Skip`,
    starter_onboarding_welcome_button: `Get started`,
    stripe_payment_failure: `Payment failed.`,
    stripe_proceed_to_pay: `Proceed to pay subscription`,
    stripe_proceed_to_setup_card_payment: `Proceed to setup card payment`,
    submit: `Submit`,
    terms: `Terms`,
    programmes: `Programmes`,
    programme_back: `Back`,
    programme_join: `Join The Programme`,
    promo_code: `Promo code`,
    promo_code_copied: `Promo code copied`,
    unlock_deal: `Want to unlock this deal?`,
    copy: `Copy`,
    book_now: `Book now`,
    sign_up: `Sign up`,
    sign_in: `Sign in`,
    valid_from: `Valid from {{startDate}}`,
    valid_until: `Valid until {{endDate}}`,
    valid_from_till: `Valid from {{startDate}} till {{endDate}}`,
    membership_card_title: `Starter membership`,
    membership_card_subtitle_1: `Present your e-card at our`,
    membership_card_subtitle_2: `NTUC partners`,
    membership_card_subtitle_3: `to enjoy deals.`,
    membership_card_pending_payment_message: `Your membership is pending payment validation`,
    membership_card_pending_payment_instructions: `Please touch the button below to check your signup status`,
    membership_retrieving_title: `Retrieving membership information`,
    membership_retrieving_message: `Please wait while we are retrieving your membership's information.`,
    age: `Age`,
    membership_expiry: `Membership expiry`,
    membership_plans: `Membership plans`,
    regular: `Regular`,
    sign_up_date: `Sign Up Date`,
    close: `Close`,
    membershipPlanUsageLimitHit: `This membership plan has been fully utilized! If you have any inquiries, please feel free to reach out to us at youngntuc@ntuc.org.sg.`,
    membershipPlanUsageLimitHitButton: `Proceed to Signup without Membership Plan`,
    confirm: `Confirm`,
    login_or_sign_up: `Login or Sign Up!`,
    logout: `Logout`,
    logout_starter: `Log out from STARTER`,
    logout_message: `Are you sure you want to log out?`,
    logout_dismiss: `No, I'm good`,
    logout_confirm: `Log me out`,
    logout_from_linkpass: `Also logout from Singpass`,
    curated_experience_see_more: `See more`,
    about_young_ntuc: `About Young NTUC`,
    about_myntuc: `About MyNTUC`,
    application_terms_of_use_link: `Terms of use`,
    application_terms_of_use_title: `Terms of use`,
    faq: `FAQ`,
    privacy_policy: `Privacy policy`,
    membership_suspended_title: `Oh bummer!`,
    membership_suspended_description: `Please contact support via youngntuc@ntuc.org.sg to reactivate your membership.`,
    multipleChoiceQuestionOptionsPlease: `Please select `,
    multipleChoiceQuestionOptionsAnd: ` and `,
    multipleChoiceQuestionOptionsMinCount_one: `{{count}} option min.`,
    multipleChoiceQuestionOptionsMinCount_other: `{{count}} options min.`,
    multipleChoiceQuestionOptionsMaxCount_one: `{{count}} option max.`,
    multipleChoiceQuestionOptionsMaxCount_other: `{{count}} options max.`,
    other_ntuc_memberships: `Other NTUC memberships`,
    expired: `EXPIRED`,
    proceed: `Proceed`,
    consent: `Consent`,
    cancel: `Cancel`,
    reload: `REFRESH`,
    existing_union_membership: `It seems you are already an existing NTUC member. To view NTUC member perks, please visit www.ntuc.org.sg for more details on your membership and it's benefits!`,
    home_page_sign_in: `Login or Sign up!`,
    home_page_register_membership: `Create your STARTER account`,
    home_page_check_signup_status: `Check signup status`,
    home_page_complete_your_application: `Complete your application!`,
    home_page_come_back: `Come back!`,
    questionnaire_free_text_answer_placeholder: `Type your answer`,
    upme_benefits_list_title: `NTUC vs Starter benefits`,
    upme_update_now_footer_title: `Upgrade at $0.32 per day`,
    upme_update_now_footer_subtitle: `Or $117 per year`,
    upme_update_now_footer_button: `Upgrade now`,
    upme_interests_title: `Customise your member experience`,
    upme_interests_subtitle: `Select up to 3 types of benefits that interest you the most.`,
    upme_interests_button: `OK`,
    upme_welcome_title: `Hi {{name}}`,
    upme_welcome_message_line_1: `Discover a myriad of exclusive benefits, PME-related contents and dive into a world of possibilities with NTUC membership today!`,
    upme_welcome_button: `Get Started`,
    upme_digital_resource_external_link: `Read More`,
    upme_digital_resource_title: `Resources`,
    upme_digital_resource_see_all: `See all`,
    upme_digital_resource_category: `{{category}}`,
    utap_landing_claim: `Claim`,
    utap_landing_become_a_member: `Become a member`,
    utap_landing_ineligible_claim_message: `Unable to make Utap claim. {{remarks}}`,
    utap_landing_cannot_claim_message: `You have reached the maximum claimable amount`,
    utap_landing_cannot_claim_button: `OK`,
    utap_claim_title: `Utap Claim`,
    utap_claim_application_title: `Application Details`,
    utap_claim_course_title: `Course Details`,
    utap_claim_supporting_doc_title: `Upload Supporting Documents`,
    utap_claim_bank_account_title: `Bank Account Information`,
    utap_claim_course_attended: `I have attended 75% of the total course hours.`,
    utap_claim_course: `Course Name`,
    utap_claim_training_provider: `Training Provider`,
    utap_claim_course_start_date: `Start Date`,
    utap_claim_course_end_date: `End Date`,
    utap_claim_course_fee: `Nett Fee Paid`,
    utap_claim_course_fee_invalid: `Must be a valid amount`,
    utap_claim_course_fee_minimum: `Must be {{minimum}} minimum`,
    utap_claim_proof_of_payment: `Proof of Payment`,
    utap_claim_proof_of_completion: `Proof of Completion`,
    utap_claim_bank: `Bank Name`,
    utap_claim_account_holder_name: `Account Holder Name`,
    utap_claim_account_number: `Account Number`,
    utap_claim_bank_book_cover_statement: `Bank Book Cover/Bank Statement `,
    utap_claim_success_title: `Your claim has been submitted`,
    utap_claim_success_description: `We will process your claims within a period of 8 weeks. If you have any inquiries, please feel free to reach out to us at youngntuc@ntuc.org.sg.`,
    utap_claim_success_error_message: `Something went wrong :(\n\nPlease try submitting the claim again in a short while!`,
    utap_claim_enhanced_500_error_message: `\n\nPlease try again in an hour. If you have any inquiries, please reach out to us at youngntuc@ntuc.org.sg.`,
    utap_claim_enhanced_400_error_message: `\n\nPlease try again. If you have any inquiries, please reach out to us at youngntuc@ntuc.org.sg.`,
    utap_claims: `My claims`,
    utap_claims_empty_title: `You don't have any claims yet`,
    utap_claims_empty_description: `View your utap claims here`,
    union_membership_nric_mismatch_error: `You retrieved MyInfo information for a user that does not belong to connected member.`,
    union_introduction_page_title: `Why upgrade to NTUC membership?`,
    user_became_union_member_title: `Congratulations!`,
    user_became_union_member_message: `You are now a Union Member. Enjoy a new bunch of benefits from now on!`,
    view_all_benefits: `View all benefits`,
    welcome_union_member_title: `Welcome!`,
    welcome_union_member_message: `Welcome in the U PME app. You can explore your benefits here!`,
}

export default {
    translation,
}