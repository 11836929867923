import { MutableRefObject, useContext, useState } from 'react'
import { CardActionAreaProps } from '@mui/material/CardActionArea'
import { useNavigate } from "react-router-dom"

import LandingPageAccessGuard from '../LandingPageAccessGuard/LandingPageAccessGuard'
import { ProgrammeApplicationForm } from '../../../types/ProgrammeApplicationForm'
import AppCard from '../../atoms/AppCard/AppCard'
import { Route } from '../../../router'
import { isMembershipActive } from '../../../types/Membership'
import { UserContext } from '../../../stores/User/UserStore'
import ConfirmationDialog from '../ConfirmationDialog/ConfirmationDialog'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
import useGoogleTagManager from '../../../helpers/analytics/use-google-tag-manager'
import GtmEventName from '../../../helpers/analytics/GtmEventName'

interface ProgrammeApplicationFormProps extends CardActionAreaProps {
    programmeApplicationForm?: ProgrammeApplicationForm,
    reference?: MutableRefObject<null>
}

const ProgrammeApplicationFormCard = (props: ProgrammeApplicationFormProps) => {
    const { programmeApplicationForm, reference, ...inheritedProps } = props

    const { t } = useTranslation()
    const navigate = useNavigate()
    const { trackCardClick } = useGoogleTagManager()

    const { state: userState } = useContext(UserContext)

    const [dialogTitle, setDialogTitle] = useState<string>()
    const [dialogMessage, setDialogMessage] = useState<string>()
    const [dialogShow, setDialogShow] = useState(false)

    const showApplicationAlreadySubmittedErrorDialog = () => {
        setDialogTitle(t(`programme_application_already_submitted_title`))
        setDialogMessage(t(`programme_application_already_submitted_message`))
        setDialogShow(true)
    }

    const showMembershipNotActiveErrorDialog = () => {
        setDialogTitle(t(`programme_application_membership_not_active_title`))
        setDialogMessage(t(`programme_application_membership_not_active_message`))
        setDialogShow(true)
    }

    const handleDialogClose = () => {
        setDialogShow(false)
    }

    const handleCardClick = () => {
        trackCardClick(GtmEventName.PROGRAMME_APPLICATION_CARD_CLICK, programmeApplicationForm?.name)

        if (!programmeApplicationForm?.allowMultipleSubmissions && programmeApplicationForm?.hasApplied) {
            showApplicationAlreadySubmittedErrorDialog()
        }
        else if (isMembershipActive(userState.membership?.data)) {
            navigate(`${Route.PROGRAMME_APPLICATION}/${programmeApplicationForm?.id}`)
        } else {
            showMembershipNotActiveErrorDialog()
        }
    }

    const ribbonText = programmeApplicationForm?.hasApplied ? t(`programme_application_form_submitted`) : undefined

    const handleOnLandingPageGuardClickHandled = () => {
        trackCardClick(GtmEventName.PROGRAMME_APPLICATION_CARD_CLICK, programmeApplicationForm?.name)
    }

    return (
        <Box>
            <LandingPageAccessGuard onClickHandled={handleOnLandingPageGuardClickHandled}>
                <AppCard
                    title={programmeApplicationForm?.name}
                    summary={programmeApplicationForm?.summary}
                    image={programmeApplicationForm?.image}
                    ribbonText={ribbonText}
                    onClick={handleCardClick}
                    ref={reference}
                    {...inheritedProps}
                />
            </LandingPageAccessGuard>

            <ConfirmationDialog
                confirmationTitle={dialogTitle}
                confirmationText={dialogMessage}
                buttonText={t(`common_button_confirmation`)}
                defaultOpen={dialogShow}
                onConfirm={handleDialogClose}
                acknowledgeOnly />
        </Box>
    )
}

export default ProgrammeApplicationFormCard