import React, { useMemo, type Dispatch } from 'react'

import ProgrammeReducer, { ProgrammeContentAction } from './ProgrammeReducer'
import { ProgrammeApplicationFormInitialState, type ProgrammeState } from './ProgrammeState'

const ProgrammeContext = React.createContext<{
    state: ProgrammeState,
    dispatch: Dispatch<ProgrammeContentAction>
}>({
    state: ProgrammeApplicationFormInitialState,
    dispatch: () => null,
})

const ProgrammeContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [state, dispatch] = React.useReducer(ProgrammeReducer, ProgrammeApplicationFormInitialState)
    const providerValues = useMemo(() => ({ state, dispatch }), [state])

    return (
        <ProgrammeContext.Provider value={providerValues}>
            {children}
        </ProgrammeContext.Provider>
    )
}

export { ProgrammeContextProvider, ProgrammeContext }