import { useContext } from 'react'

import { UserContext } from '../../stores/User/UserStore'
import { UserActionTypes } from '../../stores/User/UserReducer'
import { MembershipSettings, MembershipSettingsResponse } from '../../types'
import MembershipSettingsRepository from './membership-settings.repository'

const useMembershipSettingsRepository = () => {
    const { dispatch } = useContext(UserContext)
    const membershipSettingsRepository = new MembershipSettingsRepository()

    const retrieveMembershipSettings = async () => {
        const membershipSettingsData: MembershipSettingsResponse = {}

        return membershipSettingsRepository.retrieveMembershipSettings()
            .then((membershipSettings: MembershipSettings) => {
                membershipSettingsData.data = membershipSettings

            })
            .catch((error) => {
                membershipSettingsData.error = {
                    errorMessage: error.message,
                    errorCode: error.response?.status,
                }
            })
            .finally(() => {
                dispatch({
                    type: UserActionTypes.MEMBERSHIP_SETTINGS_RETRIEVED,
                    membershipSettings: membershipSettingsData,
                })
            })
    }

    return {
        retrieveMembershipSettings,
    }
}

export { useMembershipSettingsRepository }