import { anonymousClient } from './util'
import { HTTP_SUCCESS } from '../helpers/utils/http-status'

const fetchTrainingCourse = async (trainerId: number | string) => {
    return anonymousClient()
        .post(
            `/api/training-course`,
            { trainerId },
        ).then(res => {
            if (res.status !== HTTP_SUCCESS) {
                throw new Error(`Could not retrieve training courses`)
            }
            return res
        }).then(res => res.data)
}

export {
    fetchTrainingCourse,
}