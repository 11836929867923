import { useContext, useEffect, useState } from "react"
import { Container, Grid } from "@mui/material"

import { AppContext } from "../../../stores/App/AppStore"
import { UserContext } from "../../../stores/User/UserStore"
import { useFAQRepository } from "../../../repositories/FAQ/use-faq-repository"
import { Route } from '../../../router'
import CommonBackButton from "../../atoms/CommonBackButton/CommonBackButton"
import FAQsList from "../../organisms/FAQsList/FAQsList"
import FaqNavigationTabs from "../../molecules/FaqNavigationTabs/FaqNavigationTabs"
import { FaqEntry, FaqTags, Pillar } from "../../../types"
import { useMembershipRepository } from "../../../repositories/Membership/use-membership-repository"
import { useNavigate, useParams } from "react-router-dom"
import { usePillarRepository } from "../../../repositories/Pillar/use-pillar-repository"
import SplashScreenGuard from "../../../router/guards/SplashScreenGuard"
import useTrackPageView from "../../../helpers/hooks/use-track-page-view"

import styles from "./FAQ.module.css"

const Faq = () => {
    useTrackPageView(`FAQ Page`)

    const { state: appState } = useContext(AppContext)
    const { state: userState } = useContext(UserContext)
    const navigate = useNavigate()

    const { pillarId, tagId } = useParams()

    const { retrieveFAQs } = useFAQRepository()
    const { retrieveMembership } = useMembershipRepository()
    const { retrievePillars } = usePillarRepository()

    const [tabIndex, setTabIndex] = useState(0)
    const [faqEntriesList, setFaqEntriesList] = useState<FaqEntry[]>([])
    const [pillar, setPillar] = useState<Pillar>()

    const membershipType = userState.membership?.data?.type

    useEffect(() => {
        retrieveMembership()
    }, [])

    useEffect(() => {
        if (pillarId) {
            retrievePillars(membershipType)
        } else {
            retrieveFAQs(membershipType)
        }
        window.scrollTo(0, 0)
    }, [membershipType])

    useEffect(() => {
        if (pillarId) {
            const pillar = appState.pillars.pillarsArray?.data?.find(pillar => pillar.id.toString() === pillarId)

            if (pillar?.faqEntries) {
                setPillar(pillar)
            } else {
                navigate(Route.HOME)
            }
        }

        setFaqEntriesList(allEntries())
    }, [appState])

    const allEntries = (): FaqEntry[] => {
        return (pillar?.faqEntries ?? appState.pages.faqs)?.filter(entry => {
            if (tagId) {
                return entry.tags?.find(tag => tag.id.toString() === tagId)
            }
            return true
        })
    }

    const handleTabChange = (_: React.SyntheticEvent, newIndex: number, tag: FaqTags) => {
        if (newIndex === 0) {
            setFaqEntriesList(allEntries())
        } else {
            setTabIndex(newIndex)
            setFaqEntriesList(allEntries().filter(entry => {
                return !!entry.tags?.find(t => t.id === tag.id)
            }))
        }
    }

    return (
        <SplashScreenGuard>
            <Container className={styles.container}>
                <CommonBackButton path={Route.HOME} />
                {!tagId &&
                    <FaqNavigationTabs faqEntries={allEntries()} selectedIndex={tabIndex} onTabChange={handleTabChange} />
                }
                <Grid container direction="column" alignItems="center" spacing={2}>
                    <Grid item className={styles.gridItem}>
                        <FAQsList faqEntries={faqEntriesList} />
                    </Grid>
                </Grid>
            </Container>
        </SplashScreenGuard>
    )
}

export default Faq
