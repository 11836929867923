import { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Route } from '..'
import { UserContext } from '../../stores/User/UserStore'
import { useMembershipRepository } from '../../repositories/Membership/use-membership-repository'
import { MembershipType } from '../../types/MembershipType'
import { MembershipStatus } from '../../types/MembershipStatus'

const useProfileUpdateGuard = () => {
    const { retrieveMembership, hasVisitedProfileSummaryUpdate } = useMembershipRepository()
    const { state } = useContext(UserContext)

    const navigate = useNavigate()

    useEffect(() => {
        retrieveMembership()
    }, [])

    useEffect(() => {
        const userMembership = state.membership?.data

        if (userMembership?.status === MembershipStatus.ACTIVE || userMembership?.status === MembershipStatus.UNION_ACTIVE) {
            const memberType = userMembership?.type

            if (memberType === MembershipType.UPME) {
                navigate(Route.PROFILE_SUMMARY)
            } else {
                const hasVisitedProfileSummaryUpdatePage = hasVisitedProfileSummaryUpdate()

                if (!hasVisitedProfileSummaryUpdatePage) {
                    navigate(Route.PROFILE_SUMMARY)
                } else {
                    return
                }
            }
        } else {
            return
        }
    }, [])
}

export default useProfileUpdateGuard