import { Programme } from "../../types/Programme"
import { absoluteImageUrl } from "../utils/environment"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const fromStrapiProgramme = (strapiData: any): Programme => {
    const { id, attributes } = strapiData
    const { name, bannerImage, description, externalLinkText, externalLink, daysBeforeActivation, popupText, optInConfirmationText, summary, membershipScope, url, route, order} = attributes
    const bannerImageUrl = absoluteImageUrl(bannerImage.data[0].attributes.url)

    const item: Programme = {
        id,
        name,
        bannerImage: bannerImageUrl,
        description,
        externalLinkText,
        externalLink,
        daysBeforeActivation,
        popupText,
        optInConfirmationText,
        summary,
        membershipScope,
        url,
        route,
        order,
    }

    return item
}

export {
    fromStrapiProgramme,
}