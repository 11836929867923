import { Box, Typography } from "@mui/material"
import { CardActionAreaProps } from '@mui/material/CardActionArea'
import { useNavigate } from "react-router-dom"
import moment from "moment"

import { DigitalResourceDetail } from "../../../types/DigitalResourceDetail"
import { DatePatterns } from "../../../types/date-patterns"
import AppCard from "../../atoms/AppCard/AppCard"
import AppCardSkeleton from "../../atoms/AppCard/AppCard.skeleton"
import useGoogleTagManager from '../../../helpers/analytics/use-google-tag-manager'

import styles from "./DigitalResourceCard.module.scss"

interface DigitalResourceCardProps extends CardActionAreaProps {
    digitalResource?: DigitalResourceDetail,
    placeHolderImage?: string,
    fullWidth?: boolean
}

const DigitalResourceCard = (props: DigitalResourceCardProps) => {
    const { digitalResource, placeHolderImage, fullWidth, ...inheritedProps } = props

    const navigate = useNavigate()
    const { trackDigitalResourceClick, trackDigitalResourceRedirect } = useGoogleTagManager()

    const category = digitalResource?.category.name.toLowerCase()

    if (!digitalResource) {
        return <AppCardSkeleton />
    }

    const handleCardClick = () => {
        trackDigitalResourceClick(digitalResource)

        if (!digitalResource.externalLink && !digitalResource.description) {
            return
        }

        if (digitalResource.description) {
            navigate(`/upme/digital-resource/${category}/${digitalResource.id}`)
        } else {
            trackDigitalResourceRedirect(digitalResource)
            window.open(`${digitalResource.externalLink}`, `_blank`)
        }
    }

    const formattedDate = moment(digitalResource.date).format(DatePatterns.ABBREVIATED_DATE)

    const image = digitalResource.image ? digitalResource.image : placeHolderImage

    return (
        <AppCard onClick={handleCardClick} image={image} title={digitalResource?.title} summary={digitalResource?.summary} fullWidth={fullWidth} {...inheritedProps}>
            <Box className={styles.content}>
                <Typography className={styles.date} variant="body2">{formattedDate}</Typography>
            </Box>
        </AppCard>
    )
}

export default DigitalResourceCard
