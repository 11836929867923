import type {
    ApplicationTermsOfUsePage,
    Bank,
    CuratedExperienceDetail,
    CuratedExperiencePage,
    DealServiceResponse,
    DealsServiceResponse,
    Deal,
    EventDetail,
    FaqEntry,
    FaqTags,
    FieldOfStudy,
    HomePage,
    Institution,
    JoinPage,
    MembershipCardPage,
    MembershipSuccessPage,
    StripePaymentOrderHistoryServiceResponse,
    Pillar,
    PrivacyPolicyPage,
    RenewalSuccessPageServiceResponse,
    StripePaymentOrderServiceResponse,
    StripePaymentOrderResultServiceResponse,
    TermsAndConditions,
    UtapCoursesServiceResponse,
    Version,
    TrainingProvider,
    ServiceResponse,
    HighlightCarouselItem,
} from '../../types'

import { arrayUpsert } from '../../helpers/utils/collections'
import { UtapLandingPage } from '../../types/UtapLandingPage'
import type { AppState } from './AppState'
import { SplashScreenVisit } from '../../types/SplashScreenVisit'
import { Dispatch } from 'react'
import { SimpleFAQEntry } from '../../types/SimpleFAQEntry'
import { PageInfo } from '../../types/PageInfo'
import { ContactUsPage } from '../../types/ContactUsPage'

enum ActionTypes {
    SET_PAGE_INFO = `SET_PAGE_TITLE`,
    PAGE_HOME_RETRIEVE = `PAGE_HOME_RETRIEVE`,
    PAGE_JOIN_RETRIEVE = `PAGE_JOIN_RETRIEVE`,
    PAGE_MEMBERSHIP_SUCCESS_RETRIEVE = `PAGE_MEMBERSHIP_SUCCESS_RETRIEVE`,
    PAGE_MEMBERSHIP_RENEWAL_SUCCESS_RETRIEVE = `PAGE_MEMBERSHIP_RENEWAL_SUCCESS_RETRIEVE`,
    PILLARS_RETRIEVED = `PILLARS_RETRIEVED`,
    PILLARS_CLEAR_ALL = `PILLARS_CLEAR_ALL`,
    TERMS_RETRIEVE = `TERMS_RETRIEVE`,
    TERMS_RETRIEVE_ONE = `TERMS_RETRIEVE_ONE`,
    INSTITUTION_RETRIEVE = `INSTITUTION_RETRIEVE`,
    FIELDS_OF_STUDY_RETRIEVE = `FIELDS_OF_STUDY_RETRIEVE`,
    PAGE_MONEY_OWL_RETRIEVED = `PAGE_MONEY_OWL_RETRIEVED`,
    PAGE_SNACK_RETRIEVED = `PAGE_SNACK_RETRIEVED`,
    PAGE_MEMBERSHIP_CARD_RETRIEVED = `PAGE_MEMBERSHIP_CARD_RETRIEVED`,
    CURATED_EXPERIENCE_RETRIEVED = `CURATED_EXPERIENCE_RETRIEVED`,
    CURATED_EXPERIENCES_RETRIEVED = `CURATED_EXPERIENCES_RETRIEVED`,
    CURATED_EXPERIENCES_PAGE_RETRIEVED = `CURATED_EXPERIENCES_PAGE_RETRIEVED`,
    EVENTS_RETRIEVED = `EVENTS_RETRIEVE`,
    EVENT_RETRIEVED = `EVENT_RETRIEVED`,
    EVENTS_CLEAR_ALL = `EVENTS_CLEAR_ALL`,
    EVENT_REGISTERED = `EVENT_REGISTERED`,
    EVENT_REGISTERED_STATUS_RETRIEVED = `EVENT_REGISTERED__STATUS_RETRIEVED`,
    REGISTERED_EVENTS_RETRIEVED = `REGISTERED_EVENTS_RETRIEVED`,
    PRIVACY_POLICY_RETRIEVED = `PRIVACY_POLICY_RETRIEVED`,
    CONTACT_US_PAGE_RETRIEVED = `CONTACT_US_PAGE_RETRIEVED`,
    FAQ_RETRIEVED = `FAQ_RETRIEVED`,
    FAQ_TAGS_RETRIEVED = `FAQ_TAGS_RETRIEVED`,
    PAGE_ABOUT_CAROUSEL_RETRIEVED = `PAGE_ABOUT_CAROUSEL_RETRIEVED`,
    PAGE_ABOUT_FAQ_RETRIEVED = `PAGE_ABOUT_FAQ_RETRIEVED`,
    PAGE_APPLICATION_TERMS_OF_USE_RETRIEVED = `PAGE_APPLICATION_TERMS_OF_USE_RETRIEVED`,
    VERSION_RETRIEVE = `VERSION_RETRIEVE`,
    VERSION_SET = `VERSION_SET`,
    PAYMENT_ORDER_RETRIEVED = `PAYMENT_ORDER_RETRIEVED`,
    PAYMENT_ORDER_RESULT_RETRIEVED = `PAYMENT_ORDER_RESULT_RETRIEVED`,
    PAYMENT_ORDER_HISTORY_RETRIEVED = `PAYMENT_ORDER_HISTORY_RETRIEVED`,
    TRAINING_PROVIDER_RETRIEVE = `TRAINING_PROVIDER_RETRIEVE`,
    TRAINING_COURSE_RETRIEVE = `TRAINING_COURSE_RETRIEVE`,
    BANK_RETRIEVE = `BANK_RETRIEVE`,
    BANK_BRANCH_RETRIEVE = `BANK_BRANCH_RETRIEVE`,
    UTAP_LANDING_RETRIEVE = `UTAP_LANDING_RETRIEVE`,
    VISITED_SPLASH_SCREEN = `VISITED_SPLASH_SCREEN`,
    DEALS_RETRIEVED = `DEALS_RETRIEVED`,
    DEAL_RETRIEVED = `DEAL_RETRIEVED`,
    HOME_CAROUSEL_RETRIEVED = `HOME_CAROUSEL_RETRIEVED`,
    UPME_HOME_CAROUSEL_RETRIEVED = `UPME_HOME_CAROUSEL_RETRIEVED`,
    PROGRAMMES_RETRIEVED = `PROGRAMMES_RETRIEVED`,
}

type AppAction =
    | { type: ActionTypes.SET_PAGE_INFO, data: PageInfo }
    | { type: ActionTypes.PILLARS_RETRIEVED, data: ServiceResponse<Pillar[]> }
    | { type: ActionTypes.PILLARS_CLEAR_ALL }
    | { type: ActionTypes.PAGE_HOME_RETRIEVE, data: HomePage }
    | { type: ActionTypes.PAGE_JOIN_RETRIEVE, data: JoinPage }
    | { type: ActionTypes.PAGE_MEMBERSHIP_SUCCESS_RETRIEVE, data: MembershipSuccessPage }
    | { type: ActionTypes.PAGE_MEMBERSHIP_RENEWAL_SUCCESS_RETRIEVE, data: RenewalSuccessPageServiceResponse }
    | { type: ActionTypes.TERMS_RETRIEVE, data: TermsAndConditions[] }
    | { type: ActionTypes.TERMS_RETRIEVE_ONE, data: TermsAndConditions }
    | { type: ActionTypes.INSTITUTION_RETRIEVE, data: Institution[] }
    | { type: ActionTypes.FIELDS_OF_STUDY_RETRIEVE, data: FieldOfStudy[] }
    | { type: ActionTypes.CURATED_EXPERIENCE_RETRIEVED, curatedExperienceDetail: CuratedExperienceDetail }
    | { type: ActionTypes.CURATED_EXPERIENCES_RETRIEVED, curatedExperienceDetails: CuratedExperienceDetail[] }
    | { type: ActionTypes.CURATED_EXPERIENCES_PAGE_RETRIEVED, curatedExperiencePage: CuratedExperiencePage }
    | { type: ActionTypes.EVENTS_RETRIEVED, events: ServiceResponse<EventDetail[]> }
    | { type: ActionTypes.EVENT_RETRIEVED, event: ServiceResponse<EventDetail> }
    | { type: ActionTypes.EVENTS_CLEAR_ALL }
    | { type: ActionTypes.PRIVACY_POLICY_RETRIEVED, privacyPolicy: PrivacyPolicyPage }
    | { type: ActionTypes.CONTACT_US_PAGE_RETRIEVED, contactUsPage: ContactUsPage }
    | { type: ActionTypes.FAQ_RETRIEVED, faqs: FaqEntry[] }
    | { type: ActionTypes.FAQ_TAGS_RETRIEVED, faqTags: FaqTags[] }
    | { type: ActionTypes.PAGE_ABOUT_CAROUSEL_RETRIEVED, aboutCarouselItems: ServiceResponse<HighlightCarouselItem[]> }
    | { type: ActionTypes.PAGE_ABOUT_FAQ_RETRIEVED, aboutFaqItems: ServiceResponse<SimpleFAQEntry[]> }
    | { type: ActionTypes.PAGE_APPLICATION_TERMS_OF_USE_RETRIEVED, applicationTermsOfUsePageData: ApplicationTermsOfUsePage }
    | { type: ActionTypes.PAGE_MEMBERSHIP_CARD_RETRIEVED, membershipCardPageData: MembershipCardPage }
    | { type: ActionTypes.VERSION_RETRIEVE, version: string }
    | { type: ActionTypes.VERSION_SET, version: Version }
    | { type: ActionTypes.PAYMENT_ORDER_RETRIEVED, paymentOrderData: StripePaymentOrderServiceResponse }
    | { type: ActionTypes.PAYMENT_ORDER_RESULT_RETRIEVED, paymentOrderResultData: StripePaymentOrderResultServiceResponse }
    | { type: ActionTypes.PAYMENT_ORDER_HISTORY_RETRIEVED, paymentOrderHistoryData: StripePaymentOrderHistoryServiceResponse }
    | { type: ActionTypes.TRAINING_COURSE_RETRIEVE, data: UtapCoursesServiceResponse }
    | { type: ActionTypes.TRAINING_PROVIDER_RETRIEVE, data: TrainingProvider[] }
    | { type: ActionTypes.BANK_RETRIEVE, data: Bank[] }
    | { type: ActionTypes.UTAP_LANDING_RETRIEVE, data: UtapLandingPage }
    | { type: ActionTypes.VISITED_SPLASH_SCREEN, data: SplashScreenVisit }
    | { type: ActionTypes.DEALS_RETRIEVED, deals: DealsServiceResponse }
    | { type: ActionTypes.DEAL_RETRIEVED, deal: DealServiceResponse }
    | { type: ActionTypes.HOME_CAROUSEL_RETRIEVED, homeCarouselItems: ServiceResponse<HighlightCarouselItem[]> }

const AppReducer = (state: AppState, action: AppAction): AppState => {
    switch (action.type) {
        case ActionTypes.SET_PAGE_INFO: {
            return {
                ...state,
                currentPageTitle: action.data.pageTitle,
                currentPageItem: action.data.pageItem,
            }
        }
        case ActionTypes.PAGE_HOME_RETRIEVE: {
            return {
                ...state,
                pages: {
                    ...state.pages,
                    home: action.data,
                },
            }
        }
        case ActionTypes.PAGE_JOIN_RETRIEVE: {
            return {
                ...state,
                pages: {
                    ...state.pages,
                    join: action.data,
                },
            }
        }
        case ActionTypes.PAGE_MEMBERSHIP_SUCCESS_RETRIEVE: {
            return {
                ...state,
                pages: {
                    ...state.pages,
                    success: action.data,
                },
            }
        }
        case ActionTypes.PAGE_MEMBERSHIP_RENEWAL_SUCCESS_RETRIEVE: {
            return {
                ...state,
                pages: {
                    ...state.pages,
                    renewalSuccess: action.data,
                },
            }
        }
        case ActionTypes.PILLARS_RETRIEVED: {
            return {
                ...state,
                pillars: {
                    ...state.pillars,
                    pillarsArray: action.data,
                },
            }
        }
        case ActionTypes.PILLARS_CLEAR_ALL: {
            return {
                ...state,
                pillars: {
                    ...state.pillars,
                    pillarsArray: undefined,
                },
            }
        }
        case ActionTypes.TERMS_RETRIEVE: {
            return {
                ...state,
                terms: action.data,
            }
        }
        case ActionTypes.TERMS_RETRIEVE_ONE: {
            return {
                ...state,
                terms: arrayUpsert(state.terms, `purpose`, action.data),
            }
        }
        case ActionTypes.INSTITUTION_RETRIEVE: {
            return {
                ...state,
                institutions: action.data,
            }
        }
        case ActionTypes.FIELDS_OF_STUDY_RETRIEVE: {
            return {
                ...state,
                fieldsOfStudy: action.data,
            }
        }
        case ActionTypes.EVENTS_RETRIEVED: {
            return {
                ...state,
                events: action.events,
            }
        }
        case ActionTypes.EVENT_RETRIEVED: {
            let data: EventDetail[] | undefined = state.events?.data

            if (action.event.data) {
                if (data?.length) {
                    data = data.filter(event => event.id !== action.event?.data?.id)
                }

                data = [
                    ...(data ?? []),
                    action.event.data,
                ]
            }

            return {
                ...state,
                events: {
                    data,
                    error: action.event.error,
                },
            }
        }
        case ActionTypes.CURATED_EXPERIENCE_RETRIEVED: {
            return {
                ...state,
                pillars: {
                    ...state.pillars,
                    curatedExperience: {
                        ...state.pillars.curatedExperience,
                        experiences:
                            [...state.pillars.curatedExperience.experiences, action.curatedExperienceDetail],
                    },
                },
            }
        }
        case ActionTypes.CURATED_EXPERIENCES_RETRIEVED: {
            return {
                ...state,
                pillars: {
                    ...state.pillars,
                    curatedExperience: {
                        ...state.pillars.curatedExperience,
                        experiences: action.curatedExperienceDetails,
                    },
                },
            }
        }
        case ActionTypes.CURATED_EXPERIENCES_PAGE_RETRIEVED: {
            return {
                ...state,
                pillars: {
                    ...state.pillars,
                    curatedExperience: {
                        ...state.pillars.curatedExperience,
                        curatedExperiencePage: action.curatedExperiencePage,
                    },
                },
            }
        }
        case ActionTypes.EVENTS_CLEAR_ALL: {
            return {
                ...state,
                events: undefined,
            }
        }
        case ActionTypes.PRIVACY_POLICY_RETRIEVED: {
            return {
                ...state,
                pages: {
                    ...state.pages,
                    privacyPolicy: action.privacyPolicy,
                },
            }
        }
        case ActionTypes.CONTACT_US_PAGE_RETRIEVED: {
            return {
                ...state,
                pages: {
                    ...state.pages,
                    contactUsPage: action.contactUsPage,
                },
            }
        }
        case ActionTypes.FAQ_RETRIEVED: {
            return {
                ...state,
                pages: {
                    ...state.pages,
                    faqs: action.faqs,
                },
            }
        }
        case ActionTypes.FAQ_TAGS_RETRIEVED: {
            return {
                ...state,
                pages: {
                    ...state.pages,
                    faqTags: action.faqTags,
                },
            }
        }
        case ActionTypes.PAGE_ABOUT_CAROUSEL_RETRIEVED: {
            return {
                ...state,
                pages: {
                    ...state.pages,
                    aboutCarouselItems: action.aboutCarouselItems,
                },
            }
        }
        case ActionTypes.PAGE_ABOUT_FAQ_RETRIEVED: {
            return {
                ...state,
                pages: {
                    ...state.pages,
                    aboutFaqItems: action.aboutFaqItems,
                },
            }
        }
        case ActionTypes.PAGE_APPLICATION_TERMS_OF_USE_RETRIEVED: {
            return {
                ...state,
                pages: {
                    ...state.pages,
                    applicationTermsOfUse: action.applicationTermsOfUsePageData,
                },
            }
        }
        case ActionTypes.PAGE_MEMBERSHIP_CARD_RETRIEVED: {
            return {
                ...state,
                pages: {
                    ...state.pages,
                    membershipCard: action.membershipCardPageData,
                },
            }
        }
        case ActionTypes.VERSION_RETRIEVE: {
            return {
                ...state,
                version: {
                    current: state.version.current,
                    upcoming: action.version,
                },
            }
        }
        case ActionTypes.VERSION_SET: {
            return {
                ...state,
                version: action.version,
            }
        }
        case ActionTypes.PAYMENT_ORDER_RETRIEVED: {
            return {
                ...state,
                paymentOrder: action.paymentOrderData,
            }
        }
        case ActionTypes.PAYMENT_ORDER_RESULT_RETRIEVED: {
            return {
                ...state,
                paymentOrderResult: action.paymentOrderResultData,
            }
        }
        case ActionTypes.PAYMENT_ORDER_HISTORY_RETRIEVED: {
            return {
                ...state,
                paymentOrderHistory: action.paymentOrderHistoryData,
            }
        }
        case ActionTypes.TRAINING_COURSE_RETRIEVE: {
            return {
                ...state,
                trainingCourse: action.data,
            }
        }
        case ActionTypes.TRAINING_PROVIDER_RETRIEVE: {
            return {
                ...state,
                trainingProvider: action.data,
            }
        }
        case ActionTypes.BANK_RETRIEVE: {
            return {
                ...state,
                bank: action.data,
            }
        }
        case ActionTypes.UTAP_LANDING_RETRIEVE: {
            return {
                ...state,
                utapLanding: action.data,
            }
        }
        case ActionTypes.VISITED_SPLASH_SCREEN: {
            return {
                ...state,
                splashScreenVisit: action.data,
            }
        }
        case ActionTypes.DEALS_RETRIEVED: {
            let dealsList: Deal[] | undefined = state.deals?.data

            if (action.deals.data) {
                if (dealsList && dealsList?.length > 0) {
                    dealsList = dealsList.filter(existingDeal => {
                        const isUnique = !action.deals?.data?.some(newDeal => newDeal.id === existingDeal.id)
                        return isUnique
                    })
                }

                dealsList = [
                    ...(dealsList ?? []),
                    ...action.deals.data,
                ]
            }

            return {
                ...state,
                deals: {
                    data: dealsList,
                    error: action.deals.error,
                },
            }
        }
        case ActionTypes.DEAL_RETRIEVED: {
            let deals: Deal[] | undefined = state.deals?.data

            if (action.deal.data) {
                if (deals?.length) {
                    deals = deals.filter(deal => deal.id !== action.deal.data?.id)
                }

                deals = [
                    ...(deals ?? []),
                    action.deal.data,
                ]
            }

            return {
                ...state,
                deals: {
                    data: deals,
                    error: action.deal.error,
                },
            }
        }
        case ActionTypes.HOME_CAROUSEL_RETRIEVED: {
            return {
                ...state,
                homeCarouselItems: action.homeCarouselItems,
            }
        }
        default: {
            throw new Error(`Unhandled action type`)
        }
    }
}

const fireEmptyVisitedSplashScreen = (dispatch: Dispatch<AppAction>) => {
    dispatch({
        type: ActionTypes.VISITED_SPLASH_SCREEN,
        data: {},
    })
}

export default AppReducer
export type { AppAction }
export {
    ActionTypes,
    fireEmptyVisitedSplashScreen,
}