import { Box, BoxProps, Skeleton } from "@mui/material"
import clsx from "clsx"

import styles from "./SelectableCard.module.scss"

const SelectableCardSkeleton = (props: BoxProps) => {
    const { className } = props

    const cardStyle = clsx(
        styles.container,
        className,
        styles.skeleton,
    )

    return (
        <Box className={cardStyle}>
            <Box className={styles.image}>
                <Skeleton className={styles.skeleton} />
            </Box>
            <Box className={styles.label}>
                <Skeleton className={styles.skeleton} />
            </Box>
        </Box>
    )
}

export default SelectableCardSkeleton