import { useEffect, useContext, DependencyList } from 'react'
import { AppContext } from '../../stores/App/AppStore'
import { ActionTypes } from '../../stores/App/AppReducer'
import useGoogleTagManager from '../analytics/use-google-tag-manager'

type PageTitleGenerator = () => {
    title: string,
    item?: string,
} | undefined

const useTrackPageView = (pageTitle?: string | PageTitleGenerator | undefined, pageItemOrDependencies?: string | DependencyList) => {
    const { state: appState, dispatch: dispatchAppEvent } = useContext(AppContext)
    const { trackPageView } = useGoogleTagManager()

    const typeOfPageTitle = typeof pageTitle
    const typeOfPageItemOrDependencies = typeof pageItemOrDependencies

    const pageTitleFunction = typeOfPageTitle === `function` ? pageTitle as PageTitleGenerator : undefined
    const title = typeOfPageTitle === `string` ? pageTitle as string : undefined
    const dependencyList: DependencyList = typeOfPageItemOrDependencies === `object` ? pageItemOrDependencies as DependencyList : []
    const item = typeOfPageItemOrDependencies === `string` ? pageItemOrDependencies as string : undefined

    useEffect(() => {
        const { title: generatedPageTitle, item: generatedPageItem } = pageTitleFunction?.() ?? {}

        const actualPageTitle: string | undefined = title ?? generatedPageTitle
        const actualPageItem: string | undefined = item ?? generatedPageItem

        if (actualPageTitle && (appState.currentPageTitle != actualPageTitle || appState.currentPageItem != actualPageItem)) {
            dispatchAppEvent({
                type: ActionTypes.SET_PAGE_INFO,
                data: {
                    pageTitle: actualPageTitle,
                    pageItem: actualPageItem,
                },
            })

            trackPageView(actualPageTitle, actualPageItem)
        }
    }, dependencyList)

    return appState.currentPageTitle
}

export default useTrackPageView