const trimNonDigits = (input: string): string => input.replace(/\D/g, ``)

const trimLeadingCountryCode = (input: string | undefined): string | undefined => {
    const presetCountryCode = `+65`

    if (!input) {
        return undefined
    } else {
        return input.replace(presetCountryCode, ``)
    }
}

export {
    trimNonDigits,
    trimLeadingCountryCode,
}