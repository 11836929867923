import { EventDetail } from "../../types"
import { absoluteImageUrl } from "../utils/environment"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const fromStrapiEventPublic = (strapiData: any): EventDetail => {
    const { title, description, eventDate, location, externalLink, startTime, endTime, order,
        questionnaire, mapUrl, openToRegistration, instructions, blkHouseNumber, buildingName,
        floorNumber, unitNumber, streetName, postalCode, email, contactNumber, registrationDeadline,
        eventEndDate, membershipScope, summary, externalLinkDetails, noAddress, headerImage, registered,
    } = strapiData.attributes

    const headerImageUrl = headerImage.data.attributes.url ? absoluteImageUrl(headerImage.data.attributes.url) : ``

    const item: EventDetail = {
        id: strapiData.id,
        title,
        description,
        headerImage: headerImageUrl,
        eventDate,
        eventEndDate,
        registrationDeadline,
        startTime,
        endTime,
        location,
        externalLink,
        order,
        email,
        contactNumber,
        questionnaire,
        mapUrl,
        openToRegistration,
        instructions,
        blkHouseNumber,
        buildingName,
        floorNumber,
        unitNumber,
        streetName,
        postalCode,
        membershipScope,
        summary,
        externalLinkDetails,
        noAddress,
        registered,
    }

    return item
}

export {
    fromStrapiEventPublic,
}