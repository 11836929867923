import moment from "moment"
import { MembershipPlan } from "../../types"
import { absoluteImageUrl } from "../utils/environment"

interface StrapiMembershipPlan {
    name: string,
    key: string,
    membershipFees: string,
    onlyNewMemberships: string,
    endDate: string,
    description: string,
    bannerTop: {
        url: string
    },
    bannerBottom: {
        url: string
    },
    termsAndConditions: string,
    usageLeft: number,
    termsAndConditionsHeader: string,
    termsAndConditionsLinkText: string,
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const fromStrapiMembershipPlan = (strapiData: StrapiMembershipPlan): MembershipPlan => {
    const {
        name,
        key,
        membershipFees,
        onlyNewMemberships,
        endDate,
        description,
        bannerTop,
        bannerBottom,
        termsAndConditions,
        usageLeft,
        termsAndConditionsHeader,
        termsAndConditionsLinkText,
    } = strapiData

    const imageURLTop = absoluteImageUrl(bannerTop.url)
    const imageURLBottom = absoluteImageUrl(bannerBottom.url)

    const item: MembershipPlan = {
        name,
        key,
        membershipFees: parseFloat(membershipFees),
        onlyNewMemberships: Boolean(onlyNewMemberships),
        endDate: moment(endDate).toDate(),
        description,
        bannerTop: imageURLTop,
        bannerBottom: imageURLBottom,
        termsAndConditions: termsAndConditions,
        usageLeft: usageLeft,
        termsAndConditionsHeader: termsAndConditionsHeader,
        termsAndConditionsLinkText: termsAndConditionsLinkText,
    }

    return item
}

export {
    fromStrapiMembershipPlan,
}