import { anonymousClient } from './util'
import { MembershipType } from '../types/MembershipType'
import { HTTP_SUCCESS } from '../helpers/utils/http-status'

const fetchHomePage = async (membershipType: MembershipType) => {
    return anonymousClient()
        .get(
            `/api/home-pages?populate=*&filters[membershipScope][$eq]=${membershipType}`,
        )
        .then(res => {
            if (res.status !== HTTP_SUCCESS) {
                throw new Error(`Could not retrieve Home Page`)
            }
            return res
        })
        .then(res => res.data.data)
        .then(homePageContents => {
            if (!homePageContents.length) {
                throw new Error(`No Home Page content for membership type "${membershipType}"`)
            }

            return homePageContents[0]
        })
}

const fetchJoinPage = async () => {
    return anonymousClient()
        .get(
            `/api/join-page?populate=*`,
        ).then(res => {
            if (res.status !== HTTP_SUCCESS) {
                throw new Error(`Could not retrieve Join Page`)
            }
            return res
        }).then(res => res.data)
}

const fetchMembershipSuccessPage = async () => {
    return anonymousClient()
        .get(
            `/api/membership-success-page?populate=*`,
        ).then(res => {
            if (res.status !== HTTP_SUCCESS) {
                throw new Error(`Could not retrieve Membership Success Page`)
            }
            return res
        }).then(res => res.data)
}

const fetchMembershipRenewalSuccessPage = async () => {
    return anonymousClient()
        .get(
            `/api/membership-renewal-success-page?populate=*`,
        ).then(res => {
            if (res.status !== HTTP_SUCCESS) {
                throw new Error(`Could not retrieve Membership Renewal Success Page`)
            }
            return res
        }).then(res => res.data)
}

const fetchPrivacyPolicyPage = async () => {
    return anonymousClient()
        .get(
            `/api/privacy-policy`,
        ).then(res => {
            if (res.status !== HTTP_SUCCESS) {
                throw new Error(`Could not retrieve Privacy Policy Page`)
            }
            return res
        }).then(res => res.data)
}

const fetchContactUsPage = async () => {
    return anonymousClient()
        .get(
            `/api/contact-us-page`,
        ).then(res => {
            if (res.status !== HTTP_SUCCESS) {
                throw new Error(`Could not retrieve Contact Us Page`)
            }
            return res
        }).then(res => res.data)
}

const fetchFAQs = async (membershipType: MembershipType) => {
    return anonymousClient()
        .get(
            `/api/faqs?[populate]=*&filters[$or][0][membershipScope]=ALL&filters[$or][0][membershipScope]=${membershipType}`,
        ).then(res => {
            if (res.status !== HTTP_SUCCESS) {
                throw new Error(`Could not retrieve Faqs`)
            }
            return res
        }).then(res => res.data)
}

const fetchMembershipCardPage = async () => {
    return anonymousClient()
        .get(
            `/api/membership-page`,
        ).then(res => {
            if (res.status !== HTTP_SUCCESS) {
                throw new Error(`Could not retrieve membership page`)
            }
            return res
        }).then(res => res.data)
}

const fetchAboutStarterCarousel = async () => {
    return anonymousClient()
        .get(
            `/api/about-starter-carousel?populate[items][populate][0]=image`,
        )
        .then(res => {
            if (res.status !== HTTP_SUCCESS) {
                throw new Error(`Could not get About Starter Carousel`)
            }
            return res.data
        })
        .then(res => res.data.attributes.items)
}

const fetchAboutStarterFaq = async () => {
    return anonymousClient()
        .get(
            `/api/about-starter-faq?populate[0]=questions`,
        )
        .then(res => {
            if (res.status !== HTTP_SUCCESS) {
                throw new Error(`Could not get About Starter Faq`)
            }
            return res.data
        })
        .then(res => res.data.attributes.questions)
}

const fetchApplicationTermsOfUsePage = async () => {
    return anonymousClient()
        .get(
            `/api/application-terms-of-use`,
        ).then(res => {
            if (res.status !== HTTP_SUCCESS) {
                throw new Error(`Could not retrieve Application Terms and Conditions page`)
            }
            return res
        }).then(res => res.data)
}

export {
    fetchHomePage,
    fetchJoinPage,
    fetchMembershipSuccessPage,
    fetchMembershipRenewalSuccessPage,
    fetchPrivacyPolicyPage,
    fetchContactUsPage,
    fetchFAQs,
    fetchMembershipCardPage,
    fetchAboutStarterCarousel,
    fetchAboutStarterFaq,
    fetchApplicationTermsOfUsePage,
}