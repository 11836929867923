import { useContext, useEffect, useRef, useState } from "react"
import { useTranslation } from 'react-i18next'

import DealCard from "../../molecules/DealCard/DealCard"
import DealsSkeleton from "../../pages/Deals/Deals.skeleton"
import SplashScreenGuard from "../../../router/guards/SplashScreenGuard"
import { useMembershipRepository } from "../../../repositories/Membership/use-membership-repository"
import useDealRepository from "../../../repositories/Deal/use-deal-repository"
import { UserContext } from "../../../stores/User/UserStore"
import { AppContext } from '../../../stores/App/AppStore'
import { Deal } from "../../../types/Deal"
import useTrackPageView from "../../../helpers/hooks/use-track-page-view"
import CardsList from "../../molecules/CardsList/CardsList"
import { extractAndOrderCategories } from "../../../helpers/utils/deals"
import useGoogleTagManager from '../../../helpers/analytics/use-google-tag-manager'
import GtmEventName from '../../../helpers/analytics/GtmEventName'

const DEFAULT_PAGE_SIZE = 20

const Deals = () => {
    useTrackPageView(`Deals Page`)

    const { retrieveDeals } = useDealRepository()
    const { retrieveMembership } = useMembershipRepository()
    const { t } = useTranslation()
    const { trackFilterClick } = useGoogleTagManager()

    const { state } = useContext(AppContext)
    const { state: userState } = useContext(UserContext)

    const userMembershipType = userState.membership?.data?.type

    const allCategory = t(`filter_by_all`)

    const [sentinelReference, setSentinelReference] = useState(null)
    const lastItemReference = useRef(null)
    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE)
    const [filterType, setFilterType] = useState(allCategory)

    const deals: Deal[] = state.deals?.data ?? []

    useEffect(() => {
        retrieveMembership()
    }, [])

    useEffect(() => {
        retrieveDeals(userMembershipType, pageSize)
    }, [userState.membership, pageSize])

    useEffect(() => {
        if (sentinelReference) {
            setPageSize((prevPageSize) => {
                return prevPageSize + DEFAULT_PAGE_SIZE
            })
        }
    }, [sentinelReference])

    const handleIntersection = (item: IntersectionObserverEntry) => {
        if (item.isIntersecting) {
            setSentinelReference(lastItemReference.current)
        }
    }

    useEffect(() => {
        const observer = new IntersectionObserver(([item]) => {
            return handleIntersection(item)
        })

        if (lastItemReference.current) {
            observer.observe(lastItemReference.current)
        }

        return () => {
            if (lastItemReference.current) {
                observer.unobserve(lastItemReference.current)
            }
        }
    }, [deals])

    const dealId = (deal: Deal) => {
        return `deal-${deal.id}-${deal.title}`
    }

    const dealCard = (deal: Deal) => {
        return <DealCard deal={deal} id={dealId(deal)} key={dealId(deal)} ref={lastItemReference} fullWidth />
    }

    const handleFilterTypeChange = (newType: string) => {
        trackFilterClick(GtmEventName.FILTER_EVENTS, newType)
        setFilterType(newType)
    }

    const handleFilteredList = (filteredType: string) => {
        if (filteredType === allCategory) {
            return deals
        } else {
            return deals.filter((deal) => deal.category.name === filteredType)
        }
    }

    const uniqueCategories = extractAndOrderCategories(deals)
    const filteredList = handleFilteredList(filterType)

    if (deals.length === 0) {
        return (
            <SplashScreenGuard>
                <DealsSkeleton />
            </SplashScreenGuard>
        )
    }

    return (
        <CardsList itemsTitle={t(`deals_and_promotions`)} items={filteredList} cardRenderer={dealCard} showFilterBy={true} categoryFilterList={uniqueCategories} onFilterChange={handleFilterTypeChange} />
    )
}

export default Deals