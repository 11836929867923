import { useContext, useEffect, useState } from 'react'
import useProgrammeApplicationFormRepository from '../../../../repositories/ProgrammeApplicationForm/use-programme-application-form-repository'
import { Box, Container, Link, Typography } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'

import { ProgrammeApplicationForm as ApplicationForm } from '../../../../types/ProgrammeApplicationForm'
import { ProgrammeContext } from '../../../../stores/Programme/ProgrammeStore'
import ConfirmationDialog from '../../../molecules/ConfirmationDialog/ConfirmationDialog'
import { useTranslation } from 'react-i18next'
import { Route } from '../../../../router'
import SplashScreenGuard from '../../../../router/guards/SplashScreenGuard'
import Questionnaire from '../../../organisms/Questionnaire/Questionnaire'
import ProgrammeApplicationFormSkeleton from './ProgrammeApplicationForm.skeleton'
import QuestionAnswer, { emptyQuestionAnswerFromQuestionProps, isQuestionAnswerEmpty } from '../../../../types/QuestionAnswer'
import MarkdownContent from '../../../atoms/MarkdownContent/MarkdownContent'
import { QuestionResponses } from '../../../../types/QuestionResponses'
import StarterDataError from '../../../../types/StarterDataError'
import StarterErrorCodes from '../../../../types/ErrorCodes'
import FormFile from '../../../../types/FormFile'
import FormFileInput from '../../../molecules/FormFileInput/FormFileInput'
import ProgressBar from '../../../molecules/ProgressBar/ProgressBar'
import { LoadingButton } from '@mui/lab'
import CommonBackButton from '../../../atoms/CommonBackButton/CommonBackButton'
import useTrackPageView from "../../../../helpers/hooks/use-track-page-view"
import useGoogleTagManager from '../../../../helpers/analytics/use-google-tag-manager'
import GtmEventName from '../../../../helpers/analytics/GtmEventName'

const ProgrammeApplicationForm = () => {
    const { programmeApplicationFormId: formId } = useParams()

    const { t } = useTranslation()
    const { state: programmeState } = useContext(ProgrammeContext)

    const navigate = useNavigate()
    const { trackFormSubmit } = useGoogleTagManager()

    const { retrieveProgrammeApplicationForm, getProgrammeApplicationForm, submitProgrammeApplication } = useProgrammeApplicationFormRepository()
    const [programmeApplicationForm, setProgrammeApplicationForm] = useState(getProgrammeApplicationForm(formId))
    const [responses, setResponses] = useState<QuestionAnswer[]>([])
    const [files, setFiles] = useState<FormFile[]>([])
    const [attemptedSubmission, setAttemptedSubmission] = useState(false)
    const [programmeApplicationFormId, setProgrammeApplicationFormId] = useState<number>(0)
    const [hasErrors, setHasErrors] = useState(false)
    const [isUploading, setIsUploading] = useState(false)
    const [uploadProgress, setUploadProgress] = useState<number>(0)

    const [dialogTitle, setDialogTitle] = useState<string>()
    const [dialogMessage, setDialogMessage] = useState<string>()
    const [dialogConfirmButton, setDialogConfirmButton] = useState<string>(``)
    const [dialogAcknowledgeOnly, setDialogAcknowledgeOnly] = useState(false)
    const [dialogBackHomeOnDismiss, setDialogBackHomeOnDismiss] = useState(false)
    const [dialogShow, setDialogShow] = useState(false)

    useTrackPageView(() => {
        if (programmeApplicationForm) {
            return {
                title: `Programme Application Page`,
                item: programmeApplicationForm.name,
            }
        }
    }, [programmeApplicationForm])

    useEffect(() => {
        if (formId) {
            setProgrammeApplicationFormId(parseInt(formId))
        }

        if (formId && !programmeApplicationForm) {
            retrieveProgrammeApplicationForm(parseInt(formId), true)
        }
    }, [])

    useEffect(() => {
        const foundProgrammeApplicationForm = getProgrammeApplicationForm(programmeApplicationFormId)

        if (!foundProgrammeApplicationForm?.allowMultipleSubmissions && foundProgrammeApplicationForm?.hasApplied) {
            showApplicationAlreadySubmittedErrorDialog()
        }

        if (foundProgrammeApplicationForm) {
            setProgrammeApplicationForm(foundProgrammeApplicationForm)
        }

        if (programmeState.programmeApplicationForms?.error) {
            showFormNotFoundErrorDialog()
        }
    }, [programmeState.programmeApplicationForms, programmeApplicationFormId])

    useEffect(() => {
        if (programmeApplicationForm) {
            setInitialResponses(programmeApplicationForm)
            setInitialFiles(programmeApplicationForm)
        }
    }, [programmeApplicationForm])

    const setInitialResponses = (programmeApplicationForm: ApplicationForm) => {
        const defaultResponses: QuestionAnswer[] = []

        programmeApplicationForm.questions.forEach(question => {
            defaultResponses.push(emptyQuestionAnswerFromQuestionProps(question))
        })

        setResponses(defaultResponses)
    }
    const setInitialFiles = (programmeApplicationForm: ApplicationForm) => {
        const defaultFiles: FormFile[] = []

        programmeApplicationForm.files.forEach((file) => {
            const sameNameFiles = programmeApplicationForm.files.filter(f => f.name.toLowerCase() === file.name.toLocaleLowerCase())
            const indexAmongSameNameFiles = sameNameFiles.length > 1 ? sameNameFiles.indexOf(file) + 1 : undefined

            defaultFiles.push({
                ...file,
                name: indexAmongSameNameFiles ? `${file.name}-${indexAmongSameNameFiles}` : file.name,
            })
        })

        setFiles(defaultFiles)
    }

    const showSuccessDialog = () => {
        const confirmationMessage = programmeApplicationForm?.submissionConfirmationMessage ?? t(`programme_application_submission_success_message`)

        setDialogTitle(t(`programme_application_submission_success_title`))
        setDialogMessage(confirmationMessage)
        setDialogConfirmButton(t(`common_button_confirmation`))
        setDialogBackHomeOnDismiss(true)
        setDialogAcknowledgeOnly(true)
        setDialogShow(true)
    }

    const showFormNotFoundErrorDialog = () => {
        setDialogTitle(t(`programme_application_form_not_found_title`))
        setDialogMessage(t(`programme_application_form_not_found_message`))
        setDialogConfirmButton(t(`common_button_confirmation`))
        setDialogBackHomeOnDismiss(true)
        setDialogAcknowledgeOnly(true)
        setDialogShow(true)
    }

    const showApplicationAlreadySubmittedErrorDialog = () => {
        setDialogTitle(t(`programme_application_already_submitted_title`))
        setDialogMessage(t(`programme_application_already_submitted_message`))
        setDialogConfirmButton(t(`common_button_confirmation`))
        setDialogBackHomeOnDismiss(true)
        setDialogAcknowledgeOnly(true)
        setDialogShow(true)
    }

    const showFileTooBigErrorDialog = () => {
        setDialogTitle(t(`programme_application_file_too_big_title`))
        setDialogMessage(t(`programme_application_file_too_big_message`))
        setDialogConfirmButton(t(`common_button_confirmation`))
        setDialogBackHomeOnDismiss(false)
        setDialogAcknowledgeOnly(true)
        setDialogShow(true)
    }

    const showMembershipNotActiveErrorDialog = () => {
        setDialogTitle(t(`programme_application_membership_not_active_title`))
        setDialogMessage(t(`programme_application_membership_not_active_message`))
        setDialogConfirmButton(t(`common_button_confirmation`))
        setDialogBackHomeOnDismiss(true)
        setDialogAcknowledgeOnly(true)
        setDialogShow(true)
    }

    const showDefaultErrorDialog = () => {
        setDialogTitle(t(`error_default_title`))
        setDialogMessage(t(`error_default_message`))
        setDialogConfirmButton(t(`common_back_to_home`))
        setDialogBackHomeOnDismiss(true)
        setDialogAcknowledgeOnly(false)
        setDialogShow(true)
    }

    const handleErrorDialogClose = () => {
        if (dialogBackHomeOnDismiss) {
            navigate(Route.HOME)
        } else {
            setDialogShow(false)
        }
    }

    const handleDialogDismiss = () => {
        setDialogShow(false)
        setIsUploading(false)
    }

    const handleQuestionChange = (response: QuestionAnswer) => {
        const newResponses = [
            ...responses,
        ]

        const responseIndex = newResponses.findIndex(a => a.key === response.key)

        if (responseIndex >= 0) {
            newResponses[responseIndex] = response
        }

        setResponses(newResponses)
    }

    const handleFileChange = (formFile: FormFile) => {
        const newFiles = files.map(file => {
            if (file.id === formFile.id) {
                return {
                    ...formFile,
                    name: file.name,
                }
            }
            return file
        })

        setFiles(newFiles)
    }

    const handleUploadProgress = (progressPercent: number) => {
        setUploadProgress(progressPercent)
    }

    const handleTermsAndConditionsClick = () => {
        if (programmeApplicationForm?.termsAndConditions) {
            navigate(Route.PROGRAMME_APPLICATION_TERMS_AND_CONDITIONS, { state: programmeApplicationForm })
        }
    }

    const handleSubmitClick = () => {
        trackFormSubmit(GtmEventName.SUBMIT_PROGRAMME_APPLICATION, programmeApplicationForm?.name)

        setAttemptedSubmission(true)
        setIsUploading(true)
        setHasErrors(false)

        const responsesObject = getResponsesObject()
        const filesObject = getFilesObject()

        if (responsesObject && filesObject) {
            const programmeApplicationSubmission = {
                programmeApplicationFormId,
                responses: responsesObject,
                files: filesObject,
            }

            submitProgrammeApplication(programmeApplicationSubmission, handleUploadProgress)
                .then(handleSubmissionSuccess)
                .catch(handleSubmissionError)
        } else {
            setIsUploading(false)
            setHasErrors(true)
        }
    }

    const handleSubmissionSuccess = () => {
        setIsUploading(false)
        showSuccessDialog()
    }

    const handleSubmissionError = (error: StarterDataError) => {
        setUploadProgress(0)

        switch (error.errorCode) {
            case StarterErrorCodes.APPLICATION_ALREADY_SUBMITTED_ERROR:
                showApplicationAlreadySubmittedErrorDialog()
                break
            case StarterErrorCodes.FILE_IS_TOO_BIG:
                showFileTooBigErrorDialog()
                break
            case StarterErrorCodes.MEMBERSHIP_NOT_ACTIVE:
                showMembershipNotActiveErrorDialog()
                break
            default:
                showDefaultErrorDialog()
        }
    }

    const getResponsesObject = (): QuestionResponses | undefined => {
        const responsesObject: QuestionResponses = {}

        let isFormValid = true

        responses.forEach(response => {
            responsesObject[response.key] = response

            if (response.mandatory && isQuestionAnswerEmpty(response)) {
                isFormValid = false
            }
        })

        return isFormValid ? responsesObject : undefined
    }

    const getFilesObject = (): FormFile[] | undefined => {
        const filesObject: FormFile[] = []

        let areFilesValid = true

        files.forEach(file => {
            if (file.hasError || (file.mandatory && !file.value)) {
                areFilesValid = false
            } else if (file.value) {
                filesObject.push(file)
            }
        })

        return areFilesValid ? filesObject : undefined
    }

    const dialog = (
        <ConfirmationDialog
            confirmationTitle={dialogTitle}
            confirmationText={dialogMessage}
            buttonText={dialogConfirmButton}
            defaultOpen={dialogShow}
            onConfirm={handleErrorDialogClose}
            onDismiss={handleDialogDismiss}
            acknowledgeOnly={dialogAcknowledgeOnly} />
    )

    if (!programmeApplicationForm) {
        return (
            <SplashScreenGuard>
                <Box>
                    <ProgrammeApplicationFormSkeleton />
                    {dialog}
                </Box>
            </SplashScreenGuard>
        )
    }

    const fileInputRenderer = (formFile: FormFile) => {
        return <FormFileInput formFile={formFile} onFileChange={handleFileChange} attemptedSubmission={attemptedSubmission} disabled={isUploading} />
    }

    return (
        <SplashScreenGuard>
            <Container>
                <Box mb={2}>
                    <CommonBackButton />
                </Box>

                <Typography variant='h5'>{programmeApplicationForm.name}</Typography>

                {
                    programmeApplicationForm.description ?
                        <MarkdownContent content={programmeApplicationForm.description} /> : <Box mt={3}></Box>
                }

                {
                    programmeApplicationForm.termsAndConditions &&
                    <Link onClick={handleTermsAndConditionsClick} mb={3}>{t(`programme_application_form_terms_and_conditions`)}</Link>
                }

                <Questionnaire questionsList={programmeApplicationForm.questions} attemptedSubmission={attemptedSubmission} disabled={isUploading} onQuestionChange={handleQuestionChange} />

                <Box mb={2}>
                    {programmeApplicationForm.files.map(fileInputRenderer)}
                </Box>

                <ProgressBar progress={uploadProgress} mb={2} />

                {
                    hasErrors &&
                    <Box mb={2}>
                        <Typography color='error'>{t(`programme_application_has_errors_message`)}</Typography>
                    </Box>
                }

                <LoadingButton variant='contained' loading={isUploading} onClick={handleSubmitClick}>{t(`programme_application_form_submit`)}</LoadingButton>

                {dialog}
            </Container>
        </SplashScreenGuard>
    )
}

export default ProgrammeApplicationForm