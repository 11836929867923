import { fetchLimitedDigitalResourcesByCategory, fetchDigitalResourceById, fetchDigitalResourceCategories, fetchDigitalResourcesByCategory } from "../../clients/digital-resource"
import fromStrapiDigitalResourceDetail from "../../helpers/mapping/upme-digital-resource-detail"
import fromStrapiDigitalResourceCategory from "../../helpers/mapping/upme-digital-resource-category"
import { MembershipType } from "../../types/MembershipType"
import { DigitalResourceCategory } from "../../types/DigitalResourceCategory"
import { DigitalResourceDetail } from "../../types/DigitalResourceDetail"
import fromStrapiUpmeLimitedDigitalResourceDetail from "../../helpers/mapping/upme-limited-digital-resource-by-category"
import { UpmeLimitedDigitalResourceDetailByCategory } from "../../types/LimitedDigitalResourceDetailByCategory"

class DigitalResourceRepository {
    async retrieveDigitalResourcesByCategory(categoryId: number, membershipType: MembershipType): Promise<DigitalResourceDetail[]> {
        const upmeDigitalResourcesByCategoryResponse = await fetchDigitalResourcesByCategory(categoryId, membershipType)
        const digitalResourceByCategory = upmeDigitalResourcesByCategoryResponse.data.map(fromStrapiDigitalResourceDetail)

        digitalResourceByCategory.sort((a: DigitalResourceDetail, b: DigitalResourceDetail) => {
            if (a.order === b.order) {
                const dateA = new Date(a.date)
                const dateB = new Date(b.date)
                if (dateA > dateB) {
                    // eslint-disable-next-line no-magic-numbers
                    return -1
                }
                if (dateA < dateB) {
                    return 1
                }
                return 0
            }

            if (a.order === 0) {
                return 1
            }

            if (b.order === 0) {
                // eslint-disable-next-line no-magic-numbers
                return -1
            }

            return a.order - b.order
        })

        return digitalResourceByCategory
    }
    async retrieveDigitalResourceCategories(): Promise<DigitalResourceCategory[]> {
        const upmeDigitalResourceCategoriesResponse = await fetchDigitalResourceCategories()
        const digitalResourceCategories = upmeDigitalResourceCategoriesResponse.data.map(fromStrapiDigitalResourceCategory)
        return digitalResourceCategories
    }

    async retrieveDigitalResourceById(id: number): Promise<DigitalResourceDetail> {
        const upmeDigitalResourceByIdResponse = await fetchDigitalResourceById(id)
        const digitalResourceById = fromStrapiDigitalResourceDetail(upmeDigitalResourceByIdResponse.data)
        return digitalResourceById
    }

    async retrieveUpmeLimitedDigitalResourcesByCategory(membershipType: MembershipType): Promise<UpmeLimitedDigitalResourceDetailByCategory[]> {
        const limitedDigitalResourcesByCategoryResponses = await fetchLimitedDigitalResourcesByCategory(membershipType)

        const resources = fromStrapiUpmeLimitedDigitalResourceDetail(limitedDigitalResourcesByCategoryResponses)

        resources.sort((a: UpmeLimitedDigitalResourceDetailByCategory, b: UpmeLimitedDigitalResourceDetailByCategory) => {
            if (a.categoryOrder === b.categoryOrder) {
                return a.name.localeCompare(b.name)
            }

            if (a.categoryOrder === 0) {
                return 1
            }

            if (b.categoryOrder === 0) {
                // eslint-disable-next-line no-magic-numbers
                return -1
            }

            return a.categoryOrder - b.categoryOrder
        })

        return resources
    }

}

export default DigitalResourceRepository