import { useContext, useEffect, useState } from 'react'
import CustomSelect, { CustomSelectOption, CustomSelectProps } from '../../atoms/CustomSelect/CustomSelect'
import { AppContext } from '../../../stores/App/AppStore'
import { TrainingProvider } from '../../../types'
import { useTrainingProviderRepository } from '../../../repositories/TrainingProvider/use-training-provider-repository'

type TrainingProviderSelectProps = Omit<CustomSelectProps, `options`>

const createOptions = (trainingProvider: TrainingProvider[]) => {
    const options: CustomSelectOption[] = []

    trainingProvider.forEach((i) => options.push(
        {
            label: i.trainerName || i.trainerId.toString(),
            value: i.trainerId,
        },
    ))

    return options
}

const TrainingProviderSelect = (props: TrainingProviderSelectProps) => {
    const { state } = useContext(AppContext)
    const { retrieveTrainingProvider } = useTrainingProviderRepository()
    const [options, setOptions] = useState<CustomSelectOption[]>([])

    useEffect(() => {
        if (state.trainingProvider.length < 1) {
            retrieveTrainingProvider()
        }
    }, [])

    useEffect(() => {
        setOptions(createOptions(state.trainingProvider))
    }, [state.trainingProvider])

    return (
        <CustomSelect
            {...props}
            options={options}
        />
    )
}

export default TrainingProviderSelect