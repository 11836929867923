import moment from "moment"

const programmeActivationSteps = (optInDate: string | undefined, daysBeforeActivation: number | undefined, active: boolean): string[] => {
    if (!optInDate || !daysBeforeActivation) {
        return []
    }

    const dateFormat = `YYYY/MM/DD`

    const stepOne = `Programme joined on ${moment(optInDate).format(dateFormat)}`
    let stepTwo = `Your account has been activated`

    if (!active) {
        const creationDate = moment(optInDate).add(daysBeforeActivation, `days`).format(dateFormat)
        stepTwo = `Account will be created by ${creationDate}`
    }

    return [
        stepOne,
        stepTwo,
    ]
}

export {
    programmeActivationSteps,
}