import SessionRepository from "../../repositories/Session/session.repository"
import { CheckUnionMembershipStatusResponse, LastClaimDataResponse, MembershipPlanResponse, MembershipSettingsResponse, UserInfoServiceResponse, UtapClaimsListServiceResponse } from "../../types"
import { ImportedMembershipResponse } from "../../types/ImportedMembership"
import { CancelMembershipResponse, MembershipResponse, ResumeRecurringMembershipResponse } from "../../types/Membership"
import { MyInfoUserDataResponse } from "../../types/MyInfoUserData"
import { PartnerResponse } from "../../types/Partner"
import { ProfilePictureResponse } from "../../types/ProfilePicture"
import { SessionResponse } from "../../types/Session"
import { UtapStatusResponse } from "../../types/UtapStatus"

const sessionRepository = new SessionRepository()

type UserState = {
    user?: UserInfoServiceResponse
    session?: SessionResponse
    membership?: MembershipResponse
    importedMembership?: ImportedMembershipResponse
    myInfoUserData?: MyInfoUserDataResponse,
    unionMembershipStatus?: CheckUnionMembershipStatusResponse
    membershipSettings?: MembershipSettingsResponse,
    membershipPlan?: MembershipPlanResponse
    partner?: PartnerResponse,
    utapClaims?: UtapClaimsListServiceResponse,
    utapStatus?: UtapStatusResponse
    lastClaimData?: LastClaimDataResponse,
    membershipCancelAtPeriodEnd?: CancelMembershipResponse,
    profilePicture?: ProfilePictureResponse,
    resumeRecurringMembership?: ResumeRecurringMembershipResponse
}

const userInitialState: UserState = {
    user: {},
    session: {
        data: sessionRepository.get(),
    },
    membership: undefined,
    importedMembership: undefined,
    myInfoUserData: undefined,
    unionMembershipStatus: undefined,
    membershipSettings: undefined,
    membershipPlan: undefined,
    partner: undefined,
    utapClaims: undefined,
    utapStatus: undefined,
    lastClaimData: undefined,
    membershipCancelAtPeriodEnd: undefined,
    resumeRecurringMembership: undefined,
}

export { userInitialState }
export type { UserState }