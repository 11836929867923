import { absoluteImageUrl } from '../utils/environment'
import { JoinPage } from '../../types'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const fromStrapiJoinPage = (strapiData: any): JoinPage => {
    const { attributes } = strapiData
    const { title, description, bannerImage } = attributes
    const bannerImageURL = absoluteImageUrl(bannerImage.data.attributes.url)

    return {
        title,
        description,
        bannerImageURL,
    }
}

export { fromStrapiJoinPage }