import ReactDOM from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'
import TagManager from 'react-gtm-module'

import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import reportWebVitals from './reportWebVitals'
import { router } from './router'
import './helpers/localisation'
import './index.css'
import { DebugMode } from './types/DebugMode'
import LocalStorageKeys from './types/LocalStorageKeys'
import setApplicationMetadata from './set-manifest'

const queryParams = new URLSearchParams(window.location.search)
const debugMode = queryParams.get(`debugMode`)

if (debugMode) {
    switch (debugMode) {
        case DebugMode.DEBUG_ON:
            localStorage.setItem(LocalStorageKeys.DEBUG_MODE, DebugMode.DEBUG_ON)
            break
        case DebugMode.DEBUG_OFF:
            localStorage.removeItem(LocalStorageKeys.DEBUG_MODE)
            break
    }
}

setApplicationMetadata()

if (!process.env.REACT_APP_GTM_CONTAINER_ID) {
    throw new Error(`REACT_APP_GTM_CONTAINER_ID must be provided`)
}

TagManager.initialize({
    gtmId: process.env.REACT_APP_GTM_CONTAINER_ID!,
})

const root = ReactDOM.createRoot(
    document.getElementById(`root`) as HTMLElement,
)
root.render(
    <RouterProvider router={router} />,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
