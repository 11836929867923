import React from 'react'
import { Container, Grid, Skeleton, Box, Divider } from '@mui/material'
import SkeletonProps from '../../../../types/SkeletonProps'

import styles from './ProfileUpdateSummary.module.scss'

const ProfileUpdateSummarySkeleton: React.FC<SkeletonProps> = (props: SkeletonProps) => {
    const { children, condition } = props

    if (condition) {
        return children
    }

    return (
        <Container>
            <Grid container display='flex' direction='column'>
                <Grid item width='100%'>
                    <Skeleton variant="text" sx={{ fontSize: `1.2rem` }} width='70%' />
                </Grid>
                <Box className={styles.personalInfo}>
                    <Skeleton variant="text" sx={{ fontSize: `1rem` }} width='40%' />
                    <Skeleton variant="text" sx={{ fontSize: `1rem` }} width='30%' />
                    <Skeleton variant="text" sx={{ fontSize: `1rem` }} width='40%' />
                    <Skeleton variant="text" sx={{ fontSize: `1rem` }} width='30%' />
                    <Skeleton variant="text" sx={{ fontSize: `1rem` }} width='40%' />
                    <Skeleton variant="text" sx={{ fontSize: `1rem` }} width='30%' />
                    <Skeleton variant="text" sx={{ fontSize: `1rem` }} width='40%' />
                    <Skeleton variant="text" sx={{ fontSize: `1rem` }} width='30%' />
                </Box>
                <Divider />
                <Grid item width='100%'>
                    <Skeleton variant="text" sx={{ fontSize: `1.2rem` }} width='70%' />
                </Grid>
                <Box className={styles.interests}>
                    <Skeleton variant="text" sx={{ fontSize: `1rem` }} width='50%' />
                    <Skeleton variant="text" sx={{ fontSize: `1rem` }} width='50%' />
                    <Skeleton variant="text" sx={{ fontSize: `1rem` }} width='50%' />
                </Box>
            </Grid>
        </Container>
    )
}

export default ProfileUpdateSummarySkeleton