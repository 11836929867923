import { Box, BoxProps, Grid, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import HorizontalScrollComponentSkeleton from './HorizontalScroll.skeleton'
import styles from './HorizontalScrollComponent.module.scss'

interface WithId {
    id: number
}

interface HorizontalScrollComponentProps<T extends WithId> extends BoxProps {
    items?: T[]
    listTitle?: string
    noItemsMessage?: string
    seeAllRoute?: string
    isLandingPage?: boolean
    renderItem: (item: T) => React.ReactNode
    onSeeAllClick?: () => void
    maxNumOfItems?: number
}

const HorizontalScrollComponent = <T extends WithId>(props: HorizontalScrollComponentProps<T>) => {
    const { items, listTitle, noItemsMessage, seeAllRoute, isLandingPage, renderItem, onSeeAllClick, maxNumOfItems, ...inherited } = props

    const { t } = useTranslation()

    const previewItems = maxNumOfItems ? items?.slice(0, maxNumOfItems) : items

    const scrollingWrapperComponent = previewItems?.map((item: T, index) => {
        const key = item.id ?? index

        return (
            <Box className={styles.cardWrapper} key={key}>
                {renderItem(item)}
            </Box>
        )
    })

    if (!previewItems) {
        return <HorizontalScrollComponentSkeleton />
    }

    if (!previewItems?.length && !noItemsMessage) {
        return <></>
    }

    const scrollingWrapperStyle = {
        justifyContent: previewItems && previewItems?.length > 1 ? `flex-start` : `center`,
    }

    const itemsComponents = (
        <Grid item className={styles.scrollingWrapper} display={`flex`} sx={scrollingWrapperStyle}>
            {scrollingWrapperComponent}
        </Grid>
    )

    const noItemsMessageComponent = (
        <Grid item className={styles.noItemsMessage}>
            <Typography variant='body2' color='functional.text.lighter'>{noItemsMessage}</Typography>
        </Grid>
    )

    const itemsList = !previewItems?.length && noItemsMessage ? noItemsMessageComponent : itemsComponents
    const textForListAllRoute = isLandingPage ? t(`landing_page_learn_more`) : t(`common_see_all`)

    const handleSeeAllClick = () => {
        onSeeAllClick?.()
    }

    return (
        <Box {...inherited}>
            <Grid item className={styles.componentList} display={`flex`}>
                {
                    listTitle &&
                    <Typography className={styles.moduleTitle} variant='h6' mb={2}>{listTitle}</Typography>
                }
                {
                    seeAllRoute &&
                    <Grid item className={styles.seeAll}>
                        <Link to={`${seeAllRoute}`} onClick={handleSeeAllClick}>
                            <Typography className={styles.listAllRoute} variant='subtitle1'>{textForListAllRoute}</Typography>
                        </Link>
                    </Grid>
                }
            </Grid>
            {itemsList}
        </Box>
    )
}

export default HorizontalScrollComponent

