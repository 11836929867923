import { SyntheticEvent, useState } from "react"
import { Autocomplete, Grid, TextField } from "@mui/material"
import { t } from "i18next"
import { OptionValues } from "../../../../types/QuestionComponent"

interface DropDownSelectQuestionProps {
    key: string
    isMandatory: boolean
    question: string
    options: OptionValues[]
    attemptedSubmission?: boolean
    disabled?: boolean
    onChange: (e: string) => void
}

interface CustomOptionValues extends OptionValues {
    label: string
}

export const DropDownSelectQuestion = (props: DropDownSelectQuestionProps) => {
    const { key, isMandatory, question, options, attemptedSubmission, disabled, onChange } = props
    const [selectedOption, setSelectedOption] = useState<CustomOptionValues | undefined>(undefined)

    const createOptions = (options: OptionValues[]): CustomOptionValues[] => {
        return options.map((item) => {
            return {
                ...item,
                label: item.value,
            }
        })
    }

    const mappedOptions = createOptions(options)

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleOptionChange = (_: SyntheticEvent, newValue: any) => {
        setSelectedOption(newValue)
        onChange(newValue.value)
    }

    const helperText = isMandatory && !selectedOption ? t(`event_required_field`) : null
    const inlineStyle = isMandatory && !selectedOption ? { color: `#d32f2f` } : {}

    const isOptionEqualToValue = (option: CustomOptionValues, value: CustomOptionValues) => {
        return option?.value === value.value
    }

    const getOptionLabel = (option: CustomOptionValues) => option?.label || ``
    const isError = isMandatory && !selectedOption && attemptedSubmission

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const renderInput = (params: any) => (
        <TextField
            {...params}
            sx={inlineStyle}
            label={question}
            required={isMandatory}
            helperText={helperText}
            error={isError}
            color='functional'
        />
    )

    return (
        <Grid container direction="column" mb={3}>
            <Grid item xs={12}>
                <Autocomplete
                    key={key}
                    sx={{ 'fontSize': `16px` }}
                    value={selectedOption}
                    onChange={handleOptionChange}
                    options={mappedOptions}
                    getOptionLabel={getOptionLabel}
                    isOptionEqualToValue={isOptionEqualToValue}
                    renderInput={renderInput}
                    disabled={disabled}
                />
            </Grid>
        </Grid>

    )
}
