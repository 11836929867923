import { fetchVersion } from "../../clients/version"

class VersionRepository {
    async retrieve(): Promise<string> {
        const { version } = await fetchVersion()

        return version
    }
}

export default VersionRepository