import { useContext } from "react"

import { MembershipContext } from "../../stores/Membership/MembershipStore"
import { ServiceResponse } from "../../types"
import { UserInterestTopic } from "../../types/UserInterestTopic"
import UserInterestTopicsRepository from "./user-interest-topics.repository"
import { MembershipActions } from "../../stores/Membership/MembershipReducer"
import { starterDataError } from "../../helpers/utils/error-handling"

const useUserInterestTopicsRepository = () => {
    const { dispatch } = useContext(MembershipContext)

    const userInterestTopicsRepository = new UserInterestTopicsRepository()

    const retrieveUserInterestTopics = () => {
        const userInterestsTopicsResponse: ServiceResponse<UserInterestTopic[]> = {}

        userInterestTopicsRepository.retrieveUserInterestsTopics()
            .then(userInterestTopics => {
                userInterestsTopicsResponse.data = userInterestTopics
            })
            .catch(error => {
                userInterestsTopicsResponse.error = starterDataError(error)
            })
            .finally(() => {
                dispatch({
                    type: MembershipActions.USER_INTEREST_TOPICS_RETRIEVED,
                    userInterestTopics: userInterestsTopicsResponse,
                })
            })
    }

    const setUserInterests = (benefitIds: number[]) => {
        return userInterestTopicsRepository.setUserInterests(benefitIds)
    }

    return {
        retrieveUserInterestTopics,
        setUserInterests,
    }
}

export { useUserInterestTopicsRepository }