import { Autocomplete } from '@mui/material'
import CustomInput from '../CustomInput/CustomInput'
import { SyntheticEvent, useEffect, useState } from 'react'

type CustomSelectOption = {
    id?: string
    label: string
    value: string | number
}

type CustomSelectProps = {
    disabled?: boolean
    error?: boolean
    insetLabel?: boolean
    label: string
    onChange: (value: string | number | undefined) => void
    options: CustomSelectOption[]
    placeholder?: string
    readOnly?: boolean
    value: string | number | undefined
    isSignUp: boolean
}

const CustomSelect = ({
    disabled,
    error,
    insetLabel,
    label,
    onChange,
    options,
    placeholder,
    readOnly,
    value,
    isSignUp,
}: CustomSelectProps) => {
    const initialValue = options.find((o) => o.value === value) ?? null
    const [inputValue, setInputValue] = useState(``)
    const [localValue, setLocalValue] = useState<CustomSelectOption | null>(initialValue)
    const [localError, setLocalError] = useState<boolean>(!!error)

    useEffect(() => {
        setLocalError(!!error)
    }, [error])

    useEffect(() => {
        const matchedOption = options.find((o) => o.value === value)
        if (matchedOption) {
            setLocalValue(matchedOption)
        } else {
            setLocalValue(null)
        }
    }, [value])

    const handleChange = (_event: SyntheticEvent, newValue: CustomSelectOption | null) => {
        setLocalError(!newValue)
        onChange(newValue?.value ?? undefined)
    }
    const handleInputChange = (_event: SyntheticEvent, newInputValue: string) => {
        const hasValue = !!newInputValue.trim()
        setLocalError(!hasValue)
        setInputValue(newInputValue)
    }

    return (
        <>
            <Autocomplete
                options={options}
                renderOption={(props, option) => {
                    return (
                        <li {...props} key={option.value}>
                            {option.label}
                        </li>
                    )
                }}
                value={localValue}
                onChange={handleChange}
                inputValue={inputValue}
                onInputChange={handleInputChange}
                isOptionEqualToValue={(o, v) => o.value === v.value}
                renderInput={(params) => (
                    <CustomInput
                        insetLabel={insetLabel}
                        label={label}
                        value={inputValue}
                        params={params}
                        placeholder={placeholder}
                        readOnly={readOnly}
                        error={localError}
                        isSignUp={isSignUp}
                    />
                )}
                disabled={disabled || readOnly}
            />
        </>
    )
}

export default CustomSelect
export type {
    CustomSelectOption,
    CustomSelectProps,
}