import { QuestionProps } from "./QuestionComponent"
import QuestionType from "./QuestionType"
import { QuestionnaireComponentType } from "./QuestionnaireComponentType"

interface QuestionAnswer {
    id: number
    key: string
    question: string
    answer: string | string[]
    mandatory: boolean
    type: QuestionType
}

export interface StrapiQuestionAnswer {
    question: string
    answer: string | string[]
}

export const emptyQuestionAnswerFromQuestionProps = (questionProps: QuestionProps): QuestionAnswer => {
    let questionType: QuestionType
    let answer: string[] | string = ``

    switch (questionProps.__component) {
        case QuestionnaireComponentType.LONG_TEXT_QUESTION:
            questionType = QuestionType.LongText
            break
        case QuestionnaireComponentType.SELECT_QUESTION:
            questionType = QuestionType.Select
            break
        case QuestionnaireComponentType.SHORT_TEXT_QUESTION:
            questionType = QuestionType.ShortText
            break
        case QuestionnaireComponentType.RADIO_QUESTION:
            questionType = QuestionType.Radio
            break
        case QuestionnaireComponentType.MULTIPLE_CHOICE_QUESTION:
            questionType = QuestionType.Multi
            answer = []
            break
    }

    return {
        id: questionProps.id,
        key: `${questionType}-question_${questionProps.id}`,
        question: questionProps.question,
        answer,
        mandatory: questionProps.mandatoryField,
        type: questionType,
    }
}

export const isQuestionAnswerEmpty = (questionAnswer: QuestionAnswer): boolean => {
    switch (questionAnswer.type) {
        case QuestionType.LongText:
            return questionAnswer.answer === ``
        case QuestionType.ShortText:
            return questionAnswer.answer === ``
        case QuestionType.Radio:
            return questionAnswer.answer === ``
        case QuestionType.Multi:
            return !questionAnswer.answer.length
        case QuestionType.Select:
            return questionAnswer.answer === ``
    }
}

export const toStrapiQuestionAnswer = (questionAnswer: QuestionAnswer): StrapiQuestionAnswer => {
    return {
        question: questionAnswer.question,
        answer: questionAnswer.answer,
    }
}

export default QuestionAnswer