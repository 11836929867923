import { useCallback, useContext, useEffect, useRef, useState } from "react"
import { Box, Container, Grid, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"

import { useUpmeOnboardingRepository } from "../../../../repositories/UPMEOnboarding/use-upme-onboarding-repository"
import UpmeUpdateNowFooter from "../../../molecules/UpmeUpdateNowFooter/UpmeUpdateNowFooter"
import HeaderPageComponent from "../../../molecules/HeaderPageComponent/HeaderPageComponent"
import { UPMEContentContext } from "../../../../stores/UPMEContent/UPMEContentStore"
import UpmeBenefitCard from "../../../molecules/UpmeBenefitCard/UpmeBenefitCard"
import CommonBackButton from "../../../atoms/CommonBackButton/CommonBackButton"
import SplashScreenGuard from "../../../../router/guards/SplashScreenGuard"
import { UpmeBenefitCategory } from "../../../../types/UpmeBenefitCategory"
import ScrollingTabs from "../../../organisms/ScrollingTabs/ScrollingTabs"
import { ScrollingTabItem } from "../../../../types/ScrollingTabItem"
import UpmeBenefitsListSkeleton from "./UpmeBenefitsList.skeleton"
import { MembershipType } from "../../../../types/MembershipType"
import { UserContext } from "../../../../stores/User/UserStore"
import LocalStorageKeys from "../../../../types/LocalStorageKeys"
import { UpmeBenefit } from "../../../../types/UpmeBenefit"
import { Route } from "../../../../router"
import useTrackPageView from "../../../../helpers/hooks/use-track-page-view"
import useGoogleTagManager from '../../../../helpers/analytics/use-google-tag-manager'
import GtmEventName from '../../../../helpers/analytics/GtmEventName'

import styles from './UpmeBenefitsList.module.scss'

const SCROLL_EVENT_LISTENER_RESET_DELAY = 1000

interface UpmeBenefitCategoryWithAnchor extends UpmeBenefitCategory, ScrollingTabItem {
    anchorId: string,
}

const UpmeBenefitsList = () => {
    useTrackPageView(`NTUC Benefits Page`)

    const { t } = useTranslation()
    const { trackButtonClick } = useGoogleTagManager()

    const { retrieveUpmeBenefits, retrieveUpmeBenefitCategories } = useUpmeOnboardingRepository()

    const { state: userState } = useContext(UserContext)
    const { state: upmeContentState } = useContext(UPMEContentContext)

    const [tabIndex, setTabIndex] = useState(0)
    const [categories, setCategories] = useState<UpmeBenefitCategoryWithAnchor[]>()
    const categoriesTabsRef = useRef<HTMLButtonElement>(null)

    const userMembership = userState.membership?.data

    useEffect(() => {
        retrieveUpmeBenefitCategories()
        retrieveUpmeBenefits()

        return () => {
            disablePageScrollListener()
        }
    }, [])

    useEffect(() => {
        if (upmeContentState.benefits?.data && upmeContentState.benefitCategories?.data) {
            filterAndSetCategories()
        }
    }, [upmeContentState])

    useEffect(() => {
        enablePageScrollListener()
    }, [categories])

    const handlePageScroll = useCallback(() => {
        const benefits = upmeContentState.benefits?.data

        if (benefits) {
            for (const benefit of benefits) {
                const benefitElement = document.getElementById(benefitId(benefit))
                const boundingClientRect = benefitElement?.getBoundingClientRect()
                const middleHeight = document.documentElement.clientHeight >> 1

                if (boundingClientRect && (boundingClientRect.top < middleHeight && boundingClientRect.bottom > middleHeight)) {
                    categories?.forEach((category, index) => {
                        if (category.id === benefit.category.id) {
                            setTabIndex(index)
                        }
                    })
                    break
                }
            }
        }
    }, [upmeContentState, categories])

    useEffect(() => {
        return () => {
            disablePageScrollListener()
        }
    }, [handlePageScroll])

    const enablePageScrollListener = () => {
        window.addEventListener(`scroll`, handlePageScroll)
    }

    const disablePageScrollListener = (resetAfter?: number) => {
        window.removeEventListener(`scroll`, handlePageScroll)

        if (resetAfter) {
            setTimeout(() => {
                enablePageScrollListener()
            }, resetAfter)
        }
    }

    const filterAndSetCategories = () => {
        const availableCategories = upmeContentState.benefits?.data?.map(benefit => benefit.category.id)
        const displayableCategories = upmeContentState.benefitCategories?.data?.filter(category => {
            const found = availableCategories?.indexOf(category.id)
            return found != undefined && found >= 0
        })
        const categoriesWithAnchor = displayableCategories?.map(category => {
            const firstBenefitInList = upmeContentState.benefits?.data?.find(benefit => benefit.category.id === category.id)

            return {
                ...category,
                anchorId: firstBenefitInList ? benefitId(firstBenefitInList) : ``,
            }
        })
        setCategories(categoriesWithAnchor)
    }

    const benefitId = (benefit: UpmeBenefit) => {
        return `benefit-${benefit.id}`
    }

    const benefitCard = (benefit: UpmeBenefit) => {
        return <UpmeBenefitCard benefit={benefit} id={benefitId(benefit)} key={benefitId(benefit)} />
    }

    if (!categories || !upmeContentState.benefits?.data) {
        return (
            <SplashScreenGuard>
                <UpmeBenefitsListSkeleton />
            </SplashScreenGuard>
        )
    }

    const handleTabChange = (event: React.SyntheticEvent, newIndex: number) => {
        disablePageScrollListener(SCROLL_EVENT_LISTENER_RESET_DELAY)

        if (newIndex === 0) {
            window.scrollTo({
                top: 0,
                behavior: `smooth`,
            })
        } else {
            const anchorId = categories.at(newIndex)?.anchorId ?? ``
            const firstBenefit = document.getElementById(anchorId)
            const starterAppBarElement = document.getElementById(`starter-app-bar`)

            if (firstBenefit && starterAppBarElement && categoriesTabsRef?.current) {
                trackButtonClick(GtmEventName.NTUC_BENEFIT_CATEGORY_CLICK, categories.at(newIndex)?.name)

                const firstBenefitBoundingClientRect = firstBenefit.getBoundingClientRect()

                window.scrollBy({
                    top: firstBenefitBoundingClientRect.top - starterAppBarElement?.getBoundingClientRect().height - categoriesTabsRef.current.getBoundingClientRect().height,
                    behavior: `smooth`,
                })
            }
        }
    }

    const upmeFooter = userMembership?.type === MembershipType.STARTER && (<UpmeUpdateNowFooter />)

    const backButtonPath = localStorage.getItem(LocalStorageKeys.LAST_MENU_PAGE) ?? Route.UPME_CONVERSION_INTRO

    return (
        <SplashScreenGuard>
            <Box>
                <HeaderPageComponent />

                <Container className={styles.hiddeableHeader}>
                    <CommonBackButton path={backButtonPath} />
                    <Typography className={styles.pageTitle} variant="h3">{t(`upme_benefits_list_title`)}</Typography>
                </Container>

                <ScrollingTabs ref={categoriesTabsRef} items={categories} selectedIndex={tabIndex} onTabChange={handleTabChange} />

                <Container id="benefitsList" className={styles.container}>
                    <Grid className={styles.benefitsList} container direction="column" alignItems="center">
                        {
                            upmeContentState.benefits?.data?.map(benefitCard)
                        }
                    </Grid>
                    {upmeFooter}
                </Container>
            </Box>
        </SplashScreenGuard>
    )
}

export default UpmeBenefitsList