import { useContext } from 'react'

import { AppContext } from '../../stores/App/AppStore'
import { ActionTypes } from '../../stores/App/AppReducer'
import ContactUsRepository from './contact-us-repository'

const useContactUsRepository = () => {
    const { dispatch } = useContext(AppContext)
    const contactUsRepository = new ContactUsRepository()

    const retrieveContactUsPage = () => {
        contactUsRepository.getPage()
            .then((contactUsPage) => {
                dispatch({
                    type: ActionTypes.CONTACT_US_PAGE_RETRIEVED,
                    contactUsPage: contactUsPage,
                })
            })
            .catch((error) => {
                console.error(error.message)
            })
    }

    return {
        retrieveContactUsPage,
    }
}

export { useContactUsRepository }