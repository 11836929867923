import { toStrapiQuestionAnswer } from "./QuestionAnswer"
import { QuestionResponses, StrapiQuestionResponses } from "./QuestionResponses"

export type EventRegistration = {
    eventId: number
    responses: QuestionResponses
}

export const eventRegistrationPayload = (eventRegistration: EventRegistration) => {
    const strapiResponses: StrapiQuestionResponses = {}

    for (const questionResponse in eventRegistration.responses) {
        strapiResponses[questionResponse] = toStrapiQuestionAnswer(eventRegistration.responses[questionResponse])
    }

    return {
        eventId: eventRegistration.eventId,
        responses: strapiResponses,
    }
}