import { fetchMembershipSettings } from '../../clients/membership-settings'
import { fromStrapiMembershipSettings } from '../../helpers/mapping/membership-settings'
import { MembershipSettings } from '../../types'

class MembershipSettingsRepository {
    async retrieveMembershipSettings(): Promise<MembershipSettings> {
        const data = await fetchMembershipSettings()
        return fromStrapiMembershipSettings(data)
    }
}

export default MembershipSettingsRepository