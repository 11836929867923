import { Box, Skeleton } from "@mui/material"

import HighlightCarouselItemComponentSkeleton from "../HighlightCarouselItemComponent/HighlightCarouselItemComponent.skeleton"
import { HighlightCarouselItemImageAspectRatio } from "../HighlightCarouselItemComponent/HighlightCarouselItemComponent"

import styles from './HighlightCarousel.module.scss'

interface HighlightCarouselSkeletonProps {
    aspectRatio?: HighlightCarouselItemImageAspectRatio,
}

const HighlightCarouselSkeleton = (props: HighlightCarouselSkeletonProps) => {
    const { aspectRatio } = props

    return (
        <Box className={styles.carouselSkeleton}>
            <HighlightCarouselItemComponentSkeleton aspectRatio={aspectRatio} />
            <Skeleton className={styles.mobileStepperSkeleton} variant="rectangular" />
        </Box>
    )
}

export default HighlightCarouselSkeleton