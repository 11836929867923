import { useContext } from 'react'
import { Buffer } from 'buffer'

import { UserContext } from '../../stores/User/UserStore'
import { UserAction, UserActionTypes } from '../../stores/User/UserReducer'
import { CancelMembershipResponse, CheckUnionMembershipStatusResponse, Membership, MyInfoUserData, SignUpForm, ResumeRecurringMembershipResponse } from '../../types'
import MembershipRepository from './membership.repository'
import { MembershipResponse } from '../../types/Membership'
import LocalStorageKeys from '../../types/LocalStorageKeys'
import { ProfilePictures } from '../../components/pages/Profile/ProfilePictureSelection/ProfilePictureSelection.utils'
import { ProfilePictureResponse } from '../../types/ProfilePicture'
import { starterDataError } from '../../helpers/utils/error-handling'

const useMembershipRepository = () => {
    const { dispatch } = useContext(UserContext)
    const membershipRepository = new MembershipRepository()

    const saveMembership = async (signUpForm: SignUpForm, myInfoUserData: MyInfoUserData) => {
        return membershipRepository.createOne(signUpForm, myInfoUserData)
            .then((membership) => {
                dispatchMembership(membership, dispatch)
            })
    }

    const updateMembership = async (signUpForm: SignUpForm, membershipID: number) => {
        return membershipRepository.updateMembership(signUpForm, membershipID)
            .then((membership) => {
                dispatchMembership(membership, dispatch)
            })
            .catch((error) => {
                throw starterDataError(error)
            })
    }

    const retrieveMembership = async (): Promise<MembershipResponse> => {
        const membershipResponse: MembershipResponse = {}

        return membershipRepository.retrieveMembership()
            .then((membership: Membership) => {
                membershipResponse.data = membership

                if (membership.hasPendingSignupPayment) {
                    localStorage.setItem(LocalStorageKeys.CLIENT_ORDER_ID_KEY, membership.hasPendingSignupPayment)
                }

                return membershipResponse
            })
            .catch(error => {
                membershipResponse.error = starterDataError(error)
                return membershipResponse
            })
            .finally(() => {
                dispatch({
                    type: UserActionTypes.MEMBERSHIP_DATA_RETRIEVED,
                    data: membershipResponse,
                })
            })
    }

    const joinProgramme = async (programme: string) => {
        return membershipRepository.joinProgramme(programme)
            .then((membership) => {
                dispatchMembership(membership, dispatch)
            })
            .catch((error) => {
                console.error(error.message)
            })
    }

    const retrieveUnionMembership = async () => {
        const unionMembershipStatusData: CheckUnionMembershipStatusResponse = {}
        return membershipRepository.retrieveUnionMembership()
            .then((unionMembershipStatus) => {
                unionMembershipStatusData.data = unionMembershipStatus
            }).catch((error) => {
                unionMembershipStatusData.error = {
                    errorMessage: error.message,
                    errorCode: error.response.status,
                }
            }).finally(() => {
                dispatch({
                    type: UserActionTypes.UNION_MEMBERSHIP_STATUS_RECEIVED,
                    unionMembershipStatus: unionMembershipStatusData,
                })
            })
    }

    const checkUnionMembershipWithNRIC = async (nric: string) => {
        const unionMembershipStatusData: CheckUnionMembershipStatusResponse = {}
        return membershipRepository.retrieveUnionMembershipWithNRIC(nric)
            .then((unionMembershipStatus) => {
                unionMembershipStatusData.data = unionMembershipStatus
            })
            .catch((error) => {
                unionMembershipStatusData.error = starterDataError(error)
            }).finally(() => {
                dispatch({
                    type: UserActionTypes.UNION_MEMBERSHIP_STATUS_RECEIVED,
                    unionMembershipStatus: unionMembershipStatusData,
                })
            })
    }

    const cancelMembership = async (terminateOnCancelDate: Date) => {
        const cancelMembershipStatusData: CancelMembershipResponse = {}
        return membershipRepository.cancelMembership(terminateOnCancelDate)
            .then((membershipCancelAtPeriodEnd) => {
                cancelMembershipStatusData.data = membershipCancelAtPeriodEnd
            }).catch((error) => {
                cancelMembershipStatusData.error = starterDataError(error)
            }).finally(() => {
                dispatch({
                    type: UserActionTypes.CANCEL_MEMBERSHIP,
                    data: cancelMembershipStatusData,
                })
            })
    }

    const resumeRecurringMembership = async (resumeMembership: boolean) => {
        const resumeRecurringMembership: ResumeRecurringMembershipResponse = {}

        return membershipRepository.resumeRecurringMembership(resumeMembership)
            .then((data) => {
                resumeRecurringMembership.data = data
            }).catch((error) => {
                resumeRecurringMembership.error = starterDataError(error)
            }).finally(() => {
                dispatch({
                    type: UserActionTypes.RESUME_MEMBERSHIP,
                    data: resumeRecurringMembership,
                })
            })
    }

    const uploadProfilePicture = async (profilePictures: ProfilePictures) => {
        return membershipRepository.uploadProfilePicture(profilePictures)
            .then(() => {
                dispatch({
                    type: UserActionTypes.PROFILE_PICTURE_UPLOADED,
                    profilePictureThumbnail: profilePictures.thumbnail.dataUrl,
                })
            })
    }

    const retrieveProfilePicture = async () => {
        const profilePictureResponse: ProfilePictureResponse = {}

        return membershipRepository.retrieveProfilePicture()
            .then((profilePicture) => {
                const profilePictureBase64 = Buffer.from(profilePicture).toString(`base64`)

                profilePictureResponse.data = {
                    blob: new Blob([profilePicture]),
                    dataUrl: `data:image/jpeg;base64,${profilePictureBase64}`,
                }
            }).catch((error) => {
                profilePictureResponse.error = starterDataError(error)
            }).finally(() => {
                dispatch({
                    type: UserActionTypes.PROFILE_PICTURE_RETRIEVED,
                    profilePicture: profilePictureResponse,
                })
            })
    }

    const fetchAddressFromPostalCode = (postalCode: string) => {
        return membershipRepository.fetchAddressFromPostalCode(postalCode).then(result => { return result } )
    }

    const clearImportedMembership = () => {
        dispatch({
            type: UserActionTypes.CLEAR_IMPORTED_MEMBERSHIP,
        })
    }

    const hasVisitedProfileSummaryUpdate = () => {
        return !!localStorage.getItem(LocalStorageKeys.VISITED_PROFILE_UPDATE)
    }

    const clearUserVisitedProfileUpdate = () => {
        localStorage.removeItem(LocalStorageKeys.VISITED_PROFILE_UPDATE)
    }

    return {
        retrieveMembership,
        saveMembership,
        updateMembership,
        joinProgramme,
        retrieveUnionMembership,
        checkUnionMembershipWithNRIC,
        cancelMembership,
        uploadProfilePicture,
        retrieveProfilePicture,
        clearImportedMembership,
        hasVisitedProfileSummaryUpdate,
        clearUserVisitedProfileUpdate,
        fetchAddressFromPostalCode,
        resumeRecurringMembership,
    }
}

const dispatchMembership = (membership: Membership, dispatch: (value: UserAction) => void) => {
    const membershipResponse: MembershipResponse = {
        data: membership,
    }

    dispatch({ type: UserActionTypes.MEMBERSHIP_DATA_RETRIEVED, data: membershipResponse })
}

export { useMembershipRepository }