import { useContext } from 'react'
import { ServiceResponse } from "../../types/RemoteData"
import { HighlightCarouselItem } from "../../types"
import { AppContext } from '../../stores/App/AppStore'
import { ActionTypes } from '../../stores/App/AppReducer'
import AboutPageRepository from './about-page-repository'
import { SimpleFAQEntry } from '../../types/SimpleFAQEntry'
import { starterDataError } from '../../helpers/utils/error-handling'

const useAboutPageRepository = () => {
    const { dispatch } = useContext(AppContext)
    const aboutPageRepository = new AboutPageRepository()

    const retrieveAboutCarouselItems = () => {
        const aboutCarouselResponse: ServiceResponse<HighlightCarouselItem[]> = {}

        aboutPageRepository.getAboutCarousel()
            .then((items) => {
                aboutCarouselResponse.data = items
                return aboutCarouselResponse
            })
            .catch((error) => {
                aboutCarouselResponse.error = starterDataError(error)
            })
            .finally(() => {
                dispatch({
                    type: ActionTypes.PAGE_ABOUT_CAROUSEL_RETRIEVED,
                    aboutCarouselItems: aboutCarouselResponse,
                })
            })
    }

    const retrieveAboutFaqItems = () => {
        const aboutFaqResponse: ServiceResponse<SimpleFAQEntry[]> = {}

        aboutPageRepository.getAboutFaq()
            .then((items) => {
                aboutFaqResponse.data = items
            })
            .catch((error) => {
                aboutFaqResponse.error = starterDataError(error)
            })
            .finally(() => {
                dispatch({
                    type: ActionTypes.PAGE_ABOUT_FAQ_RETRIEVED,
                    aboutFaqItems: aboutFaqResponse,
                })
            })
    }

    return {
        retrieveAboutCarouselItems,
        retrieveAboutFaqItems,
    }
}

export { useAboutPageRepository }