import { TrainingCourse } from '../../types'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const fromStrapiTrainingCourse = (strapiData: any): TrainingCourse => {
    const { id, name, code } = strapiData

    return {
        id,
        name,
        code,
    }
}

export {
    fromStrapiTrainingCourse,
}