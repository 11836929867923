import { useTranslation } from 'react-i18next'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { Box, Typography } from "@mui/material"
import UpgradeUnionCardImage from '../../../assets/upgrade-union-logo.jpeg'

import styles from "./UpgradeUnionCard.module.scss"

interface UpgradeUnionCardProps {
    handleUnionCardClick: () => void
}

const UpgradeUnionCard = (props: UpgradeUnionCardProps) => {
    const { handleUnionCardClick } = props
    const { t } = useTranslation()

    const unionColor = {
        color: `#0FAEC6`,
    }

    return (
        <Box className={styles.container} onClick={() => handleUnionCardClick()}>
            <Box component='img' className={styles.unionImage} src={UpgradeUnionCardImage} alt={`Upgrade to Union Card`} />
            <Box className={styles.contentContainer} />
            <Box className={styles.upgradeContent}>
                <Typography variant='h3' className={styles.title} sx={unionColor}>{t(`profile_upgrade_card_title`)}</Typography>
                <Typography variant='caption' className={styles.caption}>{t(`profile_upgrade_card_content`)}</Typography>
                <Box className={styles.learnMore} sx={unionColor}>
                    <Typography variant='caption' className={styles.link} sx={unionColor}>{t(`common_know_more`)}</Typography>
                    <ArrowForwardIosIcon className={styles.arrowForward} />
                </Box>
            </Box>
        </Box>
    )

}

export default UpgradeUnionCard