import type { ProgrammesServiceResponse, ServiceResponse } from '../../types'
import { ProgrammeApplicationForm } from '../../types/ProgrammeApplicationForm'
import { ProgrammeState } from './ProgrammeState'

enum ProgrammeAction {
    PROGRAMME_APPLICATION_FORMS_RETRIEVED,
    PROGRAMME_APPLICATION_FORM_RETRIEVED,
    PROGRAMMES_RETRIEVED,
}

type ProgrammeContentAction =
    | { type: ProgrammeAction.PROGRAMME_APPLICATION_FORMS_RETRIEVED, programmeApplicationForms: ServiceResponse<ProgrammeApplicationForm[]> }
    | { type: ProgrammeAction.PROGRAMME_APPLICATION_FORM_RETRIEVED, programmeApplicationForm: ServiceResponse<ProgrammeApplicationForm> }
    | { type: ProgrammeAction.PROGRAMMES_RETRIEVED, programmes: ProgrammesServiceResponse }

const ProgrammeReducer = (state: ProgrammeState, action: ProgrammeContentAction): ProgrammeState => {
    switch (action.type) {
        case ProgrammeAction.PROGRAMME_APPLICATION_FORMS_RETRIEVED: {
            return {
                ...state,
                programmeApplicationForms: action.programmeApplicationForms,
            }
        }
        case ProgrammeAction.PROGRAMME_APPLICATION_FORM_RETRIEVED: {
            let data: ProgrammeApplicationForm[] | undefined = state.programmeApplicationForms?.data

            if (action.programmeApplicationForm.data) {
                if (data?.length) {
                    data = data.filter(programmeApplicationForm => programmeApplicationForm.id !== action.programmeApplicationForm?.data?.id)
                }

                data = [
                    ...(data ?? []),
                    action.programmeApplicationForm.data,
                ]
            }

            return {
                ...state,
                programmeApplicationForms: {
                    data,
                    error: action.programmeApplicationForm.error,
                },
            }
        }
        case ProgrammeAction.PROGRAMMES_RETRIEVED: {
            return {
                ...state,
                programmes: action.programmes,
            }
        }
        default: {
            throw new Error(`Unhandled action type`)
        }
    }
}

export default ProgrammeReducer
export type { ProgrammeContentAction }
export {
    ProgrammeAction as ProgrammeActions,
}