import { FaqEntry } from '../../types'
import { StrapiFaqEntry } from '../../types/FaqEntry'
import { FaqTags, StrapiFaqTags } from '../../types/FaqTags'

const fromStrapiFaq = (strapiData: StrapiFaqEntry): FaqEntry => {
    return {
        id: strapiData.id,
        ...strapiData.attributes,
        tags: strapiData.attributes.faqTags?.data?.map(fromStrapiFaqTags),
    }
}

const fromStrapiFaqTags = (strapiData: StrapiFaqTags): FaqTags => {
    return {
        ...strapiData.attributes,
        id: strapiData.id,
        order: strapiData.attributes.order ?? 0,
        faqEntries: strapiData.attributes?.faqEntries?.data.map((entry: StrapiFaqEntry) => {
            return {
                ...entry.attributes,
                id: entry.id,
                order: entry.attributes.order ?? 0,
            }
        }),
    }
}

export {
    fromStrapiFaq,
    fromStrapiFaqTags,
}