
const VERSION_KEY = `applicationVersion`

const storeLocalVersion = (version: string) => {
    localStorage.setItem(VERSION_KEY, version)
}

const getLocalVersion = () => {
    const version = localStorage.getItem(VERSION_KEY)

    return version ?? undefined
}

export {
    storeLocalVersion,
    getLocalVersion,
}