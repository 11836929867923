import { ServiceResponse } from "../../types"
import { SimpleFAQEntry } from "../../types/SimpleFAQEntry"
import { UpmeBenefit } from "../../types/UpmeBenefit"
import { UpmeBenefitCategory } from "../../types/UpmeBenefitCategory"
import { DigitalResourceCategory } from "../../types/DigitalResourceCategory"
import { DigitalResourceDetail } from "../../types/DigitalResourceDetail"
import { UpmeIntroductionCarouselItem } from "../../types/UpmeIntroductionCarouselItem"
import { UpmeLimitedDigitalResourceDetailByCategory } from "../../types/LimitedDigitalResourceDetailByCategory"

type UPMEContentState = {
    introductionCarouselItems?: ServiceResponse<UpmeIntroductionCarouselItem[]>
    introductionFAQEntries?: ServiceResponse<SimpleFAQEntry[]>
    benefits?: ServiceResponse<UpmeBenefit[]>
    benefitCategories?: ServiceResponse<UpmeBenefitCategory[]>
    digitalResourceCategories?: ServiceResponse<DigitalResourceCategory[]>
    digitalResourceListByCategory?: ServiceResponse<DigitalResourceDetail[]>
    digitalResourceDetails?: ServiceResponse<DigitalResourceDetail>
    limitedDigitalResourceByCategory?: ServiceResponse<UpmeLimitedDigitalResourceDetailByCategory[]>
}

const UPMEContentInitialState: UPMEContentState = {
    introductionCarouselItems: undefined,
    introductionFAQEntries: undefined,
    benefits: undefined,
    benefitCategories: undefined,
    digitalResourceCategories: undefined,
    digitalResourceListByCategory: undefined,
    digitalResourceDetails: undefined,
    limitedDigitalResourceByCategory: undefined,
}

export { UPMEContentInitialState }
export type { UPMEContentState }