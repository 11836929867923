import { HTTP_SUCCESS } from '../helpers/utils/http-status'
import { PaymentOrderReason } from '../types/PaymentOrderReason'
import { authenticatedClient } from './util'

const getPaymentOrder = async (clientOrderId: string) => {
    return authenticatedClient()
        .post(
            `/api/payment-orders/payment-order`,
            { clientOrderId },
        ).then(res => {
            if (res.status !== HTTP_SUCCESS) {
                throw new Error(`Could not retrieve payment order`)
            }
            return res
        }).then(res => res.data)
}

const createPaymentOrder = async (reason: PaymentOrderReason, plan?: string) => {
    const planParameter = plan ? `&plan=${plan}` : ``

    return authenticatedClient()
        .post(
            `/api/payment-orders?reason=${reason}${planParameter}`,
        ).then(res => {
            if (res.status !== HTTP_SUCCESS) {
                throw new Error(`Could not create new payment order`)
            }
            return res
        }).then(res => res.data)
}

const getPaymentUrl = async (clientOrderId: string) => {
    return authenticatedClient()
        .post(
            `/api/payment-orders/url`,
            { clientOrderId },
        ).then(res => {
            if (res.status !== HTTP_SUCCESS) {
                throw new Error(`Could not retrieve payment url`)
            }
            return res
        }).then(res => res.data)
}

const getPaymentOrderHistory = async (pageSize: number) => {
    const sort = `transactionDate`
    const sortOrder = `desc`

    return authenticatedClient()
        .get(
            `/api/payment-orders?pagination[pageSize]=${pageSize}&sort=${sort}:${sortOrder}`,
        ).then(res => {
            if (res.status !== HTTP_SUCCESS) {
                throw new Error(`Could not retrieve payment order history`)
            }
            return res
        }).then(res => res.data)
}

export {
    getPaymentOrder,
    createPaymentOrder,
    getPaymentUrl,
    getPaymentOrderHistory,
}