import { fromStrapiFieldOfStudy } from '../../helpers/mapping/field-of-study'
import { FieldOfStudy } from '../../types'
import { fetchFieldsOfStudy } from '../../clients/fields-of-study'

class FieldOfStudyRepository {

    async getAll(): Promise<FieldOfStudy[]> {
        const { data } = await fetchFieldsOfStudy()
        return data.map((i: unknown) => fromStrapiFieldOfStudy(i))
    }
}

export default FieldOfStudyRepository