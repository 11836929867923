import react, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import CloseIcon from '@mui/icons-material/Close'
import { Box, Button, Link, Typography, Zoom } from "@mui/material"
import { useTranslation } from "react-i18next"
import { TransitionProps } from "@mui/material/transitions"

import LocalStorageKeys from "../../../types/LocalStorageKeys"
import { Route, STRAPI_URL } from "../../../router"
import { logLandingPageAction } from "../../../clients/logger"
import { MyInfoReason } from "../../../types/MyInfoUserData"
import { MembershipType } from "../../../types/MembershipType"
import { domainCheckMembershipType } from "../../../helpers/utils/common"

import styles from './AlertAccessDialog.module.scss'

const UNION_SIGNUP_URL = process.env.REACT_APP_UNION_SIGNUP_URL ?? `https://www.ntuc.org.sg/Uportal/Home/Memberships/Become%20a%20member`

const Transition = react.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<string, string>;
    },
    ref: React.Ref<unknown>,
) {
    return <Zoom ref={ref} {...props} />
})

interface AlertAccessDialogProps {
    shouldShow: boolean
    onClose?: () => void
    membershipPlanRoute? : string
}

const AlertAccessDialog = (props: AlertAccessDialogProps) => {
    const { shouldShow = false, onClose, membershipPlanRoute } = props
    const { t } = useTranslation()
    const navigate = useNavigate()

    const [isVisible, setIsVisible] = useState(shouldShow)

    const handleClose = () => onClose?.() ?? setIsVisible(false)

    useEffect(() => {
        setIsVisible(shouldShow)
    }, [shouldShow])

    const membershipTypeFromDomainName = domainCheckMembershipType()

    const handleSignUpButtonClick = () => {
        if (membershipTypeFromDomainName === MembershipType.UPME) {
            window.location.replace(UNION_SIGNUP_URL)
        } else {
            if (membershipPlanRoute){
                localStorage.setItem(LocalStorageKeys.MYINFO_REASON, MyInfoReason.SIGNUP)
                logLandingPageAction(`Sign Up`, `default`)
                navigate(membershipPlanRoute)
            }else{
                localStorage.setItem(LocalStorageKeys.MYINFO_REASON, MyInfoReason.SIGNUP)
                logLandingPageAction(`Sign Up`, `default`)
                window.location.href = `${STRAPI_URL}${Route.MYINFO_AUTHORISE}`
            }
        }
    }

    const handleLoginButtonClick = () => {
        logLandingPageAction(`Login`, `default`)
        navigate(Route.PLS_LOGIN)
    }

    const localeKeyForMembershipType = (membershipType: MembershipType): string => {
        switch (membershipType) {
            case MembershipType.STARTER:
                return `alert_access_text_membership_starter`
            case MembershipType.UPME:
                return `alert_access_text_membership_upme`
        }
    }

    const localizedMembershipType = localeKeyForMembershipType(membershipTypeFromDomainName)

    return (
        <Dialog open={isVisible} TransitionComponent={Transition} classes={{ paper: styles.dialog }} onClose={handleClose}>
            <DialogContent>
                <Box className={styles.header}>
                    <Typography variant="h3" className={styles.title}>{t(`alert_access_header`)}</Typography>
                    <CloseIcon onClick={handleClose} className={styles.closeIcon} />
                </Box>
                <Typography variant="body1" color={`functional.text.lighter`} className={styles.bodyText}>{t(`alert_access_text`, { type: t(localizedMembershipType) })}</Typography>
            </DialogContent>

            <DialogActions className={styles.dialogActions}>
                <Box className={styles.actionBox}>
                    <Button variant="contained" className={styles.button} onClick={handleSignUpButtonClick} fullWidth>{t(`landing_page_sign_up`)}</Button>
                    <Link color="inherit" onClick={handleLoginButtonClick}>{t(`landing_page_login`)}</Link>
                </Box>
            </DialogActions>
        </Dialog>
    )
}

export default AlertAccessDialog