import { useContext, useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Container, Divider, Grid, InputAdornment, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import LoadingButton from '@mui/lab/LoadingButton'

import CommonBackButton from '../../../atoms/CommonBackButton/CommonBackButton'
import ConfirmationDialog from '../../../molecules/ConfirmationDialog/ConfirmationDialog'
import CustomInput from '../../../atoms/CustomInput/CustomInput'
import CustomSelect from '../../../atoms/CustomSelect/CustomSelect'
import FieldsOfStudySelect from '../../../molecules/FieldsOfStudySelect/FieldsOfStudySelect'
import InstitutionSelect from '../../../molecules/InstitutionSelect/InstitutionSelect'
import SIGN_UP_FORM from '../../../../helpers/validation/rules/sign-up-form'
import Spacer from '../../../atoms/Spacer/Spacer'
import { useAuthenticatedGuard } from '../../../../router/guards/authenticated-guard'
import { Route, pathMatchesProfileUpdateRoutePattern } from '../../../../router'
import { isValidMemberAge } from '../../../../helpers/validation/age'
import { trimLeadingCountryCode } from '../../../../helpers/utils/parse'
import { UserContext } from '../../../../stores/User/UserStore'
import { useMembershipPlanRepository } from '../../../../repositories/MembershipPlan/use-membership-plan-repository'
import { useUserRepository } from '../../../../repositories/User/use-user-repository'
import SIGN_UP_FORM_CONSTANTS from './SignUp.constants'
import LocalStorageKeys from '../../../../types/LocalStorageKeys'
import { SignUpForm } from '../../../../types/SignUpForm'
import { useMembershipRepository } from '../../../../repositories/Membership/use-membership-repository'
import { ServiceResponseError } from '../../../../types/RemoteData'
import StarterErrorCodes from '../../../../types/ErrorCodes'
import useMemberGuard from "../../../../router/guards/member-guard"
import useProfileUpdateGuard from '../../../../router/guards/profile-update-guard'
import { logAction } from '../../../../clients/logger'
import { errorPopupMessageTemplate, generateErrorCode, membershipFormPageMessageTemplate } from '../../../../helpers/logging/templates'
import useGoogleTagManager from '../../../../helpers/analytics/use-google-tag-manager'
import GtmEventName from '../../../../helpers/analytics/GtmEventName'

import styles from "./SignUp.module.css"

interface FormDetails {
    isAgeValid: boolean,
    phoneNumber?: string,
    institution?: string | number,
    yearsLeft?: string | number,
    fieldOfStudy?: string | number,
    eCardName?: string,
    referralCode?: string,
    gender?: string | number,
    residentialStatus?: string | number,
    postalCode?: string,
    block?: string,
    street?: string,
    floor?: string,
    unit?: string,
    building?: string,
    email?: string,
}

const SignUpFormComponent = () => {
    useAuthenticatedGuard(Route.MEMBERSHIP_SIGN_UP)
    useMemberGuard()
    useProfileUpdateGuard()

    const { t } = useTranslation()
    const { state: userState } = useContext(UserContext)

    const navigate = useNavigate()
    const location = useLocation()
    const { trackFormSubmit, trackTextFieldProvided } = useGoogleTagManager()

    const { updateMembership, clearImportedMembership, hasVisitedProfileSummaryUpdate, clearUserVisitedProfileUpdate, fetchAddressFromPostalCode } = useMembershipRepository()
    const { retrieveMembershipPlan } = useMembershipPlanRepository()
    const { retrieveUser } = useUserRepository()

    const userMembership = userState.membership?.data
    const isAgeLimitLifted = userState.user?.data?.liftAgeLimit

    const [formDetails, setFormDetails] = useState<FormDetails>({
        isAgeValid: false,
    })
    const [formRequiredState, setFormRequiredState] = useState(SIGN_UP_FORM_CONSTANTS.formRequiredStateTemplate)
    const [fieldsSetFromMembership, setFieldsSetFromMembership] = useState(false)
    const [loading, setLoading] = useState(false)
    const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false)
    const [dialogErrorMessageTitle, setDialogErrorMessageTitle] = useState(t(`sign_up_failure`))
    const [dialogErrorMessage, setDialogErrorMessage] = useState(``)
    const [confirmationButtonText, setConfirmationButtonText] = useState(t(`sign_up_confirmation_continue`))
    const [validationError, setValidationError] = useState(false)
    const validationErrorMessage = `Please double check the form for errors`

    const isFromProfileUpdate = hasVisitedProfileSummaryUpdate() && pathMatchesProfileUpdateRoutePattern(location.pathname)

    useEffect(() => {
        window.scrollTo(0, 0)
        retrieveMembershipPlan()
        retrieveUser()
        retrieveReferralCode()
    }, [])

    useEffect(() => {
        if (userMembership?.dateOfBirth && !fieldsSetFromMembership) {
            setFieldsSetFromMembership(true)
            setFormDetails({
                ...formDetails,
                isAgeValid: isAgeLimitLifted || isValidMemberAge(userMembership.dateOfBirth),
                phoneNumber: trimLeadingCountryCode(userMembership.phoneNumber),
                gender: userMembership.gender,
                residentialStatus: userMembership.residentialStatus,
                postalCode: userMembership.postalCode,
                email: userMembership.emailAddress,
                block: userMembership.block,
                street: userMembership.street,
                floor: userMembership.floor,
                unit: userMembership.unit,
                building: userMembership.building,
            })
        }
    }, [userMembership])

    useEffect(() => {
        logAction(`error`, errorPopupMessageTemplate(dialogErrorMessage, userState.membership?.data?.id?.toString() ?? `not found`))
    }, [dialogErrorMessage])

    useEffect(() => {
        if (userState.membershipPlan?.error) {
            console.error(userState.membershipPlan.error.errorMessage)
        } else if (userState.membershipPlan?.data) {
            if (userState.membershipPlan.data.membershipFees === 0) {
                setConfirmationButtonText(t(`sign_up_confirmation_signup`))
            }
        }

    }, [userState.membershipPlan])

    const retrieveReferralCode = () => {
        const localStorageReferralCode = localStorage.getItem(LocalStorageKeys.REFERRAL_CODE)

        if (localStorageReferralCode) {
            setFormDetails({
                ...formDetails,
                referralCode: localStorageReferralCode,
            })
            localStorage.removeItem(LocalStorageKeys.REFERRAL_CODE)
        }
    }

    const handleErrorDialogClose = () => {
        setIsErrorDialogOpen(false)
    }

    const handleSubmit = () => {
        trackFormSubmit(GtmEventName.SIGN_UP_CONTINUE_CLICK)

        if (areInputsValid()) {
            if (formDetails.referralCode) {
                trackTextFieldProvided(GtmEventName.REFERRAL_CODE_PROVIDED, formDetails.referralCode)
            }

            handleSaveMembership()
        } else {
            handleShowValidationErrors()
        }
    }

    const areInputsValid = (): boolean => {
        return !!userMembership?.fullName
            && !!userMembership?.dateOfBirth
            && !!formDetails.gender
            && !!formDetails.residentialStatus
            && !!formDetails.phoneNumber
            && !!formDetails.postalCode
            && !!formDetails.institution
            && !!formDetails.yearsLeft
            && !!formDetails.fieldOfStudy
            && !!formDetails.eCardName
            && !!formDetails.email
            && !!formDetails.isAgeValid
            && (formDetails.referralCode === undefined
                // eslint-disable-next-line no-magic-numbers
                || formDetails.referralCode?.length === 8
                // eslint-disable-next-line no-magic-numbers
                || formDetails.referralCode?.length === 12)
    }

    const handleShowValidationErrors = () => {
        const requiredState = {
            gender: !formDetails.gender,
            residentialStatus: !formDetails.residentialStatus,
            phoneNumber: !formDetails.phoneNumber,
            postalCode: !formDetails.postalCode,
            block: !formDetails.block,
            street: !formDetails.street,
            institution: !formDetails.institution,
            yearsLeft: !formDetails.yearsLeft,
            fieldOfStudy: !formDetails.fieldOfStudy,
            eCardName: !formDetails.eCardName,
            age: formDetails.isAgeValid,
            email: !formDetails.email,
        }
        setValidationError(true)
        setFormRequiredState(requiredState)
    }

    const handleSaveMembership = () => {
        const formData = buildMembershipCreationData()

        if (formData && userMembership?.id) {
            setLoading(true)

            logAction(`debug`, membershipFormPageMessageTemplate(formData))

            updateMembership(formData, userMembership?.id)
                .then(() => {
                    if (isFromProfileUpdate) {
                        clearUserVisitedProfileUpdate()
                        navigate(Route.PROFILE_SUMMARY, { replace: true })
                    } else {
                        clearImportedMembership()
                        navigate(Route.MEMBERSHIP_SUCCESS, { replace: true })
                    }
                })
                .catch(handleSaveMembershipError)
                .finally(() => {
                    setLoading(false)
                })
        }
    }

    const buildAddress = () => {
        return {
            block: formDetails.block ?? ``,
            street: formDetails.street ?? ``,
            floor: formDetails.floor ?? ``,
            unit: formDetails.unit ?? ``,
            building: formDetails.building ?? ``,
        }
    }

    const buildMembershipCreationData = (): SignUpForm | undefined => {
        const address = buildAddress()

        if (userMembership?.fullName && userMembership.dateOfBirth && formDetails.phoneNumber && formDetails.institution && formDetails.yearsLeft && formDetails.fieldOfStudy && formDetails.email && formDetails.postalCode && formDetails.eCardName) {

            return {
                fullName: userMembership.fullName,
                dateOfBirth: userMembership.dateOfBirth,
                gender: typeof formDetails.gender === `string` ? formDetails.gender : `U`,
                residentialStatus: typeof formDetails.residentialStatus === `string` ? formDetails.residentialStatus : `U`,
                phoneNumber: formDetails.phoneNumber,
                emailAddress: formDetails.email,
                postalCode: formDetails.postalCode,
                block: address.block,
                street: address.street,
                floor: address.floor,
                unit: address.unit,
                building: address.building,
                institutionID: typeof formDetails.institution === `number` ? formDetails.institution : parseInt(formDetails.institution),
                yearsBeforeGraduation: typeof formDetails.yearsLeft === `number` ? formDetails.yearsLeft : parseInt(formDetails.yearsLeft),
                fieldOfStudyID: typeof formDetails.fieldOfStudy === `number` ? formDetails.fieldOfStudy : parseInt(formDetails.fieldOfStudy),
                eCardName: formDetails.eCardName,
                snackConsent: true,
                moneyOwlConsent: true,
                referralCode: formDetails.referralCode || undefined,
            }
        }
    }

    const handleSaveMembershipError = (error: ServiceResponseError) => {
        if (error.errorCode === StarterErrorCodes.USER_AGE_INVALID) {
            setDialogErrorMessage(t(`error_user_age_invalid`) + ` error code: ${generateErrorCode()}`)
        } else if (error.errorCode === StarterErrorCodes.EMAIL_ALREADY_USED_BY_MEMBERSHIP) {
            setDialogErrorMessage(t(`error_email_already_used`) + ` error code: ${generateErrorCode()}`)
        } else if (error.errorCode === StarterErrorCodes.EMAIL_ALREADY_USED_BY_USER) {
            setDialogErrorMessage(t(`error_email_already_used`) + ` error code: ${generateErrorCode()}`)
        } else {
            setDialogErrorMessage((error.errorMessage ?? t(`error_unknown`)) + ` error code: ${generateErrorCode()}`)
        }

        setDialogErrorMessageTitle(t(`sign_up_failure`))
        setLoading(false)
        setIsErrorDialogOpen(true)
    }

    const handleOnPhoneNumberChange = (phoneNumber?: string) => {
        setFormDetails({
            ...formDetails,
            phoneNumber,
        })
    }

    const handleOnEmailChange = (email?: string) => {
        setFormDetails({
            ...formDetails,
            email,
        })
    }

    const handleOnGenderChange = (gender?: string | number) => {
        setFormDetails({
            ...formDetails,
            gender,
        })
    }

    const handleOnResidentialStatusChange = (residentialStatus?: string | number) => {
        setFormDetails({
            ...formDetails,
            residentialStatus,
        })
    }

    const handleOnPostalCodeChange = async (postalCode?: string) => {
        // eslint-disable-next-line no-magic-numbers
        if (postalCode?.length === 6) {
            const address = await fetchAddressFromPostalCode(postalCode)
            if (address) {
                setFormDetails({
                    ...formDetails,
                    postalCode,
                    block: address.getpostalcodeResult.BlockNumber,
                    street: address.getpostalcodeResult.StreetName,
                    building: address.getpostalcodeResult.BuildingName,
                })
            }
            else {
                setFormDetails({
                    ...formDetails,
                    postalCode,
                })
            }
        } else {
            setFormDetails({
                ...formDetails,
                postalCode,
            })
        }
    }

    const handleOnBlockChange = (block?: string) => {
        setFormDetails({
            ...formDetails,
            block,
        })
    }

    const handleOnStreetChange = (street?: string) => {
        setFormDetails({
            ...formDetails,
            street,
        })
    }

    const handleOnFloorChange = (floor?: string) => {
        setFormDetails({
            ...formDetails,
            floor,
        })
    }

    const handleOnUnitChange = (unit?: string) => {
        setFormDetails({
            ...formDetails,
            unit,
        })
    }

    const handleOnBuildingChange = (building?: string) => {
        setFormDetails({
            ...formDetails,
            building,
        })
    }

    const handleOnInstitutionChange = (institution?: string | number) => {
        setFormDetails({
            ...formDetails,
            institution,
        })
    }

    const handleOnYearsLeftChange = (yearsLeft?: string | number) => {
        setFormDetails({
            ...formDetails,
            yearsLeft,
        })
    }

    const handleOnFieldOfStudyChange = (fieldOfStudy?: string | number) => {
        setFormDetails({
            ...formDetails,
            fieldOfStudy,
        })
    }

    const handleOnECardNameChange = (eCardName?: string) => {
        setFormDetails({
            ...formDetails,
            eCardName,
        })
    }

    const handleOnReferralCodeChange = (referralCode?: string) => {
        setFormDetails({
            ...formDetails,
            referralCode,
        })
    }

    return (
        <Container>
            <Grid container direction="column" spacing={1}>
                <CommonBackButton />
                <Grid item>
                    <Typography variant="h6" mb={2} className={styles.title}>{t(`sign_up_title`)}</Typography>
                </Grid>
                <Grid item>
                    <CustomInput
                        fullWidth
                        label={t(`sign_up_name`)}
                        readOnly
                        value={userMembership?.fullName ?? ``}
                        isSignUp={true}
                    />
                    <CustomInput
                        fullWidth
                        label={t(`sign_up_phone_number`)}
                        numbersOnly
                        eagerChange
                        onChange={handleOnPhoneNumberChange}
                        params={formDetails.phoneNumber?.charAt(0) === `+` ? undefined : {
                            InputProps: {
                                startAdornment: <InputAdornment position="start">+65</InputAdornment>,
                            },
                        }}
                        placeholder={t(`common_placeholder`)}
                        validation={SIGN_UP_FORM.phoneNumber}
                        value={formDetails.phoneNumber ?? ``}
                        error={formRequiredState.phoneNumber}
                        isSignUp={true}
                        isFocused={formRequiredState.phoneNumber}
                    />
                    <CustomInput
                        fullWidth
                        label={t(`sign_up_email_address`)}
                        eagerChange
                        onChange={handleOnEmailChange}
                        validation={SIGN_UP_FORM.email}
                        readOnly={!!userMembership?.emailAddress}
                        error={formRequiredState.email}
                        value={formDetails.email}
                        isSignUp={true}
                        isFocused={formRequiredState.email}
                    />
                    {!formDetails.gender && <CustomSelect
                        label={t(`sign_up_gender`)}
                        onChange={handleOnGenderChange}
                        options={SIGN_UP_FORM_CONSTANTS.genderOptions}
                        placeholder={t(`common_placeholder`)}
                        value={formDetails.gender}
                        error={formRequiredState.gender}
                        isSignUp={true}
                    />}
                    {(!userMembership?.residentialStatus) && <CustomSelect
                        label={t(`sign_up_resident_status`)}
                        onChange={handleOnResidentialStatusChange}
                        options={SIGN_UP_FORM_CONSTANTS.residentialOptions}
                        placeholder={t(`common_placeholder`)}
                        value={formDetails.residentialStatus}
                        error={formRequiredState.residentialStatus}
                        isSignUp={true}
                    />}
                    <CustomInput
                        fullWidth
                        label={t(`sign_up_postal_code`)}
                        numbersOnly
                        eagerChange
                        onChange={handleOnPostalCodeChange}
                        placeholder={t(`common_placeholder`)}
                        validation={SIGN_UP_FORM.postalCode}
                        value={formDetails.postalCode || ``}
                        error={formRequiredState.postalCode}
                        isSignUp={true}
                        isFocused={formRequiredState.postalCode}
                    />
                    <CustomInput
                        fullWidth
                        label={t(`sign_up_block_house_number`)}
                        onChange={handleOnBlockChange}
                        placeholder={t(`common_placeholder`)}
                        value={formDetails.block}
                        error={formRequiredState.block}
                        isSignUp={true} />
                    <CustomInput
                        fullWidth
                        label={t(`sign_up_block_street_name`)}
                        onChange={handleOnStreetChange}
                        placeholder={t(`common_placeholder`)}
                        value={formDetails.street}
                        error={formRequiredState.street}
                        isSignUp={true} />
                    <CustomInput
                        fullWidth
                        label={t(`sign_up_block_floor_number`)}
                        onChange={handleOnFloorChange}
                        placeholder={t(`common_placeholder`)}
                        validation={SIGN_UP_FORM.floorNumber}
                        value={formDetails.floor}
                        numbersOnly
                        eagerChange
                        isSignUp={true}
                    />
                    <CustomInput
                        fullWidth
                        label={t(`sign_up_block_unit_number`)}
                        onChange={handleOnUnitChange}
                        placeholder={t(`common_placeholder`)}
                        validation={SIGN_UP_FORM.unitNumber}
                        value={formDetails.unit}
                        numbersOnly
                        eagerChange
                        isSignUp={true}
                    />
                    <CustomInput
                        fullWidth
                        label={t(`sign_up_block_building_name`)}
                        onChange={handleOnBuildingChange}
                        placeholder={t(`common_placeholder`)}
                        value={formDetails.building}
                        isSignUp={true}
                    />
                    <InstitutionSelect
                        label={t(`sign_up_school`)}
                        onChange={handleOnInstitutionChange}
                        placeholder={t(`common_placeholder`)}
                        value={formDetails.institution}
                        error={formRequiredState.institution}
                        isSignUp={true}
                    />
                    <CustomSelect
                        label={t(`sign_up_years_left`)}
                        onChange={handleOnYearsLeftChange}
                        options={SIGN_UP_FORM_CONSTANTS.yearOptions}
                        placeholder={t(`common_placeholder`)}
                        value={formDetails.yearsLeft}
                        error={formRequiredState.yearsLeft}
                        isSignUp={true}
                    />
                    <FieldsOfStudySelect
                        label={t(`sign_up_field_of_study`)}
                        onChange={handleOnFieldOfStudyChange}
                        placeholder={t(`common_placeholder`)}
                        value={formDetails.fieldOfStudy}
                        error={formRequiredState.fieldOfStudy}
                        isSignUp={true}
                    />
                    <CustomInput
                        fullWidth
                        eagerChange
                        label={t(`sign_up_name_ecard`)}
                        onChange={handleOnECardNameChange}
                        placeholder={t(`common_placeholder`)}
                        validation={SIGN_UP_FORM.eCardName}
                        value={formDetails.eCardName}
                        error={formRequiredState.eCardName}
                        isSignUp={true}
                        isFocused={formRequiredState.eCardName}
                    />
                    <Divider light sx={{ marginBottom: 2 }} />
                    {!pathMatchesProfileUpdateRoutePattern(location.pathname) && (<CustomInput
                        fullWidth
                        eagerChange
                        label={t(`sign_up_referral_code`)}
                        onChange={handleOnReferralCodeChange}
                        placeholder={t(`common_placeholder`)}
                        validation={SIGN_UP_FORM.referralCode}
                        value={formDetails.referralCode}
                        isSignUp={true}
                        isUpperCase={true}
                    />)}
                    <Spacer height={24} />
                    {validationError && <Typography className={styles.validationError}>{validationErrorMessage}</Typography>}
                    <LoadingButton variant='contained' onClick={handleSubmit} loading={loading} fullWidth>{confirmationButtonText}</LoadingButton>
                </Grid>
            </Grid>
            <ConfirmationDialog
                buttonText={t(`common_button_confirmation`)}
                confirmationTitle={dialogErrorMessageTitle}
                confirmationText={dialogErrorMessage}
                dismissButtonText={t(`common_back_to_home`)}
                defaultOpen={isErrorDialogOpen}
                onConfirm={handleErrorDialogClose}
                onDismiss={() => navigate(Route.ROOT)}
            />
        </Container>
    )
}

export default SignUpFormComponent