import { Typography, TypographyPropsVariantOverrides } from "@mui/material"
import { Variant } from "@mui/material/styles/createTypography"
import { OverridableStringUnion } from "@mui/types"
import SkeletonLines, { SkeletonLinesProps } from "../SkeletonLines/SkeletonLines"

interface TextContentProps {
    content?: string
    variant: OverridableStringUnion<Variant | 'inherit', TypographyPropsVariantOverrides>
    color?: string
    paragraph?: boolean
    styles?: string
}

const TextContent = (props: TextContentProps & SkeletonLinesProps) => {
    const {
        styles,
        content,
        variant,
        color,
        paragraph = false,
        skeletonClassName,
        skeletonLines,
    } = props

    if (content) {
        return <Typography variant={variant} mb={1} color={color} paragraph={paragraph} className={styles}>{content}</Typography>
    }

    return <SkeletonLines skeletonClassName={skeletonClassName} skeletonLines={skeletonLines} />
}

export default TextContent