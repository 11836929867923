const enum GtmEventName {
    FOOTER_BUTTON_CLICK = `footer_button_click`,
    HEADER_TITLE_CLICK = `header_title_click`,
    REDIRECT = `redirect`,
    ALERT_DIALOG_OPEN = `alert_dialog_open`,
    ALERT_DIALOG_CLOSE = `alert_dialog_close`,
    CAROUSEL_ITEM_CLICK = `carousel_item_click`,
    SIMPLE_FAQ_CLICK = `simple_faq_click`,
    FAQ_ENTRY_CLICK = `faq_entry_click`,
    CONFIRMATION_DIALOG_OPEN = `confirmation_dialog_open`,
    CONFIRMATION_DIALOG_CONFIRM = `confirmation_dialog_confirm`,
    CONFIRMATION_DIALOG_DISMISS = `confirmation_dialog_dismiss`,
    UPGRADE_NOW_CLICK = `upgrade_now_click`,
    PAGE_VIEW = `page_view`,
    PROGRAMME_APPLICATION_CARD_CLICK = `programme_application_card_click`,
    DEAL_CARD_CLICK = `deal_card_click`,
    SEE_ALL_CLICK = `see_all_click`,
    PROGRAMME_CARD_CLICK = `programme_card_click`,
    SIGN_UP_CLICK = `sign_up_click`,
    SIGN_IN_CLICK = `sign_in_click`,
    ACTIVATE_ACCOUNT_CLICK = `activate_account_click`,
    DISCOVER_NTUC_CLICK = `discover_ntuc_click`,
    TERMS_AND_CONDITIONS_CLICK = `terms_and_conditions_click`,
    PAYMENT_CLICK = `payment_click`,
    REFERRAL_CODE_PROVIDED = `referral_code_provided`,
    SIGN_UP_CONTINUE_CLICK = `sign_up_continue_click`,
    PROMPT_CLICK = `prompt_click`,
    OPEN_PROFILE_MENU = `open_profile_menu`,
    OPEN_MAIN_MENU = `open_main_menu`,
    COME_BACK_BUTTON_CLICK = `come_back_button_click`,
    REGISTERED_EVENT_CLICK = `registered_event_click`,
    PILLAR_CLICK = `pillar_click`,
    DIGITAL_RESOURCE_CLICK = `digital_resource_click`,
    MEMBERSHIP_CARD_CLICK = `membership_card_click`,
    UNION_KNOW_MORE_CLICK = `union_know_more_click`,
    REFERRAL_CODE_COPY_CLICK = `referral_code_copy_click`,
    REFERRAL_CODE_INVITATION_COPY_CLICK = `referral_code_invitation_copy_click`,
    UPDATE_PROFILE_CLICK = `update_profile_click`,
    VIEW_ACTIVITY_CLICK = `view_activity_click`,
    MANAGE_SUBSCRIPTION_CLICK = `manage_subscription_click`,
    CONTACT_US_CLICK = `contact_us_click`,
    LOG_OUT_CLICK = `log_out_click`,
    VIEW_NTUC_BENEFITS_CLICK = `view_ntuc_benefits_click`,
    PROFILE_PICTURE_CLICK = `profile_picture_click`,
    CHOOSE_PICTURE_CLICK = `choose_picture_click`,
    CONFIRM_PICTURE_CLICK = `confirm_picture_click`,
    NTUC_BENEFIT_CATEGORY_CLICK = `ntuc_benefit_category_click`,
    NTUC_BENEFIT_CARD_CLICK = `ntuc_benefit_card_click`,
    UPDATE_INTERESTS_CLICK = `update_interests_click`,
    CONFIRM_USER_INTERESTS = `confirm_user_interests`,
    FILTER_EVENTS = `filter_events`,
    EVENT_CARD_CLICK = `event_card_click`,
    EVENT_REGISTRATION_CLICK = `event_registration_click`,
    FILTER_DEALS = `filter_deals`,
    COPY_PROMO_CODE = `copy_promo_code`,
    DISMISS_PROGRAMME_MODAL = `dismiss_programme_modal`,
    JOIN_PROGRAMME_CLICK = `join_programme_click`,
    JOIN_PROGRAMME_CONFIRM = `join_programme_confirm`,
    JOIN_PROGRAMME_CANCEL = `join_programme_cancel`,
    RESOURCE_READ_MORE_CLICK = `resource_read_more_click`,
    UTAP_CLAIM_CLICK = `utap_claim_click`,
    UTAP_MY_CLAIMS_CLICK = `utap_my_claims_click`,
    SUBMIT_UTAP_CLAIM = `submit_utap_claim`,
    REACTIVATE_MEMBERSHIP_OPEN = `reactivate_membership_open`,
    REACTIVATE_MEMBERSHIP_DISMISS = `reactivate_membership_dismiss`,
    FAQ_CATEGORY_CLICK = `faq_category_click`,
    FAQ_ENTRY_ROUTE_CLICK = `faq_entry_route_click`,
    FAQ_ENTRY_EXTERNAL_LINK_CLICK = `faq_entry_external_link_click`,
    CANCEL_MEMBERSHIP_CLICK = `cancel_membership_click`,
    SUBMIT_PROGRAMME_APPLICATION = `submit_programme_application`,
    UTAP_SUCCESS_BACK_HOME_CLICK = `utap_success_back_home_click`,
    TRANSACTION_CARD_CLICK = `transaction_card_click`,
    MEMBERSHIP_SUCCESS_CONTINUE_CLICK = `membership_success_continue_click`,
    DRAFT_UPDATE_INFO_CLICK = `draft_update_info_click`,
    DRAFT_CONTINUE_CLICK = `draft_continue_click`,
    CURATED_EXPERIENCES_SEE_MORE_CLICK = `curated_experiences_see_more_click`,
    CURATED_EXPERIENCES_CARD_CLICK = `curated_experiences_card_click`,
}

export default GtmEventName