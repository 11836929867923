import { CircularProgress, Container, Grid, Typography } from "@mui/material"
import clsx from "clsx"

import styles from "./LoadingPageContent.module.css"
import loadingImage from "../../../assets/loading.png"

interface LoadingPageContentProps {
    title: string
    message: string
}

const LoadingPageContent: React.FC<LoadingPageContentProps> = (props: LoadingPageContentProps) => {
    const { title, message } = props

    return (
        <Container>
            <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" >
                <Grid item display="flex" justifyContent="center">
                    <img src={loadingImage} className={clsx(styles.loadingImage)} alt="Loading image" />
                </Grid>

                <Grid item textAlign="center">
                    <Typography variant="h5" className={clsx(styles.loadingTitle)} mb={1}>{title}</Typography>
                </Grid>

                <Grid item textAlign="center">
                    <Typography variant="body1" mb={1}>{message}</Typography>
                </Grid>

                <Grid item xs={3}>
                    <CircularProgress className={clsx(styles.loadingTitle)} />
                </Grid>

            </Grid>
        </Container>
    )
}

export default LoadingPageContent