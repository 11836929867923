import { MutableRefObject } from 'react'
import { CardActionAreaProps } from '@mui/material/CardActionArea'
import { useNavigate } from "react-router-dom"

import LandingPageAccessGuard from '../LandingPageAccessGuard/LandingPageAccessGuard'
import { Programme, getProgrammeRoute } from '../../../types/Programme'
import AppCard from '../../atoms/AppCard/AppCard'
import useGoogleTagManager from '../../../helpers/analytics/use-google-tag-manager'

import { Route } from '../../../router'

interface ProgrammeCardProps extends CardActionAreaProps {
    programme: Programme,
    reference?: MutableRefObject<null>
    fullWidth?: boolean
}

const ProgrammeCard = (props: ProgrammeCardProps) => {
    const { programme, fullWidth, ...inheritedProps } = props

    const navigate = useNavigate()
    const { trackProgrammeClick, trackProgrammeRedirect } = useGoogleTagManager()

    const externalUrl = programme?.url
    const internalRoute = programme?.route
    const programmeRoute = programme ? getProgrammeRoute(programme) : undefined

    const handleCardClick = () => {
        trackProgrammeClick(programme)

        if (externalUrl) {
            trackProgrammeRedirect(programme)
            window.open(`${externalUrl}`, `_blank`)
        } else if (internalRoute) {
            navigate(internalRoute)
        } else {
            navigate(programmeRoute ?? Route.HOME)
        }
    }

    const handleOnLandingPageGuardClickHandled = () => {
        trackProgrammeClick(programme)
    }

    return (
        <LandingPageAccessGuard onClickHandled={handleOnLandingPageGuardClickHandled}>
            <AppCard onClick={handleCardClick} image={programme?.bannerImage} title={programme?.name} summary={programme?.summary} imageFit='contain' fullWidth={fullWidth} {...inheritedProps} />
        </LandingPageAccessGuard>
    )
}

export default ProgrammeCard