import { useContext, useEffect, useState } from 'react'
import { Box, Button, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import moment from "moment"

import { ServiceResponseError } from '../../../types/RemoteData'
import { DatePatterns } from "../../../types/date-patterns"
import StarterErrorCodes from '../../../types/ErrorCodes'
import PwaInstallationInstructionsAlert from '../../molecules/PwaInstallationInstructionsAlert/PwaInstallationInstructionsAlert'
import { useDigitalResourceRepository } from '../../../repositories/DigitalResource/use-digital-resource.repository'
import DigitalResourcesHomeList from '../../organisms/DigitalResourcesHomeList/DigitalResourcesHomeList'
import SplashScreenGuard from '../../../router/guards/SplashScreenGuard'
import PillarsList from '../../organisms/PillarsList/PillarsList'
import { AppContext } from '../../../stores/App/AppStore'
import { UserContext } from '../../../stores/User/UserStore'
import { PaymentOrderReason } from '../../../types/PaymentOrderReason'
import MembershipRepository from '../../../repositories/Membership/membership.repository'
import { MembershipType } from '../../../types/MembershipType'
import { useHomePageRepository } from '../../../repositories/HomePage/use-home-page-repository'
import { useMembershipRepository } from '../../../repositories/Membership/use-membership-repository'
import { useMembershipPlanRepository } from '../../../repositories/MembershipPlan/use-membership-plan-repository'
import { useUserRepository } from '../../../repositories/User/use-user-repository'
import { UPMEContentContext } from '../../../stores/UPMEContent/UPMEContentStore'
import useDealRepository from '../../../repositories/Deal/use-deal-repository'
import { reaches25Within6Months, messagePromptToUnionBaseOnAge, isValidMembershipExpiryDate, is6MonthsToMembershipExpiryDate, isUserUnderAge25OnExpiryDate } from '../../../helpers/utils/membership'
import DealsHomeList from '../../organisms/DealsHomeList/DealsHomeList'
import { MembershipStatus } from '../../../types/MembershipStatus'
import MembershipCardFloatingButton from '../../atoms/MembershipCardFloatingButton/MembershipCardFloatingButton'
import Prompt from '../../molecules/Prompt/Prompt'
import { Route } from '../../../router'
import { HighlightCarouselItemImageAspectRatio } from "../../molecules/HighlightCarouselItemComponent/HighlightCarouselItemComponent"
import HomeSkeleton from './Home.skeleton'
import MyEventsHomeList from '../../organisms/MyEventsHomeList/MyEventsHomeList'
import HighlightCarousel from '../../molecules/HighlightCarousel/HighlightCarousel'
import { useHomeCarouselRepository } from '../../../repositories/HomeCarousel/use-home-carousel-repository'
import { useMembershipSettingsRepository } from '../../../repositories/MembershipSettings/use-membership-settings-repository'
import { shouldUpdateProfileFromMembershipSetting, shouldUpdateProfileDateFromConfiguration } from '../../../helpers/utils/profile-update'
import { useProgrammeRepository } from '../../../repositories/Programme/use-programme-repository'
import ProgrammesList from '../../organisms/ProgrammesList/ProgrammesList'
import { ProgrammeContext } from '../../../stores/Programme/ProgrammeStore'
import ProgrammeApplicationFormsHomeList from '../../organisms/ProgrammeApplicationFormsHomeList/ProgrammeApplicationFormsHomeList'
import ConfirmationDialog from "../../molecules/ConfirmationDialog/ConfirmationDialog"
import UpgradeToNtucAlert from '../../molecules/UpgradeToNtucAlert/UpgradeToNtucAlert'
import { generateErrorCode } from '../../../helpers/logging/templates'
import useTrackPageView from "../../../helpers/hooks/use-track-page-view"
import useGoogleTagManager from '../../../helpers/analytics/use-google-tag-manager'
import GtmEventName from '../../../helpers/analytics/GtmEventName'

import styles from './Home.module.scss'

const DEFAULT_PAGE_SIZE = 3

const HomePage = () => {
    useTrackPageView(`Home Page`)

    const { trackButtonClick, trackPromptClick } = useGoogleTagManager()

    const { state } = useContext(AppContext)
    const { state: upmeContentState } = useContext(UPMEContentContext)
    const { state: userState } = useContext(UserContext)
    const { state: programmeState } = useContext(ProgrammeContext)

    // Azure Requirement #9606
    const isUpmeConversionFeatureEnabled = process.env.REACT_APP_UPME_CONVERSION_FEATURE_FLAG_ENABLED === `true`

    const { t } = useTranslation()
    const navigate = useNavigate()

    const { retrieveProgrammes } = useProgrammeRepository()
    const { retrieveHomePage } = useHomePageRepository()
    const { retrieveHomeCarousel } = useHomeCarouselRepository()
    const { retrieveMembership, resumeRecurringMembership } = useMembershipRepository()
    const { retrieveMembershipSettings } = useMembershipSettingsRepository()
    const { resetMembershipPlan } = useMembershipPlanRepository()
    const { retrieveDeals } = useDealRepository()
    const { retrieveUser } = useUserRepository()
    const { retrieveLimitedDigitalResourcesByCategory } = useDigitalResourceRepository()

    const userMembership = userState.membership?.data
    const membershipSettings = userState.membershipSettings?.data

    const [shouldDisplayUpgradeMembershipForGraduatedMember, setShouldDisplayUpgradeMembershipForGraduatedMember] = useState(false)
    const [isResumeRecurringConfirmationDialogOpen, setIsResumeRecurringConfirmationDialogOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [dialogErrorMessage, setDialogErrorMessage] = useState(``)
    const [isResumeRecurringErrorDialogOpen, setIsResumeRecurringErrorDialogOpen] = useState(false)
    const [isShowResumingPrompt, setIsShowResumingPrompt] = useState(true)
    const [isShowEarlyRenewalPrompt, setIsShowEarlyRenewalPrompt] = useState(true)

    const profileUpdateDateSetting = userState.membershipSettings?.data?.starterProfileUpdateDate || ``
    const userLastUpdatedDate = userMembership?.lastUpdateByUser || ``

    const shouldDisplayStarterProfileUpdatePrompt = userMembership?.type === MembershipType.STARTER && (
        shouldUpdateProfileFromMembershipSetting(profileUpdateDateSetting, userLastUpdatedDate) || shouldUpdateProfileDateFromConfiguration(userLastUpdatedDate)
    )

    const programmes = programmeState.programmes?.data

    const digitalResources = upmeContentState.limitedDigitalResourceByCategory?.data

    const isUserBelowAge25OnExpiryDate = isUserUnderAge25OnExpiryDate(userMembership?.dateOfBirth, userMembership?.expiryDate)
    const is6MonthsToExpiryDate = is6MonthsToMembershipExpiryDate(userMembership?.expiryDate)

    const shouldDisplayEarlyRenewalRecurringPaymentPrompt = userMembership?.status === MembershipStatus.ACTIVE && !userMembership.isOnRecurringPayment && isUserBelowAge25OnExpiryDate && is6MonthsToExpiryDate
    const shouldDisplayResumingRecurringPaymentPrompt = userMembership?.cancelSubscriptionAtCurrentPeriod && userMembership.hasStripeSubscription && userMembership?.status === MembershipStatus.ACTIVE && isValidMembershipExpiryDate(userMembership?.expiryDate) && isUserBelowAge25OnExpiryDate && is6MonthsToExpiryDate

    const shouldDisplayUnionMembershipPrompt = reaches25Within6Months(userMembership?.dateOfBirth) && userMembership?.type === MembershipType.STARTER
    const upgradeToUnionPromptMessage = userMembership?.dateOfBirth && messagePromptToUnionBaseOnAge(userMembership?.dateOfBirth, userMembership)

    const homeCarouselItems = state.homeCarouselItems?.data?.filter((homeCarouselItem) => homeCarouselItem.hideForLoggedInUsers === false)
    const deals = state.deals?.data

    const conditionForRendering = !!userMembership && !!state.pages.home && (!!homeCarouselItems && homeCarouselItems.length > 0)

    resetMembershipPlan()

    useEffect(() => {
        retrieveMembership()
        retrieveMembershipSettings()
        retrieveUser()
        displayWelcomeUnionMemberMessageIfRequired()
    }, [])

    useEffect(() => {
        if (userMembership) {
            retrieveHomePage(userMembership.type)
            retrieveHomeCarousel(userMembership.type)
            retrieveLimitedDigitalResourcesByCategory(userMembership?.type)
            retrieveDeals(userMembership.type, DEFAULT_PAGE_SIZE)
            retrieveProgrammes(userMembership.type)

            if (userMembership.isOnRecurringPayment) {
                setIsShowEarlyRenewalPrompt(false)
            }
        }
    }, [userMembership])

    useEffect(() => {
        const isMemberGraduated = userMembership?.institutionID?.id === userState.membershipSettings?.data?.graduatedInstitution?.id
        const shouldDisplayGraduatedUsersUpgradePrompt = (userMembership?.status === MembershipStatus.ACTIVE || userMembership?.status === MembershipStatus.TERMINATED) && isMemberGraduated
        setShouldDisplayUpgradeMembershipForGraduatedMember(shouldDisplayGraduatedUsersUpgradePrompt)
    }, [userMembership, membershipSettings])

    const closeUpgradeMembershipDialog = () => {
        setShouldDisplayUpgradeMembershipForGraduatedMember(false)
    }

    const displayWelcomeUnionMemberMessageIfRequired = () => {
        const membershipRepository = new MembershipRepository()

        if (!membershipRepository.hasDisplayedWelcomeUnionMemberDialog() && userMembership?.type === MembershipType.UPME) {
            navigate(Route.UPME_WELCOME_PAGE)
        }
    }

    const handleComeBackButtonClick = async () => {
        trackButtonClick(GtmEventName.COME_BACK_BUTTON_CLICK)
        navigate(`/membership/payment-summary/${PaymentOrderReason.SIGNUP}`, { replace: true })
    }

    const handleBecomeUnionMemberPromptClick = () => {
        trackPromptClick(GtmEventName.PROMPT_CLICK, `Update to Union Membership`)
        navigate(Route.UPME_CONVERSION_INTRO)
    }

    const handleUpdateProfilePromptClick = () => {
        trackPromptClick(GtmEventName.PROMPT_CLICK, `Update Profile`)
        navigate(Route.PROFILE_SUMMARY)
    }

    const handleGraduatedUserUpgradePromptClick = () => {
        trackPromptClick(GtmEventName.PROMPT_CLICK, `Graduated User`)
        navigate(Route.UPME_CONVERSION_INTRO)
    }

    const handleRecurringSubscriptionPromptClick = () => {
        trackPromptClick(GtmEventName.PROMPT_CLICK, `Early Renewal`)
        navigate(`/membership/payment-summary/${PaymentOrderReason.SIGNUP}`, { replace: true })
    }

    const handleResumeRecurringSubscriptionPromptClick = () => {
        trackPromptClick(GtmEventName.PROMPT_CLICK, `Resume Membership`)
        setIsResumeRecurringConfirmationDialogOpen(true)
    }

    const handleResumeMembershipError = (error: ServiceResponseError) => {
        if (error.errorCode === StarterErrorCodes.CANNOT_RESUME_MEMBERSHIP_ERROR) {
            setDialogErrorMessage(t(`recurring_resuming_error`) + ` error code: ${generateErrorCode()}`)
        }
    }

    const handleResumingRecurringDialogClick = () => {
        setLoading(true)
        resumeRecurringMembership(true)
            .then(() => {
                setIsShowResumingPrompt(false)
                setIsResumeRecurringConfirmationDialogOpen(false)
            })
            .catch(handleResumeMembershipError)
            .finally(() => {
                setLoading(false)
            })
    }

    const handleDismissResumingRecurringDialogClick = () => {
        setIsResumeRecurringConfirmationDialogOpen(false)
    }

    const handleDismissResumingRecurringErrorDialogClick = () => {
        setIsResumeRecurringErrorDialogOpen(false)
    }

    const starterMembershipFab = userMembership?.type === MembershipType.STARTER && <MembershipCardFloatingButton />

    const starterEarlyRenewalRecurringPaymentPrompt = shouldDisplayEarlyRenewalRecurringPaymentPrompt && (
        <Prompt
            visible={isShowEarlyRenewalPrompt}
            className={styles.prompt}
            message={t(`prompt_upgrade_to_recurring_payment`)}
            buttonText={t(`recurring_sign_up_button`)}
            variant={`warn`}
            onButtonClick={handleRecurringSubscriptionPromptClick}
        />
    )

    const starterResumingRecurringPaymentPrompt = shouldDisplayResumingRecurringPaymentPrompt && (
        <Prompt
            visible={isShowResumingPrompt}
            className={styles.prompt}
            message={t(`prompt_resume_recurring_payment`)}
            buttonText={t(`recurring_resuming_button`)}
            variant={`warn`}
            onButtonClick={handleResumeRecurringSubscriptionPromptClick}
        />
    )

    const starterUpdateProfilePrompt = shouldDisplayStarterProfileUpdatePrompt && (
        <Prompt
            className={styles.prompt}
            message={t(`prompt_update_profile`)}
            buttonText={t(`profile_update_button`)}
            variant={`warn`}
            onButtonClick={handleUpdateProfilePromptClick}
        />
    )

    const becomeUnionMemberPrompt = isUpmeConversionFeatureEnabled && shouldDisplayUnionMembershipPrompt &&
        <Prompt
            className={styles.prompt}
            message={upgradeToUnionPromptMessage?.message}
            buttonText={t(`prompt_become_union_member_button`)}
            variant={upgradeToUnionPromptMessage?.variant}
            onButtonClick={handleBecomeUnionMemberPromptClick}
        />

    const graduatedUserUpgradePrompt = shouldDisplayUpgradeMembershipForGraduatedMember &&
        <Prompt
            className={styles.prompt}
            message={t(`prompt_for_graduated_profile`)}
            buttonText={t(`prompt_for_graduated_profile_button`)}
            variant={`warn`}
            onButtonClick={handleGraduatedUserUpgradePromptClick}
        />

    const displayPromptTypeInOrder = (graduatedUserUpgradePrompt || becomeUnionMemberPrompt) || starterEarlyRenewalRecurringPaymentPrompt || starterResumingRecurringPaymentPrompt || starterUpdateProfilePrompt
    const formatExpiryDate = moment(userMembership?.expiryDate).format(DatePatterns.DATE)

    return (
        <SplashScreenGuard>
            <HomeSkeleton condition={conditionForRendering} >
                <Box>
                    {displayPromptTypeInOrder}
                    <Box className={styles.container}>
                        <Box className={styles.carouselGridItem}>
                            <HighlightCarousel highlightCarouselItems={homeCarouselItems} aspectRatio={HighlightCarouselItemImageAspectRatio.RECTANGLE} showBackground={true} />
                        </Box>
                        <Box className={styles.programmeApplicationFormsList} mt={2}>
                            <ProgrammeApplicationFormsHomeList />
                        </Box>
                        <Box className={styles.introduction}>
                            <Box>
                                <Typography variant='h5' mb={1}>{state.pages.home.welcomeText}</Typography>
                                <Typography variant='body1' mb={1}>{state.pages.home.description}</Typography>
                            </Box>
                            <Box>
                                {userMembership?.status === MembershipStatus.TERMINATED &&
                                    <Button variant='contained' fullWidth onClick={handleComeBackButtonClick}>{t(`home_page_come_back`)}</Button>
                                }
                            </Box>
                        </Box>
                        <Box className={styles.myEventsList}>
                            <MyEventsHomeList />
                        </Box>
                        <Box mt={2}>
                            <PillarsList />
                        </Box>
                        <Box className={styles.dealsList}>
                            <DealsHomeList deals={deals} />
                        </Box>
                        <Box className={styles.programmesList}>
                            <ProgrammesList programmes={programmes} />
                        </Box>
                        <Box className={styles.digitalResourcesList}>
                            <DigitalResourcesHomeList resources={digitalResources} />
                        </Box>
                        {starterMembershipFab}
                    </Box>
                    <PwaInstallationInstructionsAlert forceOpen={false} />
                    <ConfirmationDialog
                        confirmationTitle={t(`recurring_resuming_button`)}
                        confirmationText={t(`recurring_resuming_text`, { expiryDate: formatExpiryDate })}
                        dismissButtonText={t(`cancel`)}
                        buttonText={t(`confirm`)}
                        defaultOpen={isResumeRecurringConfirmationDialogOpen}
                        onConfirm={handleResumingRecurringDialogClick}
                        onDismiss={handleDismissResumingRecurringDialogClick}
                        loading={loading}
                    />
                    <ConfirmationDialog
                        buttonText={t(`common_button_confirmation`)}
                        confirmationText={dialogErrorMessage}
                        dismissButtonText={t(`cancel`)}
                        defaultOpen={isResumeRecurringErrorDialogOpen}
                        onConfirm={handleDismissResumingRecurringErrorDialogClick}
                        onDismiss={handleDismissResumingRecurringErrorDialogClick}
                    />
                    <UpgradeToNtucAlert forceOpen={shouldDisplayUpgradeMembershipForGraduatedMember} handleClose={closeUpgradeMembershipDialog} />
                </Box>
            </HomeSkeleton>
        </SplashScreenGuard>
    )
}

export default HomePage
