import { Skeleton } from "@mui/material"
import clsx from "clsx"

import styles from "./BannerImage.module.css"

interface BannerImageProps {
    imageUrl?: string
    className?: string
    alt?: string
}

const BannerImage = (props: BannerImageProps) => {
    const { imageUrl, className, alt } = props

    if (imageUrl) {
        return <img src={imageUrl} className={className} alt={alt} />
    }

    return <Skeleton variant="rectangular" className={clsx(styles.skeleton, styles.skeletonBannerImage)} />
}

export default BannerImage