import { fetchFAQs } from '../../clients/pages'
import { FaqEntry } from '../../types'
import { fromStrapiFaq } from '../../helpers/mapping/faq'
import { sortFaqEntries } from '../../helpers/utils/faq'
import { MembershipType } from '../../types/MembershipType'
import { StrapiFaqEntry } from '../../types/FaqEntry'

class FaqRepository {
    async getAll(membershipType: MembershipType): Promise<FaqEntry[]> {
        const { data } = await fetchFAQs(membershipType)
        const allFaqEntries = data.map((i: StrapiFaqEntry) => fromStrapiFaq(i))
        const sortedFaqEntries = sortFaqEntries(allFaqEntries)
        return sortedFaqEntries
    }
}

export default FaqRepository