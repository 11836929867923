import { useContext } from 'react'

import { AppContext } from '../../stores/App/AppStore'
import { ActionTypes } from '../../stores/App/AppReducer'
import TrainingProviderRepository from './training-provider.repository'

const useTrainingProviderRepository = () => {
    const { dispatch } = useContext(AppContext)
    const trainingProviderRepository = new TrainingProviderRepository()

    const retrieveTrainingProvider = () => {
        trainingProviderRepository.getAll()
            .then((data) => {
                dispatch({ type: ActionTypes.TRAINING_PROVIDER_RETRIEVE, data })
            })
            .catch((error) => {
                console.error(error.message)
            })
    }

    return {
        retrieveTrainingProvider,
    }
}

export { useTrainingProviderRepository }