import { useContext, useEffect } from 'react'
import { Button, Container, Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { AppContext } from '../../../stores/App/AppStore'
import { useCuratedExperienceRepository } from '../../../repositories/CuratedExperience/use-curated-experience-repository'
import CommonBackButton from '../../atoms/CommonBackButton/CommonBackButton'
import CuratedExperiencesList from '../../organisms/CuratedExperiencesList/CuratedExperiencesList'
import { Route } from '../../../router'
import SplashScreenGuard from '../../../router/guards/SplashScreenGuard'
import useTrackPageView from "../../../helpers/hooks/use-track-page-view"
import useGoogleTagManager from "../../../helpers/analytics/use-google-tag-manager"
import GtmEventName from "../../../helpers/analytics/GtmEventName"

const CuratedExperiences = () => {
    useTrackPageView(`Curated Experiences List`)

    const { state } = useContext(AppContext)
    const { t } = useTranslation()
    const { trackButtonClick } = useGoogleTagManager()

    const { retrieveCuratedExperiences, retrieveCuratedExperiencePage } = useCuratedExperienceRepository()

    useEffect(() => {
        retrieveCuratedExperiences()
        retrieveCuratedExperiencePage()
        window.scrollTo(0, 0)
    }, [])

    const handleClick = () => {
        trackButtonClick(GtmEventName.CURATED_EXPERIENCES_SEE_MORE_CLICK)
        window.open(state.pillars.curatedExperience.curatedExperiencePage.seeMoreURL, `_self`)
    }

    return (
        <SplashScreenGuard>
            <Container>
                <CommonBackButton path={Route.HOME} />

                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        <CuratedExperiencesList curatedExperiences={state.pillars.curatedExperience.experiences} />
                    </Grid>
                    <Grid item>
                        <Button variant='contained' onClick={handleClick} fullWidth>{t(`curated_experience_see_more`)}</Button>
                    </Grid>
                </Grid>
            </Container>
        </SplashScreenGuard>
    )
}

export default CuratedExperiences