import { useContext, useEffect } from 'react'
import { Container, Grid } from '@mui/material'

import { AppContext } from '../../../stores/App/AppStore'
import { usePrivacyPolicyRepository } from '../../../repositories/PrivacyPolicy/use-privacy-policy-repository'
import CommonBackButton from '../../atoms/CommonBackButton/CommonBackButton'
import MarkdownContent from '../../atoms/MarkdownContent/MarkdownContent'
import useTrackPageView from "../../../helpers/hooks/use-track-page-view"

import styles from './PrivacyPolicy.module.css'

const PrivacyPolicy = () => {
    useTrackPageView(`Privacy Policy`)

    const { state } = useContext(AppContext)
    const { retrievePrivacyPolicy } = usePrivacyPolicyRepository()

    useEffect(() => {
        retrievePrivacyPolicy()
        window.scrollTo(0, 0)
    }, [])

    return <Container>
        <CommonBackButton />
        <Grid container direction="column" spacing={2}>
            <Grid item className={styles.content}>
                <MarkdownContent content={state.pages.privacyPolicy.notice} className={styles.notice} />
                <MarkdownContent content={state.pages.privacyPolicy.privacyPolicy} />
            </Grid>
        </Grid>
    </Container>
}

export default PrivacyPolicy