import axios from "axios"
import { Location } from "react-router"
import { HTTP_SUCCESS } from "../helpers/utils/http-status"

const fetchStrapiSession = async (location: Location) => {
    const params = searchParamsToMap(location.search)
    const idToken = params.get(`id_token`)

    return axios.post(`${process.env.REACT_APP_STRAPI_URL}/api/pls/singpass/token`, { idToken })
        .then(res => {
            if (res.status !== HTTP_SUCCESS) {
                throw new Error(`Couldn't login to Strapi. Status: ${res.status}`)
            }
            return res
        })
        .then(res => res.data)
}

const searchParamsToMap = (search: string): Map<string, string> => {
    const result = new Map<string, string>()
    const params = search.replace(`?`, ``)
    params.split(`&`).forEach(param => {
        const paramParts = param.split(`=`)
        result.set(paramParts[0], paramParts[1])
    })
    return result
}

export default fetchStrapiSession