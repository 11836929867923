import { anonymousClient } from './util'
import { HTTP_SUCCESS } from '../helpers/utils/http-status'
import { Partner } from '../types/Partner'
import fromStrapiPartner from '../helpers/mapping/partner'

const fetchPartner = async (partnerCode: string): Promise<Partner> => {
    return anonymousClient()
        .get(
            `/api/partnership/partners/${partnerCode}`,
        ).then(res => {
            if (res.status !== HTTP_SUCCESS) {
                throw new Error(`Could not fetch Partner with code "${partnerCode}"`)
            }
            return res
        }).then(res => res.data).then(fromStrapiPartner)
}

export {
    fetchPartner,
}