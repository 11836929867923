export enum TermType {
    MONEY_OWL = `money-owl`,
    SNACK = `snack`,
    STARTER_APP = `starter-app`
}

export type TermsAndConditions = {
    title?: string,
    content: string,
    purpose: TermType
}