import { FaqEntry } from "../../../types"
import FAQEntryComponent from "../../molecules/FAQEntryComponent/FAQEntryComponent"
import FAQEntryComponentSkeleton from "../../molecules/FAQEntryComponent/FAQEntryComponent.skeleton"

interface FAQsListProps {
    faqEntries: FaqEntry[]
}

const FAQsList = (props: FAQsListProps) => {
    const { faqEntries } = props

    const sortedFaqEntries = [...faqEntries].sort((a, b) => (a.order ?? 0) - (b.order ?? 0))
    const faqEntriesComponents = sortedFaqEntries.map((faq) => <FAQEntryComponent faqEntry={faq} key={faq.id} />)

    if (faqEntriesComponents.length > 0) {
        return (
            <>
                {faqEntriesComponents}
            </>
        )
    }

    return (
        <>
            <FAQEntryComponentSkeleton />
            <FAQEntryComponentSkeleton />
            <FAQEntryComponentSkeleton />
        </>
    )
}

export default FAQsList