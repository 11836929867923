import { SimpleFAQEntry, StrapiSimpleFAQEntry } from '../../types/SimpleFAQEntry'

const fromStrapiSimpleFAQEntry = (strapiSimpleFAQEntry: StrapiSimpleFAQEntry): SimpleFAQEntry => (
    {
        id: strapiSimpleFAQEntry.id,
        question: strapiSimpleFAQEntry.question,
        answer: strapiSimpleFAQEntry.answer,
    }
)

export default fromStrapiSimpleFAQEntry