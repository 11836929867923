import { Box, BoxProps, Skeleton } from "@mui/material"

import styles from "./ScrollingTabs.module.scss"
import clsx from "clsx"

const ScrollingTabsSkeleton = (props: BoxProps) => {
    const { className, ...inheritedProps } = props

    const tabsSkeletonStyle = clsx(
        className,
        styles.categoriesTabs,
        styles.skeleton,
    )

    return (
        <Box className={tabsSkeletonStyle} {...inheritedProps}>
            <Skeleton variant="rectangular" />
            <Skeleton variant="rectangular" />
            <Skeleton variant="rectangular" />
        </Box>
    )
}

export default ScrollingTabsSkeleton