import { Tab, Tabs, TabsProps, useTheme } from "@mui/material"
import { useEffect, useState } from "react"
import { FaqEntry, FaqTags } from "../../../types"
import useGoogleTagManager from '../../../helpers/analytics/use-google-tag-manager'
import GtmEventName from '../../../helpers/analytics/GtmEventName'

interface FaqNavigationTabsProps extends TabsProps {
    faqEntries: FaqEntry[]
    selectedIndex: number
    onTabChange: (event: React.SyntheticEvent, newIndex: number, faqTag: FaqTags) => void
}

const FaqNavigationTabs = (props: FaqNavigationTabsProps) => {
    const { faqEntries, selectedIndex, onTabChange, ...inheritedProps } = props

    const [selectedTabValue, setSelectedTabValue] = useState(0)

    const theme = useTheme()
    const { trackButtonClick } = useGoogleTagManager()

    const [items, setItems] = useState<FaqTags[]>([])

    const handleTabChange = (event: React.SyntheticEvent, newIndex: number) => {
        trackButtonClick(GtmEventName.FAQ_CATEGORY_CLICK, items[newIndex].tag)
        setSelectedTabValue(newIndex)
        onTabChange?.(event, newIndex, items[newIndex])
    }

    useEffect(() => {
        setSelectedTabValue(selectedIndex)
    }, [selectedIndex])

    useEffect(() => {
        const faqTags: FaqTags[] = [
            {
                id: 0,
                tag: `All`,
                order: 1,
            },
        ]

        faqEntries.flatMap(entry => entry.tags).forEach(tag => {
            if (tag && !faqTags.find(t => t.id === tag.id)) {
                faqTags.push(tag)
            }
        })

        setItems(faqTags)
    }, [faqEntries])

    const getTabId = (item: FaqTags): string => {
        return `tab-item-${item.id}`
    }

    const tabColor = {
        '&.Mui-selected': {
            color: `${theme.palette.secondary.main}`,
        },
        textTransform: `capitalize`,
    }
    const indicatorColor = { '& .MuiTabs-indicator': { backgroundColor: `${theme.palette.secondary.main}` } }

    const renderTab = (items: FaqTags[]) => {
        return items.map(item => {
            return <Tab id={getTabId(item)} label={item.tag} key={getTabId(item)} sx={tabColor} />
        })
    }

    return (
        <Tabs value={selectedTabValue} onChange={handleTabChange} scrollButtons={false} sx={indicatorColor} variant="scrollable" aria-label="faq tags" {...inheritedProps} >
            {renderTab(items)}
        </Tabs>
    )
}

export default FaqNavigationTabs