import { Accordion, AccordionSummary, Skeleton } from "@mui/material"
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

import styles from './SimpleFAQItem.module.scss'

const SimpleFAQItemSkeleton = () => {
    return (
        <Accordion className={styles.entryContainer} elevation={0} disableGutters expanded={false}>
            <AccordionSummary aria-controls="panel2d-content" id="panel2d-header" expandIcon={<ArrowDropDownIcon />} sx={{ border: `none` }}>
                <Skeleton className={styles.entrySkeleton} variant="rectangular" />
            </AccordionSummary>
        </Accordion >
    )
}

export default SimpleFAQItemSkeleton