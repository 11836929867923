import { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Route } from '../../../router'
import { useSessionRepository } from '../../../repositories/Session/use-session-repository'
import { UserContext } from '../../../stores/User/UserStore'
import ConfirmationDialog from '../../molecules/ConfirmationDialog/ConfirmationDialog'
import LoadingPageContent from '../../organisms/LoadingPageContent/LoadingPageContent'
import { useMembershipRepository } from '../../../repositories/Membership/use-membership-repository'
import { fireEmptyVisitedSplashScreen } from '../../../stores/App/AppReducer'
import { AppContext } from '../../../stores/App/AppStore'
import { DialogContent } from '../../../types/DialogContent'
import StarterErrorCodes from '../../../types/ErrorCodes'
import { MembershipType } from '../../../types/MembershipType'

const PlsCallback = () => {
    const { state } = useContext(UserContext)
    const { dispatch: dispatchAppEvent } = useContext(AppContext)
    const { t } = useTranslation()
    const navigate = useNavigate()
    const location = useLocation()
    const [searchParams] = useSearchParams()
    const { disposeSession, retrieveSession } = useSessionRepository()
    const { retrieveMembership } = useMembershipRepository()

    const [dialogContent, setDialogContent] = useState<DialogContent>()

    const userMembership = state.membership?.data

    useEffect(() => {
        disposeSession()

        if (searchParams.get(`id_token`)) {
            fireEmptyVisitedSplashScreen(dispatchAppEvent)
            retrieveSession(location)
        }
    }, [])

    useEffect(() => {
        if (state.session?.data?.jwt) {
            retrieveMembership()
        } else if (state.session?.error) {
            let errorMessage = t(`linkpass_retrieving_error_message`)

            switch (state.session.error.errorCode) {
                case StarterErrorCodes.EMAIL_ALREADY_USED_BY_MEMBERSHIP:
                case StarterErrorCodes.EMAIL_ALREADY_USED_BY_USER:
                    errorMessage = t(`error_email_already_used`)
                    break
                case StarterErrorCodes.UNION_MEMBERSHIP_EMAIL_ALREADY_USED:
                    errorMessage = t(`error_union_email_already_used`)
                    break
            }

            setDialogContent({
                title: t(`linkpass_retrieving_error_title`),
                message: errorMessage,
            })
        }
    }, [state.session])

    useEffect(() => {
        if (state.session?.data?.jwt) {
            if (userMembership) {
                if (userMembership.type === MembershipType.STARTER && !userMembership.starterInterestTopics?.length) {
                    navigate(Route.MEMBERSHIP_USER_INTERESTS, { replace: true })
                } else if (userMembership.type === MembershipType.UPME && !userMembership.upmeInterestTopics?.length) {
                    navigate(Route.MEMBERSHIP_USER_INTERESTS, { replace: true })
                } else {
                    navigate(Route.HOME, { replace: true })
                }
            } else if (state.membership?.error) {
                navigate(Route.MEMBERSHIP_JOIN)
            }
        }
    }, [state.membership])

    const handleOnConfirm = () => {
        fireEmptyVisitedSplashScreen(dispatchAppEvent)
        setDialogContent(undefined)
        navigate(Route.ROOT, { replace: true })
    }

    return (
        <>
            <LoadingPageContent title={t(`linkpass_retrieving_title`)} message={t(`linkpass_retrieving_message`)} />
            <ConfirmationDialog
                acknowledgeOnly
                buttonText={t(`common_button_confirmation`)}
                confirmationTitle={dialogContent?.title}
                confirmationText={dialogContent?.message}
                defaultOpen={!!dialogContent}
                onConfirm={handleOnConfirm}
            />
        </>
    )
}

export default PlsCallback