import axios, { AxiosRequestHeaders } from "axios"
import { REQUESTED_ROUTE_KEY } from "../../router/guards/authenticated-guard"
import { Jwt } from "../../types"
import axiosRetry from 'axios-retry'
import jwtDecode from "jwt-decode"
import moment from "moment"

const baseURL = process.env.REACT_APP_STRAPI_URL
const milliseconds = 1000

axiosRetry(axios, {
    retries: 3,
    retryDelay: axiosRetry.exponentialDelay,
})

const anonymousClientConfig = {
    baseURL,
    headers: {
        'Authorization': `Bearer ${process.env.REACT_APP_STRAPI_TOKEN}`,
    },
    withCredentials: true,
}

const authenticatedClientConfig = {
    baseURL,
}

const getUserToken = (): string | null => {
    const SESSION_KEY = `userSession`
    const userSessionValue = localStorage.getItem(SESSION_KEY)

    if (!userSessionValue) {
        return null
    }

    const userSession = JSON.parse(userSessionValue)
    return userSession?.jwt.token
}

const anonymousClient = () => {
    return axios.create(anonymousClientConfig)
}

const authenticatedClient = (defaultToAnonymous?: boolean) => {
    const client = axios.create(authenticatedClientConfig)
    const userToken = getUserToken()

    if (!userToken && defaultToAnonymous) {
        return anonymousClient()
    }

    client.interceptors.request.use(
        (config) => {
            const tokenExpiryDate = authTokenExpiryDate(config.headers)

            if (!tokenExpiryDate || moment().isAfter(tokenExpiryDate)) {
                localStorage.setItem(REQUESTED_ROUTE_KEY, window.location.pathname)
                window.location.replace(`${window.location.origin}/api/pls/singpass`)
            }

            return config
        },
        (error) => {
            return Promise.reject(error)
        },
    )

    if (!userToken) {
        throw Error(`User is not authenticated`)
    }
    client.defaults.headers.put[`Content-Type`] = `application/json`
    client.defaults.headers.put[`Authorization`] = `Bearer ${userToken}`

    client.defaults.headers.post[`Content-Type`] = `application/json`
    client.defaults.headers.post[`Authorization`] = `Bearer ${userToken}`

    client.defaults.headers.get[`Content-Type`] = `application/json`
    client.defaults.headers.get[`Authorization`] = `Bearer ${userToken}`

    return client
}

const authTokenExpiryDate = (headers: AxiosRequestHeaders): Date | undefined => {
    if (headers.Authorization) {
        const authSplit = (headers.Authorization as string).split(` `)

        // eslint-disable-next-line no-magic-numbers
        if (authSplit.length === 2) {
            const decodedToken: Jwt = jwtDecode(authSplit[1])
            return new Date(decodedToken.exp * milliseconds)
        }
    }
}

export {
    anonymousClient,
    authenticatedClient,
}