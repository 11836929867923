import { useContext } from 'react'

import { AppContext } from '../../stores/App/AppStore'
import { ActionTypes } from '../../stores/App/AppReducer'
import VersionRepository from './version.repository'
import { getLocalVersion, storeLocalVersion } from '../../helpers/utils/version'

const useVersionRepository = () => {
    const { dispatch } = useContext(AppContext)
    const versionRepository = new VersionRepository()

    const fetchVersion = async () => {
        return versionRepository.retrieve()
            .then((data) => {
                dispatch({ type: ActionTypes.VERSION_RETRIEVE, version: data })
            })
            .catch((error) => {
                console.error(error.message)
            })
    }

    const getVersion = () => {
        return getLocalVersion()
    }

    const setVersion = (version: string) => {
        dispatch({
            type: ActionTypes.VERSION_SET, version: {
                current: version,
                upcoming: undefined,
            },
        })
        storeLocalVersion(version)
    }

    return {
        fetchVersion,
        setVersion,
        getVersion,
    }
}

export { useVersionRepository }