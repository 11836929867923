import { useContext } from 'react'

import { AppContext } from '../../stores/App/AppStore'
import { ActionTypes } from '../../stores/App/AppReducer'
import HomePageRepository from './home-page.repository'
import { MembershipType } from '../../types/MembershipType'

const useHomePageRepository = () => {
    const { dispatch } = useContext(AppContext)
    const homePageRepository = new HomePageRepository()

    const retrieveHomePage = (membershipType: MembershipType) => {
        homePageRepository.get(membershipType)
            .then((data) => {
                dispatch({ type: ActionTypes.PAGE_HOME_RETRIEVE, data })
            })
            .catch((error) => {
                console.error(error.message)
            })
    }

    return {
        retrieveHomePage,
    }
}

export { useHomePageRepository }