import moment from 'moment'
import { getAgeFromDateOfBirth } from '../utils/membership'
import { Membership } from '../../types'

const splitToNumbers = (ageRangeInput: string) => {
    return ageRangeInput.split(`-`).map(age => parseInt(age.replace(/^"/, ``)))
}

export const [minMemberAge, maxMemberAge] = process.env.REACT_APP_MEMBER_AGE_RANGE ? splitToNumbers(process.env.REACT_APP_MEMBER_AGE_RANGE) : [undefined, undefined]

export const isValidMemberAge = (dateOfBirth: Date | string) => {
    if (!minMemberAge || !maxMemberAge) {
        return true
    }

    const age = getAgeFromDateOfBirth(dateOfBirth)

    return age >= minMemberAge && age < maxMemberAge
}

export const isValidMemberAgeForRenewal = (membership?: Membership, userLiftAgeLimit?: boolean): boolean => {
    if (!membership) {
        return false
    }

    if (userLiftAgeLimit || !minMemberAge || !maxMemberAge) {
        return true
    }

    const renewalStartDate = moment(membership.expiryDate).add(1, `month`).startOf(`month`)
    const dateOfBirth = moment(membership.dateOfBirth)
    const userAgeAtNextRenewal = renewalStartDate.diff(dateOfBirth, `years`)

    return userAgeAtNextRenewal >= minMemberAge && userAgeAtNextRenewal < maxMemberAge
}