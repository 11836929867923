import { Chip, useTheme } from "@mui/material"
import { useTranslation } from 'react-i18next'
import { MembershipStatus } from "../../../types/MembershipStatus"
import { MembershipType } from "../../../types/MembershipType"

import styles from './MembershipStatusPill.module.scss'

interface MembershipStatusPillProps {
    membershipStatus?: MembershipStatus
    membershipType?: MembershipType
}

const MembershipStatusPill: React.FC<MembershipStatusPillProps> = ({ membershipStatus, membershipType }: MembershipStatusPillProps) => {
    const { t } = useTranslation()
    const { palette } = useTheme()

    const membershipTypeName = membershipType === MembershipType.UPME ? t(`member_upme`) : t(`member_starter`)

    if (membershipStatus && membershipType) {
        if (membershipStatus === MembershipStatus.TERMINATED) {
            const terminatedChipStyle = {
                color: `${palette.primary.contrastText}`,
                backgroundColor: `${palette.disabled.main}`,
            }

            return <Chip label={t(`profile_terminated`)} sx={terminatedChipStyle} className={styles.pill} />
        } else {
            return <Chip color="primary" label={membershipTypeName} className={styles.pill} />
        }
    }

    return null
}

export default MembershipStatusPill