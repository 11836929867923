import { joinProgramme, postMembership, retrieveMembership, retrieveUnionMembership, retrieveUnionMembershipWithNRIC, updateMembership, cancelMembership, uploadProfilePicture, retrieveProfilePicture, fetchAddressFromPostalCode, resumeRecurringMembership } from '../../clients/membership'
import { Membership, MyInfoUserData, SignUpForm } from '../../types'
import { fromStrapiCreateMembership } from '../../helpers/mapping/create-membership'
import { MembershipStatus, UnionMembershipStatus } from '../../types/MembershipStatus'
import { CancelMembership, ResumeRecurringMembership } from '../../types/Membership'
import { MembershipType } from '../../types/MembershipType'
import LocalStorageKeys from '../../types/LocalStorageKeys'
import { ProfilePictures } from '../../components/pages/Profile/ProfilePictureSelection/ProfilePictureSelection.utils'

class MembershipRepository {

    async createOne(signUpForm: SignUpForm, myInfoUserData: MyInfoUserData): Promise<Membership> {
        const data = await postMembership(signUpForm, myInfoUserData)
        return fromStrapiCreateMembership(data)
    }

    async updateMembership(signUpForm: SignUpForm, membershipID: number): Promise<Membership> {
        const membership = await updateMembership(signUpForm, membershipID)

        membership.type = getMembershipType(membership)

        return membership
    }

    async retrieveMembership(): Promise<Membership> {
        const membership: Membership = await retrieveMembership()

        membership.type = getMembershipType(membership)

        return membership
    }

    async joinProgramme(programme: string): Promise<Membership> {

        const membershipData = await joinProgramme(programme)
        membershipData.type = getMembershipType(membershipData)
        return membershipData
    }

    async retrieveUnionMembership(): Promise<UnionMembershipStatus> {
        const unionMembership = await retrieveUnionMembership()
        return unionMembership.UNION_MEMBERSHIP_STATUS
    }

    async retrieveUnionMembershipWithNRIC(nric: string): Promise<UnionMembershipStatus> {
        const unionMembership = await retrieveUnionMembershipWithNRIC(nric)
        return unionMembership.UNION_MEMBERSHIP_STATUS
    }

    async cancelMembership(terminateOnCancelDate: Date): Promise<CancelMembership> {
        return cancelMembership(terminateOnCancelDate)
    }

    async resumeRecurringMembership(resumeMembership: boolean): Promise<ResumeRecurringMembership> {
        return resumeRecurringMembership(resumeMembership)
    }

    async uploadProfilePicture(profilePictures: ProfilePictures): Promise<void> {
        return uploadProfilePicture(profilePictures)
    }

    async retrieveProfilePicture(): Promise<ArrayBuffer> {
        return retrieveProfilePicture()
    }

    async fetchAddressFromPostalCode(postalCode: string) {
        return fetchAddressFromPostalCode(postalCode)
    }

    didUserBecomeUnionMember(): boolean {
        return !!localStorage.getItem(LocalStorageKeys.USER_JUST_BECAME_UNION_MEMBER)
    }

    setUserJustBecameUnionMember() {
        localStorage.setItem(LocalStorageKeys.USER_JUST_BECAME_UNION_MEMBER, `true`)
    }

    clearUserJustBecameUnionMember() {
        localStorage.removeItem(LocalStorageKeys.USER_JUST_BECAME_UNION_MEMBER)
    }

    hasDisplayedWelcomeUnionMemberDialog(): boolean {
        return !!localStorage.getItem(LocalStorageKeys.DISPLAYED_WELCOME_UNION_MEMBER_DIALOG)
    }

    setDisplayedWelcomeUnionMemberDialog() {
        localStorage.setItem(LocalStorageKeys.DISPLAYED_WELCOME_UNION_MEMBER_DIALOG, `true`)
    }
}

const getMembershipType = (membership: Membership): MembershipType => {
    return membership.status === MembershipStatus.UNION_ACTIVE ? MembershipType.UPME : MembershipType.STARTER
}

export default MembershipRepository