import { useContext, useEffect, useState } from 'react'
import { Outlet, useLocation } from "react-router-dom"
import { CssBaseline, ThemeProvider, StyledEngineProvider, Theme } from '@mui/material'

import { UserContext, UserContextProvider } from './stores/User/UserStore'
import { AppContextProvider } from './stores/App/AppStore'
import { UPMEContentContextProvider } from './stores/UPMEContent/UPMEContentStore'
import Layout from './components/organisms/Layout/Layout'
import Maintenance from './components/pages/Maintenance/Maintenance'
import { isUnderMaintenance } from './clients/maintenance'
import LocalStorageKeys from './types/LocalStorageKeys'
import { DebugMode } from './types/DebugMode'
import MetaPixel from './helpers/utils/metaPixel'
import { useMembershipRepository } from './repositories/Membership/use-membership-repository'
import { MembershipType } from './types/MembershipType'
import starterTheme from './themes/starter'
import upmeTheme from './themes/upme'
import { MembershipContextProvider } from './stores/Membership/MembershipStore'
import { domainCheckMembershipType } from './helpers/utils/common'
import { ProgrammeContextProvider } from './stores/Programme/ProgrammeStore'
import { getAgeFromDateOfBirth } from './helpers/utils/membership'
import useGoogleTagManager, { GtmUserInfo } from './helpers/analytics/use-google-tag-manager'

const AppCore = () => {
    const { retrieveMembership } = useMembershipRepository()

    const { state: userState } = useContext(UserContext)
    const { setGtmUserInfo } = useGoogleTagManager()

    const membershipTypeFromDomainName = domainCheckMembershipType()

    const membership = userState.membership?.data

    const [userInfo, setUserInfo] = useState<GtmUserInfo>()
    const [prevUserInfo, setPrevUserInfo] = useState<GtmUserInfo>()

    useEffect(() => {
        retrieveMembership()
        setUserInfo(getUserInfo)
    }, [])

    useEffect(() => {
        if (membership) {
            setUserInfo(getUserInfo)
        }
    }, [membership])

    useEffect(() => {
        if (userInfo && areUserInfoDifferent(userInfo, prevUserInfo)) {
            setGtmUserInfo(userInfo)
            setPrevUserInfo({ ...userInfo })
        }
    }, [userInfo])

    const areUserInfoDifferent = (first: GtmUserInfo, second?: GtmUserInfo) => {
        return first.userName !== second?.userName
            || first.userAge !== second?.userAge
            || first.starterExchangeId !== second?.starterExchangeId
            || first.unionExchangeId !== second?.unionExchangeId
    }

    const getUserInfo = () => ({
        userName: membership?.fullName ?? `visitor`,
        userAge: membership?.dateOfBirth ? getAgeFromDateOfBirth(membership.dateOfBirth) : undefined,
        starterExchangeId: membership?.exchangeID,
        unionExchangeId: membership?.unionMembershipEid,
    })

    const appTheme = (): Theme => {
        switch (userState.membership?.data?.type || membershipTypeFromDomainName) {
            case MembershipType.STARTER:
                return starterTheme
            case MembershipType.UPME:
                return upmeTheme
        }
    }

    const theme = appTheme()

    return (
        <ThemeProvider theme={theme} >
            <Layout>
                <Outlet />
            </Layout>
        </ThemeProvider>
    )
}

function App() {
    const location = useLocation()

    const [displayMaintenancePage, setDisplayMaintenancePage] = useState<boolean>(false)
    const debugMode = localStorage.getItem(LocalStorageKeys.DEBUG_MODE)

    useEffect(() => {
        checkMaintenanceToggle()
    }, [location])

    const checkMaintenanceToggle = () => {
        if (!debugMode || debugMode !== DebugMode.DEBUG_ON) {
            isUnderMaintenance()
                .then(result => {
                    setDisplayMaintenancePage(result)
                })
        }
    }

    if (displayMaintenancePage) {
        return (
            <div className="App">
                <CssBaseline />
                <MetaPixel />
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={starterTheme} >
                        <Maintenance />
                    </ThemeProvider>
                </StyledEngineProvider>
            </div>
        )
    }

    return (
        <div className="App">
            <CssBaseline />
            <MetaPixel />
            <StyledEngineProvider injectFirst>
                <UserContextProvider>
                    <MembershipContextProvider>
                        <UPMEContentContextProvider>
                            <AppContextProvider>
                                <ProgrammeContextProvider>
                                    <AppCore />
                                </ProgrammeContextProvider>
                            </AppContextProvider>
                        </UPMEContentContextProvider>
                    </MembershipContextProvider>
                </UserContextProvider>
            </StyledEngineProvider>
        </div>
    )
}

export default App
