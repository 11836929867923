/* eslint-disable no-magic-numbers */
import { z as zod } from 'zod'
import { lessThanString, moreThanString } from '../validation-utils'
import phone from "phone"
import i18next from 'i18next'

const eCardName = zod
    .string()
    .min(3, lessThanString(3))
    .max(24, moreThanString(26))

const phoneNumber = zod
    .string()
    .min(8, `Must be 8 digits`)
    .max(8, `Must be 8 digits only`)
    .refine(input => {
        const phoneNumberValidation = phone(input, { country: `SG` })
        return phoneNumberValidation.isValid
    }, { message: i18next.t(`common_validation_error_phone_number`) })

const postalCode = zod
    .string()
    .max(6, `Must be 6 digits only`)

const email = zod.string()
    .email(`This is not a valid email.`)

const floorNumber = zod.string().regex(/\d*/)

const unitNumber = zod.string().regex(/\d*/)

const referralCode = zod.preprocess(
    input => input === `` ? undefined : input,
    zod
        .string()
        .regex(/^([A-Z0-9]{8}|[A-Z0-9]{12})$/, `Referral code must be either 8 or 12 characters long and contain only letters and numbers`)
        .optional(),
)

const SIGN_UP_FORM = {
    eCardName,
    phoneNumber,
    postalCode,
    floorNumber,
    unitNumber,
    referralCode,
    email,
}

export default SIGN_UP_FORM