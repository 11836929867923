import { useEffect, useState } from "react"
import { Checkbox, Typography, Grid } from "@mui/material"
import { useTranslation } from "react-i18next"

import styles from './UtapCourseAttendedCheckBox.module.css'

interface UtapCourseAttendedCheckBoxProps {
    courseHoursAttended: boolean
    onCourseAttendedChange: (value: boolean) => void
    error: boolean
}

const UtapCourseAttendedCheckBox = (props: UtapCourseAttendedCheckBoxProps) => {
    const { courseHoursAttended, onCourseAttendedChange, error } = props
    const { t } = useTranslation()
    const [localError, setLocalError] = useState<string | null>(null)

    useEffect(() => {
        if (error) {
            setLocalError(`Required`)
        } else {
            setLocalError(null)
        }
    }, [error])

    const handleOnCourseAttendedChange = () => {
        onCourseAttendedChange(!courseHoursAttended)
        if (courseHoursAttended) {
            setLocalError(`Required`)
        } else {
            setLocalError(null)
        }
    }

    return (
        <Grid className={styles.courseAttendedContainer}>
            <Checkbox checked={courseHoursAttended} onChange={handleOnCourseAttendedChange} color='functional' />
            <Typography variant="body1" >
                {t(`utap_claim_course_attended`)}
            </Typography>
            <Grid item>
                <Typography className={styles.error} variant="body1">{localError ?? ``}</Typography>
            </Grid>
        </Grid>
    )
}

export default UtapCourseAttendedCheckBox