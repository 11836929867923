import { authenticatedClient } from './util'
import { HTTP_SUCCESS } from '../helpers/utils/http-status'

const fetchUser = async () => {
    return authenticatedClient()
        .get(
            `/api/users/me`,
        ).then(res => {
            if (res.status !== HTTP_SUCCESS) {
                throw new Error(`Could not retrieve user data`)
            }
            return res
        }).then(res => res.data)
}

export {
    fetchUser,
}