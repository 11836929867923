import { Box, Skeleton } from "@mui/material"
import clsx from "clsx"
import styles from './HorizontalScrollComponent.module.scss'
import AppCardSkeleton from "../../atoms/AppCard/AppCard.skeleton"

const HorizontalScrollComponentSkeleton = () => {
    const scrollingWrapperClassName = clsx(
        styles.scrollingWrapper,
        styles.skeleton,
    )

    return (
        <>
            <Box className={styles.componentList}>
                <Skeleton className={styles.moduleTitleSkeleton} variant="text" />
            </Box>

            <Box className={scrollingWrapperClassName}>
                <AppCardSkeleton className={styles.cardWrapper} />
                <AppCardSkeleton className={styles.cardWrapper} />
            </Box>
        </>
    )
}

export default HorizontalScrollComponentSkeleton
