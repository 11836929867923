import { StrapiUpmeLimitedDigitalResourceDetailByCategory, UpmeLimitedDigitalResourceDetailByCategory } from '../../types/LimitedDigitalResourceDetailByCategory'
import { absoluteImageUrl } from '../utils/environment'

const fromStrapiUpmeLimitedDigitalResourceDetail = (strapiDigitalResourceDetail: StrapiUpmeLimitedDigitalResourceDetailByCategory[]): UpmeLimitedDigitalResourceDetailByCategory[] => {
    return strapiDigitalResourceDetail.map((category) => {
        return {
            ...category,
            placeHolderImage: category.placeHolderImage ? absoluteImageUrl(category.placeHolderImage.url) : ``,
            items: category.items.map((item) => ({
                ...item,
                image: item.image ? absoluteImageUrl(item.image.url) : ``,
            })),
        }
    })
}

export default fromStrapiUpmeLimitedDigitalResourceDetail