import { Box } from "@mui/material"

import { QuestionProps } from "../../../types/QuestionComponent"
import QuestionType from "../../../types/QuestionType"
import { QuestionnaireComponentType } from "../../../types/QuestionnaireComponentType"
import { LongTextQuestion } from "../../atoms/QuestionType/LongTextQuestion/LongTextQuestion"
import { ShortTextQuestion } from "../../atoms/QuestionType/ShortTextQuestion/ShortTextQuestion"
import { DropDownSelectQuestion } from "../../atoms/QuestionType/DropDownSelectQuestion/DropDownSelectQuestion"
import { RadioQuestion } from "../../atoms/QuestionType/RadioQuestion/RadioQuestion"
import { MultipleChoiceQuestion } from "../../atoms/QuestionType/MultipleChoiceQuestion/MultipleChoiceQuestion"
import QuestionAnswer from "../../../types/QuestionAnswer"

interface QuestionnaireProps {
    questionsList: QuestionProps[]
    attemptedSubmission?: boolean
    disabled?: boolean
    onQuestionChange: (answer: QuestionAnswer) => void
}

const Questionnaire = (props: QuestionnaireProps) => {
    const { questionsList, attemptedSubmission, disabled, onQuestionChange } = props

    const handleQuestionChange = (type: QuestionType, questionId: number, answer: string | string[], question: string, mandatory: boolean): void => {
        onQuestionChange({
            id: questionId,
            key: `${type}-question_${questionId}`,
            question,
            answer,
            mandatory,
            type,
        })
    }

    const questionRenderer = (component: QuestionProps) => {
        const { __component, question, mandatoryField, minOptions, maxOptions, options, id } = component

        const handleLongTextQuestion = (answer: string) => handleQuestionChange(QuestionType.LongText, id, answer, question, mandatoryField)
        const handleShortTextQuestion = (answer: string) => handleQuestionChange(QuestionType.ShortText, id, answer, question, mandatoryField)
        const handleDropDownSelectQuestion = (answer: string) => handleQuestionChange(QuestionType.Select, id, answer, question, mandatoryField)
        const handleRadioQuestion = (answer: string) => handleQuestionChange(QuestionType.Radio, id, answer, question, mandatoryField)
        const handleMultipleChoiceQuestion = (answers: string[]) => handleQuestionChange(QuestionType.Multi, id, answers, question, mandatoryField)

        const optionValues = options ?? []

        switch (__component) {
            case QuestionnaireComponentType.LONG_TEXT_QUESTION:
                return (
                    <LongTextQuestion
                        key={`${__component}_${id}`}
                        question={question}
                        isMandatory={mandatoryField}
                        attemptedSubmission={attemptedSubmission}
                        disabled={disabled}
                        onChange={handleLongTextQuestion}
                    />
                )
            case QuestionnaireComponentType.SHORT_TEXT_QUESTION:
                return (
                    <ShortTextQuestion
                        key={`${__component}_${id}`}
                        question={question}
                        isMandatory={mandatoryField}
                        attemptedSubmission={attemptedSubmission}
                        disabled={disabled}
                        onChange={handleShortTextQuestion}
                    />
                )
            case QuestionnaireComponentType.SELECT_QUESTION:
                return (
                    <DropDownSelectQuestion
                        key={`${__component}_${id}`}
                        question={question}
                        isMandatory={mandatoryField}
                        options={optionValues}
                        attemptedSubmission={attemptedSubmission}
                        disabled={disabled}
                        onChange={handleDropDownSelectQuestion}
                    />
                )
            case QuestionnaireComponentType.RADIO_QUESTION:
                return (
                    <RadioQuestion
                        key={`${__component}_${id}`}
                        question={question}
                        isMandatory={mandatoryField}
                        options={optionValues}
                        attemptedSubmission={attemptedSubmission}
                        disabled={disabled}
                        onChange={handleRadioQuestion}
                    />
                )
            case QuestionnaireComponentType.MULTIPLE_CHOICE_QUESTION:
                return (
                    <MultipleChoiceQuestion
                        key={`${__component}_${id}`}
                        question={question}
                        isMandatory={mandatoryField}
                        minOptions={minOptions ?? 0}
                        maxOptions={maxOptions ?? 0}
                        options={optionValues}
                        attemptedSubmission={attemptedSubmission}
                        disabled={disabled}
                        onChange={handleMultipleChoiceQuestion}
                    />
                )
            default:
                console.error(`No component for question of type ${__component}`)
                return null
        }
    }

    return (
        <Box>
            {questionsList.map(questionRenderer)}
        </Box>
    )
}

export default Questionnaire