import { authenticatedClient, anonymousClient } from "./util"
import { HTTP_SUCCESS } from "../helpers/utils/http-status"
import { MembershipType } from "../types/MembershipType"
import { EventRegistration } from "../types"
import moment from "moment"
import { DatePatterns } from "../types/date-patterns"
import { MembershipScope } from "../types/MembershipScope"
import { eventRegistrationPayload } from "../types/EventRegistration"

const fetchEvent = async (id: number) => {
    return authenticatedClient()
        .get(
            `/api/event-with-registration-status/${id}?populate[headerImage][populate]=*&populate[questionnaire][populate]=options,*`,
        ).then(res => {
            if (res.status !== HTTP_SUCCESS) {
                throw new Error(`Could not retrieve Event ${id}`)
            }
            return res
        }).then(res => res.data)
}

const fetchEventsPublic = async (membershipType: MembershipType) => {
    const today = moment().format(DatePatterns.YEAR_MONTH_DAY)

    return anonymousClient()
        .get(
            `/api/events?populate[headerImage][populate]=*&populate[questionnaire][populate]=options,*&filters[$and][0][$or][0][membershipScope]=${MembershipScope.ALL}&filters[$and][0][$or][1][membershipScope]=${membershipType}&filters[$and][1][$or][0][eventDate][$gte]=${today}&filters[$and][1][$or][1][eventDate][$null]=true`,
        ).then(res => {
            if (res.status !== HTTP_SUCCESS) {
                throw new Error(`Could not retrieve Events(Public)`)
            }
            return res
        }).then(res => res.data)
}

const fetchEvents = async (membershipType: MembershipType) => {
    const today = moment().format(DatePatterns.YEAR_MONTH_DAY)

    return authenticatedClient()
        .get(
            `/api/events-with-registration-status?populate[headerImage][populate]=*&populate[questionnaire][populate]=options,*&filters[$and][0][$or][0][membershipScope]=${MembershipScope.ALL}&filters[$and][0][$or][1][membershipScope]=${membershipType}&filters[$and][1][$or][0][eventDate][$gte]=${today}&filters[$and][1][$or][1][eventDate][$null]=true`,
        ).then(res => {
            if (res.status !== HTTP_SUCCESS) {
                throw new Error(`Could not retrieve Events`)
            }
            return res
        }).then(res => res.data)
}

const postEventRegistration = async (registration: EventRegistration) => {
    return authenticatedClient()
        .post(
            `/api/register-for-event`,
            eventRegistrationPayload(registration),
        ).then(res => {
            if (res.status !== HTTP_SUCCESS) {
                throw new Error(`Could not register event ${registration.eventId}`)
            }
            return res
        }).then(res => res.data)
}

const checkRegisteredEventStatus = async (id: number) => {
    return authenticatedClient()
        .get(
            `api/check-registration-status/${id}`,
        ).then(res => {
            if (res.status !== HTTP_SUCCESS) {
                throw new Error(`Could not retrieve member registered event ${id}`)
            }
            return res
        }).then(res => res.data)
}

const fetchRegisteredEvents = async (pageSize: number) => {
    return authenticatedClient()
        .get(
            `api/registered-events?populate=*`,
            { params: { pageSize } },
        ).then(res => {
            if (res.status !== HTTP_SUCCESS) {
                throw new Error(`Could not retrieve registered events of this member`)
            }
            return res
        }).then(res => res.data)
}

export {
    fetchEvent,
    fetchEvents,
    fetchEventsPublic,
    postEventRegistration,
    checkRegisteredEventStatus,
    fetchRegisteredEvents,
}