import ReactMarkdown from "react-markdown"
import rehypeRaw from "rehype-raw"
import DOMpurify from 'dompurify'
import SkeletonLines, { SkeletonLinesProps } from "../SkeletonLines/SkeletonLines"
import useMarkdownLinkTrackerRenderer from "../../../helpers/analytics/markdown-link-tracker-renderer"

interface MarkdownContentProps {
    content?: string,
    components?: object,
    className?: string,
}

const markdownImgRenderer = ({ ...props }) => {
    return <img style={{ maxWidth: `100%` }}{...props} alt="markdown image" />
}

const MarkdownContent = (props: MarkdownContentProps & SkeletonLinesProps) => {
    const {
        content,
        components,
        className = `reactMarkdown`,
        skeletonClassName,
        skeletonLines,
    } = props

    const markdownLinkTrackerRenderer = useMarkdownLinkTrackerRenderer()

    if (content) {
        let markdownComponents = {}

        if (components) {
            markdownComponents = {
                ...markdownComponents,
                ...components,
            }
        }

        markdownComponents = {
            ...markdownComponents,
            ...components,
            a: markdownLinkTrackerRenderer,
        }

        markdownComponents = {
            ...markdownComponents,
            img: markdownImgRenderer,
        }

        return (
            <ReactMarkdown rehypePlugins={[rehypeRaw]} className={className} components={markdownComponents}>
                {DOMpurify.sanitize(content)}
            </ReactMarkdown>
        )
    }

    return <SkeletonLines skeletonClassName={skeletonClassName} skeletonLines={skeletonLines} />
}

export default MarkdownContent