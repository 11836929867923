import { useContext, useEffect, useRef, useState } from 'react'
import { Box, Container, Grid } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import AlertDialog from '../../molecules/AlertDialog/AlertDialog'
import CommonBackButton from '../../atoms/CommonBackButton/CommonBackButton'
import ConfirmationDialog from '../../molecules/ConfirmationDialog/ConfirmationDialog'
import Spacer from '../../atoms/Spacer/Spacer'
import VerticalLinearStep from '../../molecules/VerticalLinearStep/VerticalLinearStep'
import { Route } from '../../../router'
import { UserContext } from '../../../stores/User/UserStore'
import { isPastDateLimit } from '../../../helpers/utils/membership'
import { programmeActivationSteps } from '../../../helpers/utils/programme-activation-steps'
import { useMembershipRepository } from '../../../repositories/Membership/use-membership-repository'
import { useRequestedRoute } from '../../../router/hooks/requested-route'
import { useSessionRepository } from '../../../repositories/Session/use-session-repository'
import BannerImage from '../../atoms/BannerImage/BannerImage'
import MarkdownContent from '../../atoms/MarkdownContent/MarkdownContent'
import { Programme, getProgrammeSlug } from '../../../types/Programme'
import { ProgrammeRegistration, getProgrammeRegistration, isMembershipActive } from '../../../types/Membership'
import SplashScreenGuard from '../../../router/guards/SplashScreenGuard'
import { LoadingButton } from '@mui/lab'
import { useProgrammeRepository } from '../../../repositories/Programme/use-programme-repository'
import { ProgrammeContext } from '../../../stores/Programme/ProgrammeStore'
import useTrackPageView from "../../../helpers/hooks/use-track-page-view"
import useGoogleTagManager from '../../../helpers/analytics/use-google-tag-manager'
import GtmEventName from '../../../helpers/analytics/GtmEventName'

import styles from './ProgrammeCVP.module.css'

enum Status {
    LOGGED_OUT = `LOGGED_OUT`,
    LOGGED_IN = `LOGGED_IN`,
    MEMBER = `MEMBER`,
    JOINED = `JOINED`,
    ACTIVE = `ACTIVE`,
    SUSPENDED = `SUSPENDED`,
}

const ProgrammeCVP = () => {
    const { programme: programmeSlug } = useParams()

    const { t } = useTranslation()

    const navigate = useNavigate()
    const { setRequestedRoute } = useRequestedRoute()
    const { trackButtonClick, trackRedirectOnButtonClick } = useGoogleTagManager()

    const { state: userState } = useContext(UserContext)
    const { state: programmeState } = useContext(ProgrammeContext)

    const { retrieveProgrammes } = useProgrammeRepository()
    const { isMember, isAuthenticated } = useSessionRepository()
    const { joinProgramme, retrieveMembership } = useMembershipRepository()

    const [programme, setProgramme] = useState<Programme>()
    const [programmeRegistration, setProgrammeRegistration] = useState<ProgrammeRegistration>()
    const [isSuspendedDialogOpen, setIsSuspendedDialogOpen] = useState(false)
    const [isOptInConfirmationDialogOpen, setIsOptInConfirmationDialogOpen] = useState(false)
    const [status, setStatus] = useState(Status.LOGGED_OUT)
    const [buttonText, setButtonText] = useState(t(`sign_in`))
    const [buttonLink, setButtonLink] = useState<Route | string>(Route.MEMBERSHIP_JOIN)
    const [buttonLoading, setButtonLoading] = useState(false)
    const [activationSteps, setActivationSteps] = useState<string[]>()

    const programmeRef = useRef<Programme>()

    const userMembership = userState.membership?.data
    const dateLimit = programme?.daysBeforeActivation
    const externalLinkText = programme?.externalLinkText
    const externalLink = programme?.externalLink

    useTrackPageView(() => {
        if (programme) {
            return {
                title: `Programme`,
                item: programme.name,
            }
        }
    }, [programme])

    useEffect(() => {
        setStatus(Status.LOGGED_IN)
        retrieveMembership()
    }, [])

    useEffect(() => {
        if (userMembership) {
            retrieveProgrammes(userMembership.type)
        }
    }, [userMembership])

    useEffect(() => {
        findProgrammes()
    }, [programmeState.programmes])

    useEffect(() => {
        if (programme && !programmeRegistration) {
            setProgrammeRegistration(getProgrammeRegistration(programme, userState.membership?.data))
        }

        programmeRef.current = programme

    }, [programme, userMembership])

    useEffect(() => {
        if (isAuthenticated()) {
            if (programmeRegistration?.optInDate) {
                handleProgrammeConsentStatus()
            } else {
                setStatus(Status.LOGGED_IN)
                isMember().then(handleIsMemberResult)
            }
        }
    }, [userMembership, programmeRegistration])

    useEffect(() => {
        switch (status) {
            case Status.LOGGED_IN:
            case Status.SUSPENDED:
            case Status.MEMBER:
                setButtonText(t(`programme_join`))
                setButtonLink(Route.MEMBERSHIP_JOIN)
                break
            case Status.JOINED:
            case Status.ACTIVE:
                setButtonText(externalLinkText ?? `UNDEFINED`)
                setButtonLink(externalLink ?? ``)
                break
            case Status.LOGGED_OUT:
                setButtonText(t(`sign_in`))
                setButtonLink(Route.PLS_LOGIN)
                break
        }

    }, [status])

    const findProgrammes = () => {
        const filteredProgramme = programmeState.programmes?.data?.find((programme) => getProgrammeSlug(programme) === programmeSlug)
        setProgramme(filteredProgramme)
    }

    const handleIsMemberResult = (isMember: boolean) => {
        if (isMember && isMembershipActive(userMembership)) {
            setIsSuspendedDialogOpen(false)
            setStatus(Status.MEMBER)
        } else if (isMember && !isMembershipActive(userMembership)) {
            setIsSuspendedDialogOpen(true)
        }
    }

    const handleProgrammeConsentStatus = () => {
        if (programmeRegistration) {
            setActivationSteps(programmeActivationSteps(programmeRegistration.optInDate, dateLimit, false))

            if (programmeRegistration?.optInDate) {
                setStatus(Status.JOINED)

                if (isPastDateLimit(programmeRegistration.optInDate, dateLimit)) {
                    setStatus(Status.ACTIVE)
                    setActivationSteps(programmeActivationSteps(programmeRegistration.optInDate, dateLimit, true))
                }
            }
        }
    }

    const handleLinkClick = () => {
        if (programme) {
            if (status === Status.MEMBER) {
                trackButtonClick(GtmEventName.JOIN_PROGRAMME_CLICK, programme.name)
                setIsOptInConfirmationDialogOpen(true)
            } else if (buttonLink === programme?.externalLink) {
                trackRedirectOnButtonClick(buttonLink)
                setButtonLoading(true)
                window.open(buttonLink, `_self`)
            } else {
                setRequestedRoute(Route.PROGRAMME_DETAILS, getProgrammeSlug(programme))
                navigate(buttonLink)
            }
        }
    }

    const handleConfirmClick = () => {
        trackButtonClick(GtmEventName.JOIN_PROGRAMME_CONFIRM, programme?.name)

        if (programmeSlug) {
            setIsOptInConfirmationDialogOpen(false)
            setButtonLoading(true)
            joinProgramme(programmeSlug).then(() => {
                setButtonLoading(false)
            })
        }
    }

    const handleCancelClick = () => {
        trackButtonClick(GtmEventName.JOIN_PROGRAMME_CANCEL, programme?.name)

        setIsOptInConfirmationDialogOpen(false)
    }

    const handleProgrammePopupTextDialog = () => {
        trackButtonClick(GtmEventName.DISMISS_PROGRAMME_MODAL, programme?.name)
    }

    return (
        <SplashScreenGuard>
            <Container>
                <CommonBackButton path={Route.HOME} />

                <Grid container className={styles.gridContainer}>
                    <Box className={styles.programmeBannerImage}>
                        <BannerImage imageUrl={programme?.bannerImage} className="App-logo" alt="logo" />
                    </Box>
                    <MarkdownContent
                        content={programme?.description}
                        className={styles.description}
                    />
                    {
                        programmeRegistration?.optInDate &&
                        <VerticalLinearStep steps={activationSteps} activeStep={status !== Status.ACTIVE ? 0 : 1} />
                    }
                    <Spacer flexNumber={2} />
                    <LoadingButton variant="contained" onClick={handleLinkClick} fullWidth loading={buttonLoading}>{buttonText}</LoadingButton>
                </Grid>

                <AlertDialog alertText={programme?.popupText} buttonText={t(`programme_back`)} onClose={handleProgrammePopupTextDialog} shouldShow />

                <ConfirmationDialog
                    acknowledgeOnly
                    buttonText={t(`common_button_confirmation`)}
                    confirmationTitle={t(`membership_suspended_title`)}
                    confirmationText={t(`membership_suspended_description`)}
                    defaultOpen={isSuspendedDialogOpen}
                    onConfirm={() => setIsSuspendedDialogOpen(false)}
                />

                <ConfirmationDialog
                    buttonText={t(`proceed`)}
                    dismissButtonText={t(`cancel`)}
                    confirmationTitle={t(`consent`)}
                    confirmationText={programme?.optInConfirmationText}
                    defaultOpen={isOptInConfirmationDialogOpen}
                    onConfirm={handleConfirmClick}
                    onDismiss={handleCancelClick}
                />
            </Container>
        </SplashScreenGuard>
    )
}

export default ProgrammeCVP