import type { ServiceResponse } from '../../types'

import type { MembershipState } from './MembershipState'
import { UserInterestTopic } from '../../types/UserInterestTopic'
import { HighlightCarouselItem } from '../../types/HighlightCarouselItem'

enum MembershipActions {
    USER_INTEREST_TOPICS_RETRIEVED,
    STARTER_ONBOARDING_CAROUSEL_RETRIEVED,
}

type MembershipAction =
    | { type: MembershipActions.USER_INTEREST_TOPICS_RETRIEVED, userInterestTopics: ServiceResponse<UserInterestTopic[]> }
    | { type: MembershipActions.STARTER_ONBOARDING_CAROUSEL_RETRIEVED, starterOnboardingCarousel: ServiceResponse<HighlightCarouselItem[]> }

const MembershipReducer = (state: MembershipState, action: MembershipAction): MembershipState => {
    switch (action.type) {
        case MembershipActions.USER_INTEREST_TOPICS_RETRIEVED: {
            return {
                ...state,
                userInterestTopics: action.userInterestTopics,
            }
        }
        case MembershipActions.STARTER_ONBOARDING_CAROUSEL_RETRIEVED: {
            return {
                ...state,
                starterOnboardingCarousel: action.starterOnboardingCarousel,
            }
        }
        default: {
            throw new Error(`Unhandled action type`)
        }
    }
}

export default MembershipReducer
export type { MembershipAction }
export {
    MembershipActions,
}