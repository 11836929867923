import { fetchDealById, fetchAllDeals } from "../../clients/deals"
import fromStrapiDeal from "../../helpers/mapping/deal"
import { MembershipType } from "../../types/MembershipType"
import { Deal } from "../../types/Deal"

class DealRepository {
    async retrieveAllDeals(membershipType: MembershipType, pageSize?: number): Promise<Deal[]> {
        const dealsResponse = await fetchAllDeals(membershipType, pageSize)
        const deals = dealsResponse.data.map(fromStrapiDeal)

        const sortedDeals = deals.sort((a: Deal, b: Deal) => {
            if (a.order === b.order) {
                const dateA = new Date(a.startDate)
                const dateB = new Date(b.startDate)

                if (dateA > dateB) {
                    // eslint-disable-next-line no-magic-numbers
                    return -1
                }

                if (dateA < dateB) {
                    return 1
                }

                return 0
            }

            if (a.order === 0) {
                return 1
            }

            if (b.order === 0) {
                // eslint-disable-next-line no-magic-numbers
                return -1
            }

            return a.order - b.order
        })

        return sortedDeals
    }

    async retrieveDealById(id: number): Promise<Deal> {
        const dealByIdResponse = await fetchDealById(id)
        const deal = fromStrapiDeal(dealByIdResponse.data)
        return deal
    }
}

export default DealRepository