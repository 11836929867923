import { useContext } from 'react'
import { UserContext } from '../../stores/User/UserStore'
import { UserInfo, UserInfoServiceResponse } from '../../types'
import { UserActionTypes } from '../../stores/User/UserReducer'
import UserRepository from './user.repository'

const useUserRepository = () => {
    const { dispatch } = useContext(UserContext)

    const userRepository = new UserRepository()

    const retrieveUser = async () => {
        const userData: UserInfoServiceResponse = {}

        return userRepository.retrieveUser()
            .then((userInfo: UserInfo) => {
                userData.data = userInfo

            })
            .catch((error) => {
                userData.error = {
                    errorMessage: error.message,
                    errorCode: error.response?.status,
                }
            })
            .finally(() => {
                dispatch({
                    type: UserActionTypes.USER_INFO_RETRIEVED,
                    data: userData,
                })
            })
    }

    return {
        retrieveUser,
    }
}

export { useUserRepository }