import react, { useEffect } from "react"
import clsx from "clsx"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { TransitionProps } from "@mui/material/transitions"
import { Typography, Zoom } from "@mui/material"
import { useTranslation } from "react-i18next"
import remarkGfm from 'remark-gfm'
import ReactMarkdown from 'react-markdown'
import { LoadingButton } from "@mui/lab"
import useGoogleTagManager from '../../../helpers/analytics/use-google-tag-manager'

import styles from './ConfirmationDialog.module.css'

const Transition = react.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<string, string>;
    },
    ref: React.Ref<unknown>,
) {
    return <Zoom ref={ref} {...props} />
})

interface ConfirmationDialogProps {
    acknowledgeOnly?: boolean
    buttonText: string
    dismissButtonText?: string
    confirmationTitle?: string
    confirmationText?: string
    defaultOpen: boolean
    loading?: boolean
    onDismiss?: () => void
    onConfirm: () => void
    shouldShowCloseButton?: boolean
}

const ConfirmationDialog = ({
    acknowledgeOnly,
    buttonText,
    dismissButtonText,
    confirmationTitle,
    confirmationText,
    defaultOpen,
    loading,
    onDismiss,
    onConfirm,
    shouldShowCloseButton,
}: ConfirmationDialogProps) => {
    const { t } = useTranslation()
    const { trackConfirmationDialogConfirm, trackConfirmationDialogDismiss, trackConfirmationDialogOpen } = useGoogleTagManager()

    useEffect(() => {
        if (defaultOpen) {
            trackConfirmationDialogOpen(confirmationTitle)
        }
    }, [defaultOpen])

    const actionsClassName = clsx(styles.actions, {
        [styles.singleAction]: acknowledgeOnly,
    })

    const handleConfirmClick = () => {
        trackConfirmationDialogConfirm(confirmationTitle)
        onConfirm?.()
    }

    const handleDismisClick = () => {
        trackConfirmationDialogDismiss(confirmationTitle)
        onDismiss?.()
    }

    return (
        <Dialog open={defaultOpen} TransitionComponent={Transition} classes={{ paper: styles.dialog }} >
            {shouldShowCloseButton && <IconButton aria-label="close" onClick={handleDismisClick} className={styles.closeButton}>
                <CloseIcon />
            </IconButton>}
            <DialogContent>
                <Typography variant="h6" className={styles.title}>{confirmationTitle}</Typography>
                <ReactMarkdown className={`reactMarkdown`} remarkPlugins={[remarkGfm]}>{confirmationText ?? ``}</ReactMarkdown>
            </DialogContent>
            <DialogActions className={actionsClassName}>
                {!acknowledgeOnly ? (
                    <LoadingButton variant="outlined" onClick={handleDismisClick}>{dismissButtonText ?? t(`close`)}</LoadingButton>
                ) : null}
                <LoadingButton variant="contained" onClick={handleConfirmClick} fullWidth={acknowledgeOnly} loading={loading}>{buttonText}</LoadingButton>
            </DialogActions>
        </Dialog>
    )
}

export default ConfirmationDialog