import react, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { TransitionProps } from "@mui/material/transitions"
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import { useNavigate } from 'react-router-dom'
import { Box, Typography, Zoom } from "@mui/material"

import { Route } from '../../../router'
import UpmeUpdateNowFooter from "../../molecules/UpmeUpdateNowFooter/UpmeUpdateNowFooter"
import UpgradeUnionCard from '../../molecules/UpgradeUnionCard/UpgradeUnionCard'

import styles from './UpgradeToNtucAlert.module.scss'
import SessionStorageKeys from "../../../types/SessionStorageKeys"

const Transition = react.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<string, string>;
    },
    ref: React.Ref<unknown>,
) {
    return <Zoom ref={ref} {...props} />
})

interface UpgradeToNtucAlertProps {
    forceOpen?: boolean
    handleClose: () => void
}

const UpgradeToNtucAlert = (props: UpgradeToNtucAlertProps) => {
    const { handleClose, forceOpen } = props
    const { t } = useTranslation()

    const navigate = useNavigate()

    const [open, setOpen] = useState(forceOpen)

    useEffect(() => {
        if (forceOpen) {
            const hasShownPopupAlreadyDuringSession = sessionStorage.getItem(SessionStorageKeys.DISPLAYED_UPGRADE_POPUP_FOR_GRADUATED_MEMBER)

            if (hasShownPopupAlreadyDuringSession === `true`) {
                setOpen(false)
            } else {
                sessionStorage.setItem(SessionStorageKeys.DISPLAYED_UPGRADE_POPUP_FOR_GRADUATED_MEMBER, `true`)
            }
        }
    }, [forceOpen])

    const handleDismissModalClick = () => {
        setOpen(false)
    }

    const handleUnionCardClick = () => {
        navigate(`${Route.UPME_CONVERSION_BENEFITS}`)
    }

    return (
        <Dialog open={!!open} TransitionComponent={Transition} onClose={handleClose} >
            <DialogContent>
                <IconButton aria-label="close" onClick={handleDismissModalClick} className={styles.closeButton}>
                    <CloseIcon />
                </IconButton>
                <Box>
                    <DialogContentText className={styles.title} variant="h6">
                        {t(`profile_upgrade_card_title`)}
                    </DialogContentText>
                </Box>
                <Typography className={styles.message} variant="h6">{t(`prompt_for_graduated_profile`)}</Typography>
                <UpgradeUnionCard handleUnionCardClick={handleUnionCardClick} />
            </DialogContent>
            <DialogActions>
                <UpmeUpdateNowFooter />
            </DialogActions>
        </Dialog>
    )
}

export default UpgradeToNtucAlert