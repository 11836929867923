import React, { useMemo, type Dispatch } from 'react'

import AppReducer, { type AppAction } from './AppReducer'
import { AppInitialState, type AppState } from './AppState'

const AppContext = React.createContext<{
    state: AppState,
    dispatch: Dispatch<AppAction>
}>({
    state: AppInitialState,
    dispatch: () => null,
})

const AppContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [state, dispatch] = React.useReducer(AppReducer, AppInitialState)
    const providerValues = useMemo(() => ({ state, dispatch }), [state])

    return (
        <AppContext.Provider value={providerValues}>
            {children}
        </AppContext.Provider>
    )
}

export { AppContextProvider, AppContext }