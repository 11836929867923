/* eslint-disable no-magic-numbers */

const enum StarterErrorCodes {
    MEMBERSHIP_NOT_INCOMPLETE = 601,
    UIN_NOT_MATCHING_USER = 602,
    USER_IS_UNION_MEMBER = 603,
    USER_AGE_INVALID = 604,
    EMAIL_ALREADY_USED_BY_MEMBERSHIP = 605,
    EMAIL_ALREADY_USED_BY_USER = 606,
    UNION_MEMBERSHIP_EMAIL_ALREADY_USED = 607,
    MEMBERSHIP_NOT_ACTIVE = 608,
    MEMBERSHIP_ALREADY_SIGN_UP_EVENT = 609,
    EVENT_REGISTRATION_IS_NOT_OPEN = 610,
    EVENT_REGISTRATION_DEADLINE = 611,
    EVENT_NOT_ACCESSIBLE_FOR_MEMBERSHIP_TYPE = 612,
    FILE_IS_TOO_BIG = 613,
    FILE_UPLOAD_ERROR = 614,
    APPLICATION_ALREADY_SUBMITTED_ERROR = 615,
    CANNOT_RESUME_MEMBERSHIP_ERROR = 616,
}

export default StarterErrorCodes