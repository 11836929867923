import { Dispatch, SetStateAction } from "react"
import { Box, Typography } from "@mui/material"
import { t } from "i18next"

import { QuestionProps } from "../../../types/QuestionComponent"
import { QuestionResponses } from "../../../types/QuestionResponses"
import Questionnaire from "../../organisms/Questionnaire/Questionnaire"
import QuestionAnswer from "../../../types/QuestionAnswer"

interface QuestionComponentProps {
    questionnaireList: QuestionProps[]
    attemptedSubmission: boolean
    setRegistrationData: Dispatch<SetStateAction<QuestionResponses>>
}

export const EventRegistrationQuestions = (props: QuestionComponentProps) => {
    const { questionnaireList, attemptedSubmission, setRegistrationData } = props

    const handleQuestionChange = (answer: QuestionAnswer) => {
        setRegistrationData((prevData: QuestionResponses) => ({
            ...prevData,
            ...{
                [`${answer.key}`]: { ...answer },
            },
        }))
    }

    if (questionnaireList.length === 0) {
        return <></>
    }

    return (
        <Box>
            <Typography variant="subtitle1" align="center" mb={2}>
                {t(`event_registration_message`)}
            </Typography>

            <Questionnaire questionsList={questionnaireList} attemptedSubmission={attemptedSubmission} onQuestionChange={handleQuestionChange} />
        </Box>
    )
}
