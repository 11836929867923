import { TermsAndConditions } from '../../types'
import { fetchTermsAndConditions, fetchTermsAndConditionsByType } from '../../clients/terms-and-conditions'
import { fromStrapiTerms } from '../../helpers/mapping/terms'

class TermsAndConditionsRepository {

    async get(purpose: string): Promise<TermsAndConditions> {
        const { data } = await fetchTermsAndConditionsByType(purpose)
        return fromStrapiTerms(data[0])
    }

    async getAll(): Promise<TermsAndConditions[]> {
        const { data } = await fetchTermsAndConditions()
        return data.map((i: unknown) => fromStrapiTerms(i))
    }
}

export default TermsAndConditionsRepository