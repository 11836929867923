/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeEventHandler, useEffect, useRef, useState } from 'react'
import { ZodEffects, ZodNumber, ZodOptional, ZodString } from 'zod'
import clsx from 'clsx'
import { Grid, InputLabel, TextField, Typography } from '@mui/material'

import Spacer from '../Spacer/Spacer'
import { getZodErrorMessage } from '../../../helpers/utils/zod'
import { trimNonDigits } from '../../../helpers/utils/parse'
import styles from './CustomInput.module.css'

type CustomInputProps = {
    disabled?: boolean
    eagerChange?: boolean
    error?: boolean
    fullWidth?: boolean
    insetLabel?: boolean
    label: string
    numbersOnly?: boolean
    onChange?: (value: string) => void
    params?: any
    placeholder?: string
    readOnly?: boolean
    validation?: ZodString | ZodNumber | ZodEffects<ZodString, string, string> | ZodOptional<ZodString> | ZodEffects<ZodOptional<ZodString>, string | undefined, unknown>
    value: string | undefined,
    isSignUp: boolean,
    isFocused?: boolean,
    isUpperCase?: boolean,
}

const CustomInput = ({
    disabled,
    eagerChange,
    error,
    fullWidth,
    insetLabel,
    label,
    numbersOnly,
    onChange,
    params,
    placeholder,
    readOnly,
    validation,
    value,
    isSignUp,
    isFocused,
    isUpperCase = false,
}: CustomInputProps) => {
    const [localError, setLocalError] = useState<string | null>(null)
    const inputRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
        if (error) {
            setLocalError(`Required`)
        } else {
            setLocalError(null)
        }
    }, [error])

    useEffect(() => {
        if (isFocused && inputRef.current) {
            inputRef.current.focus()
        }
    }, [isFocused])

    const handleChange: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = (e) => {
        const targetValue = e.target.value
        let input = numbersOnly ? trimNonDigits(targetValue) : targetValue
        let errorMessage = ``

        input = isUpperCase ? input.toUpperCase() : input

        if (!readOnly) {

            // Call onChange despite validation errors
            if (onChange && eagerChange) {
                onChange(input)
            }

            // Get validations errors if any
            try {
                validation?.parse(input)
            } catch (error: any) {
                errorMessage = getZodErrorMessage(error.issues)
            }

            if (onChange && !eagerChange && !errorMessage) {
                onChange(input)
            }

            if (error && !input) {
                setLocalError(`Required`)
            } else {
                setLocalError(errorMessage)
            }
        }
    }

    const inputProps = isUpperCase && { style: { textTransform: `uppercase` } }

    return (
        !isSignUp ?
            <>
                {!insetLabel && (
                    <InputLabel>
                        <Typography variant="body2">
                            {label}
                        </Typography>
                    </InputLabel>
                )}
                <TextField
                    className={clsx(
                        { [styles.readOnly]: readOnly },
                        { [styles.fullWidth]: fullWidth },
                    )}
                    disabled={disabled || readOnly}
                    error={!!localError}
                    helperText={localError}
                    label={insetLabel ? label : undefined}
                    onChange={handleChange}
                    placeholder={placeholder}
                    value={value}
                    variant="outlined"
                    inputRef={inputRef}
                    {...params}
                    color='functional'
                />
                {!localError && (
                    <Spacer height={23} />
                )}
            </>
            :
            <Grid container rowSpacing={1} columnSpacing={4} textAlign="right" alignItems="center" justifyContent="center">
                {!insetLabel && (
                    <Grid item xs={3} md={2}>
                        <Typography noWrap={false} variant="body2">
                            {label}
                        </Typography>
                        {!localError && (
                            <Spacer height={23} />
                        )}
                    </Grid>
                )}
                <Grid item xs={9} md={10}>
                    <TextField
                        className={clsx(
                            { [styles.readOnly]: readOnly },
                            { [styles.fullWidth]: fullWidth },
                        )}
                        disabled={disabled || readOnly}
                        error={!!localError}
                        helperText={localError}
                        label={insetLabel ? label : undefined}
                        onChange={handleChange}
                        placeholder={placeholder}
                        value={value}
                        variant="outlined"
                        inputProps={inputProps}
                        {...params}
                        color='functional'
                    />
                    {!localError && (
                        <Spacer height={23} />
                    )}
                </Grid>
            </Grid>
    )
}

export default CustomInput