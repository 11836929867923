import { Box, Card, CardActionArea, CardContent, CardMedia, Typography } from '@mui/material'
import clsx from 'clsx'

import { Pillar } from '../../../types'
import { isUpcomingPillar } from '../../../types/Pillar'
import { getProgrammeRoute } from '../../../types/Programme'
import ComingSoonBadge from '../../atoms/ComingSoonBadge/ComingSoonBadge'
import { useNavigate } from 'react-router-dom'
import { Route } from '../../../router'
import useGoogleTagManager from '../../../helpers/analytics/use-google-tag-manager'

import styles from './PillarCard.module.css'

interface PillarCardProps {
    pillar: Pillar,
}

const PillarCard = (props: PillarCardProps) => {
    const { pillar } = props

    const navigate = useNavigate()
    const { trackPillarClick, trackPillarRedirect } = useGoogleTagManager()

    const isUpcoming = isUpcomingPillar(pillar)

    const handlePillarCardClick = () => {
        trackPillarClick(pillar)

        if (pillar.route) {
            navigate(pillar.route)
        } else if (pillar.url) {
            trackPillarRedirect(pillar)
            window.open(pillar.url, `_blank`)
        } else if (pillar.programme) {
            navigate(getProgrammeRoute(pillar.programme))
        } else if (pillar.faqEntries?.length) {
            navigate(`${Route.FAQ_FOR_PILLAR}/${pillar.id}`)
        }
    }

    return (
        <Box mb={3}>
            <CardActionArea onClick={handlePillarCardClick}>
                <Card className={clsx(styles.pillarCardBox, { [styles.upcoming]: isUpcoming })} elevation={2}>
                    <CardMedia component="img" className={styles.pillarCardMedia} image={pillar.imageURL} />
                    <CardContent className={styles.pillarCardContent}>
                        <Typography variant="h6" className={styles.title}>{pillar.title}</Typography>
                        <Typography variant="body2">{pillar.description}</Typography>
                    </CardContent>
                    {isUpcoming &&
                        <ComingSoonBadge />}
                </Card>
            </CardActionArea>
        </Box>
    )
}

export default PillarCard