import { anonymousClient } from './util'
import { HTTP_SUCCESS } from '../helpers/utils/http-status'
import { MembershipType } from '../types/MembershipType'

const fetchAllDeals = async (membershipType: MembershipType, limit?: number) => {
    const DEFAULT_LIMIT = 20
    const fetchLimit = limit ?? DEFAULT_LIMIT
    return anonymousClient()
        .get(`/api/deals?populate[0]=image&populate[1]=category&sort=order:DESC,startDate:DESC&pagination[limit]=${fetchLimit}&filters[$or][0][membershipScope]=ALL&filters[$or][0][membershipScope]=${membershipType}`).then(res => {
            if (res.status !== HTTP_SUCCESS) {
                throw new Error(`Could not fetch deals`)
            }
            return res.data
        })
}

const fetchDealById = async (id: number) => {
    return anonymousClient()
        .get(`/api/deals/${id}?populate=*`).then(res => {
            if (res.status !== HTTP_SUCCESS) {
                throw new Error(`Could not fetch deals by id`)
            }
            return res.data
        })
}

export {
    fetchDealById,
    fetchAllDeals,
}