import { ProgrammesServiceResponse, ServiceResponse } from "../../types"
import { ProgrammeApplicationForm } from "../../types/ProgrammeApplicationForm"

type ProgrammeState = {
    programmeApplicationForms?: ServiceResponse<ProgrammeApplicationForm[]>
    programmes?: ProgrammesServiceResponse
}

const ProgrammeApplicationFormInitialState: ProgrammeState = {
    programmeApplicationForms: undefined,
    programmes: undefined,
}

export { ProgrammeApplicationFormInitialState }
export type { ProgrammeState }