import type { ServiceResponse } from '../../types'
import type { UPMEContentState } from './UPMEContentState'
import { UpmeIntroductionCarouselItem } from '../../types/UpmeIntroductionCarouselItem'
import { SimpleFAQEntry } from '../../types/SimpleFAQEntry'
import { UpmeBenefit } from '../../types/UpmeBenefit'
import { UpmeBenefitCategory } from '../../types/UpmeBenefitCategory'
import { DigitalResourceCategory } from '../../types/DigitalResourceCategory'
import { DigitalResourceDetail } from '../../types/DigitalResourceDetail'
import { UpmeLimitedDigitalResourceDetailByCategory } from '../../types/LimitedDigitalResourceDetailByCategory'

enum UPMEActions {
    UPME_INTRODUCTION_CAROUSEL_ITEMS_RETRIEVED,
    UPME_INTRODUCTION_FAQ_ENTRIES_RETRIEVED,
    UPME_BENEFITS_RETRIEVED,
    UPME_BENEFIT_RETRIEVED,
    UPME_BENEFIT_CATEGORIES_RETRIEVED,
    UPME_BENEFIT_TOPICS_RETRIEVED,
    UPME_DIGITAL_RESOURCE_CATEGORIES_RETRIEVED,
    UPME_DIGITAL_RESOURCE_BY_CATEGORY_RETRIEVED,
    UPME_DIGITAL_RESOURCE_DETAILS_RETRIEVED,
    UPME_LIMITED_DIGITAL_RESOURCES_BY_CATEGORY_RETRIEVED,
}

type UPMEContentAction =
    | { type: UPMEActions.UPME_INTRODUCTION_CAROUSEL_ITEMS_RETRIEVED, introductionCarouselItems: ServiceResponse<UpmeIntroductionCarouselItem[]> }
    | { type: UPMEActions.UPME_INTRODUCTION_FAQ_ENTRIES_RETRIEVED, introductionFAQEntries: ServiceResponse<SimpleFAQEntry[]> }
    | { type: UPMEActions.UPME_BENEFITS_RETRIEVED, benefits: ServiceResponse<UpmeBenefit[]> }
    | { type: UPMEActions.UPME_BENEFIT_RETRIEVED, benefit: ServiceResponse<UpmeBenefit> }
    | { type: UPMEActions.UPME_BENEFIT_CATEGORIES_RETRIEVED, benefitCategories: ServiceResponse<UpmeBenefitCategory[]> }
    | { type: UPMEActions.UPME_DIGITAL_RESOURCE_CATEGORIES_RETRIEVED, category: ServiceResponse<DigitalResourceCategory[]> }
    | { type: UPMEActions.UPME_DIGITAL_RESOURCE_BY_CATEGORY_RETRIEVED, digitalResourceListByCategory: ServiceResponse<DigitalResourceDetail[]> }
    | { type: UPMEActions.UPME_DIGITAL_RESOURCE_DETAILS_RETRIEVED, digitalResourceDetails: ServiceResponse<DigitalResourceDetail> }
    | { type: UPMEActions.UPME_LIMITED_DIGITAL_RESOURCES_BY_CATEGORY_RETRIEVED, digitalResourceLimitedList: ServiceResponse<UpmeLimitedDigitalResourceDetailByCategory[]> }

const UPMEContentReducer = (state: UPMEContentState, action: UPMEContentAction): UPMEContentState => {
    switch (action.type) {
        case UPMEActions.UPME_INTRODUCTION_CAROUSEL_ITEMS_RETRIEVED: {
            return {
                ...state,
                introductionCarouselItems: action.introductionCarouselItems,
            }
        }
        case UPMEActions.UPME_INTRODUCTION_FAQ_ENTRIES_RETRIEVED: {
            return {
                ...state,
                introductionFAQEntries: action.introductionFAQEntries,
            }
        }
        case UPMEActions.UPME_BENEFITS_RETRIEVED: {
            return {
                ...state,
                benefits: action.benefits,
            }
        }
        case UPMEActions.UPME_BENEFIT_RETRIEVED: {
            let data: UpmeBenefit[] | undefined = state.benefits?.data

            if (action.benefit.data) {
                if (data?.length) {
                    data = data.filter(benefit => benefit.id !== action.benefit?.data?.id)
                }

                data = [
                    ...(data ?? []),
                    action.benefit.data,
                ]
            }

            return {
                ...state,
                benefits: {
                    data,
                    error: action.benefit.error,
                },
            }
        }
        case UPMEActions.UPME_BENEFIT_CATEGORIES_RETRIEVED: {
            return {
                ...state,
                benefitCategories: action.benefitCategories,
            }
        }
        case UPMEActions.UPME_DIGITAL_RESOURCE_CATEGORIES_RETRIEVED: {
            return {
                ...state,
                digitalResourceCategories: action.category,
            }
        }
        case UPMEActions.UPME_DIGITAL_RESOURCE_BY_CATEGORY_RETRIEVED: {
            return {
                ...state,
                digitalResourceListByCategory: action.digitalResourceListByCategory,
            }
        }
        case UPMEActions.UPME_DIGITAL_RESOURCE_DETAILS_RETRIEVED: {
            return {
                ...state,
                digitalResourceDetails: action.digitalResourceDetails,
            }
        }
        case UPMEActions.UPME_LIMITED_DIGITAL_RESOURCES_BY_CATEGORY_RETRIEVED: {
            return {
                ...state,
                limitedDigitalResourceByCategory: action.digitalResourceLimitedList,
            }
        }
        default: {
            throw new Error(`Unhandled action type`)
        }
    }
}

export default UPMEContentReducer
export type { UPMEContentAction }
export {
    UPMEActions,
}