import React, { useContext } from 'react'
import { Box, Button, Card, Container, Grid, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import moment from 'moment/moment'
import clsx from 'clsx'

import { getAgeFromDateOfBirth, isPassedDate } from '../../../helpers/utils/membership'
import { MembershipCardContentSkeleton } from './MembershipCardContent.skeleton'
import { MembershipStatus } from '../../../types/MembershipStatus'
import { AppContext } from '../../../stores/App/AppStore'
import { Membership } from '../../../types'
import { maxMemberAge } from '../../../helpers/validation/age'
import normalCardFace from '../../../assets/card-face.png'
import specimenCardFace from '../../../assets/specimen-card-face.png'
import { UserContext } from '../../../stores/User/UserStore'

import styles from './MembershipCardContent.module.css'

interface MembershipCardContentProps {
    membership?: Membership
}

const MembershipCardContent: React.FC<MembershipCardContentProps> = (props: MembershipCardContentProps) => {
    const { membership } = props

    const { state } = useContext(AppContext)
    const { state: userState } = useContext(UserContext)
    const { t } = useTranslation()

    const hasMembership = !!membership?.id
    const isProduction = process.env.REACT_APP_STARTER_APP_ENV === `production`

    if (!hasMembership || !membership.dateOfBirth) {
        return <MembershipCardContentSkeleton />
    }

    const membershipAgeCheck = (membership: Membership): boolean => {
        if (membership.user?.liftAgeLimit) {
            return false
        }

        return maxMemberAge ? moment().diff(membership.dateOfBirth, `years`) >= maxMemberAge : false
    }

    const handleOtherNTUCMembershipClick = () => {
        window.open(state.pages.membershipCard.otherMembershipUrl, `_self`)
    }

    const strapiMembershipCardFace = userState.membershipSettings?.data?.bannerImageURL

    const defaultCardFace = isProduction ? normalCardFace : specimenCardFace
    const cardFace = strapiMembershipCardFace ?? defaultCardFace

    const idDOBString = membership.id + `-` + moment(membership.dateOfBirth, `YYYY-MM-DD`).format(`DDMMYYYY`)
    const signUpDateString = moment(membership.signUpDate, `YYYY-MM-DD`).format(`DD MMMM YYYY`)

    return (
        <Card className={styles.cardBody}>
            {membership.status === MembershipStatus.ACTIVE ?
                <ActiveMembershipHeader /> :
                <SuspendedMembershipHeader />
            }
            <Container className={styles.unionCardContainer}>
                <Container component={`img`} src={cardFace} className={styles.unionCard} />
                <Typography variant={`body1`} className={styles.unionCardIdDOB}>{idDOBString}</Typography>
                <Typography variant={`body1`} className={styles.unionCardName}>{membership.eCardName}</Typography>
            </Container>

            <Grid container direction="row" justifyContent="space-between" className={styles.unionDetails}>
                <Stack>
                    <Typography variant={`body2`} className={styles.unionDetailsHeader}>
                        {t(`membership_expiry`)}
                    </Typography>

                    {isPassedDate(membership.expiryDate?.toString()) ? (
                        <Typography
                            variant={`subtitle1`}
                            className={clsx(styles.unionDetailsValue, styles.warning)}>{t(`expired`)}</Typography>
                    ) : (
                        <Typography
                            variant={`body1`}
                            className={styles.unionDetailsValue}>{membership.expiryDate?.toString()}</Typography>
                    )}

                    <Typography variant={`body2`} className={styles.membershipPlansHeader}>
                        {t(`membership_plans`)}
                    </Typography>
                    {membership.planUsedOnLastActivation ? (
                        <Typography variant={`body1`}
                            className={styles.unionDetailsValue}>{membership.planUsedOnLastActivation?.name}</Typography>
                    ) : (
                        <Typography variant={`body1`}
                            className={styles.unionDetailsValue}>{t(`regular`)}</Typography>
                    )}

                    <Typography variant={`body2`} className={styles.membershipPlansHeader}>
                        {t(`sign_up_date`)}
                    </Typography>
                    <Typography variant={`body1`}
                        className={styles.unionDetailsValue}>{signUpDateString}</Typography>
                </Stack>
                <Stack>
                    <Typography variant={`body2`} className={styles.unionDetailsHeader}>{t(`age`)}</Typography>
                    <Typography
                        variant={`body1`}
                        className={styles.unionDetailsValue}>{getAgeFromDateOfBirth(membership.dateOfBirth.toString())}</Typography>
                </Stack>
            </Grid>

            {membershipAgeCheck(membership) &&
                <Box className={styles.otherMembershipButton}>
                    <Button variant='contained' onClick={handleOtherNTUCMembershipClick}>{t(`other_ntuc_memberships`)}</Button>
                </Box>
            }
        </Card>
    )
}

const ActiveMembershipHeader = () => {
    const { t } = useTranslation()

    return (
        <>
            <Typography variant={`h6`} className={styles.unionTitle}>{t(`membership_card_title`)}</Typography>
            <Typography variant={`body1`} component={`span`} className={styles.unionSubtitle}>
                {t(`membership_card_subtitle_1`)}
                <div className={styles.unionSubtitleHighlight}> {t(`membership_card_subtitle_2`)} </div>
                {t(`membership_card_subtitle_3`)}
            </Typography>
        </>
    )
}

const SuspendedMembershipHeader = () => {
    const { t } = useTranslation()

    return (
        <>
            <Typography variant={`h6`} className={styles.unionTitle}>{t(`membership_suspended_title`)}</Typography>
            <Typography variant={`body1`} component={`span`} className={clsx(styles.unionSubtitle, styles.warning)}>
                {t(`membership_suspended_description`)}
            </Typography>
        </>
    )
}

export default MembershipCardContent
export { MembershipCardContentSkeleton } from './MembershipCardContent.skeleton'