import { Button, ButtonProps } from "@mui/material"
import { useTranslation } from "react-i18next"

const SignUpButton = (props: ButtonProps) => {
    const { t } = useTranslation()

    return (
        <Button variant='contained' {...props}>
            {t(`landing_page_sign_up`)}
        </Button>
    )
}

export default SignUpButton