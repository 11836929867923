import { useContext } from 'react'

import { AppContext } from '../../stores/App/AppStore'
import { ActionTypes } from '../../stores/App/AppReducer'
import ApplicationTermsOfUsePageRepository from './application-terms-of-use-page-repository'

const useApplicationTermsOfUsePageRepository = () => {
    const { dispatch } = useContext(AppContext)
    const applicationTermsOfUsePageRepository = new ApplicationTermsOfUsePageRepository()

    const retrievePwaTermsAndConditionsPage = () => {
        applicationTermsOfUsePageRepository.getPage()
            .then((applicationTermsOfUsePage) => {
                dispatch({
                    type: ActionTypes.PAGE_APPLICATION_TERMS_OF_USE_RETRIEVED,
                    applicationTermsOfUsePageData: applicationTermsOfUsePage,
                })
            })
            .catch((error) => {
                console.error(error.message)
            })
    }

    return {
        retrievePwaTermsAndConditionsPage,
    }
}

export { useApplicationTermsOfUsePageRepository }