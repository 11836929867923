import { cloneElement, useContext, useState } from "react"
import { Box } from "@mui/material"
import AlertAccessDialog from "../AlertAccessDialog/AlertAccessDialog"
import { UserContext } from '../../../stores/User/UserStore'
interface LandingPageAccessGuardProps {
    children: React.ReactElement
    onClickHandled?: () => void,
    membershipPlanRoute?: string
}

const LandingPageAccessGuard = (props: LandingPageAccessGuardProps) => {
    const { children, onClickHandled, membershipPlanRoute } = props

    const [isAlertAccess, setIsAlertAccess] = useState(false)
    const { state: userState } = useContext(UserContext)

    const userLoggedIn = userState.membership?.data

    const handleCardClick = () => {
        if (!userLoggedIn) {
            setIsAlertAccess(true)
        }

        onClickHandled?.()
    }
    const handleClose = () => {
        setIsAlertAccess(false)
    }

    if (userLoggedIn) {
        return children
    }

    const childrenWithProps = cloneElement(children, { onClick: handleCardClick })

    return (
        <Box>
            {childrenWithProps}
            {isAlertAccess && <AlertAccessDialog shouldShow={isAlertAccess} onClose={handleClose} membershipPlanRoute={membershipPlanRoute} />}
        </Box>
    )
}
export default LandingPageAccessGuard