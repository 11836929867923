import { postUtapClaim, retrieveUtapClaims, retrieveLastClaimData, retrieveUtapLandingPage, retrieveUtapStatus } from '../../clients/utap'
import { fromStrapiCreateUtapClaim } from '../../helpers/mapping/create-utap-claim'
import { UtapClaimFormSubmissionResponse } from '../../types/UtapClaimFormSubmissionResponse'
import { UtapClaimItem } from '../../types/UtapClaim'
import { UtapLandingPage } from '../../types/UtapLandingPage'
import { fromStrapiUTAPLandingPage } from '../../helpers/mapping/utap-landing-page'
import { UtapStatus } from '../../types/UtapStatus'
import { MyInfoUserData } from '../../types/MyInfoUserData'
import LastClaimData from '../../types/LastClaimData'

class UtapRepository {

    async createUtapClaim(claimForm: FormData): Promise<UtapClaimFormSubmissionResponse> {
        const data = await postUtapClaim(claimForm)
        return fromStrapiCreateUtapClaim(data)
    }

    async retrieveUtapClaims(myInfoUserData: MyInfoUserData, pageSize: number): Promise<UtapClaimItem[]> {
        return await retrieveUtapClaims(myInfoUserData, pageSize)
    }

    async retrieveLastClaimData(): Promise<LastClaimData> {
        return await retrieveLastClaimData()
    }

    async retrieveUtapStatus(myInfoUserData: MyInfoUserData): Promise<UtapStatus> {
        return await retrieveUtapStatus(myInfoUserData)
    }

    async retrieveUtapLandingPage(): Promise<UtapLandingPage> {
        const { data } = await retrieveUtapLandingPage()
        return fromStrapiUTAPLandingPage(data)
    }
}

export default UtapRepository