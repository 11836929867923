import { useContext, useEffect } from "react"
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { Box, Button, Container, Typography } from "@mui/material"
import { Link, useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

import SplashScreenGuard from "../../../../router/guards/SplashScreenGuard"
import { useStarterOnboardingCarouselRepository } from "../../../../repositories/StarterOnboardingCarousel/use-starter-onboarding-carousel-repository"
import { HighlightCarouselItemImageAspectRatio } from "../../../molecules/HighlightCarouselItemComponent/HighlightCarouselItemComponent"
import { MembershipContext } from "../../../../stores/Membership/MembershipStore"
import HighlightCarousel from "../../../molecules/HighlightCarousel/HighlightCarousel"
import { Route } from "../../../../router"

import styles from './StarterOnboarding.module.scss'

const StarterOnboarding = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { state: membershipState } = useContext(MembershipContext)
    const { retrieveStarterOnboardingCarousel } = useStarterOnboardingCarouselRepository()

    useEffect(() => {
        retrieveStarterOnboardingCarousel()
    }, [])

    const handleButtonClick = () => {
        navigate(Route.MEMBERSHIP_USER_INTERESTS)
    }

    return (
        <SplashScreenGuard>
            <Container className={styles.container}>
                <Box className={styles.skipContainer}>
                    <Link to={Route.HOME}>
                        <Typography>{t(`skip`)}</Typography>
                        <ChevronRightIcon />
                    </Link>
                </Box>
                <Box className={styles.content}>
                    <Box>
                        <HighlightCarousel highlightCarouselItems={membershipState.starterOnboardingCarousel?.data} aspectRatio={HighlightCarouselItemImageAspectRatio.SQUARE} showBackground={false} />
                    </Box>
                    <Box>
                        <Button className={styles.button} variant='contained' onClick={handleButtonClick} fullWidth>{t(`starter_onboarding_welcome_button`)}</Button>
                    </Box>
                </Box>
            </Container>
        </SplashScreenGuard>
    )
}

export default StarterOnboarding