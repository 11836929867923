import { useContext, useEffect } from "react"
import { useUpmeOnboardingRepository } from "../../../../repositories/UPMEOnboarding/use-upme-onboarding-repository"
import HighlightCarousel from "../../../molecules/HighlightCarousel/HighlightCarousel"
import { UPMEContentContext } from "../../../../stores/UPMEContent/UPMEContentStore"
import { useTranslation } from "react-i18next"
import { Box, Button, Container, Grid, Typography } from "@mui/material"

import CommonBackButton from "../../../atoms/CommonBackButton/CommonBackButton"
import { Route } from "../../../../router"
import { UpmeIntroductionCarouselItem } from "../../../../types/UpmeIntroductionCarouselItem"
import { HighlightCarouselItem } from "../../../../types/HighlightCarouselItem"
import SimpleFAQSection from "../../../molecules/SimpleFAQSection/SimpleFAQSection"
import { useNavigate } from "react-router"
import UpmeUpdateNowFooter from "../../../molecules/UpmeUpdateNowFooter/UpmeUpdateNowFooter"
import SplashScreenGuard from "../../../../router/guards/SplashScreenGuard"
import { logAction } from "../../../../clients/logger"
import { profilePageMessageTemplate } from "../../../../helpers/logging/templates"
import { UserContext } from "../../../../stores/User/UserStore"
import useTrackPageView from "../../../../helpers/hooks/use-track-page-view"

import styles from './UpmeConversionIntroduction.module.scss'

const AUTO_PLAY = true

const UpmeConversionIntroduction = () => {
    useTrackPageView(`Membership Card page`)

    const { t } = useTranslation()
    const navigate = useNavigate()

    const { retrieveUpmeIntroductionCarouselItems, retrieveUpmeIntroductionFAQEntries } = useUpmeOnboardingRepository()
    const { state: upmeContentState } = useContext(UPMEContentContext)
    const { state: userState } = useContext(UserContext)

    useEffect(() => {
        logAction(`debug`, profilePageMessageTemplate(userState.membership?.data))
        retrieveUpmeIntroductionCarouselItems()
        retrieveUpmeIntroductionFAQEntries()
    }, [])

    const handleOnCarouselItemClick = (item: HighlightCarouselItem) => {
        const upmeBenefitItem = item as UpmeIntroductionCarouselItem

        if (upmeBenefitItem.upmeBenefit) {
            navigate(`${Route.UPME_CONVERSION_BENEFITS}/${upmeBenefitItem.upmeBenefit.id}`)
        }
    }

    const handleViewAllBenefitsButtonClick = () => {
        navigate(Route.UPME_CONVERSION_BENEFITS)
    }

    return (
        <SplashScreenGuard>
            <Container className={styles.container}>
                <CommonBackButton path={Route.HOME} />

                <Grid container direction="column" alignItems="center">
                    <Grid item marginTop={2}>
                        <Typography variant="h1">{t(`union_introduction_page_title`)}</Typography>
                    </Grid>

                    <Grid item marginTop={2}>
                        <Box className={styles.carouselGridItem}>
                            <HighlightCarousel highlightCarouselItems={upmeContentState.introductionCarouselItems?.data} autoplay={AUTO_PLAY} onCarouselItemClick={handleOnCarouselItemClick} showBackground={false} />
                        </Box>
                    </Grid>

                    <Grid item marginTop={2} marginBottom={2}>
                        <Button className={styles.viewAllButton} variant="text" color="secondary" onClick={handleViewAllBenefitsButtonClick}>{t(`view_all_benefits`)}</Button>
                    </Grid>

                    <Grid item marginTop={2}>
                        <SimpleFAQSection faqEntries={upmeContentState.introductionFAQEntries?.data} />
                    </Grid>
                </Grid>

                <UpmeUpdateNowFooter />
            </Container>
        </SplashScreenGuard>
    )
}

export default UpmeConversionIntroduction