import { useContext, useEffect, useState } from 'react'
import { Container, Divider, Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import moment from 'moment'

import { Route } from '../../../../router'
import { AppContext } from '../../../../stores/App/AppStore'
import { PaymentStatus } from '../../../../types/PaymentStatus'
import { PaymentHistoryResult } from '../../../../types'
import { PaymentOrderReason } from '../../../../types/PaymentOrderReason'
import { DatePatterns } from '../../../../types/date-patterns'
import CommonBackButton from '../../../atoms/CommonBackButton/CommonBackButton'
import ConfirmationDialog from '../../../molecules/ConfirmationDialog/ConfirmationDialog'
import SplashScreenGuard from '../../../../router/guards/SplashScreenGuard'
import useTrackPageView from '../../../../helpers/hooks/use-track-page-view'

import styles from './MyActivityDetails.module.css'

const DECIMALS = 2

const MyActivityDetails = () => {
    useTrackPageView(`My Activity Details Page`)

    const { id } = useParams()

    const { t } = useTranslation()
    const navigate = useNavigate()
    const { state } = useContext(AppContext)

    const [paymentOrderTransaction, setPaymentOrderTransaction] = useState<PaymentHistoryResult>()

    useEffect(() => {
        if (id && state.paymentOrderHistory.data) {
            const foundPaymentOrderTransaction = state.paymentOrderHistory.data.find((order) => order.clientOrderId === id)
            setPaymentOrderTransaction(foundPaymentOrderTransaction)
        }
    }, [state.paymentOrderHistory])

    const toTitleCase = (text: string) => {
        return text
            .split(` `)
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(` `)
    }

    const paymentAmountFormatter = (paymentAmount: number): string => {
        return `$${paymentAmount.toFixed(DECIMALS)}`
    }

    const dateStringFormatter = (dateString: Date): string => {
        return moment(dateString).format(DatePatterns.ABBREVIATED_DATE)
    }

    const handleClose = () => {
        navigate(Route.PROFILE_MY_ACTIVITY)
    }

    const warningDialog = (warningMessage: string) => (
        <SplashScreenGuard nextRoute={Route.PROFILE_MY_ACTIVITY}>
            <Container>
                <ConfirmationDialog
                    acknowledgeOnly
                    buttonText={t(`profile_my_activity_transaction_go_back`)}
                    confirmationTitle={t(`sign_up_failure`)}
                    confirmationText={warningMessage}
                    onConfirm={handleClose}
                    defaultOpen
                />
            </Container>
        </SplashScreenGuard >
    )

    if (!state.paymentOrderHistory.data) {
        return warningDialog(t(`profile_my_activity_transaction_retrieval_error`))
    }

    if (state.paymentOrderHistory.data.length === 0) {
        return warningDialog(t(`profile_my_activity_transaction_retrieval_error`))
    }

    if (!paymentOrderTransaction) {
        return <></>
    }

    const paymentOrderReason = toTitleCase(paymentOrderTransaction.reason)

    const transactionReason = t(`profile_my_activity_transaction_reason_short`, { reason: paymentOrderReason })

    const transactionMembership = paymentOrderTransaction.reason === PaymentOrderReason.RENEWAL
        ? t(`payment_summary_details_membership_renewal`)
        : t(`payment_summary_details_membership`)

    const transactionDate = dateStringFormatter(paymentOrderTransaction.transactionDate)

    const startDate = dateStringFormatter(paymentOrderTransaction.startDate)
    const endDate = dateStringFormatter(paymentOrderTransaction.endDate)

    const transactionResultColor = paymentOrderTransaction.status === PaymentStatus.CANCELLED
        || paymentOrderTransaction.status === PaymentStatus.FAILED
        ? `error`
        : `initial`

    const transactionResult = paymentOrderTransaction.status === PaymentStatus.SUCCESS
        ? paymentAmountFormatter(paymentOrderTransaction.amount)
        : toTitleCase(paymentOrderTransaction.status)

    return (
        <SplashScreenGuard nextRoute={Route.PROFILE_MY_ACTIVITY}>
            <Grid className={styles.pageContainer}>
                <Container className={styles.myActivityDetailsHeading}>
                    <CommonBackButton disableLabel path={Route.PROFILE_MY_ACTIVITY} />
                    <Typography variant={`h6`}>{transactionReason}</Typography>
                </Container>

                <Container className={styles.detailsContainer}>
                    <Grid className={styles.gridContainer}>
                        <Typography variant={`subtitle1`}>{t(`date`)}</Typography>
                        <Typography variant={`body1`}>{transactionDate}</Typography>
                    </Grid>
                    <Grid className={styles.gridContainer}>
                        <Typography variant={`subtitle1`}>{t(`reference`)}</Typography>
                        <Typography variant={`body1`}>{id}</Typography>
                    </Grid>
                </Container>

                <Container className={styles.paymentOrderDetails}>
                    <Typography variant={`h6`} mb={2}>{t(`summary`)}</Typography>
                    <Grid container className={styles.summaryGrid}>
                        <Grid className={styles.summaryColumnLeft}>
                            <Typography variant={`subtitle1`} mb={1}>{transactionMembership}</Typography>
                            <Typography variant={`body2`} mb={1}>{t(`payment_summary_subscription_duration`, { start: startDate, end: endDate })}</Typography>
                        </Grid>
                        <Typography variant={`subtitle1`} color={transactionResultColor}>{transactionResult}</Typography>
                    </Grid>

                    <Divider className={styles.paymentOrderDetailsDivider} />

                    <Grid container className={styles.summaryGrid}>
                        <Typography variant={`subtitle1`}>{t(`payment_summary_details_membership_total`)}</Typography>
                        <Typography variant={`subtitle1`} color={transactionResultColor}>{transactionResult}</Typography>
                    </Grid>
                </Container>
            </Grid>
        </SplashScreenGuard>
    )
}

export default MyActivityDetails