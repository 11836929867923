import { useParams } from "react-router-dom"
import { Button, Container } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useContext, useEffect } from "react"

import { UPMEContentContext } from "../../../../stores/UPMEContent/UPMEContentStore"
import CommonBackButton from "../../../atoms/CommonBackButton/CommonBackButton"
import MarkdownContent from "../../../atoms/MarkdownContent/MarkdownContent"
import DigitalResourceDetailsSkeleton from "./DigitalResourceDetails.skeleton"
import SplashScreenGuard from "../../../../router/guards/SplashScreenGuard"
import DigitalResourceCard from "../../../molecules/DigitalResourceCard/DigitalResourceCard"
import { useDigitalResourceRepository } from "../../../../repositories/DigitalResource/use-digital-resource.repository"
import { UserContext } from "../../../../stores/User/UserStore"
import { MembershipType } from "../../../../types/MembershipType"
import Spacer from "../../../atoms/Spacer/Spacer"
import { isAccessible } from "../../../../helpers/utils/membershipScope"
import useTrackPageView from "../../../../helpers/hooks/use-track-page-view"
import useGoogleTagManager from '../../../../helpers/analytics/use-google-tag-manager'

import styles from "./DigitalResourceDetails.module.scss"

const DigitalResourceDetails = () => {
    const { category, id: resourceId } = useParams()

    const { t } = useTranslation()
    const { trackRedirectOnButtonClick } = useGoogleTagManager()

    const { retrieveDigitalResourceDetails, retrieveDigitalResourceCategories } = useDigitalResourceRepository()

    const { state: userState } = useContext(UserContext)
    const { state: upmeContentState } = useContext(UPMEContentContext)

    const userMembershipType = userState.membership?.data?.type
    const membershipType = userMembershipType === MembershipType.UPME ? MembershipType.UPME : MembershipType.STARTER
    const resourceDetails = upmeContentState.digitalResourceDetails?.data
    const placeHolderImage = upmeContentState.digitalResourceCategories?.data?.find((categoryState) => categoryState.name.toLowerCase() === category?.toLowerCase())?.placeHolderImage ?? ``

    const isPageAccessible = resourceDetails && isAccessible(resourceDetails?.membershipScope, membershipType)

    useTrackPageView(() => {
        if (resourceDetails) {
            return {
                title: `Digital Resource`,
                item: resourceDetails.title,
            }
        }
    }, [resourceDetails])

    useEffect(() => {
        retrieveDigitalResourceCategories()

        if (resourceId) {
            try {
                retrieveDigitalResourceDetails(parseInt(resourceId))
            } catch (error) {
                console.error(error)
            }
        }
    }, [resourceId])

    const handleExternalLinkClick = () => {
        if (resourceDetails?.externalLink) {
            trackRedirectOnButtonClick(resourceDetails.externalLink)
            window.open(resourceDetails.externalLink, `_blank`)
        }
    }

    const showExternalLink = resourceDetails?.externalLink && (
        <Button variant='contained' onClick={handleExternalLinkClick}>{t(`upme_digital_resource_external_link`)}</Button>
    )

    if (!resourceDetails || !isPageAccessible) {
        return (
            <SplashScreenGuard>
                <DigitalResourceDetailsSkeleton />
            </SplashScreenGuard>
        )
    }

    return (
        <SplashScreenGuard>
            <Container className={styles.container}>
                <CommonBackButton />

                <DigitalResourceCard className={styles.resourceCard} digitalResource={resourceDetails} placeHolderImage={placeHolderImage} />

                <Spacer height={24} />
                <MarkdownContent content={resourceDetails?.description} />

                {showExternalLink}
            </Container>
        </SplashScreenGuard>
    )
}

export default DigitalResourceDetails
