import { Box, Card, CardActionArea, CardActionAreaProps, CardContent, CardMedia, Typography, useTheme } from "@mui/material"
import { useEffect, useRef, useState } from "react"
import clsx from "clsx"

import styles from './AppCard.module.scss'

export const CARD_AREA_STYLE_NORMAL = {
    height: {
        xs: `22rem`,
        sm: `40dvh`,
    },
}

export const CARD_AREA_STYLE_BIGGER = {
    height: {
        xs: `28rem`,
        sm: `50dvh`,
    },
}

interface AppCardProps extends CardActionAreaProps {
    title?: string
    summary?: string
    image?: string
    imageFit?: `contain` | `cover`
    fullWidth?: boolean
    bigger?: boolean
    contentBelow?: boolean
    ribbonText?: string
}

const AppCard = (props: AppCardProps) => {
    const { title, summary, image, imageFit = `cover`, fullWidth, bigger, contentBelow, ribbonText, className, children, sx, ...inheritedProps } = props

    const { palette } = useTheme()
    const [lineClamp, setLineClamp] = useState(1)

    const summaryContainerRef = useRef<HTMLElement>()

    useEffect(() => {
        window.addEventListener(`resize`, updateSummaryLinesClamp)
        updateSummaryLinesClamp()

        return () => {
            window.removeEventListener(`resize`, updateSummaryLinesClamp)
        }
    }, [])

    const updateSummaryLinesClamp = () => {
        if (summaryContainerRef.current) {
            const container = summaryContainerRef.current

            if (container.children.length) {
                const summary = container.children[0]
                const containerHeight = container.getBoundingClientRect().height
                const summaryHeight = summary.getBoundingClientRect().height

                const lineHeight = Math.floor(summaryHeight / lineClamp)
                const maxLines = Math.floor(containerHeight / lineHeight)

                setLineClamp(maxLines)
            }
        }
    }

    const cardActionAreaClassName = clsx(
        styles.cardActionArea,
        className,
    )

    const defaultStyle = bigger ? CARD_AREA_STYLE_BIGGER : CARD_AREA_STYLE_NORMAL

    const cardActionAreaStyle = {
        ...sx,
        ...defaultStyle,
        width: {
            xs: fullWidth ? `100%` : `75dvw`,
            sm: fullWidth ? `75%` : `300px`,
        },
    }

    const summaryStyle = {
        '-webkit-line-clamp': `${lineClamp}`,
    }

    const imageStyle = {
        objectFit: imageFit,
    }

    const ribbonStyle = {
        backgroundColor: palette.primary.dark,
        color: palette.primary.contrastText,
    }

    return (
        <CardActionArea className={cardActionAreaClassName} sx={cardActionAreaStyle} {...inheritedProps} >
            <Card className={styles.card} elevation={2} >
                <CardMedia className={styles.image} component="img" image={image} sx={imageStyle} />
                <CardContent className={styles.content}>
                    {!contentBelow && children}
                    {title && summary &&
                        <Box className={styles.titleAndSummary}>
                            <Typography className={styles.title} variant="h3">{title}</Typography>
                            <Box className={styles.summary} ref={summaryContainerRef}>
                                <Typography sx={summaryStyle} variant="body2">{summary}</Typography>
                            </Box>
                        </Box>
                    }
                    {contentBelow && children}
                    {
                        ribbonText &&
                        <Box className={styles.ribbon} sx={ribbonStyle}>
                            <Typography>{ribbonText}</Typography>
                        </Box>
                    }
                </CardContent>
            </Card>
        </CardActionArea>
    )
}

export default AppCard