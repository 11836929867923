import { fetchAboutStarterCarousel, fetchAboutStarterFaq } from '../../clients/pages'
import fromStrapiHighlightCarouselItem from '../../helpers/mapping/highlight-carousel-item'
import fromStrapiSimpleFAQEntry from '../../helpers/mapping/simple-faq-entry'
import { HighlightCarouselItem } from '../../types'
import { SimpleFAQEntry } from '../../types/SimpleFAQEntry'

class AboutPageRepository {

    async getAboutCarousel(): Promise<HighlightCarouselItem[]> {
        const aboutCarouselResponse = await fetchAboutStarterCarousel()
        return aboutCarouselResponse.map(fromStrapiHighlightCarouselItem)
    }

    async getAboutFaq(): Promise<SimpleFAQEntry[]> {
        const aboutFaqResponse = await fetchAboutStarterFaq()
        return aboutFaqResponse.map(fromStrapiSimpleFAQEntry)
    }
}

export default AboutPageRepository