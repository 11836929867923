import { fromStrapiInstitution } from '../../helpers/mapping/institution'
import { fetchInstitutions } from '../../clients/institutions'
import { Institution } from '../../types'

class InstitutionRepository {

    async getAll(): Promise<Institution[]> {
        const { data } = await fetchInstitutions()
        return data.map((i: unknown) => fromStrapiInstitution(i))
    }
}

export default InstitutionRepository