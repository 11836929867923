import { MembershipScope } from "../../types/MembershipScope"
import { MembershipType } from "../../types/MembershipType"

export const isAccessible = (membershipScope: MembershipScope, memberType: MembershipType) => {
    if (membershipScope === MembershipScope.ALL) {
        return true
    } else {
        const isMembershipScopeMatch = membershipScope.valueOf() === memberType.valueOf()
        return isMembershipScopeMatch
    }
}
