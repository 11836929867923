import { Box, Skeleton } from '@mui/material'

import styles from './EventCard.module.scss'

const EventCardSkeleton = () => (
    <Box className={styles.skeletonEventCard}>
        <Box className={styles.skeletonEventCardImage}>
            <Skeleton variant="rectangular" />
        </Box>
        <Box className={styles.skeletonEventCardContent}>
            <Skeleton variant="text" className={styles.skeletonEventCardTitle} />
            <Skeleton variant="text" className={styles.skeletonEventCardTitle} />
            <Skeleton variant="text" className={styles.skeletonEventCardDescription} />
            <Skeleton variant="text" className={styles.skeletonEventCardDescription} />
            <Skeleton variant="text" className={styles.skeletonEventCardDescription} />
            <Skeleton variant="text" className={styles.skeletonEventCardDescription} />
        </Box>
    </Box>
)

export { EventCardSkeleton }