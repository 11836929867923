import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material"
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

import { SimpleFAQEntry } from "../../../types/SimpleFAQEntry"

import styles from './SimpleFAQItem.module.scss'

interface SimpleFAQItemProps {
    faqEntry: SimpleFAQEntry,
    currentExpanded?: string,
    onChange: (entryKey: string) => void
}

const SimpleFAQItem = (props: SimpleFAQItemProps) => {
    const { faqEntry, currentExpanded, onChange } = props

    const entryKey = simpleFaqItemKey(faqEntry)

    const handleChange = () => {
        onChange(entryKey)
    }

    return (
        <Accordion className={styles.entryContainer} elevation={0} disableGutters expanded={currentExpanded === entryKey} onChange={handleChange}>
            <AccordionSummary aria-controls="panel2d-content" id="panel2d-header" expandIcon={<ArrowDropDownIcon />} sx={{ border: `none` }}>
                <Typography variant="h6">{faqEntry.question}</Typography>
            </AccordionSummary>
            <AccordionDetails className={styles.entryDetails}>
                <Typography variant="body1">{faqEntry.answer}</Typography>
            </AccordionDetails>
        </Accordion >
    )
}

export const simpleFaqItemKey = (faqEntry: SimpleFAQEntry) => {
    return `entry-${faqEntry.id}`
}

export default SimpleFAQItem