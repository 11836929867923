import slugify from 'slugify'
import { MembershipScope } from './MembershipScope'
import { ServiceResponse } from './RemoteData'

export type ProgrammesServiceResponse = ServiceResponse<Programme[]>

export interface Programme {
    id: number,
    name: string,
    bannerImage: string,
    description: string,
    externalLinkText: string,
    externalLink: string,
    daysBeforeActivation: number,
    popupText: string,
    optInConfirmationText: string,
    summary: string,
    membershipScope: MembershipScope,
    route?: string,
    url?: string,
    order: number,
}

export const getProgrammeSlug = (programme: Programme): string => slugify(programme.name, { lower: true })

export const getProgrammeRoute = (programme: Programme): string => `/programme/${getProgrammeSlug(programme)}`
