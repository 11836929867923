import { useContext, useEffect, useState } from "react"

import CustomSelect, { CustomSelectOption, CustomSelectProps } from "../../atoms/CustomSelect/CustomSelect"
import { AppContext } from "../../../stores/App/AppStore"
import { FieldOfStudy } from "../../../types"
import { useFoSRepository } from "../../../repositories/FieldOfStudy/use-field-of-study-repository"

type FieldsOfStudySelectProps = Omit<CustomSelectProps, `options`>

const createOptions = (fieldsOfStudy: FieldOfStudy[]) => {
    const options: CustomSelectOption[] = []
    const localCopy = [...fieldsOfStudy].sort((a, b) => a.order - b.order)

    localCopy.forEach((i) => options.push({ label: i.name, value: i.id }))

    return options
}

const FieldsOfStudySelect = (props: FieldsOfStudySelectProps) => {
    const { state } = useContext(AppContext)
    const { retrieveFieldsOfStudy } = useFoSRepository()
    const [options, setOptions] = useState<CustomSelectOption[]>([])

    useEffect(() => {
        if (state.fieldsOfStudy.length < 1) {
            retrieveFieldsOfStudy()
        }
    }, [])

    useEffect(() => {
        setOptions(createOptions(state.fieldsOfStudy))
    }, [state.fieldsOfStudy])

    return (
        <CustomSelect
            {...props}
            options={options}
        />
    )
}

export default FieldsOfStudySelect