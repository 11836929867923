import { useContext } from 'react'

import { AppContext } from '../../stores/App/AppStore'
import { ActionTypes } from '../../stores/App/AppReducer'
import PrivacyPolicyRepository from './privacy-policy-repository'

const usePrivacyPolicyRepository = () => {
    const { dispatch } = useContext(AppContext)
    const privacyPolicyRepository = new PrivacyPolicyRepository()

    const retrievePrivacyPolicy = () => {
        privacyPolicyRepository.getPage()
            .then((privacyPolicyPage) => {
                dispatch({
                    type: ActionTypes.PRIVACY_POLICY_RETRIEVED,
                    privacyPolicy: privacyPolicyPage,
                })
            })
            .catch((error) => {
                console.error(error.message)
            })
    }

    return {
        retrievePrivacyPolicy,
    }
}

export { usePrivacyPolicyRepository }