const formRequiredStateTemplate = {
    email: false,
    phoneNumber: false,
    postalCode: false,
    blockHouseNumber: false,
    streetName: false,
    trainingProvider: false,
    trainingCourse: false,
    courseHoursAttended: false,
    startDate: false,
    endDate: false,
    netFee: false,
    proofOfPayment: false,
    proofOfCompletion: false,
    bank: false,
    accountHolder: false,
    accountNumber: false,
    bankBookStatement: false,
}

const ALLOWED_FILE_SIZE = 2048
const ALLOWED_FILE_TYPE = { png: `image/png`, jpeg: `image/jpeg`, jpg: `image/jpg`, pdf: `application/pdf` }
const BANK_STATEMENT_PREVIEW = `https://www.dbs.com.sg/personal/support/iwov-resources/dbs_ps3revamp/article-detail/images/consolidated-statements/bank-consolidated-statements-newlook.png`

export default {
    formRequiredStateTemplate,
    ALLOWED_FILE_SIZE,
    ALLOWED_FILE_TYPE,
    BANK_STATEMENT_PREVIEW,
}