import { Box, Container, Typography } from "@mui/material"
import { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useLocation } from "react-router-dom"
import { LoadingButton } from "@mui/lab"

import { useUserInterestTopicsRepository } from "../../../../repositories/UserInterestTopics/use-user-interest-topics"
import { UPMEUserInterestPageMessageTemplate } from "../../../../helpers/logging/templates"
import ConfirmationDialog from "../../../molecules/ConfirmationDialog/ConfirmationDialog"
import { MembershipContext } from "../../../../stores/Membership/MembershipStore"
import UserInterestsSelectionSkeleton from "./UserInterestsSelection.skeleton"
import SelectableCard from "../../../molecules/SelectableCard/SelectableCard"
import SplashScreenGuard from "../../../../router/guards/SplashScreenGuard"
import { UserInterestTopic } from "../../../../types/UserInterestTopic"
import { MembershipType } from "../../../../types/MembershipType"
import { DialogContent } from "../../../../types/DialogContent"
import { UserContext } from "../../../../stores/User/UserStore"
import { logAction } from "../../../../clients/logger"
import { Route } from "../../../../router"
import useTrackPageView from "../../../../helpers/hooks/use-track-page-view"
import useGoogleTagManager from '../../../../helpers/analytics/use-google-tag-manager'
import GtmEventName from '../../../../helpers/analytics/GtmEventName'

import styles from "./UserInterestsSelection.module.scss"

const MAX_SELECTABLE_TOPICS = 3

const UserInterestsSelection = () => {
    useTrackPageView(`User Interests Page`)

    const { t } = useTranslation()

    const navigate = useNavigate()
    const location = useLocation()
    const { trackButtonClick } = useGoogleTagManager()

    const { retrieveUserInterestTopics, setUserInterests } = useUserInterestTopicsRepository()

    const { state: userState } = useContext(UserContext)
    const { state: membershipState } = useContext(MembershipContext)

    const userMembership = userState.membership?.data
    const currentUserInterests = userMembership?.type === MembershipType.STARTER ? userMembership.starterInterestTopics : userMembership?.upmeInterestTopics

    const [selectedTopics, setSelectedTopics] = useState<number[]>(currentUserInterests ?? [])
    const [isLoading, setIsLoading] = useState(false)
    const [dialogContent, setDialogContent] = useState<DialogContent>()
    const [interestTopics, setInterestTopics] = useState<UserInterestTopic[]>()

    useEffect(() => {
        retrieveUserInterestTopics()
    }, [])

    useEffect(() => {
        if (membershipState.userInterestTopics?.data) {
            setInterestTopics(membershipState.userInterestTopics.data.filter(topic => {
                return topic.membershipScope === userMembership?.type
            }))
        }
    }, [membershipState.userInterestTopics?.data])

    const handleTopicClick = (topicId: number, selected: boolean) => {
        if (!selected) {
            const newTopics = selectedTopics.filter(id => id !== topicId)
            setSelectedTopics(newTopics)
        } else if (setSelectedTopics.length < MAX_SELECTABLE_TOPICS) {
            setSelectedTopics([...selectedTopics, topicId])
        }
    }

    const isTopicCurrentUserInterest = (topic: UserInterestTopic): boolean => {
        return !!currentUserInterests?.find(item => item === topic.id)
    }

    const handleBottomButtonClick = () => {
        trackButtonClick(GtmEventName.CONFIRM_USER_INTERESTS)
        setIsLoading(true)
        setUserInterests(selectedTopics)
            .then(() => {
                logAction(`debug`, UPMEUserInterestPageMessageTemplate(userState.membership?.data, selectedTopics))
                const currentLocation = location.state ? location.state.from : navigate(Route.HOME, { replace: true })
                navigate(currentLocation)
            })
            .catch(() => {
                setDialogContent({
                    title: t(`error_default_title`),
                    message: t(`error_default_message`),
                })
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const handleOnConfirm = () => {
        const currentLocation = location.state ? location.state.from : navigate(Route.HOME, { replace: true })
        navigate(currentLocation)
    }

    const topicCard = (topic: UserInterestTopic) => {
        return <SelectableCard
            className={styles.topicCard}
            itemId={topic.id}
            image={topic.image}
            label={topic.title}
            selected={isTopicCurrentUserInterest(topic)}
            selectable={selectedTopics.length < MAX_SELECTABLE_TOPICS}
            onItemClick={handleTopicClick}
            key={`topic-${topic.id}`}
        />
    }

    if (!membershipState.userInterestTopics?.data) {
        return (
            <SplashScreenGuard>
                <UserInterestsSelectionSkeleton />
            </SplashScreenGuard>
        )
    }

    return (
        <SplashScreenGuard>
            <Container className={styles.container}>
                <Typography className={styles.title} variant="h1">{t(`upme_interests_title`)}</Typography>
                <Typography className={styles.subtitle} variant="subtitle1">{t(`upme_interests_subtitle`)}</Typography>

                <Box className={styles.topicsList}>
                    {
                        interestTopics?.map(topicCard)
                    }
                </Box>

                <Box className={styles.bottomButton}>
                    <LoadingButton variant="contained" loading={isLoading} onClick={handleBottomButtonClick}>{t(`upme_interests_button`)}</LoadingButton>
                </Box>

                <ConfirmationDialog
                    acknowledgeOnly
                    buttonText={t(`common_button_confirmation`)}
                    confirmationTitle={dialogContent?.title}
                    confirmationText={dialogContent?.message}
                    defaultOpen={!!dialogContent}
                    onConfirm={handleOnConfirm}
                />
            </Container>
        </SplashScreenGuard>
    )
}

export default UserInterestsSelection