import { Box, Typography } from "@mui/material"
import { SimpleFAQEntry } from "../../../types/SimpleFAQEntry"
import SimpleFAQItem, { simpleFaqItemKey } from "../SimpleFAQItem/SimpleFAQItem"
import { useTranslation } from "react-i18next"
import { useState } from "react"
import SimpleFAQSectionSkeleton from "./SimpleFAQSection.skeleton"
import useGoogleTagManager from '../../../helpers/analytics/use-google-tag-manager'
import GtmEventName from '../../../helpers/analytics/GtmEventName'

interface SimpleFAQSectionProps {
    faqEntries?: SimpleFAQEntry[],
}

const SimpleFAQSection = (props: SimpleFAQSectionProps) => {
    const { faqEntries } = props

    const { t } = useTranslation()
    const { trackButtonClick } = useGoogleTagManager()

    const [currentExpanded, setCurrentExpanded] = useState<string>()

    const handleAccordionChange = (entryKey: string) => {
        const newCurrentExpanded = entryKey === currentExpanded ? undefined : entryKey

        if (newCurrentExpanded) {
            trackButtonClick(GtmEventName.SIMPLE_FAQ_CLICK, currentEntryQuestion())
        }

        setCurrentExpanded(newCurrentExpanded)
    }

    const currentEntryQuestion = (): string | undefined => {
        if (faqEntries && currentExpanded) {
            return faqEntries.find((entry) => simpleFaqItemKey(entry) === currentExpanded)?.question
        }
    }

    if (!faqEntries) {
        return <SimpleFAQSectionSkeleton />
    }

    return (
        <Box>
            <Typography variant="h5">{t(`simple_faq_title`)}</Typography>
            <Box marginTop={1}>
                {faqEntries?.map(entry =>
                    <SimpleFAQItem faqEntry={entry} key={entry.id} currentExpanded={currentExpanded} onChange={handleAccordionChange} />,
                )}
            </Box>
        </Box>
    )
}

export default SimpleFAQSection