import { fetchMyInfoUserData } from '../../clients/myinfo'
import { MyInfoUserData } from '../../types/MyInfoUserData'

class MyInfoRepository {

    async get(code: string, state: string, liftAgeLimit: boolean, reason?: string): Promise<MyInfoUserData> {
        return await fetchMyInfoUserData(code, state, liftAgeLimit, reason)
    }
}

export default MyInfoRepository