import { useEffect, useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { useRequestedRoute } from '../hooks/requested-route'
import { UserContext } from '../../stores/User/UserStore'
import { Route } from '../index'

const REQUESTED_ROUTE_KEY = `requestedRoute`
const PREVIOUS_ROUTE_KEY = `previousRoute`

function useAuthenticatedGuard(requestedRoute: Route) {
    const { state: userSession } = useContext(UserContext)
    const navigate = useNavigate()
    const location = useLocation()

    const { setRequestedRoute } = useRequestedRoute()

    useEffect(() => {
        if (!userSession.session || userSession.session?.error) {
            setRequestedRoute(requestedRoute)
            navigate(Route.PLS_LOGIN)
        } else {
            localStorage.setItem(PREVIOUS_ROUTE_KEY, location.pathname)
        }
    })
}

export {
    REQUESTED_ROUTE_KEY,
    PREVIOUS_ROUTE_KEY,
    useAuthenticatedGuard,
}