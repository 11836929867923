import { StrapiUpmeBenefit, UpmeBenefit } from '../../types/UpmeBenefit'
import { absoluteImageUrl } from '../utils/environment'
import fromStrapiUpmeBenefitCategory from './upme-benefit-category'

const fromStrapiUpmeBenefit = (strapiUpmeBenefit: StrapiUpmeBenefit): UpmeBenefit => (
    {
        id: strapiUpmeBenefit.id,
        title: strapiUpmeBenefit.attributes.title,
        summary: strapiUpmeBenefit.attributes.summary,
        description: strapiUpmeBenefit.attributes.description,
        isUpme: strapiUpmeBenefit.attributes.isUPME,
        isUpmeDetails: strapiUpmeBenefit.attributes.isUPMEDetails,
        isStarter: strapiUpmeBenefit.attributes.isStarter,
        isStarterDetails: strapiUpmeBenefit.attributes.isStarterDetails,
        order: strapiUpmeBenefit.attributes.order,
        image: strapiUpmeBenefit.attributes.image ? absoluteImageUrl(strapiUpmeBenefit.attributes.image.data.attributes.url) : undefined,
        category: fromStrapiUpmeBenefitCategory(strapiUpmeBenefit.attributes.category.data),
    }
)

export default fromStrapiUpmeBenefit