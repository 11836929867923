import { Box, Skeleton } from '@mui/material'

import styles from './PillarCard.module.css'

const PillarCardSkeleton = () => (
    <Box className={styles.skeletonPillarCard}>
        <Box className={styles.skeletonPillarCardImage}>
            <Skeleton variant="rectangular" />
        </Box>
        <Box className={styles.skeletonPillarCardContent}>
            <Skeleton variant="text" className={styles.skeletonPillarCardTitle} />
            <Skeleton variant="text" className={styles.skeletonPillarCardTitle} />
            <Skeleton variant="text" className={styles.skeletonPillarCardDescription} />
            <Skeleton variant="text" className={styles.skeletonPillarCardDescription} />
            <Skeleton variant="text" className={styles.skeletonPillarCardDescription} />
            <Skeleton variant="text" className={styles.skeletonPillarCardDescription} />
        </Box>
    </Box>
)

export { PillarCardSkeleton }