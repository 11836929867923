import { StrapiUserInterestTopic, UserInterestTopic } from '../../types/UserInterestTopic'
import { absoluteImageUrl } from '../utils/environment'

const fromStrapiUserInterestTopic = (strapiUpmeBenefitTopic: StrapiUserInterestTopic): UserInterestTopic => (
    {
        id: strapiUpmeBenefitTopic.id,
        title: strapiUpmeBenefitTopic.attributes.title,
        order: strapiUpmeBenefitTopic.attributes.order,
        enabled: strapiUpmeBenefitTopic.attributes.enabled,
        membershipScope: strapiUpmeBenefitTopic.attributes.membershipScope,
        image: absoluteImageUrl(strapiUpmeBenefitTopic.attributes.image.data.attributes.url),
    }
)

export default fromStrapiUserInterestTopic