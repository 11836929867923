import { useEffect, useState } from 'react'
import { FormControlLabel, Typography, Grid, Checkbox } from '@mui/material'
import { t } from 'i18next'

import { OptionValues } from '../../../../types/QuestionComponent'

import styles from "./MultipleChoiceQuestion.module.css"

interface MultipleChoiceQuestionProps {
    key: string
    isMandatory: boolean
    minOptions: number
    maxOptions: number
    question: string
    options: OptionValues[]
    attemptedSubmission?: boolean
    disabled?: boolean
    onChange: (answers: string[]) => void
}

export const MultipleChoiceQuestion = (props: MultipleChoiceQuestionProps) => {
    const { key, question, options, isMandatory, minOptions, maxOptions, attemptedSubmission, disabled, onChange } = props

    const [selectedOptions, setSelectedOptions] = useState<string[]>([])

    useEffect(() => {
        onChange(selectedOptions)
    }, [selectedOptions])

    const createOptions = (options: OptionValues[]) => {
        return options.map((item) => {
            return {
                ...item,
                label: item.value,
            }
        })
    }

    const mappedOptions = createOptions(options)

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleOptionChange = (event: any) => {
        if (event.target.checked) {
            if (maxOptions && selectedOptions.length >= maxOptions) {
                return
            }

            setSelectedOptions([
                ...selectedOptions,
                event.target.value,
            ])
        } else {
            setSelectedOptions(
                selectedOptions.filter(value => value !== event.target.value),
            )
        }
    }

    const isRequired =
        (isMandatory && !selectedOptions.length && minOptions > 0 && selectedOptions.length < minOptions)
        && attemptedSubmission

    const displayValidationError = isRequired && (
        <Typography className={styles.error}>{t(`event_required_field`)}</Typography>
    )

    const radioControl = <Checkbox size="small" color='functional' disabled={disabled} />

    const showMappedRadioOptions = mappedOptions.map((option) => {
        const optionLabel = option.label ? option.label : ``

        const isChecked = selectedOptions.indexOf(option.value) >= 0

        return (
            <FormControlLabel
                key={`${key}_${option.value}`}
                value={option.value}
                control={radioControl}
                label={optionLabel}
                checked={isChecked}
                onChange={handleOptionChange}
            />
        )
    })

    return (
        <Grid container direction="column" mb={3}>
            <Grid item xs={12}>
                <Typography variant="subtitle1" >
                    {question} {isMandatory && `*`}
                </Typography>
                <Typography variant="caption" >
                    {(minOptions || maxOptions) ? t(`multipleChoiceQuestionOptionsPlease`) : ``}
                    {minOptions ? t(`multipleChoiceQuestionOptionsMinCount`, { count: minOptions }) : ``}
                    {(minOptions && maxOptions) ? t(`multipleChoiceQuestionOptionsAnd`) : ``}
                    {maxOptions ? t(`multipleChoiceQuestionOptionsMaxCount`, { count: maxOptions }) : ``}
                </Typography>
            </Grid>
            <Grid item xs={12} sx={{ 'display': `contents` }}>
                {showMappedRadioOptions}
                {displayValidationError}
            </Grid>
        </Grid>
    )
}
