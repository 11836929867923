import { FC, useState } from "react"
import { useNavigate } from "react-router-dom"
import { t } from "i18next"
import { Box } from "@mui/material"
import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js"
import { PaymentIntentResult, SetupIntentResult, StripePaymentElementOptions } from "@stripe/stripe-js"

import ConfirmationDialog from "../../molecules/ConfirmationDialog/ConfirmationDialog"
import { Route } from "../../../router"
import { logAction } from "../../../clients/logger"
import { errorPopupMessageTemplate, generateErrorCode } from "../../../helpers/logging/templates"
import { LoadingButton } from "@mui/lab"
import useGoogleTagManager from '../../../helpers/analytics/use-google-tag-manager'
import GtmEventName from '../../../helpers/analytics/GtmEventName'

import styles from "./StripePaymentElement.module.css"

export interface StripeModalProps {
    email?: string
    isFirstYearFree: boolean
    consent: boolean
    onConsentFalse: () => void
    onSuccess: () => void
}

export const StripePaymentElement: FC<StripeModalProps> = ({
    email,
    isFirstYearFree,
    consent,
    onConsentFalse,
    onSuccess,
}) => {
    const stripe = useStripe()
    const elements = useElements()
    const navigate = useNavigate()
    const { trackButtonClick } = useGoogleTagManager()

    const [isLoading, setIsLoading] = useState(false)
    const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false)
    const [dialogErrorMessageTitle, setDialogErrorMessageTitle] = useState(``)
    const [dialogErrorMessage, setDialogErrorMessage] = useState(``)

    const handleErrorDialogClose = () => {
        setIsErrorDialogOpen(false)
    }

    const handleOnDismiss = () => {
        logAction(`error`, errorPopupMessageTemplate(dialogErrorMessage))
        navigate(Route.ROOT)
    }

    const handleSubmit = async () => {
        trackButtonClick(GtmEventName.PAYMENT_CLICK)

        if (!consent) {
            onConsentFalse()
            return
        }
        if (!stripe || !elements) {
            return
        }

        setIsLoading(true)

        const confirmPayment = async (stripeFunction: Promise<PaymentIntentResult | SetupIntentResult>) => {
            try {
                const res = await stripeFunction

                if (res?.error && (res.error.payment_intent?.status === `requires_payment_method` || res.error.type)) {
                    setIsLoading(false)
                    setIsErrorDialogOpen(true)
                    setDialogErrorMessage(`${res.error.message}. The Stripe element has returned error: ${res.error.code} Error code: ${generateErrorCode()}`)
                    setDialogErrorMessageTitle(t(`sign_up_failure`) + `: ${res.error.type}`)
                    logAction(`error`, JSON.stringify(res.error))
                } else {
                    onSuccess()
                }
            } catch (error: unknown) {
                setIsLoading(false)
                setIsErrorDialogOpen(true)
                setDialogErrorMessage(`${error} error code: ${generateErrorCode()}`)
                setDialogErrorMessageTitle(t(`stripe_payment_failure`))
                logAction(`error`, JSON.stringify(error))
            }
        }

        if (isFirstYearFree) {
            return confirmPayment(stripe.confirmSetup({
                elements,
                redirect: `if_required`,
                confirmParams: {
                    payment_method_data: {
                        billing_details: {
                            email,
                        },
                    },
                },
            }))
        } else {
            return confirmPayment(stripe.confirmPayment({
                elements,
                redirect: `if_required`,
                confirmParams: {
                    payment_method_data: {
                        billing_details: {
                            email,
                        },
                    },
                },
            }))
        }
    }

    const options: StripePaymentElementOptions = {
        layout: `tabs`,
        business: { name: `Stripe` },
    }

    const buttonLabel = isFirstYearFree ? t(`stripe_proceed_to_setup_card_payment`) : t(`stripe_proceed_to_pay`)
    const isButtonDisabled = !stripe || !elements || isLoading

    return (
        <Box className={styles.container}>
            <PaymentElement id="payment-element" options={options} />
            <LoadingButton variant='contained' className={styles.paymentButton} disabled={isButtonDisabled} onClick={handleSubmit} loading={isLoading} fullWidth>{buttonLabel}</LoadingButton>
            <ConfirmationDialog
                buttonText={t(`common_button_confirmation`)}
                confirmationTitle={dialogErrorMessageTitle}
                confirmationText={dialogErrorMessage}
                dismissButtonText={t(`common_back_to_home`)}
                defaultOpen={isErrorDialogOpen}
                onConfirm={handleErrorDialogClose}
                onDismiss={handleOnDismiss}
            />
        </Box>
    )
}