import react, { useState } from "react"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import { useTranslation } from "react-i18next"
import { TransitionProps } from "@mui/material/transitions"
import { Button, Grid, Typography, Zoom } from "@mui/material"
import { useDeviceSelectors } from 'react-device-detect'

import LocalStorageKeys from "../../../types/LocalStorageKeys"
import StarterAppIcon from "../../../assets/logo192.png"
import UpmeAppIcon from "../../../assets/upme-logo_192.png"
import ShareIcon from "../../../assets/ios-share-icon.png"
import MenuIcon from "../../../assets/android-menu-icon.png"
import commonUtils from "../../../helpers/utils/common"

import styles from './PwaInstallationInstructionsAlert.module.css'

const Transition = react.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<string, string>;
    },
    ref: React.Ref<unknown>,
) {
    return <Zoom ref={ref} {...props} />
})

interface PwaInstallationInstructionsAlertProps {
    forceOpen?: boolean
}

const PwaInstallationInstructionsAlert = (props: PwaInstallationInstructionsAlertProps) => {
    const { t } = useTranslation()

    const [selectors] = useDeviceSelectors(window.navigator.userAgent)
    const { isAndroid, isIOS } = selectors

    const displayInstallationInstructionsKey = localStorage.getItem(LocalStorageKeys.DISPLAY_INSTALLATION_INSTRUCTIONS) ?? ``

    const shouldShowInstructions: boolean = props.forceOpen ||
        (displayInstallationInstructionsKey.length <= 0 && (isAndroid || isIOS))

    const [open, setOpen] = useState(shouldShowInstructions)

    const handleClose = () => {
        setOpen(false)
        localStorage.setItem(LocalStorageKeys.DISPLAY_INSTALLATION_INSTRUCTIONS, `true`)
    }

    const isUpmeDomain = commonUtils.isUpmeDomain()

    const logoIcon = isUpmeDomain ? UpmeAppIcon : StarterAppIcon

    const platformInstructions1 = isIOS ? t(`pwa_instructions_ios_1`) : t(`pwa_instructions_android_1`)
    const platformInstructions2 = isIOS ? t(`pwa_instructions_ios_2`) : t(`pwa_instructions_android_2`)
    const instructionsIcon = isIOS ? ShareIcon : MenuIcon

    const localeKeyForMembershipType = (): string => {
        if (isUpmeDomain) {
            return `membership_type_upme`
        }
        return `membership_type_starter`
    }

    const localizedMembershipType = localeKeyForMembershipType()

    return (
        <Dialog open={!!open} TransitionComponent={Transition} onClose={handleClose} >
            <DialogContent>
                <Grid container display="flex" direction="column" alignItems="center" spacing={2}>
                    <Grid item>
                        <img className={styles.appIcon} src={logoIcon} alt="app logo" />
                    </Grid>

                    <Grid item>
                        <DialogContentText className={styles.title}>
                            {t(`pwa_instructions_title`, { type: t(localizedMembershipType) })}
                        </DialogContentText>
                    </Grid>

                    <Grid item>
                        <Typography className={styles.message} variant="body1">{t(`pwa_instructions_message`)}</Typography>
                    </Grid>

                    <Grid container item display="flex" alignItems="center" justifyContent="center">
                        <Grid item>
                            <Typography className={styles.message} variant="body2">{platformInstructions1}</Typography>
                        </Grid>
                        <Grid item className={styles.instructionsIcon}>
                            <img src={instructionsIcon} />
                        </Grid>
                        <Grid item>
                            <Typography className={styles.message} variant="body2">{platformInstructions2}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant='contained' onClick={handleClose} fullWidth>{t(`pwa_instructions_close`)}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default PwaInstallationInstructionsAlert
