import { Grid } from "@mui/material"

import MarkdownContent from "../../atoms/MarkdownContent/MarkdownContent"
import BannerImage from "../../atoms/BannerImage/BannerImage"
import styles from './MembershipJoinPageContent.module.scss'

interface MembershipJoinPageContentProps {
    mainImage: string
    description: string
    bottomImage?: string
}

const MembershipJoinPageContent = (props: MembershipJoinPageContentProps) => {
    const { mainImage, description, bottomImage } = props

    return (
        <Grid container>
            <Grid item className={styles.membershipPlanBanner}>
                <BannerImage imageUrl={mainImage} className={styles.appLogo} alt="logo" />
            </Grid>
            <Grid item className={styles.centered}>
                <MarkdownContent
                    className={styles.membershipPlanDescription}
                    content={description}
                />
            </Grid>
            <Grid item className={styles.membershipPlanBanner}>
                <BannerImage imageUrl={bottomImage} className={styles.appLogo} alt="logo" />
            </Grid>
        </Grid>
    )
}

export default MembershipJoinPageContent