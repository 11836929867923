import { UtapLandingPage } from '../../types/UtapLandingPage'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const fromStrapiUTAPLandingPage = (strapiData: any): UtapLandingPage => {
    const { attributes: { title, description } } = strapiData
    return {
        title, description,
    }
}

export {
    fromStrapiUTAPLandingPage,
}