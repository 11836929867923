import { Container, Skeleton } from '@mui/material'
import CommonBackButton from '../../../atoms/CommonBackButton/CommonBackButton'
import Spacer from '../../../atoms/Spacer/Spacer'

const PaymentSummarySkeleton = () => {
    return (
        <Container>
            <CommonBackButton/>
            <Skeleton variant="text" sx={{ fontSize: `2rem` }} />
            <Skeleton variant="text" sx={{ fontSize: `1rem` }} />

            <Skeleton variant="text" sx={{ fontSize: `2rem` }} />
            <Skeleton variant="rounded" width="100%" height={120} />
            <Skeleton variant="text" sx={{ fontSize: `2rem` }} />

            <Spacer flexNumber={2}/>

            <Skeleton variant="rounded" width="100%" height={50} />
        </Container>
    )
}

export default PaymentSummarySkeleton