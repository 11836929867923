import { Container, Grid, Skeleton, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import CommonBackButton from '../../../atoms/CommonBackButton/CommonBackButton'
import { Route } from '../../../../router'

import styles from './MyActivity.module.css'
import SkeletonProps from '../../../../types/SkeletonProps'

const MyActivitySkeleton: React.FC<SkeletonProps> = (props: SkeletonProps) => {
    const { children, condition } = props

    const { t } = useTranslation()

    if (condition) {
        return children
    }

    return (
        <Grid className={styles.pageContainer}>
            <Container className={styles.myActivityHeading}>
                <CommonBackButton disableLabel path={Route.HOME} />
                <Typography variant={`h6`}>{t(`profile_my_activity`)}</Typography>
            </Container>

            <Container className={styles.skeletonContainer}>
                <Skeleton variant="rounded" height={80} />
                <Skeleton variant="text" sx={{ fontSize: `1rem` }} />
                <Skeleton variant="rounded" height={80} />
                <Skeleton variant="text" sx={{ fontSize: `1rem` }} />
                <Skeleton variant="rounded" height={80} />
                <Skeleton variant="text" sx={{ fontSize: `1rem` }} />
                <Skeleton variant="rounded" height={80} />
            </Container>
        </Grid>
    )
}

export default MyActivitySkeleton