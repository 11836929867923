import { fromStrapiMembershipPlan } from '../../helpers/mapping/membership-plan'
import { fetchMembershipPlan } from '../../clients/membership-plan'
import { MembershipPlan } from '../../types'
import { LocalStorageKeys } from '../../types/LocalStorageKeys'

class MembershipPlanRepository {
    async retrieveMembershipPlan(membershipPlan: string): Promise<MembershipPlan> {
        const data = await fetchMembershipPlan(membershipPlan)
        return fromStrapiMembershipPlan(data)
    }

    setMembershipPlanKey(membershipPlan?: string) {
        if (membershipPlan) {
            localStorage.setItem(LocalStorageKeys.MEMBERSHIP_PLAN, membershipPlan)
        } else {
            localStorage.removeItem(LocalStorageKeys.MEMBERSHIP_PLAN)
        }
    }

    getMembershipPlanKey(): string | undefined {
        return localStorage.getItem(LocalStorageKeys.MEMBERSHIP_PLAN) ?? undefined
    }
}

export default MembershipPlanRepository