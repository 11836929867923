import { absoluteImageUrl } from '../utils/environment'
import { HomePage } from '../../types'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const fromStrapiHomePage = (strapiData: any): HomePage => {
    const { attributes } = strapiData
    const { welcomeText, description, bannerImage } = attributes
    const bannerImageURL = absoluteImageUrl(bannerImage.data.attributes.url)

    return {
        welcomeText,
        description,
        bannerImageURL,
    }
}

export { fromStrapiHomePage }