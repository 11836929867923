import { FaqEntry } from "./FaqEntry"
import { MembershipScope } from "./MembershipScope"
import { Programme } from "./Programme"

export type Pillar = {
    id: number
    title: string
    description: string
    route?: string
    url?: string
    programme?: Programme,
    faqEntries?: FaqEntry[],
    imageURL?: string
    order: number,
    membershipScope: MembershipScope,
}

export const isUpcomingPillar = (pillar: Pillar) => !pillar.route && !pillar.url && !pillar.programme && !pillar.faqEntries?.length

export const isCurrentPillar = (pillar: Pillar) => !isUpcomingPillar(pillar)