import { useContext } from 'react'

import { AppContext } from '../../stores/App/AppStore'
import { ActionTypes } from '../../stores/App/AppReducer'
import FieldOfStudyRepository from './field-of-study.repository'

const useFoSRepository = () => {
    const { dispatch } = useContext(AppContext)
    const fosRepository = new FieldOfStudyRepository()

    const retrieveFieldsOfStudy = () => {
        fosRepository.getAll()
            .then((data) => {
                dispatch({ type: ActionTypes.FIELDS_OF_STUDY_RETRIEVE, data })
            })
            .catch((error) => {
                console.error(error.message)
            })
    }

    return {
        retrieveFieldsOfStudy,
    }
}

export { useFoSRepository }