import { Container, Skeleton } from "@mui/material"

import styles from "./FAQEntryComponent.module.css"

const FAQEntryComponentSkeleton = () => {
    return <Container>
        <Skeleton variant={`text`} className={styles.skeletonQuestion} />
        <Skeleton variant={`text`} className={styles.skeletonAnswer} />
        <Skeleton variant={`text`} className={styles.skeletonAnswer} />
        <Skeleton variant={`text`} className={styles.skeletonAnswer} />
    </Container>
}

export default FAQEntryComponentSkeleton