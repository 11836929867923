import { useContext, useEffect, useState } from "react"
import { Button, Slide, SlideProps, Snackbar, useTheme } from "@mui/material"
import { useTranslation } from "react-i18next"

import { AppContext } from "../../../stores/App/AppStore"
import { useVersionRepository } from "../../../repositories/Version/use-version-repository"

const VersionUpdateSnackbar: React.FC = () => {
    const { t } = useTranslation()
    const { palette } = useTheme()
    const { state } = useContext(AppContext)
    const { fetchVersion, setVersion } = useVersionRepository()
    const [hasNewVersion, setHasNewVersion] = useState(false)
    const [newVersion, setNewVersion] = useState<undefined | string>(undefined)

    useEffect(() => {
        fetchVersion()
    }, [])

    useEffect(() => {
        const { current, upcoming } = state.version

        if (upcoming !== undefined && current !== upcoming) {
            setHasNewVersion(true)
            setNewVersion(upcoming)
        }
    }, [state.version.upcoming])

    const handleRefresh = () => {
        setHasNewVersion(false)

        if (newVersion) {
            setVersion(newVersion)
        }

        window.location.reload()
    }

    const versionUpdateButtonStyle = {
        color: palette.primary.main,
    }

    const versionUpdateSnackBarAction = (
        <Button sx={versionUpdateButtonStyle} size="small" onClick={handleRefresh}>
            {t(`reload`)}
        </Button>
    )

    const snackBarTransitionComponent = (props: SlideProps) => (
        <Slide direction="up" {...props} />
    )

    return <Snackbar
        open={hasNewVersion}
        message={`New version available`}
        action={versionUpdateSnackBarAction}
        TransitionComponent={snackBarTransitionComponent}
    />
}

export default VersionUpdateSnackbar