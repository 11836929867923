import { fromStrapiTrainingCourse } from '../../helpers/mapping/training-course'
import { TrainingCourse } from '../../types'
import { fetchTrainingCourse } from '../../clients/training-course'

class TrainingCourseRepository {

    async getAll(trainerId: number | string): Promise<TrainingCourse[]> {
        const data = await fetchTrainingCourse(trainerId)

        return data.map((i: unknown) => fromStrapiTrainingCourse(i))
    }
}

export default TrainingCourseRepository