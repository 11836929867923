import { Close } from "@mui/icons-material"
import { Box, ButtonBase, Checkbox, Container, Dialog, Typography } from "@mui/material"
import { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { useTnCRepository } from "../../../repositories/TermsAndConditions/use-terms-repository"
import { AppContext } from "../../../stores/App/AppStore"
import { TermType } from "../../../types/TermsAndConditions"
import MarkdownContent from "../../atoms/MarkdownContent/MarkdownContent"
import useGoogleTagManager from '../../../helpers/analytics/use-google-tag-manager'
import GtmEventName from '../../../helpers/analytics/GtmEventName'

import styles from "./TermsAndConditionsCheckbox.module.css"

interface TermsAndConditionsCheckboxProps {
    starterTemsConsent: boolean
    onTermsConsentChange: (value: boolean) => void
}

const TermsAndConditionsCheckbox = (props: TermsAndConditionsCheckboxProps) => {
    const { starterTemsConsent, onTermsConsentChange } = props

    const { t } = useTranslation()
    const { trackButtonClick } = useGoogleTagManager()
    const { state } = useContext(AppContext)

    const { retrieveTerm } = useTnCRepository()
    const [showTerms, setShowTerms] = useState(false)

    const termsAndConditions = state.terms.find((term) => term.purpose === TermType.STARTER_APP)

    const handleShowTerms = () => {
        trackButtonClick(GtmEventName.TERMS_AND_CONDITIONS_CLICK)
        retrieveTerm(TermType.STARTER_APP)
        setShowTerms(true)
    }

    return <>
        <Container className={styles.termsContainer}>
            <Checkbox checked={starterTemsConsent} onChange={() => onTermsConsentChange(!starterTemsConsent)} color='functional' />
            <Typography variant="body1" className={styles.termsText}>
                {t(`sign_up_consent_starter_terms_1`)} <span className={styles.termsClickableText} onClick={handleShowTerms}>{` ` + t(`sign_up_consent_starter_terms_2`)}</span>
            </Typography>
        </Container>

        <Dialog
            fullScreen
            open={showTerms}
            onClose={() => setShowTerms(false)}
        >
            <Box className={styles.dialogContainer}>
                <ButtonBase
                    onClick={() => setShowTerms(false)}
                    className={styles.dialogCloseButton}
                >
                    <Close />
                </ButtonBase>

                <Box className={styles.dialogContent}>

                    <Typography
                        variant="h6">{termsAndConditions?.title ?? t(`terms`)}</Typography>
                    <MarkdownContent content={termsAndConditions?.content} />
                </Box>
            </Box>
        </Dialog>
    </>
}

export default TermsAndConditionsCheckbox