import commonUtils from "./helpers/utils/common"

const setApplicationMetadata = () => {
    const isUpmeDomain = commonUtils.isUpmeDomain()

    const link = document.createElement(`link`)
    link.rel = `manifest`
    link.href = isUpmeDomain ? `/upme-manifest.json` : `/starter-manifest.json`
    document.head.appendChild(link)

    if (isUpmeDomain) {
        document.title = `U PME`
        const favicon = document.querySelector(`link[rel="icon"]`)
        const appleTouchIconLink = document.querySelector(`link[rel="apple-touch-icon"]`)

        favicon?.setAttribute(`href`, `/upme-favicon.ico`)
        appleTouchIconLink?.setAttribute(`href`, `/upme-logo_192.png`)
    }
}

export default setApplicationMetadata