import { LocalStorageKeys } from '../../types/LocalStorageKeys'
import { Partner } from '../../types/Partner'
import { fetchPartner } from '../../clients/partner'

class PartnerRepository {
    async retrievePartner(partnerCode: string): Promise<Partner> {
        return fetchPartner(partnerCode)
    }

    async setPartnerCode(partnerCode?: string): Promise<Partner | undefined> {
        if (partnerCode) {
            localStorage.setItem(LocalStorageKeys.PARTNER, partnerCode)
            return this.retrievePartner(partnerCode)
        } else {
            localStorage.removeItem(LocalStorageKeys.MEMBERSHIP_PLAN)
        }

        return undefined
    }

    getPartnerCode(): string | undefined {
        return localStorage.getItem(LocalStorageKeys.PARTNER) ?? undefined
    }
}

export default PartnerRepository