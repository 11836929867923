import { PaymentHistoryResult } from "../../types/StripePaymentOrder"

interface StrapiPaymentOrderTransaction {
    id: number,
    attributes: PaymentHistoryResult
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const fromStrapiPaymentOrderHistory = (strapiData: StrapiPaymentOrderTransaction[]): PaymentHistoryResult[] => {
    return strapiData.map((data) => data.attributes)
}

export { fromStrapiPaymentOrderHistory }