import { Container, Typography } from "@mui/material"
import { useLocation, useNavigate } from "react-router-dom"

import CommonBackButton from "../../../atoms/CommonBackButton/CommonBackButton"
import MarkdownContent from "../../../atoms/MarkdownContent/MarkdownContent"
import { Route } from "../../../../router"
import { useTranslation } from "react-i18next"
import SplashScreenGuard from "../../../../router/guards/SplashScreenGuard"
import { useEffect } from "react"

const ProgrammeApplicationFormTermsAndConditions = () => {
    const { state } = useLocation()
    const { t } = useTranslation()
    const navigate = useNavigate()

    useEffect(() => {
        if (!state) {
            navigate(Route.HOME)
        }
    }, [state])

    if (!state) {
        return <></>
    }

    return (
        <SplashScreenGuard>
            <Container>
                <CommonBackButton />
                <Typography variant={`h3`} mt={2}>{t(`programme_application_form_terms_and_conditions`)}</Typography>
                <MarkdownContent content={state.termsAndConditions} />
            </Container>
        </SplashScreenGuard>
    )
}

export default ProgrammeApplicationFormTermsAndConditions