import { Institution } from "../../types"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const fromStrapiInstitution = (strapiData: any): Institution => {
    const { id, attributes } = strapiData
    const { name, order } = attributes

    const item: Institution = {
        id,
        name,
        order,
    }

    return item
}

export {
    fromStrapiInstitution,
}