import { useState } from 'react'
import { Radio, FormControlLabel, Typography, Grid } from '@mui/material'
import { t } from 'i18next'
import { OptionValues } from '../../../../types/QuestionComponent'

import styles from "./RadioQuestion.module.css"

interface RadioQuestionProps {
    key: string
    isMandatory: boolean
    question: string
    options: OptionValues[]
    attemptedSubmission?: boolean
    disabled?: boolean
    onChange: (data: string) => void
}

export const RadioQuestion = (props: RadioQuestionProps) => {
    const { key, question, options, isMandatory, attemptedSubmission, disabled, onChange } = props

    const [selectedOption, setSelectedOption] = useState(``)

    const createOptions = (options: OptionValues[]) => {
        return options.map((item) => {
            return {
                ...item,
                label: item.value,
            }
        })
    }

    const mappedOptions = createOptions(options)

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleOptionChange = (event: any) => {
        setSelectedOption(event.target.value)
        onChange(event.target.value)
    }

    const isRequired = isMandatory && !selectedOption && attemptedSubmission

    const displayValidationError = isRequired && (
        <Typography className={styles.error}>{t(`event_required_field`)}</Typography>
    )

    const radioControl = <Radio size="small" color='functional' disabled={disabled} />

    const showMappedRadioOptions = mappedOptions.map((option) => {
        const optionLabel = option.label ? option.label : ``
        const isChecked = selectedOption === option.value

        return (
            <FormControlLabel
                key={`${key}_${option.value}`}
                value={option.value}
                control={radioControl}
                label={optionLabel}
                checked={isChecked}
                onChange={handleOptionChange}
            />
        )
    })

    return (
        <Grid container direction="column" mb={3}>
            <Grid item xs={12}>
                <Typography variant="subtitle1" >
                    {question} {isMandatory && `*`}
                </Typography>
            </Grid>
            <Grid item xs={12} sx={{ 'display': `contents` }}>
                {showMappedRadioOptions}
                {displayValidationError}
            </Grid>
        </Grid>
    )
}
