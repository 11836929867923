import { Box, Typography } from "@mui/material"
import { useTranslation } from 'react-i18next'

import CustomDrawer from "../../atoms/CustomDrawer/CustomDrawer"
import { AnchorDirection } from "../../../types/AnchorDirection"

import styles from './FilterMenu.module.scss'

interface FilterMenuProps {
    isOpen: boolean
    handleMenuCloseClick: () => void
    filterTypeList: string[]
    onFilterChange: (type: string) => void
}

const FilterMenu = (props: FilterMenuProps) => {
    const { isOpen, handleMenuCloseClick, filterTypeList, onFilterChange } = props

    const { t } = useTranslation()

    const handleFilterClick = (filterType: string) => {
        onFilterChange(filterType)
    }

    const filterListCategories = filterTypeList.map((name, index) => {
        return (
            <Box key={`category-${index}-${name}`} className={styles.categories}>
                <Typography variant='h6' className={styles.categoryName} onClick={() => handleFilterClick(name)}>{name}</Typography>
            </Box>)
    })

    return (
        <CustomDrawer anchor={AnchorDirection.RIGHT} isOpen={isOpen} onClose={handleMenuCloseClick}>
            <Box>
                <Box className={styles.filterBy}>
                    <Typography className={styles.filterName} variant='body2' color={`functional.text.lighter`}>{t(`filter_by`, {colon: `:`})}</Typography>
                </Box>
                <Box>
                    {filterListCategories}
                </Box>
            </Box>
        </CustomDrawer>
    )
}

export default FilterMenu