import { Container, Grid, Typography } from "@mui/material"
import { t } from "i18next"
import { useState } from "react"

import { QuestionResponses } from "../../../types/QuestionResponses"
import { QuestionProps } from "../../../types/QuestionComponent"
import { EventRegistrationQuestions } from "./EventRegistrationQuestions"

import styles from "./EventRegistrationForm.module.css"
import { LoadingButton } from "@mui/lab"

interface EventRegistrationFormProps {
    handleRegistration: (data: QuestionResponses) => void
    questionnaireList: QuestionProps[]
    isLoading: boolean
}

export const EventRegistrationForm = (
    props: EventRegistrationFormProps,
) => {
    const { handleRegistration, questionnaireList, isLoading } = props

    const [registrationData, setRegistrationData] = useState({})
    const [attemptedSubmission, setAttemptedSubmission] = useState(false)

    const handleRegistrationClick = () => {
        setAttemptedSubmission(true)
        handleRegistration(registrationData)
    }

    return (
        <Container className={styles.container}>
            <Grid container direction="column" spacing={1} className={styles.gridContainer}>
                <Grid item>
                    <Typography variant="h6" align="center" mb={2}>
                        {t(`event_registration`)}
                    </Typography>
                </Grid>
                <Grid item>
                    <EventRegistrationQuestions questionnaireList={questionnaireList} attemptedSubmission={attemptedSubmission} setRegistrationData={setRegistrationData} />
                </Grid>
                <Grid item className={styles.centered}>
                    <LoadingButton variant='outlined' className={styles.button} onClick={handleRegistrationClick} loading={isLoading}>{t(`event_register`)}</LoadingButton>
                </Grid>
            </Grid>
        </Container>
    )
}
