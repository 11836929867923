const enum GtmEventAction {
    CLICK = `Click`,
    OPEN = `Open`,
    CLOSE = `Close`,
    CONFIRM = `Confirm`,
    DISMISS = `Dismiss`,
    SUBMIT = `Submit`,
    PROVIDED = `Provided`,
}

export default GtmEventAction