import { ProfilePicture } from "../../../../types/ProfilePicture"

const MAX_ORIGINAL_WIDTH = 500
const THUMBNAIL_WIDTH = 100
const JPG_QUALITY = 0.8

export interface ProfilePictures {
    original: ProfilePicture
    thumbnail: ProfilePicture
}

export const getPictureResizes = (file?: File): Promise<ProfilePictures> => {
    if (!file) {
        throw new Error(`ProfilePictureSelection.utils.getPictureResizes: The file is undefined in function`)
    }

    return readFileAsync(file)
        .then(loadImageFromFile)
        .then(generateFullSizeAndThumbnail)
}

const readFileAsync = (file: File): Promise<ProgressEvent<FileReader>> => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader()
        fileReader.onloadend = resolve
        fileReader.onerror = reject
        fileReader.readAsDataURL(file)
    })
}

const loadImageFromFile = (result: ProgressEvent<FileReader>): Promise<HTMLImageElement> =>
    new Promise((resolve, reject) => {
        if (!result.target?.result) {
            reject(new Error(`ProfilePictureSelection.utils.loadImageFromFile: No image content in image loading result`))
        }

        const imageSrc = result.target?.result as string

        const sourceImage = new Image()
        sourceImage.onload = () => {
            resolve(sourceImage)
        }
        sourceImage.src = imageSrc
    })

const generateFullSizeAndThumbnail = (image: HTMLImageElement): Promise<ProfilePictures> =>
    new Promise((resolve, reject) => {
        Promise.all([
            resizeImage(image, MAX_ORIGINAL_WIDTH / image.width),
            resizeImage(image, THUMBNAIL_WIDTH / image.width),
        ]).then(result => {
            const [original, thumbnail] = result

            resolve({
                original,
                thumbnail,
            })
        }).catch((error) => {
            reject(error)
        })
    })

const resizeImage = (image: HTMLImageElement, ratio: number): Promise<ProfilePicture> =>
    new Promise((resolve, reject) => {
        const canvas = document.createElement(`canvas`)

        canvas.width = image.width * ratio
        canvas.height = image.height * ratio

        const ctx = canvas.getContext(`2d`)

        if (ctx) {
            ctx.drawImage(image, 0, 0, image.width, image.height, 0, 0, canvas.width, canvas.height)
        } else {
            reject(new Error(`ProfilePictureSelection.utils.generateFullSizeAndThumbnail: Could not get canvas' context`))
        }

        canvas.toBlob((blob) => {
            if (blob) {
                resolve({
                    dataUrl: canvas.toDataURL(),
                    blob: blob,
                })
            }
        }, `image/jpeg`, JPG_QUALITY)
    })