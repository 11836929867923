/* eslint-disable no-magic-numbers */
interface RGBA {
    r: number,
    g: number,
    b: number,
    a: number,
}

export const hexToRGB = (hex: string): RGBA => {
    let alpha = false
    let hexString = hex.slice(hex.startsWith(`#`) ? 1 : 0)

    if (hexString.length === 3) {
        hexString = [...hexString].map(x => x + x).join(``)
    }
    else if (hexString.length === 8) {
        alpha = true
    }

    const hexNumber = parseInt(hexString, 16)

    return {
        r: hexNumber >>> (alpha ? 24 : 16),
        g: (hexNumber & (alpha ? 0x00ff0000 : 0x00ff00)) >>> (alpha ? 16 : 8),
        b: (hexNumber & (alpha ? 0x0000ff00 : 0x0000ff)) >>> (alpha ? 8 : 0),
        a: alpha ? (hexNumber & 0x000000ff) : 255,
    }
}

export const rgbToString = (rgb: RGBA) => {
    return `rgba(${rgb.r},${rgb.g},${rgb.b},${rgb.a})`
}