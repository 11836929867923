import { anonymousClient } from './util'
import { HTTP_SUCCESS } from '../helpers/utils/http-status'

const fetchMembershipPlan = async (planKey: string) => {
    return anonymousClient()
        .get(
            `/api/membership-plans/${planKey}?populate=*`,
        ).then(res => {
            if (res.status !== HTTP_SUCCESS) {
                throw new Error(`Could not fetch Membership Plan`)
            }
            return res
        }).then(res => res.data)
}

export {
    fetchMembershipPlan,
}