import { Box, Container, Skeleton } from "@mui/material"
import CommonBackButton from "../../../atoms/CommonBackButton/CommonBackButton"

const ProgrammeApplicationFormSkeleton = () => {
    return (
        <Container>
            <CommonBackButton />
            <Box mt={3}>
                <Skeleton variant='text' sx={{ fontSize: `2rem` }} />
                <Skeleton variant='text' sx={{ fontSize: `2rem` }} />
            </Box>
            <Box mt={3}>
                <Skeleton variant='text' sx={{ fontSize: `1rem` }} />
                <Skeleton variant='text' sx={{ fontSize: `1rem` }} />
                <Skeleton variant='text' sx={{ fontSize: `1rem` }} />
                <Skeleton variant='text' sx={{ fontSize: `1rem` }} />
            </Box>
            <Box mt={2}>
                <Skeleton variant='text' width='40%' sx={{ fontSize: `1.4rem` }} />
                <Skeleton variant='rectangular' height='6rem' />
            </Box>
            <Box mt={2}>
                <Skeleton variant='text' width='50%' sx={{ fontSize: `1.4rem` }} />
                <Skeleton variant='rectangular' height='2rem' />
            </Box>
            <Box display='flex' justifyContent='center' mt={3}>
                <Skeleton variant='rounded' height='3rem' width='80%' />
            </Box>
        </Container>
    )
}

export default ProgrammeApplicationFormSkeleton