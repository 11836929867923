import Dialog from '@mui/material/Dialog'
import styles from './LogoutDialog.module.css'
import DialogContent from '@mui/material/DialogContent'
import { Button, Checkbox, Stack, Typography, Zoom } from '@mui/material'
import DialogActions from '@mui/material/DialogActions'
import react, { FC, useState } from 'react'
import { TransitionProps } from '@mui/material/transitions'
import { useTranslation } from 'react-i18next'

const Transition = react.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<string, string>;
    },
    ref: React.Ref<unknown>,
) {
    return <Zoom ref={ref} {...props} />
})

const LogoutDialogStyleClass = {
    paper: styles.dialog,
}

type LogoutDialogProps = {
    dialogText: string
    confirmationButtonText: string
    dismissButtonText: string
    isOpen: boolean
    onDismiss: () => void
    onConfirm: (logoutFromLinkPass: boolean) => void
}
const LogoutDialog: FC<LogoutDialogProps> = ({
    dialogText,
    confirmationButtonText,
    dismissButtonText,
    isOpen,
    onConfirm,
    onDismiss,
}) => {
    const { t } = useTranslation()
    const [logoutFromLinkPass, setLogoutFromLinkPass] = useState(false)

    const handleSetLogoutFromLinkPass = () => setLogoutFromLinkPass(prevState => !prevState)

    const handleOnConfirm = () => onConfirm(logoutFromLinkPass)

    const handleOnDismiss = () => onDismiss()

    return <Dialog open={isOpen} TransitionComponent={Transition} classes={LogoutDialogStyleClass}>
        <DialogContent>
            <Typography>{dialogText}</Typography>
            <Stack direction={`row`} className={styles.checkbox}>
                <Checkbox value={logoutFromLinkPass} onClick={handleSetLogoutFromLinkPass} color='functional' />
                <Typography>{t(`logout_from_linkpass`)}</Typography>
            </Stack>
        </DialogContent>
        <DialogActions>
            <Button variant='outlined' onClick={handleOnDismiss}>{dismissButtonText ?? t(`close`)}</Button>
            <Button variant='contained' onClick={handleOnConfirm}>{confirmationButtonText}</Button>
        </DialogActions>
    </Dialog>
}

export default LogoutDialog