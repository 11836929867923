import { StrapiDigitalResourceDetail, DigitalResourceDetail } from '../../types/DigitalResourceDetail'
import { absoluteImageUrl } from '../utils/environment'
import fromStrapiDigitalResourceCategory from './upme-digital-resource-category'

const fromStrapiDigitalResourceDetail = (strapiDigitalResourceDetail: StrapiDigitalResourceDetail): DigitalResourceDetail => {
    return {
        id: strapiDigitalResourceDetail.id,
        date: strapiDigitalResourceDetail.attributes.date,
        title: strapiDigitalResourceDetail.attributes.title,
        order: strapiDigitalResourceDetail.attributes.order,
        membershipScope: strapiDigitalResourceDetail.attributes.membershipScope,
        summary: strapiDigitalResourceDetail.attributes.summary,
        externalLink: strapiDigitalResourceDetail.attributes.externalLink,
        description: strapiDigitalResourceDetail.attributes.description,
        image: strapiDigitalResourceDetail.attributes.image?.data ? absoluteImageUrl(strapiDigitalResourceDetail.attributes.image.data.attributes.url) : ``,
        category: fromStrapiDigitalResourceCategory(strapiDigitalResourceDetail.attributes.category.data),
    }
}

export default fromStrapiDigitalResourceDetail