import { Deal } from "../../types"
import { DealCategory } from "../../types/DealCategory"

export const extractAndOrderCategories = (deals: Deal[]): DealCategory[] => {
    const dealCategories: DealCategory[] = []

    deals.forEach(deal => {
        const category = deal.category
        if (category) {
            const existingDeal = dealCategories.find((item) => item.name === category.name)
            if(!existingDeal){
                dealCategories.push(category)
            } else {
                return
            }
        }
    })

    dealCategories.sort((a: DealCategory, b: DealCategory) => {
        if (a.order === b.order) {
            return a.name.localeCompare(b.name)
        }

        if (a.order === 0) {
            return 1
        }

        if (b.order === 0) {
            // eslint-disable-next-line no-magic-numbers
            return -1
        }

        return a.order - b.order
    })

    return dealCategories
}