import { useContext, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Route } from '../../../router'
import { UserContext } from '../../../stores/User/UserStore'
import { useMyInfoUserDataRepository } from '../../../repositories/MyInfo/use-myinfo-repository'
import { useMembershipRepository } from '../../../repositories/Membership/use-membership-repository'
import { useUserRepository } from '../../../repositories/User/use-user-repository'
import { isValidMemberAge, maxMemberAge, minMemberAge } from '../../../helpers/validation/age'
import ConfirmationDialog from '../../molecules/ConfirmationDialog/ConfirmationDialog'
import LoadingPageContent from '../../organisms/LoadingPageContent/LoadingPageContent'
import LocalStorageKeys from '../../../types/LocalStorageKeys'
import { MyInfoReason } from '../../../types/MyInfoUserData'
import { MembershipStatus, UnionMembershipStatus } from '../../../types/MembershipStatus'
import { HTTP_FORBIDDEN, HTTP_SUCCESS, HTTP_UNAUTHORIZED } from '../../../helpers/utils/http-status'
import { fireEmptyVisitedSplashScreen } from '../../../stores/App/AppReducer'
import { AppContext } from '../../../stores/App/AppStore'
import { useSessionRepository } from '../../../repositories/Session/use-session-repository'
import { PaymentOrderReason } from '../../../types/PaymentOrderReason'
import StarterErrorCodes from '../../../types/ErrorCodes'
import { usePartnerRepository } from '../../../repositories/Partner/use-partner-repository'
import { MembershipType } from '../../../types/MembershipType'
import { Membership } from '../../../types'
import { errorPopupMessageTemplate, generateErrorCode, myinfoCallbackPageMessageTemplate } from '../../../helpers/logging/templates'
import { logAction, logLandingPageAction } from '../../../clients/logger'
import useTrackPageView from "../../../helpers/hooks/use-track-page-view"

const MyInfoCallback = () => {
    useTrackPageView(`MyInfo Callback`)

    const { t } = useTranslation()
    const { state: userState } = useContext(UserContext)
    const { dispatch: dispatchAppEvent } = useContext(AppContext)

    const navigate = useNavigate()
    const [searchParams] = useSearchParams()

    const { retrieveMyInfoUserData } = useMyInfoUserDataRepository()
    const { checkUnionMembershipWithNRIC } = useMembershipRepository()
    const { retrieveMembership, hasVisitedProfileSummaryUpdate } = useMembershipRepository()
    const { retrieveUser } = useUserRepository()
    const { disposeSession } = useSessionRepository()
    const { hasVisitedPartnerPage } = usePartnerRepository()

    const [myInfoErrorTitle, setMyinfoErrorTitle] = useState<string>()
    const [myInfoError, setMyinfoError] = useState<string>()
    const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false)
    const [isMyinfoAgeError, setIsMyinfoAgeError] = useState(false)

    const reason = localStorage.getItem(LocalStorageKeys.MYINFO_REASON) ?? undefined
    const myInfoUserData = userState.myInfoUserData?.data

    useEffect(() => {
        const code = searchParams.get(`code`)
        const state = searchParams.get(`state`)

        if (code && state) {
            fireEmptyVisitedSplashScreen(dispatchAppEvent)
            retrieveMyInfoUserData(code, state, userState.user?.data?.liftAgeLimit ?? false, reason)
        } else {
            displayRetrievalErrorAlert()
        }
    }, [])

    useEffect(() => {
        if (userState.myInfoUserData?.error) {
            const errorCode = userState.myInfoUserData.error.errorCode
            switch (errorCode) {
                case StarterErrorCodes.UIN_NOT_MATCHING_USER:
                    displayUINMismatchErrorAlert()
                    disposeSession()
                    break
                case StarterErrorCodes.USER_IS_UNION_MEMBER:
                    displayUserIsUnionMemberErrorAlert()
                    disposeSession()
                    break
                case StarterErrorCodes.USER_AGE_INVALID:
                    displayUserAgeInvalidErrorAlert()
                    disposeSession()
                    break
                case StarterErrorCodes.EMAIL_ALREADY_USED_BY_USER:
                case StarterErrorCodes.EMAIL_ALREADY_USED_BY_MEMBERSHIP:
                    displayEmailAlreadyUsedErrorAlert()
                    disposeSession()
                    break
                case StarterErrorCodes.UNION_MEMBERSHIP_EMAIL_ALREADY_USED:
                    displayUnionEmailAlreadyUsedErrorAlert()
                    disposeSession()
                    break
                default:
                    displayDefaultErrorAlert(userState.myInfoUserData.error.errorMessage)
            }
        } else {
            retrieveUser()
            retrieveMembership()
        }
    }, [userState.myInfoUserData])

    useEffect(() => {
        if (userState.membership && userState.myInfoUserData) {
            navigateToNextPage()
        }
    }, [userState.membership])

    useEffect(() => {
        const errorMsg = errorPopupMessageTemplate(myInfoError, userState.membership?.data?.id?.toString() ?? `not found`)
        if (errorMsg) {
            logLandingPageAction(errorMsg, `myInfo`)
        }
    }, [myInfoError])

    useEffect(() => {
        if (!userState.unionMembershipStatus) {
            return
        }

        const { error, data } = userState.unionMembershipStatus

        if (error) {
            switch (error.errorCode) {
                case HTTP_UNAUTHORIZED:
                case HTTP_FORBIDDEN:
                    displayUINMismatchErrorAlert()
                    return
                case HTTP_SUCCESS:
                default:
                    displayRetrievalErrorAlert()
                    return
            }
        }

        switch (data) {
            case UnionMembershipStatus.IS_MEMBER:
                navigate(Route.HOME, { replace: true })
                break
            case UnionMembershipStatus.NOT_MEMBER:
                if (reason === MyInfoReason.UTAP) {
                    navigate(`${Route.UTAP_CLAIM_FORM}?fromAuth=true`, { replace: true })
                }

                if (reason === MyInfoReason.UTAP_CLAIM_LIST) {
                    navigate(`${Route.UTAP_CLAIMS}?fromAuth=true`, { replace: true })
                }
                break
            default:
                break
        }
    }, [userState.unionMembershipStatus])

    const navigateToNextPage = () => {
        logAction(`debug`, myinfoCallbackPageMessageTemplate(userState.unionMembershipStatus?.data ?? UnionMembershipStatus.NOT_MEMBER, MyInfoReason.SIGNUP))
        switch (reason) {
            case MyInfoReason.PROFILE_UPDATE:
            case MyInfoReason.SIGNUP:
                handleSignupMyInfoData()
                break
            case MyInfoReason.UTAP:
                handleUtapMyInfoData()
                break
            case MyInfoReason.UTAP_CLAIM_LIST:
                navigate(`${Route.UTAP_CLAIMS}?fromAuth=true`, { replace: true })
                break
            default:
                displayMissingInformationAlert()
        }
    }

    const handleSignupMyInfoData = () => {
        const userMembership = userState.membership?.data

        if (userMembership?.status === MembershipStatus.PENDING_ACTIVATION) {
            handleWhenPendingActivationStatus(userMembership)
        } else if (userMembership?.status === MembershipStatus.PROCESSING || userMembership?.status === MembershipStatus.TERMINATED) {
            handleWhenProcessingOrTerminatedStatus()
        } else if (userMembership?.status === MembershipStatus.INCOMPLETE) {
            navigate(Route.MEMBERSHIP_SIGN_UP, { replace: true })
        } else if (userState.membership?.data?.status === MembershipStatus.ACTIVE) {
            if (hasVisitedProfileSummaryUpdate()) {
                navigate(Route.PROFILE_UPDATE, { replace: true })
            } else {
                navigate(Route.HOME, { replace: true })
            }
        } else {
            handleDefault(userMembership)
        }
    }

    const handleUtapMyInfoData = () => {
        if (!!myInfoUserData && isMyInfoUserUinFinContentComplete()) {
            checkUnionMembershipWithNRIC(myInfoUserData.content?.uinFin ?? ``)

        } else {
            displayMissingInformationAlert()
        }
    }

    const handleWhenPendingActivationStatus = (userMembership: Membership) => {
        if (!hasVisitedPartnerPage()) {
            navigate(`/membership/partner/${userMembership.partner?.code}`)
        } else {
            navigate(`/membership/payment-summary/${PaymentOrderReason.SIGNUP}`, { replace: true })
        }
    }

    const handleWhenProcessingOrTerminatedStatus = () => {
        if (isMyInfoUserDataContentComplete() && isUserAgeValid()) {
            navigate(`/membership/payment-summary/${PaymentOrderReason.SIGNUP}`, { replace: true })
        } else {
            displayInvalidAgeErrorAlert()
        }
    }

    const handleDefault = (userMembership?: Membership) => {
        if (userMembership?.type === MembershipType.STARTER && !userMembership?.starterInterestTopics?.length) {
            navigate(Route.MEMBERSHIP_USER_INTERESTS, { replace: true })
        } else if (userMembership?.type === MembershipType.UPME && !userMembership?.upmeInterestTopics?.length) {
            navigate(Route.MEMBERSHIP_USER_INTERESTS, { replace: true })
        } else {
            navigate(Route.HOME, { replace: true })
        }
    }

    const isUserAgeValid = () => {
        if (myInfoUserData) {
            if (myInfoUserData.content && (`dob` in myInfoUserData.content)) {
                const userData = myInfoUserData.content
                const isAgeLimitLifted = userState.user?.data?.liftAgeLimit
                return isAgeLimitLifted || isValidMemberAge(userData.dob)
            }
        }
        return false
    }

    const isMyInfoUserDataContentComplete = () => {
        if (myInfoUserData) {
            const userData = myInfoUserData.content
            return userData && (`dob` in userData) && userData.dob && userData.uinFin && userData.name
        }
        return false
    }

    const isMyInfoUserUinFinContentComplete = () => {
        if (myInfoUserData) {
            const userData = myInfoUserData.content
            return !!userData?.uinFin
        }
        return false
    }

    const displayMissingInformationAlert = () => {
        setMyinfoErrorTitle(t(`myinfo_retrieval_error_title`))
        setMyinfoError(t(`myinfo_missing_required_information`) + ` error code: ${generateErrorCode()}`)
        setIsErrorDialogOpen(true)
    }

    const displayRetrievalErrorAlert = () => {
        setMyinfoErrorTitle(t(`myinfo_retrieval_error_title`))
        setMyinfoError(t(`myinfo_retrieval_error_message`) + ` error code: ${generateErrorCode()}`)
        setIsErrorDialogOpen(true)
    }

    const displayDefaultErrorAlert = (message?: string) => {
        setMyinfoErrorTitle(t(`myinfo_retrieval_error_title`))
        setMyinfoError(message ?? t(`myinfo_retrieval_error_message`) + ` error code: ${generateErrorCode()}`)
        setIsErrorDialogOpen(true)
    }

    const displayInvalidAgeErrorAlert = () => {
        setMyinfoErrorTitle(t(`myinfo_retrieval_error_invalid_age_title`, {
            minAge: minMemberAge,
            maxAge: maxMemberAge,
        }))
        setMyinfoError(t(`myinfo_retrieval_error_invalid_age`) + ` error code: ${generateErrorCode()}`)
        setIsErrorDialogOpen(true)
    }

    const displayUINMismatchErrorAlert = () => {
        setMyinfoErrorTitle(t(`myinfo_retrieval_error_title`))
        setMyinfoError(t(`error_uin_not_matching_user`) + ` error code: ${generateErrorCode()}`)
        setIsErrorDialogOpen(true)
    }

    const displayUserIsUnionMemberErrorAlert = () => {
        setMyinfoErrorTitle(t(`myinfo_retrieval_error_title`))
        setMyinfoError(t(`error_user_is_union_member`) + ` error code: ${generateErrorCode()}`)
        setIsErrorDialogOpen(true)
    }

    const displayUserAgeInvalidErrorAlert = () => {
        setMyinfoErrorTitle(t(`myinfo_retrieval_error_title`))
        setMyinfoError(t(`error_user_age_invalid`))
        setIsMyinfoAgeError(true)
        setIsErrorDialogOpen(true)
    }

    const displayEmailAlreadyUsedErrorAlert = () => {
        setMyinfoErrorTitle(t(`myinfo_retrieval_error_title`))
        setMyinfoError(t(`error_email_already_used`) + ` error code: ${generateErrorCode()}`)
        setIsErrorDialogOpen(true)
    }

    const displayUnionEmailAlreadyUsedErrorAlert = () => {
        setMyinfoErrorTitle(t(`myinfo_retrieval_error_title`))
        setMyinfoError(t(`error_union_email_already_used`) + ` error code: ${generateErrorCode()}`)
        setIsErrorDialogOpen(true)
    }

    const handleOnConfirm = () => {
        setIsErrorDialogOpen(false)
        navigate(userState.membership?.data ? Route.HOME : Route.ROOT, { replace: true })
    }

    const handleOnDismiss = () => {
        setIsErrorDialogOpen(false)
        navigate(userState.membership?.data ? Route.HOME : Route.ROOT, { replace: true })
    }

    const openUnionPromoLink = () => {
        setIsErrorDialogOpen(false)
        const newWindow = window.open(process.env.REACT_APP_UNION_PROMOTIONAL_SIGNUP_URL, `_blank`, `noopener,noreferrer`)
        if (newWindow) {
            newWindow.opener = null
        }
        navigate(userState.membership?.data ? Route.HOME : Route.ROOT, { replace: true })
    }

    return (
        <>
            <LoadingPageContent title={t(`myinfo_retrieving_title`)} message={t(`myinfo_retrieving_message`)} />
            <ConfirmationDialog
                acknowledgeOnly
                buttonText={isMyinfoAgeError ? t(`apply`) : t(`common_button_confirmation`)}
                confirmationTitle={myInfoErrorTitle}
                confirmationText={myInfoError}
                defaultOpen={isErrorDialogOpen}
                onConfirm={isMyinfoAgeError ? openUnionPromoLink : handleOnConfirm}
                onDismiss={handleOnDismiss}
                shouldShowCloseButton={isMyinfoAgeError}
            />
        </>
    )
}

export default MyInfoCallback