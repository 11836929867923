import { Drawer, Container } from "@mui/material"

import { AnchorDirection } from "../../../types/AnchorDirection"
import CommonBackButton from "../../atoms/CommonBackButton/CommonBackButton"

import styles from "./CustomDrawer.module.scss"

interface CustomerDrawerProps {
    onClose: () => void
    isOpen: boolean
    anchor?: AnchorDirection
    children: React.ReactElement
}

const CustomDrawer = (props: CustomerDrawerProps) => {
    const { onClose, isOpen, anchor, children } = props

    const anchorDirection = anchor ?? AnchorDirection.RIGHT

    return (
        <Drawer anchor={anchorDirection} open={isOpen} onClose={onClose} classes={{paper: styles.customDrawerPaper}} >
            <Container className={styles.container}>
                <CommonBackButton onClick={onClose} />
                {children}
            </Container>
        </Drawer>
    )
}

export default CustomDrawer
