import { Container, Grid, Typography } from '@mui/material'
import 'react'

import maintenanceLogo from '../../../assets/maintenance-logo.png'

import { useTranslation } from 'react-i18next'

const Maintenance = () => {
    const { t } = useTranslation()

    return (
        <Container>
            <Grid container direction="column" spacing={4} alignItems="center" marginTop={2}>
                <Grid item>
                    <img src={maintenanceLogo} />
                </Grid>
                <Grid item textAlign='center'>
                    <Typography variant='h5'>{t(`maintenance_page_title`)}</Typography>
                </Grid>
                <Grid item textAlign='center'>
                    <Typography variant='body1'>{t(`maintenance_page_message`)}</Typography>
                </Grid>
            </Grid>
        </Container>
    )
}

export default Maintenance