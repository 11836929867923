import { useContext, useEffect } from 'react'
import { Container, Grid } from '@mui/material'

import { AppContext } from '../../../stores/App/AppStore'
import { useContactUsRepository } from '../../../repositories/ContactUsPage/use-contact-us-repository'
import CommonBackButton from '../../atoms/CommonBackButton/CommonBackButton'
import MarkdownContent from '../../atoms/MarkdownContent/MarkdownContent'
import useTrackPageView from "../../../helpers/hooks/use-track-page-view"

import styles from './ContactUs.module.css'

const ContactUs = () => {
    useTrackPageView(`Contact Us`)

    const { state } = useContext(AppContext)
    const { retrieveContactUsPage } = useContactUsRepository()

    useEffect(() => {
        retrieveContactUsPage()
        window.scrollTo(0, 0)
    }, [])

    return <Container>
        <CommonBackButton />
        <Grid container direction="column" spacing={2}>
            <Grid item className={styles.content}>
                <MarkdownContent content={state.pages.contactUsPage.details} className={styles.notice} />
            </Grid>
        </Grid>
    </Container>
}

export default ContactUs