import { useEffect, useContext } from "react"
import { Divider, Typography, Box, useTheme } from "@mui/material"
import { ArrowRight } from "@mui/icons-material"
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import MenuSection from "../../atoms/MenuSection/MenuSection"
import { Route } from "../../../router"
import { useDigitalResourceRepository } from "../../../repositories/DigitalResource/use-digital-resource.repository"
import { useMembershipRepository } from "../../../repositories/Membership/use-membership-repository"
import { MembershipType } from "../../../types/MembershipType"
import { programmesMenuMapping, resourceMenuMapping } from "../../../helpers/utils/main-menu"
import { UPMEContentContext } from "../../../stores/UPMEContent/UPMEContentStore"
import { AnchorDirection } from "../../../types/AnchorDirection"
import CustomDrawer from "../../atoms/CustomDrawer/CustomDrawer"
import { ABOUT_NTUC_MEMBERSHIP_URL } from "../AppFooter/AppFooter"

import styles from "./MainMenu.module.scss"
import { useProgrammeRepository } from "../../../repositories/Programme/use-programme-repository"
import { ProgrammeContext } from "../../../stores/Programme/ProgrammeStore"

interface MainMenuProps {
    isOpen: boolean
    handleMenuCloseClick: () => void
    membershipType?: MembershipType
}

const MainMenu = (props: MainMenuProps) => {
    const { isOpen, handleMenuCloseClick, membershipType } = props

    const { state: upmeContentState } = useContext(UPMEContentContext)
    const { state: programmeState } = useContext(ProgrammeContext)

    const { t } = useTranslation()
    const navigate = useNavigate()

    const { retrieveProgrammes } = useProgrammeRepository()
    const { retrieveMembership } = useMembershipRepository()
    const { retrieveLimitedDigitalResourcesByCategory } = useDigitalResourceRepository()

    const resourceCategories = upmeContentState.limitedDigitalResourceByCategory?.data
    const programmes = programmeState.programmes?.data

    useEffect(() => {
        retrieveMembership()
    }, [])

    useEffect(() => {
        if (membershipType) {
            retrieveProgrammes(membershipType)
            retrieveLimitedDigitalResourcesByCategory(membershipType)
        }
    }, [membershipType])

    const theme = useTheme()

    const aboutTextStyle = {
        color: theme.palette.primary.main,
    }

    const handleAboutPageClick = () => {
        handleMenuCloseClick()

        if (membershipType === MembershipType.UPME) {
            window.open(`${ABOUT_NTUC_MEMBERSHIP_URL}`, `_blank`)
        } else {
            navigate(Route.ABOUT)
        }
    }

    const offeringList = [{ name: t(`events_and_trainings`), route: Route.PILLARS_EVENT }, { name: t(`deals_and_promotions`), route: Route.DEALS_LIST }]
    const programmesList = programmes && programmesMenuMapping(programmes)
    const resourceList = resourceCategories && resourceMenuMapping(resourceCategories)

    const offeringsSection = offeringList.length > 0 && (
        <Box>
            <Divider />
            <MenuSection sectionTitle={t(`main_menu_explore_offers`)} list={offeringList} onMenuClose={handleMenuCloseClick} />
        </Box>
    )
    const programmesSection = programmesList && programmesList?.length > 0 && (
        <Box>
            <Divider />
            <MenuSection sectionTitle={t(`main_menu_programmes`)} list={programmesList ?? []} onMenuClose={handleMenuCloseClick} />
        </Box>
    )

    const resourcesSection = resourceList && resourceList?.length > 0 && (
        <Box>
            <Divider />
            <MenuSection sectionTitle={t(`main_menu_resources`)} list={resourceList ?? []} onMenuClose={handleMenuCloseClick} />
        </Box>
    )

    const aboutPageTitle = membershipType === MembershipType.UPME ? t(`main_menu_about_upme`) : t(`main_menu_about_starter`)

    return (
        <CustomDrawer anchor={AnchorDirection.RIGHT} isOpen={isOpen} onClose={handleMenuCloseClick} >
            <Box>
                <Box className={styles.aboutSection} onClick={handleAboutPageClick}>
                    <Typography variant='h5' sx={aboutTextStyle}>{aboutPageTitle}</Typography>
                    <ArrowRight />
                </Box>
                {offeringsSection}
                {programmesSection}
                {resourcesSection}
            </Box>
        </CustomDrawer>
    )
}

export default MainMenu
