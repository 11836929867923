/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo, type Dispatch } from 'react'

import SessionReducer, { UserAction } from './UserReducer'
import { type UserState, userInitialState } from './UserState'

const UserContext = React.createContext<{
    state: UserState,
    dispatch: Dispatch<UserAction>,
}>({
    state: userInitialState,
    dispatch: () => null,
})

const UserContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [state, dispatch] = React.useReducer(SessionReducer, userInitialState)
    const providerValues = useMemo(() => ({ state, dispatch }), [state])
    return (
        <UserContext.Provider value={providerValues}>
            {children}
        </UserContext.Provider>
    )
}

export { UserContextProvider, UserContext }