import { useContext } from "react"
import { MembershipType } from "../../types/MembershipType"
import ProgrammeRepository from "./programme-repository"
import { ProgrammeActions } from '../../stores/Programme/ProgrammeReducer'
import { ProgrammesServiceResponse } from "../../types/Programme"
import { ProgrammeContext } from "../../stores/Programme/ProgrammeStore"
import { starterDataError } from "../../helpers/utils/error-handling"

const useProgrammeRepository = () => {
    const { dispatch } = useContext(ProgrammeContext)
    const programmeRepository = new ProgrammeRepository()

    const retrieveProgrammes = (membershipType: MembershipType) => {
        const programmeResponse: ProgrammesServiceResponse = {}

        programmeRepository.retrieveAllProgrammes(membershipType)
            .then(programmes => {
                programmeResponse.data = programmes
            })
            .catch(error => {
                programmeResponse.error = starterDataError(error)
            })
            .finally(() => {
                dispatch({
                    type: ProgrammeActions.PROGRAMMES_RETRIEVED,
                    programmes: programmeResponse,
                })
            })
    }

    return {
        retrieveProgrammes,
    }
}

export { useProgrammeRepository }