import React, { useMemo, type Dispatch } from 'react'

import UPMEContentReducer, { type UPMEContentAction } from './UPMEContentReducer'
import { UPMEContentInitialState, type UPMEContentState } from './UPMEContentState'

const UPMEContentContext = React.createContext<{
    state: UPMEContentState,
    dispatch: Dispatch<UPMEContentAction>
}>({
    state: UPMEContentInitialState,
    dispatch: () => null,
})

const UPMEContentContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [state, dispatch] = React.useReducer(UPMEContentReducer, UPMEContentInitialState)
    const providerValues = useMemo(() => ({ state, dispatch }), [state])

    return (
        <UPMEContentContext.Provider value={providerValues}>
            {children}
        </UPMEContentContext.Provider>
    )
}

export { UPMEContentContextProvider, UPMEContentContext }