import moment from "moment"
import { FieldOfStudy } from "./FieldOfStudy"
import { Institution } from "./Institution"
import { MembershipStatus } from "./MembershipStatus"
import { MembershipType } from "./MembershipType"
import { Partner } from "./Partner"
import { Programme, getProgrammeSlug } from "./Programme"
import { ServiceResponse } from "./RemoteData"
import { MembershipPlan } from "./MembershipPlan"

export interface Membership {
    id?: number,
    fullName?: string,
    dateOfBirth?: Date,
    gender?: string,
    residentialStatus?: string,
    phoneNumber?: string,
    emailAddress?: string,
    postalCode?: string,
    block?: string,
    street?: string,
    floor?: string,
    unit?: string,
    building?: string,
    institutionID?: Institution,
    yearsBeforeGraduation?: number,
    fieldOfStudyID?: FieldOfStudy,
    eCardName?: string,
    expiryDate?: Date,
    preferences?: unknown,
    moneyOwlConsent?: boolean,
    snackConsent?: boolean,
    status?: MembershipStatus,
    hasPendingSignupPayment?: string,
    user?: {
        liftAgeLimit?: boolean,
    }
    exchangeID?: string,
    provisionalTerminationDateOnCancellation?: Date
    cancelSubscriptionAtCurrentPeriod?: boolean
    upmeInterestTopics: number[],
    starterInterestTopics: number[],
    hasStripeSubscription?: boolean,
    partner?: Partner,
    nric?: boolean,
    type: MembershipType,
    utapBalance?: number,
    utapBalanceLastUpdated?: Date,
    unionMembershipEid?: string,
    unionPhoneNumber?: string,
    unionEmailAddress?: string,
    unionMemberStartDate?: Date,
    profilePictureThumbnail?: string,
    lastUpdateByUser?: Date,
    planUsedOnLastActivation?: MembershipPlan,
    signUpDate?: Date,
    isOnRecurringPayment?: boolean,
}

export type MembershipResponse = ServiceResponse<Membership>
export type CancelMembershipResponse = ServiceResponse<CancelMembership>
export type ResumeRecurringMembershipResponse = ServiceResponse<ResumeRecurringMembership>

export interface CancelMembership {
    terminateOnCancelDate: Date
}

export interface ResumeRecurringMembership {
    resumeRecurringMembership: boolean
}
export interface ProgrammeRegistration {
    sentDate: string,
    optInDate: string
}

export const getProgrammeRegistration = (programme: Programme, membership?: Membership): ProgrammeRegistration | undefined => {
    if (membership?.preferences) {
        const preferences = membership.preferences
        const programmeSlug = getProgrammeSlug(programme)

        for (const [key, value] of Object.entries(preferences)) {
            if (key === programmeSlug) {
                return value as ProgrammeRegistration
            }
        }
    }
}

export const hasConsentForProgramme = (programme: Programme, membership?: Membership): boolean => {
    if (membership?.preferences) {
        const registration = getProgrammeRegistration(programme, membership)
        return !!(registration?.optInDate)
    }

    return false
}

export const membershipUserAge = (membership: Membership): number => {
    return moment().diff(membership.dateOfBirth, `years`, false)
}

export const isMembershipActive = (membership?: Membership): boolean => {
    return membership?.status === MembershipStatus.ACTIVE || membership?.status === MembershipStatus.UNION_ACTIVE
}