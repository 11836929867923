import { anonymousClient } from './util'
import { HTTP_SUCCESS } from '../helpers/utils/http-status'

const fetchUpmeIntroductionCarouselItems = async () => {
    return anonymousClient()
        .get(
            `/api/upme-introduction-carousel?populate[0]=items&populat[1]=items.image&populate[2]=items.image.media&populate[3]=items.upmeBenefit&populate[4]=items.upmeBenefit.category`,
        ).then(res => {
            if (res.status !== HTTP_SUCCESS) {
                throw new Error(`Could not fetch UPME introduction carousel items`)
            }
            return res.data
        })
}

const fetchUpmeIntroductionFAQEntries = async () => {
    return anonymousClient()
        .get(`/api/upme-introduction-faq?populate[0]=questions`).then(res => {
            if (res.status !== HTTP_SUCCESS) {
                throw new Error(`Could not fetch UPME introduction FAQ entries`)
            }
            return res.data
        })
}

const fetchUpmeBenefits = async () => {
    return anonymousClient()
        .get(`/api/upme-benefits?populate[0]=image&populate[1]=category`).then(res => {
            if (res.status !== HTTP_SUCCESS) {
                throw new Error(`Could not fetch UPME benefits`)
            }
            return res.data
        })
}

const fetchUpmeBenefit = async (id: number) => {
    return anonymousClient()
        .get(`/api/upme-benefits/${id}?populate[0]=image&populate[1]=category`).then(res => {
            if (res.status !== HTTP_SUCCESS) {
                throw new Error(`Could not fetch UPME benefit`)
            }
            return res.data
        })
}

const fetchUpmeBenefitCategories = async () => {
    return anonymousClient()
        .get(`/api/upme-benefit-categories`).then(res => {
            if (res.status !== HTTP_SUCCESS) {
                throw new Error(`Could not fetch UPME benefit categories`)
            }
            return res.data
        })
}

const fetchUpmeBenefitTopics = async () => {
    return anonymousClient()
        .get(`/api/upme-benefit-topics?populate=image&filters[enabled][$eq]=true`).then(res => {
            if (res.status !== HTTP_SUCCESS) {
                throw new Error(`Could not fetch UPME benefit topics`)
            }
            return res.data
        })
}

export {
    fetchUpmeIntroductionCarouselItems,
    fetchUpmeIntroductionFAQEntries,
    fetchUpmeBenefits,
    fetchUpmeBenefit,
    fetchUpmeBenefitCategories,
    fetchUpmeBenefitTopics,
}