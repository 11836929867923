import { Box, Button, Grid, Modal, Slide, Snackbar, SnackbarContent, Typography } from "@mui/material"
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined'
import { useTranslation } from "react-i18next"
import { useState } from "react"

import { MembershipStatus } from "../../../types/MembershipStatus"
import { Membership, UserInfo } from "../../../types"
import { Route } from "../../../router"
import useGoogleTagManager from '../../../helpers/analytics/use-google-tag-manager'
import GtmEventName from '../../../helpers/analytics/GtmEventName'

import styles from './ProfileReferralCode.module.css'

interface ProfileReferralCodeProps {
    user: UserInfo | undefined
    userMembership: Membership | undefined
}

enum ReferralCodeSnackbarMessage {
    SUCCESS = `profile_referral_modal_copy_snackbar`,
    ERROR = `clipboard_write_text_error`,
    NO_PERMISSION = `clipboard_write_text_no_permission`,
}

interface SnackBarConfig {
    visible: boolean
    messageKey: ReferralCodeSnackbarMessage
}

const ProfileReferralCode = (props: ProfileReferralCodeProps) => {
    const { user, userMembership } = props

    const { t } = useTranslation()
    const { trackButtonClick } = useGoogleTagManager()

    const [showModal, setShowModal] = useState(false)
    const [snackbarConfig, setSnackBarConfig] = useState<SnackBarConfig>({
        visible: false,
        messageKey: ReferralCodeSnackbarMessage.SUCCESS,
    })

    const modalStyle = {
        bgcolor: `background.paper`,
        outline: `none`,
        boxShadow: 24,
        p: 4,
    }

    const handleCodeClick = () => {
        trackButtonClick(GtmEventName.REFERRAL_CODE_COPY_CLICK, user?.referralCode)
        setShowModal(true)
    }

    const handleModalClose = () => {
        setShowModal(false)
    }

    const handleCopyButtonClick = () => {
        trackButtonClick(GtmEventName.REFERRAL_CODE_INVITATION_COPY_CLICK, user?.referralCode)

        const message = t(
            `profile_referral_modal_invitation`,
            {
                referralCode: user?.referralCode,
                link: `${process.env.REACT_APP_STARTER_DOMAIN_NAME}${Route.MEMBERSHIP_JOIN}/default/${user?.referralCode}`,
                interpolation: { escapeValue: false },
            },
        )

        navigator.clipboard.writeText(
            message,
        ).then(
            onCopySuccess,
            onCopyError,
        ).catch(
            onCopyNoPermission,
        )
    }

    const onCopySuccess = () => {
        setSnackBarConfig({
            visible: true,
            messageKey: ReferralCodeSnackbarMessage.SUCCESS,
        })
    }

    const onCopyError = () => {
        setSnackBarConfig({
            visible: true,
            messageKey: ReferralCodeSnackbarMessage.ERROR,
        })
    }

    const onCopyNoPermission = () => {
        setSnackBarConfig({
            visible: true,
            messageKey: ReferralCodeSnackbarMessage.NO_PERMISSION,
        })
    }

    const handleDismissCopySnackbar = () => {
        setSnackBarConfig({
            visible: false,
            messageKey: ReferralCodeSnackbarMessage.SUCCESS,
        })
    }

    if (user?.referralCode && userMembership?.status === MembershipStatus.ACTIVE) {
        return (
            <>
                <Grid container direction="row" alignItems="left" spacing={1}>
                    <Grid item>
                        <Typography className={styles.title} variant={`h6`} onClick={handleCodeClick}>{t(`profile_referral_text`) + user?.referralCode}</Typography>
                    </Grid>
                    <Grid className={styles.profileReferralCopyIconGridItem} display='flex' alignItems="center" item>
                        <ContentCopyOutlinedIcon className={styles.profileReferralCopyIcon} fontSize="medium" onClick={handleCodeClick} />
                    </Grid>
                </Grid>
                <Modal
                    open={showModal}
                    onClose={handleModalClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className={styles.modal} sx={modalStyle}>
                        <Grid container justifyContent="center" spacing={2}>
                            <Grid item>
                                <Typography id="modal-modal-title" variant="h6" component="h2">
                                    {t(`profile_referral_modal_title`)}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                    {t(`profile_referral_modal_description`)}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography>
                                    {t(`profile_referral_modal_explanation`)}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Button variant='contained' onClick={handleCopyButtonClick}>{t(`profile_referral_modal_copy_button`)}</Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>
                <Snackbar
                    anchorOrigin={{ vertical: `bottom`, horizontal: `center` }}
                    autoHideDuration={3000}
                    open={snackbarConfig.visible}
                    TransitionComponent={Slide}
                    onClose={handleDismissCopySnackbar}
                >
                    <SnackbarContent message={t(snackbarConfig.messageKey)} />
                </Snackbar>
            </>
        )
    }
    return <></>
}

export default ProfileReferralCode