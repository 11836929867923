import { useContext, useEffect, useState } from "react"

import { useInstitutionRepository } from "../../../repositories/Institution/use-institutions-repository"
import CustomSelect, { CustomSelectOption, CustomSelectProps } from "../../atoms/CustomSelect/CustomSelect"
import { AppContext } from "../../../stores/App/AppStore"
import { Institution } from "../../../types"

type InstitutionSelectProps = Omit<CustomSelectProps, `options`>

const createOptions = (institutions: Institution[]) => {
    const options: CustomSelectOption[] = []
    const localCopy = [...institutions].sort((a, b) => a.order - b.order)

    localCopy.forEach((i) => options.push({ label: i.name, value: i.id }))

    return options
}

const InstitutionSelect = (props: InstitutionSelectProps) => {
    const { state } = useContext(AppContext)
    const { retrieveInstitutions } = useInstitutionRepository()
    const [options, setOptions] = useState<CustomSelectOption[]>([])

    useEffect(() => {
        if (state.institutions.length < 1) {
            retrieveInstitutions()
        }
    }, [])

    useEffect(() => {
        setOptions(createOptions(state.institutions))
    }, [state.institutions])

    return (
        <CustomSelect
            {...props}
            options={options}
        />
    )
}

export default InstitutionSelect