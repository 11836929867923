import { anonymousClient, authenticatedClient } from './util'
import { HTTP_SUCCESS } from '../helpers/utils/http-status'

const fetchUserInterestsTopics = async () => {
    return anonymousClient()
        .get(`/api/user-interest-topics?populate=image&filters[enabled][$eq]=true`).then(res => {
            if (res.status !== HTTP_SUCCESS) {
                throw new Error(`Could not fetch user interest topics`)
            }
            return res.data
        })
}

const setUserInterests = async (interestsIds: number[]) => {
    const body = {
        interestsIds,
    }

    return authenticatedClient()
        .post(`/api/user-interest-topics/set-user-interests`, body).then(res => {
            if (res.status !== HTTP_SUCCESS) {
                throw new Error(`Could not set preferred interest topics`)
            }
            return res.data
        })
}

export {
    fetchUserInterestsTopics,
    setUserInterests,
}