import React, { useContext, useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { Box } from "@mui/material"
import { useTranslation } from "react-i18next"

import { Route, pathMatchesPaymentSummaryRoutePattern } from "../../../router"
import { UserContext } from "../../../stores/User/UserStore"
import { useMembershipRepository } from "../../../repositories/Membership/use-membership-repository"
import { useSessionRepository } from "../../../repositories/Session/use-session-repository"
import ConfirmationDialog from "../../molecules/ConfirmationDialog/ConfirmationDialog"
import VersionUpdateSnackbar from "../../molecules/VersionUpdateSnackbar/VersionUpdateSnackbar"
import { UnionMembershipStatus } from "../../../types/MembershipStatus"
import { MembershipType } from "../../../types/MembershipType"
import HeaderPageComponent from "../../molecules/HeaderPageComponent/HeaderPageComponent"
import AppFooter from "../../molecules/AppFooter/AppFooter"

import styles from "./Layout.module.css"

const Layout: React.FC<{ children: React.ReactElement }> = ({ children }) => {
    const { t } = useTranslation()
    const location = useLocation()

    const { state: userState } = useContext(UserContext)
    const { isAuthenticated } = useSessionRepository()
    const { retrieveMembership } = useMembershipRepository()

    const [isUnionDialogOpen, setUnionDialogOpen] = useState(false)

    const isUpmeMember = userState.membership?.data?.type === MembershipType.UPME

    useEffect(() => {
        if (isAuthenticated()) {
            retrieveMembership()
        }
    }, [userState.session])

    useEffect(() => {
        if (userState.unionMembershipStatus?.data === UnionMembershipStatus.IS_MEMBER) {
            setUnionDialogOpen(true)
        }
    }, [userState.unionMembershipStatus])

    const shouldHideHeaderAndFooter = () => {
        return location.pathname === Route.ROOT
            || location.pathname === Route.PLS_LOGIN
            || location.pathname === Route.PLS_CALLBACK
            || location.pathname === Route.MYINFO_CALLBACK
            || location.pathname === Route.MEMBERSHIP_SIGN_UP
            || location.pathname === Route.MEMBERSHIP_STARTER_ONBOARDING
            || location.pathname === Route.MEMBERSHIP_USER_INTERESTS
            || location.pathname === Route.UPME_CONVERSION_BENEFITS
            || location.pathname === Route.UPME_WELCOME_PAGE
            || pathMatchesPaymentSummaryRoutePattern(location.pathname)
    }

    if (shouldHideHeaderAndFooter()) {
        return children
    }

    return (
        <Box className={styles.appRoot}>
            <HeaderPageComponent />
            {children}
            <AppFooter isUpmeMember={isUpmeMember} />
            <ConfirmationDialog
                acknowledgeOnly
                buttonText={t(`common_button_confirmation`)}
                confirmationText={t(`existing_union_membership`)}
                defaultOpen={isUnionDialogOpen}
                onConfirm={() => setUnionDialogOpen(false)}
            />
            {process.env.REACT_APP_TEST !== `true` && <VersionUpdateSnackbar />}
        </Box>
    )
}

export default Layout