import { HTTP_SUCCESS } from "../helpers/utils/http-status"
import { anonymousClient } from "./util"

const fetchStarterHomeCarousel = async () => {
    return anonymousClient()
        .get(
            `/api/starter-home-carousel?populate[items][populate][0]=image`,
        )
        .then(res => {
            if (res.status !== HTTP_SUCCESS) {
                throw new Error(`Could not get Starter homepage carousel`)
            }
            return res.data
        })
        .then(res => res.data.attributes.items)
}

export {
    fetchStarterHomeCarousel,
}