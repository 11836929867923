import { fetchContactUsPage } from '../../clients/pages'
import { ContactUsPage } from '../../types/ContactUsPage'
import { fromStrapiContactUsPage } from '../../helpers/mapping/contactUsPage'

class ContactUsRepository {

    async getPage(): Promise<ContactUsPage> {
        const { data } = await fetchContactUsPage()
        return fromStrapiContactUsPage(data)
    }
}

export default ContactUsRepository