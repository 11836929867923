import { absoluteImageUrl } from '../utils/environment'
import { MembershipSuccessPage } from '../../types'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const fromStrapiMembershipSuccessPage = (strapiData: any): MembershipSuccessPage => {
    const { attributes } = strapiData
    const { title, description, subtitle, subtext, bannerImage } = attributes
    const bannerImageURL = absoluteImageUrl(bannerImage.data.attributes.url)

    return {
        title,
        description,
        subtitle,
        subtext,
        bannerImageURL,
    }
}

export { fromStrapiMembershipSuccessPage }