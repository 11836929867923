import { Grid, Typography } from "@mui/material"
import { useNavigate } from "react-router"
import { ArrowRight } from "@mui/icons-material"
import { FaqEntry } from "../../../types"
import { Route } from "../../../router"
import useGoogleTagManager from '../../../helpers/analytics/use-google-tag-manager'
import GtmEventName from '../../../helpers/analytics/GtmEventName'

import styles from "./FAQEntryComponent.module.css"

interface FAQEntryComponentProps {
    faqEntry: FaqEntry
}

const FAQEntryComponent = (props: FAQEntryComponentProps) => {
    const { faqEntry } = props

    const navigate = useNavigate()
    const { trackCardClick } = useGoogleTagManager()

    const handleCardClick = () => {
        trackCardClick(GtmEventName.FAQ_ENTRY_CLICK, faqEntry.question)
        navigate(`${Route.FAQ_DETAILS}/${faqEntry.id}`)
    }

    return (
        <Grid item className={styles.faqCard} onClick={handleCardClick}>
            <Typography variant={`h6`} >
                {faqEntry.question}
            </Typography>
            <Grid item xs={2} style={{ textAlign: `right` }}>
                <ArrowRight />
            </Grid>
        </Grid>
    )
}

export default FAQEntryComponent