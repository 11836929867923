import Box from "@mui/material/Box"
import Fab from "@mui/material/Fab"
import { useNavigate } from "react-router-dom"

import { Route } from "../../../router"
import membershipCardIcon from "../../../assets/membership-card-icon.png"
import useGoogleTagManager from '../../../helpers/analytics/use-google-tag-manager'
import GtmEventName from '../../../helpers/analytics/GtmEventName'

import styles from "./MembershipCardFloatingButton.module.scss"

const MembershipCardFloatingButton = () => {
    const navigate = useNavigate()
    const { trackButtonClick } = useGoogleTagManager()

    const handleFloatingButtonClick = () => {
        trackButtonClick(GtmEventName.MEMBERSHIP_CARD_CLICK)
        navigate(Route.MEMBERSHIP_CARD)
    }

    return (
        <Fab className={styles.floatingButton} aria-label={`Membership card floating button`} onClick={handleFloatingButtonClick} >
            <Box component='img' src={membershipCardIcon} alt={`Floating membership card button`} />
        </Fab>
    )
}

export default MembershipCardFloatingButton