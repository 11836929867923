/* eslint-disable no-magic-numbers */
import { HTTP_SUCCESS } from '../helpers/utils/http-status'
import LocalStorageKeys from '../types/LocalStorageKeys'
import { anonymousClient, authenticatedClient } from './util'

const createLocalStoraeUserSessionKey = () => {
    const crypto = window.crypto
    const array = new Uint32Array(1)
    return `SID-` + crypto.getRandomValues(array).toString()
}

const logAction = async (type: string, message: string) => {
    let localStorageUserSessionKey = localStorage.getItem(LocalStorageKeys.USER_SESSION_KEY)
    if (!localStorageUserSessionKey) {
        const key = createLocalStoraeUserSessionKey()
        localStorage.setItem(LocalStorageKeys.USER_SESSION_KEY, key)
        localStorageUserSessionKey = key
    }
    if (message.length === 0){
        return
    }
    return authenticatedClient()
        .post(
            `/api/log-action`,
            {
                type,
                message,
                localStorageUserSessionKey,
            },
        ).then(res => {
            if (res.status !== HTTP_SUCCESS) {
                throw new Error(`Could not log FE action`)
            }
            return res
        })
}

const logLandingPageAction = (action: string, pageType: string, key?: string) => {
    let localStorageUserSessionKey = localStorage.getItem(LocalStorageKeys.USER_SESSION_KEY)
    if (!localStorageUserSessionKey) {
        const key = createLocalStoraeUserSessionKey()
        localStorage.setItem(LocalStorageKeys.USER_SESSION_KEY, key)
        localStorageUserSessionKey = key
    }
    return anonymousClient()
        .post(
            `/api/log-landing-page-action`,
            {
                action,
                pageType,
                key,
                localStorageUserSessionKey,
            },
        ).then(res => {
            if (res.status !== HTTP_SUCCESS) {
                throw new Error(`Could not log FE landing action`)
            }
            return res
        })
}

export {
    logAction,
    logLandingPageAction,
}