import { StrapiDigitalResourceCategory, DigitalResourceCategory } from '../../types/DigitalResourceCategory'
import { absoluteImageUrl } from '../utils/environment'

const fromStrapiDigitalResourceCategory = (strapiDigitalResourceCategory: StrapiDigitalResourceCategory): DigitalResourceCategory => {
    return {
        id: strapiDigitalResourceCategory.id,
        name: strapiDigitalResourceCategory.attributes.name,
        order: strapiDigitalResourceCategory.attributes.order,
        membershipScope: strapiDigitalResourceCategory.attributes.membershipScope,
        placeHolderImage: strapiDigitalResourceCategory.attributes.placeHolderImage ? absoluteImageUrl(strapiDigitalResourceCategory.attributes.placeHolderImage.data.attributes.url) : ``,
    }
}

export default fromStrapiDigitalResourceCategory