import { ReactMarkdownProps } from "react-markdown/lib/complex-types"
import useGoogleTagManager from '../../helpers/analytics/use-google-tag-manager'

const useMarkdownLinkTrackerRenderer = () => {
    const { trackHyperlinkClick } = useGoogleTagManager()

    const markdownLinkTrackerRenderer = ({ href, children }: React.ClassAttributes<HTMLAnchorElement> & React.AnchorHTMLAttributes<HTMLAnchorElement> & ReactMarkdownProps) => {
        const handleLinkClick = () => {
            trackHyperlinkClick(href)
        }

        return (
            <a href={href} onClick={handleLinkClick} target="_blank" rel="noreferrer">
                {children}
            </a>
        )
    }

    return markdownLinkTrackerRenderer
}

export default useMarkdownLinkTrackerRenderer